import React, { useEffect, useState } from "react";
import styles from "../../common/css/zigle.module.scss";
import {FlexColumn} from "../common/container/PageContainer";

import GuideImg1 from "../../assets/imges/metamask/metamask_guide1_1.png";
import GuideImg2 from "../../assets/imges/metamask/metamask_guide1_2.png";
import {Trans, useTranslation} from "react-i18next";

export default function Guide1() {
    const { t } = useTranslation();
    return (
        <FlexColumn gap={24}>
            <FlexColumn gap={8}>
                <div className={styles.txt18_500}>Chrome Download</div>
                <div className={styles.txt14_B05} style={{lineHeight:'1.71'}}>
                    <Trans t={t} i18nKey={"1. 크롬에서 메타마스크를 검색해서 접속하세요.<br/>2. 다운로드 버튼을 누르고 사용하는 기기를 선택하세요.<br/>3. Install Meta for Chorme을 눌러주세요.<br/>4. 핸드폰에 설치를 원하실 경우 IOS 또는 Android를 선택하여 다운로드 받으세요."}/>
                </div>
            </FlexColumn>
            <div><img src={GuideImg1} alt="Guide Img" style={{width:'100%'}}/></div>
            <div><img src={GuideImg2} alt="Guide Img" style={{width:'100%'}}/></div>
        </FlexColumn>
    )
}