
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './slick.css'
import {FlexColumn, FlexColumnCenter, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function TopBanner(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover : true,
        draggable : true,
        appendDots: (dots: any) => (
            <FlexRowStart gap={3}
                          style={{
                              width: '100%',
                              position: 'absolute',
                              bottom: '-20px'
                          }}
            >
                <ul style={{marginLeft:'-43px'}}> {dots} </ul>
            </FlexRowStart>
        ),
        dotsClass: 'dots_custom'
    }
    return (
        <FlexColumn className={styles.mainBox} style={{padding:'5px 20px'}}>
            <div style={{width:'100%'}}>
                <Slider {...settings}>
                    {/*<div style={{width:'100%'}}>*/}
                    {/*    <FlexRowSpace  onClick={()=>{window.open('https://metacarbon.net/')}}>*/}
                    {/*        <FlexRowStart style={{width:'70%'}}>*/}
                    {/*            <FlexColumn>*/}
                    {/*                <div className={styles.txt15_600_B03}>{t("지글 X 미스트 선착순 에어드랍")}</div>*/}
                    {/*                <div className={styles.txt13_B04}>{t("미스트와 함께 하는 오픈 이벤트")}</div>*/}
                    {/*            </FlexColumn>*/}
                    {/*        </FlexRowStart>*/}
                    {/*        <FlexRowEnd style={{width:'30%'}}>*/}
                    {/*            <img src={require('../../assets/imges/banner/top_banner1.png')} width={78} />*/}
                    {/*        </FlexRowEnd>*/}
                    {/*    </FlexRowSpace>*/}
                    {/*</div>*/}
                    <div style={{width:'100%'}}>
                        <FlexRowSpace onClick={()=>{navigate('/ref')}}>
                            <FlexRowStart style={{width:'70%'}}>
                                <FlexColumn>
                                    <div className={styles.txt15_600_B03}>{t("지글 초대하고 포인트받기")}</div>
                                    <div className={styles.txt13_B04}>{t("초대링크를 통해 초대후 포인트 받기")}</div>
                                </FlexColumn>
                            </FlexRowStart>
                            <FlexRowEnd style={{width:'30%'}}>
                                <img src={require('../../assets/imges/img_188.png')} width={78} />
                            </FlexRowEnd>
                        </FlexRowSpace>
                    </div>
                </Slider>
            </div>
        </FlexColumn>
    )
}