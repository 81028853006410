import React, {CSSProperties, useEffect, useState} from "react";

import {inspect} from "util";
import styles from "../../common/css/zigle.module.scss";
import BottomLayer, { FlexBottomButton } from "../common/container/BottomLayer";
import {
    FlexColumEnd,
    FlexColumn,
    FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart
} from "../common/container/PageContainer";
import BottomButton from "../common/button/BottomButton";
import {useSelector} from "react-redux";
import MetaMaskService from "../../services/metamask.service";
import CommonService from "../../services/common.service";
import Button34 from "../common/button/Button34";
import {Toast} from "../common/popup/ToastAlert";
import CopyToClipboard from "react-copy-to-clipboard";
import moment from "moment";
import EmptyContent from "../common/EmptyContent";
import { maskingEmail } from "../../common/js/utilities";
import {useTranslation} from "react-i18next";

interface IRefPopup {
    show: boolean;
    setShow: any;
    refList: any[];
    refCnt: number;
}
function RefPopup(props:IRefPopup) {
    const { t } = useTranslation();

    let monthDay = "";
    let monthResult = "";

    return (
        <>
            {
                props.show && (
                    <BottomLayer  show={props.show}
                         setShow={props.setShow}
                         title={t("초대내역")}
                         content={
                             (
                                 <FlexColumn gap={22} style={{
                                     paddingTop: '32px',
                                     paddingBottom: '100px',
                                     height: '600px',
                                     overflow: 'scroll'
                                 }}>
                                     <FlexColumn gap={24} style={{marginBottom:"auto"}}>
                                         {
                                             props.refList.length > 0 && props.refCnt > 0 ? (
                                                 props.refList.map((item, index) => {
                                                     let tmpDay = moment(item.regDate).format('MM-DD');

                                                     if ((tmpDay) && (monthDay != tmpDay)) {
                                                         monthResult = "Y";
                                                         monthDay = tmpDay;
                                                     } else {
                                                         monthResult = "N";
                                                     }

                                                     return (
                                                         <FlexColumn gap={20} key={item.regDate}>
                                                             {
                                                                 monthResult === "Y" &&
                                                                 (
                                                                     index !== 0 ?
                                                                         (
                                                                             <FlexColumn gap={24}>
                                                                                 <FlexColumn
                                                                                     className={styles.line}></FlexColumn>
                                                                                 <FlexColumn
                                                                                     className={styles.txt13_B04}>{moment(item.regDate).format('MM월DD일')}</FlexColumn>
                                                                             </FlexColumn>
                                                                         )
                                                                         :
                                                                         (<FlexColumn
                                                                             className={styles.txt13_B04}>{moment(item.regDate).format('MM월DD일')}</FlexColumn>)
                                                                 )
                                                             }
                                                             <FlexRowSpace>
                                                                 <FlexRowStart style={{height: '40px'}}
                                                                               className={styles.txt16_B04}>
                                                                     <FlexColumn gap={2}>
                                                                         <div>{item.doname ? item.doname : maskingEmail(item.email)}</div>
                                                                         <div
                                                                             className={styles.txt12_W03}>{moment(item.regDate).format('HH:mm')}</div>
                                                                     </FlexColumn>
                                                                 </FlexRowStart>
                                                                 <FlexRowEnd>
                                                                     <FlexColumEnd gap={2}>
                                                                         <div>+{item.ziglePoint}</div>
                                                                         <div
                                                                             className={styles.txt12_W03}>{moment(item.regDate).format('YYYY.MM.DD')} {t("가입")}
                                                                         </div>
                                                                     </FlexColumEnd>
                                                                 </FlexRowEnd>
                                                             </FlexRowSpace>
                                                         </FlexColumn>
                                                     )
                                                 })
                                             ) : (
                                                 <EmptyContent titleText={t('초대 내역이 없습니다.')}
                                                               titleImage={require('../../assets/imges/list_x.png')}/>
                                             )
                                         }
                                     </FlexColumn>
                                 </FlexColumn>
                             )
                         }/>
                )
            }
            </>
    );
}

export default RefPopup;
