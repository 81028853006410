import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import {useForm} from "react-hook-form";
import AssetService from "../../services/asset.service";
import PopupService from "../../services/popup.service";
import {
    FlexBottom,
    FlexColumn,
    FlexRowAround,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart
} from "../common/container/PageContainer";
import DefaultInput from "../common/input/DefaultInput";
import styles from "../../common/css/zigle.module.scss";
import {TextField} from "@material-ui/core";
import BottomButton from "../common/button/BottomButton";
import styled from "styled-components";
import MetaMaskService from "../../services/metamask.service";
import {useSelector} from "react-redux";
import {useEthersSigner, useWalletEthersPorvider} from "../../common/js/ethersProviderHook";
import {Toast, ToastError} from "../common/popup/ToastAlert";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {ellipseAddress} from "../../common/js/utilities";
import {useTranslation} from "react-i18next";

const HidddenInputBox = styled.input`
  display: none;
`;
interface ITokenWriteProps {
    setPageMode?: any;
}
export default function CoinWrite(props:ITokenWriteProps) {
    const { t } = useTranslation();

    const inputRef = useRef<HTMLFormElement>(null);
    const [formValue, setFormValue] = useState({
        checkConfirm: false,
        coinName : '',
        coinSymbol : 'ABC',
        coinDecimal : 18,
        txId:''
    })
    const [mainNet, setMainNet] = useState<any>({});
    
    const chainId = useSelector((state:any) => state.walletReducer.chainId);
    const mainNetDetail = useSelector((state: any) => state.walletReducer.mainNetDetail);
    const signer = useEthersSigner();
    const {
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit,
        setError,
        clearErrors,
    } = useForm();

    const zigleWalletAddress = process.env.REACT_APP_WALLET_ADDRESS;

    const handleSymbolImageUploader = () => {
        inputRef.current?.symbolImageFile.click();
    };

    const [btnActive, setBtnActive] = useState(true);
    const navigate = useNavigate();
    const onSubmit = (data:any) => {
        if(!formValue.checkConfirm){
            setError("coinContract", {type:'custom', message:t('Contract를 확인해 주세요.')});
            return false;
        }
        const params = new FormData();
        params.append('mainnetId', mainNet.mainnetId);
        params.append('contract', data.coinContract);
        params.append('coinName', data.coinName);
        params.append('symbolName', formValue.coinSymbol);
        params.append('decimal', formValue.coinDecimal.toString());
        params.append('symbolImg', data.symbolImageFile[0]);
        params.append('typeCode', 'TOKEN');
        params.append('desc', formValue.txId);

        AssetService.coinInsert(params).then(
            (res) => {
                // PopAlert('', ()=>navigate("/findcoin") ,t('coinList.write.submitOkTitle'));
                console.log("res",res)
                if(res.status){
                    PopupService.newPopup(t("Coin 추가 요청 성공")
                        , t("Coin 추가 요청을 성공했어요.")
                        , ""
                        ,{btnTxt:t("확인 했어요"),
                            onClick: () => {
                                props.setPageMode('list');
                            }}
                        , {}
                    );
                }
            }
        )

    };

    const onClickCoinConfirm = (coinContract:string) =>{
        if (signer != null) {
            MetaMaskService.checkTokenProfile(signer, chainId, coinContract).then(
                (response) => {
                    // setCoinName(response.name);
                    // setCoinSymbol(response.symbol);
                    // setCoinDecimal(response.decimal);

                    setFormValue({
                        ...formValue,
                        checkConfirm: true,
                        coinName : response.name,
                        coinSymbol: response.symbol,
                        coinDecimal: response.decimal
                    })
                    let el = inputRef.current?.coinName;
                    el.focus();
                },
                (error) => {
                    console.log("error",error);
                    ToastError(t("해당 메인넷과 컨트랙트가 맞지 않습니다."));
                    const _content =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();

                }
            )
        }
    }
    useEffect(() => {
        const fetchMainnet = async () => {
            let data = await mainNetDetail;
            setMainNet(data);
        };
        if (mainNetDetail) {
            fetchMainnet();
        }
    }, [mainNetDetail]);

    useEffect(()=>{
        if(isDirty === true && isValid === true)
        {
            setBtnActive(true);
        }
    },[isDirty, isValid, errors])
    // @ts-ignore
    return (
        <FlexColumn>
            <FlexColumn>
                <form ref={inputRef} onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                    <FlexRowAround>
                        <div style={{width:'100%'}}>
                            <DefaultInput
                                lable={t("컨트렉트")}
                                error={errors.coinContract}
                                icon={require("../../assets/icons/input_x.png")}
                                iconClick={() => {
                                    let el = inputRef.current?.coinContract;
                                    el.value ='';
                                    clearErrors("coinContract");
                                }}

                                inputElement={
                                    <input
                                        type={"text"}
                                        className={styles.inputText_44}
                                        placeholder={t("컨트렉트를 입력해주세요.")}
                                        {...register("coinContract", {
                                            required: t("컨트렉트를 입력해주세요.")
                                        })}
                                        onChange={(e)=>{
                                            clearErrors("coinContract");
                                            setFormValue({
                                                ...formValue,
                                                checkConfirm: false,
                                            })}}
                                    />
                                }
                            />
                        </div>
                        <div style={{width:'30%', height:'50px'}}>
                            <div className={styles.img_btn}  onClick={() => {
                                let el = inputRef.current?.coinContract;
                                onClickCoinConfirm(el.value)
                            }}>
                                {t("확인")}
                            </div>
                        </div>
                    </FlexRowAround>
                    <DefaultInput
                        lable={t("토큰 이름")}
                        error={errors.coinName}
                        icon={require("../../assets/icons/input_x.png")}
                        iconClick={() => {
                            let el = inputRef.current?.coinName;
                            el.value ='';
                        }}
                        inputElement={
                            <input
                                type={"text"}
                                className={styles.inputText_44}
                                placeholder={t("토큰 이름을 입력해주세요.")}
                                defaultValue={formValue.coinName}
                                {...register("coinName", {
                                    required: t("토큰 이름을 입력해주세요.")
                                })}
                                onChange={(e)=>{
                                    setFormValue({
                                        ...formValue,
                                    coinName: e.target.value
                                })}}
                            />
                        }
                    />
                    <DefaultInput
                        lable={t("데시멀")}
                        error={errors.coinDecimal}
                        inputElement={
                            <input
                                type={"number"}
                                className={styles.inputText_44}
                                value={formValue.coinDecimal}
                                disabled={true}
                            />
                        }
                    />
                    <FlexRowAround>
                        <div style={{width:'100%'}}>
                            <DefaultInput
                                lable={t("토큰 심볼")}
                                error={errors.coinSymbol}
                                inputElement={
                                    <input
                                        type={"text"}
                                        className={styles.inputText_44}
                                        value={formValue.coinSymbol}
                                        disabled={true}
                                    />
                                }
                            />
                        </div>
                        <div style={{width:'30%', height:'50px'}}>

                            <HidddenInputBox
                                type={"file"}
                                {...register("symbolImageFile", {
                                    required: t("파일을 추가해주세요.")
                                })}
                            />
                            <div className={styles.img_btn}  onClick={() => {handleSymbolImageUploader();}}>
                                {t("이미지 첨부")}
                            </div>
                            {errors?.symbolImageFile && (
                                <div
                                    className={styles.txt13_red}
                                    style={{ marginTop: "4px", marginBottom: "4px" }}
                                >{errors.symbolImageFile.message?.toString()}
                                </div>
                            )}
                        </div>
                    </FlexRowAround>
                    {
                        mainNet.coinFee > 0 && (
                            <FlexColumn gap={32}>
                                <FlexColumn gap={24}>
                                    <FlexColumn gap={8}>
                                        <FlexRowSpace className={styles.select_mainnet} style={{height:'58px'}}>
                                            <FlexRowStart>{ellipseAddress(zigleWalletAddress!, 5)}</FlexRowStart>
                                            <FlexRowEnd>
                                                <CopyToClipboard text={zigleWalletAddress!} onCopy={() => Toast(t("주소를 복사 했습니다."))}>
                                                    <img src={require("../../assets/icons/copy.png")} width={20} alt={"copy"} />
                                                </CopyToClipboard>
                                            </FlexRowEnd>
                                        </FlexRowSpace>
                                        <FlexRowStart className={styles.txt13_B05} gap={4}><img src={require('../../assets/imges/exclamation.png')} width={20} />{t("위 주소로 0.1ETH를 보낸 후 TXID를 첨부해주세요.")}</FlexRowStart>
                                    </FlexColumn>
                                    <DefaultInput
                                        lable={"TXID"}
                                        error={errors.txId}
                                        inputElement={
                                            <input
                                                type={"text"}
                                                className={styles.inputText_44}
                                                placeholder={t("TXID를 첨부해주세요.")}
                                                defaultValue={formValue.txId}
                                                {...register("txId", {
                                                    required: t("TXID를 첨부해주세요.")
                                                })}
                                                onChange={(e)=>{
                                                    setFormValue({
                                                        ...formValue,
                                                        txId: e.target.value
                                                    })}}
                                            />
                                        }
                                    />
                                </FlexColumn>
                                <FlexColumn className={styles.txt13_B05} style={{padding:'20px', borderRadius:'12px', background:'#F8F9FC'}} gap={4}>
                                    {t("관리자의 검수 후 추가됩니다. 추가된 토큰이 불법적으로 사용되거나 다른 사람에게 유무형의 피해를 야기한 경우 또는 관리자의 재량에 따라 예고 없이 지원 중단 될 수 있습니다.")}
                                </FlexColumn>
                            </FlexColumn>
                        )
                    }
                </form>
            </FlexColumn>
            <FlexBottom>
                <BottomButton
                    isActive={btnActive}
                    backgroundColor={"#3a71ff"}
                    textColor={"white"}
                    // border={"solid 1px #dbdee6"}
                    onClick={handleSubmit(onSubmit)}
                    text={t("다음")}
                />
            </FlexBottom>
        </FlexColumn>
    )
}