import React, { useEffect, useState } from "react";
import styles from "./common/css/zigle.module.scss";
import {useTranslation} from "react-i18next";
const PWAInstallPrompt = ({pwaVisible}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(null);

  useEffect(()=>{
    setVisible(pwaVisible)
  },[pwaVisible])

  const handleInstallClick = () => {
    window.promptEvent.prompt();
    //@ts-ignore
    window.promptEvent.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the A2HS prompt");
      } else {
        console.log("User dismissed the A2HS prompt");
      }
    });
  };

  return (
    <React.Fragment>
      {visible && <div className={styles.pwa_btn} onClick={handleInstallClick}>{t("설치하기")}</div>}
    </React.Fragment>
  );
};

export default PWAInstallPrompt;
