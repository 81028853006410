import React from "react";
import PageTitle, {FlexBottom, PageContent, PageRootContainer} from "../components/common/container/PageContainer";
import {useNavigate} from "react-router-dom";
import DefaultInput from "../components/common/input/DefaultInput";
import styles from "../common/css/zigle.module.scss";
import {useForm} from "react-hook-form";
import BottomButton from "../components/common/button/BottomButton";
import AuthService from "../services/auth.service";
import PopupService from "../services/popup.service";
import {useTranslation} from "react-i18next";

export default function EmailLoginForm(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const onSubmit = (data:any) => {
        const params = new URLSearchParams();
        AuthService.login(data.email, data.passwd).then((resData: any) => {
            if (resData.status) {
                if (sessionStorage.getItem("redirectTo")) {
                    const redirectTo = sessionStorage.getItem("redirectTo");
                    sessionStorage.setItem("redirectTo", "");
                    navigate(redirectTo ?? "/");
                } else {
                    document.location.href = '/';
                }
            } else {
                PopupService.newPopup(""
                    ,resData.msg
                    , ""
                    ,{btnTxt:t("확인 했어요"),
                        onClick: () => {

                        }}
                    , {}
                );
            }
        });
    }

    return (
        <PageRootContainer>
            <PageTitle
                textTitle={t('이메일 회원가입')}
                onClickLeft={() => {
                    navigate(-1);
                }}
            />
            <PageContent>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ width: "100%" }}
                >
                <DefaultInput
                    lable={t("이메일")}
                    inputElement={
                        <input
                            type={"text"}
                            className={styles.inputText_44}
                            placeholder={t("이메일을 입력해주세요.")}
                            {...register(
                                "email",
                                {
                                    required: t("이메일을 입력해주세요."),
                                    pattern: {
                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                                        message: t("이메일 형식을 확인해 주세요.")
                                    }
                                }
                            )
                            }
                        />
                    }
                />

                <DefaultInput
                    lable={t("패스워드")}
                    inputElement={
                        <input placeholder="password"
                               type="password"
                               {...register("passwd",
                                   {required: t("패스워드를 입력해주세요.")})} />
                    }
                />
                </form>

                <FlexBottom>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#3a71ff"}
                        textColor={"white"}
                        // border={"solid 1px #dbdee6"}
                        onClick={handleSubmit(onSubmit)}
                        icon={require("../assets/imges/login/iconEmail.png")}
                        text={t("인증이메일 발송하기")}
                    />
                </FlexBottom>
            </PageContent>
        </PageRootContainer>
    );
}