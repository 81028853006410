import React, {useEffect, useRef, useState} from "react";
import styles from "../common/css/zigle.module.scss";

import PageTitle, {
    FlexBottom,
    FlexColumn, FlexColumnCenter,
    FlexRowAround, FlexRowEnd, FlexRowSpace, FlexRowStart,
    PageContent,
    PageRootContainer,
} from "../components/common/container/PageContainer";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import MetaMaskService from "../services/metamask.service";
import bigDecimal from "js-big-decimal";
import {useAccount} from "wagmi";
import DefaultInput from "../components/common/input/DefaultInput";
import {useForm} from "react-hook-form";
import CopyToClipboard from "react-copy-to-clipboard";
import {Toast} from "../components/common/popup/ToastAlert";
import {ellipseAddress} from "../common/js/utilities";
import BottomButton from "../components/common/button/BottomButton";

export default function ZethPay(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { address, isConnected } = useAccount();
    const [modeType, setModeType] = useState('B');
    const [btnActive, setBtnActive] = useState(false);

    const [balance, setBalance] = useState(0.0);
    const [zBalance, setZbalance] = useState(0.0);

    const [borderBottom, setBorderBottom] = useState({height:'44px', paddingBlock:'1px', borderBottom:'solid 1px #f2f4f8'})
    const wEthConAddr = useRef('0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2');
    const inputRef = useRef<HTMLFormElement>(null);
    const {
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit,
    } = useForm();

    useEffect(()=>{
        MetaMaskService.getBalance(address, wEthConAddr.current).then((res) => { setBalance(Number(res)); });
    },[]);
    return (
        <PageRootContainer>
            <PageTitle textTitle={'0.1534 zEth'} onClickLeft={()=>{navigate(-1);}}/>
            <PageContent>
                <FlexColumn gap={84}>
                    <FlexColumn gap={20}>
                        <FlexColumn>
                            <FlexRowStart gap={15} >
                                <div className={ modeType === 'B' ? styles.topTabActive : styles.topTabDefault} onClick={()=>{setModeType('B'); }}>{t('구매')}</div>
                                <div className={ modeType === 'S' ? styles.topTabActive : styles.topTabDefault} onClick={()=>{setModeType('S'); }}>{t("판매")}</div>
                            </FlexRowStart>
                            <div className={styles.line} style={{width: 'calc(100% + 40px)', marginLeft:'-20px'}}/>
                        </FlexColumn>
                        <FlexColumn gap={9}>
                            <DefaultInput
                                error={errors.wETH}
                                marginBottom={1}
                                inputElement={
                                <>
                                    <input
                                        type={"text"}
                                        className={styles.inputText_44}
                                        placeholder={t("수량입력")}
                                        {...register("wETH", {
                                            required: t("수량을 입력해주세요."),
                                            onChange:(el) =>{
                                                setZbalance(el.target.value)
                                            }
                                        })}
                                        onFocus={()=>{setBorderBottom({height:'44px', paddingBlock:'1px', borderBottom:'solid 1px #3a71ff'})}}
                                        onBlur={()=>{setBorderBottom({height:'44px', paddingBlock:'1px', borderBottom:'solid 1px #f2f4f8'})}}
                                    />
                                    <div className={styles.txt20_B01} style={borderBottom}>wETH</div>
                                </>
                                }
                            />
                            <div className={styles.txt11_B04} style={{borderRadius:'8px', backgroundColor:'#F2F4F6', padding:'8px 12px'}}>
                                {t("사용 가능 {0}", {0:'3,235.12 wETH'})}
                            </div>
                        </FlexColumn>
                        <FlexColumnCenter>
                            <img src={require('../assets/imges/chat/arrow_bottom_pay.png')} width={28} />
                        </FlexColumnCenter>
                        <FlexColumn gap={9}>
                            <DefaultInput
                                error={errors.zETH}
                                marginBottom={1}
                                inputElement={
                                    <>
                                        <input
                                            type={"text"}
                                            className={styles.inputText_44}
                                            placeholder={t("0")}
                                            readOnly={true}
                                            value={zBalance}
                                            {...register("zETH", {
                                            })}
                                        />
                                        <div className={styles.txt20_B01} style={{height:'44px', paddingBlock:'1px', borderBottom:'solid 1px #f2f4f8'}}>zETH</div>
                                    </>
                                }
                            />
                            <FlexRowEnd className={styles.txt12_B04}>
                                {t("교환 비율 1 : 1")}
                            </FlexRowEnd>
                        </FlexColumn>
                    </FlexColumn>
                    <FlexColumn style={{background:'#F8F9FC', borderRadius:'12px', padding:'16px'}} gap={10}>
                        <FlexRowSpace>
                            <FlexRowStart className={styles.txt14_B05}>{t('수수료')}</FlexRowStart>
                            <FlexRowEnd className={styles.txt14_B03}>0.1%</FlexRowEnd>
                        </FlexRowSpace>
                        <FlexRowSpace>
                            <FlexRowStart className={styles.txt14_B05}>{t('내 지갑')}</FlexRowStart>
                            <FlexRowEnd className={styles.txt14_B04}>{ellipseAddress(address, 5)}</FlexRowEnd>
                        </FlexRowSpace>
                    </FlexColumn>
                </FlexColumn>
                <FlexBottom>
                    <BottomButton
                        isActive={btnActive}
                        backgroundColor={"#f2f4f6"}
                        textColor={"#37191A"}
                        text={t("교환하기")}
                        onClick={() => {

                        }}
                    />
                </FlexBottom>
            </PageContent>
        </PageRootContainer>
    )
}