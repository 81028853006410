import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import {useInView} from "react-intersection-observer";
import styles from "../../common/css/zigle.module.scss";
import {Box} from "../../pages/Profile";
import {FlexColumn, FlexColumnCenter, FlexRowCenter} from "../common/container/PageContainer";
import RcvHisItem from "../profile/RcvHisItem";
import EmptyContent from "../common/EmptyContent";
import MiningService from "../../services/mining.service";
import MiningHisItem from "./MiningHisItem";
import {decimalFormat} from "../../common/js/utilities";


export default function MiningHis(){
    const { t } = useTranslation();
    const [list, setList] = useState<any[]>([])

    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [currPage, setCurrPage] = useState(1);
    const [blockSize] = useState(10);
    const totalPoint = useRef(0);
    let monthDay = "";
    let monthResult = "";

    useEffect(() => {
        if (currPage === 1) {
            setList([]);
        }

        MiningService.miningList('I', currPage, blockSize).then((data) => {
            if (!data.data.length) {
                setLastList(true);
                return;
            }
            data.data.map((item:any)=>{
                totalPoint.current = totalPoint.current + item.amount;
            });
            setList([...list, ...data.data]);
        });
    }, [currPage]);

    useEffect(() => {
        if (inView && lastList) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ lastList, inView]);

    return (
        <Box gap={24}>
            <FlexRowCenter gap={20} style={{padding:'16px 20px', borderRadius:'12px', background:'#f2F4F6'}}>
                <div className={styles.txt14_B05}>{("총 보상")}</div>
                <div className={styles.txt15_PointB}>{decimalFormat(totalPoint.current, 4)}<span className={styles.txt14_B05}>ZIG</span></div>
                </FlexRowCenter>
            <FlexColumn gap={40}>
                {
                    list.length > 0 ? (
                            list.map((item,index)=>{
                                let tmpDay = new Date(item.modDtm).getMonth()+1 +"월 "+new Date(item.modDtm).getDate()+"일";

                                if((tmpDay) && (monthDay != tmpDay)){
                                    monthResult = "Y";
                                    monthDay = tmpDay;
                                }else{
                                    monthResult = "N";
                                }

                                return (
                                    <MiningHisItem  key={item.hist_id}
                                                 item={item}
                                                 index={index}
                                                 monthResult={monthResult}/>
                                );
                            })
                        )
                        :
                        (
                            <EmptyContent titleText={t('보상 내역이 없습니다.')}  titleImage={require('../../assets/imges/list_x.png')}/>
                        )

                }
                {
                    Object.values(list).length >= blockSize ? (
                        <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) : null
                }
            </FlexColumn>
        </Box>
    );
};