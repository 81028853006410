import React, {useEffect, useRef, useState} from "react";
import styles from "../common/css/zigle.module.scss";

import PageTitle, {
    EMPTY_SRC,
    FlexBottom,
    FlexColumn, FlexColumnCenter,
    FlexRowAround, FlexRowSpace, GrayBox,
    PageContent,
    PageRootContainer,
} from "../components/common/container/PageContainer";
import BottomButton from "../components/common/button/BottomButton";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import { useForm } from "react-hook-form";
import DefaultInput from "../components/common/input/DefaultInput";
import KrwSelect from "../components/ziglecharge/KrwSelect";
import UsdSelect from "../components/ziglecharge/UseSelect";
import TokenSelect from "../components/ziglecharge/TokenSelect";
import PayDepth1 from "../components/ziglecharge/PayDepth1";
import PayDepth2 from "../components/ziglecharge/PayDepth2";
import PayDepthEnd from "../components/ziglecharge/PayDepthEnd";
import {PayType_Map} from "./MyPoint";
import PayDepthCryptoEnd from "../components/ziglecharge/PayDepthCryptoEnd";
import {useTranslation} from "react-i18next";
import {Bar, BlueBox, Box} from "./MyLink";

function Hp() {
    const [postBody, setPostBody] = useState<HTMLHtmlElement>();

    const navigate = useNavigate();
    const {t} = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    let { accountNum, bankNm } = useParams<{accountNum: string, bankNm: string}>();


    return (
        <PageRootContainer>
            <PageTitle leftSrc={EMPTY_SRC} rightSrc={require('../assets/icons/close_28.png')} onClickRight={() => {
                navigate("/");
            }}/>
            <PageContent>
                <FlexColumn style={{height:'124px'}} gap={4}>
                    <FlexColumnCenter>
                        <img src={require('../assets/icons/confirm.png')} width={64}/>
                    </FlexColumnCenter>
                    <FlexColumnCenter className={styles.txt20_B01} style={{marginBottom:'20px'}}>
                        <div>포인트 충전을</div>
                        <div><strong>신청</strong> 하였습니다</div>
                    </FlexColumnCenter>
                </FlexColumn>
                <Bar/>
                <FlexColumn style={{margin:'28px 0 28px 0'}} gap={10}  className={styles.txt14_B03}>
                    <FlexRowSpace>
                        <div>입금은행</div>
                        <div>{searchParams.get("bankNm")}</div>
                    </FlexRowSpace>
                    <FlexRowSpace>
                        <div>계좌번호</div>
                        <div>{searchParams.get("accountNum")}</div>
                    </FlexRowSpace>
                    <FlexRowSpace>
                        <div>입금금액</div>
                        <div>{searchParams.get("amount")} 원</div>
                    </FlexRowSpace>
                    <FlexRowSpace>
                        <div>입금마감일</div>
                        <div> {searchParams.get("expireDate")}</div>
                    </FlexRowSpace>
                </FlexColumn>
                <Bar/>
                <FlexColumn style={{margin:'28px 0 28px 0'}} gap={10}  className={styles.txt14_B03}>
                    <FlexRowSpace>
                        <div>충전 전</div>
                        <div>0 P</div>
                    </FlexRowSpace>
                    <FlexRowSpace>
                        <div>충전 예정</div>
                        <div>{searchParams.get("amount")} P</div>
                    </FlexRowSpace>
                    <FlexRowSpace>
                        <div className={styles.txt18_700_B01}>충전후</div>
                        <div className={styles.txt18_500_Point_B}>{searchParams.get("amount")} P</div>
                    </FlexRowSpace>
                </FlexColumn>
                <GrayBox className={styles.txt12_B05}>
                    [포인트 내역]에서 해당 결제에 대한 정보를 관리 하실 수 있습니다.
                </GrayBox>
            </PageContent>
        </PageRootContainer>
    );
}


export default Hp;