import PageTitle, {
    FlexColumEnd,
    FlexColumn, FlexColumnCenter, FlexRowCenter, FlexRowEnd,
    FlexRowSpace, FlexRowStart,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import React, {useEffect, useRef, useState} from "react";
import styles from "../common/css/zigle.module.scss";
import {useNavigate} from "react-router-dom";
import {Bar} from "./MyLink";
import ProgressBar from "../components/airdrop/ProgressBar";
import DefaultInput from "../components/common/input/DefaultInput";
import {useForm} from "react-hook-form";
import IMG_0206 from "../assets/imges/ido/IMG_0206.png";
import CommonService from "../services/common.service";
import {decimalFormat} from "../common/js/utilities";
import moment from "moment";
import {Trans, useTranslation} from "react-i18next";
interface resData {
    amount : string;
    dropAmount: number;
    dropPercent: number;
    txDatetime: string;
    txId: string;
}
export default function Ido(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const totalMist = useRef(100000000);
    const [viewTime, setViewTime] = useState('0');
    const [diffDays, setDiffDays] = useState('0');
    const [diffHours, setDiffHours] = useState('0');
    const [diffMin, setDiffMin] = useState('0');
    const [diffSec, setDiffSec] = useState('0');

    const [content, setContent] = useState({
        totalCnt : 0,
        totalAmount : 0,
        totalMist:0,
        resultDate : ''
    });
    const [product, setProduct] = useState({
        dropAmount: 0,
        dropPercent : 0,
        startDate : '',
        totalSum:0
    });

    const {
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit,
    } = useForm();

    const onSubmit = (data: any) => {

        CommonService.getMistList(data.walletAddress).then((res)=>{
            let sumAmount = 0;
            let sumMist = 0;
            let resultDate = '';
            let totalCnt = 0;
            if(res.data.length > 0)
            {
                totalCnt = res.data.length;
                resultDate = res.data[totalCnt -1].txDatetime;
                res.data.map((item:resData)=>{
                    sumAmount += Number(item.amount);
                    sumMist += item.dropAmount;
                    console.log(item.txId)
                });
            }
            console.log("sumAmount",sumAmount)
            console.log("sumMist",sumMist)
            setContent({
                totalCnt:totalCnt,
                totalAmount : sumAmount,
                totalMist : sumMist,
                resultDate : resultDate
            })
        });
    }
    useEffect(()=>{
        CommonService.getProduct('mistCoin').then(res=>{
            setProduct(res.data);
            var getCurrentTime = moment();
            var targetTime = moment(res.data.startDate);
            var getCurrentTimeUnix = getCurrentTime.unix();
            var targetTimeUnix = targetTime.unix();
            var leftTime = targetTimeUnix - getCurrentTimeUnix;
            if(leftTime > 0){
                var duration = moment.duration(leftTime, 'seconds');

                var interval = 1000;
                var intv = setInterval(function () {
                    duration = moment.duration(duration.asSeconds() - 1, 'seconds');
                    setDiffDays(Math.floor(duration.asMilliseconds() / (1000 * 60 * 60 * 24)).toString());
                    setDiffHours((duration.hours() < 10) ? '0' + duration.hours() : duration.hours().toString());
                    setDiffMin((duration.minutes() < 10) ? '0' + duration.minutes() : duration.minutes().toString());
                    setDiffSec((duration.seconds() < 10) ? '0' + duration.seconds() : duration.seconds().toString());

                }, interval);
            }
        });

    },[])
    return (
        <PageRootContainer style={{backgroundColor:'#000000'}}>
            <PageTitle leftSrc={require('../assets/imges/back_arrow_black.png')} onClickLeft={()=>{ navigate(-1);}} thema={'black'}/>
            <PageContent className={styles.txt32_800_W} style={{paddingBottom:'50px'}}>
                <FlexColumn gap={40}>
                    <FlexColumnCenter className={styles.idoSpan} style={{padding:'46px 70px', textAlign:'center', background:'linear-gradient(90deg, rgba(95, 95, 95, 0.00) 0%, rgba(95, 95, 95, 0.50) 50.52%, rgba(95, 95, 95, 0.00) 100%)'}}>
                        <Trans t={t} i18nKey={"전세계 최초<span style={{color:'#5F5F5F'}}>로</span><br/>대한민국<span style={{color:'#5F5F5F'}}>에서</span><br/>제네시스 마이닝<span style={{color:'#5F5F5F'}}>이</span><br/>시작<span style={{color:'#5F5F5F'}}>됩니다.</span>"}/>
                    </FlexColumnCenter>
                    <FlexColumn gap={44} style={{paddingLeft:'15px', fontSize:'28px'}}>
                        <FlexColumn>
                            <div style={{color:'#5F5F5F'}}>{t("MIST COIN")}</div>
                            <div>https://metacarbon.net</div>
                        </FlexColumn>
                        <FlexColumn>
                            <div style={{color:'#5F5F5F'}}>{t('제네시스 마이닝 한정수량')}</div>
                            <div>100,000{t('개')}</div>
                        </FlexColumn>
                        <FlexColumn>
                            <div style={{color:'#5F5F5F'}}>{t("혜택")}</div>
                            <div>{t("200% 추가 마이닝")}</div>
                            <div style={{fontSize:'20px'}}>{t('1000 MIST + 2000 MIST(추가)')}</div>
                        </FlexColumn>
                    </FlexColumn>
                    <FlexColumnCenter gap={20}>
                        <div className={styles.txt24_800_W}>{t('Vape 구매 입금 링크')}</div>
                        <FlexColumnCenter gap={5}>
                            <div className={styles.txt16} onClick={()=>{navigate('/l/MistVape')}}>{window.location.protocol + "//" + window.location.host+"/l/MistVape"}</div>
                            <div style={{ fontSize:'10px'}} >{t("지글링크 입금 후 메모란에 수량, 이름, 연락처, 주소 기재")}</div>
                        </FlexColumnCenter>
                    </FlexColumnCenter>
                    <FlexColumnCenter style={{paddingTop:'20px'}} gap={40}>
                        <img src={require('../assets/imges/ido/mist_graph.578960df90e210a033e1.png')} width={300} />
                        <FlexColumn style={{fontSize:'14px'}} gap={8}>
                            <FlexRowStart gap={8}><div style={{width:'14px', height:'14px', borderRadius:'2px', background:'#404693'}} />{t("Mining 75%")}</FlexRowStart>
                            <FlexRowStart gap={8}><div style={{width:'14px', height:'14px', borderRadius:'2px', background:'#723FE0'}} />{t('TEAM 10% ( Lock 2 Year )')}</FlexRowStart>
                            <FlexRowStart gap={8}><div style={{width:'14px', height:'14px', borderRadius:'2px', background:'#BD89FF'}} />{t('Marketing 5%')}</FlexRowStart>
                            <FlexRowStart gap={8}><div style={{width:'14px', height:'14px', borderRadius:'2px', background:'#707AEA'}} />{t("Seed 9% ( Lock 1 Year ) – 0.005$")}</FlexRowStart>
                            <FlexRowStart gap={8}><div style={{width:'14px', height:'14px', borderRadius:'2px', background:'#E6D2FF'}} />{t("IDO 1% ( No Lock ) – 0.01$")}</FlexRowStart>
                        </FlexColumn>
                    </FlexColumnCenter>
                    <FlexColumnCenter gap={20}>
                        <div className={styles.txt24_800_W}>{t("토큰세일 참여 링크")}</div>
                        <FlexColumnCenter gap={5}>
                            <div className={styles.txt16} onClick={()=>{navigate('/l/MistCoin')}}>{window.location.protocol + "//" + window.location.host+"/l/MistCoin"}</div>
                            <div style={{ fontSize:'10px'}} >{t("지글링크 입금 후 메모란에 수량, 이름, 연락처, 주소 기재")}</div>
                        </FlexColumnCenter>
                    </FlexColumnCenter>

                    <FlexColumn gap={24}>

                        <FlexRowCenter>
                            <FlexColumnCenter>
                                <div style={{fontSize:'36px'}}>{diffDays}</div>
                                <div style={{fontSize:'14px', color:'#5F5F5F'}}>DAYS</div>
                            </FlexColumnCenter>
                            <div style={{width:'1px', height:'50px', backgroundColor:'#5F5F5F'}} />
                            <FlexColumnCenter>
                                <div style={{fontSize:'36px'}}>{diffHours}</div>
                                <div style={{fontSize:'14px', color:'#5F5F5F'}}>HOURS</div>
                            </FlexColumnCenter>
                            <div style={{width:'1px', height:'50px', backgroundColor:'#5F5F5F'}} />
                            <FlexColumnCenter>
                                <div style={{fontSize:'36px'}}>{diffMin}</div>
                                <div style={{fontSize:'14px', color:'#5F5F5F'}}>MIN</div>
                            </FlexColumnCenter>
                            <div style={{width:'1px', height:'50px', backgroundColor:'#5F5F5F'}} />
                            <FlexColumnCenter>
                                <div style={{fontSize:'36px'}}>{diffSec}</div>
                                <div style={{fontSize:'14px', color:'#5F5F5F'}}>SEC</div>
                            </FlexColumnCenter>
                        </FlexRowCenter>
                        <ProgressBar border={"none"} containerBgcolor={"#1F1F1F"} containerPadding={0} fillerBgcolor={"#723FE0"} completed={(product.dropAmount / totalMist.current) * 100}  fillerHeight={50} leftLabelValue={(product.dropAmount / totalMist.current) * 100} />
                        <FlexColumnCenter className={styles.txt14_B04}>
                            <div><span style={{color:'#FFFFFF'}}>{decimalFormat(product.dropAmount)}</span> / {decimalFormat(totalMist.current)} <span style={{color:'#FFFFFF'}}>MIST</span></div>
                        </FlexColumnCenter>
                    </FlexColumn>
                    <FlexColumn gap={120}>
                        <FlexColumn gap={20} style={{paddingLeft:'30px', fontSize:'20px', fontWeight:'900', backgroundRepeat:'no-repeat',  backgroundImage:'url(/assets/image/Asset.png)', backgroundSize:'100%', padding:'10px 20px'}}>
                            <FlexColumn gap={3}>
                                <div className={styles.txt12_W03}>{t("교환비율")}</div>
                                <FlexColumn>
                                    <div>1 ETH = {decimalFormat(product.dropPercent)} MIST</div>
                                    <div style={{fontSize:'20px'}}>1MIST = 0.01$</div>
                                </FlexColumn>
                            </FlexColumn>
                            <FlexColumn gap={3}>
                                <div className={styles.txt12_W03}>{t("분배")}</div>
                                <div>{t("No Lock(상장전 분배)")}</div>
                            </FlexColumn>
                            <FlexColumn gap={3}>
                                <div className={styles.txt12_W03}>Hardcap</div>
                                <div>1,000,000$</div>
                            </FlexColumn>
                            <FlexColumn gap={3}>
                                <div className={styles.txt12_W03}>Amount</div>
                                <div>{decimalFormat(totalMist.current)} MIST</div>
                            </FlexColumn>
                            <FlexColumn gap={3}>
                                <div className={styles.txt12_W03}>{t("상장 PRICE")}</div>
                                <div>0.02$</div>
                            </FlexColumn>
                            <FlexColumn gap={3}>
                                <div className={styles.txt12_W03}>{t("상장 초기 유통량")}</div>
                                <div>140,000,000 MIST</div>
                            </FlexColumn>
                            <FlexColumn gap={3}>
                                <div className={styles.txt12_W03}>Airdrop</div>
                                <div>10,000,000 MIST</div>
                            </FlexColumn>
                            <FlexColumn gap={3}>
                                <div className={styles.txt12_W03}>IDO</div>
                                <div>100,000,000 MIST</div>
                            </FlexColumn>
                            <FlexColumn gap={3}>
                                <div className={styles.txt12_W03}>Mining</div>
                                <div>30,000,000 MIST</div>
                            </FlexColumn>
                            <FlexColumn gap={3}>
                                <div className={styles.txt12_W03}>Market Cap</div>
                                <div>2,800,000 MIST</div>
                            </FlexColumn>
                        </FlexColumn>
                        <FlexColumn gap={44}>
                            <div style={{fontSize:'28px', fontWeight:'800'}}>{t("내 참여내역 조회")}</div>
                            <FlexRowSpace>
                                <FlexRowStart style={{width:'60%'}}>
                                    <DefaultInput
                                        error={errors.walletAddress}
                                        inputElement={
                                            <input
                                                type={"text"}
                                                className={styles.inputText_44}
                                                style={{backgroundColor:'#1F1F1F', borderBottom:"none", color:'#FFFFFF'}}
                                                placeholder={t("지갑 주소를 입력해 주세요.")}
                                                {...register("walletAddress", {
                                                    required: t("지갑 주소를 입력해 주세요.")
                                                })}
                                            />
                                        }
                                    />
                                </FlexRowStart>
                                <FlexRowEnd style={{marginBottom:'24px', width:'40%'}}>
                                    <div style={{fontSize:'20px', padding:'10px 40px', border:'1px solid #FFFFFF'}}  onClick={handleSubmit(onSubmit)}>검색</div>
                                </FlexRowEnd>
                            </FlexRowSpace>
                            <FlexColumnCenter className={styles.txt14_W03} style={{border:'1px solid #5F5F5F', borderRadius:'10px', minHeight:'320px' }}>
                                {
                                    content.totalMist === 0 ? t('참여 내역을 조회해 주세요.') : (
                                        <FlexColumn gap={40} style={{padding:'30px 30px', fontSize:'28px',color:'#FFFFFF', backgroundImage:'url(/assets/image/IMG_0206.png)', backgroundSize:'100%'}}>
                                            <FlexRowEnd className={styles.txt12_W03}>{moment(content.resultDate).format('YYYY-MM-DD')}</FlexRowEnd>
                                            <div>{t("총 참여건수")} : {content.totalCnt}</div>
                                            <div className={styles.line} />
                                            <FlexColumn gap={12}>
                                                <FlexColumn>
                                                    <div style={{fontSize:'16px'}}>{t("참여수량")}</div>
                                                    <div>{decimalFormat(content.totalAmount, 0)} ETH</div>
                                                </FlexColumn>
                                                <FlexColumn>
                                                    <div style={{fontSize:'16px'}}>{t("지급 받을 수량")}</div>
                                                    <div>{decimalFormat(content.totalMist)} MIST</div>
                                                </FlexColumn>
                                            </FlexColumn>
                                        </FlexColumn>
                                    )
                                }
                            </FlexColumnCenter>
                        </FlexColumn>
                    </FlexColumn>
                </FlexColumn>
            </PageContent>
        </PageRootContainer>
    )
}