import styles from "../../common/css/zigle.module.scss";
import QRCode from "qrcode.react";
import {
    FlexBottom,
    FlexColumn,
    FlexColumnCenter, FlexRowCenter,
    FlexRowSpace,
    FlexRowStart,
} from "../common/container/PageContainer";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import CopyToClipboard from "react-copy-to-clipboard";
import {Toast, ToastError} from "../common/popup/ToastAlert";
import { Bar } from "../../pages/MyLink";
import ProgressBar from "./ProgressBar";
import DatePicker from "react-datepicker";
import CommonService from "../../services/common.service";
import moment from "moment";
import {useForm} from "react-hook-form";
import DefaultInput from "../common/input/DefaultInput";
import AirdropService from "../../services/airdrop.service";
import PopupService from "../../services/popup.service";
import MetaMaskService from "../../services/metamask.service";
import {useSelector} from "react-redux";
import {ethers} from "ethers";
import bigDecimal from "js-big-decimal";
import {decimalFormat, ellipseAddress} from "../../common/js/utilities";
import FoundationView from "./FoundationView";
import {Trans, useTranslation} from "react-i18next";

const QrArea = styled.div`
  width: 160px;
  height: 160px;
  flex-grow: 0;
  margin: 0 4px 20px;
  padding: 10.5px 13.2px 11.4px 10.5px;
  border-radius: 24px;
  border: solid 1px #d4d8e2;
`;
interface IMyAirdropViewContent {
    onClickEndViewItem: ()=> void;
    viewItem?:any;
    setAirdropFeeShow:any;
    setReLoad:any;
    reLoad:any;
}
export default function MyAirdropViewContent(props:IMyAirdropViewContent) {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState<any>('');
    const [startTime, setStartTime] = useState<any>('');
    const [airdropBlur, setAirdropBlur ] = useState(false);
    const [airdropCountDown, setAirdropCountDow ] = useState('');
    const [airdropCountDay, setAirdropCountDay] = useState('');
    const [updateService, setUpdateService] = useState(true);
    const [airdropState, setAirdropState] = useState(props.viewItem.airdropState);
    const [gasFee , setGasFee] = useState('0');
    const [showPop, setShowPop] = useState(false);

    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
    } = useForm();
    useEffect(()=>{

    },[])
    const aridropSendTransfer = async (selectItem:any)=>{

        let rpcurl = selectItem.mainnetRpcUrl;
        if(selectItem.mainnetChainId == "1")
        {
            rpcurl += process.env.REACT_APP_INFURA_KEY
        }
        const provider = new ethers.providers.JsonRpcProvider(
            rpcurl
        );
        const gas = await provider.getGasPrice();

        const tmpGas = new bigDecimal(ethers.utils.formatUnits(gas, selectItem.decimalVal)).multiply(new bigDecimal("21000")).multiply(new bigDecimal("1.3"));
        const realAirdropCnt =  selectItem.airdropCnt - selectItem.participateCount;
        const totalAirdropCnt = new bigDecimal(selectItem.participateCount).multiply(new bigDecimal(selectItem.amount));
        const ciAirdropCnt = new bigDecimal(selectItem.airdropCnt).subtract(new bigDecimal(selectItem.participateCount)).multiply(new bigDecimal(selectItem.amount));

        PopupService.newPopup(t("종료 하시겠어요?")
            ,<FlexColumnCenter style={{paddingBottom:'20px'}}><Trans t={t} i18nKey={"<div>지금 종료하면 참여한 인원 {0}명에게</div> <div>에어드랍이 지급되며</div><div>프로젝트가 완전히 종료됩니다.</div>"} values={{0:selectItem.participateCount}}/></FlexColumnCenter>
            , <FlexColumn className={styles.txt13_B05} gap={8} style={{padding:'20px', background:'#F8F9Fc', borderRadius:'12px'}}>
                <FlexRowSpace>
                    <div>{t("총 지급 수량")}</div>
                    <div className={styles.txt13_600_B}>{totalAirdropCnt.getValue()}{selectItem.coinSymbolName}</div>
                </FlexRowSpace>
                {
                    realAirdropCnt > 0 && (
                        <FlexRowSpace>
                            <div>{t("회수 수량")}</div>
                            <div className={styles.txt13_600_B}>{ciAirdropCnt.getValue()}{selectItem.coinSymbolName}</div>
                        </FlexRowSpace>
                    )
                }
                <FlexRowSpace>
                    <div>{t("예상 Gas Fee")}</div>
                    <div className={styles.txt13_300_B}>{decimalFormat(tmpGas.multiply(new bigDecimal(realAirdropCnt)).getValue() , 7)}{selectItem.mainnetSymbolName}</div>
                </FlexRowSpace>
                <FlexRowSpace>
                    <div>{t("지급 인원")}</div>
                    <div className={styles.txt13_300_B}>{selectItem.participateCount}명</div>
                </FlexRowSpace>
                <FlexRowSpace>
                    <div>{t("서비스 이용료")}</div>
                    <div className={styles.txt13_300_B}>{ realAirdropCnt > 0 ? decimalFormat(Number(selectItem.ziglePoint)*Number(selectItem.participateCount + 1), 0) : decimalFormat(Number(selectItem.ziglePoint)*Number(selectItem.participateCount), 0)} P</div>
                </FlexRowSpace>
            </FlexColumn>
            ,{btnTxt:t("종료할래요"),
                onClick: () => {

                    AirdropService.airdropFeeUser(selectItem.mainnetId).then((res)=> {
                        if (res) {

                            if ((Number(res.data.balance) - Number(res.data.balanceLock)) > Number(tmpGas.multiply(new bigDecimal(realAirdropCnt)).getValue())) {
                                if((Number(selectItem.ziglePoint)*Number(realAirdropCnt)) > res.data.ziglePoint){
                                    ToastError((Number(selectItem.ziglePoint)*Number(realAirdropCnt))-res.data.ziglePoint+t(" Zigle point가 부족합니다."));
                                    return false;
                                }else {
                                    const params = new FormData();
                                    params.append("airdropMngId", selectItem.airdropMngId);
                                    params.append("feeAverage", tmpGas.getValue());
                                    params.append("participateCount", selectItem.participateCount);

                                    AirdropService.updateAirdropFiEnd(params).then((res) => {
                                        if(props.onClickEndViewItem)
                                        {
                                            props.onClickEndViewItem();
                                        }
                                    });
                                }
                            }else{
                                ToastError(t("사용할 수수료가 부족 합니다 수수료를 입금 후 사용해 주세요."));
                            }
                        }
                    });
                }}
            , {}
        );

    }
    const airdripStateUpdate = (state : string)=> {
        const params = new FormData();
        params.append("airdropMngId", props.viewItem.airdropMngId);
        params.append("airdropState", state);
        let msg = "";
        let tmpState = "";
        switch (state)
        {
            case "pause" : msg= t("일시 정지"); tmpState="P";
            break;
            case "restart" : msg= t("다시 시작"); tmpState="S";
                break;
            case "start" : msg= t("OPEN"); tmpState="S";
                break;

        }
        AirdropService.updateAirdropState(params).then(
            (res)=> {
                if (res) {
                    if(state === "start"){
                        setStartDate(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
                        setStartTime(moment(new Date()).format('YYYY-MM-DD HH:mm:ss'));
                    }
                    setAirdropState(tmpState);
                    Toast(t("{0} 되었습니다.", {0:msg}));
                    props.setReLoad(!props.reLoad);
                }
            }
        );
    }

    useEffect(()=>{
        if(airdropState === "W") {
            setAirdropBlur(false);
        }else {
            if (props.viewItem.airdropStartDay) {

                setStartDate(new Date(moment(props.viewItem.airdropStartDay).format('YYYY-MM-DD HH:mm:ss')));
                setStartTime(new Date(moment(props.viewItem.airdropStartDay).format('YYYY-MM-DD HH:mm:ss')));
                var getCurrentTime = moment();
                var targetTime = moment(props.viewItem.airdropStartDay);
                var getCurrentTimeUnix = getCurrentTime.unix();
                var targetTimeUnix = targetTime.unix();


                var leftTime = targetTimeUnix - getCurrentTimeUnix;
                var duration = moment.duration(leftTime, 'seconds');
                var interval = 1000;
                var intv = setInterval(function () {
                    if (duration.asSeconds() <= 1 || getCurrentTimeUnix >= targetTimeUnix || props.viewItem.airdropOpenYn === "N") {
                        setAirdropBlur(false)
                        clearInterval(intv);
                    } else {
                        duration = moment.duration(duration.asSeconds() - 1, 'seconds');
                        setAirdropBlur(true);
                        setAirdropCountDow('');
                        var timer = {
                            days: duration.days(),
                            hours: (duration.hours() < 10) ? '0' + duration.hours() : duration.hours(),
                            minutes: (duration.minutes() < 10) ? '0' + duration.minutes() : duration.minutes(),
                            seconds: (duration.seconds() < 10) ? '0' + duration.seconds() : duration.seconds()
                        }
                        if(timer.days > 0)
                        {
                            setAirdropCountDay("D-" + timer.days);
                        }
                        setAirdropCountDow(timer.hours + ' : ' + timer.minutes + ' : ' + timer.seconds)


                    }
                }, interval);
            } else {
                setAirdropBlur(true);
            }
        }
    },[props.viewItem])
    const getGasFee = async(selectItem:any)=>{
        let rpcurl = selectItem.mainnetRpcUrl;
        if(selectItem.mainnetChainId == "1")
        {
            rpcurl += process.env.REACT_APP_INFURA_KEY
        }
        const provider = new ethers.providers.JsonRpcProvider(
            rpcurl
        );
        const gas = await provider.getGasPrice();
        const realAirdropCnt =  selectItem.airdropCnt - selectItem.participateCount;
        const tmpGas = new bigDecimal(ethers.utils.formatUnits(gas, selectItem.decimalVal)).multiply(new bigDecimal("21000")).multiply(new bigDecimal("1.3"));
        console.log(tmpGas);
        setGasFee(decimalFormat(tmpGas.multiply(new bigDecimal(realAirdropCnt)).getValue() , 7));

    }
    useEffect(()=>{
        getGasFee(props.viewItem);
    },[props.viewItem])
    return (
        <>
            {
                props.viewItem.airdropType === "airdropFi" &&
                    (
                            <>
                                <FoundationView item={props.viewItem} show={showPop} setShow={setShowPop} />
                            <FlexColumn gap={20}>
                                <FlexColumnCenter className={styles.txt15_B05}>
                                    <QrArea>
                                        <QRCode
                                            id="qrCodeEl"
                                            size={261}
                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                            value={window.location.protocol + "//" + window.location.host+"/a/"+props.viewItem.airdropUrl}
                                        />
                                    </QrArea>
                                    <div>
                                        <img src={props.viewItem.mainnetSymbolImgFileId} width={20}/> <span className={styles.txt15_B02}>{props.viewItem.coinDisplayName}</span>({props.viewItem.coinSymbolName})
                                    </div>
                                    <div>
                                        <FlexRowStart gap={4}>
                                            <div className={styles.ellipse_151}></div>
                                            <div className={styles.txt12_B04} style={{textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden'}}>{props.viewItem.mainnetDisplayName}</div>
                                        </FlexRowStart>
                                    </div>
                                </FlexColumnCenter>
                                <FlexColumn gap={8}>
                                    <FlexRowSpace>
                                        <div className={styles.txt16_B04}>{t("에어드랍 링크")}</div>
                                        <div className={styles.txt11_B04} style={{borderRadius:'100px', padding:'4px 8px', border:'1px solid #D4D8E2'}} onClick={()=>{setShowPop(true)}}>{t("상세정보")}</div>
                                    </FlexRowSpace>
                                    <FlexRowSpace className={styles.select_mainnet}>
                                        <div>
                                            {window.location.protocol + "//" + window.location.host+"/a/"+props.viewItem.airdropUrl}
                                        </div>
                                        <div>
                                            <CopyToClipboard text={window.location.protocol + "//" + window.location.host+"/a/"+props.viewItem.airdropUrl}
                                                             onCopy={() => Toast(t("주소를 복사 하였습니다."))}>
                                                <span className={styles.copy} style={{backgroundColor:'#ffffff', padding:'10px 16px'}}>{t("복사")}</span>
                                            </CopyToClipboard>
                                        </div>
                                    </FlexRowSpace>
                                </FlexColumn>
                            </FlexColumn>
                            <Bar />
                        </>
                    )
            }

            <FlexColumn gap={20}>
                <FlexRowSpace>
                    <div className={styles.txt16_B02}>{t("에어드랍 현황")}</div>
                    {
                        airdropState === "W" ? (
                            <div className={styles.txt13_red}>{t("입금 대기중")}</div>
                        )
                        : (
                            props.viewItem.airdropType === "airdropFi" &&
                            (
                                <div className={styles.txt12_W03}>
                                    { startDate ? moment(props.viewItem.airdropStartDay).format('YYYY-MM-DD HH:mm')+ t("OPEN") : t('미지정')}
                                </div>
                            )
                        )
                    }
                </FlexRowSpace>
                {
                    props.viewItem.airdropType === "airdropFi" ?
                    (
                        <FlexColumn gap={8}>
                            <ProgressBar containerPadding={6} fillerBgcolor={
                                props.viewItem.airdropCnt - props.viewItem.participateCount === 0 ?
                                (
                                    "#D2D6D9"
                                )
                                : (
                                    airdropState === "P" ? ("#DEE9FD") : ("#3A71FF")
                                )
                            } completed={Number((props.viewItem.participateCount / props.viewItem.airdropCnt) * 100)}
                                         fillerHeight={35}
                                         rightLabelValue={decimalFormat(((props.viewItem.participateCount / props.viewItem.airdropCnt) * 100), 0) + "%"}/>
                            <FlexRowSpace>
                                <div className={styles.txt16_B01}>{t("{0}명 참여", {0:props.viewItem.participateCount})}</div>
                                <div
                                    className={styles.txt13_B05}>{t("{0}명 남음", {0:props.viewItem.airdropCnt - props.viewItem.participateCount})}
                                </div>
                            </FlexRowSpace>
                        </FlexColumn>
                    ) : (
                            <FlexColumn gap={12}>
                                <FlexColumnCenter gap={30}>
                                    <FlexColumnCenter>
                                        <div>
                                            <img src={props.viewItem.mainnetSymbolImgFileId} width={20}/> <span className={styles.txt15_B02}>{props.viewItem.coinDisplayName}</span>({props.viewItem.coinSymbolName})
                                        </div>
                                        <div>
                                            <FlexRowStart gap={4}>
                                                <div className={styles.ellipse_151}></div>
                                                <div className={styles.txt12_B04} style={{textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden'}}>{props.viewItem.mainnetDisplayName}</div>
                                            </FlexRowStart>
                                        </div>
                                    </FlexColumnCenter>
                                    <img src={require('../../assets/imges/airdrop.png')} width={64} />
                                </FlexColumnCenter>
                                <FlexColumn  className={styles.txt20_B01}>
                                    <FlexRowCenter><span className={styles.txt20_500_B01}>{t("총 {0}명에게", {0:props.viewItem.airdropCnt})}</span></FlexRowCenter>
                                    <FlexRowCenter>{
                                        props.viewItem.airdropState === "W" ? (
                                            t("에어드랍이 입급대기중에 있어요!")
                                        ) : (
                                            props.viewItem.airdropState === "E" ? (
                                                t("에어드랍을 완료 했어요!")
                                            ) : (
                                                t("에어드랍이 입금중에 있어요!")
                                            )
                                        )
                                    }</FlexRowCenter>
                                </FlexColumn>

                            </FlexColumn>
                    )
                }
                <FlexColumn gap={12}  className={styles.txt14_B05} style={{padding:'16px', borderRadius:'12px', backgroundColor:'#F8F9FC'}}>
                    <FlexRowSpace>
                        <div>{t("출금 지갑")}</div>
                        <div>{ellipseAddress(props.viewItem.walletAddr,6)}</div>
                    </FlexRowSpace>
                    <FlexRowSpace>
                        <div>{t("전송방식")}</div>
                        <div>{props.viewItem.airdropType === "airdropFi" ? t("선착순") :  t("지정인원")} {t("{0}명",{0:decimalFormat(props.viewItem.airdropCnt, 0)})}</div>
                    </FlexRowSpace>
                    <FlexRowSpace>
                        <div>{t("지갑당")}</div>
                        <div className={styles.txt15_500_B01}>{decimalFormat(props.viewItem.amount,2)} {props.viewItem.coinSymbolName}</div>
                    </FlexRowSpace>
                    <FlexRowSpace>
                        <div>{t("Zigle 수수료")}</div>
                        <div className={styles.txt15_500_B01}>{decimalFormat(props.viewItem.ziglePoint,2)}</div>
                    </FlexRowSpace>
                    <FlexRowSpace>
                        <div>{t("예상 GAS FEE")}</div>
                        <div>{props.viewItem.airdropState === "E" ? props.viewItem.feeAverage : gasFee} {props.viewItem.mainnetSymbolName}</div>
                    </FlexRowSpace>
                    <FlexRowSpace>
                        <div>{t("수수료 관리")}</div>
                        <div><img src={require('../../assets/imges/airdrop/airdrop_fee.png')} height={28} onClick={()=>{props.setAirdropFeeShow(true);}} /></div>
                    </FlexRowSpace>
                    <FlexColumn className={styles.line}></FlexColumn>
                    <FlexRowSpace className={styles.txt18_700_B01}>
                        <div>{t("총 수량")}</div>
                        <div style={{color:'#3A71FF'}}>{decimalFormat(props.viewItem.receiveAmount,2)} {props.viewItem.coinSymbolName}</div>
                    </FlexRowSpace>
                </FlexColumn>
                {
                    airdropState === "W" || airdropState === "E" || airdropBlur || props.viewItem.airdropCnt - props.viewItem.participateCount === 0  ? null : (
                        <FlexColumnCenter className={styles.profile_btn_white} onClick={()=>{
                            aridropSendTransfer(props.viewItem);
                        }}><div className={styles.txt16}>{t('종료하기')}</div></FlexColumnCenter>
                    )
                }
            </FlexColumn>
            {
                ((airdropBlur) && (props.viewItem.airdropType === "airdropFi"))  && (
                    <>
                        <Bar />
                        <FlexColumn gap={12}>
                            <FlexRowSpace>
                                <div className={styles.txt16_500_B01}>{t("에어드랍 오픈 예약")}</div>
                                {
                                    updateService ? (
                                        <div className={styles.textBox} onClick={()=>setUpdateService(false)}>변경</div>
                                    ) : (
                                        <div className={styles.textBox}  onClick={()=>{
                                            const params = new FormData();
                                            if(startDate === ''){
                                                setError("startDate", {type:'custom', message:t('시작 날짜를 지정해 주세요.')});
                                            }else if(startTime === ''){
                                                setError("startTime", {type:'custom', message:t('시작시간을 지정해 주세요.')});
                                            }else{
                                                const timeValue = moment(startDate).format('YYYY-MM-DD') + " "+ moment(startTime).format('HH:mm');
                                                params.append("airdropMngId", props.viewItem.airdropMngId);
                                                params.append("airdropStartDay", moment(timeValue).format('YYYY-MM-DD HH:mm:ss'));

                                                AirdropService.updateAirdropStartDay(params).then(
                                                    (res)=> {
                                                        if (res) {
                                                            setUpdateService(true);
                                                            props.setReLoad(!props.reLoad);
                                                        }
                                                    }
                                                );
                                            }

                                        }} style={{color:'#ffffff', backgroundColor:'#111111'}}>{t("저장")}</div>
                                    )
                                }
                            </FlexRowSpace>
                            <FlexColumn gap={8}>
                                <FlexRowSpace className={styles.select_mainnet} style={{backgroundColor:'#ffffff'}}>
                                    <FlexRowStart gap={12}>
                                        <div>
                                            <img src={require('../../assets/imges/airdrop/airdrop_ymd.png')} height={28}/>
                                        </div>
                                        <FlexColumn className={styles.txt12_B05}>
                                            <div>{t("날짜")}</div>
                                            <div>
                                                <DefaultInput
                                                    error={errors.startDate}
                                                    marginBottom={3}
                                                    inputElement={
                                                        updateService ? (
                                                            <span style={{color:'#111111'}}>{startDate ? moment(startDate).format('YYYY.MM.DD') : t('미지정')}</span>
                                                        ) : (
                                                            <DatePicker
                                                                className={styles.customDatePicker}
                                                                selected={startDate}
                                                                placeholderText={"YYYY.MM.DD"}
                                                                dateFormat="yyyy.MM.dd"
                                                                locale="ko"
                                                                onChange={(date: Date) => {
                                                                    setStartDate(date);
                                                                    clearErrors("startDate");
                                                                }}
                                                            />
                                                        )
                                                    }
                                                />
                                            </div>
                                        </FlexColumn>
                                    </FlexRowStart>
                                </FlexRowSpace>
                                <FlexRowSpace className={styles.select_mainnet} style={{backgroundColor:'#ffffff'}}>
                                    <FlexRowStart gap={12}>
                                        <div>
                                            <img src={require('../../assets/imges/airdrop/airdrop_hs.png')} height={28}/>
                                        </div>
                                        <FlexColumn className={styles.txt12_B05}>
                                            <div>{t("시간")}</div>
                                            <div>
                                                <DefaultInput
                                                    error={errors.startTime}
                                                    marginBottom={3}
                                                    inputElement={
                                                        updateService ? (
                                                            <span style={{color:'#111111'}}>{startDate ? moment(startTime).format('a h:mm') : t('미지정')}</span>
                                                        ) : (
                                                            <DatePicker className={styles.customDatePicker}
                                                                        selected={startTime}
                                                                        placeholderText={t("시작시간")}
                                                                        showTimeSelect
                                                                        showTimeSelectOnly
                                                                        timeIntervals={15}
                                                                        timeCaption="Time"
                                                                        dateFormat="aa h:mm"
                                                                        locale="ko"
                                                                        onChange={(date: Date) => {
                                                                            setStartTime(date);
                                                                            clearErrors("startTime");
                                                                        }}
                                                            />
                                                        )
                                                    }
                                                />
                                            </div>
                                        </FlexColumn>
                                    </FlexRowStart>
                                </FlexRowSpace>
                            </FlexColumn>
                        </FlexColumn>
                    </>
                )
            }

            <FlexBottom>
                {
                    props.viewItem.airdropType === "airdropFi" ?
                        (
                            airdropBlur ? (
                                <FlexColumnCenter style={{height: "56px", backgroundColor: "#3a71ff", color: "white"}}onClick={() => {airdripStateUpdate("start") }}>
                                    <div style={{fontSize:'16px'}}>{t('오픈하기')}</div>
                                    {
                                        airdropCountDown && (
                                            <div>
                                                {airdropCountDay && (
                                                    <><span style={{background:'#ffffff', padding:'3px 8px', fontSize:'11px', borderRadius:'100px', color:'#3a71ff'}}>{airdropCountDay}</span>&nbsp;&nbsp;</>
                                                )}
                                                {airdropCountDown}
                                            </div>
                                        )
                                    }
                                </FlexColumnCenter>
                            ) :
                            (
                                airdropState === "E" ? (
                                    <FlexColumnCenter style={{height: "56px", backgroundColor: "#F2F4F6", color: "#D2D6D9"}}>
                                        <div style={{fontSize:'16px'}}>{t('종료된 에어드랍 입니다.')}</div>
                                    </FlexColumnCenter>
                                )
                                :
                                (
                                    props.viewItem.airdropCnt - props.viewItem.participateCount === 0 ?
                                    (
                                        <FlexColumnCenter style={{height: "56px", backgroundColor: "#3a71ff", color: "#ffffff"}}  onClick={() => {aridropSendTransfer(props.viewItem)}}>
                                            <div style={{fontSize:'16px'}}>{t('지급하기')}</div>
                                        </FlexColumnCenter>
                                    ) : (
                                        airdropState === "S" || airdropState === "P"  &&
                                        (
                                            airdropState === "S" ?
                                                (
                                                    <FlexColumnCenter style={{height: "56px", backgroundColor: "#111111", color: "white"}} onClick={() => {airdripStateUpdate("pause")}}>
                                                        <div style={{fontSize:'16px'}}>{t('일시정지')}</div>
                                                    </FlexColumnCenter>
                                                ) :
                                                (
                                                    <FlexColumnCenter style={{height: "56px", backgroundColor: "#F2F4F6", color: "#3A71FF"}}  onClick={() => {airdripStateUpdate("restart")}}>
                                                        <div style={{fontSize:'16px'}}>{t('다시 시작하기')}</div>
                                                    </FlexColumnCenter>
                                                )
                                        )
                                    )
                                )
                            )
                        ) : (
                            <FlexColumnCenter style={{height: "56px", backgroundColor: "#F2F4F7", color: "#D2D6D9"}}  onClick={() => {}}>
                                <div style={{fontSize:'16px'}}>{
                                    props.viewItem.airdropState === "W" ? (
                                        t("에어드랍이 입급대기중에 있어요!")
                                    ) : (
                                        props.viewItem.airdropState === "E" ? (
                                            t("에어드랍이 완료 되었습니다!")
                                        ) : (
                                            t("에어드랍이 입금중에 있어요!")
                                        )
                                    )
                                }</div>
                            </FlexColumnCenter>
                        )
                }
            </FlexBottom>
        </>
    )
}