import React, {CSSProperties, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import PageTitle, {
  FlexColumn,
  FlexColumSpace,
  FlexRowCenter,
  FlexRowSpace,
  FlexRowStart,
  GrayBoxW00,
  GrayBoxW00Small,
  PageContent,
  PageRootContainer,
  RightToLeft,
} from "../components/common/container/PageContainer";
import { useNavigate } from "react-router-dom";
import Button34 from "../components/common/button/Button34";
import ReactPlaceholder from "react-placeholder";
import IconCopy from "../assets/icons/copy.png";
import IconGoogle from "../assets/imges/login/iconGoogle.png";
import IconKakao from "../assets/imges/login/iconKakao.png";
import IconTelegram from "../assets/imges/setting/telegram_icon.png";

import IconNft from "../assets/imges/setting/btcon_nft.png";
import IconNftG from "../assets/imges/setting/btcon_nft_g.png";
import IconIdo from "../assets/imges/setting/btcon_ido.png";
import IconIdoG from "../assets/imges/setting/btcon_ido_g.png";
import IconSto from "../assets/imges/setting/btcon_sto.png";
import IconStoG from "../assets/imges/setting/btcon_sto_g.png";
import IconRef from "../assets/imges/setting/ref.png";
import IconLogin from "../assets/imges/setting/profile img_list.png";
import IconCs from "../assets/imges/setting/icon_cs.png";
import IconAggress from "../assets/imges/setting/icon_aggre.png";
import IconPrivate from "../assets/imges/setting/icon_private.png";
import IconGuide from "../assets/imges/setting/icon_guide.png";
import IconMetamask from "../assets/imges/setting/icon_metamask.png";
import IconQuit from "../assets/imges/setting/icon_quit.png";

import styles from "../common/css/zigle.module.scss";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {DISCONNECT} from "../components/reducer/walletReducer";
import {LOGOUT} from "../components/reducer/userReducer";
import BottomButton from "../components/common/button/BottomButton";
import BottomLayer, {FlexBottomButton} from "../components/common/container/BottomLayer";
import FriendDetail from "../components/common/popup/FriendDetail";
import PopupService from "../services/popup.service";
import {Toast} from "../components/common/popup/ToastAlert";
import { Bar } from "./Profile";
import UpRefPopup from "../components/setting/UpRefPopup";
import {ellipseAddress} from "../common/js/utilities";
import {useAccount} from "wagmi";
import {useScript} from "../common/js/hooks";
import {useTranslation} from "react-i18next";

const bntStyle:CSSProperties = {
  width: "79px",
  height: "34px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: "10px",
  // padding: "10px 16px",
  borderRadius: "8px",
  border: "solid 1px #f2f4f8",
  backgroundColor: "#ffffff",
}
interface ISettingProps {
  pwaVisible: any;
  loginCheck:any;
}
function Setting(props:ISettingProps) {
  const [load, setLoad] = useState(false);
  const [mainNet, setMainNet] = useState<any>({});
  const [showFriend, setShowFriend] = useState(false);
  const { address, isConnected } = useAccount();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const login: boolean = useSelector((state: any) => state.userReduce.login);
  const profileImageFileId: string = useSelector(
    (state: any) => state.userReduce.profileImageFileId
  );
  const doname: string = useSelector((state: any) => state.userReduce.doname);
  const email: string = useSelector((state: any) => state.userReduce.email);
  const socialCode: string = useSelector((state: any) => state.userReduce.socialCode);
  const mainNetDetail = useSelector((state: any) => state.walletReducer.mainNetDetail);

  
  const upRefCode = useSelector((state: any) => state.userReduce.upRefCode);

  const [popupShow, setPopupShow] = useState(false);
  const dispatch = useDispatch();
  const status = useScript("https://developers.kakao.com/sdk/js/kakao.js");



  const onClickKaKaoPf = () =>{
    window.Kakao.Channel.chat({
      channelPublicId: '_xfxnjxkxj', //카카오 채널 ID
    });
  }

  useEffect(() => {
    if (status === "ready" && window.Kakao) {
      // 중복 initialization 방지
      if (!window.Kakao.isInitialized()) {
        // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
        window.Kakao.init(process.env.REACT_APP_KAKAO_AUTH_KEY);
      }
    }
  }, [status]);

  useEffect(() => {
    if (email !== "") {
      setLoad(true);
    }
  }, [email]);

  useEffect(() => {
    const fetchMainnet = async () => {
      let data = await mainNetDetail;
      setMainNet(data);
      setLoad(true);
    };

    if (mainNetDetail) {
      fetchMainnet();
    }
  }, [mainNetDetail]);

  return (
      <RightToLeft>
        <PageRootContainer>
          <FriendDetail setShow={setShowFriend} show={showFriend}/>
          <UpRefPopup setShow={setPopupShow} show={popupShow} loginCheck={props.loginCheck}/>
          <PageTitle
              onClickLeft={() => {
                navigate(-1);
                // setShowFriend(true);
              }}
              textTitle={t("설정")}
              pwaInstall={props.pwaVisible}
          />

          <PageContent>
            {/*로그인이 되었을 경우.*/}
            {login && (
                <FlexColumn gap={20}>
                  <FlexRowSpace>
                    <FlexRowStart gap={8}>
                      <ReactPlaceholder
                          showLoadingAnimation={true}
                          ready={load}
                          type={"media"}
                          rows={2}
                          style={{width: "100%"}}
                      >
                        <div>
                          <img
                              src={profileImageFileId}
                              width={50}
                              style={{borderRadius:'100px', aspectRatio:'1', objectFit:'cover'}}
                              alt={"profile image"}
                          />
                        </div>
                        <FlexColumSpace style={{alignItems: "start"}}>
                          <div className={styles.txt20_B01}>
                            {doname ?? t("닉네임이 없습니다.")}
                          </div>
                          <div className={styles.txt14_300_B01}>
                            {socialCode === "kakao" && (
                                <span style={{marginRight: "4px"}}>
                            <img src={IconKakao} width={20}/>
                          </span>
                            )}
                            {socialCode === "google" && (
                                <span style={{marginRight: "4px"}}>
                            <img src={IconGoogle} width={20}/>
                          </span>
                            )}
                            {email}
                          </div>
                        </FlexColumSpace>
                      </ReactPlaceholder>
                    </FlexRowStart>
                    <div>
                      <img
                          src={require("../assets/imges/profile/modify.png")}
                          width={28}
                          alt={"modify"}
                          onClick={()=>navigate("/modifyProfile")}
                      />
                    </div>
                  </FlexRowSpace>

                  <GrayBoxW00>
                    <FlexRowSpace>
                      <FlexRowStart gap={8}>
                        <FlexColumn>
                          <FlexRowStart className={styles.txt14_B04} gap={4}>
                            <div>{ellipseAddress(address, 5)}</div>
                            <div>
                              <CopyToClipboard
                                  text={address as string}
                                  onCopy={() => Toast(t("주소를 복사 했습니다."))}
                              >
                                <img src={IconCopy} width={20} alt={"copy"}/>
                              </CopyToClipboard>
                            </div>
                          </FlexRowStart>
                        </FlexColumn>
                      </FlexRowStart>
                      <div
                          className={styles.txt13_B04}
                          style={bntStyle}
                          onClick={() => {
                            dispatch({type: DISCONNECT});
                            dispatch({type: LOGOUT});
                            document.location.reload();
                          }}
                      >
                        {t('연결해제')}
                      </div>
                    </FlexRowSpace>
                  </GrayBoxW00>
                </FlexColumn>
            )}
            {/*로그인이 안되었을 경우*/}
            {!login && (
                <FlexRowSpace style={{
                  border: " solid 1px #e3e5ed",
                  borderRadius: "12px",
                  height: "70px",
                  padding: "16px 12px"
                }}>
                  <FlexRowStart gap={8}>
                    <div><img src={IconLogin} width={32} height={32} alt={"IconLogin"}/></div>
                    <div className={styles.txt14_B01}>{t('로그인해주세요.')}</div>
                  </FlexRowStart>
                  <Button34 radius={"8"} text={t("지갑연결")} type={"sky"} onClick={() => navigate("/walletLogin")}/>
                </FlexRowSpace>
            )}
            <FlexColumn style={{marginTop: '32px', paddingBottom: '12px'}} gap={14}>
              <FlexRowStart gap={8}>
                <div className={styles.txt16_B02}>
                  {t('지글서비스')}
                </div>
              </FlexRowStart>
              <FlexRowCenter gap={8}>
                <GrayBoxW00Small>
                  <div><img src={IconIdoG} width={28} alt={"NFT"}/></div>
                  <div className={styles.txt11_B04} style={{
                    backgroundColor: '#f2f4f6',
                    borderRadius: '100px',
                    padding:'0px 8px',
                    height: '24px',
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: "center"
                  }}>IDO {t('준비중')}
                  </div>
                </GrayBoxW00Small>
                <GrayBoxW00Small>
                  <div><img src={login ? IconNftG : IconNft} width={28} alt={"NFT"}/></div>
                  <div className={styles.txt11_B04} style={{
                    backgroundColor: '#f2f4f6',
                    borderRadius: '100px',
                    padding:'0px 8px',
                    height: '24px',
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: "center"
                  }}>NFT {t('준비중')}
                  </div>
                </GrayBoxW00Small>
                <GrayBoxW00Small>
                  <div><img src={login ? IconStoG : IconSto} width={28} alt={"NFT"}/></div>
                  <div className={styles.txt11_B04} style={{
                    backgroundColor: '#f2f4f6',
                    borderRadius: '100px',
                    padding:'0px 8px',
                    height: '24px',
                    display: "flex",
                    alignItems: 'center',
                    justifyContent: "center"
                  }}>STO {t('준비중')}
                  </div>
                </GrayBoxW00Small>
              </FlexRowCenter>

            </FlexColumn>


              {
                upRefCode ? (
                    <FlexRowStart style={{height: '60px'}} gap={8}>
                      <div><img src={IconRef} width={20} alt={"REF"}/></div>
                        <div className={styles.txt16_W03}>{t('초대코드 입력')}</div>
                        <div className={styles.txt11_B04} style={{
                          backgroundColor: '#f2f4f6',
                          borderRadius: '100px',
                          padding:'4px 8px',
                          display: "flex",
                          alignItems: 'center',
                          justifyContent: "center"
                        }}>{upRefCode}</div>
                    </FlexRowStart>
                  ) : (
                      login ? (
                          <FlexRowStart style={{height: '60px'}} gap={8}>
                            <div><img src={IconRef} width={20} alt={"REF"}/></div>
                            <div className={styles.txt16_B02} onClick={()=>{
                              setPopupShow(true);
                            }}>{t('초대코드 입력')}</div>
                          </FlexRowStart>
                        ) : null

                )
              }

            <FlexRowStart style={{height: '60px'}} gap={8} onClick={()=>{onClickKaKaoPf()}}>
              <div><img src={IconCs} width={20} alt={"NFT"}/></div>
              <div className={styles.txt16_B02}>{t('고객센터')}</div>
            </FlexRowStart>
            <FlexRowStart style={{height: '60px'}} gap={8} onClick={()=>navigate("/terms")}>
              <div><img src={IconAggress} width={20} alt={"NFT"}/></div>
              <div className={styles.txt16_B02}>{t('이용약관')}</div>
            </FlexRowStart>
            <FlexRowStart style={{height: '60px'}} gap={8} onClick={()=>navigate("/privacy")}>
              <div><img src={IconPrivate} width={20} alt={"NFT"}/></div>
              <div className={styles.txt16_B02}>{t('개인정보처리방침')}</div>
            </FlexRowStart>
            <FlexRowStart style={{height: '60px'}} gap={8} onClick={()=>navigate("/metamask")}>
              <div><img src={IconMetamask} width={20} alt={"NFT"}/></div>
              <div className={styles.txt16_B02}>{t('메타마스크 설명서')}</div>
            </FlexRowStart>
            <FlexRowStart style={{height: '60px'}} gap={8}>
              <div><img src={IconGuide} width={20} alt={"NFT"}/></div>
              <div className={styles.txt16_B02}>{t('지글소개서')}</div>
            </FlexRowStart>
            <Bar/>
            <FlexRowStart style={{height: '60px'}} gap={8} onClick={()=>{ window.open("https://t.me/zigle_official", '_blank', 'noreferrer');}}>
              <div><img src={IconTelegram} width={20} alt={"커뮤니티"}/></div>
              <div className={styles.txt16_B02}>{t("커뮤니티 채널")}</div>
            </FlexRowStart>
            {
              login && (
                  <>
                  <Bar/>
                    <FlexRowStart style={{height: '60px'}} gap={8} onClick={()=>navigate("/quit")}>
                      <div><img src={IconQuit} width={20} alt={"NFT"}/></div>
                      <div className={styles.txt16_B02}>{t('탈퇴하기')}</div>
                    </FlexRowStart>
                  </>
                )
            }
          </PageContent>
        </PageRootContainer>
      </RightToLeft>
  );
}

export default Setting;
