import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import PageTitle, {
    FlexColumEnd,
    FlexColumn, FlexColumnCenter, FlexRowCenter, FlexRowEnd,
    FlexRowSpace, FlexRowStart,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import React, {useEffect, useRef, useState} from "react";
import styles from "../common/css/zigle.module.scss";
import BottomButton from "../components/common/button/BottomButton";
import AirdropList from "../components/airdrop/AirdropList";
import AirdropService from "../services/airdrop.service";
import CommonService from "../services/common.service";
import MyAirdropView from "../components/airdrop/MyAirdropView";
import MyAirdropParticipation from "../components/airdrop/MyAirdropParticipation";
import {IMyAirdropTransfer} from "../common/dto/AirdropList";
import {decimalFormat} from "../common/js/utilities";
import { useTranslation } from "react-i18next";
import NaviBar from "../components/home/NaviBar";


export default function Airdrop(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const doname: string = useSelector((state: any) => state.userReduce.doname);
    const email: string = useSelector((state: any) => state.userReduce.email);

    const [home, setHome] = useState(true);

    const [modeType, setModeType] = useState('');
    const [subType, setSubType] = useState('');
    const progressValue = useRef(30);
    const [reLoad, setReLoad] = useState(false);
    const [myList, setMyList] = useState<Array<IMyAirdropTransfer>>([]);
    const myTotalCount = useRef(0);
    const [airdropView, setAirdropView] = useState(false);

    const setMyAirdropList = (page:number, block:number)=>{
        AirdropService.airdropMyList(page, block).then((res)=>{
            setMyList(res.data);
            if(res){
                myTotalCount.current = res.totalCount;
            }
        });
    }
    useEffect(()=>{
        setMyAirdropList(1,10);
    },[]);
    useEffect(()=>{
        if(localStorage.getItem("airdropView")){
            setAirdropView(true);
            localStorage.removeItem("airdropView");
        }
    },[localStorage.getItem("airdropView")])

    return (
        <PageRootContainer>
            <MyAirdropParticipation setShow={setAirdropView} show={airdropView} listInfo={myList} setMyAirdropList={setMyAirdropList}/>

            <PageTitle home={home}  onClickLeft={()=>{ navigate(-1);}}  rightSrc={require('../assets/icons/link_history.png')} onClickRight={()=>{ setAirdropView(true) }}/>
            <PageContent  style={{marginBottom:'80px'}}>
                <FlexColumn gap={20}>
                    <FlexColumn gap={16}>
                        <FlexRowSpace>
                            <FlexColumn style={{width:'60%'}} className={styles.txt22_B01}>
                                <div>{doname ? doname : t("닉네임없음")}{t('님은')}</div>
                                <div>{t('총')} <span className={styles.txt22_500_B01}>{decimalFormat(myTotalCount.current, 0)}</span>{t("번")}</div>
                                <div>{t('에어드랍에 참여했어요!')}</div>
                            </FlexColumn>
                            <FlexRowEnd style={{width:'105px'}} >
                                <FlexRowCenter className={styles.airdrop_sub} gap={2} onClick={()=>navigate('/myAirdrop')}>
                                    <img src={require('../assets/imges/airdrop/airdrop.png')} width={20}/>
                                    <div>{t('내 에어드랍')}</div>
                                </FlexRowCenter>
                            </FlexRowEnd>
                        </FlexRowSpace>
                        <FlexColumn>
                            <BottomButton
                                isActive={true}
                                backgroundColor={"#111111"}
                                textColor={"white"}
                                // border={"solid 1px #dbdee6"}
                                onClick={()=>{navigate("/airdropCreate")}}
                                text={t("에어드랍 만들기")}
                                radius={'8px'}
                            />
                        </FlexColumn>
                    </FlexColumn>
                    <FlexColumn>
                        <FlexRowStart gap={16}  className={styles.airdropTab}>
                            <div className={ modeType === '' ? styles.airdropTabActive : styles.airdropTabDefault} style={{lineHeight:'28px'}} onClick={()=>{setModeType(''); setReLoad(!reLoad); }}>{t('전체')}</div>
                            <div className={ modeType === 'I' ? styles.airdropTabActive : styles.airdropTabDefault} style={{lineHeight:'28px'}} onClick={()=>{setModeType('I'); setReLoad(!reLoad); }}>{t('진행예정')}</div>
                            <div className={ modeType === 'S' ? styles.airdropTabActive : styles.airdropTabDefault} style={{lineHeight:'28px'}} onClick={()=>{setModeType('S'); setReLoad(!reLoad); }}>{t('진행중')}</div>
                            <div className={ modeType === 'E' ? styles.airdropTabActive : styles.airdropTabDefault} style={{lineHeight:'28px'}} onClick={()=>{setModeType('E'); setReLoad(!reLoad); }}>{t('종료')}</div>
                        </FlexRowStart>
                        <div style={{backgroundColor:'#F2F4F7', width:'calc(100% + 40px)', marginLeft:'-20px'}}>

                            <FlexColumn style={{padding: '16px 20px'}} gap={16}>
                                <FlexColumn  className={styles.overFlowScrillHideBar}>
                                    <FlexRowStart  style={{width:'420px'}} gap={8}>
                                        <div className={ subType === '' ? styles.airdropSubTabActive : styles.airdropSubTabDefault} onClick={()=>{setSubType(''); setReLoad(!reLoad); }}>{t('최신순')}</div>
                                        <div className={ subType === 'E' ? styles.airdropSubTabActive : styles.airdropSubTabDefault} onClick={()=>{setSubType('E'); setReLoad(!reLoad); }}>{t('마감임박')}</div>
                                        <div className={ subType === 'O' ? styles.airdropSubTabActive : styles.airdropSubTabDefault} onClick={()=>{setSubType('O'); setReLoad(!reLoad); }}>{t('오래된 순')}</div>
                                        <div className={ subType === 'P' ? styles.airdropSubTabActive : styles.airdropSubTabDefault} onClick={()=>{setSubType('P'); setReLoad(!reLoad); }}>{t('높은금액 순')}</div>
                                    </FlexRowStart>
                                </FlexColumn>
                                <FlexColumn gap={12}>
                                    <AirdropList modeType={modeType} subType={subType} reLoad={reLoad} pageMode={''}/>
                                </FlexColumn>
                            </FlexColumn>
                        </div>
                    </FlexColumn>
                </FlexColumn>
            </PageContent>
            <NaviBar pageType={"airdrop"}/>
        </PageRootContainer>
    )
}