import axios from "axios";
import { getHeader } from "./profile.service";
import { checkStatus401 } from "./common.service";

let API_URL = "";
if (process.env.REACT_APP_ENVTEXT === "local") {
  API_URL = "/api";
} else {
  API_URL = process.env.REACT_APP_API_URL ?? "https://api.zigle.link/api";
}
const miningList = (
    orderType: string,
    currentPage: number,
    blockSize: number
) => {
  return axios
    .get(API_URL + "/v1/user/mining/his", {
      params: {
        orderType: orderType,
        currentPage: currentPage,
        blockSize: blockSize
      },
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatus401(error.response);
    });
};
const miningToday = () => {
    return axios
        .get(API_URL + "/v1/user/mining/today", {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            checkStatus401(error.response);
        });
};
const FavService = {
    miningList,
    miningToday
};

export default FavService;
