import PageTitle, {FlexColumn, FullScreen, PageContent, PageRootContainer, PopupBodyWrapper } from "../common/container/PageContainer";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import AirdropFeeItem from "./AirdropFeeItem";
import {IMainnetInfo} from "../../common/dto/MainnetInfo";
import {useInView} from "react-intersection-observer";
import EmptyContent from "../common/EmptyContent";
import AirdropService from "../../services/airdrop.service";
import { IAirdropFeeResDto} from "../../common/dto/AirdropList";
import AirdropFeeIn from "./AirdropFeeIn";
import {useSelector} from "react-redux";
import MetaMaskService from "../../services/metamask.service";
import AirdropFeeOut from "./AirdropFeeOut";
import AirdropFeeHis from "./AirdropFeeHis";
import {useWalletEthersPorvider} from "../../common/js/ethersProviderHook";
import {useTranslation} from "react-i18next";

interface IAirdropFee {
    airdropFeeShow: boolean;
    setAirdropFeeShow: any;
}
export default function AirdropFee(props:IAirdropFee){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [home, setHome] = useState(true);

    const [mainNetList, setMainNetList] = useState<Array<IAirdropFeeResDto>>([]);

    const [currPage, setCurrPage] = useState(1);
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [blockSize] = useState(10);
    const [popupIn, setPopupIn] = useState(false);
    const [popupOut, setPopupOut] = useState(false);
    const [popupHis, setPopupHis] = useState(false);
    const [popupItem, setPopupItem] = useState('');
    const chainId = useSelector((state:any) => state.walletReducer.chainId);

    const provider = useWalletEthersPorvider();
    
    const [reLoad, setReLoad] = useState(false);
    const [reSet, setReSet] = useState(false);

    const handleOnClick = (popupType:string,selectItem: any) =>{

        if((chainId.toString() !== selectItem.mainnetInfo.chainId.toString()) && ((popupType === "in" || popupType === "out"))){
            MetaMaskService.setSwitchNetwork(provider, parseInt(selectItem.mainnetInfo.chainId), selectItem.mainnetName, selectItem.symbolName, selectItem.decimalVal, selectItem.rpcUrl, selectItem.explorerUrl, '').then(
                (response) => {
                    if (!response.status) {
                    }
                }
            );
        }else{
            setPopupItem(selectItem);
            if(popupType === "in"){
                setPopupIn(true);
            }else if(popupType === "out"){
                setPopupOut(true);
            }else{
                setPopupHis(true);
            }

        }
    }
    const fetchData = async() =>{
        const response = await AirdropService.airdropFeeList();
        if (!response.data.length) {
            setLastList(true);
            return;
        }
        setMainNetList([...mainNetList, ...response.data]);
    }
    useEffect(() => {

        if (!lastList) {
            fetchData();
        }
    },[currPage]);

    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);
    useEffect(()=>{
       if(reSet !== reLoad)
       {
           setLastList(false);
           setMainNetList([]);
           if(currPage === 1){
               fetchData();
           }else{
               setCurrPage(1);
           }
           setReSet(reLoad)
       }
    },[reLoad]);

    return (
        <>
            {
                props.airdropFeeShow && (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <AirdropFeeIn popupIn={popupIn} setPopupIn={setPopupIn} popupItem={popupItem}
                                              reLoad={reLoad} setReLoad={setReLoad}/>
                                <AirdropFeeOut popupOut={popupOut} setPopupOut={setPopupOut} popupItem={popupItem}
                                               reLoad={reLoad} setReLoad={setReLoad}/>
                                <AirdropFeeHis popupHis={popupHis} setPopupHis={setPopupHis} popupItem={popupItem}/>
                                <PageTitle home={home} textTitle={t('수수료 관리')} onClickLeft={() => {
                                    props.setAirdropFeeShow(false);
                                }}/>
                                <PageContent>
                                    <FlexColumn gap={16}>
                                        {
                                            mainNetList.length > 0 ? (
                                                mainNetList.map((item, index) => {
                                                    return (
                                                        <AirdropFeeItem key={item.mainnetId} itemDetail={item}
                                                                        handleOnClick={handleOnClick}/>
                                                    )
                                                })
                                            ) : (
                                                <EmptyContent titleText={t('메인넷을 찾을수 없어요')}
                                                              titleImage={require('../../assets/icons/empty_icon.png')}/>
                                            )
                                        }
                                        {
                                            Object.values(mainNetList).length >= blockSize ? (<div ref={ref}
                                                                                                   className="py-3 bg-red-500 text-white text-center"></div>) : null
                                        }
                                    </FlexColumn>
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
            </>
    )
}