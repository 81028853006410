import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {combineReducers, legacy_createStore as createStore} from "redux";
import walletReducer from "./components/reducer/walletReducer";
import userReduce from "./components/reducer/userReducer";
import {Provider} from "react-redux";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './lang/i18n';
import { publicProvider } from 'wagmi/providers/public'

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
   mainnet, polygon, optimism, arbitrum, fantom, avalanche, bsc, klaytn
} from '@wagmi/core/chains';
import rpcText from "./common/json/rpc.json"

import { Chain } from '@wagmi/core'

import { infuraProvider } from 'wagmi/providers/infura'

const projectId = "71a29de34f2203893362d94220d78dcb";



const chains = [mainnet, bsc, polygon, avalanche, optimism, arbitrum, fantom];


// const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const { publicClient } = configureChains(
    chains,
    [
        infuraProvider({
            apiKey: process.env.REACT_APP_INFURA_KEY as string
        }),
        publicProvider()
    ],
    { batch: { multicall: true } },
);

// const { publicClient } = configureChains(chains, [infuraProvider({ apiKey: process.env.REACT_APP_INFURA_KEY as string })],);
const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)


const rootReducer = combineReducers({
    walletReducer,
    userReduce,
});
const store = createStore(rootReducer);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

      <Provider store={store}>
          <WagmiConfig config={wagmiConfig}>
            <App />
          </WagmiConfig>
          <Web3Modal projectId={projectId} ethereumClient={ethereumClient}/>
      </Provider>

);
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
