import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import PageTitle, {
    FlexColumn,
    FlexColumnCenter,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import styles from "../common/css/zigle.module.scss";
import Intro1 from "../components/intro/intro1";
import Intro2 from "../components/intro/intro2";
import Intro3 from "../components/intro/intro3";
import {useTranslation} from "react-i18next";


export default function Service() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <PageRootContainer>
            <PageTitle textTitle = {''} onClickLeft={()=>{
                navigate(-1);
            }}
            />
            <PageContent  style={{width: 'calc(100% + 40px)', marginLeft:'-20px', marginBottom:'0px'}}>
                <FlexColumn gap={26}>
                    <Intro1 />
                    <FlexColumn style={{backgroundColor:'black', padding:'0px 20px'}}>
                        <Intro2 />
                        <Intro3 />
                    </FlexColumn>
                </FlexColumn>
            </PageContent>
        </PageRootContainer>
    )
}