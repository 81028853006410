import React, {useRef, useState} from "react";

import styles from "../common/css/zigle.module.scss";
import PageTitle, {
    FlexColumn,
    FlexRowAround, FlexRowEnd, FlexRowSpace, FlexRowStart,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import PhoneList from "../components/Friend/PhoneList";
import FriendList from "../components/Friend/FriendList";
import {useNavigate} from "react-router-dom";
import DonamePopup from "../components/Friend/DonamePopup";
import PhoneView from "../components/Friend/PhoneView";
import {useTranslation} from "react-i18next";
import NaviBar from "../components/home/NaviBar";
import FriendingTab from "../components/Friend/FriendingTab";
import FriendingTab2 from "../components/Friend/FriendingTab2";



export default function Friend() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [tabFirst, setTabFirst] = useState('0');
    const [donameView, setDonameView] = useState(false);

    const handleOnRightClick = ()=>{
        setDonameView(false);
    }
    return (
        <PageRootContainer>
            <DonamePopup setShow={setDonameView} show={donameView} handleOnRightClick={handleOnRightClick}/>
            {/*{*/}
            {/*    tabPage ==='phone' ?*/}
            {/*        (*/}
            {/*            <PageTitle textTitle ={t('친구 관리')}*/}
            {/*                       onClickLeft={()=>{ navigate(-1); }}*/}
            {/*                       // rightText={phoneMode ==='list' ? t('편집') : t('완료')}*/}
            {/*                       // onClickRight={()=>{ phoneMode ==='list' ? setPhoneMode('write') : setPhoneMode('list');}}*/}
            {/*            />*/}
            {/*        )*/}
            {/*        :*/}
            {/*        (*/}
            {/*            <PageTitle textTitle ={t('친구 관리')}*/}
            {/*                       onClickLeft={()=>{ navigate(-1); }}*/}
            {/*                       rightSrc={require('../assets/icons/plus_friend.png')}*/}
            {/*                       onClickRight={() => {setDonameView(true)}}*/}
            {/*            />*/}
            {/*        )*/}
            {/*}*/}

            <PageContent  style={{marginBottom:'80px', paddingTop:'30px' }}>
                    <FlexRowSpace style={{height:'56px'}}>
                        <FlexRowStart gap={12}>
                            <div className={ tabFirst === '0' ? styles.txt22_600_B01 : styles.txt22_600_W03} onClick={()=>{setTabFirst('0')}}>{t("프렌딩")}</div>
                            <div className={ tabFirst === '1' ? styles.txt22_600_B01 : styles.txt22_600_W03} onClick={()=>{setTabFirst('1')}}>{t("둘러보기")}</div>
                        </FlexRowStart>
                        <FlexRowEnd>
                            <img src={require('../assets/imges/search_icon.png')} style={{width:'28px'}} onClick={()=>{setDonameView(true)}}/>
                        </FlexRowEnd>
                    </FlexRowSpace>
                    { tabFirst === '0' && (<FriendingTab donameView={donameView}/>)}
                    { tabFirst === '1' && (<FriendingTab2/>)}
                </PageContent>
            <NaviBar pageType={"friend"}/>
        </PageRootContainer>
    );
}