import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import PageTitle, {
    FlexBottom86,
    FlexColumn, FlexRowEnd, FlexRowSpace, FlexRowStart,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import TransferStep1 from "../components/transferCoin/TransferStep1";
import TransferStep2 from "../components/transferCoin/TransferStep2";
import TransferStep3 from "../components/transferCoin/TransferStep3";
import MetaMaskService from "../services/metamask.service";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import MainnetList from "../components/common/MainnetList";
import CoinList from "../components/common/CoinList";
import FriendList from "../components/Friend/FriendList";
import styles from "../common/css/zigle.module.scss";
import CommonService from "../services/common.service";
import PopupService from "../services/popup.service";
import TransferEnd from "../components/transferCoin/TransferEnd";
import TransferPhone from "../components/transferCoin/TransferPhone";
import TransferFriend from "../components/transferCoin/TransferFriend";
import TransferFriend2 from "../components/transferCoin/TransferFriend2";
import TransferPhone2 from "../components/transferCoin/TransferPhone2";
import PhoneService from "../services/phone.service";
import { Toast } from "../components/common/popup/ToastAlert";
import {IMainnetInfo} from "../common/dto/MainnetInfo";
import CoinSelect from "../components/common/CoinSelect";
import CoinPopup from "../components/tokenlist/CoinPopup";
import {useAccount} from "wagmi";
import {useWalletEthersPorvider} from "../common/js/ethersProviderHook";
import CoinWrite from "../components/tokenlist/CoinWrite";
import TransferMessage from "../components/transferCoin/TransferMessage";

interface ITransferInfo {
    pageTitle:string;
    pageType:string;
    linkType:string;
    linkName:string;
    coinId:string;
    coinTypeCode: string,
    coinContAddr: string,
    coinDecimalVal: string,
    coinSymbolName:string;
    coinSymbolImgFileId:string;
    mainnetId:string;
    mainnetLinkName:string;
    sendUserId:string;
    sendUserName:string;
    sendWalletAddress:string;
    countryNum:string,
    phoneNum:string,
    zigleFee:number;
    amount:number;
    safeOn:boolean;
    confirmTxt:string;
    txId:string;
    linkUrl:string;
    message:string;
}

export default function TransferCoin() {
    const navigate = useNavigate();
    const {state} = useLocation();
    const { t } = useTranslation();

    const [stateValue, setStateValue] = useState(state ? state.tmpLinkType : '');
    const { address, isConnected } = useAccount();
    
    const chainId = useSelector((state:any) => state.walletReducer.chainId);
    const disconnect = useSelector((state:any) => state.walletReducer.disconnect);
    const mainNetDetail = useSelector((state: any) => state.walletReducer.mainNetDetail);
    const [mainnetList, setMainnetList] = useState<any[]>([]);
    const [mainnetItem, setMainnetItem] = useState<IMainnetInfo>();
    const [pageMode, setPageMode] = useState('list');

    const [useBottom, setUseBottom] = useState(true);
    const [home, setHome] = useState(true);
    const [showCoinPopup, setShowCoinPopup] = useState(false);
    const zigleWalletAddress = process.env.REACT_APP_WALLET_ADDRESS;
    const provider = useWalletEthersPorvider();
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const [depth, setDepth] = useState(0);
    const [pageTitle, setPageTitle] = useState('');
    const [mainnetInfo, setMainnetInfo] = useState<IMainnetInfo>();
    const [transferInfo, setTransferInfo] = useState<ITransferInfo>({
        pageTitle:'',
        pageType:'transfer',
        linkType:'',
        linkName:t('송금 방식'),
        coinId: '',
        coinTypeCode: '',
        coinContAddr: '',
        coinDecimalVal: '',
        coinSymbolName: t('송금 코인'),
        coinSymbolImgFileId:require('../assets/icons/question2.png'),
        mainnetId : '',
        mainnetLinkName: '',
        sendUserId : '',
        sendUserName : '',
        sendWalletAddress:'',
        countryNum:'',
        phoneNum:'',
        zigleFee:0,
        amount : 0,
        safeOn: false,
        confirmTxt: '',
        txId : '',
        linkUrl: '',
        message : ''
    })

    const txIdResult = (result:any,
                        resultMessage:any,
                        send_token_amount:any,
                        account:any,
                        to_address:any,
                        mainnetId:any,
                        coinId:any,
                        linkMngId:any)=>{
        if(result=="success")
        {
            if(transferInfo.linkType === 'safeTrans')
            {
                CommonService.transactionInsert(resultMessage, send_token_amount, account, to_address, mainnetId, coinId, linkMngId, transferInfo.sendUserId, transferInfo.message).then(
                    (res) => {
                    setTransferInfo(
                        {...transferInfo,
                            txId: resultMessage
                        }
                    );
                    setDepth(10);
                });
            }else{
                PhoneService.phoneDeposit(resultMessage, account, to_address, transferInfo).then(
                    (res) => {
                        setTransferInfo(
                            {...transferInfo,
                                confirmTxt:res.data.confirmTxt,
                                linkUrl:res.data.linkUrl,
                                txId: resultMessage
                            }
                        );
                        setDepth(10);
                    });
            }


        } else {
            PopupService.newPopup(t("확인 필요")
                , t("메타마스크에서 트랜잭션을 승인해 주세요.")
                , ""
                , {
                    btnTxt: t("확인 했어요"),
                    onClick: () => {
                    }
                }
                , {}
            );
        }
    }

    const handleOnClick = (selectItem: any) =>{

        if(depth===0)
        {
            let tmpLinkName='';
            switch(selectItem){
                case 'safeTrans' :tmpLinkName = t('안전 송금');
                    break;
                case 'hpTrans' :tmpLinkName = t('전화번호 송금');
                    break;
                default :tmpLinkName = t('직접송금');
                    break;
            }
            setTransferInfo(
                {...transferInfo,
                    linkType:selectItem,
                    linkName:tmpLinkName
                }
            );
            setDepth(1);
            setHome(true);
        }else if(depth === 1){
            setTransferInfo({...transferInfo,
                coinId:selectItem.coinId,
                coinTypeCode: selectItem.typeCode,
                coinContAddr: selectItem.contAddr,
                coinDecimalVal: selectItem.decimalVal,
                coinSymbolName:selectItem.symbolName,
                coinSymbolImgFileId:selectItem.symbolImgFileId
            });
            setDepth(2);
            setHome(true);
        } else if(depth === 2)
        {
            if(transferInfo.linkType === 'safeTrans')
            {
                CommonService.getWalletLoginAddress(selectItem.userId).then(
                    (response) => {
                        if(!response.status)
                        {
                            setTransferInfo(
                                {...transferInfo,
                                    pageTitle:selectItem.doname+'에게\n'+t('안전송금'),
                                    sendUserId:selectItem.userId,
                                    sendUserName:selectItem.doname,
                                    sendWalletAddress: response.data.find((obj:any)=>{return obj.mainUse ==="Y"})?.walletAddress,
                                    zigleFee:0
                                }
                            );
                            setDepth(3);
                            setHome(true);
                        }
                    }
                )

            }else{
                let userName = selectItem.hpName;
                let userId = selectItem.donameId;
                if(!userName){
                    userName = selectItem.hpCountry + selectItem.hpNum;
                }
                setTransferInfo(
                    {...transferInfo,
                        pageTitle:userName+'에게\n'+t('전화번호 송금'),
                        sendUserId:userId,
                        sendUserName:userName,
                        countryNum:selectItem.hpCountry,
                        phoneNum:selectItem.hpNum,
                        sendWalletAddress: zigleWalletAddress!
                    }
                );
                setDepth(3);
                setHome(true);
            }


        }else if(depth ===3){
            setDepth(10);
            setHome(false);
        }
    }

    useEffect(() => {
        const fetchAddress = async () => {
            let setAddress = await address;
        };
        if (address) {
            fetchAddress();
        }
    }, [address]);

    useEffect(() => {
        async function fetchMainnetDetail() {
            const response = await CommonService.mainnetList('', 1, 100, '');
            setMainnetList(response.data);
            let setMainNetDetail = await mainNetDetail;

            setMainnetItem(setMainNetDetail);
            if(stateValue !==''){
                setTransferInfo({
                    ...transferInfo,
                    linkType:stateValue,
                    linkName:t('전화번호 송금'),
                    mainnetId: setMainNetDetail.mainnetId,
                    mainnetLinkName: setMainNetDetail.linkName,
                    zigleFee : setMainNetDetail.zigleFee
                });
                setDepth(1);
            }else{
                setTransferInfo({
                    ...transferInfo,
                    mainnetId: setMainNetDetail.mainnetId,
                    mainnetLinkName: setMainNetDetail.linkName,
                    zigleFee : setMainNetDetail.zigleFee
                });
            }

            setMainnetInfo(setMainNetDetail);
        }
        fetchMainnetDetail();

    },[mainNetDetail]);
    const onClickMainnet = () =>{
        setShowCoinPopup(true);
    }
    const onHandleClickMainnet = (selectItem:any) =>{
        if(selectItem.mainnetId !== transferInfo.mainnetId)
        {
            MetaMaskService.setSwitchNetwork(provider, parseInt(selectItem.chainId), selectItem.mainnetName, selectItem.symbolName, selectItem.decimalVal, selectItem.rpcUrl, selectItem.explorerUrl, '').then(
                (response) => {
                    if (!response.status) {
                    }
                }
            );
        }
        setShowCoinPopup(false);
    }
    useEffect(()=>{
        console.log("depth",depth)
        if(depth <= 2)
        {
            if(depth <2)
            {
                setUseBottom(true);
            }else{
                if(transferInfo.linkType ==='hpTrans')
                {
                    setUseBottom(false);
                }else{
                    setUseBottom(true);
                }
            }

        }else{
            setUseBottom(false);
        }

    },[depth])


    return (
        <PageRootContainer>
            <CoinPopup setShow={setShowCoinPopup} show={showCoinPopup} mainnetList={mainnetList} onHandleClickMainnet={onHandleClickMainnet} />
            {
                (pageMode === 'list') ? (
                    depth === 10 ?
                        (
                            <PageTitle rightSrc={require('../assets/icons/close_28.png')} onClickRight={()=>{ setDepth(0); }} />
                        )
                        :
                        (
                            depth === 0 ?
                                (
                                    <PageTitle home={home} textTitle={transferInfo.pageTitle} onClickLeft={()=>{ navigate(-1);}}  rightSrc={require('../assets/icons/link_history.png')} onClickRight={()=>{ navigate('/history/scv'); }}/>
                                ) : (
                                    <PageTitle  home={home} textTitle={transferInfo.pageTitle} onClickLeft={()=>{
                                        if(depth == 0)
                                        {
                                            navigate(-1);
                                        }else{
                                            if(depth === 9){
                                                setTransferInfo({...transferInfo,
                                                    message: ''
                                                });
                                                setDepth(3);
                                            }else if(depth ===3){
                                                setTransferInfo({...transferInfo,
                                                    pageTitle:'',
                                                    sendUserId:'',
                                                    sendUserName:'',
                                                    countryNum:'',
                                                    phoneNum:'',
                                                    sendWalletAddress: ''
                                                });
                                                setDepth(2);
                                            }else if(depth ===2){
                                                setTransferInfo({...transferInfo,
                                                    coinId: '',
                                                    coinTypeCode: '',
                                                    coinContAddr: '',
                                                    coinDecimalVal: '',
                                                    coinSymbolName: t('송금 코인'),
                                                    coinSymbolImgFileId:require('../assets/icons/question2.png'),
                                                });
                                                setDepth(1);
                                            }else if(depth ===1){
                                                setTransferInfo(
                                                    {...transferInfo,
                                                        linkType:'',
                                                        linkName:t('송금 방식'),
                                                        pageTitle:'',
                                                        sendUserId:'',
                                                        sendUserName:'',
                                                        countryNum:'',
                                                        phoneNum:'',
                                                        sendWalletAddress: ''
                                                    }
                                                );
                                                setDepth(0);
                                            }

                                        }
                                    }}
                                                mainnetName = { (depth ===1) ? transferInfo.mainnetLinkName : ''}
                                                rightSrc={(depth === 1) ? require('../assets/imges/plus_icon.png') : ''}
                                                onClickRight={() => {
                                                    if (depth === 1) {
                                                        setPageMode('write');
                                                    }
                                                }}
                                                onClickMainnet={onClickMainnet}
                                    />
                                )
                        )
                ) : (
                    <PageTitle
                        textTitle={t('토큰 추가 요청')}
                        onClickLeft={()=>setPageMode('list')}
                    />
                )
            }

            <PageContent style={{marginBottom:'100px'}}>
                {
                    (pageMode === 'list') ? (
                        <>
                            {(depth === 0) && <TransferStep3 handleOnClick={handleOnClick}/>}
                            {(depth === 1) && <CoinSelect itemInfo={transferInfo} handleOnClick={handleOnClick} setPageMode={setPageMode}/>}

                            {(transferInfo.linkType === 'safeTrans' && depth === 2) && (
                                <TransferFriend setDepth={setDepth} transferInfo={transferInfo}
                                                setTransferInfo={setTransferInfo} handleOnClick={handleOnClick}/>)}
                            {(transferInfo.linkType === 'safeTrans' && depth === 3) && (
                                <TransferFriend2 setDepth={setDepth} mainnetInfo={mainnetInfo} transferInfo={transferInfo}
                                                 setTransferInfo={setTransferInfo} handleOnClick={handleOnClick}
                                                 txIdResult={txIdResult}/>)}

                            {(transferInfo.linkType === 'hpTrans' && depth === 2) && (
                                <TransferPhone setDepth={setDepth} transferInfo={transferInfo}
                                               setTransferInfo={setTransferInfo} handleOnClick={handleOnClick}/>)}
                            {(transferInfo.linkType === 'hpTrans' && depth === 3) && (
                                <TransferPhone2 setDepth={setDepth} mainnetInfo={mainnetInfo} transferInfo={transferInfo}
                                                setTransferInfo={setTransferInfo} handleOnClick={handleOnClick}
                                                txIdResult={txIdResult}/>)}

                            {(depth === 9) && (
                                <TransferMessage mainnetInfo={mainnetInfo} transferInfo={transferInfo}
                                                setTransferInfo={setTransferInfo} handleOnClick={handleOnClick}
                                                txIdResult={txIdResult}/>)}

                            {(depth === 10) && <TransferEnd transferInfo={transferInfo} setTransferInfo={setTransferInfo}
                                                            setDepth={setDepth}/>}

                            {
                                useBottom && (
                                    <FlexBottom86 style={{
                                        padding: '24px 20px',
                                        borderRadius: '24px',
                                        boxShadow: 'inset 0 0.6px 1px 0 #dbdee6',
                                        backgroundColor: '#ffffff'
                                    }}>
                                        <FlexColumn>
                                            <FlexRowSpace>
                                                <FlexRowStart>
                                                    <div style={{paddingRight: '12px'}}>
                                                        <img src={transferInfo.coinSymbolImgFileId} alt="bgIcon" width={38}/>
                                                    </div>
                                                    <FlexColumn gap={3}>
                                                        <div
                                                            className={transferInfo.coinId ? styles.txt16_B02 : styles.txt16_W03}>{transferInfo.coinSymbolName}</div>
                                                        <FlexRowStart className={styles.txt12_B05}>
                                                            <div style={{
                                                                marginRight: '8px',
                                                                width: '4px',
                                                                height: '4px',
                                                                borderRadius: '50%',
                                                                backgroundColor: '#66bf9f'
                                                            }}></div>
                                                            <div
                                                                className={transferInfo.mainnetId ? styles.txt12_200_B04 : styles.txt12_W03}>{transferInfo.mainnetLinkName}</div>
                                                        </FlexRowStart>
                                                    </FlexColumn>
                                                </FlexRowStart>
                                                <FlexRowEnd>
                                                    {
                                                        transferInfo.linkType ?
                                                            (
                                                                transferInfo.linkType === 'safeTrans' ?
                                                                    (<div style={{
                                                                        backgroundColor: '#ebf2ff',
                                                                        color: '#3a71ff',
                                                                        borderRadius: '100px',
                                                                        padding: '9px 20px'
                                                                    }}
                                                                          className={styles.txt15_B02}>{transferInfo.linkName}</div>)
                                                                    :
                                                                    (
                                                                        transferInfo.linkType === 'hpTrans' ?
                                                                            (<div style={{
                                                                                backgroundColor: '#e2fffd',
                                                                                color: '#53cec7',
                                                                                borderRadius: '100px',
                                                                                padding: '9px 20px'
                                                                            }}
                                                                                  className={styles.txt15_B02}>{transferInfo.linkName}</div>)
                                                                            :
                                                                            (<div style={{
                                                                                backgroundColor: '#f2f4f6',
                                                                                color: '#53cec7',
                                                                                borderRadius: '100px',
                                                                                padding: '9px 20px'
                                                                            }}
                                                                                  className={styles.txt15_B02}>{transferInfo.linkName}</div>)
                                                                    )
                                                            )
                                                            :
                                                            (<div className={styles.txt15_W03}>{transferInfo.linkName}</div>)
                                                    }
                                                </FlexRowEnd>
                                            </FlexRowSpace>
                                        </FlexColumn>
                                    </FlexBottom86>
                                )
                            }
                        </>
                    ) :
                    (
                        <CoinWrite setPageMode={setPageMode}  />
                    )
                }

            </PageContent>
        </PageRootContainer>
    );
}