import styles from "../../../common/css/zigle.module.scss";
import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import styled, {css} from "styled-components";
import PWAInstallPrompt from "../../../PWAInstallPrompt";

export const PageRootContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  background-color: white;
`;

export const PageContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 76px;
  margin-bottom: 56px;
`;

interface IFlex {
  gap?: number;
  bgColor?: string;
}

export const FC = styled.div<IFlex>`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${(props) => (props.gap ? "gap:" + props.gap + "px" : "")};
`;

export const FR = styled.div<IFlex>`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  ${(props) => (props.gap ? "gap:" + props.gap + "px" : "")};
`;

export const FlexColumn = styled(FC)`
  align-items: flex-start;
  justify-content: center;
`;

export const FlexColumnCenter = styled(FC)`
  justify-content: center;
align-items: center;
`;


export const FlexColumEnd = styled(FC)`
  align-items: flex-end;
  justify-content: center;
`;

export const FlexColumSpace = styled(FC)`
  align-items: center;
  justify-content: space-between;
`;


export const FlexRowSpace = styled(FR)`
  justify-content: space-between;
  align-items: center;
`;

export const FlexRowEnd = styled(FR)`
  justify-content: flex-end;
  align-items: center;
`;

export const FlexRowStart = styled(FR)`
  justify-content: flex-start;
  align-items: center;
`;

export const FlexRowAround = styled(FR)`
  justify-content: space-around;
  align-items: center;
`;

export const FlexRowCenter = styled(FR)`
  justify-content: center;
  align-items: center;
`;

export const FlexBottom = styled(FR)`
  position:fixed;
  top: calc(100% - (56px + constant(safe-area-inset-bottom)));
  top: calc(100% - (56px + env(safe-area-inset-bottom)));
  justify-content: space-between;
  align-items: center;
  width: calc(100%);
  min-width: 360px;
  max-width: 412px;
  height: calc(56px + constant(safe-area-inset-bottom));
  height: calc(56px + env(safe-area-inset-bottom));
  background-color:#FFFFFF;
  margin-left:-20px;
`;

export const FlexBottom79 = styled(FR)`
  position:fixed;
  top: calc(100% - (79px + constant(safe-area-inset-bottom)));
  top: calc(100% - (79px + env(safe-area-inset-bottom)));
  justify-content: space-between;
  align-items: center;
  width: calc(100%);
  min-width: 360px;
  max-width: 412px;
  height: calc(79px + constant(safe-area-inset-bottom));
  height: calc(79px + env(safe-area-inset-bottom));
  background-color:#FFFFFF;
  margin-left:-20px;
`;

export const FlexBottom86 = styled(FR)`
  position:fixed;
  top: calc(100% - (86px + constant(safe-area-inset-bottom)));
  top: calc(100% - (86px + env(safe-area-inset-bottom)));
  justify-content: space-between;
  align-items: center;
  width: calc(100%);
  min-width: 360px;
  max-width: 412px;
  height: calc(86px + constant(safe-area-inset-bottom));
  height: calc(86px + env(safe-area-inset-bottom));
  background-color:#FFFFFF;
  margin-left:-20px;
`;

export const FlexBottom143 = styled(FC)`
  position:fixed;
  top: calc(100% - (143px + constant(safe-area-inset-bottom)));
  top: calc(100% - (143px + env(safe-area-inset-bottom)));
  justify-content: space-between;
  align-items: center;
  width: calc(100%);
  min-width: 360px;
  max-width: 412px;
  height: calc(143px + constant(safe-area-inset-bottom));
  height: calc(143px + env(safe-area-inset-bottom));
  background-color:#FFFFFF;
  margin-left:-20px;
`;
export const FlexBottomStart = styled(FC)`
 position:fixed;
 top: calc(100% - (96px + constant(safe-area-inset-bottom)));
  top: calc(100% - (96px + env(safe-area-inset-bottom)));
  justify-content: flex-start;
  align-items: center;
  width: calc(100%);
  min-width: 360px;
  max-width: 412px;
  height: calc(96px + constant(safe-area-inset-bottom));
  height: calc(96px + env(safe-area-inset-bottom));
  background-color:#FFFFFF;
  margin-left:-20px;
`;
export const FlexBottomFree = styled(FC)`
  position:fixed;
  justify-content: flex-start;
  align-items: center;
  width: calc(100%);
  min-width: 360px;
  max-width: 412px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding:24px 20px;
  background-color:#ffffff;  
  animation: fadeInUp 0.5s;
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }
`;
export const YelloBox = styled(FC)`
  gap: 8px;
  width: 100%;
  padding: 20px;
  border-radius: 12px;
  background-color: #fffbeb;
`;

export const GrayBox = styled(FC)`
  gap: 4px;
  width: 100%;
  padding: 20px;
  border-radius: 16px;
  background-color: #f9fafb;
`;

export const GrayBoxW00 = styled(FC)`
  width: 100%;
  padding: 16px 12px;
  border-radius: 16px;
  border: solid 1px #e3e5ed;
  background-color: #f8f9fc;
  align-items: center;
  justify-content: center;
`;

export const GrayBoxW01 = styled(FC)`
  width: 100%;
  padding: 16px 12px;
  border-radius: 8px;
  background-color: #f2f4f6;
  align-items: center;
  justify-content: center;
`;

export const GrayBoxW00Small = styled(FC)` 
  height: 80px;
  gap: 4px;
  padding: 4px 0;
  border-radius: 12px;
  background-color: #f8f9fc;
  align-items: center;
  justify-content: center;
`;

export const Circle = styled(FC)`
  width: 20px;
  height: 20px;
  margin: 0 4px 0 0;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 800;
  color: white;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#ed6957")};
`;

export const ZBadge = styled(FC)`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#f2f4f6")};
  border-radius: 100px;
  padding: 4px 4px;
  font-size: 11px;
  font-weight: 500;
  justify-content: center;
  align-items: center;
`;

interface IToggle {
  toggle?: boolean;
}

export const ToggleBtn = styled.div<IToggle>`
  width: 36px;
  height: 20px;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  background-color: ${(props) => (!props.toggle ? "#E7E9ED" : "#3A71FF")};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease-in-out;
`;
export const ToggleCircle = styled.div<IToggle>`
  background-color: white;
  width: 14px;
  height: 14px;
  border-radius: 50px;
  position: absolute;
  left: 5%;
  transition: all 0.5s ease-in-out;
  ${(props) =>
    props.toggle &&
    css`
      transform: translate(20px, 0);
      transition: all 0.5s ease-in-out;
    `}
`;


export const RightToLeft = styled.div`
  width: 100%;
  animation: smoothAppear 0.3s;

  @keyframes smoothAppear {
    from {
      opacity: 0;
      transform: translateX(100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;

export const CoinBox = styled(FR)`
  height: 80px;
  align-self: stretch;
  flex-grow: 0;
  justify-content: space-between;
  align-items: center;
  padding: 21px 16px;
  box-sizing: border-box;
  border-radius: 16px;
  border: solid 1px #f2f4f6;
`;

export const Bullet = styled(FR)`
  &::before {
    content: " ";
    //content: "●";
    background-color: ${(props) => (props.color ? props.color : "#66bf9f")};
    font-size: 8px;
    margin-right: 4px;
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }
  align-items: center;
`;

export const BellDiv = styled(FR)`
  &::before {
    content: " ";
    background: url("../../../assets/icons/bell.png");
    margin-right: 10px;
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
  }
  align-items: center;
`;

export const ZSelect = styled.select`
  border: 0px;
  text-align: right;
  padding: 0 10px;
  background: #FFFFFF;
`;
export const FullScreen = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100vh;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  z-index: 11;
  background: rgba(0, 0, 0, 0.5);
`;

export const PopupBodyWrapper = styled.div`
  box-sizing: border-box;
  min-width: 360px;
  max-width: 412px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  //padding: 0px 20px 0px 20px;
`;
export const QrArea = styled.div`
  width: 160px;
  height: 160px;
  flex-grow: 0;
  margin: 0 4px 20px;
  padding: 10.5px 13.2px 11.4px 10.5px;
  border-radius: 24px;
  border: solid 1px #d4d8e2;
`;
export const EMPTY_SRC = " ";
interface IPageTitleProps {
  leftText?: string;
  leftSrc?: string;
  mainnetName?: string;
  onClickMainnet?: () => void;
  onClickLeft?: () => void;
  textSrc?: string;
  textTitle?: string;
  rightSrc?: string;
  rightText?: string;
  onClickRight?: () => void;
  pwaInstall?: string;
  home?:boolean;
  thema?:string;
}
export default function PageTitle(props: IPageTitleProps) {
  const navigate = useNavigate();

  return (
    <FlexColumn className={props.thema === 'black' ? styles.pageTitleBlack : styles.pageTitle} style={{ height: "56px" }}>
      <FlexRowSpace>
        <FlexRowStart style={{width:'30%'}}>
          {props.leftText && (
            <span
              onClick={() => {
                if (props.onClickLeft) props.onClickLeft();
              }}
            >
              {props.leftText}
            </span>
          )}
          {(props.leftSrc && props.leftSrc.length>1) && (
            <img
              src={props.leftSrc}
              width={28}
              alt={"back_arrow"}
              onClick={() => {
                if (props.onClickLeft) props.onClickLeft();
              }}
            />
          )}
          {(!props.leftSrc && (props.onClickLeft)) && (
              <img
                  src={require("../../../assets/imges/back_arrow.png")}
                  width={28}
                  alt={"back_arrow"}
                  style={{ marginLeft: "-8px" }}
                  onClick={() => {
                    if (props.onClickLeft) props.onClickLeft();
                  }}
              />
          )}
          <img src={require('../../../assets/icons/home.png')} width={28} onClick={()=>navigate('/')}/>
        </FlexRowStart>
        {
          props.mainnetName ? (
                  <FlexRowCenter  style={{width:'40%', whiteSpace:'pre-wrap', textAlign:'center'}} className={styles.txt14_B02}>
                    <div style={{border:' 1px solid #D4D8E2', padding:'3px 10px 3px 16px', borderRadius:'100px'}}
                         onClick={() => {
                           if (props.onClickMainnet) props.onClickMainnet();
                         }}
                    >{props.mainnetName}<img src={require('../../../assets/icons/arrow_select.png')} width={20} /></div>
                  </FlexRowCenter>
              )
              :
              (
                  <FlexRowCenter  style={{width:'40%', whiteSpace:'pre-wrap', textAlign:'center'}} className={styles.txt15_B02}>
                    {props.textSrc ? (
                        <img src={props.textSrc} width={20} style={{ padding: "2.9px" }} />
                    ) : null}
                    {props.textTitle}
                  </FlexRowCenter>
              )
        }

        <FlexRowEnd  style={{width:'30%'}} className={styles.txt15_Crimson}>
          {props.rightText && (
            <span
              onClick={() => {
                if (props.onClickRight) props.onClickRight();
              }}
            >
              {props.rightText}
            </span>
          )}
          {props.rightSrc && (
            <img
              src={props.rightSrc}
              height={28}
              alt={"back_arrow"}
              onClick={() => {
                if (props.onClickRight) props.onClickRight();
              }}
            />
          )}
          {!props.rightText && !props.rightSrc && (
            <div style={{ width: "28px" }}></div>
          )}
          {
            props.pwaInstall && (<PWAInstallPrompt pwaVisible={props.pwaInstall}/>)
          }
        </FlexRowEnd>
      </FlexRowSpace>
    </FlexColumn>
  );
}
