import React, {CSSProperties, useEffect, useState} from "react";

import {inspect} from "util";
import styles from "../../common/css/zigle.module.scss";
import BottomLayer, {FlexBottomButton, Overlay} from "../common/container/BottomLayer";
import {FlexBottomFree, FlexColumn, FlexRowEnd, FlexRowStart} from "../common/container/PageContainer";
import BottomButton from "../common/button/BottomButton";
import {useSelector} from "react-redux";
import MetaMaskService from "../../services/metamask.service";
import {Toast, ToastError} from "../common/popup/ToastAlert";
import PopupService from "../../services/popup.service";
import {useAccount} from "wagmi";
import {useWalletEthersPorvider} from "../../common/js/ethersProviderHook";
import {useTranslation} from "react-i18next";

interface ITokenDetail {
    show: boolean;
    setShow: any;
    mainnetItem: any;
}
function TokenPopup(props:ITokenDetail) {
    const { t } = useTranslation();
    
    const chainId = useSelector((state:any) => state.walletReducer.chainId);
    const disconnect = useSelector((state:any) => state.walletReducer.disconnect);
    const { address, isConnected } = useAccount();
    const provider = useWalletEthersPorvider();
    const onclickHandleMainNetSave = ()=>{
        if(!address){
            ToastError(t("지갑연결을 확인해 주세요."));
            return false;
        }
        MetaMaskService.setSwitchNetwork(provider, parseInt(props.mainnetItem.chainId), props.mainnetItem.mainnetName, props.mainnetItem.symbolName, props.mainnetItem.decimalVal, props.mainnetItem.rpcUrl,  props.mainnetItem.explorerUrl, '').then(
            (response) => {
                console.log("response",response)
                if(response.status)
                {
                    props.setShow(false);
                }
            }
        );
    }
    return (
        <>
            {
                props.show &&
                (
                    <Overlay>
                        <FlexBottomFree style={{top: 'calc(100% - 180px)', height: '180px'}}>
                            <FlexRowEnd>
                                <img src={require('../../assets/icons/close_28.png')} width={28} height={28}
                                     alt={"close"}
                                     onClick={() => props.setShow(false)}/>
                            </FlexRowEnd>
                            <FlexColumn>
                                <FlexColumn>
                                    <div style={{padding: '15px 20px'}} className={styles.txt16_B04}
                                         onClick={() => {
                                             PopupService.snsLinkMobile(window.location.protocol + "//" + window.location.host + "/tokenList/" + props.mainnetItem.linkName);
                                         }}
                                    >{t("메인넷 추가 링크 공유")}
                                    </div>
                                    <div style={{padding: '15px 20px'}} className={styles.txt16_B04}
                                         onClick={() => {
                                             onclickHandleMainNetSave()
                                         }}>{t("내 메타마스크 메인넷 변경")}
                                    </div>
                                </FlexColumn>
                            </FlexColumn>
                        </FlexBottomFree>
                    </Overlay>
                )
            }
        </>
    );
}

export default TokenPopup;
