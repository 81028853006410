import MetaMaskService from "../../services/metamask.service";
import {
    FlexBottom,
    FlexBottom143,
    FlexBottom86,
    FlexColumn,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, ZSelect
} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import React, {useEffect, useRef, useState} from "react";
import {TextField} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import PhonePopup from "./PhonePopup";
import {CONNECT} from "../reducer/walletReducer";
import BottomButton from "../common/button/BottomButton";
import DefaultInput from "../common/input/DefaultInput";
import CommonService from "../../services/common.service";
import {useTranslation} from "react-i18next";

interface ITransferStep {
    transferInfo:any;
    setTransferInfo:any;
    setDepth:any;
    handleOnClick?: (item: string)=> void;
}

export default function TransferPhone(props: ITransferStep) {
    const [btnActive, setBtnActive] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [phoneShow, setPhoneShow] = useState(false);
    const [phoneItem, setPhoneItem] = useState<any>([])
    const [countryList, setCountryList] = useState<any[]>([]);
    const inputRef = useRef<HTMLFormElement>(null);

    const {
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit,
        setError,
        clearErrors,
    } = useForm();
    const selectItem =(item:any)=>{

        setPhoneItem(item);
        if(inputRef.current)
        {
            inputRef.current.hpCountry.value = item.hpCountry;
            inputRef.current.hpNum.value = item.hpNum;
            inputRef.current.hpCountry.focus();
            inputRef.current.hpNum.focus();

            setBtnActive(true);
        }
        setPhoneShow(false);
    }
    const onSubmit = (data: any) => {

        if (props.handleOnClick) {
            props.handleOnClick(phoneItem);
        }
    }
    useEffect(()=>{
        CommonService.getSelectCountry().then((data) => {
            setCountryList([...countryList, ...data.data]);

            setPhoneItem({
                ...phoneItem ,
                hpCountry : data.data[0].countryNum
            })

        });
    }, []);
    useEffect(()=>{
        console.log(errors);
    }, [errors]);
    useEffect(()=>{
        if(phoneItem.hpCountry && phoneItem.hpNum)
        {
            setBtnActive(true);
        }
    },[phoneItem])
    return (
        <FlexColumn gap={12}>
            <PhonePopup show={phoneShow} setShow={setPhoneShow}  setDepth={props.setDepth} transferInfo={props.transferInfo} setTransferInfo={props.setTransferInfo}  handleOnClick={selectItem} />
            <FlexColumn className={styles.txt20_500_B01}>
                {t("누구한테 보낼까요?")}
            </FlexColumn>
            <FlexColumn gap={34}>
                <FlexColumn gap={2}>
                    <form ref={inputRef} onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                        <DefaultInput
                            error={errors.hpNum}
                            marginBottom={3}
                            inputElement={
                                <FlexRowStart>
                                    <ZSelect {...register('hpCountry')} onChange={(el)=>{

                                        setPhoneItem({
                                            ...phoneItem ,
                                            hpCountry : el.target.value
                                        })
                                    }}
                                             className={styles.inputText_33} style={{height:'46px', width:'100px', textAlign:'center', fontSize:'13px', color: '#111111', borderBottom: 'solid 1px #f2f4f8'}} >

                                        {
                                            countryList.length > 0 && (
                                                countryList.map((item, index)=>{
                                                    return(
                                                        <option key={item.countryNum + index} value={item.countryNum}>+{item.countryNum} {item.kor}</option>
                                                    )
                                                })
                                            )
                                        }
                                    </ZSelect>
                                    <input
                                        type={"number"}
                                        className={styles.inputText_44}
                                        placeholder={t("받는 사람의 전화번호를 입력해주세요.")}
                                        maxLength={12}
                                        {...register("hpNum", {
                                            required: t("받는 사람의 전화번호를 입력해주세요."),
                                            maxLength:12,
                                            pattern: {
                                                value: /^([0-9]{2,4})([0-9]{3,4})([0-9]{4})$/i,
                                                    message: t("휴대폰 형식에 맞지 않습니다."),
                                                }
                                        })}
                                        onChange={(el)=>{

                                            setPhoneItem({
                                                ...phoneItem ,
                                                hpNum : el.target.value
                                            })}
                                        }
                                    />
                                </FlexRowStart>
                            }
                        />
                    </form>
                </FlexColumn>
                <FlexColumn>
                    <FlexRowStart>
                        <div className={styles.txt13_B04} style={{fontWeight:'500', backgroundColor:'#f2f4f6', padding:'10px 16px', borderRadius:'8px'}} onClick={()=>setPhoneShow(true)}>
                            {t("연락처")}
                        </div>
                    </FlexRowStart>
                </FlexColumn>
            </FlexColumn>
            <FlexBottom143  gap={5} style={{padding:'24px 20px', borderRadius:'24px', boxShadow:'inset 0 0.6px 1px 0 #dbdee6', backgroundColor:'#ffffff'}}>
                <FlexColumn>
                    <FlexRowSpace>
                        <FlexRowStart>
                            <div style={{paddingRight:'12px'}}>
                                <img src={props.transferInfo.coinSymbolImgFileId} alt="bgIcon" width={38} />
                            </div>
                            <FlexColumn gap={3}>
                                <div className={props.transferInfo.coinId ? styles.txt16_B02 : styles.txt16_W03}>{props.transferInfo.coinSymbolName}</div>
                                <FlexRowStart className={styles.txt12_B05}>
                                    <div style={{marginRight:'8px', width:'4px', height:'4px', borderRadius:'50%', backgroundColor:'#66bf9f'}}></div>
                                    <div className={props.transferInfo.mainnetId ? styles.txt12_200_B04 : styles.txt12_W03}>{props.transferInfo.mainnetLinkName}</div>
                                </FlexRowStart>
                            </FlexColumn>
                        </FlexRowStart>
                        <FlexRowEnd>
                            {
                                props.transferInfo.linkType ?
                                    (
                                        props.transferInfo.linkType === 'safeTrans' ?
                                            (<div style={{backgroundColor:'#ebf2ff', color:'#3a71ff', borderRadius:'100px', padding:'9px 20px'}} className={styles.txt15_B02}>{props.transferInfo.linkName}</div>)
                                            :
                                            (
                                                props.transferInfo.linkType === 'hpTrans' ?
                                                    (<div style={{backgroundColor:'#e2fffd', color:'#53cec7', borderRadius:'100px', padding:'9px 20px'}}  className={styles.txt15_B02}>{props.transferInfo.linkName}</div>)
                                                    :
                                                    (<div style={{backgroundColor:'#f2f4f6', color:'#53cec7', borderRadius:'100px', padding:'9px 20px'}}  className={styles.txt15_B02}>{props.transferInfo.linkName}</div>)
                                            )
                                    )
                                    :
                                    (<div className={styles.txt15_W03}>{props.transferInfo.linkName}</div>)
                            }
                        </FlexRowEnd>
                    </FlexRowSpace>
                </FlexColumn>
            </FlexBottom143>
            <FlexBottom>
                <FlexColumn>
                    <BottomButton
                        isActive={btnActive}
                        backgroundColor={"#3a71ff"}
                        textColor={"white"}
                        // border={"solid 1px #dbdee6"}
                        onClick={
                            handleSubmit(onSubmit)
                        }
                        text={t("다음")}
                    />
                </FlexColumn>
            </FlexBottom>
        </FlexColumn>
    )
}