import axios from "axios";
import ProfileService, { getHeader } from "./profile.service";
import { checkStatus401, checkStatus401RstType } from "./common.service";
import { JsonData } from "../common/dto/CommonDto";
import {IAirDropReqDto} from "../common/dto/request/AirdropDto";
import {ToastError} from "../components/common/popup/ToastAlert";


let API_URL = "";
if (process.env.REACT_APP_ENVTEXT === "local") {
    API_URL = "/api";
} else {
    API_URL = process.env.REACT_APP_API_URL ?? "https://api.zigle.link/api";
}
const createAirdrop = (params:FormData | undefined) => {
    return axios
        .post(API_URL + "/v1/user/airdrop/createAirdrop", params, {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return checkStatus401RstType(error.response);
        });
};

const airdropList = (
    currentPage: number,
    blockSize: number,
    modeType: string,
    subType:string,
    pageMode?:string
) =>{
    return axios
        .get(API_URL + "/v1/user/airdrop/airdropList", {
            params: {
                currentPage: currentPage,
                blockSize: blockSize,
                airdropState: modeType,
                orderBy: subType,
                pageMode:pageMode
            },
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return checkStatus401RstType(error.response);
        });
}
const displayOpen = (params:FormData | undefined) => {
    return axios
        .post(API_URL + "/v1/user/airdrop/displayOpen", params, {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return checkStatus401RstType(error.response);
        });
};
const updateAirdropStartDay = (params:FormData | undefined) => {
    return axios
        .post(API_URL + "/v1/user/airdrop/updateStartDay", params, {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return checkStatus401RstType(error.response);
        });
};
const updateAirdropState = (params:FormData | undefined) => {
    return axios
        .post(API_URL + "/v1/user/airdrop/updateAirdropState", params, {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return checkStatus401RstType(error.response);
        });
};
const airdropFeeList = () =>{
    return axios.get(API_URL + "/v1/user/airdrop/airdropFeeList", {
        headers: {
            ...getHeader(),
        },
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return checkStatus401RstType(error.response);
        });
}
const airdropUserList = (mngIdx: string) =>{
    return axios.get(API_URL + "/v1/user/airdrop/airdropUserList", {
        params: {
            airdropMngId:mngIdx
        },
        headers: {
            ...getHeader(),
        },
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return checkStatus401RstType(error.response);
        });
}
const createAirdropDe = (params:any) => {
    return axios
        .post(API_URL + "/v1/user/airdrop/createAirdropDe", params, {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return checkStatus401RstType(error.response);
        });
};

const airdropFeeDeposit = (params:any) => {
    return axios
        .post(API_URL + "/v1/user/airdrop/airdropFeeDeposit", params, {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return { status: true, msg: "" };
        })
        .catch((error) => {
            ToastError(error.response.data.message);
            return { status: false, msg: error.response.data.message };
        });
};
const airdropFeeHis = ( mainnetId:string,  currentPage: number, blockSize: number) =>{
    return axios.get(API_URL + "/v1/user/airdrop/airdropFeeHis", {
        params: {
            mainnetId: mainnetId,
            currentPage: currentPage,
            blockSize: blockSize,
        },
        headers: {
            ...getHeader(),
        },
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return checkStatus401RstType(error.response);
        });
}

const airdropView = ( linkCode:string) =>{
    return axios.get(API_URL + "/v1/anony/airdrop/airdropView/"+linkCode, {
        headers: {
            ...getHeader(),
        },
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return checkStatus401RstType(error.response);
        });
}
const airdropConfirm = (params:any) => {
    return axios
        .post(API_URL + "/v1/anony/airdrop/airdropConfirm", params, {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return { status: true, msg: "" };
        })
        .catch((error) => {
            ToastError(error.response.data.message);
            return { status: false, msg: error.response.data.message };
        });
};

const airdropFeeUser = ( mainnetId:string) =>{
    return axios.get(API_URL + "/v1/user/airdrop/airdropFeeUser", {
        params: {
            mainnetId: mainnetId,
        },
        headers: {
            ...getHeader(),
        },
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return checkStatus401RstType(error.response);
        });
}
const updateAirdropFiEnd = (params:any) => {
    return axios
        .post(API_URL + "/v1/user/airdrop/updateAirdropFiEnd", params, {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return checkStatus401RstType(error.response);
        });
};
const airdropMyList = ( currentPage: number, blockSize: number) =>{
    return axios.get(API_URL + "/v1/user/airdrop/airdropMyList", {
        params: {
            currentPage: currentPage,
            blockSize: blockSize,
        },
        headers: {
            ...getHeader(),
        },
    })
    .then((response) => {
        return response.data;
    }).catch((error) => {
        return checkStatus401RstType(error.response);
    });
}

const airdropFeeTransfer = (params:any) => {
    return axios
        .post(API_URL + "/v1/user/airdrop/airdropFeeTransfer", params, {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return { status: true, msg: "" };
        })
        .catch((error) => {
            ToastError(error.response.data.message);
            return { status: false, msg: error.response.data.message };
        });
};

const airdropFoundation = (params:any) => {
    return axios
        .post(API_URL + "/v1/user/airdrop/airdropFoundation", params, {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return { status: true, msg: "" };
        })
        .catch((error) => {
            ToastError(error.response.data.message);
            return { status: false, msg: error.response.data.message };
        });
};

const airdropRefund = (airdropMngId: string) =>{
    return axios.get(API_URL + "/v1/user/airdrop/airdropRefund/"+airdropMngId, {
        headers: {
            ...getHeader(),
        },
    })
        .then((response) => {
            return response.data;
        }).catch((error) => {
            return checkStatus401RstType(error.response);
        });
}
const AirdropService = {
    createAirdrop,
    airdropList,
    displayOpen,
    updateAirdropStartDay,
    updateAirdropState,
    airdropUserList,
    createAirdropDe,
    airdropFeeList,
    airdropFeeDeposit,
    airdropFeeHis,
    airdropView,
    airdropConfirm,
    airdropFeeUser,
    updateAirdropFiEnd,
    airdropMyList,
    airdropFeeTransfer,
    airdropRefund,
    airdropFoundation
};

export default AirdropService;