import React, {useEffect, useRef, useState} from "react";

import styles from "../common/css/zigle.module.scss";
import PageTitle, {
    FlexBottom,
    FlexColumn, FlexColumnCenter,
    FlexRowAround, FlexRowCenter, FlexRowEnd, FlexRowSpace, FlexRowStart,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import {useNavigate, useParams} from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import {Toast, ToastError} from "../components/common/popup/ToastAlert";
import LinkPopup from "../components/myLink/LinkPopup";
import PopupService from "../services/popup.service";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import BottomButton from "../components/common/button/BottomButton";
import {CONNECT} from "../components/reducer/walletReducer";
import AirdropService from "../services/airdrop.service";
import {IAirdropList} from "../common/dto/AirdropList";
import CommonService from "../services/common.service";
import moment from "moment";
import MetaMaskService from "../services/metamask.service";
import {ellipseAddress} from "../common/js/utilities";
import {useAccount} from "wagmi";
import {useWalletEthersPorvider} from "../common/js/ethersProviderHook";
import Popup from "../services/popup.service";
import {Trans, useTranslation} from "react-i18next";
export default function AirDropTrans() {
    const param = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [btnActive, setBtnActive] = useState(false);
    const linkCode = useRef(param.linkCode);

    const { address, isConnected } = useAccount();
    const provider = useWalletEthersPorvider();
    
    const chainId = useSelector((state: any) => state.walletReducer.chainId);
    const login: boolean = useSelector((state: any) => state.userReduce.login);
    const [itemDetail, setItemDetail] = useState<IAirdropList>({
        airdropMngId:"",
        airdropType:"",
        airdropName:"",
        walletAddr:"",
        receiveAmount:0,
        airdropCnt:0,
        amount:0,
        airdropUrl:"",
        displayYn:"",
        airdropOpenYn:"",
        airdropStartDay:"",
        airdropState:"",
        feeAverage:0,
        doname:"",
        profileImageFileId:"",
        ziglePoint:0,
        foundationName:'',
        homepageUrl:'',
        informationContents:'',
        mainnetName:"",
        mainnetChainId:"",
        mainnetExplorerUrl:"",
        mainnetRpcUrl:"",
        decimalVal:"",
        mainnetSymbolImgFileId:"",
        mainnetSymbolName:"",
        mainnetDisplayName:"",
        coinId:"",
        coinSymbolImgFileId:"",
        coinSymbolName:"",
        coinDisplayName:"",
        participateCount:0,
    });

    const [airdropMode, setAirdropMode ] = useState('');
    const [airdropCountDown, setAirdropCountDow ] = useState('');
    const [airdropCountDay, setAirdropCountDay] = useState('');
    const [airdropCnt, setAirdropCnt] = useState(0);

    const {
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit,
    } = useForm();
    const onSubmit = (data: any) => {
        if(!address){
            ToastError(t("지갑연결을 확인해 주세요."));
            return false;
        }
        if(chainId != itemDetail?.mainnetChainId){
            MetaMaskService.setSwitchNetwork(
                provider, parseInt(itemDetail?.mainnetChainId ? itemDetail?.mainnetChainId : "0"),
                itemDetail?.mainnetName,
                itemDetail?.mainnetSymbolName,
                itemDetail?.decimalVal,
                itemDetail?.mainnetRpcUrl,
                itemDetail?.mainnetExplorerUrl,
                '').then(
                (response) => {
                    if (!response.status) {
                    }
                }
            );
        }else{

            const params = new FormData();
            params.append("airdropMngId", itemDetail.airdropMngId);
            params.append("walletAddr", address);
            AirdropService.airdropConfirm(params).then((res)=>{
                console.log(res);
                if(res.status){
                    if(linkCode.current){
                        getViewContents(linkCode.current);
                    }

                    PopupService.newPopup(t("에어드랍 받기 성공!")
                        , <FlexColumnCenter>
                            <div>{itemDetail.amount+" "+itemDetail.coinSymbolName + t("를 받기 신청되었어요")}</div>
                            <div>{t('지글에서 내역을 확인해 볼까요?')}</div>
                        </FlexColumnCenter>
                        , ""
                        , {
                            btnTxt: t("지글에서 내역보기"),
                            onClick: () => {
                                localStorage.setItem("airdropView", "1");
                                navigate("/airdrop");
                            }
                        }
                        , {}
                    );
                }
            });
        }
    }
    const getViewContents = async (linkCode:string)=>{
        await AirdropService.airdropView(linkCode).then((item)=>{
            setItemDetail(item.data);
            if(item.data.airdropType === "airdropFi"){
                setAirdropCnt(item.data.airdropCnt - item.data.participateCount);

                if(item.data.airdropState === "W"){
                    setAirdropMode("TN");
                } else if((item.data.airdropState === "E") || (item.data.airdropCnt - item.data.participateCount === 0)){
                    setAirdropMode("E");
                } else if(item.data.airdropState === "P"){
                    setAirdropMode("P");
                } else {
                    if (item.data.airdropStartDay) {
                        var getCurrentTime = moment();
                        var targetTime = moment(item.data.airdropStartDay.toString());
                        var getCurrentTimeUnix = getCurrentTime.unix();
                        var targetTimeUnix = targetTime.unix();


                        var leftTime = targetTimeUnix - getCurrentTimeUnix;
                        var duration = moment.duration(leftTime, 'seconds');
                        var interval = 1000;
                        var intv = setInterval(function () {
                            if (duration.asSeconds() <= 1 || getCurrentTimeUnix >= targetTimeUnix || item.data.airdropOpenYn === "N") {

                                setAirdropMode("S")
                                clearInterval(intv);
                            } else {
                                duration = moment.duration(duration.asSeconds() - 1, 'seconds');
                                setAirdropMode("T");
                                setAirdropCountDow('');
                                var timer = {
                                    days: duration.days(),
                                    hours: (duration.hours() < 10) ? '0' + duration.hours() : duration.hours(),
                                    minutes: (duration.minutes() < 10) ? '0' + duration.minutes() : duration.minutes(),
                                    seconds: (duration.seconds() < 10) ? '0' + duration.seconds() : duration.seconds()
                                }
                                if(timer.days > 0)
                                {
                                    setAirdropCountDay("D-" + timer.days);
                                }
                                setAirdropCountDow(timer.hours + ' : ' + timer.minutes + ' : ' + timer.seconds)

                            }
                        }, interval);
                    }else{
                        setAirdropMode("TN");
                    }
                }
            }else{
                setAirdropMode("N");
            }
        });

    }

    useEffect(()=> {
        if (linkCode.current) {
            getViewContents(linkCode.current);
        }
    },[linkCode.current]);
    useEffect(()=>{

        if(!address){
            setBtnActive(false);
            dispatch({
                type: CONNECT,
            });
        }else{
            setBtnActive(true);
        }
    },[address])

    return (
        <PageRootContainer>
            <PageTitle
                onClickLeft={() => {
                    if(login){
                        navigate("/airdrop");
                    }else{
                        navigate("/");
                    }
                }}
                rightSrc={require('../assets/imges/profile/profile_link.png')}
                onClickRight={() => {
                    PopupService.snsLinkMobile(window.location.protocol + "//" + window.location.host + "/a/" + linkCode.current);
                }}
            />
            <PageContent>
                <FlexColumn gap={8}>
                    <FlexColumn gap={20}>
                        <FlexColumn gap={28}>
                            {
                                airdropMode === "E" ? (
                                    <FlexColumn gap={8}>
                                        <div className={styles.txt20_B01}>
                                            <Trans t={t} i18nKey="<span className={styles.txt20_500_B01}>이미 종료된 에어드랍</span>이에요!<br />{0}명이 받아 갔어요" values={{0:itemDetail?.participateCount}}/>
                                        </div>
                                    </FlexColumn>
                                ) : (
                                    airdropMode === "TN" ? (
                                        <FlexColumn gap={8}>
                                            <div className={styles.txt20_B01}>
                                                <Trans t={t} i18nKey="<span className={styles.txt20_500_B01}>아직 시작되지 않은</span><br /><span className={styles.txt20_500_B01}>에어드랍</span>이에요!"/>
                                            </div>
                                        </FlexColumn>
                                    ) : (
                                        airdropMode === "T" ? (
                                            <FlexColumn gap={8}>
                                                <div className={styles.txt20_B01}>
                                                    <Trans t={t} i18nKey="<span className={styles.txt20_500_B01}>에어드랍이 예정</span>되어 있어요!<br />놓치지 말아요"/>
                                                </div>
                                            </FlexColumn>
                                        ) : (
                                            airdropMode === "P" ? (
                                                <FlexColumn gap={8}>
                                                    <div className={styles.txt20_B01}>
                                                        <Trans t={t} i18nKey="<span className={styles.txt20_500_B01}>일시 중단된 에어드랍</span>이에요!<br />오픈되면 다시 이용해주세요"/>
                                                    </div>
                                                </FlexColumn>
                                            ) : (
                                                !address ? (
                                                    <FlexColumn gap={8}>
                                                        <Trans t={t} i18nKey="<div className={styles.txt20_B01}>에어드랍을 받으려면<br /><span className={styles.txt20_500_B01}>지갑 연결</span>이 필요해요</div>"/>
                                                        <Trans t={t} i18nKey="<div className={styles.txt14_B05}>{airdropCnt}자리 남앗어요!<br />지갑을 연결하고 에어드랍을 받아보세요.</div>"/>
                                                    </FlexColumn>
                                                ) : (
                                                    <FlexColumn gap={8}>
                                                        <div className={styles.txt20_B01}>
                                                            <Trans t={t} i18nKey="<span className={styles.txt20_500_B01}>{0}</span>로 연결됐어요<br />에어드랍을 받으세요" values={{0:ellipseAddress(address, 4)}}/>
                                                        </div>
                                                        <div className={styles.txt14_B05}>
                                                            <Trans t={t} i18nKey="{0}자리 남앗어요!<br />지금 에어드랍을 받아보세요." values={{0:airdropCnt}}/>
                                                        </div>
                                                    </FlexColumn>
                                                )
                                            )
                                        )
                                    )
                                )
                            }

                            {
                                airdropMode === "E" ? (
                                    <FlexColumn className={styles.airdrop_bg_out} >
                                        <FlexColumnCenter className={styles.airdropTransBlur_out} gap={8}>
                                            <div className={styles.txt20_600_W}>{t('종료되었습니다')}</div>
                                            <FlexColumnCenter className={styles.txt14_W03} style={{textAlign:'center'}}>
                                                <Trans t={t} i18nKey="새로운 에어드랍이 생길 때 <br/>알림을 받을 수 있어요"/>
                                            </FlexColumnCenter>
                                        </FlexColumnCenter>
                                    </FlexColumn>
                                ) : (
                                    airdropMode === "TN" ? (
                                        <FlexColumn className={styles.airdrop_bg_out} >
                                            <FlexColumnCenter className={styles.airdropTransBlur_out} gap={8}>
                                                <div className={styles.txt20_600_W}>{t('오픈시간 미지정')}</div>
                                                <FlexColumnCenter className={styles.txt14_W03} style={{textAlign:'center'}}>
                                                    <Trans t={t} i18nKey="에어드랍 메이커가<br/>오픈시간을 지정하지 않은<br/>에어드랍 링크에요"/>
                                                </FlexColumnCenter>
                                            </FlexColumnCenter>
                                        </FlexColumn>
                                    ) : (
                                        airdropMode === "T" ? (
                                            <FlexColumn className={styles.airdrop_bg_out} >
                                                <FlexColumnCenter className={styles.airdropTransBlur_out} gap={16}>
                                                    <FlexColumnCenter style={{padding:'40px'}} gap={16}>
                                                        <div className={styles.txt24_800_W}>{t("OPEN")}</div>
                                                        <FlexRowCenter gap={8}>
                                                            {
                                                                airdropCountDay && (
                                                                    <div style={{background:'#ffffff', padding:'10px 16px', fontSize:'24px', borderRadius:'100px', color:'#000000'}}>{airdropCountDay}</div>
                                                                )
                                                            }
                                                            <div className={styles.txt32_350_W}>{airdropCountDown}</div>
                                                        </FlexRowCenter>
                                                    </FlexColumnCenter>
                                                </FlexColumnCenter>
                                            </FlexColumn>
                                        ) : (
                                            airdropMode === "P" ? (
                                                <FlexColumn className={styles.airdrop_bg_out} >
                                                    <FlexColumnCenter className={styles.airdropTransBlur_out} gap={8}>
                                                        <img src={require('../assets/imges/airdrop/Robot.png')} width={30} />
                                                        <div className={styles.txt20_600_W}>{t("일시 중단.")}</div>
                                                        <FlexColumnCenter className={styles.txt14_W03}>
                                                            <div>{t("일시 중단된 에러드랍 링크에요")}</div>
                                                        </FlexColumnCenter>
                                                    </FlexColumnCenter>
                                                </FlexColumn>
                                            ) : (
                                                <FlexColumn className={styles.airdrop_bg}>
                                                    <FlexColumnCenter className={styles.airdropTransBlur} gap={42}>
                                                        <FlexRowSpace>
                                                            <FlexRowStart className={styles.txt18_500_W}>
                                                                {itemDetail?.coinSymbolName}&nbsp;<span className={styles.txt12_W03}>Airdrop</span>
                                                            </FlexRowStart>
                                                            <div className={styles.airdrop_bage_blur}>{ellipseAddress(address, 4)}</div>
                                                        </FlexRowSpace>
                                                        <FlexColumn>
                                                            <FlexRowEnd style={{color:'#d2d6d9'}}>Total</FlexRowEnd>
                                                            <FlexRowEnd className={styles.txt22_W}>{itemDetail?.receiveAmount}</FlexRowEnd>
                                                        </FlexColumn>
                                                    </FlexColumnCenter>
                                                </FlexColumn>
                                            )
                                        )
                                    )

                                )
                            }
                            <FlexColumn gap={8} className={styles.txt12_B05} style={{padding:'12px 14px', border:'1px solid #F2F4F8', borderRadius:'16px'}}>
                                <FlexRowSpace>
                                    <FlexRowStart gap={4}>
                                        <img src={itemDetail.profileImageFileId} width={40}  style={{borderRadius:'100px', aspectRatio:'1', objectFit:'cover'}}/>
                                        <FlexColumn>
                                            <div className={styles.txt14_B01}>{itemDetail.doname}</div>
                                            <div>{t("{0}명 프렌딩", {0:itemDetail.participateCount})}</div>
                                        </FlexColumn>
                                    </FlexRowStart>
                                    <FlexRowEnd>
                                       <div style={{padding:'12px 14px', border:'1px solid #F2F4F8', borderRadius:'16px'}}>{ itemDetail?.walletAddr ? ellipseAddress(itemDetail?.walletAddr, 6) : null}</div>
                                    </FlexRowEnd>
                                </FlexRowSpace>
                                <div className={styles.line}/>
                                <div>{itemDetail.informationContents}</div>
                                {
                                    itemDetail.homepageUrl && (
                                        <FlexColumnCenter style={{backgroundColor:'#EBF2FF', color:'#3A71FF', padding:'10px 14px', borderRadius:'8px'}} onClick={()=>{ window.open(itemDetail.homepageUrl, '_blank', 'noreferrer'); }} >{itemDetail.homepageUrl}</FlexColumnCenter>
                                    )
                                }
                            </FlexColumn>
                            <FlexColumn gap={10}>
                                <FlexColumn gap={20}  className={styles.txt14_B05} style={{padding:'16px', borderRadius:'12px', backgroundColor:'#F8F9FC'}}>
                                    <FlexRowSpace>
                                        <div>{t('메인넷')}</div>
                                        <div>{itemDetail?.mainnetDisplayName}</div>
                                    </FlexRowSpace>
                                    <FlexRowSpace>
                                        <div>{t('전송방식')}</div>
                                        <div>{itemDetail?.airdropType === "airdropFi" ? "선착순" : "지정인원"} {t('{0}명', {0:itemDetail?.airdropCnt})}</div>
                                    </FlexRowSpace>
                                    <FlexRowSpace>
                                        <div>{t('지갑당')}</div>
                                        <div className={styles.txt15_500_B01}>{itemDetail?.amount} {itemDetail?.coinSymbolName}</div>
                                    </FlexRowSpace>
                                </FlexColumn>
                                <FlexColumn className={styles.txt12_B05} style={{padding:'16px', border:'1px solid #F2F4F8', borderRadius:'16px'}}>
                                    {t("에어드랍은 블록체인 네트워크를 사용해 실제 지갑으로 전송되어 네트워크 수수료가 소모되오니 내 지갑에 충분한 수수료가 필요합니다.")}
                                </FlexColumn>
                            </FlexColumn>
                        </FlexColumn>
                    </FlexColumn>
                </FlexColumn>
                <FlexBottom>
                    {
                        airdropMode === "E" ? null : (
                            airdropMode === "TN" ? (
                                <BottomButton
                                    isActive={false}
                                    backgroundColor={"#F2F4F7"}
                                    textColor={"#D2D6D9"}
                                    text={t("오픈 시간이 지정되지 않았습니다.")}
                                    onClick={() => {
                                    }}
                                />
                            ) : (
                                airdropMode === "T" ? (
                                    <FlexColumnCenter style={{height: "56px", backgroundColor: "#3a71ff", color: "white"}}>
                                        {
                                            airdropCountDown && (
                                                <div>
                                                    {airdropCountDay && (
                                                        <><span style={{background:'#ffffff', padding:'3px 8px', fontSize:'11px', borderRadius:'100px', color:'#3a71ff'}}>{airdropCountDay}</span>&nbsp;&nbsp;</>
                                                    )}
                                                    {airdropCountDown}
                                                </div>
                                            )
                                        }
                                    </FlexColumnCenter>
                                ) : (
                                    airdropMode === "P" ? (
                                        <BottomButton
                                            isActive={false}
                                            backgroundColor={"#F2F4F7"}
                                            textColor={"#D2D6D9"}
                                            text={t("일시 중단되었습니다.")}
                                            onClick={() => {

                                            }}
                                        />
                                    ) : (
                                        !address ? (
                                            <BottomButton
                                                isActive={true}
                                                backgroundColor={"#F2F4F7"}
                                                textColor={"#3A71FF"}
                                                text={t("지갑 연결 하기")}
                                                onClick={() => {
                                                    dispatch({
                                                        type: CONNECT,
                                                    });
                                                }}
                                            />
                                        ) : (
                                            <BottomButton
                                                isActive={btnActive}
                                                backgroundColor={"#3a71ff"}
                                                textColor={"#ffffff"}
                                                text={t("에어드랍 받기")}
                                                onClick={handleSubmit(onSubmit)}
                                            />
                                        )
                                    )
                                )
                            )
                        )
                    }

                </FlexBottom>
            </PageContent>
        </PageRootContainer>
    )
}