import React from "react";
import styles from "../../../common/css/zigle.module.scss";
import CloseBtn from "../../../assets/icons/X.svg";
import { IPopAction } from "../../../services/popup.service";
import {FlexColumn, FlexRowCenter, FlexRowEnd} from "../container/PageContainer";
import {useTranslation} from "react-i18next";

interface IPopConfirm {
  title: string | null;
  message: string | JSX.Element;
  subMessage: string | JSX.Element;
  onClose: () => void;
  fnClose?: () => void | null;
  ok: IPopAction | null;
  cancel: IPopAction | null;
}

function NewPopConfirm(props: IPopConfirm) {
    const { t } = useTranslation();
  let isStr = false;
  let isSubStr = false;

  if (typeof props.message === "string") {
    isStr = true;
  }
  if (typeof props.subMessage === "string") {
      isSubStr = true;
  }

  return (
    <FlexColumn gap={20} style={{backgroundColor: 'white', width:'300px', borderRadius:'24px', padding:'20px 20px 32px 20px'}}>
        <FlexRowEnd>
            <img
            onClick={() => {
                if (props.fnClose) {
                    props.fnClose();
                }
                props.onClose();
            }}
            src={CloseBtn}
            alt="close"
        />
        </FlexRowEnd>
        <FlexRowCenter className={styles.txt20_700_B01}>{props.title ? props.title : "확인"}</FlexRowCenter>
        <FlexColumn gap={4}>
            {isStr ? (
                <FlexRowCenter style={{textAlign:'center'}} className={styles.txt16_B02} dangerouslySetInnerHTML={{ __html: props.message.toString() }} />
            )
                :
                (<FlexRowCenter style={{textAlign:'center'}} className={styles.txt16_B02}>{props.message}</FlexRowCenter>)
            }
            {isSubStr ? (
                    <FlexRowCenter style={{textAlign:'center'}} className={styles.txt14_B05} dangerouslySetInnerHTML={{ __html: props.subMessage.toString() }} />
                )
                :
                (<FlexRowCenter style={{textAlign:'center'}} className={styles.txt14_B05}>{props.subMessage}</FlexRowCenter>)
            }
        </FlexColumn>
        <FlexRowCenter>
            <div className={styles.popupBtnBlue}
                 onClick={() => {
                     props.onClose();
                     if (props.ok?.onClick) {

                         props.ok.onClick();
                     }else{

                     }
                 }}
            >{props.ok?.btnTxt ? props.ok.btnTxt : t("확인")}</div>
        </FlexRowCenter>
        {
            props.cancel?.onClick && props.cancel?.btnTxt && (
                <FlexRowCenter>
                    <div className={styles.txt14_B05}
                         onClick={() => {
                             props.onClose();
                             if (props.cancel?.onClick) {

                                 props.cancel.onClick();
                             }else{

                             }
                         }}
                    >
                        {props.cancel?.btnTxt ? props.cancel.btnTxt : t("취소")}
                    </div>
                </FlexRowCenter>
            )
        }


    </FlexColumn>
  );
}

export default NewPopConfirm;
