import React, {ReactHTMLElement, useEffect, useRef, useState} from "react";
import PageTitle, {
    FlexBottom, FlexBottom86,
    FlexColumn, FlexRowAround, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper, ZSelect
} from "../common/container/PageContainer";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import MainnetList from "../common/MainnetList";
import CoinList from "../common/CoinList";
import TransferFriend2 from "../transferCoin/TransferFriend2";
import TransferPhone2 from "../transferCoin/TransferPhone2";
import TransferEnd from "../transferCoin/TransferEnd";
import CommonService from "../../services/common.service";
import EmptyContent from "../common/EmptyContent";
import {useInView} from "react-intersection-observer";
import {useForm} from "react-hook-form";
import FavService from "../../services/fav.service";
import PopupService from "../../services/popup.service";
import {Toast} from "../common/popup/ToastAlert";
import {maskingEmail} from "../../common/js/utilities";
import {useTranslation} from "react-i18next";
import PhoneList from "./PhoneList";
import FriendList from "./FriendList";
import DonameList from "./DonameList";


export default function FriendingTab2() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [tabPage, setTabPage] = useState('new');

    const [reset, setReset] = useState(false);

    return (
        <>
            <FlexColumn>
                <FlexRowAround>
                    <div className={tabPage === 'new' ? styles.topTabActive : styles.topTabDefault} onClick={() => {setTabPage('new');}}>{t('새로 가입')}</div>
                    <div className={tabPage === 'activity' ? styles.topTabActive : styles.topTabDefault} onClick={() => {setTabPage('activity');}}>{t('최근 활동')}</div>
                    <div className={tabPage === 'popular' ? styles.topTabActive : styles.topTabDefault} onClick={() => {setTabPage('popular');}}>{t('인기 많은')}</div>
                </FlexRowAround>
                <div className={styles.line} style={{width: 'calc(100% + 40px)', marginLeft:'-20px'}}/>
            </FlexColumn>
            <DonameList tabPage={tabPage}/>
        </>
    )
}