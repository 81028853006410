import React, {CSSProperties, useEffect, useRef, useState} from "react";
import {useInView} from "react-intersection-observer";
import styles from "../common/css/zigle.module.scss";
import PageTitle, {
    FC,
    FlexBottom,
    FlexColumn,
    FlexColumSpace,
    FlexRowAround, FlexRowCenter, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FR, PageContent, PageRootContainer
} from "../components/common/container/PageContainer";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import styled from "styled-components";
import LinkPopup from "../components/myLink/LinkPopup";
import CoinList from "../components/common/CoinList";
import AssetService from "../services/asset.service";
import {Toast} from "../components/common/popup/ToastAlert";
import MetaMaskOnboarding from "@metamask/onboarding";
import {Bar} from "./Profile";
import Guide1 from "../components/metamask/Guide1";
import Guide4 from "../components/metamask/Guide4";
import Guide3 from "../components/metamask/Guide3";
import Guide2 from "../components/metamask/Guide2";
import Guide5 from "../components/metamask/Guide5";
import {Trans, useTranslation} from "react-i18next";

const bntStyle:CSSProperties = {
    width: "187px",
    height: "34px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "8px",
    border: "solid 1px #f2f4f8",
    backgroundColor: "#ffffff",
}
const forwarderOrigin = "https://zigle.io";
export default function Metamask() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [tab, setTab] = useState(0);
    const onboarding = new MetaMaskOnboarding({forwarderOrigin});
    const onClickInstall = () =>{
        onboarding.startOnboarding();
    }
    const openInNewTab = (url:any) => {
        window.open(url, '_blank', 'noreferrer');
    };

    return (
        <PageRootContainer>
            <PageTitle
                textTitle ={t('메타마스크 설명서')}
                onClickLeft={()=>{
                    navigate(-1);
                }}
            />
            <PageContent>
                <FlexColumn gap={32}>
                    <FlexColumn gap={8}>
                        <div className={styles.txt18} style={{color:'#9ea0a5'}}>Metamask</div>
                        <div className={styles.txt24}>
                            <Trans t={t} i18nKey={'메타마스크를 통해 암호화폐를<br/><b>안전하고 확장성 있게 관리</b>하세요'}/>
                        </div>
                    </FlexColumn>
                    <FlexColumn gap={12} style={{paddingTop:'8px'}}>
                        <div className={styles.txt18_500}>
                            <Trans t={t} i18nKey={'메타마스크는 이더리움들의<br/>암호화폐를 보관, 송금, 관리 할 수 있는<br/>개인지갑입니다.'}/>
                        </div>
                        <div className={styles.txt14_B05}>
                            <Trans t={t} i18nKey={'암호화폐를 거래소에 보관하고 송금하는 개념과는 다르게 <br/>암호화폐를 개인금고에 보관하고 관리하는 개념으로 디파<br/>이 및 NFT 민팅 등 탈중앙 서비스를 사용하기 위해서는 필<br/>수적으로 메타마스크 지갑을 가지고 있어야 합니다.'}/>
                        </div>
                        <div className={styles.txt13_500_B04} style={bntStyle} onClick={()=>openInNewTab('https://metamask.io/')}>{t('메타마스크 홈페이지 바로가기')}</div>
                    </FlexColumn>
                    <Bar></Bar>
                    <FlexColumn gap={12}>
                        <div className={styles.txt18_500}>{t('메타마스크 설치 가이드')}</div>
                        <div className={styles.txt14_B05}><Trans t={t} i18nKey={'메타마스크 설치는 PC/모바일 거의 동일하므로 PC설치를 <br/>기준으로 가이드를 설명 드립니다.'}/></div>
                        <div className={styles.txt18_500} style={{color:'red'}} onClick={()=>onClickInstall()}>{t('메타마스크 다운로드페이지 바로가기')}</div>
                        {/*<div className={styles.txt18_500} style={{color:'red'}} onClick={()=>onClickInstall()}>메타마스크 다운로드페이지 바로가기<img src={require('../assets/icons/arrow_up_right_blue.png')} width={18}/></div>*/}
                    </FlexColumn>
                    <FlexRowStart gap={10} style={{overflow:"scroll"}}>
                        <div className={tab === 0 ? styles.metamaskTabOn : styles.metamaskTabOff} onClick={()=>setTab(0)}>Phase1</div>
                        <div className={tab === 1 ? styles.metamaskTabOn : styles.metamaskTabOff} onClick={()=>setTab(1)}>Phase2</div>
                        <div className={tab === 2 ? styles.metamaskTabOn : styles.metamaskTabOff} onClick={()=>setTab(2)}>Phase3</div>
                        <div className={tab === 3 ? styles.metamaskTabOn : styles.metamaskTabOff} onClick={()=>setTab(3)}>Phase4</div>
                        <div className={tab === 4 ? styles.metamaskTabOn : styles.metamaskTabOff} onClick={()=>setTab(4)}>Phase5</div>
                    </FlexRowStart>
                    { tab === 0 && (<Guide1 />) }
                    { tab === 1 && (<Guide2 />) }
                    { tab === 2 && (<Guide3 />) }
                    { tab === 3 && (<Guide4 />) }
                    { tab === 4 && (<Guide5 />) }
                </FlexColumn>
            </PageContent>
        </PageRootContainer>
    )
}