import PageTitle, {
    FlexColumn,
    FlexRowAround,
    FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../common/container/PageContainer";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import styles from "../../common/css/zigle.module.scss";
import MyAirdropViewContent from "./MyAirdropViewContent";
import MyAirdropViewPartic from "./MyAirdropViewPartic";
import AirdropService from "../../services/airdrop.service";
import {Toast, ToastError} from "../common/popup/ToastAlert";
import {useTranslation} from "react-i18next";



interface IMyAirdropView {
    show: boolean;
    setShow: any;
    itemInfo:any;
    setReLoad:any;
    reLoad:any;
    onClickEndViewItem:()=> void;
    setAirdropFeeShow:any;
}
export default function MyAirdropView(props:IMyAirdropView) {
    const { t } = useTranslation();
    const [tabPage, setTabPage] = useState('1');
    const [viewItem, setViewItem] = useState<any>([]);
    const [home, setHome] = useState(true);
    const [displayYn, setDisplayYn]= useState('');
    const handleOnClick=(selectItem: any)=>{
        if(tabPage ==='1')
        {
            setViewItem(selectItem);
        }
    }
    useEffect(()=>{
        console.log(props.itemInfo);
        setDisplayYn(props.itemInfo.displayYn);
    }, [props.itemInfo])
    return (
        <>
            {
                props.show &&
                (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle
                                    home={home}
                                    textTitle ={props.itemInfo.airdropName}
                                    onClickLeft={()=>{
                                        props.setShow(false);
                                    }}
                                    rightSrc={
                                        props.itemInfo.airdropType === "airdropFi" ? (
                                            displayYn === "N" ? require('../../assets/imges/airdrop/airdrop_lock.png') : require('../../assets/imges/airdrop/airdrop_open.png')
                                        ) : null

                                    }
                                    onClickRight={() => {
                                        if(props.itemInfo.airdropType === "airdropFi")
                                        {
                                            const params = new FormData();
                                            params.append("airdropMngId", props.itemInfo.airdropMngId);
                                            params.append("displayYn", displayYn === "Y" ? "N" : "Y");
                                            AirdropService.displayOpen(params).then(
                                                (res)=> {
                                                    if (res) {
                                                        if(displayYn === "N")
                                                        {
                                                            Toast(t("에어드랍 전체 목록에 공개 처리 되었습니다."));
                                                        }else{
                                                            ToastError(t("에어드랍 링크로만 입장 가능합니다."))
                                                        }
                                                        props.setReLoad(!props.reLoad);
                                                        setDisplayYn(displayYn === "Y" ? "N" : "Y");
                                                    }
                                                }
                                            );
                                        }
                                    }}
                                />
                                <PageContent style={{marginBottom:'100px'}}>
                                    <FlexColumn gap={32}>
                                        <FlexColumn>
                                            <FlexRowAround>
                                                <div
                                                    className={
                                                        tabPage === '1' ? styles.topTabActive : styles.topTabDefault
                                                    }
                                                    onClick={() => {
                                                        setTabPage('1');
                                                    }}
                                                >
                                                    {t("에어드랍 정보")}
                                                </div>
                                                <div
                                                    className={
                                                        tabPage === '2' ? styles.topTabActive : styles.topTabDefault
                                                    }
                                                    onClick={() => {
                                                        setTabPage('2');
                                                    }}
                                                >
                                                    {t("참여인원")}
                                                </div>
                                            </FlexRowAround>
                                            <div className={styles.line} style={{width: 'calc(100% + 40px)', marginLeft:'-20px'}}/>
                                        </FlexColumn>
                                        {tabPage === '1' && <MyAirdropViewContent viewItem={props.itemInfo}  onClickEndViewItem={props.onClickEndViewItem} reLoad={props.reLoad} setReLoad={props.setReLoad} setAirdropFeeShow={props.setAirdropFeeShow}/>}
                                        {tabPage === '2' && <MyAirdropViewPartic viewItem={props.itemInfo}/>}
                                    </FlexColumn>
                                </PageContent>
                            </PageRootContainer>

                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
            </>
    )
}