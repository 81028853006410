import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useInView} from "react-intersection-observer";
import {useForm} from "react-hook-form";
import PopupService from "../../services/popup.service";
import FavService from "../../services/fav.service";
import {Toast} from "../common/popup/ToastAlert";
import CommonService from "../../services/common.service";
import {FlexColumn, FlexColumnCenter, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import EmptyContent from "../common/EmptyContent";


export default function DonameList(props:{tabPage:string;}){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [tmpOrder , setTmpOrder] = useState('')
    const [searchTxt, setSearchTxt] = useState('');
    const [friendList, setFriendList] = useState<any[]>([]);
    const [currPage, setCurrPage] = useState(1);
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [blockSize] = useState(10);
    const [reSet, setReSet] = useState(false);
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const fetchData = async() =>{
        const response = await CommonService.donamelist('', currPage, blockSize, props.tabPage);
        if (!response.data.length) {
            setLastList(true);
            return;
        }
        setFriendList([...friendList, ...response.data]);
    }
    useEffect(() => {
        if (!lastList) {
            fetchData();
        }
    },[currPage,  reSet]);
    useEffect(()=>{
        if(tmpOrder != props.tabPage){
            setFriendList([]);
            setSearchTxt('');
            setLastList(false);
            setCurrPage(1);
            setReSet(!reSet);
            setTmpOrder(props.tabPage);
        }
    },[props.tabPage])
    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);
    return (
        <>
            <FlexColumn style={{marginTop:'16px'}}>
                {
                    friendList.length > 0 ? (
                            friendList.map((item, index) => {
                                return (
                                    <FlexColumn key={item.userId} style={{height:'78px'}}>
                                        <FlexRowSpace>
                                            <FlexRowStart  onClick={()=>{navigate("/u/"+item.doname, {
                                                state: {
                                                    back:true
                                                }})}}>
                                                <div style={{paddingRight:'12px'}}>
                                                    <img src={item.profileImageFileId ? item.profileImageFileId : 'https://s3.ap-northeast-2.amazonaws.com/bucket.zigle.io/profiles/profile'+(item.userId % 9)+'.png' } alt="bgIcon" style={{width:'38px', height:'38px', borderRadius:'15px', aspectRatio:'1', objectFit:'cover'}} />
                                                </div>
                                                <FlexColumn>
                                                    <div className={ styles.txt16_B02}>{item.doname}</div>
                                                    <div className={styles.txt14_B05}>{t("{0}명 프렌딩", {0:item.favTrandingTotalCnt})}</div>
                                                </FlexColumn>
                                            </FlexRowStart>
                                            <FlexRowEnd gap={4}>
                                                <img src={require('../../assets/imges/chat/btn_chat.png')} width={34} onClick={()=>{navigate("/chat/"+item.doname)}}/>
                                                {
                                                    item.isMyFriend === "N" ? (
                                                        <FlexColumnCenter className={styles.txt14_500_PointB} style={{ width:'75px', height:'34px', background:'#3A71FF', color:'#FFFFFF', borderRadius:'8px' }} onClick={()=> {
                                                            PopupService.newPopup(t("프렌드하기")
                                                                ,t("'{0}' 님을 프렌드 할까요?", {0:item.doname})
                                                                , ""
                                                                ,{btnTxt:"확인",
                                                                    onClick: () => {
                                                                        FavService.friendInsert(item.userId).then((response) => {
                                                                            Toast(t('친구등록이 완료 되었습니다.'));
                                                                            setFriendList(friendList.map(ztem=>ztem.userId === item.userId ? { ...ztem, isMyFriend:'Y' } : ztem))
                                                                        });
                                                                    }}
                                                                , {
                                                                    btnTxt:"",
                                                                    onClick: () => {}
                                                                }
                                                            );
                                                        }}>{t("프렌드")}</FlexColumnCenter>
                                                    ) : (
                                                        <FlexColumnCenter className={styles.txt14_500_PointB} style={{ width:'75px', height:'34px',  background:'#EBF2FF',  borderRadius:'8px' }} onClick={()=>{

                                                            PopupService.newPopup(t("언프렌드")
                                                                ,t("'{0}' 님을 언프렌드 할까요?", {0:item.doname})
                                                                , ""
                                                                ,{btnTxt:t("확인"),
                                                                    onClick: () => {
                                                                        FavService.friendDelete(item.userId).then((response) => {
                                                                            setFriendList(friendList.map(ztem=>ztem.userId === item.userId ? { ...ztem, isMyFriend:'N' } : ztem))
                                                                            Toast(t('친구취소가 완료 되었습니다.'));
                                                                        });
                                                                    }}
                                                                , {
                                                                    btnTxt:"",
                                                                    onClick: () => {}
                                                                }
                                                            );
                                                        }} >{t('언프렌드')}</FlexColumnCenter>
                                                    )
                                                }
                                            </FlexRowEnd>
                                        </FlexRowSpace>
                                    </FlexColumn>
                                )
                            })
                        )
                        :
                        (
                                <EmptyContent titleText={t('친구를 찾을 수 없어요')} titleImage={require('../../assets/imges/list_x.png')}/>
                        )
                }
            </FlexColumn>
            {
                Object.values(friendList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
            }
        </>
    )
}