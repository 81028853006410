import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import styled from "styled-components";
import PageTitle, {
    FlexBottom, FlexBottomStart, FlexColumEnd,
    FlexColumn, FlexColumnCenter, FlexRowCenter, FlexRowEnd, FlexRowSpace, FlexRowStart,
    PageContent,
    PageRootContainer
} from "../common/container/PageContainer";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import chatJson from "../../common/json/chat.json";
import EmptyContent from "../common/EmptyContent";
import moment from "moment";
import {useInView} from "react-intersection-observer";
import ChatService from "../../services/chat.service";
import ChatListItem from "./ChatListItem";
export interface IChatItem {
    profileImage: string;
    doname: string;
    key: string;
    time: string;
    activity : string;
    contents: string;
    master:string;
    ethValue:string;
}
export default function ChatList(props:{pageType:string, talkPlus:any}){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchTxt, setSearchTxt] = useState('');

    const [lastIndex, setLastIndex] = useState(0);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [blockSize] = useState(10);
    const [lastList, setLastList] = useState(false);

    const userId: string = useSelector((state: any) => state.userReduce.userId);
    const profileImageFileId: string = useSelector((state: any) => state.userReduce.profileImageFileId);
    const doname: string = useSelector((state: any) => state.userReduce.doname);
    // const [chatList, setChatList] = useState<Array<IChatItem>>(chatJson);
    const [chatList, setChatList] = useState<Array<any>>([]);
    const [currPage, setCurrPage] = useState(1);
    const [myChannel, setMyChannel] = useState<any>({
        channel:{

        }
    });
    const getMyChannel = async()=>{
        await props.talkPlus.getChannel({channelId: userId+"_chat"})
        .then((resMyChannel:any)=>{
            var getCurrentTime = moment();
            var targetTime = moment(resMyChannel.channel.lastSentAt);
            var leftTime = getCurrentTime.diff(targetTime, 'minutes');
            if(Number(leftTime) > 1440){
                resMyChannel.channel.leftTime = parseInt(String(Number(leftTime) /1440))+ t("일");
            }else if(Number(leftTime) > 59){
                resMyChannel.channel.leftTime = parseInt(String(Number(leftTime) /60))+ t("시간");
            }else{
                resMyChannel.channel.leftTime = parseInt(String(leftTime))+t("분");
            }
            setMyChannel(resMyChannel);
        }).catch((error:any)=>{
            console.log("error",error)
        });

    }

    const getAllChannels = async()=>{
        // let params;
        // if(lastList){
        //     params = {
        //         lastChannelId:lastIndex,
        //         limit:blockSize
        //     }
        // }else{
        //     params = {
        //         limit:blockSize
        //     }
        // }
        // const resp = await props.talkPlus.getPublicChannels(params);
        // if(resp.channels.length > 0){
        //     setChatList([...chatList, ...resp.channels]);
        //     setLastIndex(resp.channels[resp.channels.length - 1].id);
        // }
        //
        // setLastList(resp.hasNext);
        ChatService.listChatRoom('', props.pageType, currPage, 'modDate', 'DESC', '').then((data) =>{
            if (!data.data.length) {
                setLastList(true);
                return;
            }
            setChatList([...chatList, ...data.data]);

        });

    }

    useEffect(()=>{
        if(props.talkPlus){
            if(props.pageType === "my")
            {
                getMyChannel();
            }
        }
    },[props.talkPlus]);

    useEffect(()=>{
        if(props.talkPlus) {
            getAllChannels();
        }
    }, [currPage, props.talkPlus]);

    useEffect(() => {
        if (inView && hasNextPage && !lastList) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);

    return (
        <>
            <FlexColumn style={{marginTop:'5px'}}>
                {
                    ((props.pageType === "my") && (doname)) && (
                        <FlexColumn key={"my"} style={{padding:'12px 16px', marginBottom:'12px', background:'#F2F4F6', borderRadius:'12px'}} onClick={()=>{navigate("/chat/"+doname)}}>
                            <FlexRowSpace>
                                <FlexRowStart gap={14} style={{width:'70%'}}>
                                    <img
                                        src={profileImageFileId}
                                        width={48}
                                        alt={"profile image"}
                                        style={{borderRadius:'48px', aspectRatio:'1', objectFit:'cover'}}
                                    />
                                    <FlexColumn>
                                        <div className={styles.txt16_B02}>{doname}</div>
                                        <div className={styles.txt13_B05}>
                                            {t("{0} 전 활동 · {1}명", {0:myChannel.channel?.leftTime, 1:myChannel.channel?.memberCount})}
                                        </div>
                                    </FlexColumn>
                                </FlexRowStart>
                                <FlexRowEnd className={styles.txt13_B06} style={{width:'30%'}}>{t("내 채팅방")}</FlexRowEnd>
                            </FlexRowSpace>
                        </FlexColumn>
                    )
                }
                {
                    chatList.length > 0 ?
                        (
                            chatList.map((item,index)=>{
                                if(item.userId != userId){
                                    return (
                                        <ChatListItem key={index} pageType={props.pageType} talkPlus={props.talkPlus} item={item} searchTxt={searchTxt}/>
                                    )
                                }

                            })
                        ) : (
                            <EmptyContent titleText={t('등록된 채팅방이 없습니다.')}/>
                        )
                }
            </FlexColumn>
            {
                Object.values(chatList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
            }
        </>
    )
}