import React, {useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import {TextField} from "@material-ui/core";
import {FlexBottom, FlexColumn, FlexRowAround, FlexRowEnd} from "../common/container/PageContainer";
import BottomButton from "../common/button/BottomButton";
import {useForm} from "react-hook-form";
import {IAirdropSet} from "../../pages/AirdropFi";
import DefaultInput from "../common/input/DefaultInput";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

interface AirdropFiStep {
    airdropInfo: IAirdropSet;
    setAirdropInfo: any;
    setDepth: any;
}
const HidddenInputBox = styled.input`
  display: none;
`;
export default function AirdropFiStep1({airdropInfo, setAirdropInfo, setDepth}: AirdropFiStep) {
    const [btnActive, setBtnActive] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [informationContentsLength , setInformationContentsLength] = useState(0);

    const inputRef = useRef<HTMLFormElement>(null);
    const handleSymbolImageUploader = () => {
        inputRef.current?.informationContents.click();
    };
    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
    } = useForm();
    const onSubmit = (data: any) => {
        setAirdropInfo({...airdropInfo, homepageUrl:data.homepageUrl, informationContents :data.informationContents});
        setDepth(2);
    }
    return (
        <FlexColumn>
            <FlexColumn>
                <form
                    ref={inputRef}
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ width: "100%" }}
                >
                    <FlexColumn gap={20}>
                        <FlexColumn gap={5}>
                            <div className={styles.txt20_500_B01}>{t("에어드랍 상세 설명")}</div>
                            <FlexColumn style={{
                                backgroundColor: '#F8F9FC',
                                borderRadius: '16px',
                                padding: '16px 12px'
                            }}>
                                    <textarea
                                        style={{border: 'none'}}
                                        className={styles.textAreaGrayBox}
                                        placeholder={t("상세 설명을 입력해주세요.")}
                                        rows={4}
                                        maxLength={30}
                                        defaultValue={airdropInfo.informationContents}
                                        {...register("informationContents", {
                                            required: t("상세 설명을 입력해주세요."),
                                            maxLength: 200,
                                            onChange: (el) => {
                                                setInformationContentsLength(el.target.value.length);
                                            }
                                        })}
                                    />
                                <FlexRowEnd className={styles.txt15_W03}>({informationContentsLength}/200)</FlexRowEnd>
                            </FlexColumn>
                        </FlexColumn>
                        <FlexColumn>
                            <DefaultInput
                                lable={t("첨부 링크(선택)")}
                                error={errors.homepageUrl}
                                inputElement={
                                    <input
                                        type={"text"}
                                        className={styles.inputText_44}
                                        placeholder={t("링크 첨부")}
                                        {...register("homepageUrl", {
                                        })}
                                    />
                                }
                            />
                            <div className={styles.txt13_B06}>{t("텔레그램, SNS, 웹사이트 등")}</div>
                        </FlexColumn>
                    </FlexColumn>

                </form>
            </FlexColumn>
            <FlexBottom>
                <BottomButton
                    isActive={btnActive}
                    backgroundColor={"#3a71ff"}
                    textColor={"white"}
                    // border={"solid 1px #dbdee6"}
                    onClick={handleSubmit(onSubmit)}
                    text={t("다음")}
                />
            </FlexBottom>
        </FlexColumn>
    )
}