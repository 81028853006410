import styles from "../../common/css/zigle.module.scss";
import {Trans, useTranslation} from "react-i18next";
import {
    FlexBottom,
    FlexBottomStart,
    FlexColumn,
    FlexColumnCenter,
    FlexRowCenter
} from "../common/container/PageContainer";
import PopupService from "../../services/popup.service";
import FavService from "../../services/fav.service";
import {Toast} from "../common/popup/ToastAlert";
import ChatService from "../../services/chat.service";
import React from "react";
import {useNavigate} from "react-router-dom";
import { IChatNoFriend } from "./ChatNoFriend";
import {ellipseAddress} from "../../common/js/utilities";
import {CopyToClipboard} from "react-copy-to-clipboard";
import BottomButton from "../common/button/BottomButton";


export default function ChatTokenCheck(props:IChatNoFriend){
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <>
            <FlexColumnCenter style={{paddingTop:'300px'}} gap={16}>
                <img
                    src={props.userInfo.coinSymbolImgFileId}
                    width={72}
                    alt={"profile image"}
                    style={{borderRadius:'48px', aspectRatio:'1', objectFit:'cover'}}
                />
                <FlexColumnCenter gap={2}>
                    <div className={styles.txt18_700_B01}>{props.userInfo.doname}</div>
                    <FlexRowCenter gap={2}>
                        <div className={styles.txt12_W01} style={{background:'#111111',lineHeight:'130%',  borderRadius:'100px', padding:'3px 8px'}}>{props.userInfo.mainnetDisplayName}</div>
                        <div  className={styles.txt12_B04} style={{background:'#F2F4F6', lineHeight:'130%', borderRadius:'100px', padding:'3px 4px 3px 6px'}}>
                            {ellipseAddress(props.userInfo.contAddr, 5)}
                            <CopyToClipboard
                                text={props.userInfo.contAddr}
                                onCopy={() => Toast(t("주소를 복사 했습니다."))}
                            >
                                <img src={require("../../assets/icons/copy.png")} width={16} alt={"copy"} />
                            </CopyToClipboard>
                        </div>
                    </FlexRowCenter>
                </FlexColumnCenter>
                <FlexRowCenter gap={4}>
                    <div className={styles.txt12_B05}>
                        {t("최소 참여 수량")}
                    </div>
                    <div className={styles.txt12_B02} style={{fontWeight:'600'}}>{props.userInfo.checkCnt} {props.userInfo.coinSymbolName}</div>
                </FlexRowCenter>
            </FlexColumnCenter>
            <FlexBottomStart gap={12}>
                <FlexRowCenter className={styles.txt13_B05} style={{textAlign:'center'}}>
                    <Trans t={t} i18nKey={"내 지갑에 해당 코인을<br/>보유 중인지 확인 후 입장 가능해요"}/>
                </FlexRowCenter>
                <FlexColumn>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#3a71ff"}
                        textColor={"white"}
                        // border={"solid 1px #dbdee6"}
                        onClick={()=>{
                            if(props.handleOnClick){
                                props.handleOnClick(props.userInfo);
                            }
                        }}
                        text={t("입장하기")}
                    />
                </FlexColumn>
            </FlexBottomStart>
        </>
    )
}