import {
    FlexColumEnd,
    FlexColumn,
    FlexColumnCenter,
    FlexRowSpace,
    FlexRowStart
} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import ProgressBar from "./ProgressBar";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import moment from "moment";
import CommonService from "../../services/common.service";
import {decimalFormat} from "../../common/js/utilities";
import {useTranslation} from "react-i18next";

interface IMyAirdropItem {
    onClickViewItem?: (item: any)=> void;
    itemDetail : any;
}
export default function MyAirdropItem({itemDetail, onClickViewItem}:IMyAirdropItem) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [airdropBlur, setAirdropBlur ] = useState(true);
    const [airdropCountDown, setAirdropCountDow ] = useState('');
    const [airdropCountDay, setAirdropCountDay] = useState('');
    const {state} = useLocation();

    useEffect(()=>{
        if(localStorage.getItem("airdropUrl") === itemDetail.airdropUrl && onClickViewItem){
            onClickViewItem(itemDetail);
            localStorage.removeItem("airdropUrl");
        }
    },[])
    useEffect(()=>{
        if(itemDetail.airdropState === "W"){
            setAirdropBlur(false);
        }else {
            if(itemDetail.airdropType==="airdropDe")
            {
                setAirdropBlur(false);
            }else{
                if (itemDetail.airdropStartDay) {
                    var getCurrentTime = moment();
                    var targetTime = moment(itemDetail.airdropStartDay);
                    var getCurrentTimeUnix = getCurrentTime.unix();
                    var targetTimeUnix = targetTime.unix();


                    var leftTime = targetTimeUnix - getCurrentTimeUnix;
                    var duration = moment.duration(leftTime, 'seconds');
                    var interval = 1000;
                    var intv = setInterval(function () {
                        if (duration.asSeconds() <= 1 || getCurrentTimeUnix >= targetTimeUnix || itemDetail.airdropOpenYn === "N") {
                            setAirdropBlur(false)
                            clearInterval(intv);
                        } else {
                            duration = moment.duration(duration.asSeconds() - 1, 'seconds');
                            setAirdropBlur(true);
                            setAirdropCountDow('');
                            var timer = {
                                days: duration.days(),
                                hours: (duration.hours() < 10) ? '0' + duration.hours() : duration.hours(),
                                minutes: (duration.minutes() < 10) ? '0' + duration.minutes() : duration.minutes(),
                                seconds: (duration.seconds() < 10) ? '0' + duration.seconds() : duration.seconds()
                            }
                            if(timer.days > 0)
                            {
                                setAirdropCountDay("D-" + timer.days);
                            }
                            setAirdropCountDow(timer.hours + ' : ' + timer.minutes + ' : ' + timer.seconds)

                        }
                    }, interval);
                } else {
                    setAirdropBlur(true);
                }
            }
        }

    },[itemDetail])
    return (
        <>
            <FlexColumn className={styles.airdropMyItem} gap={12} onClick={()=>{
                if(onClickViewItem) {
                    onClickViewItem(itemDetail);
                }
            }}>
                <FlexRowSpace>
                    <FlexRowStart gap={4}>
                        {
                            ((itemDetail.displayYn === "N" ) && (itemDetail.airdropType==="airdropFi")) && (
                                <img src={require('../../assets/imges/airdrop/my_airdrop_lock.png')} width={24} />
                            )
                        }
                        <div>{itemDetail.airdropName}</div>
                    </FlexRowStart>
                    {
                        airdropBlur ? (
                                airdropCountDay ? (
                                    <div className={styles.airdrop_bage_time} style={{width:'60px'}}>
                                        {airdropCountDay}
                                    </div>
                                ) :(
                                    airdropCountDown ? (
                                        <div className={styles.airdrop_bage_time} style={{width:'110px'}}>
                                            {airdropCountDown}
                                        </div>
                                    ) : (
                                        <div style={{width:'33px'}}>
                                            <img src={require('../../assets/imges/airdrop/my_airdrop_re.png')} width={32}/>
                                        </div>
                                    )
                                )
                        ) : (
                            itemDetail.airdropState === "S" || itemDetail.airdropState === "P" || itemDetail.airdropState === "E" ?
                                (
                                    ((itemDetail.airdropState === "E") || (itemDetail.airdropCnt - itemDetail.participateCount === 0)) ?
                                    (
                                        itemDetail.airdropState === "E" ? (
                                            <div className={styles.airdrop_bage_card_end} style={{width:'48px', borderRadius:'8px'}}>
                                                {t("종료")}
                                            </div>
                                        ) : (
                                            <div className={styles.airdrop_bage_card_end} style={{width:'48px', borderRadius:'8px'}}>
                                                {t("출금 전")}
                                            </div>
                                        )
                                    )
                                     : (
                                        itemDetail.airdropState === "S" ? (
                                            <div style={{width:'33px'}}>
                                                <img src={require('../../assets/imges/airdrop/my_airdrop_play.png')} width={32}/>
                                            </div>
                                        ) : (
                                            <div style={{width:'33px'}}>
                                                <img src={require('../../assets/imges/airdrop/my_airdrop_pause.png')} width={32}/>
                                            </div>
                                        )
                                    )
                                ) : (
                                    <div className={styles.airdrop_bage_time} style={{width:'100px',color:'red'}}>
                                        {t("입금 진행중")}
                                    </div>
                                )
                        )
                    }
                </FlexRowSpace>
                <FlexRowStart gap={4} className={styles.txt12_B05}>
                    {t("지갑당")}<span className={styles.txt16_B01}>{itemDetail.amount}</span>{itemDetail.coinSymbolName}
                </FlexRowStart>
                <div className={styles.line}></div>
                <ProgressBar fillerBgcolor={"#000000"} completed={(itemDetail.participateCount / itemDetail.airdropCnt) * 100} containerBgcolor={"#f2f4f6"} fillerHeight={8}/>
                <FlexRowStart gap={4} className={styles.txt12_B05}>
                    {t("참여율")}<span className={styles.txt14_B03}>{decimalFormat(((itemDetail.participateCount / itemDetail.airdropCnt) * 100), 0)}%</span>
                </FlexRowStart>
            </FlexColumn>
        </>
    )
}