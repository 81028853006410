import React, { useEffect, useState } from "react";
import styles from "../common/css/zigle.module.scss";
import PageTitle, {
  FlexBottom,
  FlexColumn,
  FlexRowCenter,
  GrayBox,
  PageContent,
  PageRootContainer,
} from "../components/common/container/PageContainer";
import {useNavigate, useParams} from "react-router-dom";
import BottomButton from "../components/common/button/BottomButton";
import { useDispatch, useSelector } from "react-redux";
import { CONNECT } from "../components/reducer/walletReducer";
import AuthService from "../services/auth.service";
import {useAccount} from "wagmi";
import {useTranslation} from "react-i18next";

export default function WalletLogin() {
  const [btnActive, setBtnActive] = useState(true);
  const params = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // 


  const { address, isConnected } = useAccount();


  useEffect(()=>{
    if(params.refCode){
      localStorage.setItem("refCode", params.refCode);
    }
  },[params.refCode])
  useEffect(() => {
    //지갑여부 확인
    if (address) {
      setBtnActive(false);
      //지갑로그인 여부 확인
      AuthService.checkJoinByAddress(address).then((walletLogin) => {
        if (walletLogin === null || walletLogin === undefined) {
          //회원계정과 연결 안되었을 경우.
          navigate("/noJoinInfo");
          return;
        }
        let socialCode = walletLogin.socialCode;
        //카카오 계정과 연결 되었을 경우
        if (socialCode === "kakao") {
          navigate("/loginKakao");
        } else if (socialCode === "google") {
          //구글 계정과 연결 되었을 경우
          navigate("/loginGoogle");
        } else {
          //이메일 계정과 연결 되었을 경우.
          navigate("/loginEmail");
        }
      });
    }
  }, [address]);

  return (
    <PageRootContainer>
      <PageTitle
        onClickLeft={() => {
          navigate(-1);
        }}
        textTitle={""}
      />

      <PageContent>
        <FlexColumn>
          <FlexColumn>
            <div className={styles.txt20_B01}>{t('지갑을 연결해 주세요.')}</div>
            <FlexRowCenter>
              <img
                src={require("../assets/imges/login/img_01.png")}
                width={226}
                alt={"imgwallet"}
              />
            </FlexRowCenter>
            <GrayBox>
              <div className={styles.txt14_BOLD_B04}>
                {t('지갑 연결 + 로그인을 통해 바로 이용해보세요')}
              </div>
              <div className={styles.txt13_B05}>
                {t('지글은 메타마스크를 간편하고 안전하게 이용 할 수 있는 지갑 관리 서비스입니다.')}
              </div>
            </GrayBox>
          </FlexColumn>

          <FlexBottom>
            <BottomButton
              isActive={btnActive}
              backgroundColor={"#3a71ff"}
              textColor={"white"}
              text={t("지갑 연결하기")}
              onClick={() => {
                dispatch({
                  type: CONNECT,
                });
              }}
            />
          </FlexBottom>
        </FlexColumn>
      </PageContent>
    </PageRootContainer>
  );
}
