import styled from "styled-components";
import CommonService from "../../services/common.service";
import {decimalFormat} from "../../common/js/utilities";
const ContainerStyles  = styled.div<IProgressBar>`
       
        align-self: stretch;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 100px;
        ${(props) => (props.border ? "border:" + props.border : "border: solid 1px #f2f4f8")};
        
        ${(props) => (props.containerPadding ? "padding:" + props.containerPadding + "px" : "")};
        ${(props) => (props.containerBgcolor ? "background-color:" + props.containerBgcolor : "")};
    `;

const FillerStyles  = styled.div<IProgressBar>`
        flex-grow: 0;
        border-radius: 100px;
        text-align: left;
        ${(props) => (props.fillerHeight ? "height:" + props.fillerHeight + "px" : "height:20px")};
        ${(props) => (props.completed ? "width:" + props.completed + "%" : "")};
        ${(props) => (props.fillerBgcolor ? "background-color:" + props.fillerBgcolor : "")};
    `;

const LabelStyles  = styled.div`
        padding: 8px;
        color: white;
        font-weight: bold;
    `;
const LabelStyles2  = styled.div`
        padding: 8px;
        color: #5a6168;
        font-weight: bold;
    `;
interface IProgressBar {
    containerBgcolor? :string;
    completed? : number;
    containerPadding? : number;
    fillerBgcolor? :string;
    fillerHeight? : number;
    leftLabelValue? : number;
    rightLabelValue? : string;
    border?:string;

}
const ProgressBar = (props:IProgressBar) => {
    const { completed, containerBgcolor, containerPadding, fillerBgcolor, fillerHeight, leftLabelValue, rightLabelValue, border } = props;

    return (
        <ContainerStyles containerBgcolor={containerBgcolor} containerPadding={containerPadding} border={border}>
            <FillerStyles fillerBgcolor={fillerBgcolor} completed={completed} fillerHeight={fillerHeight}>
                { leftLabelValue ?
                    (<LabelStyles>{decimalFormat(leftLabelValue, 0)}%</LabelStyles>)
                    :
                    null
                }
            </FillerStyles>
            { rightLabelValue && (<LabelStyles2>{rightLabelValue}</LabelStyles2>) }
        </ContainerStyles>
    );
};

export default ProgressBar;