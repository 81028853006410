import React, {useEffect, useRef, useState} from "react";
import styles from "../../common/css/zigle.module.scss";
import {
    FlexBottom, FlexBottom86, FlexBottomStart,
    FlexColumn, FlexColumnCenter,
    FlexRowAround, FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, ToggleBtn, ToggleCircle
} from "../common/container/PageContainer";
import BottomButton from "../common/button/BottomButton";
import {useForm} from "react-hook-form";
import DefaultInput from "../common/input/DefaultInput";
import {IAirdropSet} from "../../pages/AirdropFi";
import bigDecimal from "js-big-decimal";
import MetaMaskService from "../../services/metamask.service";
import {useSelector} from "react-redux";
import CommonService from "../../services/common.service";
import "react-datepicker/dist/react-datepicker.css";
import {decimalFormat} from "../../common/js/utilities";
import {useAccount, useBalance} from "wagmi";
import {fetchBalance} from "@wagmi/core";
import {Trans, useTranslation} from "react-i18next";

interface IAirdropStep4 {
    airdropInfo: IAirdropSet;
    setAirdropInfo:any;
    setDepth: any;
}

export default function AirdropDeStep5({airdropInfo, setAirdropInfo, setDepth}:IAirdropStep4){
    const { t } = useTranslation();
    const [balance, setBalance] = useState(0.0);
    const [price, setPrice] = useState(0);
    const [btnActive, setBtnActive] = useState(false);
    const inputRef = useRef<HTMLFormElement>(null);
    const { address, isConnected } = useAccount();

    const hookCoinBalance = useBalance({
        address: address,
    });
    
    const [amount, setAmount] = useState(0);
    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
    } = useForm();

    const onSubmit = (data: any) => {
        setAirdropInfo({
            ...airdropInfo,
            amount:amount,
            receiveAmount:new bigDecimal(airdropInfo.airdropCnt).multiply(new bigDecimal(amount)).getValue()
        })
        setDepth(5);
    }


    useEffect(() => {
        if(airdropInfo.coinInfo.typeCode ==='TOKEN') {
            try {
                // @ts-ignore
                MetaMaskService.getBalance(address, airdropInfo.coinInfo.contAddr)
                    .then((res) => {
                        setBalance(Number(res));
                        //가격정보 호출
                        // MetaMaskService.getBalance(coinInfo.address, contract, coinInfo.symbolName,coinInfo.contAddr, 0).then((rst)=>{
                        setPrice(Number((new bigDecimal(airdropInfo.coinInfo.exchangeRate).multiply(new bigDecimal(res))).getValue()));

                        // });
                    });
            } catch (E) {
                console.info("E", E);
                setBalance(0);
                setPrice(0);
            }
        }else{
            setBalance(Number(hookCoinBalance.data?.formatted));
            //가격정보 호출
            // MetaMaskService.getBalance(coinInfo.address, contract, coinInfo.symbolName,coinInfo.contAddr, 0).then((rst)=>{
            setPrice(Number((new bigDecimal(airdropInfo.coinInfo.exchangeRate).multiply(new bigDecimal(hookCoinBalance.data?.formatted))).getValue()));

        }
    }, [airdropInfo.coinInfo]);
    return (
        <FlexColumn>
            <FlexColumn gap={12}>
                <FlexColumn className={styles.txt20_B01}>{t('지갑당 에어드랍 수량')}</FlexColumn>
                <FlexColumn>
                    <FlexRowSpace>
                        <FlexRowStart style={{width:'80%'}}>
                            <DefaultInput
                                error={errors.amount}
                                inputElement={
                                    <input
                                        type={"number"}
                                        className={styles.inputText_44}
                                        placeholder={t("코인 수량 입력")}
                                        value={amount}
                                        {...register("amount", {
                                            required: t("코인 수량 입력"),
                                            onChange: (el)=>{
                                                setAmount(el.target.value);
                                                const balanceDecimal = new bigDecimal(balance).round(4);
                                                if(new bigDecimal(el.target.value).compareTo(new bigDecimal(0)) <= 0){
                                                    setError("amount", {type:'custom', message:t('수량을 입력해 주세요.')});
                                                    setBtnActive(false);
                                                }else if(balanceDecimal.compareTo(new bigDecimal(el.target.value)) > 0){
                                                    setBtnActive(true);
                                                    clearErrors('amount');
                                                }else{
                                                    setError("amount", {type:'custom', message:t("최대 {0}까지 가능합니다.", {0:decimalFormat(balance, 4)})});
                                                    setBtnActive(false);
                                                }
                                            }
                                        })}
                                    />
                                }
                            />
                        </FlexRowStart>
                        <div className={styles.txt20_B01} style={{width:'20%', textAlign:'right', marginTop:'22px', marginBottom:'auto', borderBottom:'solid 1px #f2f4f8'}}>
                            {airdropInfo.coinInfo.symbolName}
                        </div>
                    </FlexRowSpace>
                    <FlexRowSpace>
                        <FlexRowStart className={styles.txt11_B04}>
                            <div style={{padding:'8px 12px', background:'#F2F4F7', borderRadius:'8px'}}>{t("사용가능 {0}", {0:decimalFormat(balance, 4)})} {airdropInfo.coinInfo.symbolName}</div>
                        </FlexRowStart>
                        <FlexRowEnd className={styles.txt12_B04}>{t("{0}원", {0:decimalFormat(price,2)})}</FlexRowEnd>
                    </FlexRowSpace>
                </FlexColumn>
                <FlexColumnCenter className={styles.txt13_B05}  style={{padding:'16px', borderRadius:'12px', backgroundColor:'#F8F9FC'}}>
                    <div style={{textAlign:'center'}}>
                        <Trans t={t} i18nKey={"지갑당 {0} {1} 씩<br/>{2}개의 지갑에 보냅니다."} values={{0:amount, 1:airdropInfo.coinInfo.symbolName, 2:airdropInfo.airdropCnt}}/>
                    </div>
                    <div className={styles.txt17_600_mint}>{t("총 {0} {1} 사용", {0:new bigDecimal(airdropInfo.airdropCnt).multiply(new bigDecimal(amount)).getValue(), 1:airdropInfo.coinInfo.symbolName})}</div>
                </FlexColumnCenter>
            </FlexColumn>
            <FlexBottom>
                <BottomButton
                    isActive={btnActive}
                    backgroundColor={"#3a71ff"}
                    textColor={"white"}
                    // border={"solid 1px #dbdee6"}
                    onClick={handleSubmit(onSubmit)}
                    text={t("다음")}
                />
            </FlexBottom>
        </FlexColumn>
    )
}