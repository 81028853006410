import React, { useEffect, useState } from "react";
import styled from "styled-components";
import styles from "../../common/css/zigle.module.scss";
import MetaMaskService from "../../services/metamask.service";
import ReactPlaceholder from "react-placeholder";
import CommonService from "../../services/common.service";
import { FlexColumEnd, FlexColumn } from "./container/PageContainer";
import AssetService from "../../services/asset.service";
import {Toast} from "./popup/ToastAlert";
import {useSelector} from "react-redux";
import bigDecimal from "js-big-decimal";
import {useNavigate} from "react-router-dom";
import {decimalFormat} from "../../common/js/utilities";
import {useTranslation} from "react-i18next";

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 70px;
`;

const SymbolImg = styled.div`
  width: 38px;
  height: 38px;
`;

const CryptoInfoWarpper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

interface ICryptoItem {
  coinInfo: any;
  coinBalance:any;
  onClick?: () => void;
  pageInfo?:any;
    address:`0x${string}` | undefined;
}

export default function CryptoItem({ coinInfo, address, coinBalance, onClick, pageInfo }: ICryptoItem) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [balance, setBalance] = useState(0.0);
  const [price, setPrice] = useState(0);
  const [balLoading, setBalLoading] = useState(false);
  const [priceLoading, setPriceLoading] = useState(false);
  const chainId = useSelector((state: any) => state.walletReducer.chainId);
  const [limitSize] = useState("10");
  const [bookHist, setBookHist] = useState("0");


  //balance , contAddr ,

  const setBookMark = (selectCoin:any) =>{
    const params = new FormData();
    params.append('chainId', chainId);
    params.append('coinId', selectCoin.coinId);
    params.append('limitSize', limitSize);

    let msg = "";
    if(bookHist)
    {
      params.append('histId', bookHist);
      msg = t("삭제 되었습니다.");
    }else{
      msg = t("등록 되었습니다.");
    }
    AssetService.updateMyCoin(params).then(
        (response) => {

          if (response)
          {
            setBookHist(response.data > 0 ? response.data : null)
            Toast(msg);
          }else{

          }

        }
    )
  }

  useEffect(() => {
    if(coinInfo.typeCode ==='TOKEN') {
      try {
          MetaMaskService.getBalance(address, coinInfo.contAddr)
              .then((res) => {
              setBalance(Number(res));
              setBalLoading(true);
              //가격정보 호출
              // MetaMaskService.getBalance(coinInfo.address, contract, coinInfo.symbolName,coinInfo.contAddr, 0).then((rst)=>{
              setPrice(Number((new bigDecimal(coinInfo.exchangeRate).multiply(new bigDecimal(res))).getValue()));
              setPriceLoading(true);
              // });
            })
            .catch(() => {
              setBalLoading(true);
              setPriceLoading(true);
            });
      } catch (E) {
          console.log("Error",E)
        setBalance(0);
        setPrice(0);
        setBalLoading(true);
        setPriceLoading(true);
      }
    }else{
      setBalance(Number(coinBalance));
      setBalLoading(true);
      //가격정보 호출
      // MetaMaskService.getBalance(coinInfo.address, contract, coinInfo.symbolName,coinInfo.contAddr, 0).then((rst)=>{
      setPrice(Number((new bigDecimal(coinInfo.exchangeRate).multiply(new bigDecimal(coinBalance))).getValue()));
      setPriceLoading(true);
    }
    setBookHist(coinInfo.myCoin);
  }, [coinInfo]);

  return (
    <ItemWrapper>
      {
        pageInfo?.pageType === "myCoin" && (
              bookHist ? (
                  <div onClick={()=>setBookMark(coinInfo)}><img src={require('../../assets/icons/star_on.png')} width={28} /></div>
                  ) : (
                  <div onClick={()=>setBookMark(coinInfo)}><img src={require('../../assets/icons/star_off.png')} width={28} /></div>
              )
          )
      }
      <SymbolImg>
        <img src={coinInfo.symbolImgFileId} alt={"symbol"} width={38} />
      </SymbolImg>
      <CryptoInfoWarpper onClick={() => {
        if (onClick) {
          onClick();
        }else{
            navigate('/coin', {
                state: {
                    item:coinInfo
                }})
        }
      }}>
        <FlexColumn>
          <div className={styles.txt14_B01}>{coinInfo.coinName}</div>
          <div className={styles.txt12_200_B05}>{coinInfo.symbolName}</div>
        </FlexColumn>
        <FlexColumEnd>
          <ReactPlaceholder
            showLoadingAnimation={true}
            rows={1}
            type="text"
            ready={balLoading}
            style={{ marginBottom: "2px" }}
          >
            <div className={styles.txt16_B01}>
              {decimalFormat(balance)}
            </div>
          </ReactPlaceholder>
          {
              balance > 0 && (
                  <ReactPlaceholder
                      showLoadingAnimation={true}
                      rows={1}
                      type="text"
                      ready={priceLoading}
                  >
                    <div className={styles.txt12_B05}>
                        {t("{0}원", {0:decimalFormat(price, 0)})}
                    </div>
                  </ReactPlaceholder>
              )
          }
        </FlexColumEnd>
      </CryptoInfoWarpper>
    </ItemWrapper>
  );
}
