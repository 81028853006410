import React, {useEffect, useState} from "react";
import {FlexBottom86, FlexColumn, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import {useTranslation} from "react-i18next";

interface ITransferStep {
    handleOnClick?: (item: string)=> void;
}

export default function TransferStep3(props: ITransferStep) {
    const { t } = useTranslation();

    return (
      <>
          <FlexColumn className={styles.txt20_500_B01}>
              {t("어떻게 보낼까요?")}
          </FlexColumn>
          <FlexColumn gap={32} style={{marginTop:'28px'}}>
              <FlexColumn onClick={() => {
                  if(props.handleOnClick) {
                      props.handleOnClick('safeTrans');
                  }
              }}>
                  <FlexRowSpace>
                      <FlexRowStart>
                          <div style={{paddingRight:'12px'}}>
                              <img src={require('../../assets/imges/howto/howto_01.png')} alt="bgIcon" width={48} />
                          </div>
                          <FlexColumn gap={3}>
                              <div className={styles.txt13_B05}>{t("실수 없이 안전하게")}</div>
                              <div className={styles.txt15_B01}>{t("안전 송금")}</div>
                          </FlexColumn>
                      </FlexRowStart>
                      <FlexRowEnd>
                              <img
                                  src={require("../../assets/imges/arrow.png")}
                                  width={20}
                                  alt={"arrow"}
                              />
                      </FlexRowEnd>
                  </FlexRowSpace>
              </FlexColumn>
              <FlexColumn onClick={() => {
                  if(props.handleOnClick) {
                      props.handleOnClick('hpTrans');
                  }
              }}>
                  <FlexRowSpace>
                      <FlexRowStart>
                          <div style={{paddingRight:'12px'}}>
                              <img src={require('../../assets/imges/howto/howto_02.png')} alt="bgIcon" width={48} />
                          </div>
                          <FlexColumn gap={3}>
                              <div className={styles.txt13_B05}>{t("쉽고 편하게 번호로")}</div>
                              <div className={styles.txt15_B01}>{t("전화번호 송금")}</div>
                          </FlexColumn>
                      </FlexRowStart>
                      <FlexRowEnd>
                          <img
                              src={require("../../assets/imges/arrow.png")}
                              width={20}
                              alt={"arrow"}
                          />
                      </FlexRowEnd>
                  </FlexRowSpace>
              </FlexColumn>
              <FlexColumn onClick={() => {
                  if(props.handleOnClick) {
                      alert(t('준비중입니다.'));
                      // props.handleOnClick('normalTrans');
                  }
              }}>
                  <FlexRowSpace>
                      <FlexRowStart>
                          <div style={{paddingRight:'12px'}}>
                              <img src={require('../../assets/imges/howto/howto_03.png')} alt="bgIcon" width={48} />
                          </div>
                          <FlexColumn gap={3}>
                              <div className={styles.txt13_B05}>{t("기존처럼 내맘대로")}</div>
                              <div className={styles.txt15_B01}>{t("직접 송금")}</div>
                          </FlexColumn>
                      </FlexRowStart>
                      <FlexRowEnd>
                          <img
                              src={require("../../assets/imges/arrow.png")}
                              width={20}
                              alt={"arrow"}
                          />
                      </FlexRowEnd>
                  </FlexRowSpace>
              </FlexColumn>
          </FlexColumn>
      </>
    );
}