import React, {CSSProperties, useEffect, useState} from "react";

import {inspect} from "util";
import styles from "../../common/css/zigle.module.scss";
import BottomLayer, { FlexBottomButton } from "../common/container/BottomLayer";
import {FlexColumn, FlexRowStart} from "../common/container/PageContainer";
import BottomButton from "../common/button/BottomButton";
import {useSelector} from "react-redux";
import MetaMaskService from "../../services/metamask.service";
import {IMainnetInfo} from "../../common/dto/MainnetInfo";
import {ICoinInfo} from "../../common/dto/CoinInfoDto";
import {useTranslation} from "react-i18next";

interface ITokenDetail {
    show: boolean;
    setShow: any;
    mainnetList?: any[];
    onHandleClickMainnet?: (item: any)=> void;
}
function CoinPopup(props:ITokenDetail) {

    const { t } = useTranslation();

    const chainId = useSelector((state:any) => state.walletReducer.chainId);
    const disconnect = useSelector((state:any) => state.walletReducer.disconnect);

    return (
        <BottomLayer show={props.show}
         setShow={props.setShow}
         // content={
         //     tesFunction()
         // }
         content={
             props.mainnetList ? (
                 <>
                 {
                     props.mainnetList.map((item,index)=> {
                         return ( (<div key={item.mainnetId} style={{height: '40px', padding: '12px 20px'}} className={styles.txt16_B04}
                                        onClick={()=>{
                                            if(props.onHandleClickMainnet)
                                            {
                                                props.onHandleClickMainnet(item)
                                            }
                                        }}
                         >{t(item.displayName)}</div>))
                     })
                 }
                 </>

             ) : null
         }
        />


    );
}

export default CoinPopup;
