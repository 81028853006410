import React, {ReactHTMLElement, useEffect, useRef, useState} from "react";
import PageTitle, {
    FlexBottom, FlexBottom86,
    FlexColumn, FlexColumnCenter, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper, ZSelect
} from "../common/container/PageContainer";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import MainnetList from "../common/MainnetList";
import CoinList from "../common/CoinList";
import TransferFriend2 from "../transferCoin/TransferFriend2";
import TransferPhone2 from "../transferCoin/TransferPhone2";
import TransferEnd from "../transferCoin/TransferEnd";
import CommonService from "../../services/common.service";
import EmptyContent from "../common/EmptyContent";
import {useInView} from "react-intersection-observer";
import {useForm} from "react-hook-form";
import FavService from "../../services/fav.service";
import PopupService from "../../services/popup.service";
import {Toast} from "../common/popup/ToastAlert";
import {maskingEmail} from "../../common/js/utilities";
import {useTranslation} from "react-i18next";
import ChatService from "../../services/chat.service";
import ChatListItem from "./ChatListItem";

interface IChatSearchPopup {
    show: boolean;
    setShow: any;
    talkPlus:any;
    handleOnClick?: (item: string)=> void;
}
export default function ChatSearchPopup(props:IChatSearchPopup) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchTxt, setSearchTxt] = useState('');
    const [currPage, setCurrPage] = useState(1);
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [blockSize] = useState(10);
    const [reSet, setReSet] = useState(false);
    const [chatList, setChatList] = useState<Array<any>>([]);
    const {
        register,
        resetField,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const fetchData = async(searchTxt: any) =>{
        const response = await ChatService.listChatRoom(searchTxt, "", 1, "ASC", "name", '');

        if (!response.data.length) {
            setLastList(true);
            return;
        }
        setChatList([...chatList, ...response.data]);
    }
    useEffect(() => {

        if (!lastList && props.show && searchTxt) {

            fetchData(searchTxt);
        }
    },[currPage, searchTxt, props.show, reSet]);

    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);

    return (
        <>
            {
                props.show &&
                (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageContent style={{paddingTop:'10px'}}>
                                    <FlexColumn>
                                        <FlexRowStart>
                                            <img
                                                src={require("../../assets/imges/back_arrow.png")}
                                                width={28}
                                                alt={"back_arrow"}
                                                style={{ marginLeft: "-8px" }}
                                                onClick={() => {

                                                    setCurrPage(1);
                                                    setChatList([]);
                                                    setLastList(false);
                                                    setSearchTxt('')
                                                    resetField("searchTxt");
                                                    props.setShow(false);
                                                }}
                                            />
                                            <div style={{width:'100%'}}><input
                                                type={"text"}
                                                className={styles.inputText_44_noborder}
                                                placeholder={t('채팅방을 검색해보세요.')}
                                                value={searchTxt}
                                                {...register("searchTxt", {
                                                    required: t('채팅방을 검색해보세요.'),
                                                    onChange: (el) => {
                                                        setCurrPage(1);
                                                        setChatList([]);
                                                        setLastList(false);
                                                        setSearchTxt(el.target.value);
                                                    }
                                                })}
                                            /></div>
                                        </FlexRowStart>
                                        <div className={styles.line} style={{width: 'calc(100% + 40px)', marginLeft:'-20px'}}/>
                                    </FlexColumn>
                                    <FlexColumn style={{marginTop:'15px'}}>
                                        {
                                            searchTxt && (
                                                chatList.length > 0 ? (
                                                        chatList.map((item, index) => {

                                                            return (
                                                                <ChatListItem key={index} pageType={''} talkPlus={props.talkPlus} item={item} searchTxt={searchTxt}/>
                                                            )
                                                        })
                                                    )
                                                    :
                                                    (
                                                        <EmptyContent titleText={t('검색 결과가 없어요')} titleImage={require('../../assets/imges/list_x.png')}/>
                                                    )
                                                )

                                        }
                                    </FlexColumn>
                                    {
                                        Object.values(chatList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
                                    }
                                </PageContent>

                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>
    )
}