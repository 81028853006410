import React from "react";
import { confirmAlert } from "react-confirm-alert";
import PopAlert from "../components/common/popup/PopAlert";
import PopConfirm from "../components/common/popup/PopConfirm";
import SnsModal from "../components/common/popup/SnsModal"
import mobileStyle from "../components/common/popup/Popup.module.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import $ from "jquery";
import NewPopConfirm from "../components/common/popup/NewPopConfirm";
import NewDatePop from "../components/common/popup/NewDatePop";
import ChatModal from "../components/common/popup/ChatModal";


export interface IPopAction {
  btnTxt?: string | null;
  onClick?: () => void | null | Promise<void>;
}


const newPopup = (title: string,
                  message: string | JSX.Element,
                  subMessage: string | JSX.Element,
                  ok: IPopAction,
                  cancel: IPopAction)=>{
  confirmAlert({
    overlayClassName: mobileStyle.AlertWrapper,
    closeOnClickOutside: false,
    customUI: ({ onClose }) => {
      return (
          <NewPopConfirm
              onClose={onClose}
              message={message}
              subMessage={subMessage}
              title={title ? title : null}
              ok={ok}
              cancel={cancel}
          />
      );
    },
  });
}

const snsLinkMobile = (
    linkUrl: string | null
) => {
  confirmAlert({
    overlayClassName: mobileStyle.AlertWrapper,
    closeOnClickOutside: true,
    // afterClose: () => fnClose ? fnClose() : null,
    customUI: ({ onClose }) => {
      return (
          <SnsModal
              onClose={onClose}
              linkUrl={linkUrl}
          />
      );
    },
  });
};

const chatPopup = (message: string | JSX.Element,) =>{
  confirmAlert({
    overlayClassName: mobileStyle.AlertWrapper,
    closeOnClickOutside: true,
    // afterClose: () => fnClose ? fnClose() : null,
    customUI: ({ onClose }) => {
      return (
          <ChatModal
              onClose={onClose}
              message={message}
          />
      );
    },
  });
}
const chatPopupClose = () => {
  try {
    $("#chatPopClose")[0].click();
  } catch (e) {}
};


const alertMobile = (
  message: string | JSX.Element,
  fnClose?: () => void | null,
  title?: string | null
) => {
  let ok: IPopAction = {
    btnTxt: "OK",
    onClick: fnClose,
  };
  confirmAlert({
    overlayClassName: mobileStyle.AlertWrapper,
    closeOnClickOutside: false,
    // afterClose: () => fnClose ? fnClose() : null,
    customUI: ({ onClose }) => {
      return (
        <PopAlert
          onClose={onClose}
          message={message}
          title={title ? title : null}
          ok={ok}
        />
      );
    },
  });
};

/*
ok | cancel 구조
{
onClick : ()=>{}
,btnTxt:''
}
 */

const confirmMobile = (
  title: string,
  message: string | JSX.Element,
  ok: IPopAction,
  cancel: IPopAction
) => {
  confirmAlert({
    overlayClassName: mobileStyle.AlertWrapper,
    closeOnClickOutside: false,
    customUI: ({ onClose }) => {
      return (
        <PopConfirm
          onClose={onClose}
          message={message}
          title={title ? title : null}
          ok={ok}
          cancel={cancel}
        />
      );
    },
  });
};

const alert = (
  message: string | JSX.Element,
  fnClose?: () => void | null,
  title?: string
) => {
  let ok: IPopAction = {
    btnTxt: "OK",
    onClick: fnClose,
  };
  return confirmAlert({
    overlayClassName: mobileStyle.AlertWrapper,
    closeOnClickOutside: false,
    // afterClose: () => (fnClose ? fnClose() : null),
    customUI: ({ onClose }) => {
      return (
        <PopAlert
          onClose={onClose}
          message={message}
          title={title ? title : null}
          ok={ok}
        />
      );
    },
  });
};

const confirm = (
  title: string | null,
  message: string | JSX.Element,
  ok: IPopAction | null,
  cancel: IPopAction | null
) => {
  confirmAlert({
    overlayClassName: mobileStyle.AlertWrapper,
    closeOnClickOutside: false,
    customUI: ({ onClose }) => {
      return (
        <PopConfirm
          onClose={onClose}
          message={message}
          title={title ? title : null}
          ok={ok}
          cancel={cancel}
        />
      );
    },
  });
};

const alertMobilelose = () => {
  try {
    $("#btnPopClose")[0].click();
  } catch (e) {}
};


const newDatePopup = (
                  ok: IPopAction,
                  cancel: IPopAction)=>{
  confirmAlert({
    overlayClassName: mobileStyle.AlertWrapper,
    closeOnClickOutside: false,
    customUI: ({ onClose }) => {
      return (
          <NewDatePop
              onClose={onClose}
              ok={ok}
              cancel={cancel}
          />
      );
    },
  });
}

const Popup = {
  alert,
  confirm,
  alertMobile,
  confirmMobile,
  alertMobilelose,
  snsLinkMobile,
  newPopup,
  newDatePopup,
  chatPopup,
  chatPopupClose
};

export default Popup;
