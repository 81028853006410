import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FlexBottom, FlexColumn} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import BottomButton from "../common/button/BottomButton";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {ToastError} from "../common/popup/ToastAlert";
import MetaMaskService from "../../services/metamask.service";
import {useAccount, useBalance} from "wagmi";
import {useSelector} from "react-redux";
import {useWalletEthersPorvider} from "../../common/js/ethersProviderHook";

interface ITransferStep {
    transferInfo:any;
    setTransferInfo:any;
    mainnetInfo:any;
    handleOnClick?: (item: string)=> void;
    txIdResult?:(result:any,
                 resultMessage:any,
                 send_token_amount:any,
                 account:any,
                 to_address:any,
                 mainnetId:any,
                 coinId:any,
                 linkMngId:any)=> void;
}
export default function TransferMessage(props:ITransferStep){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { address, isConnected } = useAccount();
    const hookCoinBalance = useBalance({
        address: address,
    });

    const chainId = useSelector((state:any) => state.walletReducer.chainId);
    const disconnect = useSelector((state:any) => state.walletReducer.disconnect);
    const ziglePoint = useSelector((state: any) => state.userReduce.ziglePoint);
    const provider = useWalletEthersPorvider();

    const [coinBalance , setCoinBalance] = useState('0')

    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
    } = useForm();
    const onSubmit = (data: any) => {
        if(!address){
            ToastError(t("지갑연결을 확인해 주세요."));
            return false;
        }
        if(chainId != props.mainnetInfo.chainId)
        {
            MetaMaskService.setSwitchNetwork(provider,parseInt(props.mainnetInfo.chainId), props.mainnetInfo.mainnetName, props.mainnetInfo.symbolName, props.mainnetInfo.decimalVal, props.mainnetInfo.rpcUrl, props.mainnetInfo.explorerUrl, '').then(
                (response) => {
                    if(!response.status)
                    {
                    }
                }
            );
        }else {

            if(props.transferInfo.zigleFee > ziglePoint){
                ToastError(t("Zigle point가 부족합니다."));
                return false;
            }
            MetaMaskService.sendTransferToken(
                address,
                props.transferInfo.coinContAddr,
                props.transferInfo.amount.toString(),
                props.transferInfo.sendWalletAddress,
                props.transferInfo.mainnetId,
                props.transferInfo.coinId,
                props.transferInfo.linkMngId,
                props.txIdResult
            );
        }
    }

    return(
        <FlexColumn>
            <FlexColumn>
                    <textarea
                        style={{border:'none'}}
                        className={styles.textAreaBox}
                        placeholder={t("함께 보낼 메세지를\n작성해주세요(선택사항)")}
                        rows={2}
                        maxLength={200}
                        {...register("message", {
                            maxLength:200,
                            onChange: (el) => {
                                props.setTransferInfo(
                                    {...props.transferInfo,
                                        message: el.target.value
                                    }
                                );
                            }
                        })}
                    />
            </FlexColumn>
            <FlexBottom>
                <BottomButton
                    isActive={true}
                    backgroundColor={"#3a71ff"}
                    textColor={"white"}
                    // border={"solid 1px #dbdee6"}
                    onClick={handleSubmit(onSubmit)}
                    text={t("송금하기")}
                />
            </FlexBottom>
        </FlexColumn>
    )
}