import React, {useEffect, useRef, useState} from "react";
import {
    FlexColumn,
    FlexRowAround,
    FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, ZSelect
} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import {useInView} from "react-intersection-observer";
import PhoneService from "../../services/phone.service";
import EmptyContent from "../common/EmptyContent";
import PointSndHisItem from "./PointSndHisItem";
import CommonService from "../../services/common.service";
import {decimalFormat} from "../../common/js/utilities";
import moment from "moment";
import {useTranslation} from "react-i18next";
export default function PointSndHis() {
    const { t } = useTranslation();
    const [list, setList] = useState<any[]>([])
    const [currPage, setCurrPage] = useState(1);
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [blockSize] = useState(10);
    const [modeType] = useState('O')
    const [searchType, setSearchType] = useState('');
    const refPointCount = useRef(0);
    const refPointSum = useRef(0);

    let monthDay = "";
    let monthResult = "";
    useEffect(() => {
        const fetchData = async() =>{
            const response = await PhoneService.ziglePointList(modeType, searchType, currPage, blockSize);
            if (!response.data.length) {
                setLastList(true);
                return;
            }
            response.data.map((item:any)=>{
                if (item.confirmType === 'Y') {
                    refPointCount.current = refPointCount.current + 1;
                    refPointSum.current = refPointSum.current + item.ziglePoint;
                }
            });
            setList([...list, ...response.data]);
        }
        if (!lastList) {
            
            fetchData();
        }
    },[currPage, modeType, searchType]);

    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);

    return (
        <FlexColumn gap={28}>
            <FlexColumn>
                <FlexRowEnd>
                    <ZSelect onChange={el => {
                        setList([]);
                        setLastList(false);
                        setCurrPage(1);
                        setSearchType(el.target.value);
                        refPointCount.current = 0;
                        refPointSum.current = 0;
                    }}>
                        <option value={""}>{t("전체")}</option>
                        <option value={"Z"}>{t("전화번호송금")}</option>
                    </ZSelect>
                </FlexRowEnd>
            </FlexColumn>
            <FlexRowCenter className={styles.txt14_B05} style={{padding:'16px 20px', borderRadius:'12px', backgroundColor:'#f2f4f6'}}>
                <FlexRowCenter gap={12}>
                    <div>{t("사용 포인트")}</div>
                    <div><span className={styles.txt15_PointB}>{decimalFormat(refPointSum.current)}</span>P</div>
                </FlexRowCenter>
                <div style={{width:'1px', height:'100%', alignSelf:'stretch', flexGrow:'0', backgroundColor:'#e7e9ed'}}></div>
                <FlexRowCenter gap={12}>
                    <div>{t("사용 횟수")}</div>
                    <div><span className={styles.txt15_PointB}>{t("{0}건", {0:decimalFormat(refPointCount.current)})}</span></div>
                </FlexRowCenter>
            </FlexRowCenter>
            <FlexColumn gap={32}>
                {
                    list.length > 0 ?
                        (
                            list.map((item, index)=>{
                                let tmpDay = moment(item.regDate).format('MM월DD일')
                                if((tmpDay) && (monthDay != tmpDay)){
                                    monthResult = "Y";
                                    monthDay = tmpDay;
                                }else{
                                    monthResult = "N";
                                }
                                return (
                                    <PointSndHisItem key={item.histPointId} index={index} item={item} monthResult={monthResult}/>
                                )
                            })
                        )
                        :
                        (
                            <EmptyContent titleText={t('사용 내역이 없어요.')}/>
                        )
                }
                {
                    Object.values(list).length >= blockSize ? (
                        <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) : null
                }
            </FlexColumn>
        </FlexColumn>

    )
}