import React, {ReactHTMLElement, useEffect, useRef, useState} from "react";
import PageTitle, {
    FlexBottom, FlexBottom86,
    FlexColumn, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../common/container/PageContainer";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import CopyToClipboard from "react-copy-to-clipboard";
import BottomButton from "../common/button/BottomButton";
import QRCode from "qrcode.react";
import DefaultInput from "../common/input/DefaultInput";
import {useForm} from "react-hook-form";
import AssetService from "../../services/asset.service";
import PopupService from "../../services/popup.service";
import {ILinkList} from "../../common/dto/LinkDto";
import PhoneList from "../Friend/PhoneList";
import {useTranslation} from "react-i18next";

interface ITokenDetail {
    show: boolean;
    setShow: any;
    setDepth:any;
    transferInfo:any;
    setTransferInfo:any;
    handleOnClick?: (item: string)=> void;
}
function PhonePopup(props:ITokenDetail) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [pageMode, setPageMode] = useState('list');
    const [phoneMode, setPhoneMode] = useState('list')
    const inputRef = useRef<HTMLFormElement>(null);
    const {
        setValue,
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit,
    } = useForm();
    return (
        <>
            {
                props.show &&
                (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle
                                    home={true}
                                    textTitle ={t('지글 친구')}
                                    onClickLeft={()=>{
                                        props.setDepth(3);
                                        props.setShow(false);
                                    }}
                                    rightText={phoneMode ==='list' ? t('편집') : t('완료')}
                                    onClickRight={()=>{
                                        phoneMode ==='list' ? setPhoneMode('write') : setPhoneMode('list');
                                    }}
                                />
                                <PageContent>
                                    <PhoneList  phoneView={''} setPhoneView={''} viewItem={''} handleOnClick={props.handleOnClick} phoneMode={phoneMode} setPhoneMode={setPhoneMode}/>
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>

    );
}

export default PhonePopup;
