import PageTitle, {
    FlexBottomStart,
    FlexColumn, FlexRowCenter,
    FlexRowStart,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import styles from "../common/css/zigle.module.scss";
import AirdropList from "../components/airdrop/AirdropList";
import MyAirdropView from "../components/airdrop/MyAirdropView";
import PopupService from "../services/popup.service";
import AirdropFee from "../components/airdrop/AirdropFee";
import {useTranslation} from "react-i18next";

export default function MyAirdrop(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [airdropFeeShow, setAirdropFeeShow] = useState(false);
    const [home, setHome] = useState(true);
    const [modeType, setModeType] = useState('');
    const [subType, setSubType] = useState('');
    const [airdropView, setAirdropView] = useState(false);
    const [itemInfo, setItemInfo] = useState('');
    const [reLoad, setReLoad] = useState(false);

    const onClickViewItem = (itemIdx:any)=>{
        console.log("onClickViewItem");
        setItemInfo(itemIdx);
        setAirdropView(true);
    }
    const onClickEndViewItem = () =>{
        PopupService.newPopup(t("에어드랍 종료")
            ,t("에어드랍이 종료되었습니다.")
            , ""
            ,{btnTxt:t("확인 했어요"),
                onClick: () => {
                    setReLoad(!reLoad);
                    setAirdropView(false);
                }}
            , {}
        );

    }
    return (
        <PageRootContainer>
            <MyAirdropView setShow={setAirdropView} setAirdropFeeShow={setAirdropFeeShow} show={airdropView} itemInfo={itemInfo} reLoad={reLoad} setReLoad={setReLoad} onClickEndViewItem={onClickEndViewItem}/>
            <AirdropFee setAirdropFeeShow={setAirdropFeeShow} airdropFeeShow={airdropFeeShow} />
            <PageTitle home={home} textTitle={t('내 에어드랍')} onClickLeft={()=>{ navigate(-1);}}  rightSrc={require('../assets/imges/airdrop/airdrop_fee.png')} onClickRight={()=>{ setAirdropFeeShow(true) }}/>
            <PageContent>
                <FlexColumn gap={16}>
                    <FlexColumn>
                        <FlexRowStart gap={15} >
                            <div className={ modeType === '' ? styles.topTabActive : styles.topTabDefault} onClick={()=>{setModeType(''); setReLoad(!reLoad); }}>{t('전체')}</div>
                            <div className={ modeType === 'I' ? styles.topTabActive : styles.topTabDefault} onClick={()=>{setModeType('I'); setReLoad(!reLoad); }}>{t("진행예정")}</div>
                            <div className={ modeType === 'S' ? styles.topTabActive : styles.topTabDefault} onClick={()=>{setModeType('S'); setReLoad(!reLoad); }}>{t("진행중")}</div>
                            <div className={ modeType === 'E' ? styles.topTabActive : styles.topTabDefault} onClick={()=>{setModeType('E'); setReLoad(!reLoad); }}>{t("종료")}</div>
                        </FlexRowStart>
                        <div className={styles.line} style={{width: 'calc(100% + 40px)', marginLeft:'-20px'}}/>
                    </FlexColumn>
                    <FlexColumn  className={styles.overFlowScrillHideBar}>
                        <FlexRowStart  style={{width:'420px'}} gap={8}>
                            <div className={ subType === '' ? styles.airdropSubTabActive : styles.airdropSubTabDefault} onClick={()=>{setSubType(''); setReLoad(!reLoad); }}>{t('최신순')}</div>
                            <div className={ subType === 'E' ? styles.airdropSubTabActive : styles.airdropSubTabDefault} onClick={()=>{setSubType('E'); setReLoad(!reLoad); }}>{t('마감임박')}</div>
                            <div className={ subType === 'O' ? styles.airdropSubTabActive : styles.airdropSubTabDefault} onClick={()=>{setSubType('O'); setReLoad(!reLoad); }}>{t('오래된 순')}</div>
                            <div className={ subType === 'P' ? styles.airdropSubTabActive : styles.airdropSubTabDefault} onClick={()=>{setSubType('P'); setReLoad(!reLoad); }}>{t('높은금액 순')}</div>
                        </FlexRowStart>
                    </FlexColumn>
                    <FlexColumn gap={12}>
                        <AirdropList modeType={modeType} subType={subType} pageMode={'MY'} reLoad={reLoad} onClickViewItem={onClickViewItem} />
                    </FlexColumn>
                    <FlexBottomStart>
                        <FlexRowCenter className={styles.txt15_PointB} style={{height:'52px', width:'135px', borderRadius:'100px', backgroundColor:'#f2f4f6'}} onClick={()=>{navigate("/airdropCreate")}}>
                            <img src={require('../assets/icons/plus_blue.png')} width={20} /> {t('에어드랍 추가')}
                        </FlexRowCenter>
                    </FlexBottomStart>
                </FlexColumn>
            </PageContent>
        </PageRootContainer>
    )
}
