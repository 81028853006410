import styles from "../../common/css/zigle.module.scss";
import QRCode from "qrcode.react";
import {
    FlexBottom,
    FlexColumn,
    FlexColumnCenter, FlexRowCenter,
    FlexRowSpace,
    FlexRowStart,
    ToggleBtn,
    ToggleCircle
} from "../common/container/PageContainer";
import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import CopyToClipboard from "react-copy-to-clipboard";
import {Toast} from "../common/popup/ToastAlert";
import DatePicker from "react-datepicker";
import BottomButton from "../common/button/BottomButton";
import AirdropService from "../../services/airdrop.service";
import EmptyContent from "../common/EmptyContent";
import CommonService from "../../services/common.service";
import {IAirdropTransfer} from "../../common/dto/AirdropList";
import moment from 'moment';
import {Confirm_Map} from "../../pages/MyPoint";
import Popup from "../../services/popup.service";
import PopupService from "../../services/popup.service";
import {decimalFormat, ellipseAddress} from "../../common/js/utilities";
import {Trans, useTranslation} from "react-i18next";

interface IMyAirdropViewPartic {
    viewItem: any;
}
export default function MyAirdropViewPartic(props:IMyAirdropViewPartic){
    const { t } = useTranslation();
    const [userList, setUserList] = useState<Array<IAirdropTransfer>>([]);
    const [refund, setRefund] = useState<IAirdropTransfer>();
    const txIdCount = useRef(0);


    const popupView = (item:any)=>{
        PopupService.newPopup(t("참여내역")
            ,<FlexColumn gap={20}>
                <FlexColumn gap={20}>
                    <FlexRowCenter>
                        <img src={require('../../assets/icons/confirm.png')} width={64} />
                    </FlexRowCenter>
                </FlexColumn>
                <FlexColumn style={{backgroundColor:'#f2f4f6', borderRadius:'10px', padding:'20px'}} gap={8}>
                    <FlexRowSpace>
                        <div className={styles.txt13_B05}>{t("입금금액")}</div>
                        <div className={styles.txt13_300_B05}>{decimalFormat(item.amount)} {item.coinSymbolName}</div>
                    </FlexRowSpace>
                    <FlexRowSpace>
                        <div className={styles.txt13_B05}>{t("수수료")}</div>
                        <div className={styles.txt13_300_B05}>{item.airdropFee} {item.mainnetSymbolName}</div>
                    </FlexRowSpace>
                    <FlexRowSpace>
                        <div className={styles.txt13_B05}>TXID</div>
                        <div className={styles.txt13_300_B05}>
                            {ellipseAddress(item.txId, 6)}
                            <CopyToClipboard text={item.txId} onCopy={() => Toast(t("주소를 복사 했습니다."))}>
                                <img src={require("../../assets/icons/copy.png")} height={20} alt={"copy"} />
                            </CopyToClipboard>
                        </div>
                    </FlexRowSpace>
                </FlexColumn>
            </FlexColumn>
            , ""
            ,{btnTxt:t("확인"), onClick: () => {

            }}, {})
    }

    useEffect(()=>{
        if(props.viewItem){
            AirdropService.airdropUserList(props.viewItem.airdropMngId).then(
                (res)=>{
                    setUserList(res.data);
                    res.data.map((item:any)=>{
                        if (item.airdropState === 'DO') {
                            txIdCount.current = txIdCount.current + 1;
                        }
                    });

                }
            )
            if(props.viewItem.airdropState === "E"){
                AirdropService.airdropRefund(props.viewItem.airdropMngId).then((res)=>{
                    if(res.data){
                        setRefund(res.data);
                    }
                });
            }
        }
    },[props.viewItem]);
    useEffect(()=>{
        console.log("refund",refund)
    },[refund])
    return (
        <>
            <FlexColumn gap={28}>
                <FlexColumn gap={8}>
                    <FlexColumn className={styles.txt20_B01}>
                        <div><Trans t={t} className={styles.myAirdropViewPartic} i18nKey={"{0}명 풀에 {1}명이 참여했고<br/>그 중 <span style={{fontWeight:'700'}}>{2}명이 받았어요!</span>"} values={{0:props.viewItem.airdropCnt, 1:props.viewItem.participateCount, 2:txIdCount.current}}/></div>
                    </FlexColumn>
                    {
                        refund && (
                            <FlexRowSpace className={styles.txt13_B05}>
                                <div><Trans t={t} i18nKey={"나머지 {0} {1}은<br/>환불 되었어요"} values={{0:refund.amount, 1:refund.coinSymbolName}} /></div>
                                <div style={{padding:'10px 16px', background:'#111111', borderRadius:'8px', color:'#ffffff'}} onClick={()=>{
                                    window.open(refund?.mainnetExplorerUrl+"/tx/"+refund?.txId, "_blank", "noreferrer");
                                }}>{t("확인하기")}</div>
                            </FlexRowSpace>
                        )
                    }
                </FlexColumn>
                <FlexColumn gap={12}>
                    {
                        userList.length > 0 ?
                            (
                                userList.map((item, index) => {
                                        return (
                                            <FlexRowSpace className={styles.select_mainnet} key={item.histAirdropId}
                                                          style={{backgroundColor: '#ffffff'}}>
                                                <FlexRowStart gap={12}>
                                                    <FlexColumn>
                                                        <div className={styles.txt15_B02}>{ellipseAddress(item.receiveAddr, 6)}</div>
                                                        <div className={styles.txt12_B05}>{moment(item.regDatetime).format('YYYY-MM-DD')}</div>
                                                    </FlexColumn>
                                                </FlexRowStart>

                                                {
                                                    item.txId ? (
                                                        <div className={styles.txt12_B05}  style={{textDecoration:'Underline'}}
                                                             onClick={()=>{
                                                                 popupView(item);
                                                             }}>TXID</div>
                                                        ) : (
                                                            <div className={styles.txt12_B05}>{item.txId ? "TXID" : "..."}</div>
                                                        )
                                                }
                                            </FlexRowSpace>
                                        )
                                    }
                                )
                            ) : (
                                <EmptyContent titleText={t('신청한 유저가 없습니다.')} titleImage={require('../../assets/icons/empty_icon.png')}/>
                            )
                    }
                </FlexColumn>
            </FlexColumn>
        </>
    )
}