import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import styles from "../common/css/zigle.module.scss";
import PageTitle, {
    FC,
    FlexBottom,
    FlexColumn,
    FlexColumSpace,
    FlexRowAround, FlexRowCenter, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FR, PageContent, PageRootContainer
} from "../components/common/container/PageContainer";
import {useSelector} from "react-redux";
import CommonService from "../services/common.service";
import BottomButton from "../components/common/button/BottomButton";
import PhoneList from "../components/Friend/PhoneList";
import FriendList from "../components/Friend/FriendList";
import PointSndHis from "../components/point/PointSndHis";
import PointRcvHis from "../components/point/PointRcvHis";
import {JsonData} from "../common/dto/CommonDto";
import {decimalFormat} from "../common/js/utilities";
import {useTranslation} from "react-i18next";


export const Event_Map:JsonData = {
    'A':'충전신청',
    'R':'Reffer',
    'F':'수수료',
    'L':'Login'
}

export const PayType_Map:JsonData = {
    'K1':'무통장 입금',
    'K2':'신용카드 결제',
    'K3':'핸드폰 결제',
    'P':'Paypal 입금',
    'T':'암호화폐 충전',
    'Z':'전화번호 송금',
    'A':'Airdrop',
    'R':'리퍼럴',
    'L':'회원가입'
}
export const Confirm_Map:JsonData = {
    'N':'신청중',
    'C':'취소중',
    'I':'입금확인중',
    'F':'충전 실패',
    'Y':'충전 완료'
}

export default function MyPoint() {
    const navigate = useNavigate();
    const ziglePoint = useSelector((state: any) => state.userReduce.ziglePoint);
    const [tabNum, setTabNum] = useState(0);
    const { t } = useTranslation();

    return (
        <PageRootContainer>
            <PageTitle
                textTitle ={t('내 포인트')}
                onClickLeft={()=>{
                    navigate(-1);
                }}
            />
            <PageContent>
                <FlexColumn gap={28}>
                    <FlexColumn gap={24}>
                        <FlexColumn>
                            <div className={styles.txt14_B04}>{t('내 결제 포인트')}</div>
                            <div className={styles.txt28_B01}>{decimalFormat(ziglePoint)} P</div>
                        </FlexColumn>
                        <FlexColumn>
                            <BottomButton
                                isActive={true}
                                backgroundColor={"#3a71ff"}
                                textColor={"white"}
                                // border={"solid 1px #dbdee6"}
                                onClick={()=>{navigate("/zigleCharge");}}
                                text={t("충전하기")}
                                radius={'8px'}
                            />
                        </FlexColumn>
                        <FlexColumn gap={8}>
                            <FlexColumn gap={4} style={{padding:'20px', borderRadius:'16px', lineHeight:'22px', backgroundColor:'#f2f4f6'}}>
                                <div className={styles.txt14_B04}>{t('결제 포인트란?')}</div>
                                <span className={styles.txt13_B05}>
                                    {t('지글에서 안전 송금 또는 전화번호 송금 등에 사용되는 수수료로써 개인 지갑 이용 시에 이더리움을 예치해놓고 사용하는 방식에 비해 간편하고 안전하게 코인 전송 서비스를 이용 할 수 있습니다. 또 다양한 결제처 이용 가능하도록 준비 중입니다.')}
                                </span>
                            </FlexColumn>
                            <FlexColumn  className={styles.txt12_B05}>
                                {t('채굴을 통해 얻은 지글(ZIG)은 결제 포인트로 전환 가능합니다.')}
                            </FlexColumn>
                        </FlexColumn>
                    </FlexColumn>
                    <FlexColumn gap={30}>
                        <FlexColumn>
                            <FlexRowAround>
                                <div
                                    className={
                                        tabNum === 0 ? styles.topTabActive : styles.topTabDefault
                                    }
                                    onClick={() => {
                                        setTabNum(0);
                                    }}
                                >
                                    {t('사용 내역')}
                                </div>
                                <div
                                    className={
                                        tabNum === 1 ? styles.topTabActive : styles.topTabDefault
                                    }
                                    onClick={() => {
                                        setTabNum(1);
                                    }}
                                >
                                    {t('충전 내역')}
                                </div>
                            </FlexRowAround>
                            <div className={styles.line} style={{width: 'calc(100% + 40px)', marginLeft:'-20px'}}/>
                        </FlexColumn>
                        <FlexColumn>
                            {tabNum === 0 && <PointSndHis />}
                            {tabNum === 1 && <PointRcvHis />}
                        </FlexColumn>
                    </FlexColumn>

                </FlexColumn>
            </PageContent>
        </PageRootContainer>
    )
}
