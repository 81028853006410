import React from "react";
import {FlexRowAround} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import {useTranslation} from "react-i18next";

interface IPayDepthProps {
    payInfo:any;
    setPayInfo:any;
}

export default function UsdSelect({payInfo, setPayInfo}:IPayDepthProps) {
    const { t } = useTranslation();

    return (
        <FlexRowAround>
            <div className={ payInfo.subPayType === 'P' ? styles.imageBoxActive : styles.imageBoxDefault}
                 onClick={()=>{
                     setPayInfo(
                         {...payInfo,
                             subPayType : 'P'
                         }
                     );
                 }}>
                <img
                    src={require("../../assets/imges/payment/paypal.png")}
                    width={40}
                    alt={"payment01"}
                />
                <span>Payapl</span>
            </div>
            <div style={{width:'102px'}}></div>
            <div style={{width:'102px'}}></div>
        </FlexRowAround>
    )
}