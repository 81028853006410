import axios from "axios";
import {getHeader} from "./profile.service";
import {checkStatus401, checkStatus401RstType} from "./common.service";
import {Client} from "talkplus-sdk";
import {JsonData} from "../common/dto/CommonDto";

let API_URL = "";

const talkPlusAppId = process.env.REACT_APP_TALKPLUS_APPID ?? "";
const talkPlusAppKey = process.env.REACT_APP_TALKPLUS_APIKEY ?? "";

if (process.env.REACT_APP_ENVTEXT === "local") {
    API_URL = "/api";
} else {
    API_URL = process.env.REACT_APP_API_URL ?? "https://api.zigle.link/api";
}

const userLogin = (email: string, userId : string) =>{
    return axios.post("https://api.talkplus.io/v1.4/api/users/login", {
        userId:userId,
        password:email +"_" + userId
    }, {
        headers: {
            "Content-Type" : "application/json",
            "api-key": talkPlusAppKey,
            "app-id": talkPlusAppId
        },
    })
        .then((response) => {
            return response;
        }).catch(error=>{
            return error.response;
        });
}
const userRegister = (email: string, userId : string, doname : string, profileImageUrl : string) =>{
    return axios.post("https://api.talkplus.io/v1.4/api/users/create", {
        userId:userId,
        password:email +"_" + userId,
        userName:doname,
        profileImageUrl : profileImageUrl,
    }, {
        headers: {
            "Content-Type" : "application/json",
            "api-key": talkPlusAppKey,
            "app-id": talkPlusAppId
        },
    })
    .then((response) => {
        return response;
    }).catch(error=>{
        return error.response;
    });
}
const userCheck = (userId : string) => {
    return axios
        .get("https://api.talkplus.io/v1.4/api/users/"+userId, {
            headers: {
                "api-key": talkPlusAppKey,
                "app-id": talkPlusAppId
            }
        })
        .then((response) => {
            return response;
        }).catch(error=>{
            console.log("error",error.response)
            return error.response;
        });
};

const createChatRoom = (chatId:string , doname:string) =>{
    const params = new URLSearchParams();
    params.append("chatId", chatId);
    params.append("doname", doname);

    return axios
    .post(API_URL + "/v1/user/chat/createChatRoom", params,{
        headers: {
            ...getHeader(),
        },
    })
    .then((response) => {
        return response;
    });
}
const banChatRoom = (chatId:string, userId:string, delYn:string ) =>{
    const params = new URLSearchParams();
    params.append("chatId", chatId);
    params.append("userId", userId);
    params.append("delYn", delYn);

    return axios
        .post(API_URL + "/v1/user/chat/banChatRoom", params,{
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response;
        });
}
const setTokenCheck = (chatId:string, mainnetId:string, coinId:string, amount:string, checkYn:string) =>{
    const params = new URLSearchParams();
    params.append("chatId", chatId);
    params.append("mainnetId", mainnetId);
    params.append("coinId", coinId);
    params.append("amount", amount);
    params.append("checkYn", checkYn);

    return axios
        .post(API_URL + "/v1/user/chat/setTokenCheck", params,{
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        });
}
const joinChatRoom = (chatId:string, delYn:string ) =>{
    const params = new URLSearchParams();
    params.append("chatId", chatId);
    params.append("delYn", delYn);

    return axios
        .post(API_URL + "/v1/user/chat/joinChatRoom", params,{
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response;
        });
}
const listChatRoom = (searchTxt:string, pageType: string, currentPage: number, orderType: string | null, orderBy: string | 'DESC', userId: string | null, ) => {
    let paramJsonData: JsonData = {};
    paramJsonData['searchTxt'] = searchTxt;
    paramJsonData['pageType'] = pageType;
    paramJsonData['currentPage'] = currentPage;
    paramJsonData['orderType'] = orderType;
    paramJsonData['orderBy'] = orderBy;
    paramJsonData['userId'] = userId;

    return axios
        .get(API_URL + "/v1/user/chat/listChatRoom", {
            params: paramJsonData,
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error: any) => {
            checkStatus401(error.response);
        });
};
const chatCnt = (chatId:string) =>{
    let paramJsonData: JsonData = {};
    paramJsonData['chatId'] = chatId;
    return axios
        .get(API_URL + "/v1/user/chat/chat", {
            params: paramJsonData,
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error: any) => {
            checkStatus401(error.response);
        });
}
const listChatUser = (chatId:string, currentPage:number, blockSize:number)=>{
    let paramJsonData: JsonData = {};
    paramJsonData['chatId'] = chatId;
    paramJsonData['currentPage'] = currentPage;
    paramJsonData['blockSize'] = blockSize;
    return axios
        .get(API_URL + "/v1/user/chat/listChatUser", {
            params: paramJsonData,
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error: any) => {
            checkStatus401(error.response);
        });
}
const ChatService = {
    userCheck,
    userRegister,
    userLogin,
    createChatRoom,
    joinChatRoom,
    listChatRoom,
    chatCnt,
    listChatUser,
    banChatRoom,
    setTokenCheck,
};

export default ChatService;
