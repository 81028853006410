import React, { useEffect, useState } from "react";
import styles from "../../common/css/zigle.module.scss";
import {FlexColumn} from "../common/container/PageContainer";

import GuideImg1 from "../../assets/imges/metamask/metamask_guide5_1.png";
import GuideImg2 from "../../assets/imges/metamask/metamask_guide5_2.png";
import {Trans, useTranslation} from "react-i18next";
export default function Guide5() {
    const { t } = useTranslation();
    return (
        <FlexColumn gap={24}>
            <FlexColumn gap={8}>
                <div className={styles.txt18_500}>Purse import progress</div>
                <div className={styles.txt14_B05} style={{lineHeight:'1.71'}}>
                    <Trans t={t} i18nKey={"시드 구문의 단어 순서에 해당하는 버튼을 클릭합니다.<br/>올바르게 입력되면 확인 버튼이 파란색으로 바뀝니다."}/>
                </div>
                <div><img src={GuideImg1} alt="Guide Img" style={{width:'100%'}}/></div>
            </FlexColumn>
            <FlexColumn gap={8}>
                <div className={styles.txt18_500}>Completion</div>
                <div className={styles.txt14_B05} style={{lineHeight:'1.71'}}>
                    {t("확인버튼을 클릭하면 설치가 완료 됩니다.")}
                </div>
                <div><img src={GuideImg2} alt="Guide Img" style={{width:'100%'}}/></div>
            </FlexColumn>
        </FlexColumn>
    )
}