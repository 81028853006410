import CommonService from "../../services/common.service";
import { IAction, IWallet } from "../../common/dto/CommonDto";
import PopupService from "../../services/popup.service";
import { InjectedConnector } from 'wagmi/connectors/injected';
import {useWeb3Modal} from "@web3modal/react";

export const WEB3_INIT = "INIT";
export const CONNECT = "connect";
export const AUTO_CONNECT = "auto_connect";
export const CONNECTED = "connected";
export const NOT_INSTALL = "not_install";
export const DISCONNECT = "disconnect";

const initial: IWallet = {
  openModal:()=>{},
  chainId: 0,
  metaInstall: true,
  mainNetDetail: {},
};


const walletReducer = (currentState = initial, action: IAction) => {

  switch (action.type) {
    case NOT_INSTALL:
      PopupService.newPopup("지갑을 연결해야 서비스를 이용할수 있어요","메타마스크 서비스 페이지로 이동합니다.", "",{btnTxt:"확인", onClick: () => {
          window.location.href = '/metamask';
        }}, {})
      return {
        ...currentState

      };
    case WEB3_INIT:
      return {
        ...currentState,
        openModal: action.payload.openModal,
      };
    case CONNECT:
      if(!currentState.metaInstall){
        //메타마스크 페이지로 이동.
        PopupService.newPopup("지갑을 연결해야 서비스를 이용할수 있어요","메타마스크 서비스 페이지로 이동합니다.", "",{btnTxt:"확인", onClick: () => {
            window.location.href = '/metamask';
          }}, {})
      }
      if (currentState.openModal != null) {
        currentState.openModal();
      }

      return {
        ...currentState,
      };
    case AUTO_CONNECT:

      return {
        ...currentState,
      };
    case CONNECTED:

      return {
        ...currentState,
        chainId: action.payload.chainId,
        // mainNetDetail: {},
        mainNetDetail: CommonService.chainIdTokenList(
            action.payload.chainId,
            "mainnetDetail",
            ""
        )
        .then((response) => {
          return response.data;

        })
        .catch(() => {
          return {};
        }),
      };
    case DISCONNECT:
      disconnect(currentState).then(() => {

      });
      return {
        ...currentState,
        chainId: 0,
        metaInstall: true,
        mainNetDetail: {},

      }
    default:
      return { ...currentState };
  }
};

const disconnect = async (currentState: IWallet) => {
  const tmpAccessToken = localStorage.getItem("accessToken") ?? "";
  const tmpRefreshToken = localStorage.getItem("refreshToken") ?? "";
  const tmpUser = localStorage.getItem("user") ?? "";
  window.localStorage.clear();
  localStorage.setItem("accessToken", tmpAccessToken);
  localStorage.setItem("refreshToken", tmpRefreshToken);
  localStorage.setItem("user", tmpUser);
  // document.location.reload();
};

export default walletReducer;
