import BottomLayer from "../common/container/BottomLayer";
import React, {useState} from "react";
import {FlexBottom, FlexColumn, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import DefaultInput from "../common/input/DefaultInput";
import {useForm} from "react-hook-form";
import BottomButton from "../common/button/BottomButton";
import AirdropService from "../../services/airdrop.service";
import {Toast} from "../common/popup/ToastAlert";
import {useTranslation} from "react-i18next";

interface IFoundationView {
    show:boolean;
    setShow: any;
    item: any;
}
export default function FoundationView(prop:IFoundationView){
    const { t } = useTranslation();
    const {
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit,
    } = useForm();
    const [informationContentsLength , setInformationContentsLength] = useState(0);

    const onSubmit = (data: any) => {

        const params = new FormData();
        params.append("airdropMngId", prop.item.airdropMngId);
        params.append("airdropName", data.airdropName);
        params.append("foundationName", data.foundationName);
        params.append("homepageUrl", data.homepageUrl);
        params.append("informationContents", data.informationContents);

        AirdropService.airdropFoundation(params).then(
            (res)=> {
                if (res.status) {
                    prop.item.foundationName = data.foundationName;
                    prop.item.airdropName = data.airdropName;
                    prop.item.homepageUrl = data.homepageUrl;
                    prop.item.informationContents = data.informationContents;
                    Toast(t('수정 되었습니다.'));
                    prop.setShow(false);
                }
            });
    }
    return (
        <BottomLayer show={prop.show} setShow={prop.setShow} content={
            (
                <>
                    <FlexColumn gap={20}  style={{paddingBottom:'120px'}}>
                        <FlexColumn gap={12}>
                            <DefaultInput
                                lable={t("제목")}
                                error={errors.airdropName}
                                inputElement={
                                    <input
                                        type={"text"}
                                        className={styles.inputText_44}
                                        placeholder={t("제목")}
                                        defaultValue={prop.item.airdropName}
                                        {...register("airdropName", {
                                        })}
                                    />
                                }
                            />
                            <div className={styles.txt14_B04}>{t("에어드랍 상세 설명")}</div>
                            <FlexColumn style={{
                                backgroundColor: '#F8F9FC',
                                borderRadius: '16px',
                                padding: '16px 12px'
                            }}>
                                    <textarea
                                        style={{border: 'none'}}
                                        className={styles.textAreaGrayBox}
                                        placeholder={t("상세 설명을 입력해주세요.")}
                                        rows={4}
                                        maxLength={30}
                                        defaultValue={prop.item.informationContents}
                                        {...register("informationContents", {
                                            required: t("상세 설명을 입력해주세요."),
                                            maxLength: 200,
                                            onChange: (el) => {
                                                setInformationContentsLength(el.target.value.length);
                                            }
                                        })}
                                    />
                                <FlexRowEnd className={styles.txt15_W03}>({informationContentsLength}/200)</FlexRowEnd>
                            </FlexColumn>
                        </FlexColumn>
                        <FlexColumn>
                            <DefaultInput
                                lable={t("첨부 링크(선택)")}
                                error={errors.homepageUrl}
                                marginBottom={4}
                                inputElement={
                                    <input
                                        type={"text"}
                                        className={styles.inputText_44}
                                        defaultValue={prop.item.homepageUrl}
                                        placeholder={t("회사 홈페이지 URL")}
                                        {...register("homepageUrl", {
                                        })}
                                    />
                                }
                            />
                            <div className={styles.txt13_B06}>{t("텔레그램, SNS, 웹사이트 등")}</div>
                        </FlexColumn>
                    </FlexColumn>
                    <FlexBottom>
                        <BottomButton
                            isActive={true}
                            backgroundColor={"#3a71ff"}
                            textColor={"white"}
                            // border={"solid 1px #dbdee6"}
                            onClick={handleSubmit(onSubmit)}
                            text={t("수정")}
                        />
                    </FlexBottom>
                    </>
            )}
        />
    )
}