import React, {useEffect, useState} from 'react'
import styles from "./loading.module.css";
import {FadeLoader,PacmanLoader,GridLoader,HashLoader} from "react-spinners";

const axiosLoading:React.CSSProperties  = {
    inset:'0px',
    width: '100vw',
    height: '100vh',
    zIndex: '999',
    overflow: 'auto',
    display: 'block',
    position: 'fixed',
    backgroundColor: 'rgba(37, 37, 37, 0.3)',
    backdropFilter: 'blur(3px)'
}

const axiosLoadingIndicator:React.CSSProperties  = {
    top:'45%',
    left: 'calc(50% - 15px)',
    position:'fixed'
}


interface ILoading {
    loading:boolean
}

export default function Loading({loading}: ILoading): JSX.Element {
    const [check, setCheck] = useState(false);

    useEffect(() => {
        if (loading) {
            setCheck(true);
            // document.body.style.overflow = "hidden";
        } else {
            setCheck(false);
            // document.body.style.overflow = "unset";
        }
    }, [loading])

    if (check) {
        return (
            <div style={axiosLoading}>
                <div style={axiosLoadingIndicator}>
                    <HashLoader color="#4f46e5"
                                size={50}
                                speedMultiplier={1}
                    />

                </div>
            </div>
        );
    } else {
        return (<></>)
    }
};
