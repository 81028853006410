import React, {useEffect, useState} from "react";
import PageTitle, {
    FlexBottom,
    FlexColumn, FlexRowAround, FlexRowCenter,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import {useLocation, useNavigate} from "react-router-dom";
import styles from "../common/css/zigle.module.scss";
import {Bar, Box} from "./Profile";
import {ICoinInfo} from "../common/dto/CoinInfoDto";
import MetaMaskService from "../services/metamask.service";
import bigDecimal from "js-big-decimal";
import {useSelector} from "react-redux";
import CommonService from "../services/common.service";
import CoinRcvHis from "../components/Coin/CoinRcvHis";
import CoinSndHis from "../components/Coin/CoinSndHis";
import {decimalFormat} from "../common/js/utilities";
import {useAccount, useBalance} from "wagmi";
import {useTranslation} from "react-i18next";

export default function Coin(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {state} = useLocation();

    const { address, isConnected } = useAccount();

    const hookCoinBalance = useBalance({
        address: address,
    });


    const [tabNum, setTabNum] = useState(0);

    const [balance, setBalance] = useState(0.0);
    const [price, setPrice] = useState(0);
    const [coinDetailInfo, setCoinDetailInfo] = useState<ICoinInfo>();
    useEffect(()=>{
        if(state){
            const {item} = state;	// 3번 라인
            setCoinDetailInfo(item);
            if(item.typeCode ==='TOKEN') {
                try {
                    MetaMaskService.getBalance(address, item.contAddr).then((res) => {
                            setBalance(Number(res));
                            //가격정보 호출

                            // MetaMaskService.getBalance(coinInfo.address, contract, coinInfo.symbolName,coinInfo.contAddr, 0).then((rst)=>{
                            setPrice(Number((new bigDecimal(item.exchangeRate).multiply(new bigDecimal(res))).getValue()));

                            // });
                        })
                        .catch(() => {
                        });
                } catch (E) {
                    console.info("E", E);
                    setBalance(0);
                    setPrice(0);
                }
            }else{
                setBalance(Number(hookCoinBalance.data?.formatted));
                //가격정보 호출
                // MetaMaskService.getBalance(coinInfo.address, contract, coinInfo.symbolName,coinInfo.contAddr, 0).then((rst)=>{
                setPrice(Number((new bigDecimal(item.exchangeRate).multiply(new bigDecimal(hookCoinBalance.data?.formatted))).getValue()));
            }
        }
    },[state])
    return (
        <PageRootContainer>
            <PageTitle
                onClickLeft={() => {
                    navigate(-1);
                }}
            />
            <PageContent>
                <FlexColumn gap={32}>
                    <FlexColumn gap={24}>
                        <FlexColumn>
                            <div className={styles.txt14_B04}>{coinDetailInfo?.displayName}</div>
                            <div className={styles.txt28_B01}>{decimalFormat(balance)} <span className={styles.txt22_500_B05}>{coinDetailInfo?.symbolName}</span></div>
                            <div>≈ {decimalFormat(price)}원</div>
                        </FlexColumn>
                        <FlexRowCenter gap={8}>
                            <div className={styles.profile_btn_white} onClick={()=>navigate("/myLink")}><div className={styles.txt16}>{t('받기')}</div></div>
                            <div className={styles.profile_btn_black} onClick={()=>navigate("/transfer")}><div className={styles.txt16} style={{color:'#ffffff'}}>{t('송금')}</div></div>
                        </FlexRowCenter>
                    </FlexColumn>
                    <Bar/>
                </FlexColumn>
                <Box style={{ padding: "32px 0" }}>
                    {/* 송금 / 받기 TAB*/}
                    <FlexRowAround>
                        <div
                            className={
                                tabNum === 0 ? styles.topTabActive : styles.topTabDefault
                            }
                            onClick={() => setTabNum(0)}
                        >
                            {t('송금')}
                        </div>
                        <div
                            className={
                                tabNum === 1 ? styles.topTabActive : styles.topTabDefault
                            }
                            onClick={() => setTabNum(1)}
                        >
                            {t('받기')}
                        </div>
                    </FlexRowAround>
                    <div className={styles.line} style={{width: 'calc(100% + 40px)', marginLeft:'-20px'}}/>
                    <div style={{paddingTop:'28px'}}>
                        {tabNum===0 && (<CoinSndHis coinId={coinDetailInfo?.coinId}/>)}
                        {tabNum===1 && (<CoinRcvHis coinId={coinDetailInfo?.coinId}/>)}
                    </div>
                </Box>
            </PageContent>
        </PageRootContainer>
    );
}