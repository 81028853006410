import PageTitle, {
    FlexBottom, FlexBottomStart,
    FlexColumn, FlexColumnCenter, FlexRowAround, FlexRowCenter, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper, QrArea
} from "../common/container/PageContainer";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import CopyToClipboard from "react-copy-to-clipboard";
import BottomButton from "../common/button/BottomButton";
import QRCode from "qrcode.react";
import DefaultInput from "../common/input/DefaultInput";
import {useForm} from "react-hook-form";
import AssetService from "../../services/asset.service";
import PopupService from "../../services/popup.service";
import {ILinkList} from "../../common/dto/LinkDto";
import {Toast} from "../common/popup/ToastAlert";
import {Trans, useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import CommonService from "../../services/common.service";
import FavService from "../../services/fav.service";
import {useInView} from "react-intersection-observer";
import EmptyContent from "../common/EmptyContent";
import {useSelector} from "react-redux";
import ChatService from "../../services/chat.service";
import ChatListItem from "../chat/ChatListItem";

interface IUserChatRoom {
    show: boolean;
    setShow: any;
    doname:string;
    userId:string;
    talkPlus:any;
}

export default function UserChatRoom(props:IUserChatRoom) {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const [lastIndex, setLastIndex] = useState(0);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [blockSize] = useState(10);
    const [lastList, setLastList] = useState(false);
    const [currPage, setCurrPage] = useState(1);
    const login: boolean = useSelector((state: any) => state.userReduce.login);
    const [chatList, setChatList] = useState<Array<any>>([]);

    const [myChannel, setMyChannel] = useState<any>({
        channel:{

        }
    });
    const handleOnClick=(selectItem: any)=>{
        props.setShow(false);
        setChatList([]);
        setLastList(false);
        setCurrPage(1);
        navigate("/u/"+selectItem);
    }

    const getAllChannels = async()=>{
        ChatService.listChatRoom('', 'my', currPage, '', 'DESC', props.userId).then((data) =>{
            if (!data.data.length) {
                setLastList(true);
                return;
            }
            setChatList([...chatList, ...data.data]);
        });
    }

    useEffect(()=>{
        if(props.show){
            getAllChannels();
        }
    },[props.show, currPage]);

    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);
    return (
        <>
            {
                props.show &&
                (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle
                                    textTitle={props.doname ?? t("닉네임이 없습니다.")}
                                    onClickLeft={() => {

                                        props.setShow(false);
                                        setChatList([]);
                                        setLastList(false);
                                        setCurrPage(1);
                                    }}
                                />
                                <PageContent>
                                    <FlexColumn style={{marginTop:'5px'}}>

                                        {
                                            chatList.length > 0 ?
                                                (
                                                    chatList.map((item,index)=>{
                                                        return (
                                                            <ChatListItem key={index} pageType={"profile"} talkPlus={props.talkPlus} item={item} searchTxt={''} handleOnClick={handleOnClick}/>
                                                        )
                                                    })
                                                ) : (
                                                    <EmptyContent titleText={t('등록된 채팅방이 없습니다.')}/>
                                                )
                                        }
                                    </FlexColumn>
                                    {
                                        Object.values(chatList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
                                    }
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>
    )
}