import React, {CSSProperties, useEffect, useRef, useState} from "react";

import {inspect} from "util";
import styles from "../../common/css/zigle.module.scss";
import BottomLayer, {BottomTitlte, FlexBottomButton, Overlay} from "../common/container/BottomLayer";
import {FlexBottom, FlexBottomFree, FlexColumn, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import BottomButton from "../common/button/BottomButton";
import {useSelector} from "react-redux";
import MetaMaskService from "../../services/metamask.service";
import {IMainnetInfo} from "../../common/dto/MainnetInfo";
import {ICoinInfo} from "../../common/dto/CoinInfoDto";
import {IAirdropSet} from "../../pages/AirdropFi";
import TextareaAutosize from "react-textarea-autosize";
import {register} from "../../serviceWorkerRegistration";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

interface IAirdropPopup {
    show: boolean;
    setShow: any;
    onHandleClickMainnet?: (item: any)=> void;
    airdropInfo: IAirdropSet;
    setAirdropInfo: any;
}
function AirdropPopup(props:IAirdropPopup) {
    const { t } = useTranslation();
    const [airdropList, setAirdropList] = useState<string>('');
    const textAreaHeight = useRef<number>(0);
    const {
        register,
        formState: {errors},
        handleSubmit,
        setError,
        clearErrors,
    } = useForm();
    const onCheckHandle = async() => {
        const userArray = airdropList.split(",");
        let setArray = userArray.filter((v, i) => userArray.indexOf(v) === i);
        props.setAirdropInfo({
            ...props.airdropInfo,
            tmpAirDropList: [...props.airdropInfo.tmpAirDropList, ...setArray]
        })
        props.setShow(false);
    }
    useEffect(()=>{
        if(props.show){
            setAirdropList('');
        }
    },[props.show])
    return (
        <>
        {
            props.show &&
            (
                <Overlay>
                    <FlexBottomFree style={{top: 'calc(100% - '+(220+textAreaHeight.current)+'px)', height: (220+textAreaHeight.current)}} gap={16}>
                        <FlexRowSpace>
                            <FlexRowStart className={styles.txt17_B01} >{t("인원 추가")}</FlexRowStart>
                            <FlexRowEnd>
                                <img src={require('../../assets/icons/close_28.png')} width={28} height={28}
                                     alt={"close"}
                                     onClick={()=>{
                                         setAirdropList('');
                                         textAreaHeight.current = 0;
                                         props.setShow(false);

                                     }}/>
                            </FlexRowEnd>
                        </FlexRowSpace>
                        <FlexColumn gap={16}>
                            <TextareaAutosize
                                style={{border: 'none'}}
                                className={styles.textAreaBox}
                                placeholder={props.airdropInfo.airdropSubType === 'wallet' ? t("지갑주소입력") : t("닉네임입력")}
                                value={airdropList}
                                onHeightChange={(height) => {
                                    textAreaHeight.current = height;
                                }}
                                {...register("airdropList", {
                                    required: t('지갑주소입력'),
                                    onChange: (el) => {

                                        setAirdropList(el.target.value);
                                    }
                                })}
                            />
                            <FlexBottom>
                                <BottomButton
                                    isActive={true}
                                    backgroundColor={"#3a71ff"}
                                    textColor={"white"}
                                    // border={"solid 1px #dbdee6"}
                                    onClick={()=>{
                                        onCheckHandle();
                                    }}
                                    text={t("추가하기")}
                                />
                            </FlexBottom>
                        </FlexColumn>
                    </FlexBottomFree>
                </Overlay>
            )
        }
        </>
    )
}
export default AirdropPopup;
