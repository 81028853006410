import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import PageTitle, {
    FlexBottom, FlexColumn,
    FlexColumnCenter,
    FlexRowCenter, FlexRowEnd,
    PageContent,
    PageRootContainer
} from "../common/container/PageContainer";
import {useNavigate} from "react-router-dom";
import ProfileService from "../../services/profile.service";
import styled from "styled-components";
import DefaultInput from "../common/input/DefaultInput";
import styles from "../../common/css/zigle.module.scss";
import {useForm} from "react-hook-form";
import BottomButton from "../common/button/BottomButton";
import PopupService from "../../services/popup.service";
import {UPDATE_DONAME, UPDATE_IMG, UPDATE_INTRODUCE} from "../reducer/userReducer";
import ReactPlaceholder from "react-placeholder";
import {Trans, useTranslation} from "react-i18next";
import {ToastError} from "../common/popup/ToastAlert";
import {IChatView} from "../../pages/Chat";
import ChatService from "../../services/chat.service";
import {ChannelTypeEnum} from "talkplus-sdk";

const HidddenInputBox = styled.input`
  display: none;
`;

export default function ModifyProfile({talkPlus}:IChatView){
    const { t } = useTranslation();
    const [load, setLoad] = useState(false);
    const profileRef = useRef<any>();
    const inputRef = useRef<HTMLFormElement>(null);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userId: string = useSelector((state: any) => state.userReduce.userId);
    const email: string = useSelector((state: any) => state.userReduce.email);
    const profileImageFileId: string = useSelector((state: any) => state.userReduce.profileImageFileId);
    const doname:string = useSelector((state: any) => state.userReduce.doname);
    const introduce:string = useSelector((state: any) => state.userReduce.introduce);
    const [btnActive, setBtnActive] = useState(true);
    const [introduceLength , setIntroduceLength] = useState(0);
    const {
        register,
        setValue,
        setError,
        formState: { errors},
        handleSubmit,
    } = useForm();

    useEffect(()=>{
        setValue("doname", doname);
        setValue("introduce", introduce);
        if(introduce){
            setIntroduceLength(introduce.length);
        }

        if (profileImageFileId) {
            setLoad(true);
        }
        console.log("talkPlus",talkPlus);
    },[doname,profileImageFileId, introduce, talkPlus])




    // dispatch({
    //     type: UPDATE_IMG,
    //     payload: {
    //         profileImageFileId: ''
    //     }
    // });
    const onSubmit = (data: any) => {


        if (doname === null || doname === undefined || doname === "")
        {

            ProfileService.isDoName(inputRef.current?.doname.value).then(res=>{
                if(res.data.exist)
                {
                    setError(
                        'doname', // 에러 핸들링할 input요소 name
                        { message: '사용중인 닉네임 입니다.' }, // 에러 메세지
                        { shouldFocus: true }, // 에러가 발생한 input으로 focus 이동
                    );
                }else{
                    // ProfileService.introduceChange(inputRef.current?.)
                    if(introduce !==inputRef.current?.introduce.value){
                        ProfileService.introduceChange(inputRef.current?.introduce.value).then(res=>{
                            dispatch({
                                type: UPDATE_INTRODUCE,
                                payload: {
                                    introduce: inputRef.current?.introduce.value
                                }
                            });
                        })
                    }
                    ProfileService.doNameChange(inputRef.current?.doname.value).then(
                        (response) => {
                            if (response.data.bResult) {
                                dispatch({
                                    type: UPDATE_DONAME,
                                    payload: {
                                        doname: inputRef.current?.doname.value
                                    }
                                });
                                ChatService.userLogin(email, userId).then(async res=>{
                                    console.log("userLogin", res);

                                    // ChatHelp.chatLogin(userToken.userId, data.data.doname, data.data.profileImageFileId, res.data.loginToken);
                                    await talkPlus.loginWithToken({
                                        userId: userId, // unique userId
                                        username: inputRef.current?.doname.value, // username
                                        loginToken: res.data.loginToken, // login token issued by admin REST API
                                        profileImageUrl: profileImageFileId,
                                    }).then(async (res:any)=>{
                                        console.log("loginWithToken",res)

                                        await talkPlus.updateUser({
                                            username:inputRef.current?.doname.value,
                                            profileImageUrl: profileImageFileId
                                        });

                                        await talkPlus.getChannel({channelId: userId+"_chat"})
                                            .then(async (res:any)=>{
                                                await talkPlus.updateChannel({
                                                    channelId: userId+"_chat",
                                                    name: inputRef.current?.doname.value,
                                                    imageUrl:profileImageFileId
                                                })
                                                ChatService.createChatRoom(userId+"_chat",inputRef.current?.doname.value);
                                                await talkPlus.hideChannel({channelId: userId+"_chat"});
                                            })
                                            .catch(async (err:any)=>{
                                                console.log("err", err)
                                                await talkPlus.createChannel({
                                                    channelId: userId+"_chat",
                                                    name: inputRef.current?.doname.value,
                                                    type: ChannelTypeEnum.SuperPublic,
                                                    members: [],
                                                    maxMemberCount:100000,
                                                    imageUrl:profileImageFileId
                                                }).then(async (res:any)=>{
                                                    ChatService.createChatRoom(userId+"_chat",inputRef.current?.doname.value);
                                                    await talkPlus.hideChannel({channelId: userId+"_chat"});
                                                })

                                            });
                                    })
                                });
                                PopupService.newPopup(t("닉네임 등록 완료")
                                    ,t("닉네임[{0}]을 등록 하셨습니다.", {0:inputRef.current?.doname.value})
                                    , ""
                                    ,{btnTxt:t("확인 했어요"),
                                        onClick: () => {

                                        }}
                                    , {}
                                );
                            }
                        }
                    );
                }
            });
        }else{
            if(introduce !==inputRef.current?.introduce.value){
                ProfileService.introduceChange(inputRef.current?.introduce.value).then(res=>{
                    dispatch({
                        type: UPDATE_INTRODUCE,
                        payload: {
                            introduce: inputRef.current?.introduce.value
                        }
                    });
                })
            }else{
                ToastError("변경된 값이 없습니다.");
            }

        }

    };

    const handleFileChange = (e:any) => {

        ProfileService.photoChange(e.target.files[0]).then(
            (response) => {
                if (response.data) {
                    dispatch({
                        type: UPDATE_IMG,
                        payload: {
                            profileImageFileId: response.data
                        }
                    });
                    ChatService.userLogin(email, userId).then(async res=>{
                        console.log("userLogin", res);

                        // ChatHelp.chatLogin(userToken.userId, data.data.doname, data.data.profileImageFileId, res.data.loginToken);
                        await talkPlus.loginWithToken({
                            userId: userId, // unique userId
                            username: doname, // username
                            loginToken: res.data.loginToken, // login token issued by admin REST API
                            profileImageUrl: response.data,
                        }).then(async (res:any)=>{
                            console.log("loginWithToken",res)

                            await talkPlus.updateUser({
                                username:doname,
                                profileImageUrl: response.data
                            });

                            await talkPlus.getChannel({channelId: userId+"_chat"})
                                .then(async (res:any)=>{
                                    await talkPlus.updateChannel({
                                        channelId: userId+"_chat",
                                        name: doname,
                                        imageUrl:response.data
                                    })
                                    await talkPlus.hideChannel({channelId: userId+"_chat"});
                                })
                                .catch(async (err:any)=>{
                                    console.log("err", err)
                                    await talkPlus.createChannel({
                                        channelId: userId+"_chat",
                                        name: doname,
                                        type: ChannelTypeEnum.SuperPublic,
                                        members: [],
                                        maxMemberCount:100000,
                                        imageUrl:response.data
                                    }).then(async (res:any)=>{
                                        await talkPlus.hideChannel({channelId: userId+"_chat"});
                                    })

                                });
                        })
                    })
                    PopupService.newPopup(t("프로필 이미지 변경 완료")
                        ,t("프로필 이미지를 변경 하였습니다.")
                        , ""
                        ,{btnTxt:t("확인 했어요"),
                            onClick: () => {

                            }}
                        , {}
                    );
                }
            }
        );
    };

    const handleProfileImageUploader = (e: React.MouseEvent) => {
        profileRef.current.click();
    };


    return (
        <PageRootContainer>
            <PageTitle onClickLeft={() => navigate("/profile")}
                       textTitle={t("프로필변경")}
            />
            <PageContent>
                <FlexRowCenter style={{position:'relative'}}>
                    <HidddenInputBox
                        ref={profileRef}
                        type="file"
                        onChange={handleFileChange}
                    ></HidddenInputBox>
                    <ReactPlaceholder ready={load} type={"round"}>
                    <img
                        src={profileImageFileId}
                        width={96}
                        height={96}
                        alt={"profile image"}
                        style={{borderRadius:'100px', aspectRatio:'1', objectFit:'cover'}}
                        onClick={(e) => {
                            handleProfileImageUploader(e);
                        }}
                    />
                        <img
                            src={require("../../assets/imges/profile/modify.png")}
                            width={28}
                            alt={"modify"}
                            style={{position:'absolute', bottom:'0px', right:'120px', zIndex:'100'}}
                            onClick={(e) => {
                                handleProfileImageUploader(e);
                            }}
                        />
                    </ReactPlaceholder>
                </FlexRowCenter>
                <FlexColumn gap={15}>
                    <form ref={inputRef} onSubmit={handleSubmit(onSubmit)} style={{width: "100%"}}>
                        <DefaultInput
                            lable={t("닉네임")}
                            error={errors.doname}
                            inputElement={
                                <input
                                    type={"text"}
                                    className={styles.inputText_44}
                                    placeholder={t("닉네임을 입력해 주세요.")}
                                    defaultValue={doname}
                                    readOnly={doname ? true : false}
                                    {...register("doname", {
                                        required: t("닉네임을 입력해 주세요."),
                                        minLength: {
                                            value: 2, message: t("닉네임을 2자 이상 입력해 주세요.")
                                        },
                                        maxLength: {
                                            value: 10, message: t("닉네임을 8자 이하로 입력해 주세요.")
                                        },
                                        onChange: (el) => {
                                            el.target.value = el.target.value.replace(' ','')
                                        }
                                    })}
                                />
                            }
                        />
                        <FlexColumn gap={10}>
                            <DefaultInput
                                lable={t("소개글")}
                                error={errors.introduce}
                                inputElement={
                                    <FlexColumn style={{
                                        backgroundColor: '#F8F9FC',
                                        borderRadius: '16px',
                                        padding: '16px 12px'
                                    }}>
                                            <textarea
                                                style={{border: 'none'}}
                                                className={styles.textAreaGrayBox}
                                                placeholder={t("소개글을 입력해주세요.")}
                                                rows={2}
                                                maxLength={30}
                                                defaultValue={introduce}
                                                {...register("introduce", {
                                                    required: t("소개글을 입력해주세요."),
                                                    maxLength: 30,
                                                    onChange: (el) => {
                                                        setIntroduceLength(el.target.value.length);
                                                    }
                                                })}
                                            />
                                        <FlexRowEnd className={styles.txt15_W03}>({introduceLength}/30)</FlexRowEnd>
                                    </FlexColumn>
                                }/>
                        </FlexColumn>
                    </form>
                    <FlexColumn  className={styles.txt12_300_B04} style={{padding:'20px 40px', backgroundColor:'#F8F9FC', borderRadius:'12px'}}>
                        <ul style={{paddingLeft:'0px'}}>
                            <li><Trans t={t} className={styles.modifyProfileSpan} i18nKey={"닉네임 설정 후 <span style={{fontWeight:'700'}}>변경할 수 없으니</span> 신중하게 결정해주세요."}/></li>
                            <li>{t("안전 송금 및 에어드랍 등에 닉네임이 사용되므로 변경 후 서비스 이용에 유의하세요.")}</li>
                        </ul>
                    </FlexColumn>
                </FlexColumn>

                {
                    <FlexBottom>
                        <BottomButton
                            isActive={btnActive}
                            backgroundColor={"#3a71ff"}
                            textColor={"white"}
                            // border={"solid 1px #dbdee6"}
                            onClick={handleSubmit(onSubmit)}
                            text={t("수정")}
                        />
                    </FlexBottom>
                }

            </PageContent>

        </PageRootContainer>
    );
}