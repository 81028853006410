import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import {FlexColumn, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import {ICoinInfo} from "../../common/dto/CoinInfoDto";
import {useInView} from "react-intersection-observer";
import CommonService from "../../services/common.service";
import {useForm} from "react-hook-form";
import {IMainnetInfo} from "../../common/dto/MainnetInfo";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import MetaMaskService from "../../services/metamask.service";
import EmptyContent from "../common/EmptyContent";

interface ILinkStep {
    handleOnClick?: (item: IMainnetInfo)=> void;
}

export default function MainnetList(props: ILinkStep) {
    const { t } = useTranslation();
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();
    const [searchCoin, setSearchCoin] = useState('');

    const [mainNetList, setMainNetList] = useState<Array<IMainnetInfo>>([]);
    const [currPage, setCurrPage] = useState(1);
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [blockSize] = useState(10);

    useEffect(() => {
        const fetchData = async(searchCoin: any) =>{
            const response = await CommonService.mainnetList(searchCoin, currPage, blockSize, '');
            if (!response.data.length) {
                setLastList(true);
                return;
            }
            setMainNetList([...mainNetList, ...response.data]);
        }
        if (!lastList) {

            fetchData(searchCoin);
        }
    },[currPage, searchCoin]);

    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);

    return (
        <>
            <FlexColumn gap={12}>
                <FlexColumn className={styles.txt20_500_B01}>
                    {t("어떤 메인넷을 사용할까요?")}
                </FlexColumn>
                <FlexColumn>
                    <FlexRowStart>
                        <div style={{width:'28px', border: '0', borderBottom: 'solid 1px #f2f4f8', height: '47px', display: 'flex',alignItems: 'center'}}><img src={require('../../assets/imges/search_icon.png')} style={{width:'28px'}} /></div>
                        <div style={{width:'100%'}}><input
                            type={"text"}
                            className={styles.inputText_44}
                            placeholder={t('메인넷명을 입력해 주세요')}
                            defaultValue={searchCoin}
                            onChange ={(el) => {
                                setCurrPage(1);
                                setMainNetList([]);
                                setLastList(false);
                                setSearchCoin(el.target.value);
                            }}
                        /></div>
                    </FlexRowStart>
                </FlexColumn>
            </FlexColumn>
            {
                mainNetList.length > 0 ? (
                        mainNetList.map((item, index) => {
                            return (
                                <FlexColumn key={item.mainnetId} style={{height:'78px'}}>
                                    <FlexRowSpace  onClick={()=>{
                                        if(props.handleOnClick) {
                                            props.handleOnClick(item);
                                        }
                                    }}>
                                        <FlexRowStart>
                                            <div style={{paddingRight:'12px'}}>
                                                <img src={item.symbolImgFileId} alt="bgIcon" width={38} />
                                            </div>
                                            <FlexColumn>
                                                <div className={ item.displayName ? styles.txt14_B01 : styles.txt14_}>{item.displayName}</div>
                                                <div className={styles.txt12_B05}>{item.symbolName}</div>
                                            </FlexColumn>
                                        </FlexRowStart>
                                        <FlexRowEnd>
                                            {/*<div>*/}
                                            {/*    <div className={styles.tokenListSubBadge}>*/}
                                            {/*        <div className={styles.txt11_B04}>체인 ID {item.chainId}</div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                        </FlexRowEnd>
                                    </FlexRowSpace>
                                </FlexColumn>
                            )
                        })
                    ) :
                    (
                        < EmptyContent titleText={t('메인넷을 찾을수 없어요')} titleImage={require('../../assets/icons/empty_icon.png')}/>
                    )
            }
            {
                Object.values(mainNetList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
            }
        </>
    );
}
