import axios from "axios";
import { getHeader } from "./profile.service";
import {
  IHpInfoReqDto,
  IHpTransReqDto,
} from "../common/dto/request/UserReqDto";
import { checkStatus401, checkStatus401RstType } from "./common.service";

let API_URL = "";
if (process.env.REACT_APP_ENVTEXT === "local") {
  API_URL = "/api";
} else {
  API_URL = process.env.REACT_APP_API_URL ?? "https://api.zigle.link/api";
}

const phoneDelete = (hpId: string) => {
  // urlType : mainnetlink, coinlink, oneclick
  return axios
    .get(API_URL + "/v1/user/hptrans/hpdelete/", {
      params: {
        hpId: hpId,
      },
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatus401(error.response);
    });
};
const phoneInsert = (params: any) => {
  return axios
    .post(API_URL + "/v1/user/hptrans/hpinsert", params, {
      headers: {

        ...getHeader(),
      },
    })
    .then((response) => {
      return { status: true, msg: "" };
    })
    .catch((error) => {
      return checkStatus401RstType(error.response);
    });
};
const phoneUpdate = (params: any) =>{
  return axios
      .post(API_URL + "/v1/user/hptrans/hpudate", params, {
        headers: {
          ...getHeader(),
        },
      })
      .then((response) => {
        return { status: true, msg: "" };
      })
      .catch((error) => {
        return checkStatus401RstType(error.response);
      });
}
const phoneList = (
    searchTxt: string,
  currentPage: number,
  blockSize: number
) => {
  return axios
    .get(API_URL + "/v1/user/hptrans/hplist", {
      params: {
        searchTxt: searchTxt,
        currentPage: currentPage,
        blockSize: blockSize,
        pagingYn: "Y",
      },
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatus401(error.response);
    });
};
const phoneDeposit = (txId:string, account:string, to_address:string, sendParam:any) => {

  const params = new URLSearchParams();
  params.append('mainnetId', sendParam.mainnetId);
  params.append('coinId', sendParam.coinId);
  params.append('fromAddr', account);
  params.append('toAddr', to_address);
  params.append('amount', sendParam.amount);
  params.append('ziglePoint', sendParam.zigleFee);
  params.append('hpContry', sendParam.countryNum);
  params.append('hpNum', sendParam.phoneNum);
  params.append('txId', txId);
  params.append('useConfirm', sendParam.safeOn ? 'Y' : 'N');
  params.append('message', sendParam.message);

  return axios
    .post(API_URL + "/v1/user/hptrans/deposit", params, {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return checkStatus401RstType(error.response);
    });
};
const hpTransferList = (
  currentPage: number,
  blockSize: number,
  modeType: string
) => {
  return axios
    .get(API_URL + "/v1/user/hptrans/depositList", {
      params: {
        modeType: modeType,
        currentPage: currentPage,
        blockSize: blockSize,
        pagingYn: "Y",
      },
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return checkStatus401RstType(error.response);
    });
};
const phoneCancle = (histHpId: string) => {
  // urlType : mainnetlink, coinlink, oneclick
  return axios
    .get(API_URL + "/v1/user/hptrans/deposit?histHpId=" + histHpId, {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return { status: true, msg: "" };
    })
    .catch((error) => {
      return checkStatus401RstType(error.response);
    });
};

const phoneConfirm = (histHpId: string) => {
  const params = new URLSearchParams();
  params.append("histHpId", histHpId);
  return axios
    .post(API_URL + "/v1/user/hptrans/confirmDeposit", params, {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return { status: true, msg: "" };
    })
    .catch((error) => {
      return checkStatus401RstType(error.response);
    });
};
const ziglePointList = (modeType:string, searchTxt:string, currentPage: number, blockSize: number) => {
  return axios
    .get(API_URL + "/v1/user/hptrans/point", {
      params: {
        modeType: modeType,
        searchTxt: searchTxt,
        currentPage: currentPage,
        blockSize: blockSize,
        pagingYn: "Y",
      },
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return checkStatus401RstType(error.response);
    });
};
const ziglePointBank = (payInfo: any) => {

  const params = new URLSearchParams();
  params.append("accountType", "I");
  params.append("payType", payInfo.subPayType);
  params.append("eventType", payInfo.eventType);
  params.append("ziglePoint", payInfo.ziglePoint);
  params.append("amount", payInfo.amount);
  params.append("symbolName", payInfo.cryptoInfo.symbolName);


  if (payInfo.subPayType === "K1") {
    params.append("bankUser",payInfo.bankInfo.userName + " " + payInfo.bankInfo.checkNum);
    params.append("bankName", payInfo.bankInfo.bankName);
    params.append("toAddr", payInfo.bankInfo.accountNum);
    params.append("txId", "");
  } else {
    params.append("fromAddr", payInfo.cryptoInfo.fromAddr);
    params.append("toAddr", payInfo.cryptoInfo.toAddr);
    params.append("txId", payInfo.cryptoInfo.txId);
  }
  return axios
    .post(API_URL + "/v1/user/hptrans/point", params, {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return { status: true, msg: response.data.data.bResult };
    })
    .catch((error) => {
      return checkStatus401RstType(error.response);
    });
};
const exchangeList = () => {
  return axios
    .get(API_URL + "/v1/user/hptrans/exchangeLate", {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return checkStatus401RstType(error.response);
    });
};
const PhoneService = {
  phoneList,
  phoneInsert,
  phoneDelete,
  phoneUpdate,
  phoneDeposit,
  hpTransferList,
  phoneCancle,
  phoneConfirm,
  // phoneView,
  // updateConfirmSms,
  ziglePointList,
  ziglePointBank,
  exchangeList,
};

export default PhoneService;
