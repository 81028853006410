import React, {useEffect, useRef, useState} from "react";
import styles from "../common/css/zigle.module.scss";

import PageTitle, {
  FlexBottom,
  FlexColumn,
  FlexRowAround,
  PageContent,
  PageRootContainer,
} from "../components/common/container/PageContainer";
import BottomButton from "../components/common/button/BottomButton";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import DefaultInput from "../components/common/input/DefaultInput";
import KrwSelect from "../components/ziglecharge/KrwSelect";
import UsdSelect from "../components/ziglecharge/UseSelect";
import TokenSelect from "../components/ziglecharge/TokenSelect";
import PayDepth1 from "../components/ziglecharge/PayDepth1";
import PayDepth2 from "../components/ziglecharge/PayDepth2";
import PayDepthEnd from "../components/ziglecharge/PayDepthEnd";
import {PayType_Map} from "./MyPoint";
import PayDepthCryptoEnd from "../components/ziglecharge/PayDepthCryptoEnd";
import {useTranslation} from "react-i18next";

interface IPayInfo {
  pageTitle:string;
  payType:string;
  subPayType:string;
  eventType:string;
  depth: number;
  ziglePoint: string;
  amount:string;
  bankInfo:{
    userName:string;
    bankName:string;
    accountNum:string;
    bankOwner:string;
    checkNum:string;
  },
  cryptoInfo:{
    txId:string;
    fromAddr:string;
    toAddr:string;
    tokenType:string;
    chainId:string;
    symbolName:string;
    symbolFileName:string;
    contAddr:string;
    exchangeRate:string;
    mainnetName:string;
    explorerUrl:string;
    rpcUrl:string;
    symbolImgFileId:string;
    decimalVal:string;
  }
}
function ZigleCharge() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [payInfo, setPayInfo] = useState({
    pageTitle:t('포인트 충전'),
    payType:'K',
    subPayType:'',
    eventType:'A',
    depth: 1,
    ziglePoint: '0',
    amount:'0',
    bankInfo:{
      userName:'',
      bankName:process.env.REACT_APP_BANK_NAME,
      accountNum:process.env.REACT_APP_BANK_NUM,
      bankOwner:process.env.REACT_APP_BANK_OWNER,
      checkNum: Math.floor(Math.random() * 899) + 100
    },
    cryptoInfo:{
      txId: '',
      fromAddr: '',
      toAddr:process.env.REACT_APP_WALLET_ADDRESS,
      tokenType:'',
      chainId:'',
      symbolName:'',
      symbolFileName:'',
      contAddr:'',
      exchangeRate:'',
      mainnetName:'',
      explorerUrl:'',
      rpcUrl:'',
      symbolImgFileId:'',
      decimalVal:''
    }
  });
  const reset = ()=>{
    setPayInfo(
        {
          pageTitle:t('포인트 충전'),
          payType:'K',
          subPayType:'',
          eventType:'A',
          depth: 1,
          ziglePoint: '0',
          amount:'0',
          bankInfo:{
            userName:'',
            bankName:process.env.REACT_APP_BANK_NAME,
            accountNum:process.env.REACT_APP_BANK_NUM,
            bankOwner:process.env.REACT_APP_BANK_OWNER,
            checkNum: Math.floor(Math.random() * 899) + 100
          },
          cryptoInfo:{
            txId: '',
            fromAddr: '',
            toAddr:process.env.REACT_APP_WALLET_ADDRESS,
            tokenType:'',
            chainId:'',
            symbolName:'',
            symbolFileName:'',
            contAddr:'',
            exchangeRate:'',
            mainnetName:'',
            explorerUrl:'',
            rpcUrl:'',
            symbolImgFileId:'',
            decimalVal:''
          }
        })
  }

  return (
    <PageRootContainer>
      {
        payInfo.depth === 10 ?
            (
                <PageTitle rightSrc={require('../assets/icons/close_28.png')} onClickRight={() => {
                  reset();
                }}/>
            )
            :
            (
                <PageTitle textTitle = {payInfo.pageTitle} onClickLeft={()=>{
                  if(payInfo.depth == 1)
                  {
                    navigate(-1);
                  }else{
                    setPayInfo(
                        {...payInfo,
                          pageTitle:t('포인트 충전'),
                          depth : payInfo.depth-1
                        }
                    );
                  }
                }}
                />
            )
      }
      <PageContent>
        { payInfo.depth ===1 && (<PayDepth1 payInfo={payInfo} setPayInfo={setPayInfo} /> )}
        { ((payInfo.depth ===2) && (payInfo.payType==='K'))  && (<PayDepth2 payInfo={payInfo} setPayInfo={setPayInfo} /> )}
        { (payInfo.depth ===10 && payInfo.payType==='K')  && (<PayDepthEnd payInfo={payInfo} setPayInfo={setPayInfo} reset={reset}/> )}
        { (payInfo.depth ===10 && payInfo.payType==='T')  && (<PayDepthCryptoEnd payInfo={payInfo} setPayInfo={setPayInfo} reset={reset}/> )}
      </PageContent>
    </PageRootContainer>
  );
}

export default ZigleCharge;
