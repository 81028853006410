import MetaMaskService from "../../services/metamask.service";
import {FlexColumn, FlexRowStart} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import React, {useRef, useState} from "react";
import {TextField} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
import {useForm} from "react-hook-form";
import PhonePopup from "./PhonePopup";
import FriendPopup from "./FriendPopup";
import {useTranslation} from "react-i18next";

interface ITransferStep {
    transferInfo:any;
    setTransferInfo:any;
    setDepth:any;
    handleOnClick?: (item: string)=> void;
}

export default function TransferFriend(props: ITransferStep) {
    const [btnActive, setBtnActive] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [phoneShow, setPhoneShow] = useState(true);
    const inputRef = useRef<HTMLFormElement>(null);

    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
    } = useForm();
    const onSubmit = (data: any) => {

    }
    return (
        <FlexColumn gap={12}>
            <FriendPopup show={phoneShow} setShow={setPhoneShow}  setDepth={props.setDepth} transferInfo={props.transferInfo} setTransferInfo={props.setTransferInfo}  handleOnClick={props.handleOnClick} />
        </FlexColumn>
    )
}