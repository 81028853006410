import {FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";


export default function ChatTime(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [timeView, setTimeView] = useState('');
    useEffect(()=>{
        var interval = 1000;
        let getCurrentTime = moment();
        let targetTime = moment().endOf('day');
        let timeResult = targetTime.diff(getCurrentTime, 'seconds');
        var duration = moment.duration(timeResult, 'seconds');
        var intv = setInterval(function () {
            duration = moment.duration(duration.asSeconds() - 1, 'seconds');
            var timer = {
                days: duration.days(),
                hours: (duration.hours() < 10) ? '0' + duration.hours() : duration.hours(),
                minutes: (duration.minutes() < 10) ? '0' + duration.minutes() : duration.minutes(),
                seconds: (duration.seconds() < 10) ? '0' + duration.seconds() : duration.seconds()
            }
            setTimeView(timer.hours + ':' + timer.minutes + ':<span style="color:#838B96">' + timer.seconds+'</span>');
        } , interval);
    },[])
    return (
        <FlexRowSpace>
            <FlexRowStart gap={4}>
                <img src={require('../../assets/imges/chat/icon_20.png')} width={20}/>
                <div className={styles.txt15_500_B02}>{t("보상 수령까지 남은 시간")}</div>
            </FlexRowStart>
            <FlexRowEnd>
                <div className={styles.txt14_B01} style={{borderRadius:'8px', background:'#F8F9FC', padding:'6px 10px'}} dangerouslySetInnerHTML={{ __html: timeView }}></div>
            </FlexRowEnd>
        </FlexRowSpace>
    )
}