import React, {useEffect, useRef, useState} from "react";
import {useInView} from "react-intersection-observer";
import styles from "../common/css/zigle.module.scss";
import PageTitle, {
    FC,
    FlexBottom,
    FlexColumn,
    FlexColumSpace,
    FlexRowAround, FlexRowCenter, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FR, PageContent, PageRootContainer
} from "../components/common/container/PageContainer";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import styled from "styled-components";
import LinkPopup from "../components/myLink/LinkPopup";
import CoinList from "../components/common/CoinList";
import AssetService from "../services/asset.service";
import {Toast} from "../components/common/popup/ToastAlert";
import TokenWrite from "../components/tokenlist/TokenWrite";
import CoinWrite from "../components/tokenlist/CoinWrite";
import {useTranslation} from "react-i18next";

export default function MyCoinSetting() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const chainId = useSelector((state: any) => state.walletReducer.chainId);
    const mainNetDetail = useSelector((state: any) => state.walletReducer.mainNetDetail);
    const [limitSize] = useState("10");

    const [pageMode, setPageMode] = useState('list');

    const [linkInfo, setLinkInfo] =  useState({
        mainnetLinkName:'',
        pageType:'myCoin'
    });
    const handleOnClick = (selectCoin: any) =>{
        navigate('/coin', {
            state: {
                item:selectCoin
            }})
    }
    useEffect(() => {
        const fetchMainnet = async () => {
            let data = await mainNetDetail;
            setLinkInfo({
                ...linkInfo,
                mainnetLinkName:data.linkName
            })
        };
        if (mainNetDetail) {
            fetchMainnet();
        }
    }, [mainNetDetail]);

    return (
        <PageRootContainer>
            {
                (pageMode === 'list') && (
                    <>
                        <PageTitle
                            textTitle={t('내 자산')}
                            onClickLeft={() => {
                                navigate(-1);
                            }}
                            rightSrc={require('../assets/imges/plus_icon.png')}
                            onClickRight={() => {
                                setPageMode('write');
                            }}
                        />
                        <PageContent>
                            <CoinList itemInfo={linkInfo} handleOnClick={handleOnClick} setPageMode={setPageMode}/>
                        </PageContent>
                    </>
                )
            }
            {
                (pageMode === 'write') && (
                    <>
                        <PageTitle
                            textTitle={t('토큰 추가 요청')}
                            onClickLeft={()=>setPageMode('list')}
                        />
                        <PageContent>
                            <CoinWrite setPageMode={setPageMode}  />
                        </PageContent>
                    </>
                )
            }
        </PageRootContainer>
    )
}