import styles from "../common/css/zigle.module.scss";
import { isMobile, isAndroid, isIOS, CustomView } from 'react-device-detect';
import PageTitle, {
    FC,
    FlexBottom,
    FlexColumn, FlexColumnCenter,
    FlexColumSpace,
    FlexRowAround, FlexRowCenter, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FR, PageContent, PageRootContainer
} from "../components/common/container/PageContainer";
import React from "react";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Toast, ToastError} from "../components/common/popup/ToastAlert";
import {useTranslation} from "react-i18next";

export default function MobilePwa(){
    const { t } = useTranslation();
    const handleInstallClick = () => {
        if(window.promptEvent){
            //@ts-ignore
            window.promptEvent.prompt();
            //@ts-ignore
            window.promptEvent.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === "acceptd") {
                    console.log("User accepted the A2HS prompt");
                } else {
                    console.log("User dismissed the A2HS prompt");
                }
            });
        }else{
            ToastError(t("이미 설치 하신 상태입니다."))
        }
    };
    return (
        <React.Fragment>
            <PageRootContainer>
                <PageContent>
                    <FlexColumnCenter gap={12} style={{paddingTop:'120px'}}>
                        <div className={styles.pwa_round_bg}>{t("⚡ 지글을 쓰려면 필수에요!")}</div>
                        <FlexColumnCenter gap={8}>
                            <img src={require('../assets/imges/pwa/zigle_logo.png')} width={72}/>
                            <img src={require('../assets/imges/pwa/zigle_title.png')} width={67}/>
                            <FlexColumnCenter className={styles.txt13_B} style={{paddingTop:'20px'}} gap={4}>
                                {
                                    isIOS ? (<div style={{padding:'10px 16px', color:'#3A71FF', borderRadius:'8px', backgroundColor:'#ebf2ff'}} >{t("크롬, 사파리에서 ‘홈화면 추가’ 해주세요")}</div>) : (<div style={{padding:'10px 16px', color:'#3A71FF', borderRadius:'8px', backgroundColor:'#ebf2ff'}} onClick={handleInstallClick}>{t("홈 바로가기 추가")}</div>)
                                }

                                <div className={styles.txt12_B05}>
                                    {window.location.protocol + "//" + window.location.host}
                                    <CopyToClipboard
                                        text={window.location.protocol + "//" + window.location.host}
                                        onCopy={() => Toast(t("주소를 복사 했습니다."))}
                                    >
                                        <img src={require("../assets/icons/copy.png")} width={20} alt={"copy"} />
                                    </CopyToClipboard>
                                </div>
                                <FlexColumnCenter gap={8} className={styles.txt12_B05} style={{paddingTop:'99px'}}>
                                    <FlexRowStart style={{backgroundColor:'#F8F9FC', padding:'8px', borderRadius:'8px'}} gap={10}><img src={require('../assets/imges/pwa/icon_chrome.png')} width={32}/>{t("지글 접속 - 우측 상단 공유 아이콘 - 앱 설치 또는 홈 추가")}</FlexRowStart>
                                    <FlexRowStart style={{backgroundColor:'#F8F9FC', padding:'8px', borderRadius:'8px'}} gap={10}><img src={require('../assets/imges/pwa/icon_safari.png')} width={32}/>{t("지글 접속 - 하단 중앙 공유 아이콘 - 홈 화면에 추가")}</FlexRowStart>
                                </FlexColumnCenter>
                            </FlexColumnCenter>
                        </FlexColumnCenter>
                    </FlexColumnCenter>
                </PageContent>
            </PageRootContainer>
        </React.Fragment>
    )
}