import React, {useEffect, useRef, useState} from "react";

import styles from "../common/css/zigle.module.scss";
import PageTitle, {
    FlexColumn,
    FlexRowAround,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import {useNavigate, useParams} from "react-router-dom";
import CommonService from "../services/common.service";
import TransferFriend2 from "../components/transferCoin/TransferFriend2";
import PopupService from "../services/popup.service";
import TransferEnd from "../components/transferCoin/TransferEnd";
import {IMainnetInfo} from "../common/dto/MainnetInfo";
import {useDispatch, useSelector} from "react-redux";
import {CONNECT} from "../components/reducer/walletReducer";
import {useAccount} from "wagmi";
import TransferMessage from "../components/transferCoin/TransferMessage";
import {useTranslation} from "react-i18next";


interface ITransferInfo {
    pageTitle:string;
    linkType:string;
    linkName:string;
    linkMngId:string;
    coinId:string;
    coinTypeCode: string,
    coinContAddr: string,
    coinDecimalVal: string,
    coinSymbolName:string;
    coinSymbolImgFileId:string;
    mainnetId:string;
    mainnetLinkName:string;
    sendUserId:string;
    sendUserName:string;
    sendWalletAddress:string;
    countryNum:string,
    phoneNum:string,
    zigleFee:number;
    amount:number;
    safeOn:boolean;
    confirmTxt:string;
    txId:string;
    message:string;
}
export default function LinkTrans() {
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const dispatch = useDispatch();
    const { address, isConnected } = useAccount();

    const [depth, setDepth] = useState(0);
    const [mainnetInfo, setMainnetInfo] = useState<IMainnetInfo>();
    const [transferInfo, setTransferInfo] = useState<ITransferInfo>({
        pageTitle:'',
        linkType:'',
        linkName:t('송금 방식'),
        linkMngId:'',
        coinId: '',
        coinTypeCode: '',
        coinContAddr: '',
        coinDecimalVal: '',
        coinSymbolName: t('송금 코인'),
        coinSymbolImgFileId:require('../assets/icons/question2.png'),
        mainnetId : '',
        mainnetLinkName: t('메인넷'),
        sendUserId : '',
        sendUserName : '',
        sendWalletAddress:'',
        countryNum:'',
        phoneNum:'',
        zigleFee:0,
        amount : 0,
        safeOn: false,
        confirmTxt: '',
        txId : '',
        message : ''
    })

    const txIdResult = (result:any,
                        resultMessage:any,
                        send_token_amount:any,
                        account:any,
                        to_address:any,
                        mainnetId:any,
                        coinId:any,
                        linkMngId:any)=>{
        if(result=="success")
        {
            CommonService.transactionInsert(resultMessage, send_token_amount, account, to_address, mainnetId, coinId, linkMngId, transferInfo.sendUserId, transferInfo.message).then(
                (res) => {
                    setTransferInfo(
                        {...transferInfo,
                            txId: resultMessage
                        }
                    );
                    setDepth(10);
                });
        } else {
            PopupService.newPopup(t("확인 필요")
                , t("메타마스크에서 트랜잭션을 승인해 주세요.")
                , ""
                , {
                    btnTxt: t("확인 했어요"),
                    onClick: () => {
                    }
                }
                , {}
            );
        }
    }

    useEffect(()=>{
        if(params.linkCode)
        {
            CommonService.getSnsLinkList(params.linkCode).then(
                (res) => {
                    setTransferInfo({
                        ...transferInfo,
                        pageTitle:res.data.donameVo.doname+'의 '+res.data.linkWalletVo.linkName+t('링크 송금'),
                        linkType:res.data.linkWalletVo.linksTypeCode,
                        coinId: res.data.linkWalletVo.tnCoinInfo.coinId,
                        linkMngId: res.data.linkWalletVo.linkMngId,
                        coinTypeCode: res.data.linkWalletVo.tnCoinInfo.typeCode,
                        coinContAddr: res.data.linkWalletVo.tnCoinInfo.contAddr,
                        coinDecimalVal: res.data.linkWalletVo.tnCoinInfo.decimalVal,
                        coinSymbolName: res.data.linkWalletVo.tnCoinInfo.symbolName,
                        coinSymbolImgFileId:res.data.linkWalletVo.tnCoinInfo.symbolImgFileId,
                        mainnetId : res.data.linkWalletVo.tnMainnetInfo.mainnetId,
                        mainnetLinkName: res.data.linkWalletVo.tnMainnetInfo.linkName,
                        sendUserId : res.data.donameVo.userId,
                        sendUserName : res.data.donameVo.doname,
                        sendWalletAddress:res.data.linkWalletVo.walletAddr,
                        zigleFee:0,
                        amount : res.data.linkWalletVo.amount
                    });
                    setMainnetInfo(res.data.linkWalletVo.tnMainnetInfo);

                }
            )
        }
    }, [params.linkCode])

    useEffect(()=>{
        if(!address){
            dispatch({
                type: CONNECT,
            });
        }
    },[address]);
    return (
        <PageRootContainer>
            <PageTitle
                textTitle ={transferInfo.pageTitle}
                onClickLeft={()=>{
                    navigate(-1);
                }}
            />
            <PageContent>
                { depth ===0 && <TransferFriend2  setDepth={setDepth} mainnetInfo={mainnetInfo} transferInfo={transferInfo} setTransferInfo={setTransferInfo} txIdResult={txIdResult}/>}
                {(depth === 9) && (
                    <TransferMessage mainnetInfo={mainnetInfo} transferInfo={transferInfo}
                                     setTransferInfo={setTransferInfo}
                                     txIdResult={txIdResult}/>)}
                { depth ===10 && <TransferEnd transferInfo={transferInfo} setTransferInfo={setTransferInfo}  setDepth={setDepth}/>}
            </PageContent>
        </PageRootContainer>
    )
}