import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import PageTitle, {
    FlexColumEnd,
    FlexColumn, FlexColumnCenter, FlexRowCenter, FlexRowEnd,
    FlexRowSpace, FlexRowStart,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import React, {useEffect, useState} from "react";
import styles from "../common/css/zigle.module.scss";
import LinkStep1 from "../components/creatlink/LinkStep1";
import AirdropFiStep0 from "../components/airdrop/AirdropFiStep0";
import AirdropFiStep1 from "../components/airdrop/AirdropFiStep1";
import AirdropFiStep2 from "../components/airdrop/AirdropFiStep2";
import CoinList from "../components/common/CoinList";
import CoinSelect from "../components/common/CoinSelect";
import CoinPopup from "../components/tokenlist/CoinPopup";
import CommonService from "../services/common.service";
import {IMainnetInfo} from "../common/dto/MainnetInfo";
import MetaMaskService from "../services/metamask.service";
import AirdropFiStep4 from "../components/airdrop/AirdropFiStep4";
import {ICoinInfo} from "../common/dto/CoinInfoDto";
import PopupService from "../services/popup.service";
import AirdropFiEnd from "../components/airdrop/AirdropFiEnd";
import AirdropService from "../services/airdrop.service";
import moment from "moment";
import {useAccount} from "wagmi";
import {useWalletEthersPorvider} from "../common/js/ethersProviderHook";
import CoinWrite from "../components/tokenlist/CoinWrite";
import {useTranslation} from "react-i18next";
interface IAirdropFi {
    airdrop_title :string;

}
export interface IAirdropSet {
    pageTitle:string;
    airdropType:string;
    airdropSubType:string;
    airdropName:string;
    mainnetId:string;
    mainnetLinkName:string;
    walletAddr:string;
    receiveAddr:string;
    txId:string;
    receiveAmount:number;
    ziglePoint:number;
    airdropCnt:number;
    failCnt:number,
    airdropUrl:string;
    amount:number;
    feeAverage:number;
    displayYn:string;
    airdropOpenYn:string;
    foundationName:string;
    homepageUrl:string;
    informationContents:string;
    airdropStartDay:string;
    airdropStartTime:string;
    airDropList:[{
        usrId:string,
        delYn:string,
        checkYn:string
    }];
    tmpAirDropList:string[];
    coinInfo:ICoinInfo;
    mainnetInfo:IMainnetInfo;
}
export default function AirdropFi() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [home, setHome] = useState(true);
    const [depth, setDepth] = useState(0);
    const [pageTitle, setPageTitle] = useState('');
    const [showCoinPopup, setShowCoinPopup] = useState(false);
    const zigleWalletAddress = process.env.REACT_APP_WALLET_ADDRESS;
    const [mainnetList, setMainnetList] = useState<any[]>([]);
    const [mainnetItem, setMainnetItem] = useState<IMainnetInfo>();
    const mainNetDetail = useSelector((state: any) => state.walletReducer.mainNetDetail);

    const [pageMode, setPageMode] = useState('list');


    const provider = useWalletEthersPorvider();

    const { address, isConnected } = useAccount();

    const [airdropInfo, setAirdropInfo] = useState<IAirdropSet>({
        pageTitle:'',
        airdropType:'airdropFi',
        airdropSubType:'',
        airdropName:'',
        mainnetId : '',
        mainnetLinkName: '',
        walletAddr :'',
        receiveAddr : zigleWalletAddress ? zigleWalletAddress : '',
        txId : '',
        receiveAmount : 0,
        ziglePoint:0,
        airdropCnt:0,
        failCnt:0,
        airdropUrl:'',
        amount:0,
        feeAverage:0,
        displayYn:'Y',
        airdropOpenYn:'N',
        foundationName:'',
        homepageUrl:'',
        informationContents:'',
        airdropStartDay:'',
        airdropStartTime:'',
        airDropList:[{
            usrId:'',
            delYn:'',
            checkYn:''
        }],
        tmpAirDropList:[],
        coinInfo: {
            btopFlag:false,
            coinId: '',
            coinName: '',
            contAddr: '',
            displayName:'',
            decimalVal: '',
            description: '',
            linkName: '',
            mainnetId: '',
            multiCode: '',
            priority: '',
            statusCode: '',
            symbolImgFileId: '',
            symbolName: '',
            typeCode: '',
            useYn: '',
            coingekoId: '',
            exchangeRate: '',
        },
        mainnetInfo:{
            btopFlag:false,
            chainId: '',
            coinDtoList: '',
            decimalVal: '',
            description: '',
            displayName:'',
            explorerUrl: '',
            linkName: '',
            mainnetId: '',
            mainnetName: '',
            phoneUse: '',
            priority: 0,
            rpcUrl: '',
            statusCode: '',
            symbolImgFileId: '',
            symbolName: '',
            zigleFee: 0,
        }
    });

    const handleOnClick = (selectItem: ICoinInfo) =>{
        
        setAirdropInfo({
            ...airdropInfo,
            coinInfo : selectItem
        });
        setDepth(4);
    }
    const onClickMainnet = () =>{
        setShowCoinPopup(true);
    }
    const onHandleClickMainnet = (selectItem:any) =>{
        if(selectItem.mainnetId !== airdropInfo.mainnetId)
        {
            MetaMaskService.setSwitchNetwork(provider, parseInt(selectItem.chainId), selectItem.mainnetName, selectItem.symbolName, selectItem.decimalVal, selectItem.rpcUrl, selectItem.explorerUrl, '').then(
                (response) => {
                    if (!response.status) {
                    }
                }
            );
        }
        setShowCoinPopup(false);
    }

    const txIdResult = (result:any,
                        resultMessage:any,
                        send_token_amount:any,
                        account:any,
                        to_address:any,
                        mainnetId:any,
                        coinId:any,
                        linkMngId:any)=> {
        if (result == "success") {
            const params = new FormData();
            params.append("airdropType", airdropInfo.airdropType);
            params.append("airdropName", airdropInfo.airdropName);
            params.append("mainnetId", airdropInfo.mainnetId);
            params.append("coinId", airdropInfo.coinInfo.coinId);
            params.append("walletAddr", airdropInfo.walletAddr);
            params.append("receiveAddr", airdropInfo.receiveAddr);
            params.append("txId", resultMessage);
            params.append("receiveAmount", send_token_amount);
            params.append("ziglePoint", airdropInfo.ziglePoint.toString());
            params.append("airdropCnt", airdropInfo.airdropCnt.toString());
            params.append("amount", airdropInfo.amount.toString());
            params.append("feeAverage", airdropInfo.feeAverage.toString());
            params.append("displayYn", airdropInfo.displayYn);
            params.append("airdropOpenYn", airdropInfo.airdropOpenYn);
            params.append("homepageUrl", airdropInfo.homepageUrl);
            params.append("informationContents", airdropInfo.informationContents);
            params.append("foundationName", airdropInfo.foundationName);



            if(airdropInfo.airdropStartDay)
            {
                params.append("airdropStartDay",  moment(airdropInfo.airdropStartDay+ " " +airdropInfo.airdropStartTime+":00").format('YYYY-MM-DD HH:mm:ss'));

            }


            AirdropService.createAirdrop(params).then(
                (res)=>{
                    if(res.resultCode === "SUCCESS"){
                        setAirdropInfo({
                            ...airdropInfo,
                            airdropUrl: res.data.airdropUrl
                        });
                        setDepth(5);
                    }
                }
            )
        } else {
            PopupService.newPopup(t("확인 필요")
                , t("메타마스크에서 트랜잭션을 승인해 주세요.")
                , ""
                , {
                    btnTxt: t("확인 했어요"),
                    onClick: () => {
                    }
                }
                , {}
            );
        }
    }
    useEffect(() => {
        async function fetchMainnetDetail() {
            const response = await CommonService.mainnetList('', 1, 100, '');
            setMainnetList(response.data);
            let setMainNetDetail = await mainNetDetail;
            setMainnetItem(setMainNetDetail);

            setAirdropInfo({
                ...airdropInfo,
                walletAddr: address ? address.toString() : '',
                mainnetId: setMainNetDetail.mainnetId,
                mainnetLinkName: setMainNetDetail.linkName,
                mainnetInfo : {
                    ...airdropInfo.mainnetInfo,
                    chainId: setMainNetDetail.chainId,
                    decimalVal: setMainNetDetail.decimalVal,
                    displayName:setMainNetDetail.displayName,
                    explorerUrl: setMainNetDetail.explorerUrl,
                    linkName: setMainNetDetail.linkName,
                    mainnetId: setMainNetDetail.mainnetId,
                    mainnetName: setMainNetDetail.mainnetName,
                    rpcUrl: setMainNetDetail.rpcUrl,
                    symbolImgFileId: setMainNetDetail.symbolImgFileId,
                    symbolName: setMainNetDetail.symbolName,
                    zigleFee: setMainNetDetail.zigleFee,
                },
                ziglePoint: setMainNetDetail.airdropFee
            });
        }
        fetchMainnetDetail();
    },[mainNetDetail]);

    useEffect(()=>{
        console.log("airdropInfo",airdropInfo)
    }, [airdropInfo])
    return (
        <PageRootContainer>
            <CoinPopup setShow={setShowCoinPopup} show={showCoinPopup} mainnetList={mainnetList} onHandleClickMainnet={onHandleClickMainnet} />
            {
                (pageMode === 'list') ? (
                    <PageTitle home={home}
                               textTitle={airdropInfo.pageTitle}
                               mainnetName={(depth === 3) ? airdropInfo.mainnetLinkName : ''}
                               onClickMainnet={onClickMainnet}
                               rightSrc={(depth === 3) ? require('../assets/imges/plus_icon.png') : ''}
                               onClickRight={() => {
                                   if (depth === 3) {
                                       setPageMode('write');
                                   }
                               }}
                               onClickLeft={() => {
                                   if (depth == 0) {
                                       navigate(-1);
                                   } else {
                                       if (depth === 4) {
                                           setAirdropInfo({
                                               ...airdropInfo,
                                               pageTitle: '',
                                               airdropType: 'airdropFi',
                                               airdropName: '',
                                               mainnetId: '',
                                               mainnetLinkName: '',
                                               walletAddr: '',
                                               receiveAddr: zigleWalletAddress ? zigleWalletAddress : '',
                                               txId: '',
                                               receiveAmount: 0,
                                               ziglePoint: 0,
                                               airdropCnt: 0,
                                               failCnt: 0,
                                               amount: 0,
                                               feeAverage: 0,
                                               displayYn: 'N',
                                               airdropOpenYn: 'N',
                                               foundationName:'',
                                               homepageUrl:'',
                                               informationContents:'',
                                               airdropStartDay: '',
                                               airdropStartTime: '',
                                               airDropList: [{
                                                   usrId: '',
                                                   delYn: '',
                                                   checkYn: ''
                                               }],
                                               coinInfo: {
                                                   btopFlag: false,
                                                   coinId: '',
                                                   coinName: '',
                                                   contAddr: '',
                                                   displayName: '',
                                                   decimalVal: '',
                                                   description: '',
                                                   linkName: '',
                                                   mainnetId: '',
                                                   multiCode: '',
                                                   priority: '',
                                                   statusCode: '',
                                                   symbolImgFileId: '',
                                                   symbolName: '',
                                                   typeCode: '',
                                                   useYn: '',
                                                   coingekoId: '',
                                                   exchangeRate: '',
                                               }
                                           });
                                           setDepth(0);
                                       } else {
                                           setDepth(depth - 1);
                                       }
                                   }
                               }}
                    />
                ) : (
                    <PageTitle
                        textTitle={t('토큰 추가 요청')}
                        onClickLeft={()=>setPageMode('list')}
                    />
                )
            }
            <PageContent  style={{marginBottom:'0px'}}>
                {
                    (pageMode === 'list') ? (
                        <>
                            {depth === 0 && <AirdropFiStep0 setDepth={setDepth} airdropInfo={airdropInfo}
                                                            setAirdropInfo={setAirdropInfo}/>}
                            {depth === 1 && <AirdropFiStep1 setDepth={setDepth} airdropInfo={airdropInfo}
                                                            setAirdropInfo={setAirdropInfo}/>}
                            {depth === 2 && <AirdropFiStep2 setDepth={setDepth} airdropInfo={airdropInfo}
                                                            setAirdropInfo={setAirdropInfo}/>}
                            {depth === 3 && <CoinSelect itemInfo={airdropInfo} handleOnClick={handleOnClick} setPageMode={setPageMode}/>}
                            {depth === 4 && <AirdropFiStep4 setDepth={setDepth} airdropInfo={airdropInfo}
                                                            setAirdropInfo={setAirdropInfo} txIdResult={txIdResult}/>}
                            {depth === 5 && <AirdropFiEnd airdropInfo={airdropInfo}/>}
                        </>
                    ) : (
                        <CoinWrite setPageMode={setPageMode}  />
                    )
                }
            </PageContent>
        </PageRootContainer>

    )
}