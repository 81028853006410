import {
    FlexBottom,
    FlexBottomStart, FlexColumn,
    FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart
} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import BottomButton from "../common/button/BottomButton";
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import TextareaAutosize from 'react-textarea-autosize';
import AirdropFriend from "./AirdropFriend";
import {IAirdropDeStep} from "./AirdropDeStep1";
import {Toast} from "../common/popup/ToastAlert";
import CopyToClipboard from "react-copy-to-clipboard";
import EmptyContent from "../common/EmptyContent";
import CommonService from "../../services/common.service";
import AirdropUserItem from "./AirdropUserItem";
import {useSelector} from "react-redux";
import {ethers} from "ethers";
import {supportsPassiveEvents} from "react-select/dist/declarations/src/utils";
import AirdropPopup from "./AirdropPopup";
import MetaMaskService from "../../services/metamask.service";
import {useTranslation, Trans} from "react-i18next";

export default function AirdropDeStep3({airdropInfo, setAirdropInfo, setDepth}: IAirdropDeStep){
    const { t } = useTranslation();
    const [btnActive, setBtnActive] = useState(false);
    const [airdropList, setAirdropList] = useState([]);
    const doname: string = useSelector((state: any) => state.userReduce.doname);

    const [show, setShow] = useState(false);
    const inputRef = useRef<HTMLFormElement>(null);
    const failCheck = useRef(0);
    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
    } = useForm();

    const onClickHandle = (type:string, tmpId:string, changeId:string, changeDel:string, checkYn:string)=>{
        let tmpArray = [...airdropInfo.airDropList];
        let tmpArray2 = [...airdropInfo.tmpAirDropList];
        let tmpCnt = airdropInfo.airdropCnt;

        if((type === "ynChange") || (type === "idChange")){
            const findIndex = airdropInfo.airDropList.findIndex(element=> element.usrId === tmpId);
            const findIndex2 = airdropInfo.tmpAirDropList.findIndex(element=> element === tmpId);

            if(findIndex != -1) {
                if(type === "ynChange") {

                    tmpArray[findIndex] = {...tmpArray[findIndex], delYn: changeDel};
                    (changeDel === "N") ? tmpCnt-- : tmpCnt++;
                    setAirdropInfo({
                        ...airdropInfo,
                        airdropCnt:tmpCnt,
                        airDropList:tmpArray,
                    });
                }else{
                    tmpArray[findIndex] = {...tmpArray[findIndex], delYn: changeId, checkYn};
                    tmpArray2[findIndex2] = changeId;
                    setAirdropInfo({
                        ...airdropInfo,
                        airdropCnt:tmpCnt,
                        airDropList:tmpArray,
                        tmpAirDropList:tmpArray2
                    });
                }
            }
        }

    }
    const onSubmit = (data: any) => {
        setDepth(3);
    }
    const checkArray = async ()=>{
        failCheck.current = 0;
        let tmpArray = airdropInfo.tmpAirDropList.filter((v, i) => airdropInfo.tmpAirDropList.indexOf(v) === i);
        const resultList = await Promise.all(tmpArray.map(async(item:string, index) => {

            if((!item) || (item.replace(/\n/g, "").replace(/^\s+|\s+$/g, "") === doname)){
                return null;
            } else{
                const checkResult = await MetaMaskService.transUserCheck(item.replace(/\n/g, "").replace(/^\s+|\s+$/g, ""), airdropInfo.airdropSubType);
                if(checkResult === "N") {
                    failCheck.current++;
                }
                return {
                    usrId : item.replace(/\n/g, "").replace(/^\s+|\s+$/g, ""),
                    delYn: "Y",
                    checkYn :checkResult
                };
            }
        }));
        setAirdropInfo({
            ...airdropInfo,
            failCnt:failCheck.current,
            airdropCnt:resultList.filter((value) => value != null).length,
            airDropList:resultList.filter((value) => value != null)
        });
    }
    useEffect(()=>{
        checkArray();
    },[airdropInfo.tmpAirDropList]);
    useEffect(()=>{
        if(airdropInfo.failCnt > 0){
            setBtnActive(false);
        }else{
            if(airdropInfo.airdropCnt > 0)
            {
                setBtnActive(true);
            }else{
                setBtnActive(false);
            }

        }
    },[airdropInfo])
    return (
        <>
            <AirdropPopup show={show} setShow={setShow} airdropInfo={airdropInfo} setAirdropInfo={setAirdropInfo} />
            <FlexColumn gap={20} style={{marginBottom:'200px' }}>
                <FlexColumn gap={4}>
                    <FlexRowSpace>
                        <FlexRowStart className={styles.txt20_500_B01}>
                            <FlexColumn>
                                <div>
                                    <Trans  className={styles.airdropDeStep3} t={t} i18nKey={"<span style={{fontWeight:'700'}}>총 {0}개의 지갑</span>이<br/>확인 되었습니다."} values={{0:airdropInfo.airdropCnt}}/>
                                </div>
                            </FlexColumn>
                        </FlexRowStart>
                        <FlexRowEnd>
                            <span className={styles.copy} style={{backgroundColor:'#ffffff', padding:'10px 16px'}} onClick={()=>{
                                setShow(true);
                            }}>{t('추가')}</span>
                        </FlexRowEnd>
                    </FlexRowSpace>
                    <FlexColumn className={styles.txt13_B05}><Trans t={t} i18nKey={"확인 실패 {0}개"} values={{0:airdropInfo.failCnt}} /></FlexColumn>
                </FlexColumn>
                <FlexColumn gap={12}>

                        {
                            airdropInfo.airDropList.length > 0 ?
                                (
                                    airdropInfo.airDropList.map((item:any, index)=>{

                                        if(item.usrId===""){
                                            return false;
                                        }
                                        return (
                                            <AirdropUserItem key={index} airdropInfo={airdropInfo} setAirdropInfo={setAirdropInfo} itemDetail={item} onClickHandle={onClickHandle} />
                                        )
                                    })
                                )
                                :
                                (
                                    <EmptyContent titleText={t('등록된 사람이 없습니다.')} titleImage={require('../../assets/icons/empty_icon.png')}/>
                                )
                        }
                </FlexColumn>
            </FlexColumn>
            <FlexBottom>
                <BottomButton
                    isActive={btnActive}
                    backgroundColor={"#3a71ff"}
                    textColor={"white"}
                    // border={"solid 1px #dbdee6"}
                    onClick={handleSubmit(onSubmit)}
                    text={t("다음")}
                />
            </FlexBottom>
        </>
    )
}