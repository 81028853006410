import React, {useEffect, useRef, useState} from "react";

import styles from "../common/css/zigle.module.scss";
import PageTitle, {
    FlexBottom,
    FlexColumn,
    FlexRowAround, FlexRowCenter, FlexRowSpace,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import TransferFriend2 from "../components/transferCoin/TransferFriend2";
import TransferEnd from "../components/transferCoin/TransferEnd";
import {useNavigate, useParams} from "react-router-dom";
import CommonService from "../services/common.service";
import PhoneService from "../services/phone.service";
import CopyToClipboard from "react-copy-to-clipboard";
import {Toast} from "../components/common/popup/ToastAlert";
import DefaultInput from "../components/common/input/DefaultInput";
import {useForm} from "react-hook-form";
import BottomButton from "../components/common/button/BottomButton";
import {RST_MAP} from "../components/profile/SndHis";
import {useSelector} from "react-redux";
import PopupService from "../services/popup.service";
import {ellipseAddress} from "../common/js/utilities";
import {useAccount} from "wagmi";
import {Trans, useTranslation} from "react-i18next";

interface ITransferInfo {
    pageTitle:string;
    histHpId:string;
    rstCode:string;
    coinId:string;
    coinSymbolName:string;
    coinSymbolImgFileId:string;
    mainnetId:string;
    mainnetLinkName:string;
    sendUserId:string;
    sendUserName:string;
    sendWalletAddress:string;
    receiveAddr:string
    countryNum:string,
    phoneNum:string,
    zigleFee:number;
    amount:number;
    safeOn:boolean;
    confirmTxt:string;
    txId:string;
}

export default function PhoneTrans() {
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [service, setService] = useState<boolean>(false);
    const [btnActive, setBtnActive] = useState(false);

    const { address, isConnected } = useAccount();
    const inputRef = useRef<HTMLFormElement>(null);

    const {
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit,
    } = useForm();

    const [transferInfo, setTransferInfo] = useState<ITransferInfo>({
        pageTitle:'',
        histHpId:'',
        rstCode:'',
        coinId: '',
        coinSymbolName: '',
        coinSymbolImgFileId:require('../assets/icons/question2.png'),
        mainnetId : '',
        mainnetLinkName: t('메인넷'),
        sendUserId : '',
        sendUserName : '',
        sendWalletAddress:'',
        receiveAddr:'',
        countryNum:'',
        phoneNum:'',
        zigleFee:0,
        amount : 0,
        safeOn: false,
        confirmTxt: '',
        txId : ''
    });
    const onSubmit = (data: any) => {
        CommonService.updateConfirmSms(transferInfo.histHpId, data.rcvAddress, data.confirmTxt, "2").then(
            (res) => {
                if(!res?.status)
                {
                    if(res?.msg)
                    {
                        PopupService.newPopup(""
                            ,res.msg
                            , ""
                            ,{btnTxt:t("확인 했어요"),
                                onClick: () => {

                                }}
                            , {}
                        );
                    }
                }else{
                    let tmpCode = transferInfo.rstCode;
                    if(transferInfo.safeOn)
                    {
                        tmpCode = "DI";
                    }else{
                        tmpCode = "DW";
                    }
                    setTransferInfo({
                        ...transferInfo,
                        rstCode:tmpCode
                    })
                    //document.location.reload();
                }
            }
        );

    }
    useEffect(()=>{
        if(params.linkCode)
        {
            CommonService.phoneView(params.linkCode).then(
                (res) => {
                    setTransferInfo({
                        ...transferInfo,
                        pageTitle:'',
                        histHpId:res.data.histHpId,
                        rstCode:res.data.rstCode,
                        coinId: res.data.coinId,
                        coinSymbolName: res.data.symbolName,
                        coinSymbolImgFileId:res.data.symbolImgFileId,
                        mainnetId : res.data.mainnetId,
                        mainnetLinkName: res.data.mainnetName,
                        sendUserId : res.data.userId,
                        sendUserName : res.data.doname,
                        sendWalletAddress:res.data.fromAddr,
                        receiveAddr:res.data.receiveAddr,
                        countryNum:res.data.hpCountry,
                        phoneNum:res.data.hpNum,
                        zigleFee:res.data.ziglePoint,
                        amount : res.data.amount,
                        safeOn: res.data.useConfirm === 'Y' ? true : false,
                        confirmTxt: '',
                        txId : res.data.resTxId
                    });
                    if(res.data.rstCode !='SM')
                    {
                        setService(true)
                    }
                }
            )

        }
    }, [params.linkCode]);
    useEffect(()=>{
        if(isDirty === true && isValid === true)
        {
            setBtnActive(true);
        }
    },[isDirty, isValid]);
    useEffect(()=>{
        inputRef.current?.rcvAddress.focus();
    },[inputRef.current])

    return (
        <PageRootContainer>
            <PageTitle rightSrc={require('../assets/icons/close_28.png')} onClickRight={()=>{ navigate("/"); }}/>
            {
                transferInfo.rstCode === "SM" ?
                    (
                        <PageContent>
                            <FlexColumn gap={28}>
                                <FlexColumn gap={12}>
                                    <FlexRowCenter><img src={require('../assets/icons/sendTransfer.png')} width={64} /></FlexRowCenter>
                                    <FlexColumn>
                                        <FlexRowCenter className={styles.txt20_500_B01}>{transferInfo.sendUserName}님이</FlexRowCenter>
                                        <FlexRowCenter className={styles.txt20_B01}>{t('코인을 보냈어요!')}</FlexRowCenter>
                                    </FlexColumn>
                                </FlexColumn>
                                <FlexColumn gap={1}>
                                    <form
                                        ref={inputRef}
                                        onSubmit={handleSubmit(onSubmit)}
                                        style={{ width: "100%" }}
                                    >
                                        <DefaultInput
                                            lable={t("입금주소")}
                                            error={errors.rcvAddress}
                                            inputElement={
                                                <input
                                                    type={"text"}
                                                    className={styles.inputText_44}
                                                    disabled={service}
                                                    defaultValue={address}
                                                    placeholder={t("입금주소를 입력해주세요.")}
                                                    {...register("rcvAddress", {
                                                        required: t("입금주소를 입력해주세요.")
                                                    })}
                                                />
                                            }
                                        />
                                        <DefaultInput
                                            lable={t("인증번호")}
                                            error={errors.confirmTxt}
                                            inputElement={
                                                <input
                                                    type={"number"}
                                                    className={styles.inputText_44}
                                                    disabled={service}
                                                    placeholder={t("인증번호를 입력해주세요.")}
                                                    {...register("confirmTxt", {
                                                        required: t("인증번호를 입력해주세요.")
                                                    })}
                                                />
                                            }
                                        />
                                        {
                                            service === true && (
                                                <div className={styles.txt13_red} style={{ marginTop: "4px", marginBottom: "4px" }}>
                                                    <Trans t={t} i18nKey={'{0} 중에 있습니다 잠시 후 다시 확인하여 주세요.'} values={{0:RST_MAP[transferInfo.rstCode]}} />
                                                </div>
                                            )
                                        }
                                    </form>
                                </FlexColumn>
                                <FlexColumn style={{backgroundColor:'#f2f4f6', height:'16px'}}></FlexColumn>
                                <FlexRowSpace>
                                    <div className={styles.txt18_500}>{t('받은 수량')}</div>
                                    <div className={styles.txt18_500_Point_B}>{transferInfo.amount} {transferInfo.coinSymbolName}</div>
                                </FlexRowSpace>
                                <FlexColumn className={styles.txt13_B05} style={{padding:'20px', backgroundColor:'#f9fafb'}} gap={5}>
                                    <div>{t('문자로 발송 받은 지글 인증 번호를 입력해주세요.')}</div>
                                    <div>보낸 사람이 취소하기 전까지 언제든 받을 수 있습니다.</div>
                                    <div className={styles.txt13_600_B05}>{t('인증 번호 및 링크를 다른 사람에게 알려주지 마세요.')}</div>
                                    <div style={{color:'#ed6957', marginTop:'10px'}}>
                                        <Trans t={t} i18nKey={'타 거래소 입금 시 트래블룰이 시행됨에 따라 <br />2022. 03. 25일부터 트래블룰을 준수하지 않거나 출처가 <br />불분명한 입금 건은 입금 반영이 불가합니다.'}/>
                                    </div>
                                </FlexColumn>
                            </FlexColumn>
                            <FlexBottom>
                                <BottomButton
                                    isActive={btnActive}
                                    backgroundColor={"#3a71ff"}
                                    textColor={"white"}
                                    // border={"solid 1px #dbdee6"}
                                    onClick={handleSubmit(onSubmit)}
                                    text={t("다음")}
                                />
                            </FlexBottom>
                        </PageContent>
                    )
                    :
                    (
                        transferInfo.rstCode === "DO" ?
                            (
                                <PageContent>
                                    <FlexColumn gap={28}>
                                        <FlexColumn gap={12}>
                                            <FlexRowCenter><img src={require('../assets/icons/send_ok.png')} width={64} /></FlexRowCenter>
                                            <FlexColumn>
                                                <FlexRowCenter className={styles.txt20_500_B01}>{transferInfo.sendUserName}님이</FlexRowCenter>
                                                <FlexRowCenter className={styles.txt20_B01}>{t('보낸 코인을 받았어요!')}</FlexRowCenter>
                                            </FlexColumn>
                                        </FlexColumn>
                                        <FlexColumn className={styles.txt14_B03} gap={16}>
                                            <FlexRowSpace>
                                                <div>{t('받은수량')}</div>
                                                <div>{transferInfo.amount} {transferInfo.coinSymbolName}</div>
                                            </FlexRowSpace>
                                            <FlexRowSpace>
                                                <div>{t('받은주소')}</div>
                                                <div>
                                                    {ellipseAddress(transferInfo.receiveAddr, 10)}
                                                    <CopyToClipboard text={transferInfo.receiveAddr} onCopy={() => Toast(t("주소를 복사 했습니다."))}>
                                                        <img src={require("../assets/icons/copy.png")} height={20} alt={"copy"} />
                                                    </CopyToClipboard>
                                                </div>
                                            </FlexRowSpace>
                                            <FlexRowSpace>
                                                <div>TXID</div>
                                                <div>
                                                    {ellipseAddress(transferInfo.txId, 10)}
                                                    <CopyToClipboard text={transferInfo.txId} onCopy={() => Toast("주소를 복사 했습니다.")}>
                                                        <img src={require("../assets/icons/copy.png")} height={20} alt={"copy"} />
                                                    </CopyToClipboard>
                                                </div>
                                            </FlexRowSpace>
                                        </FlexColumn>
                                        <FlexColumn className={styles.line}></FlexColumn>
                                    </FlexColumn>
                                    <FlexBottom>
                                        <BottomButton
                                            isActive={true}
                                            backgroundColor={"#3a71ff"}
                                            textColor={"white"}
                                            // border={"solid 1px #dbdee6"}
                                            onClick={()=>navigate("/history/rcv")}
                                            text={t("내역보기")}
                                        />
                                    </FlexBottom>
                                </PageContent>
                            )
                            :
                            (
                                <PageContent>
                                    <FlexColumn gap={28}>
                                            <FlexColumn gap={12}>
                                                <FlexRowCenter><img src={require('../assets/icons/sendTransfer.png')} width={64} /></FlexRowCenter>
                                                <FlexRowCenter className={styles.txt20_B01}>
                                                    <Trans t={t} i18nKey={'{0}중 입니다.'} values={{0:RST_MAP[transferInfo.rstCode]}}/>
                                                </FlexRowCenter>
                                            </FlexColumn>
                                            {
                                                transferInfo.rstCode === "DW" ?
                                                    (
                                                        <>
                                                        <FlexRowCenter>
                                                            <div style={{backgroundColor:'#fff9d9', color:'#ffbe00', padding:'10px 20px'}}>
                                                                <img src={require('../assets/icons/yellowBell.png')} width={20}/> {t('승인요청')}
                                                            </div>
                                                        </FlexRowCenter>
                                                        <FlexColumn gap={3} style={{backgroundColor:'#f9fafb', borderRadius:'16px', padding:'20px'}}>
                                                            <div className={styles.txt14_BOLD_B04}>{t('보낸 사람이 최종 승인을 안해주면 어떻게 되나요?')}</div>
                                                            <div className={styles.txt13_B05}>
                                                                <Trans t={t} i18nKey={'보낸 사람이 최종 승인을 완료해야 받은 코인을 사용 할 수 있어요. 보내신 분께 최종 승인 완료를 요청해보세요. 최종 승인은 지글 홈의<span className={styles.txt13_500_B05}>[프로필]</span> 페이지의 <span className={styles.txt13_500_B05}>[송금 내역]</span>에 있습니다.'}/>
                                                            </div>
                                                        </FlexColumn>
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <FlexColumn gap={3} style={{backgroundColor:'#f9fafb', borderRadius:'16px', padding:'20px'}}>
                                                            <div className={styles.txt14_BOLD_B04}>
                                                                <Trans t={t} i18nKey={'현재 {0}중 입니다.'} values={{0:RST_MAP[transferInfo.rstCode]}}/>
                                                            </div>
                                                            <div className={styles.txt13_B05}>
                                                                {t('시간이 소요 되오니 잠시 후 확인 하시기 바랍니다.')}
                                                            </div>
                                                        </FlexColumn>
                                                    )
                                            }
                                    </FlexColumn>
                                    <FlexBottom>
                                        <BottomButton
                                            isActive={true}
                                            backgroundColor={"#3a71ff"}
                                            textColor={"white"}
                                            // border={"solid 1px #dbdee6"}
                                            onClick={()=>navigate("/history/rcv")}
                                            text={t("내역보기")}
                                        />
                                    </FlexBottom>
                                </PageContent>
                            )
                    )
            }

        </PageRootContainer>
    )
}