import ProfileService from "../../services/profile.service";
import { IAction, IUser } from "../../common/dto/CommonDto";

export const LOGIN_CHECK = "loginCheck";
export const LOGOUT = "logout";
export const CRYPTO_ZIGLE = "cryptoZigle";
export const UPDATE_DONAME = "updateDoname";
export const UPDATE_POINT = "updatePoint";
export const UPDATE_IMG = "updateImg";
export const UPDATE_HPNUM = "updateHpnum";
export const UPDATE_INTRODUCE = "updateIntroduce";
const initial: IUser = {
  userId: "",
  email: "",
  sliceEmail: "",
  doname: "",
  ziglePoint: 0,
  cryptoZigle: 0.0,
  profileImageFileId:
    "https://s3.ap-northeast-2.amazonaws.com/bucket.zigle.io/profiles/profile6.png",
  introduce: "",
  hpCountry: "",
  hpNum: "",
  socialCode: "",
  refCode:"",
  upRefCode: "",
  miningAmount: 0.0,
  login: false,
};

const userReduce = (currentState = initial, action: IAction) => {
  switch (action.type) {
    case LOGOUT:

      ProfileService.logout();
      return {
        ...currentState,
        initial,
      };

    case LOGIN_CHECK:
      return {
        ...currentState,
        email: action.payload.email,
        userId: action.payload.userId,
        socialCode: action.payload.socialCode,
        sliceEmail: action.payload.sliceEmail,
        profileImageFileId: action.payload.profileImageFileId,
        ziglePoint: action.payload.ziglePoint,
        cryptoZigle: 0,
        doname: action.payload.doname,
        introduce: action.payload.introduce,
        hpCountry: action.payload.hpCountry,
        hpNum: action.payload.hpNum,
        refCode: action.payload.refCode,
        upRefCode: action.payload.upRefCode,
        miningAmount: action.payload.miningAmount,
        login: true,
      };
    case CRYPTO_ZIGLE:
      return {
        ...currentState,
        cryptoZigle: action.payload.cryptoZigle,
      };
    case UPDATE_DONAME:
      console.info("action.payload.doname", action.payload.doname);
      return {
        ...currentState,
        doname: action.payload.doname,
      };
    case UPDATE_INTRODUCE:
      console.info("action.payload.introduce", action.payload.introduce);
      return {
        ...currentState,
        introduce: action.payload.introduce,
      };
    case UPDATE_POINT:
      return {
        ...currentState,
        ziglePoint: action.payload.ziglePoint,
      };
    case UPDATE_IMG:
      return {
        ...currentState,
        profileImageFileId: action.payload.profileImageFileId,
      };
    case UPDATE_HPNUM:
      return {
        ...currentState,
        hpCountry: action.payload.hpCountry,
        hpNum: action.payload.hpNum,
      };
    default:
      return { ...currentState };
  }
};

export default userReduce;
