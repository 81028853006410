
import PageTitle, {
    FlexBottom,
    FlexBottomStart,
    FlexColumn,
    FlexRowAround, FlexRowCenter, FlexRowSpace, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../common/container/PageContainer";
import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import MetaMaskService from "../../services/metamask.service";
import styles from "../../common/css/zigle.module.scss";
import BottomButton from "../common/button/BottomButton";
import DefaultInput from "../common/input/DefaultInput";
import {useForm} from "react-hook-form";
import bigDecimal from "js-big-decimal";
import CommonService from "../../services/common.service";
import AirdropService from "../../services/airdrop.service";
import PopupService from "../../services/popup.service";
import {decimalFormat, ellipseAddress} from "../../common/js/utilities";
import {useAccount, useBalance} from "wagmi";
import {ToastError} from "../common/popup/ToastAlert";
import {useWalletEthersPorvider} from "../../common/js/ethersProviderHook";
import {useTranslation} from "react-i18next";


interface IAirdropFeeIn {
    popupIn:boolean;
    setPopupIn:any;
    popupItem:any;
    reLoad:boolean;
    setReLoad:any;
}
export default function AirdropFeeIn(props:IAirdropFeeIn) {
    const { t } = useTranslation();
    const [home, setHome] = useState(true);
    const chainId = useSelector((state:any) => state.walletReducer.chainId);

    const [amount, setAmount] = useState(0);
    const [btnActive, setBtnActive] = useState(false);
    const [coinBalance , setCoinBalance] = useState('0');

    const { address, isConnected } = useAccount();
    const provider = useWalletEthersPorvider();


    const hookCoinBalance = useBalance({
        address: address,
    });


    const zigleWalletAddress = process.env.REACT_APP_WALLET_ADDRESS;
    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
    } = useForm();
    const formData = useRef({

    });


    const txIdResult = (result:any,
                        resultMessage:any,
                        send_token_amount:any,
                        account:any,
                        to_address:any,
                        mainnetId:any,
                        coinId:any,
                        linkMngId:any)=> {
        if (result == "success") {
            formData.current = {
                accountType : "I",
                mainnetId:mainnetId,
                feeAmount: send_token_amount,
                fromAddr: account,
                receiveAddr: to_address,
                txId: resultMessage
            }
            AirdropService.airdropFeeDeposit(formData.current).then((res)=>{
                if(res.status){
                    PopupService.newPopup(t("입금 신청 완료")
                        , t("네트워크 상환에 따라 다소 시간이 걸릴 수 있습니다.")
                        , "txId:"+ellipseAddress(resultMessage, 6)
                        ,{btnTxt:t("입금확인 시 충전이 완료됩니다."),
                            onClick: () => {
                                props.setReLoad(!props.reLoad);
                                props.setPopupIn(false);
                            }}
                        , {}
                    );
                }
            });
        } else {
            PopupService.newPopup(t("확인 필요")
                , t("메타마스크에서 트랜잭션을 승인해 주세요.")
                , ""
                , {
                    btnTxt: t("확인 했어요"),
                    onClick: () => {
                    }
                }
                , {}
            );
        }
    }
    const onSubmit = () => {
        // if(props.handleOnClick) {
        //     props.handleOnClick('10');
        // }
        if(!address){
            ToastError(t("지갑연결을 확인해 주세요."));
            return false;
        }
        if (chainId != props.popupItem.mainnetInfo.chainId) {
            MetaMaskService.setSwitchNetwork(provider, parseInt(props.popupItem.mainnetInfo.chainId), props.popupItem.mainnetInfo.mainnetName, props.popupItem.mainnetInfo.symbolName, props.popupItem.mainnetInfo.decimalVal, props.popupItem.mainnetInfo.rpcUrl, props.popupItem.mainnetInfo.explorerUrl, '').then(
                (response) => {
                    if (!response.status) {
                    }
                }
            );
        } else {
            MetaMaskService.sendTransferToken(
                address,
                '',
                amount.toString(),
                zigleWalletAddress!.toString(),
                props.popupItem.mainnetInfo.mainnetId,
                '',
                '',
                txIdResult
            );
        }
    }
    useEffect(()=>{
        if(hookCoinBalance.data?.formatted && props.popupIn){
            setCoinBalance(hookCoinBalance.data?.formatted);
        }
    },[hookCoinBalance]);
    return (
        <>
            {
                props.popupIn && (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle
                                    home={home}
                                    textTitle={props.popupItem.mainnetInfo?.symbolName+ t("수수료 입금")}
                                    onClickLeft={()=>{
                                        props.setPopupIn(false);
                                    }}
                                />

                                <PageContent>
                                    <FlexColumn gap={8}>
                                        <FlexRowCenter>
                                            <div style={{width: '150px'}}>
                                                <DefaultInput
                                                    style={{width: '100px'}}
                                                    error={errors.amount}
                                                    inputElement={
                                                        <input
                                                            type={"text"}
                                                            className={styles.inputText_33}
                                                            placeholder={t("코인 수량 입력")}
                                                            {...register("amount", {
                                                                required: t("코인 수량 입력"),
                                                                onChange: (el) => {
                                                                    if (new bigDecimal(el.target.value).compareTo(new bigDecimal(0)) <= 0) {
                                                                        setError("amount", {
                                                                            type: 'custom',
                                                                            message: t('수량을 입력해 주세요.')
                                                                        });
                                                                        setBtnActive(false);
                                                                    } else if (new bigDecimal(decimalFormat(coinBalance, 4)).compareTo(new bigDecimal(el.target.value)) > 0) {
                                                                        setAmount(el.target.value);
                                                                        setBtnActive(true);
                                                                        clearErrors('amount');
                                                                    } else {
                                                                        setError("amount", {
                                                                            type: 'custom',
                                                                            message: t("최대 {0}까지 가능합니다.", {0:decimalFormat(coinBalance, 4)})
                                                                        });
                                                                        setBtnActive(false);
                                                                    }
                                                                }
                                                            })}
                                                        />
                                                    }
                                                />
                                            </div>
                                            <div style={{
                                                marginBottom: '24px',
                                                color: '#dbdee6'
                                            }}>{props.popupItem.mainnetInfo.symbolName}</div>
                                        </FlexRowCenter>
                                        <FlexRowCenter>
                                            <div className={styles.txt13_B04} style={{
                                                borderRadius: '8px',
                                                textAlign: 'center',
                                                padding: '10px 16px',
                                                backgroundColor: '#f2f4f6'
                                            }}>
                                                {t("보유수량")} {decimalFormat(coinBalance)} {props.popupItem.mainnetInfo.symbolName}
                                            </div>
                                        </FlexRowCenter>
                                    </FlexColumn>
                                    <FlexBottom>
                                        <BottomButton
                                            isActive={btnActive}
                                            backgroundColor={"#3a71ff"}
                                            textColor={"white"}
                                            // border={"solid 1px #dbdee6"}
                                            onClick={handleSubmit(onSubmit)}
                                            text={t("다음")}
                                        />
                                    </FlexBottom>
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
            </>
    )
}