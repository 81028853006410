import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import Button34 from "../common/button/Button34";
import { useSelector } from "react-redux";
import CommonService from "../../services/common.service";
import {Toast} from "../common/popup/ToastAlert";
import IconCopy from "../../assets/icons/copy.png";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import AssetService from "../../services/asset.service";
import ProfileService from "../../services/profile.service";
import RefPopup from "./RefPopup";
import {decimalFormat, ellipseAddress} from "../../common/js/utilities";
import {useAccount} from "wagmi";
import {useTranslation} from "react-i18next";


export default function WalletFrame() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { address, isConnected } = useAccount();

  const login = useSelector((state: any) => state.userReduce.login);
  const email = useSelector((state: any) => state.userReduce.email);
  const doname = useSelector((state: any) => state.userReduce.doname);
  const ziglePoint = useSelector((state: any) => state.userReduce.ziglePoint);

  const [refList, setRefList] = useState([]);
  const refCnt = useRef(0);
  const [showPop, setShowPop] = useState(false);

  useEffect(()=>{
    if(login){
      ProfileService.refList().then((res)=>{
        setRefList(res.data);
        if(res.data[0]?.email ===null){
          refCnt.current = 0
        }else{
          refCnt.current = res.data.length;
        }
      })
      // ProfileService.refList
    }
  },[])

  return (
      <>
        <RefPopup setShow={setShowPop} show={showPop} refCnt={refCnt.current} refList={refList} />
        <div className={styles.mainBox}>
          {login && (
            <div className={styles.titleContainer} onClick={()=>navigate("/profile")}>
              <div className={styles.txtTitle18}>{doname ? doname : t('닉네임을 설정해 주세요')}</div>
              <div>
                <img
                  src={require("../../assets/icons/btnArrow.png")}
                  height={28}
                  alt={"arrow"}
                />
              </div>
            </div>
          )}
          <div className={styles.contentsColumn} style={{ gap: "16px" }}>
            {address && (
              <div className={styles.flexRowSpace}>
                <div className={styles.mainWalletBadge} style={{ gap: "8px" }}>
                  <div className={styles.flexRowEnd} style={{ gap: "2px" }}>
                    <span className={styles.txtCryptoAddr}>{ellipseAddress(address, 5)}</span>
                    <CopyToClipboard
                        text={address}
                        onCopy={() => Toast(t("주소를 복사 했습니다."))}
                    >
                      <img src={require("../../assets/icons/copy.png")} width={20} alt={"copy"} />
                    </CopyToClipboard>
                  </div>
                </div>
                <div className={styles.flexRowEnd} style={{ gap: "2px" }}>
                  <Button34
                    type={"white"}
                    text={t("받기")}
                    onClick={() => {
                      navigate("/myLink")
                    }}
                  />
                  <Button34
                    type={"black"}
                    text={t("보내기")}
                    onClick={() => {
                      navigate("/transfer")
                    }}
                  />
                </div>
              </div>
            )}
            {/*{!shortAddress && <div>지갑을 연결해 주세요.</div>}*/}

            {login && (
              <>
                <div className={styles.line}></div>
                <FlexRowSpace onClick={() => {
                  navigate("/myPoint")
                }}>
                  <FlexRowStart className={styles.txt14_B04} style={{ width: "200px" }}>
                    {t("결제포인트")}
                  </FlexRowStart>
                  <FlexRowEnd >
                    <span
                      className={styles.txt14_B04}
                      style={{ paddingRight: "10px" }}
                    >
                      {decimalFormat(ziglePoint)} P
                    </span>
                    <img
                      src={require("../../assets/icons/btnArrow.png")}
                      alt={"arrow"}
                      height={20}
                    />
                  </FlexRowEnd>
                </FlexRowSpace>
                <FlexRowSpace style={{background:'#F8F9FC', borderRadius:'12px', padding:'10px 12px'}} className={styles.txt14_300_B05}>
                  <FlexRowStart gap={8} onClick={()=>{
                    setShowPop(true);

                  }}>{t("초대한 친구")}
                    <span style={{fontWeight:'600'}}>{t("{0}명",{0:refCnt.current})}</span>
                    <img
                        src={require("../../assets/icons/btnArrow.png")}
                        alt={"arrow"}
                        height={20}
                    />
                  </FlexRowStart>
                  <FlexRowEnd>
                    <div className={styles.txt12_500_PointB} style={{padding:'8px 12px', background:'#EBF2FF',  borderRadius:'100px' }} onClick={()=>{navigate("/ref")}}>{t("초대하기")}</div>
                  </FlexRowEnd>
                </FlexRowSpace>
              </>
            )}
          </div>
        </div>
      </>
  );
}
