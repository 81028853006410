import React, {CSSProperties, useEffect, useState} from "react";

import {inspect} from "util";
import styles from "../../common/css/zigle.module.scss";
import BottomLayer, { FlexBottomButton } from "../common/container/BottomLayer";
import {FlexColumn, FlexRowStart} from "../common/container/PageContainer";
import BottomButton from "../common/button/BottomButton";
import {useSelector} from "react-redux";
import MetaMaskService from "../../services/metamask.service";
import CommonService from "../../services/common.service";
import {ellipseAddress} from "../../common/js/utilities";
import {useTranslation} from "react-i18next";
interface ITokenDetail {
    show: boolean;
    setShow: any;
    walletList: any[];
    walletOnClick?: (item: string)=> void;
}
function WalletPopup(props:ITokenDetail) {
    const { t } = useTranslation();

    return (
        <BottomLayer show={props.show}
         setShow={props.setShow}
         content={
             (
                 <>
                     <FlexColumn>
                         {
                             props.walletList.length > 0 ? (
                                 props.walletList.map((item, index) => {
                                     return (
                                         <div key={item.walletAddress} style={{height:'40px', padding:'12px 20px'}} className={styles.txt16_B04} onClick={()=>{ if(props.walletOnClick){ props.walletOnClick(item.walletAddress)} }}>
                                             {ellipseAddress(item.walletAddress, 14)}
                                         </div>
                                    )
                                })
                             ) : null
                         }
                     </FlexColumn>
                 </>
             )
         }/>
    );
}

export default WalletPopup;
