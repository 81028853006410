import React, {useEffect, useState} from "react";
import styles from "../common/css/zigle.module.scss";

import PageTitle, {
    EMPTY_SRC, FlexBottom,
    FlexColumn, FlexRowCenter,
    FlexRowStart,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import {useLocation, useNavigate} from "react-router-dom";
import BottomButton from "../components/common/button/BottomButton";
import {Trans, useTranslation} from "react-i18next";

export default function JoinEmailCmpl(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {state} = useLocation();
    const [title, setTitle] = useState(t('인증메일'));
    useEffect(()=>{
        if(state){
            const {page} = state;	// 3번 라인
            if(page === "quit")
            {
                setTitle(t("탈퇴인증메일"));
            }
        }
    },[state]);

    return (
        <PageRootContainer>
            <PageTitle leftSrc={EMPTY_SRC} rightSrc={require('../assets/icons/close_28.png')} onClickRight={() => {
                navigate("/");
            }}/>
            <PageContent>
                <FlexColumn gap={10}>
                    <div className={styles.txt20_600_B01}>
                        <Trans t={t} i18nKey={"<strong>{0}이 발송</strong>되었어요!<br/>이메일 확인 후 로그인이 가능해요"}  values={{0:title}}/>
                    </div>
                    <div className={styles.txt13_B04}>
                        <Trans t={t} i18nKey={"이메일을 수신 받지 못하셨을 경우<br/>스팸함을 확인해주세요"}/>
                    </div>
                    <FlexRowCenter style={{padding:"95px 0"}}>
                        <img src={require("../assets/imges/login/send_email.png")} width={226}/>
                    </FlexRowCenter>
                </FlexColumn>
                <FlexBottom>
                <BottomButton
                    isActive={true}
                    backgroundColor={"#3a71ff"}
                    textColor={"white"}
                    // border={"solid 1px #dbdee6"}
                    onClick={()=>navigate("/")}
                    text={t("확인")}
                />
                </FlexBottom>
            </PageContent>
        </PageRootContainer>
    );
}