import React, {useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {FlexBottom, FlexColumn} from "../common/container/PageContainer";
import {TextField} from "@material-ui/core";
import styles from "../../common/css/zigle.module.scss";
import BottomButton from "../common/button/BottomButton";
import PhoneService from "../../services/phone.service";
import {useTranslation} from "react-i18next";


interface IPayDepthProps {
    payInfo:any;
    setPayInfo:any;
    handleOnClick?: ()=> void;
}
export default function PayDepth2({payInfo, setPayInfo, handleOnClick}:IPayDepthProps) {
    const inputRef = useRef<HTMLFormElement>(null);
    const [btnActive, setBtnActive] = useState(true);
    const { t } = useTranslation();

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();


    const onSubmit = (data: any) => {

        PhoneService.ziglePointBank(payInfo).then(
            (res) => {
                setPayInfo(
                {...payInfo,
                    depth : 10
                });
            }
        );
    };

    return (
        <FlexColumn>
            <FlexColumn>
                <form
                    ref={inputRef}
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ width: "100%" }}
                >
                    <textarea
                        style={{border:'none'}}
                        className={styles.textAreaBox}
                        placeholder={t("입금하시는 분의\n이름을 입력해주세요")}
                        rows={2}
                        maxLength={12}
                        {...register("userName", {
                            required: t("입금하시는 분의\n이름을 입력해주세요"),
                            onChange: (el) => {
                                setPayInfo(
                                    {...payInfo,
                                        bankInfo : {
                                            ...payInfo.bankInfo,
                                            userName:el.target.value
                                        }
                                    });

                                if(el.target.value){
                                    setBtnActive(true)
                                }else{
                                    setBtnActive(false)
                                }
                            }
                        })}
                    />
                </form>
            </FlexColumn>
            <FlexBottom>
                <BottomButton
                    isActive={btnActive}
                    backgroundColor={"#3a71ff"}
                    textColor={"white"}
                    // border={"solid 1px #dbdee6"}
                    onClick={handleSubmit(onSubmit)}
                    text={t("다음")}
                />
            </FlexBottom>
        </FlexColumn>
    )
}