import PageTitle, {
    FlexBottom,
    FlexBottomStart,
    FlexColumn,
    FlexRowAround, FlexRowCenter, FlexRowSpace, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../common/container/PageContainer";
import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import MetaMaskService from "../../services/metamask.service";
import styles from "../../common/css/zigle.module.scss";
import BottomButton from "../common/button/BottomButton";
import DefaultInput from "../common/input/DefaultInput";
import {useForm} from "react-hook-form";
import bigDecimal from "js-big-decimal";
import CommonService from "../../services/common.service";
import AirdropService from "../../services/airdrop.service";
import PopupService from "../../services/popup.service";
import {useInView} from "react-intersection-observer";
import PhoneService from "../../services/phone.service";
import PointRcvHisItem from "../point/PointRcvHisItem";
import EmptyContent from "../common/EmptyContent";
import AirdropFeeHisItem from "./AirdropFeeHisItem";
import moment from "moment";
import {useTranslation} from "react-i18next";



interface IAirdropFeeHis {
    popupHis:boolean;
    setPopupHis:any;
    popupItem:any;

}
export default function AirdropFeeHis(props:IAirdropFeeHis) {
    const { t } = useTranslation();
    const [list, setList] = useState<any[]>([])
    const [currPage, setCurrPage] = useState(1);
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [blockSize] = useState(10);
    const refPointCount = useRef(0);
    const refPointSum = useRef(0);

    let monthDay = "";
    let monthResult = "";

    useEffect(() => {
        const fetchData = async() =>{
            const response = await AirdropService.airdropFeeHis(props.popupItem.mainnetId, currPage, blockSize);
            if (!response.data.length) {
                setLastList(true);
                return;
            }
            setList([...list, ...response.data]);
        }
        if (!lastList &&  props.popupHis) {
            fetchData();
        }
    },[currPage,  props.popupHis]);

    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);
    return (
        <>
            {
                props.popupHis && (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle
                                    textTitle={t("수수료 이용 내역")}
                                    onClickLeft={()=>{
                                        setCurrPage(1);
                                        setLastList(false);
                                        setList([]);
                                        props.setPopupHis(false);
                                    }}
                                />
                                <PageContent>
                                    <FlexColumn gap={32}>
                                        {
                                            list.length > 0 ?
                                                (
                                                    list.map((item, index)=>{
                                                        let tmpDay = moment(item.regDate).format('MM-DD');

                                                        if((tmpDay) && (monthDay != tmpDay)){
                                                            monthResult = "Y";
                                                            monthDay = tmpDay;
                                                        }else{
                                                            monthResult = "N";
                                                        }
                                                        return (
                                                            <AirdropFeeHisItem key={item.regDate} index={index} item={item} monthResult={monthResult}/>
                                                        )
                                                    })
                                                )
                                                :
                                                (
                                                    <EmptyContent titleText={t('충전 내역이 없어요.')}/>
                                                )
                                        }
                                        {
                                            Object.values(list).length >= blockSize ? (
                                                <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) : null
                                        }
                                    </FlexColumn>
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
            </>
    )
}