import React, {useEffect, useState} from "react";
import {ICoinInfo} from "../../common/dto/CoinInfoDto";
import {
    FlexBottom,
    FlexColumn,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart
} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import BottomButton from "../common/button/BottomButton";
import CopyToClipboard from "react-copy-to-clipboard";
import CommonService from "../../services/common.service";
import CoinPopup from "./CoinPopup";
import {IMainnetInfo} from "../../common/dto/MainnetInfo";
import MetaMaskService from "../../services/metamask.service";
import {useSelector} from "react-redux";
import {Toast, ToastError} from "../common/popup/ToastAlert";
import PopupService from "../../services/popup.service";
import {useAccount} from "wagmi";
import {useWalletEthersPorvider} from "../../common/js/ethersProviderHook";
import {Trans, useTranslation} from "react-i18next";


interface ICoinViewProps {
    coinDetailInfo?: ICoinInfo;
    setPageMode?: any;
}

export default function CoinDetail(props:ICoinViewProps) {
    const { t } = useTranslation();

    const [btnActive, setBtnActive] = useState(true);
    const [showCoinPopup, setShowCoinPopup] = useState(false);
    const [itemInfo, setItemInfo] = useState([]);
    const [mainnetList, setMainnetList] = useState<any[]>([]);
    const [mainnetItem, setMainnetItem] = useState<IMainnetInfo>();

    const { address, isConnected } = useAccount();
    const provider = useWalletEthersPorvider();
    
    const chainId = useSelector((state:any) => state.walletReducer.chainId);


    const handleOnClick = async () =>{
        setShowCoinPopup(true);
    }
    const onclickHandleMainNetSave = (mainnetItem:any)=>{
        if(!address){
            ToastError(t("지갑연결을 확인해 주세요."));
            return false;
        }
        MetaMaskService.setSwitchNetwork(provider, parseInt(mainnetItem.chainId), mainnetItem.mainnetName, mainnetItem.symbolName, mainnetItem.decimalVal, mainnetItem.rpcUrl,  mainnetItem.explorerUrl, '').then(
            (response) => {

                if(response.status)
                {
                }
            }
        );

    }
    const onClickHandleTokenSave = (tokenName:string, symbolImg:string, tokenAddress:string, decimals:string)=>{
        if(!address){
            ToastError(t("지갑연결을 확인해 주세요."));
            return false;
        }

        MetaMaskService.addToken(provider, tokenName, symbolImg, tokenAddress, decimals).then(
            (response) => {
                if(response){

                }
            }
        );
    }
    const onHandleClickMainnet = (item:any) =>{
        setMainnetItem(item);
        setShowCoinPopup(false);
    }
    useEffect(()=>{
        if(props.coinDetailInfo?.multiCode){
           CommonService.coinMultiCodeList(props.coinDetailInfo?.multiCode).then(
               (response) => {
                   setMainnetItem(response.data[0])
                   setMainnetList(response.data);
               }
           )
        }
    }, [props.coinDetailInfo]);

    return (
        <>
            <CoinPopup setShow={setShowCoinPopup} show={showCoinPopup} mainnetList={mainnetList} onHandleClickMainnet={onHandleClickMainnet}/>
            <FlexColumn>
                <FlexColumn style={{letterSpacing:'-0.6px'}} gap={4}>
                    <div className={styles.txt20_B01}><Trans t={t} i18nKey={"<b>링크를 복사</b>해<br /> 친구에게 보내보세요!"}/></div>
                    <div className={styles.txt13_B05}><Trans t={t} i18nKey={"간편하게 메타마스크에 <br />메인넷 및 토큰을 추가 할 수 있어요"}/></div>
                    {
                        (mainnetItem) ? (
                            <>
                                <div className={styles.txt14_B04} style={{marginTop:'40px', marginBottom:'8px'}}>{t("메인넷 지정")}</div>
                                <FlexRowSpace className={styles.select_mainnet} onClick={()=>handleOnClick()}>
                                    <FlexRowStart>
                                        <div style={{ width:'28px'}}>
                                            <img src={mainnetItem?.symbolImgFileId} height={28}/>
                                        </div>
                                        <div style={{paddingLeft:'5px'}} className={styles.txt16_B01}>
                                            {t(mainnetItem?.displayName)}
                                        </div>
                                    </FlexRowStart>
                                    <FlexRowEnd>
                                        <img src={require('../../assets/icons/arrow_select.png')} width={20} />
                                    </FlexRowEnd>
                                </FlexRowSpace>
                                <FlexRowSpace style={{marginTop:'16px'}}>
                                    <FlexRowStart  style={{width:'80%'}} className={styles.selected_area}>
                                        {window.location.protocol + "//" + window.location.host+"/tokenList/"+mainnetItem?.linkName+"/"+props.coinDetailInfo?.linkName}
                                            </FlexRowStart>
                                            <FlexRowEnd style={{width:'19%'}}>
                                            <CopyToClipboard text={window.location.protocol + "//" + window.location.host+"/tokenList/"+mainnetItem?.linkName+"/"+props.coinDetailInfo?.linkName} onCopy={() =>{Toast(t('복사 하였습니다.')); }}>
                                            <div className={styles.img_btn}>
                                                {t("복사")}
                                            </div>
                                            </CopyToClipboard>
                                            </FlexRowEnd>
                                            </FlexRowSpace>
                            </>
                        ) : null
                    }

                </FlexColumn>
            </FlexColumn>

            <FlexBottom>
                <BottomButton
                    isActive={true}
                    backgroundColor={"#f2f4f6"}
                    textColor={"#3a71ff"}
                    text={t("공유하기")}
                    onClick={() => {
                        PopupService.snsLinkMobile(window.location.protocol + "//" + window.location.host+"/tokenList/"+mainnetItem?.linkName+"/"+props.coinDetailInfo?.linkName);
                    }}
                />
                <BottomButton
                    isActive={true}
                    backgroundColor={"#3a71ff"}
                    textColor={"#ffffff"}
                    text={t("내 메타마스크에 추가")}
                    onClick={() => {
                        if(props.coinDetailInfo?.typeCode==='TOKEN'){
                            onClickHandleTokenSave(props.coinDetailInfo?.coinName, props.coinDetailInfo?.symbolImgFileId, props.coinDetailInfo?.contAddr, props.coinDetailInfo?.decimalVal)
                        }else{
                            onclickHandleMainNetSave(mainnetItem);
                        }

                    }}
                />
            </FlexBottom>
        </>
    )
}