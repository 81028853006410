import {FlexColumn, FlexColumnCenter} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import React from "react";
import {useTranslation} from "react-i18next";


export default function Intro3() {
    const { t } = useTranslation();
    return (
        <FlexColumn gap={80} style={{padding:'160px 0px'}}>
            <FlexColumnCenter gap={10}>
                <img src={require('../../assets/imges/intro/introduction_img5.png')} width={48} />
                <div className={styles.txt20_600_B05}>{t("지원가능 메인넷")}</div>
                <div className={styles.txt32_600_W }>16</div>
            </FlexColumnCenter>
            <FlexColumnCenter gap={10}>
                <img src={require('../../assets/imges/intro/introduction_img6.png')} width={48} />
                <div className={styles.txt20_600_B05}>{t("지원가능 코인")}</div>
                <div className={styles.txt32_600_W }>600</div>
            </FlexColumnCenter>
        </FlexColumn>
    )
}