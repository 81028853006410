import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import {FlexColumn, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import {IMainnetInfo} from "../../common/dto/MainnetInfo";
import {useInView} from "react-intersection-observer";
import CommonService from "../../services/common.service";
import {useForm} from "react-hook-form";
import ReactPlaceholder from "react-placeholder";
import {toast} from "react-toastify";
import loading = toast.loading;
import EmptyContent from "../common/EmptyContent";
import FriendDetail from "../common/popup/FriendDetail";
import TokenPopup from "./TokenPopup";
import {Trans, useTranslation} from "react-i18next";

interface ITokenWrapperProps {
    setItemInfo?: any;
    setShowTokenPopup?: any;
    mainLink?: string;
}

export default function TokenWrapper(props:ITokenWrapperProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [searchCoin, setSearchCoin] = useState(props.mainLink);



    const [mainNetList, setMainNetList] = useState<Array<IMainnetInfo>>([]);
    const [currPage, setCurrPage] = useState(1);
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [blockSize] = useState(10);

    const handleOnClick = (tokenItem:any) =>{
        props.setItemInfo(tokenItem);
        props.setShowTokenPopup(true);
    }
    useEffect(() => {
        const fetchData = async(searchCoin: any) =>{
            const response = await CommonService.mainnetList(searchCoin, currPage, blockSize, '');
            if (!response.data.length) {
                setLastList(true);
                return;
            }
            setMainNetList([...mainNetList, ...response.data]);
        }
        if (!lastList) {
            
            fetchData(searchCoin);
        }
    },[currPage, searchCoin]);

    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);

    return (
        <>


            <FlexColumn className={styles.tokenEventBadge}>
                    <span className={styles.tokenEventText}>
                        <Trans t={t} className={styles.transSpanWeight400} i18nKey={"<span>아래 항목을 선택하면 </span>메타마스크에 해당 메인넷으로 등록<span>됩니다.</span>"} />
                    </span>
            </FlexColumn>
            <FlexColumn>
                <FlexRowStart>
                    <div style={{width:'28px', border: '0', borderBottom: 'solid 1px #f2f4f8', height: '47px', display: 'flex',alignItems: 'center'}}><img src={require('../../assets/imges/search_icon.png')} style={{width:'28px'}} /></div>
                    <div style={{width:'100%'}}><input
                        type={"text"}
                        className={styles.inputText_44}
                        placeholder={t('메인넷명을 입력해 주세요')}
                        defaultValue={searchCoin}
                        onChange ={(el) => {
                            setCurrPage(1);
                            setMainNetList([]);
                            setLastList(false);
                            setSearchCoin(el.target.value);
                        }}
                    /></div>
                </FlexRowStart>
            </FlexColumn>

            {
                mainNetList.length > 0 ? (
                    mainNetList.map((item, index) => {
                        return (
                            <FlexColumn key={item.mainnetId} style={{height:'78px'}} onClick={()=>{handleOnClick(item)}}>
                                <FlexRowSpace>
                                    <FlexRowStart>
                                        <div style={{paddingRight:'12px'}}>
                                            <img src={item.symbolImgFileId} alt="bgIcon" width={38} />
                                        </div>
                                        <FlexColumn>
                                            <div className={styles.txt14_B01}>{t(item.displayName)}</div>
                                            <div className={styles.txt12_B05}>{item.symbolName}</div>
                                        </FlexColumn>
                                    </FlexRowStart>
                                    <FlexRowEnd>
                                        <div>
                                            <div className={styles.tokenListSubBadge}>
                                                <div className={styles.txt11_B04}>{t("체인 ID")} {item.chainId}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <img
                                                src={require("../../assets/imges/arrow.png")}
                                                height={20}
                                                alt={"arrow"}
                                            />
                                        </div>
                                    </FlexRowEnd>
                                </FlexRowSpace>
                            </FlexColumn>
                        )
                    })
                ) : (
                    <EmptyContent titleText={t('메인넷을 찾을수 없어요')} titleImage={require('../../assets/icons/empty_icon.png')}/>
                )
            }
            {
                Object.values(mainNetList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
            }
        </>
    );
}
