import PageTitle, {
    FlexBottom, FlexBottomStart,
    FlexColumn, FlexColumnCenter, FlexRowAround, FlexRowCenter, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper, QrArea
} from "../common/container/PageContainer";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import CopyToClipboard from "react-copy-to-clipboard";
import BottomButton from "../common/button/BottomButton";
import QRCode from "qrcode.react";
import DefaultInput from "../common/input/DefaultInput";
import {useForm} from "react-hook-form";
import AssetService from "../../services/asset.service";
import PopupService from "../../services/popup.service";
import {ILinkList} from "../../common/dto/LinkDto";
import {Toast} from "../common/popup/ToastAlert";
import {Trans, useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import CommonService from "../../services/common.service";
import FavService from "../../services/fav.service";
import {useInView} from "react-intersection-observer";
import EmptyContent from "../common/EmptyContent";
import {useSelector} from "react-redux";

interface IUserFriendList {
    show: boolean;
    setShow: any;
    modeType:string;
    doname:string;
    userId:string;
    friendCnt:any;
}

export default function UserFriendList(props:IUserFriendList){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [friendList, setFriendList] = useState<any[]>([]);
    const [currPage, setCurrPage] = useState(1);
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [blockSize] = useState(10);
    const [refresh, setRefresh] = useState(false);
    const [tabView, setTabView] =  useState('');

    const login: boolean = useSelector((state: any) => state.userReduce.login);
    const userId: string = useSelector((state: any) => state.userReduce.userId);


    const fetchData = async() =>{
        const response = await CommonService.friendList( props.userId, currPage, blockSize, tabView);
        if (!response.data.length) {
            setLastList(true);
            return;
        }
        setFriendList([...friendList, ...response.data]);
    }
    useEffect(() => {

        if(tabView) {
            console.log("tab in,,,,,,,,,", tabView)
            if (!lastList) {
                console.log("tabView,,,,,,,,,")
                fetchData();
            }
        }
    },[currPage, refresh]);

    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);
    useEffect(()=>{
        if(friendList.length > 0)
        {
            setFriendList([]);
            setLastList(false);
            if(currPage ===1 ){
                setRefresh(!refresh);
            }else{
                setCurrPage(1);
            }
        }else{
            setLastList(false);
            setRefresh(!refresh);
        }
    },[tabView])
    useEffect(()=>{
        if(props.show){
            setTabView(props.modeType);
        }
    },[props.modeType, props.show])
    return (
        <>
            {
                props.show &&
                (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle
                                    textTitle={props.doname ?? t("닉네임이 없습니다.")}
                                    onClickLeft={() => {
                                        props.setShow(false);
                                        setTabView('');
                                        setFriendList([]);
                                        setLastList(false);
                                        setCurrPage(1);
                                    }}
                                />
                                <PageContent>
                                    <FlexRowAround>
                                        <div className={ tabView === 'friend' ? styles.topTabActive : styles.topTabDefault} onClick={()=>{
                                            setTabView('friend');
                                        }}>{t('프렌더({0})', {0:props.friendCnt.friendCnt})}</div>
                                        <div className={ tabView === 'friending' ? styles.topTabActive : styles.topTabDefault} onClick={()=>{
                                            setTabView('friending');
                                        }}>{t('프렌딩({0})', {0:props.friendCnt.friendingCnt})}</div>
                                    </FlexRowAround>
                                    <div className={styles.line} style={{width: 'calc(100% + 40px)', marginLeft:'-20px'}}/>
                                    <FlexColumn style={{marginTop:'20px'}}>
                                        {
                                            friendList.length > 0 ? (
                                                    friendList.map((item, index) => {
                                                        return (
                                                            <FlexColumn key={item.favoriteId} style={{height:'78px'}}>
                                                                <FlexRowSpace>
                                                                    <FlexRowStart>
                                                                        <div style={{paddingRight:'12px'}}>
                                                                            <img src={item.profileImageFileId ? item.profileImageFileId : 'https://s3.ap-northeast-2.amazonaws.com/bucket.zigle.io/profiles/profile'+(item.userId % 9)+'.png' } alt="bgIcon" style={{width:'38px', height:'38px', borderRadius:'15px', aspectRatio:'1', objectFit:'cover'}} />
                                                                        </div>
                                                                        <FlexColumn>
                                                                            <div className={ styles.txt16_B02}>{item.doname}</div>
                                                                            <div className={styles.txt14_B05}>{t("{0}명 프렌딩", {0:item.favTotalCnt})}</div>
                                                                        </FlexColumn>
                                                                    </FlexRowStart>

                                                                            <FlexRowEnd gap={4}>
                                                                                {
                                                                                    item.userId !== userId ? (
                                                                                        item.isMyFriend === "Y" ?
                                                                                            (
                                                                                                <FlexColumnCenter className={styles.txt14_500_PointB} style={{ width:'75px', height:'34px',  background:'#EBF2FF',  borderRadius:'8px' }} onClick={()=>{

                                                                                                    PopupService.newPopup(t("언프렌드")
                                                                                                        ,t("'{0}' 님을 언프렌드 할까요?", {0:item.doname})
                                                                                                        , ""
                                                                                                        ,{btnTxt:t("확인"),
                                                                                                            onClick: () => {
                                                                                                                FavService.friendDelete(item.userId).then((response) => {
                                                                                                                    setFriendList(friendList.map(ztem=>ztem.userId === item.userId ? { ...ztem, isMyFriend:'N' } : ztem))
                                                                                                                    Toast(t('친구취소가 완료 되었습니다.'));
                                                                                                                });
                                                                                                            }}
                                                                                                        , {
                                                                                                            btnTxt:"",
                                                                                                            onClick: () => {}
                                                                                                        }
                                                                                                    );
                                                                                                }} >{t('언프렌드')}</FlexColumnCenter>
                                                                                            ) :
                                                                                            (
                                                                                            <FlexColumnCenter className={styles.txt14_500_PointB} style={{ width:'75px', height:'34px', background:'#3A71FF', color:'#FFFFFF', borderRadius:'8px' }} onClick={()=> {


                                                                                                if(!login){
                                                                                                    PopupService.newPopup("로그인을 해주세요!","로그인 후 이용이 가능 합니다.", "",{btnTxt:"확인", onClick: () => {
                                                                                                            sessionStorage.setItem("redirectTo",window.location.href);
                                                                                                            navigate('/walletLogin');
                                                                                                        }}, {})
                                                                                                }else{
                                                                                                    PopupService.newPopup(t("프렌드하기")
                                                                                                        ,t("'{0}' 님을 프렌드 할까요?", {0:item.doname})
                                                                                                        , ""
                                                                                                        ,{btnTxt:"확인",
                                                                                                            onClick: () => {
                                                                                                                FavService.friendInsert(item.userId).then((response) => {
                                                                                                                    Toast(t('친구등록이 완료 되었습니다.'));
                                                                                                                    setFriendList(friendList.map(ztem=>ztem.userId === item.userId ? { ...ztem, isMyFriend:'Y' } : ztem))
                                                                                                                });
                                                                                                            }}
                                                                                                        , {
                                                                                                            btnTxt:"",
                                                                                                            onClick: () => {}
                                                                                                        }
                                                                                                    );
                                                                                                }

                                                                                            }}>{t("프렌드")}</FlexColumnCenter>
                                                                                        )
                                                                                    ) : null
                                                                                }
                                                                                <img src={require('../../assets/imges/chat/btn_chat.png')} width={34} onClick={()=>{navigate("/chat/"+item.doname)}}/>
                                                                            </FlexRowEnd>

                                                                </FlexRowSpace>
                                                            </FlexColumn>
                                                        )
                                                    })
                                                )
                                                :
                                                (
                                                    <EmptyContent titleText={t('아직 프렌드가 없어요.')} titleImage={require('../../assets/imges/list_x.png')}/>
                                                )
                                        }
                                    </FlexColumn>
                                    {
                                        Object.values(friendList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
                                    }
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>
    )
}