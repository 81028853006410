import React, {ReactHTMLElement, useEffect, useRef, useState} from "react";
import PageTitle, {
    FlexBottom, FlexBottomStart,
    FlexColumn, FlexRowCenter, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper, QrArea
} from "../common/container/PageContainer";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import CopyToClipboard from "react-copy-to-clipboard";
import BottomButton from "../common/button/BottomButton";
import QRCode from "qrcode.react";
import DefaultInput from "../common/input/DefaultInput";
import {useForm} from "react-hook-form";
import AssetService from "../../services/asset.service";
import PopupService from "../../services/popup.service";
import {ILinkList} from "../../common/dto/LinkDto";
import {Toast} from "../common/popup/ToastAlert";
import {Trans, useTranslation} from "react-i18next";

interface ITokenDetail {
    show: boolean;
    setShow: any;
    itemInfo:any;
    setReLoad:any;
}
function LinkPopup(props:ITokenDetail) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [pageMode, setPageMode] = useState('list');
    const [btnActive, setBtnActive] = useState(false);
    const inputRef = useRef<HTMLFormElement>(null);
    const [service, setService] = useState<boolean>(false);
    const {
        setValue,
        register,
        setError,
        clearErrors,
        formState: { errors, isDirty, isValid },
        handleSubmit,
    } = useForm();



    const deleteLink = (linkMngId:string) =>{
        PopupService.newPopup(t("링크 삭제")
            ,t("링크를 정말 삭제 하시겠습니까?")
            , ""
            ,{btnTxt:t("삭제 할게요"),
                onClick: () => {
                    AssetService.assetDelete(linkMngId).then(
                        (response) =>{
                            PopupService.newPopup(t("링크 삭제 완료")
                                ,t("링크가 성공적으로 삭제 되었어요.")
                                , ""
                                ,{btnTxt:t("확인 했어요"),
                                    onClick: () => {
                                        setPageMode('list');
                                        props.setShow(false);
                                        props.setReLoad((state:boolean) => !state);
                                    }}
                                , {}
                            );
                        }
                    )
                }}
            , {
                btnTxt:t("취소"),
                onClick: () => {}
            }
        );

    }
    const onSubmit = (data: any) => {
        const params = new FormData();
        params.append('linkMngId', props.itemInfo.linkMngId);
        params.append('linkName', data.popLinkName);
        if(props.itemInfo.linksTypeCode != 'CO'){
            params.append('amount', data.popAmount);
        }


        AssetService.updateMylink(params).then(
            (response) =>{
                PopupService.newPopup(t("링크 수정 완료")
                    ,t("링크가 성공적으로 수정 되었어요.")
                    , ""
                    ,{btnTxt:t("확인 했어요"),
                        onClick: () => {
                            setPageMode('list');
                            props.setShow(false);
                            props.setReLoad((state:boolean) => !state);
                        }}
                    , {}
                );
            }
        );
    }
    useEffect(()=>{

        if(!service){
            setBtnActive(false);
        }else{
            if(inputRef.current?.popAmount)
            {
                inputRef.current.popAmount.value='';
            }
            setBtnActive(true);
        }
    },[service])
    return (
        <>
            {
                props.show &&
                (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle
                                    textTitle ={t('링크 상세')}
                                    onClickLeft={()=>{
                                        setPageMode('list');
                                        props.setShow(false);
                                    }}

                                />
                                    {
                                        pageMode ==='list' ?
                                        (
                                            <PageContent style={{paddingBottom:'86px'}}>
                                                <FlexColumn gap={28}>
                                                    <FlexColumn gap={20} style={{alignItems:'center'}} className={styles.txt16_B04}>
                                                        <QrArea>
                                                            <QRCode
                                                                id="qrCodeEl"
                                                                size={261}
                                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                                value={window.location.protocol + "//" + window.location.host+"/l/"+props.itemInfo.linkUrl}
                                                            />
                                                        </QrArea>
                                                        <FlexColumn style={{alignItems:'center'}}>
                                                            <Trans t={t} i18nKey={"QR 또는 링크를 친구에게 공유해<br/>간편하게 코인을 받아보세요."}/>

                                                        </FlexColumn>
                                                    </FlexColumn>
                                                    <FlexColumn gap={25}>
                                                        <FlexColumn gap={10}>
                                                            <div className={styles.txt14_B04}>{t("링크 이름")}</div>
                                                            <div className={styles.txt20_B01}>{props.itemInfo.linkName}</div>
                                                            <div className={styles.line}></div>
                                                        </FlexColumn>
                                                        <FlexColumn gap={10}>
                                                            <div className={styles.txt14_B04}>{t("받을 수량")}</div>
                                                            <div className={styles.txt20_B01}>{props.itemInfo.amount} {props.itemInfo.tnCoinInfo.symbolName}</div>
                                                            <div className={styles.line}></div>
                                                        </FlexColumn>
                                                        <FlexColumn gap={10}>
                                                            <div className={styles.txt14_B04}>{t("도네이션링크")}</div>
                                                            <FlexRowSpace style={{marginTop:'16px'}}>
                                                                <FlexRowStart  style={{width:'80%'}}  className={styles.txt20_B01}>
                                                                    {window.location.protocol + "//" + window.location.host+"/l/"+props.itemInfo.linkUrl}
                                                                </FlexRowStart>
                                                                <FlexRowEnd style={{width:'19%'}}>
                                                                    <CopyToClipboard text={window.location.protocol + "//" + window.location.host+"/l/"+props.itemInfo.linkUrl} onCopy={() => Toast(t("주소를 복사 했습니다."))}>
                                                                        <div className={styles.img_btn} style={{width:'56px'}}>
                                                                            {t("복사")}
                                                                        </div>
                                                                    </CopyToClipboard>
                                                                </FlexRowEnd>
                                                            </FlexRowSpace>
                                                            <div className={styles.line}></div>
                                                        </FlexColumn>
                                                        <FlexColumn gap={10}>
                                                            <div className={styles.txt14_B04}>{t("메인넷")}</div>
                                                            <div className={styles.txt20_W03}>{props.itemInfo.tnMainnetInfo.displayName}</div>
                                                            <div className={styles.line}></div>
                                                        </FlexColumn>
                                                        <FlexColumn gap={10}>
                                                            <div className={styles.txt14_B04}>{t("코인")}</div>
                                                            <div className={styles.txt20_W03}>{props.itemInfo.tnCoinInfo.displayName}</div>
                                                            <div className={styles.line}></div>
                                                        </FlexColumn>
                                                        <FlexColumn className={styles.txt13_B05} style={{borderRadius:'16px', padding:'20px', backgroundColor:'#f9fafb', marginBottom:'30px'}}>
                                                            {t("링크 이름, 수량만 수정 가능 하며 종목 변경을 원하는 경우 삭제 후 재생성 해 주세요.")}
                                                        </FlexColumn>
                                                    </FlexColumn>
                                                </FlexColumn>
                                                <FlexBottom>
                                                    <BottomButton
                                                        isActive={true}
                                                        backgroundColor={"#f2f4f6"}
                                                        textColor={"#3a71ff"}
                                                        text={t("수정하기")}
                                                        onClick={() => {
                                                            setValue("popLinkName",props.itemInfo.linkName);
                                                            setValue("popAmount",props.itemInfo.amount);
                                                            setPageMode('write');
                                                        }}
                                                    />
                                                    <BottomButton
                                                        isActive={true}
                                                        backgroundColor={"#3a71ff"}
                                                        textColor={"#ffffff"}
                                                        text={t("공유하기")}
                                                        onClick={() => {
                                                            PopupService.snsLinkMobile(window.location.protocol + "//" + window.location.host+"/l/"+props.itemInfo.linkUrl);
                                                        }}
                                                    />
                                                </FlexBottom>
                                            </PageContent>
                                        ) :
                                        (
                                            <PageContent style={{paddingBottom:'86px'}}>
                                                <FlexColumn>
                                                    <form ref={inputRef} onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                                                        <DefaultInput
                                                            lable={t("링크 이름")}
                                                            error={errors.popLinkName}
                                                            inputElement={
                                                                <input
                                                                    type={"text"}
                                                                    className={styles.inputText_44_Stroke}
                                                                    placeholder={t('링크 이름을 입력해주세요.')}
                                                                    defaultValue={props.itemInfo.linkName}
                                                                    maxLength={12}
                                                                    {...register("popLinkName", {
                                                                        required: t("링크이름을 입력해주세요."),
                                                                        maxLength:12,
                                                                    })}
                                                                    onChange={(el)=>{
                                                                        if(props.itemInfo.linkName != el.target.value)
                                                                        {
                                                                            setBtnActive(true);
                                                                        }else{
                                                                            setBtnActive(false);
                                                                        }
                                                                    }}
                                                                />

                                                            }
                                                        />
                                                        {
                                                            props.itemInfo.linksTypeCode === 'CO' ?
                                                                (
                                                                    <DefaultInput
                                                                        style={{width:'100px'}}
                                                                        error={errors.popAmount}
                                                                        inputElement={
                                                                            <input
                                                                                type={"number"}
                                                                                className={styles.inputText_33}
                                                                                disabled={true}
                                                                                placeholder={t("자유 수량 링크입니다.")}
                                                                                {...register("popAmount", {
                                                                                    required:false,
                                                                                    validate: (value, formValues) => {
                                                                                        clearErrors('popAmount');
                                                                                        return true;
                                                                                    }
                                                                                })}
                                                                            />
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <DefaultInput
                                                                        lable={t("받을 수량")}
                                                                        error={errors.popAmount}
                                                                        inputElement={
                                                                            <>
                                                                                <input
                                                                                    type={"number"}
                                                                                    className={styles.inputText_44_Stroke}
                                                                                    defaultValue={props.itemInfo.amount}
                                                                                    placeholder={t("받을 수량을 입력해주세요.")}
                                                                                    {...register("popAmount", {
                                                                                        required: t("받을 수량을 입력해주세요."),
                                                                                        validate: (value, formValues) => {
                                                                                            var pattern=  /^([0-9]{1,6})(\.[0-9]{1,5})?$/i;

                                                                                            if(pattern.test(value)){
                                                                                                setBtnActive(true);
                                                                                                clearErrors('popAmount');
                                                                                                return true;
                                                                                            }else{
                                                                                                setBtnActive(false);
                                                                                                setError("popAmount", {type:'custom', message:t('6자리 숫자 및 소수점 5자리까지 입력 가능합니다.')});
                                                                                                return false;
                                                                                            }
                                                                                        }
                                                                                    })}
                                                                                />
                                                                                <span className={styles.txt20_Stroke} style={{marginLeft:'-100px', width:'100px', textAlign:'right'}}>{props.itemInfo.tnCoinInfo.symbolName}</span>
                                                                            </>
                                                                        }
                                                                    />
                                                                )
                                                        }

                                                    </form>
                                                </FlexColumn>
                                                <FlexBottom>
                                                    <BottomButton
                                                        isActive={true}
                                                        backgroundColor={"#f2f4f6"}
                                                        textColor={"#f01d1d"}
                                                        text={t("링크삭제")}
                                                        onClick={() => {
                                                            deleteLink(props.itemInfo.linkMngId);

                                                        }}
                                                    />
                                                    <BottomButton
                                                        isActive={true}
                                                        backgroundColor={"#3a71ff"}
                                                        textColor={"white"}
                                                        // border={"solid 1px #dbdee6"}
                                                        onClick={handleSubmit(onSubmit)}
                                                        text={t("완료")}
                                                    />
                                                </FlexBottom>

                                            </PageContent>
                                        )
                                    }
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                        )
            }
        </>

    );
}

export default LinkPopup;
