import React, {useEffect, useState} from "react";

import styles from "./Popup.module.css";
import {FlexColumn, FlexRowCenter} from "../container/PageContainer";
import {useTranslation} from "react-i18next";

interface IPopAlert {
    message: string | JSX.Element;
    onClose: () => void;
}

export default function ChatModal(props: IPopAlert) {
    const { t } = useTranslation();
    let isStr = false;
    if (typeof props.message === "string") {
        isStr = true;
    }
    return (
        <FlexColumn style={{backgroundColor:'#ffffff', width:'260px', borderRadius:'20px', padding:'10px 0px'}} gap={5}>
            <div style={{display:'none'}} id={'chatPopClose'} onClick={()=>{props.onClose()}}></div>
            {isStr && (
                <div
                    className={styles.chatBody}
                    dangerouslySetInnerHTML={{ __html: props.message.toString() }}
                />
            )}
            {!isStr && <div className={styles.chatBody}>{props.message}</div>}
        </FlexColumn>
    );
}