import React, {useEffect, useState} from "react";

import styles from "../../common/css/zigle.module.scss";
import {FlexColumn, FlexRowCenter} from "../container/PageContainer";
import { FacebookShareButton, TelegramShareButton, TwitterShareButton } from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";
import {Toast} from "./ToastAlert";
import {useScript} from "../../../common/js/hooks";
import {useTranslation} from "react-i18next";

interface IPopAlert {
  linkUrl: string | null;
  onClose: () => void;
}
declare global {
    interface Window {
        Kakao: any;
    }
}
function SnsModal(props: IPopAlert) {
    const { t } = useTranslation();
    const [linkUrl, setLinkUrl] = useState('');
    const status = useScript("https://developers.kakao.com/sdk/js/kakao.js");
    const handleKakaoButton = (currentUrl:any) => {
        window.Kakao.Share.sendScrap({
            requestUrl: currentUrl,
        });
    };
    // kakao sdk 초기화하기
    // status가 변경될 때마다 실행되며, status가 ready일 때 초기화를 시도합니다.
    useEffect(() => {
        if (status === "ready" && window.Kakao) {
            // 중복 initialization 방지
            if (!window.Kakao.isInitialized()) {
                // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
                window.Kakao.init(process.env.REACT_APP_KAKAO_AUTH_KEY);
            }
        }
    }, [status]);

    useEffect(() => {
        if(props.linkUrl)
        {
            setLinkUrl(props.linkUrl);
        }
    },[props.linkUrl])
    return (
        <FlexRowCenter style={{backgroundColor:'#ffffff', borderRadius:'24px', padding:'20px'}} gap={5}>
            <TelegramShareButton url={linkUrl}>
                <div><img src={require('../../../assets/imges/sns/telegramBtn.png')} width={56}/></div>
            </TelegramShareButton>
            <div onClick={()=>handleKakaoButton(linkUrl)}>
                <img src={require('../../../assets/imges/sns/kakaoBtn.png')} width={56}/>
            </div>
            <TwitterShareButton url={linkUrl}>
                <div><img src={require('../../../assets/imges/sns/twitterBtn.png')} width={56}/></div>
            </TwitterShareButton>

            <div><img src={require('../../../assets/imges/sns/bandBtn.png')} width={56}/></div>
            <CopyToClipboard text={linkUrl} onCopy={() => {
                Toast(t("주소를 복사 했습니다."));
                if (props.onClose) {
                    props.onClose();
                }
            }}>
            <div><img src={require('../../../assets/imges/sns/copyBtn.png')} width={56}/></div>
            </CopyToClipboard>
        </FlexRowCenter>

    );
}

export default SnsModal;
