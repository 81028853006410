import React from "react";
import { Contract, ethers } from "ethers";

import { abi } from "../common/js/erc20Abi";
import Popup from "./popup.service";
import { FadeLoader } from "react-spinners";
import { Trans } from "react-i18next";
import CommonService from "./common.service";
import {prepareWriteContract, switchNetwork, sendTransaction, writeContract, fetchBalance, fetchToken} from '@wagmi/core';
import { parseEther, parseUnits } from 'viem';
import {FlexColumn, FlexColumnCenter} from "../components/common/container/PageContainer";



const transUserCheck = async (userId:string, airdropSubType:string) =>{
  if(airdropSubType === "wallet")
  {
    if(! ethers.utils.isAddress(userId)){
      return "N";
    }else {
      return "Y";
    }
  }else{
    const response = await CommonService.donameSearch(userId);
    if(response.data){
      return "Y";
    }else{
      return "N";
    }
  }
}

const toHex = (num: number | string) => {
  const val = Number(num);
  return "0x" + val.toString(16);
};
const addToken = async (
  provider: any,
  tokenName: string,
  symbolImg: string,
  tokenAddress: string,
  decimals: string
) => {
  let useConfirm = false;
  if (provider.connection.url === "eip-1193:") {
      useConfirm = true;
  } else {
    if (provider.connection.url === "metamask") {
      useConfirm = true;
    }
  }
  if (useConfirm) {
    const metaTokenAddData = {
      type: "ERC20",
      options: {
        address: tokenAddress,
        symbol: tokenName,
        decimals: decimals,
        image: symbolImg,
      },
    };

    try {
      if(provider?.provider.request) {
        await provider?.provider
            .request({
              method: "wallet_watchAsset",
              params: metaTokenAddData,
            })
            .then((success: any) => {
              if (success) {
                console.log("ENG successfully added to wallet!");
              } else {
                throw new Error("Something went wrong.");
              }
            })
            .catch(console.error);
      }
    } catch (error) {
      console.log(error);
    }
  } else {
    const alertHtml: JSX.Element = (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        ></div>
        <div style={{ textAlign: "center" }}><Trans i18nKey={"해당 기능은 MetaMask에서만 지원 하는 기능 입니다."}/></div>
      </div>
    );
    Popup.alertMobile(alertHtml);
  }

  return 1;
};
const setSwitchNetwork = async (
    provider: any,
    setNetwork: number,
    chainName: string,
    symbol: string,
    decimals: string,
    rpcUrls: string,
    blockExp: string,
    imageUrl: string,
) => {

    //팝업 호출 메시지..
    const alertHtml = (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "10px",
          }}
        >
          <FadeLoader color="#5046dd" />
        </div>
        <div style={{ textAlign: "center" }}>
          <Trans i18nKey={"지갑을 호출중 입니다<br />지갑 네트워크 속도에 따라 <br/>연결이 지연 될수 있습니다.<br/>연결지연시 지갑연결을 해지 후 <br/>다시 시도해 주세요."} />
        </div>
      </div>
    );
  Popup.alertMobile(alertHtml);
      let msg = "";
      let status = true;
  // if (setNetwork === 1) {
  if (setNetwork === 1){
    try {
      //팜업 호출



      const network = await switchNetwork({
        chainId: setNetwork,
      }).then((res)=>{

        if(res){
          Popup.alertMobilelose();
        }
      });
      // if (provider?.provider.request) {
      //   const result = await provider?.provider
      //       .request({
      //         method: "wallet_switchEthereumChain",
      //         params: [{chainId: ethers.utils.hexValue(setNetwork)}],
      //       });
      //   console.log("result", result);
      //
      // }
    } catch (err: any) {
      console.log(err);
      if (err.code === "-32002") {
        msg = "MetaMask에 승인신청이 진행중입니다. 승인 해주세요.";
        status = false;
      } else if (err.code === "4001") {
        msg = "취소 되었습니다.";
      } else {
        msg = "개인지갑APP을 확인해 주세요.";
      }
    }
  }else{
    const data = [
      {
        chainId: ethers.utils.hexValue(setNetwork),
        chainName: chainName,
        iconUrls: imageUrl,
        nativeCurrency: {
          name: chainName,
          symbol: symbol,
          decimals: parseInt(decimals),
        },
        rpcUrls: [rpcUrls],
        blockExplorerUrls: [blockExp],
      },
    ];

    await provider?.provider
        .request({
          method: "wallet_addEthereumChain",
          params: data,
        })
        .then((success: any) => {
          console.log(success);
        })
        .catch((err: any) => {
          console.log("wallet_addEthereumChain err", err);

          if (err.code === "-32002") {
            msg = "MetaMask를 이미 호출 하였습니다. MetaMask를 확인해 주세요.";
          } else if (err.code === "4001") {
            msg = "취소 되었습니다.";
          } else {
            msg = err.message;
          }
          try {
            Popup.alertMobilelose();
          } catch (e) {
            console.warn(e);
          }
          status = false;
        });
  }
  Popup.alertMobilelose();
  return { status: status, msg: msg };
};
const addNetWork = async (library: any) => {
  const metaTestMetaData = {
    chainId: ethers.utils.hexlify(parseInt("137")),
    chainName: "Polygon Mainnet",
    rpcUrls: ["https://polygon-rpc.com"],
    blockExplorerUrls: ["https://polygonscan.com"],
    nativeCurrency: {
      name: "MATIC",
      decimals: 18,
      symbol: "MATIC",
    },
  };

  try {
    await library.provider.request({
      method: "wallet_addEthereumChain",
      params: [metaTestMetaData],
    });
  } catch (e) {
    console.error("Failed to add network!");
  }
};
const sendTransferToken = async (
  account: string,
  token_address: string | '',
  send_token_amount: string,
  to_address: string | '',
  mainnetId: string,
  coinId: string,
  linkMngId: string,
  txIdResult: any,
) => {

  const alertHtml = (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "10px",
        }}
      >
        <FadeLoader color="#5046dd" />
      </div>
      <div style={{ textAlign: "center" }}>
        <Trans i18nKey={"지갑을 호출중 입니다<br />지갑 네트워크 속도에 따라 <br/>연결이 지연 될수 있습니다.<br/>연결지연시 지갑연결을 해지 후 <br/>다시 시도해 주세요."}/>
      </div>
    </div>
  );
  Popup.newPopup("메타마스크 연결"
      , <FlexColumn gap={20}>
          <FlexColumnCenter gap={16}>
            <img src={require('../assets/imges/metamask/metamask_popup.png')} width={64} />
          </FlexColumnCenter>
          <FlexColumn gap={4}>
            <FlexColumn style={{fontSize:'16px', fontWeight:'600'}}><Trans i18nKey={"메타마스크로 연결되면"}/></FlexColumn>
            <FlexColumn style={{padding:'12px', color:'#838B96', textAlign:'left', fontSize:'13px', fontWeight:'300', borderRadius:'16px', background:'#F9FAFB'}}><Trans i18nKey={"5초동안 기다려주세요. 간혹 메타마스크 반응이 느린 경우가 있습니다."}/></FlexColumn>
          </FlexColumn>
        <FlexColumn gap={4}>
          <FlexColumn style={{fontSize:'16px', fontWeight:'600'}}><Trans i18nKey={"현재 화면에서 반응이 없다면"}/></FlexColumn>
          <FlexColumn style={{padding:'12px', color:'#838B96' ,textAlign:'left', fontSize:'13px', fontWeight:'300', borderRadius:'16px', background:'#F9FAFB'}}><Trans i18nKey={"해당 화면이 계속되고 반응이 없는 경우 팝업을 닫고 새로고침 후 다시 시도해주세요 또 메타마스크의 메인넷과 지글의 메인넷이 일치하는지 확인해주세요."}/></FlexColumn>
        </FlexColumn>
      </FlexColumn>
      , ""
      ,{btnTxt:"확인 했어요",
        onClick: () => {
        }}
      , {}
  );
  // Popup.alertMobile(alertHtml, () => {}, '');
  if (token_address) {
    const token = await fetchToken({
      address: token_address as `0x${string}`,
    })

    const config = await prepareWriteContract({
      address: token_address as `0x${string}`,
      abi: abi,
      functionName: 'transfer',
      args: [
        to_address,
        parseUnits(send_token_amount, token.decimals)
      ]
    })
        .then( async (res)=>{
          console.log("res.",res);
          await writeContract(res).then((txHash) => {
            Popup.alertMobilelose();
            txIdResult(
                "success",
                txHash.hash,
                send_token_amount,
                account,
                to_address,
                mainnetId,
                coinId,
                linkMngId
            );
          })
          .catch((error: any) => {
            console.log("error.message", error.message);
            txIdResult("false", "실패 되었습니다.");
          });

        })

    .catch((error: any) => {
      console.log("error.message", error.message);
      txIdResult("false", "실패 되었습니다.");
    });

  } // ether send
  else {

    await sendTransaction({
        to: to_address as `0x${string}`,
        value: parseEther(send_token_amount),
      }).then((txHash: any) => {
        Popup.alertMobilelose();
        txIdResult(
            "success",
            txHash.hash,
            send_token_amount,
            account,
            to_address,
            mainnetId,
            coinId,
            linkMngId
        );

      })
      .catch((error: any) => {
        txIdResult("false", error.message);
      });
  }
};
// const chainList = async (signer: any, account: any, searchText: any) => {
//   let tokens: any[] = [];
//   console.log("chainList");
//   for (let i = 0; i < TOKENS.length; i++) {
//     //console.log(TOKENS[i].symbol+"====="+ TOKENS[i].address);
//     //swichNetwork(contract_address[i].chainId, web3);
//     console.log(TOKENS[i].symbol);
//     if (
//       TOKENS[i].symbol.toUpperCase().indexOf(searchText.toUpperCase()) > 0 ||
//       searchText === ""
//     ) {
//       let contract = new ethers.Contract(TOKENS[i].address, abi, signer);
//
//       getBalance(
//         account,
//         contract,
//         TOKENS[i].symbol,
//         TOKENS[i].address,
//         i
//       ).then(function (result) {
//         tokens.push(result);
//       });
//     }
//   }
//   console.log(tokens);
//   return tokens;
// };
const getCoinBalance = async (
  library: any,
  coinAddress: string,
  decimalVal: string
) => {
  const accounts = await library.listAccounts();
  const signer = await library.getSigner();
  // console.log('signer', signer);
  // console.log('accounts', accounts);
  // console.log('walletConfig', walletConfig);
  const getCode = await library.getCode(coinAddress);

  if (getCode === "0x") {
    console.log("getCode Error : " + coinAddress);
  }
  const contract = new ethers.Contract(coinAddress, abi, signer);
  const balance = await contract.balanceOf(accounts[0]);
  return ethers.utils.formatUnits(balance, decimalVal);
};

export interface ITokenProfile {
  name: string;
  symbol: string;
  decimal: number;
}

const checkTokenProfile = async (
    signer: any,
  chainId: number,
  coinAddress: string
) => {

  const abiContract = new ethers.Contract(coinAddress, abi, signer);

  const name = await abiContract.name();

  const symbol = await abiContract.symbol();
  const decimal = await abiContract.decimals();
  const params: ITokenProfile = {
    name: name,
    symbol: symbol,
    decimal: parseInt(decimal),
  };
  return params;
};
const transferRecipt = async (library: any, tx_id: string) => {
  await library.provider
    .request({ method: "eth_getTransactionReceipt", params: [tx_id] })
    .then((res: any) => {
      
    });
};
const getBalance = async(address: string|undefined, coinContract: string |undefined) =>{
  if(coinContract && address) {
    const balance =  await fetchBalance({
      address: address as `0x${string}`,
      token: coinContract as `0x${string}`,
    });
    return balance.formatted;
  }
}
const MetaMaskService = {
  // getWalletAccount,
  getBalance,
  // getWalletChainId,
  addToken,
  setSwitchNetwork,
  addNetWork,
  sendTransferToken,
  // chainList,
  getCoinBalance,
  checkTokenProfile,
  transUserCheck,
};

export default MetaMaskService;
