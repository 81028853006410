import {FlexColumn, FlexColumnCenter} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import React from "react";
import {useTranslation} from "react-i18next";


export default function Intro1() {
    const { t } = useTranslation();
    return (
        <FlexColumn gap={66}>
            <FlexColumnCenter gap={12}>
                <div className={styles.txt14_B01}>{t("Web3 지갑 포털")}</div>
                <FlexColumnCenter className={styles.txt32_600} gap={2}>
                    <div>{t("코인을 쉽게,")}</div>
                    <div>{t("간편 송금 서비스")}</div>
                </FlexColumnCenter>
            </FlexColumnCenter>
            <FlexColumnCenter>
                <img src={require('../../assets/imges/intro/introduction_img1.png')} width={264} />
            </FlexColumnCenter>
            <FlexColumnCenter>
                <img src={require('../../assets/imges/intro/icon_20.png')} width={20}/>
            </FlexColumnCenter>
        </FlexColumn>
    )
}