import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import {useDispatch, useSelector} from "react-redux";
import { CONNECT, DISCONNECT } from "../reducer/walletReducer";
import commonService from "../../services/common.service";
import { Toast } from "../common/popup/ToastAlert";
import {FlexBottomFree, FlexRowCenter, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import {useAccount} from "wagmi";
import {useTranslation} from "react-i18next";
import localJson from "../../lang/local.json";
import i18next from "i18next";

export default function SettingFrame() {
  const [mainNet, setMainNet] = useState<any>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [countryList, setCountryList] = useState<Array<{image:string, localType:string, localTxt:string}>>(localJson);
  const mainNetDetail = useSelector((state: any) => state.walletReducer.mainNetDetail);
  const { address, isConnected } = useAccount();
  const [dropMenu, setDropMenu] = useState({display:'none'});

  const onChangeLang = (localType:string) => {
    i18next.changeLanguage(localType);
    localStorage.setItem('language', localType);
  }
  useEffect(() => {
    const fetchMainnet = async () => {
      let data = await mainNetDetail;
      setMainNet(data);
    };

    if (mainNetDetail) {
      fetchMainnet();
    }
  }, [mainNetDetail]);
  return (
      <FlexBottomFree  style={{ top: '0px', backgroundColor:'rgba(242, 243, 246, 1)', borderRadius:'0px', zIndex:'100', height:'65px'}}>
      <FlexRowSpace>
        <FlexRowStart gap={4}>
          <div>
            <img
                src={require("../../assets/logos/mainLogo.png")}
                width={60}
                alt={"mainLogo"}
                onClick={()=>navigate("/service")}
            />
          </div>
          <div className={styles.dropdown}>
            <FlexRowCenter gap={9} className={styles.mainCountySelect} onClick={()=>{setDropMenu({display:'block'})}}>
              <div><img src={'../assets/image/country/'+ (localStorage.getItem("language") ? localStorage.getItem("language")?.toUpperCase() : 'KR') +'.png'} width={24} /></div>
            </FlexRowCenter>
            <div className={styles.dropdownContent} style={dropMenu}>
              {
                countryList.map((item, index) =>{
                  return (
                      <a key={index} onClick={()=>{ onChangeLang(item.localType); setDropMenu({display:'none'});}}><img src={item.image} width={28}/>  {item.localTxt}</a>
                  )
                })
              }
            </div>
          </div>
        </FlexRowStart>
        <FlexRowEnd style={{gap: "8px"}}>

          {(address && mainNet?.symbolImgFileId) && (
              <div className={styles.mainCryptoBadge} onClick={()=>navigate("/tokenList")}>
                <img
                    src={mainNet.symbolImgFileId}
                    height={20}
                    alt={"symbol"}
                />
                <div className={styles.homeTitle13}>{t(mainNet.displayName)}</div>
              </div>
          )}

          <div>
            <img
                src={require("../../assets/icons/setting.png")}
                width={28}
                alt={"setting"}
                onClick={() => navigate("/setting")}
            />
          </div>
          <div>
            <img
                src={require("../../assets/icons/alert.png")}
                width={28}
                alt={"alert"}
                onClick={() => {
                  Toast(t("준비중 입니다."))
                }}
            />
          </div>
        </FlexRowEnd>
      </FlexRowSpace>
      </FlexBottomFree>
  );
}
