import React, {ReactHTMLElement, useEffect, useRef, useState} from "react";
import PageTitle, {
    FlexBottom, FlexBottom86,
    FlexColumn, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper, ZSelect
} from "../common/container/PageContainer";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import PhoneUpdatePopup from "./PhoneUpdatePopup";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {IMainnetInfo} from "../../common/dto/MainnetInfo";
import MainnetList from "../common/MainnetList";
import MetaMaskService from "../../services/metamask.service";
import CoinList from "../common/CoinList";
import TransferFriend2 from "../transferCoin/TransferFriend2";
import TransferPhone2 from "../transferCoin/TransferPhone2";
import CommonService from "../../services/common.service";
import PhoneService from "../../services/phone.service";
import PopupService from "../../services/popup.service";
import TransferEnd from "../transferCoin/TransferEnd";
import {useWalletEthersPorvider} from "../../common/js/ethersProviderHook";
import TransferMessage from "../transferCoin/TransferMessage";


interface IPhoneTrans {
    show: boolean;
    setShow: any;
    phoneTransUser:any;
}
interface ITransferInfo {
    pageTitle:string;
    pageType:string;
    linkType:string;
    linkName:string;
    coinId:string;
    coinTypeCode: string,
    coinContAddr: string,
    coinDecimalVal: string,
    coinSymbolName:string;
    coinSymbolImgFileId:string;
    mainnetId:string;
    mainnetLinkName:string;
    sendUserId:string;
    sendUserName:string;
    sendWalletAddress:string;
    countryNum:string,
    phoneNum:string,
    zigleFee:number;
    amount:number;
    safeOn:boolean;
    confirmTxt:string;
    txId:string;
    message:string;
}
function PhoneTransPopup(props:IPhoneTrans) {

    const navigate = useNavigate();
    const { t } = useTranslation();

    
    const chainId = useSelector((state:any) => state.walletReducer.chainId);
    const disconnect = useSelector((state:any) => state.walletReducer.disconnect);
    const [useBottom, setUseBottom] = useState(true);

    const zigleWalletAddress = process.env.REACT_APP_WALLET_ADDRESS;

    const provider = useWalletEthersPorvider();
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const [depth, setDepth] = useState(0);
    const [mainnetInfo, setMainnetInfo] = useState<IMainnetInfo>();
    const [transferInfo, setTransferInfo] = useState<ITransferInfo>({
        pageTitle:'',
        pageType:'phoneTransfer',
        linkType:'',
        linkName:t('송금 방식'),
        coinId: '',
        coinTypeCode: '',
        coinContAddr: '',
        coinDecimalVal: '',
        coinSymbolName: t('송금 코인'),
        coinSymbolImgFileId:require('../../assets/icons/question2.png'),
        mainnetId : '',
        mainnetLinkName: t('메인넷'),
        sendUserId : '',
        sendUserName : '',
        sendWalletAddress:'',
        countryNum:'',
        phoneNum:'',
        zigleFee:0,
        amount : 0,
        safeOn: false,
        confirmTxt: '',
        txId : '',
        message : ''
    })

    const txIdResult = (result:any,
                        resultMessage:any,
                        send_token_amount:any,
                        account:any,
                        to_address:any,
                        mainnetId:any,
                        coinId:any,
                        linkMngId:any)=>{
        if(result=="success")
        {
            if(transferInfo.linkType === 'safeTrans')
            {
                CommonService.transactionInsert(resultMessage, send_token_amount, account, to_address, mainnetId, coinId, linkMngId, transferInfo.sendUserId, transferInfo.message).then(
                    (res) => {
                        setTransferInfo(
                            {...transferInfo,
                                txId: resultMessage
                            }
                        );
                        setDepth(10);
                    });
            }else{
                PhoneService.phoneDeposit(resultMessage, account, to_address, transferInfo).then(
                    (res) => {
                        setTransferInfo(
                            {...transferInfo,
                                confirmTxt:res.data.confirmTxt,
                                txId: resultMessage
                            }
                        );
                        setDepth(10);
                    });
            }


        } else {
            PopupService.newPopup(t("확인 필요")
                , t("메타마스크에서 트랜잭션을 승인해 주세요.")
                , ""
                , {
                    btnTxt: t("확인 했어요"),
                    onClick: () => {
                    }
                }
                , {}
            );
        }
    }
    const handleOnClick = (selectItem: any) => {
        if (depth === 0) {
            if (chainId.toString() === selectItem.chainId.toString()) {
                setTransferInfo({
                    ...transferInfo,
                    mainnetId: selectItem.mainnetId,
                    mainnetLinkName: selectItem.linkName,
                    zigleFee: selectItem.zigleFee
                });
                setMainnetInfo(selectItem);
                setDepth(1);
                setUseBottom(true);
            } else {
                MetaMaskService.setSwitchNetwork(provider, parseInt(selectItem.chainId), selectItem.mainnetName, selectItem.symbolName, selectItem.decimalVal, selectItem.rpcUrl, selectItem.explorerUrl, '').then(
                    (response) => {
                        if (!response.status) {
                        }
                    }
                );
            }
        }else if(depth === 1){
            setTransferInfo({...transferInfo,
                coinId:selectItem.coinId,
                coinTypeCode: selectItem.typeCode,
                coinContAddr: selectItem.contAddr,
                coinDecimalVal: selectItem.decimalVal,
                coinSymbolName:selectItem.symbolName,
                coinSymbolImgFileId:selectItem.symbolImgFileId
            });
            setDepth(4);
            setUseBottom(false);
        }
    }

    useEffect(()=>{

        if(props.phoneTransUser.linkType)
        {

            if(props.phoneTransUser.linkType ==='hpTrans')
            {
                setTransferInfo(
                    {
                        ...transferInfo,
                        pageTitle:props.phoneTransUser.sendUserName+'에게 '+props.phoneTransUser.linkName,
                        linkType:props.phoneTransUser.linkType,
                        linkName:props.phoneTransUser.linkName,
                        sendUserName:props.phoneTransUser.sendUserName,
                        countryNum:props.phoneTransUser.countryNum,
                        phoneNum:props.phoneTransUser.phoneNum,
                        sendWalletAddress: zigleWalletAddress!
                    }
                );
            }else{
                setTransferInfo(
                    {
                        ...transferInfo,
                        pageTitle:props.phoneTransUser.sendUserName+'에게 '+props.phoneTransUser.linkName,
                        linkType:props.phoneTransUser.linkType,
                        linkName:props.phoneTransUser.linkName,
                        sendUserId : props.phoneTransUser.sendUserId,
                        sendUserName : props.phoneTransUser.sendUserName,
                    }
                );
            }
        }
    },[props.phoneTransUser.linkType]);

    return (
        <>
            {
                props.show &&
                (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle
                                    textTitle={transferInfo.pageTitle}
                                    onClickLeft={() => {
                                        props.setShow(false);
                                    }}
                                />
                                <PageContent>
                                    { depth === 0 && <MainnetList handleOnClick={handleOnClick} /> }
                                    { depth === 1 && <CoinList itemInfo={transferInfo} handleOnClick={handleOnClick} /> }
                                    { (transferInfo.linkType === 'safeTrans' && depth === 4) && (<TransferFriend2  setDepth={setDepth} mainnetInfo={mainnetInfo} transferInfo={transferInfo} setTransferInfo={setTransferInfo} handleOnClick={handleOnClick} txIdResult={txIdResult}/>) }
                                    { (transferInfo.linkType === 'hpTrans' && depth === 4) && (<TransferPhone2  setDepth={setDepth} mainnetInfo={mainnetInfo} transferInfo={transferInfo} setTransferInfo={setTransferInfo} handleOnClick={handleOnClick} txIdResult={txIdResult}/>) }

                                    {(depth === 9) && (
                                        <TransferMessage mainnetInfo={mainnetInfo} transferInfo={transferInfo}
                                                         setTransferInfo={setTransferInfo} handleOnClick={handleOnClick}
                                                         txIdResult={txIdResult}/>)}

                                    { depth ===10 && <TransferEnd transferInfo={transferInfo} setTransferInfo={setTransferInfo}  setDepth={setDepth}/>}
                                </PageContent>
                                {
                                    useBottom && (
                                        <FlexBottom86  style={{padding:'24px 20px', boxShadow:'inset 0 0.6px 1px 0 #dbdee6', backgroundColor:'#ffffff'}}>
                                            <FlexColumn>
                                                <FlexRowSpace>
                                                    <FlexRowStart>
                                                        <div style={{paddingRight:'12px'}}>
                                                            <img src={transferInfo.coinSymbolImgFileId} alt="bgIcon" width={38} />
                                                        </div>
                                                        <FlexColumn gap={3}>
                                                            <div className={transferInfo.coinId ? styles.txt16_B02 : styles.txt16_W03}>{transferInfo.coinSymbolName}</div>
                                                            <FlexRowStart className={styles.txt12_B05}>
                                                                <div style={{marginRight:'8px', width:'4px', height:'4px', backgroundColor:'#66bf9f'}}></div>
                                                                <div className={transferInfo.mainnetId ? styles.txt12_B04 : styles.txt12_W03}>{transferInfo.mainnetLinkName}</div>
                                                            </FlexRowStart>
                                                        </FlexColumn>
                                                    </FlexRowStart>
                                                    <FlexRowEnd>
                                                        {
                                                            transferInfo.linkType ?
                                                                (
                                                                    transferInfo.linkType === 'safeTrans' ?
                                                                        (<div style={{backgroundColor:'#ebf2ff', color:'#3a71ff', borderRadius:'100px', padding:'9px 20px'}} className={styles.txt15_B02}>{transferInfo.linkName}</div>)
                                                                        :
                                                                        (
                                                                            transferInfo.linkType === 'hpTrans' ?
                                                                                (<div style={{backgroundColor:'#e2fffd', color:'#53cec7', borderRadius:'100px', padding:'9px 20px'}}  className={styles.txt15_B02}>{transferInfo.linkName}</div>)
                                                                                :
                                                                                (<div style={{backgroundColor:'#f2f4f6', color:'#53cec7', borderRadius:'100px', padding:'9px 20px'}}  className={styles.txt15_B02}>{transferInfo.linkName}</div>)
                                                                        )
                                                                )
                                                                :
                                                                (<div className={styles.txt15_W03}>{transferInfo.linkName}</div>)
                                                        }
                                                    </FlexRowEnd>
                                                </FlexRowSpace>
                                            </FlexColumn>
                                        </FlexBottom86>
                                    )
                                }
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>
    )
}
export default PhoneTransPopup;