import React from "react";
import styles from "../../common/css/zigle.module.scss";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function LoginFrame() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className={styles.mainBox}>
      <div className={styles.titleContainer}>
        <div
          className={styles.txtTitle18}
          onClick={() => {
            navigate("/walletLogin");
          }}
          style={{ width: "100%" }}
        >
          {t("로그인을 해주세요")}
        </div>
        <div>
          <img
            src={require("../../assets/icons/btnArrow.png")}
            height={28}
            alt={"arrow"}
            onClick={() => navigate("/walletLogin")}
          />
        </div>
      </div>
    </div>
  );
}
