import React, {useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import {TextField} from "@material-ui/core";
import {FlexBottom, FlexColumn} from "../common/container/PageContainer";
import BottomButton from "../common/button/BottomButton";
import {useForm} from "react-hook-form";
import {IAirdropSet} from "../../pages/AirdropFi";
import {useTranslation} from "react-i18next";

interface AirdropFiStep {
    airdropInfo: IAirdropSet;
    setAirdropInfo: any;
    setDepth: any;
}

export default function AirdropFiStep0({airdropInfo, setAirdropInfo, setDepth}: AirdropFiStep) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [btnActive, setBtnActive] = useState(false);
    const inputRef = useRef<HTMLFormElement>(null);

    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
    } = useForm();
    const onSubmit = (data: any) => {
        setAirdropInfo({...airdropInfo, pageTitle:data.airdropName, airdropName :data.airdropName});
        setDepth(1);
    }
    return (
        <FlexColumn>
            <FlexColumn>
                <form
                    ref={inputRef}
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ width: "100%" }}
                >
                    <textarea
                        style={{border:'none'}}
                        className={styles.textAreaBox}
                        placeholder={t("선착순 에어드랍 링크의\n이름을 입력해주세요")}
                        rows={2}
                        maxLength={20}
                        defaultValue={airdropInfo.pageTitle}
                        {...register("airdropName", {
                            required: t("선착순 에어드랍 링크의\n이름을 입력해주세요"),
                            maxLength:20,
                            onChange: (el) => {
                                if(el.target.value){
                                    setBtnActive(true)
                                }else{
                                    setBtnActive(false)
                                }
                            }
                        })}
                    />
                </form>
            </FlexColumn>
            <FlexBottom>
                <BottomButton
                    isActive={btnActive}
                    backgroundColor={"#3a71ff"}
                    textColor={"white"}
                    // border={"solid 1px #dbdee6"}
                    onClick={handleSubmit(onSubmit)}
                    text={t("다음")}
                />
            </FlexBottom>
        </FlexColumn>
    )
}