import React, {ReactHTMLElement, useEffect, useRef, useState} from "react";
import PageTitle, {
    FlexBottom, FlexBottom86,
    FlexColumn, FlexColumnCenter, FlexRowCenter, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper, ToggleBtn, ToggleCircle, ZSelect
} from "../common/container/PageContainer";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import BottomButton from "../common/button/BottomButton";
import PopupService from "../../services/popup.service";
import FavService from "../../services/fav.service";
import ChatService from "../../services/chat.service";
import {Toast} from "../common/popup/ToastAlert";
import {Bar} from "../../pages/MyLink";
import {ellipseAddress} from "../../common/js/utilities";
import {CopyToClipboard} from "react-copy-to-clipboard";
import CommonService from "../../services/common.service";
import {IMainnetInfo} from "../../common/dto/MainnetInfo";
import ChatCoinSelect from "./ChatCoinSelect";
import {userInfo} from "os";

interface IChatSetting {
    show: boolean;
    setShow: any;
    userInfo: any;
    talkPlus: any;
    userCnt:any;
    chatId:string;
    setUserInfo:any;
    handleOnClick?: (item: string)=> void;
}
export default function ChatSetting(props:IChatSetting){
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [toggle, setToggle] = useState(false);

    const [coinSelect, setCoinSelect] = useState(false);

    const userId: string = useSelector((state: any) => state.userReduce.userId);
    const mainNetDetail = useSelector((state: any) => state.walletReducer.mainNetDetail);
    const [tokenCheck , setTokenCheck] = useState({
        mainnetId : '',
        mainnetSymbolName : '',
        mainnetLinkName : '',
        coinId : '',
        coinSymbolName : '',
        coinSymbolImgFileId:''
    })


    const {
        register,
        resetField,
        setError,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const onSubmit = (data: any) => {

        if(toggle){
            if(data.checkAmount <=0){
                setError("checkAmount", {type:'custom', message:t('0개 이상 설정해 주세요.')});
                return false;
            }
        }else{
            data.checkAmount = 0;
        }
        ChatService.setTokenCheck(props.chatId, tokenCheck.mainnetId, tokenCheck.coinId, data.checkAmount, toggle ? "Y" : "N").then((res:any)=>{
            res.data[0].isMyFriend = "Y";
            res.data[0].checkUse = toggle ? "Y" : "N";
            res.data[0].checkCnt = data.checkAmount;
            props.setUserInfo(res.data[0]);
            Toast(t("설정 되었습니다."));
            props.setShow(false);
        });
    }
    useEffect(() => {
        async function fetchMainnetDetail() {


            if(props.userInfo.coinId){
                if(props.userInfo.checkUse === "Y"){
                    setToggle(true);
                }else{
                    setToggle(false);
                }
                setTokenCheck({...tokenCheck, mainnetId: props.userInfo.mainnetId, mainnetSymbolName:props.userInfo.mainnetSymbolName, mainnetLinkName:props.userInfo.mainnetLinkName , coinId:props.userInfo.coinId, coinSymbolName: props.userInfo.coinSymbolName, coinSymbolImgFileId: props.userInfo.coinSymbolImgFileId});
            }else{
                let setMainNetDetail = await mainNetDetail;
                setTokenCheck({...tokenCheck, mainnetId: setMainNetDetail.mainnetId, mainnetSymbolName:setMainNetDetail.symbolName, mainnetLinkName: setMainNetDetail.linkName, coinId:'', coinSymbolName: setMainNetDetail.symbolName, coinSymbolImgFileId: setMainNetDetail.symbolImgFileId});
            }

        }
        if(props.show === true){
            console.log("userRef ", props.userInfo)
            fetchMainnetDetail();
        }
    },[props.show]);
    return (
        <>
            {
                props.show &&
                (

                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <ChatCoinSelect show={coinSelect} setShow={setCoinSelect} itemInfo={tokenCheck} setItemInfo={setTokenCheck}/>
                                <FlexColumn className={styles.pageTitle} style={{ height: "56px" }}>
                                    <FlexRowSpace>
                                        <FlexRowStart style={{width:'30%'}}>
                                            <img
                                                src={require("../../assets/imges/back_arrow.png")}
                                                width={28}
                                                alt={"back_arrow"}
                                                style={{ marginLeft: "-8px" }}
                                                onClick={() => {
                                                    props.setShow(false);
                                                }}
                                            />
                                        </FlexRowStart>
                                        {
                                            props.userInfo.userId === userId && (
                                                <FlexRowEnd  style={{width:'30%'}} className={styles.txt15_PointB}>
                                                    <span onClick={handleSubmit(onSubmit)}>{t("저장")}</span>
                                                </FlexRowEnd>
                                            )
                                        }
                                    </FlexRowSpace>
                                </FlexColumn>
                                <PageContent>
                                    <FlexColumn gap={16}>
                                        <FlexColumn gap={20}>
                                            <FlexColumnCenter  gap={8}>
                                                <img
                                                    src={props.userInfo.profileImageFileId}
                                                    width={72}
                                                    alt={"profile image"}
                                                    style={{borderRadius:'48px', aspectRatio:'1', objectFit:'cover'}}
                                                />
                                                <FlexColumnCenter>
                                                    <FlexRowCenter gap={2}>
                                                        <div className={styles.txt18}>{props.userInfo.doname}</div>
                                                        {
                                                            props.userInfo.checkUse === "Y" && (
                                                                <div className={styles.txt11_PointB} style={{padding:'4px 8px', borderRadius:'100px', background:'#EBF2FF'}}>{t("홀더룸")}</div>
                                                            )
                                                        }
                                                    </FlexRowCenter>
                                                    <div className={styles.txt14_B05}>{t("{0} 멤버", {0:props.userCnt})}</div>
                                                </FlexColumnCenter>
                                            </FlexColumnCenter>
                                            <FlexColumn gap={8}>
                                                <div className={styles.txt14_300_B04}>{t("링크공유")}</div>
                                                <FlexRowStart gap={6}>
                                                       <div className={styles.txt13_B04} style={{width:'258px', padding:'10px 16px', borderRadius:'8px', border:'solid 1px #f2f4f8' }}>{window.location.protocol + "//" + window.location.host + "/u/" + props.userInfo.doname}</div>
                                                    <CopyToClipboard
                                                        text={window.location.protocol + "//" + window.location.host + "/u/" + props.userInfo.doname}
                                                        onCopy={() => Toast(t("주소를 복사 했습니다."))}
                                                    >
                                                       <div className={styles.txt13_500_B04} style={{width:'26px', padding:'10px 16px', borderRadius:'8px', border:'solid 1px #f2f4f8' }}>
                                                           {t("복사")}
                                                       </div>
                                                    </CopyToClipboard>
                                                </FlexRowStart>
                                            </FlexColumn>
                                        </FlexColumn>
                                        {
                                            props.userInfo.userId === userId && (
                                                <FlexColumn gap={24}>
                                                    <Bar/>
                                                    <FlexRowSpace>
                                                        <FlexRowStart className={styles.txt14_300_B04}>{t("채팅룸 타입")}</FlexRowStart>
                                                        <FlexRowEnd className={styles.txt15_B01} gap={2}>
                                                            {t("홀더룸")}
                                                                <ToggleBtn onClick={()=>{
                                                                    setToggle((prev) => !prev);
                                                                }} toggle={toggle}>
                                                                    <ToggleCircle toggle={toggle} />
                                                                </ToggleBtn>
                                                        </FlexRowEnd>
                                                    </FlexRowSpace>
                                                    <FlexColumn gap={8}>
                                                        <div className={styles.txt14_300_B04}>{t("지정 코인")}</div>
                                                        <FlexRowSpace>
                                                            <FlexRowStart style={{width:'30%'}} gap={8} onClick={()=>{
                                                                if(toggle){
                                                                    setCoinSelect(true);
                                                                }
                                                            }}>
                                                                <img
                                                                    src={tokenCheck.coinSymbolImgFileId}
                                                                    width={28}
                                                                    alt={"coin image"}
                                                                    style={{borderRadius:'48px', aspectRatio:'1', objectFit:'cover'}}
                                                                />
                                                                <div className={styles.txt18}>
                                                                    {tokenCheck.coinSymbolName}
                                                                    <img src={require('../../assets/icons/arrow_select.png')} width={20}/>
                                                                </div>
                                                            </FlexRowStart>
                                                            <FlexRowEnd style={{width:'70%'}}>
                                                                <input
                                                                    type={"number"}
                                                                    style={{textAlign:'right'}}
                                                                    className={styles.inputText_44_noborder}
                                                                    defaultValue={props.userInfo.checkCnt}
                                                                    placeholder={t('최소 참여 수량')}
                                                                    disabled={!toggle}
                                                                    {...register("checkAmount", {
                                                                    })}
                                                                />
                                                            </FlexRowEnd>
                                                        </FlexRowSpace>
                                                        <FlexRowEnd>
                                                            {
                                                                errors?.checkAmount && (
                                                                    <div className={styles.txt13_red} style={{ marginTop: "4px", marginBottom: "4px" }}>
                                                                        {errors?.checkAmount?.message?.toString()}
                                                                    </div>
                                                                )
                                                            }
                                                        </FlexRowEnd>
                                                    </FlexColumn>
                                                </FlexColumn>
                                            )
                                        }
                                        <Bar />
                                        <FlexColumn gap={8}>
                                            <div className={styles.txt14_300_B04}>{t("방장")}</div>
                                            <FlexRowSpace>
                                                <FlexRowStart gap={8}>
                                                    <img
                                                        src={props.userInfo.profileImageFileId}
                                                        width={38}
                                                        alt={"profile image"}
                                                        style={{borderRadius:'48px', aspectRatio:'1', objectFit:'cover'}}
                                                    />
                                                    <div className={styles.txt15_B02}>{props.userInfo.doname}</div>
                                                </FlexRowStart>
                                                <FlexRowEnd>
                                                    <div  className={styles.txt12_B04} style={{background:'#F2F4F6', lineHeight:'130%', borderRadius:'100px', padding:'3px 4px 3px 6px'}}>
                                                        {ellipseAddress(props.userInfo.mainWalletAddress, 5)}
                                                        <CopyToClipboard
                                                            text={props.userInfo.mainWalletAddress}
                                                            onCopy={() => Toast(t("주소를 복사 했습니다."))}
                                                        >
                                                            <img src={require("../../assets/icons/copy.png")} width={16} alt={"copy"} />
                                                        </CopyToClipboard>
                                                    </div>
                                                </FlexRowEnd>
                                            </FlexRowSpace>
                                        </FlexColumn>
                                    </FlexColumn>
                                </PageContent>
                                {
                                    props.userInfo.userId !== userId && (
                                        <FlexBottom>
                                            <BottomButton
                                                isActive={true}
                                                backgroundColor={"#111111"}
                                                textColor={"white"}
                                                // border={"solid 1px #dbdee6"}
                                                onClick={()=>{
                                                    PopupService.chatPopup(
                                                        <FlexRowSpace className={styles.txt16_B04} style={{padding:'8px 20px'}} onClick={()=>{
                                                            PopupService.newPopup(t("룸에서 나갈까요?")
                                                                ,t("룸에서 나가면<br/>자동으로 언프렌딩 됩니다.")
                                                                , ""
                                                                ,{btnTxt:t("나갈게요"),
                                                                    onClick: () => {
                                                                        FavService.friendDelete(props.userInfo.userId).then(async (response) => {
                                                                            ChatService.joinChatRoom(props.userInfo.userId+"_chat", "Y");
                                                                            await props.talkPlus.leaveChannel({channelId: props.userInfo.userId+'_chat'});
                                                                            Toast(t('친구취소가 완료 되었습니다.'));
                                                                            props.setShow(false);
                                                                            navigate(-1);
                                                                        });
                                                                    }}
                                                                , {
                                                                    btnTxt:t("취소"),
                                                                    onClick: () => {}
                                                                }
                                                            );
                                                        }}>
                                                            <FlexRowStart>{t("나가기")}</FlexRowStart>
                                                            <FlexRowEnd><img src={require('../../assets/imges/setting/icon_quit.png')}  width={20}/></FlexRowEnd>
                                                        </FlexRowSpace>
                                                    )
                                                }}
                                                text={t("방나가기")}
                                            />
                                        </FlexBottom>
                                    )
                                }

                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>
    )
}