import React, {useEffect, useRef, useState} from "react";
import PageTitle, {
  Circle,
  FC,
  FlexColumn,
  FlexColumSpace,
  FlexRowAround,
  FlexRowCenter,
  FlexRowEnd,
  FlexRowSpace,
  FlexRowStart,
  GrayBoxW01,
  PageContent,
  PageRootContainer,
  ZBadge,
} from "../components/common/container/PageContainer";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "../common/css/zigle.module.scss";
import IconKakao from "../assets/imges/login/iconKakao.png";
import IconGoogle from "../assets/imges/login/iconGoogle.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import IconCopy from "../assets/icons/copy.png";
import styled from "styled-components";
import Button56 from "../components/common/button/Button56";
import CommonService from "../services/common.service";
import CoinItem from "../components/profile/CoinItem";
import PopupService from "../services/popup.service";
import SndHis from "../components/profile/SndHis";
import RcvHis from "../components/profile/RcvHis";
import {Toast, ToastError} from "../components/common/popup/ToastAlert";
import LinkPopup from "../components/myLink/LinkPopup";
import LinkWrapper from "../components/myLink/LinkWrapper";
import {ZButton} from "../components/common/button/Button34";
import WalletPopup from "../components/profile/WalletPopup";
import {decimalFormat, ellipseAddress} from "../common/js/utilities";
import {useAccount} from "wagmi";
import {useScript} from "../common/js/hooks";
import {useTranslation} from "react-i18next";
import UserFriendList from "../components/profile/UserFriendList";
import {IChatView} from "./Chat";

export const Box = styled(FC)``;

export const Bar = styled.div`
  background-color: #f2f4f6;
  height: 16px;
  width: calc(100% + 40px);
  margin-left: -20px;
`;

export const Bar1px = styled.div`
  background-color: #f2f4f6;
  height: 1px;
  width: calc(100% - 40px);
  //margin-left: -20px;
`;

export default function Profile({talkPlus}:IChatView) {
  const [load, setLoad] = useState(false);
  const [tabNum, setTabNum] = useState(0);
  const [mainNet, setMainNet] = useState<any>({});
  const { address, isConnected } = useAccount();

  const userId: string = useSelector((state: any) => state.userReduce.userId);
  const profileImageFileId: string = useSelector((state: any) => state.userReduce.profileImageFileId);
  const doname: string = useSelector((state: any) => state.userReduce.doname);
  const introduce: string = useSelector((state: any) => state.userReduce.introduce);
  const email: string = useSelector((state: any) => state.userReduce.email);
  const socialCode: string = useSelector((state: any) => state.userReduce.socialCode);
  const cryptoZigle = useSelector((state: any) => state.userReduce.cryptoZigle);
  const ziglePoint = useSelector((state: any) => state.userReduce.ziglePoint);

  const mainNetDetail = useSelector((state: any) => state.walletReducer.mainNetDetail);

  const miningAmount = useSelector((state: any) => state.userReduce.miningAmount);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [blockSize] = useState(5);
  const [searchTxt, setSearchTxt] = useState('');
  const [showTokenPopup, setShowTokenPopup] = useState(false);
  const [itemInfo, setItemInfo] = useState('');
  const [reLoad, setReLoad] = useState(false);

  const [walletList, setWalletList] = useState<any[]>([]);
  const [showWalletPopup, setShowWalletPopup] = useState(false);

  const [showFriend, setShowFriend] = useState(false);
  const [friendView, setFriendView] = useState("");

  const [friendCnt, setFriendCnt] = useState<{friendCnt:number, friendingCnt:number, chatRoomCnt:number, myRoomUser:number}>({
    friendCnt:0,
    friendingCnt:0,
    chatRoomCnt:0,
    myRoomUser:0
  });

  const onClickLinkItem = (linkInfo:any)=>{
    setItemInfo(linkInfo);
    setShowTokenPopup(true);
  }

  useEffect(() => {
    const fetchMainnet = async () => {
      let data = await mainNetDetail;
      setMainNet(data);
      setLoad(true);
    };
    if (mainNetDetail) {
      fetchMainnet();
    }
  }, [mainNetDetail]);

  useEffect(() => {
    const fetchUserId = async () => {
      let setUserId = await userId;
      const response = await CommonService.getWalletLoginAddress(setUserId);
      setWalletList([...walletList, ...response.data]);
      CommonService.friendCnt(setUserId).then(res=>{
        setFriendCnt(res.data)
      });
    };

    if (userId) {
      fetchUserId();
    }
  }, [userId]);

  return (
    <PageRootContainer>
      <LinkPopup setShow={setShowTokenPopup} show={showTokenPopup} itemInfo={itemInfo} setReLoad={setReLoad}/>
      <WalletPopup setShow={setShowWalletPopup} show={showWalletPopup} walletList={walletList} setWalletList={setWalletList} address={address}/>
      <UserFriendList show={showFriend} setShow={setShowFriend} modeType={friendView} doname={doname} userId={userId} friendCnt={friendCnt}/>
      <PageTitle
        onClickLeft={() => {
          navigate("/");
        }}
        textTitle={t("내 프로필")}
        rightSrc={require('../assets/imges/profile/profile_link.png')}
        onClickRight={() => {
          if(doname) {
            PopupService.snsLinkMobile(window.location.protocol + "//" + window.location.host + "/u/" + doname);
          }else{
            ToastError(t('닉네임을 입력하셔야 사용 가능합니다.'))
          }
        }}
      />
      <PageContent>
        {/**/}
        <FlexColumn>
          <Box gap={24} style={{ paddingBottom: "24px" }}>
            <FlexColumn gap={10}>
              <FlexRowSpace>
                <FlexRowStart gap={8}>
                    <img
                      src={profileImageFileId}
                      width={50}
                      alt={"profile image"}
                      style={{borderRadius:'100px', aspectRatio:'1', objectFit:'cover'}}
                    />
                </FlexRowStart>
                <FlexRowEnd gap={4}>
                  <FlexRowCenter className={styles.txt13_W} style={{borderRadius:'100px', width:'102px', height:'24px', backgroundColor:'#3A71FF'}} gap={4}>
                    <FlexRowCenter>{t("채팅방")}</FlexRowCenter><FlexRowCenter style={{width:'53px', height:'24px', fontSize:'12px', backgroundColor:'#759CFF', padding:'2px 8px 2px 6px', borderRadius:'100px'}}><img src={require('../assets/imges/profile/eye.png')} width={20} />{friendCnt.myRoomUser}</FlexRowCenter>
                  </FlexRowCenter>
                </FlexRowEnd>
              </FlexRowSpace>
              <FlexColumn gap={8}>
                <FlexColumn gap={3}>
                  <div className={styles.txt20_B01}>
                    {doname ?? t("닉네임이 없습니다.")}
                    <img
                        src={require("../assets/imges/profile/modify.png")}
                        width={28}
                        alt={"modify"}
                        onClick={()=>navigate("/modifyProfile")}
                    />
                  </div>
                  {introduce && (
                      <div className={styles.txt14_B05}>
                        {introduce}
                      </div>
                  )}
                </FlexColumn>
                <FlexRowStart className={styles.txt14_B04} gap={8}>
                  <div onClick={()=>{}}>{friendCnt.chatRoomCnt} {t("룸")}</div>
                  <div style={{height:'12px', width:'1px' , backgroundColor:'#E3E5ED'}}/>
                  <div onClick={()=>{setShowFriend(true); setFriendView('friend');}}>{decimalFormat(friendCnt.friendCnt,0)} {t("프렌더")}</div>
                  <div style={{height:'12px', width:'1px' , backgroundColor:'#E3E5ED'}}/>
                  <div onClick={()=>{setShowFriend(true); setFriendView('friending');}}>{decimalFormat(friendCnt.friendingCnt,0)} {t("프렌딩")}</div>
                </FlexRowStart>
              </FlexColumn>
            </FlexColumn>
            <GrayBoxW01 style={{ height: "40px" }}>
              <FlexRowCenter gap={4} onClick={()=>{ setShowWalletPopup(true); }}>
                <div className={styles.txt11_W} style={{padding:'4px 8px', borderRadius:'100px', backgroundColor:'#111111'}}>대표지갑</div>
                <div className={styles.txt13_B04}>{ellipseAddress(walletList.find(obj=>{return obj.mainUse ==="Y"})?.walletAddress, 8)}</div>

                  {
                      walletList.length > 0 && (<div><img src={require('../assets/icons/arrow_select.png')} width={20}/></div>)
                  }
                  {/*<CopyToClipboard*/}
                  {/*  text={address}*/}
                  {/*  onCopy={() => Toast("주소를 복사 했습니다.")}*/}
                  {/*>*/}
                  {/*  <img src={IconCopy} width={20} alt={"copy"} />*/}
                  {/*</CopyToClipboard>*/}
              </FlexRowCenter>
            </GrayBoxW01>

            <FlexRowCenter gap={8}>
              <div className={styles.profile_btn_white} onClick={()=>navigate("/myLink")}><div className={styles.txt16}>{t('받기')}</div></div>
              <div className={styles.profile_btn_black} onClick={()=>navigate("/transfer")}><div className={styles.txt16} style={{color:'#ffffff'}}>{t('송금')}</div></div>
            </FlexRowCenter>

            <FlexColumn gap={16}>
              <FlexRowSpace style={{ height: "20px" }} onClick={()=>navigate("/myPoint")}>
                <FlexRowStart gap={4}>
                  <Circle>P</Circle>
                  <div className={styles.txt13_B04}>{t('결제 포인트')}</div>
                </FlexRowStart>
                <FlexRowEnd>
                  <div className={styles.txt14_B05}>
                    {decimalFormat(ziglePoint)} P
                  </div>
                  <img
                    src={require("../assets/icons/btnArrow1.png")}
                    height={20}
                    alt={"C"}
                  />
                </FlexRowEnd>
              </FlexRowSpace>
              <FlexRowSpace style={{ height: "20px" }} onClick={()=>{navigate("/mining")}}>
                <FlexRowStart gap={4}>
                  <Circle bgColor={"#3a71ff"}>Z</Circle>
                  <div className={styles.txt13_B04}>{t('내 지글(ZIG)')}</div>
                </FlexRowStart>
                <FlexRowEnd>
                  <div className={styles.txt14_B05}>
                    {decimalFormat(miningAmount)} ZIG
                  </div>
                  <img
                    src={require("../assets/icons/btnArrow1.png")}
                    height={20}
                    alt={"C"}
                  />
                </FlexRowEnd>
              </FlexRowSpace>
            </FlexColumn>
          </Box>
          <Bar/>
          <Box style={{ padding: "32px 0" }}>
            <FlexRowSpace
              style={{ marginBottom: "16px" }}
              onClick={() => navigate("/myLinkList")}
            >
              <div className={styles.txt18_500_B02}>{t('도네이션 링크')}</div>
              <img
                src={require("../assets/icons/btnArrow1.png")}
                height={28}
                alt={"C"}
              />
            </FlexRowSpace>
            {/*도네이션링크 목록*/}
            <FlexColumn gap={8}>
              <LinkWrapper userId={userId} blockSize={blockSize} orderBy={'DESC'} limit={true} reLoad={reLoad} searchTxt={searchTxt} onClickLinkItem={onClickLinkItem}/>
            </FlexColumn>
          </Box>
          <Bar></Bar>
          <Box style={{ padding: "32px 0" }}>
            {/* 송금 / 받기 TAB*/}
            <FlexRowAround>
              <div
                className={
                  tabNum === 0 ? styles.topTabActive : styles.topTabDefault
                }
                onClick={() => setTabNum(0)}
              >
                {t('송금')}
              </div>
              <div
                className={
                  tabNum === 1 ? styles.topTabActive : styles.topTabDefault
                }
                onClick={() => setTabNum(1)}
              >
                {t('받기')}
              </div>
            </FlexRowAround>
            <div className={styles.line} style={{width: 'calc(100% + 40px)', marginLeft:'-20px'}}/>
            <div style={{paddingTop:'28px'}}>
              {tabNum===0 && (<SndHis/>)}
              {tabNum===1 && (<RcvHis/>)}
            </div>
          </Box>
        </FlexColumn>
      </PageContent>
    </PageRootContainer>
  );
}
