import PageTitle, {
    FlexBottomStart,
    FlexColumn, FlexColumnCenter, FlexRowAround, FlexRowCenter, FlexRowEnd, FlexRowSpace,
    FlexRowStart,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import styles from "../common/css/zigle.module.scss";
import {useTranslation} from "react-i18next";
import MiningTime from "../components/mining/MiningTime";
import {Bar} from "./MyLink";
import ProgressBar from "../components/airdrop/ProgressBar";
import MiningHis from "../components/mining/MiningHis";
import WithDrawHis from "../components/mining/WithDrawHis";
import MiningService from "../services/mining.service";
import {decimalFormat} from "../common/js/utilities";
import {ToastError} from "../components/common/popup/ToastAlert";


export default function Mining(){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [tabPage, setTabPage] = useState('mining');
    const [view, setView] = useState({
        miningAmount: 0,
        todayGroupPoint: 0,
        todayPersonalPoint: 0
    });
    useEffect(()=>{
        MiningService.miningToday().then((res:any)=>{
            setView(res.data);
        })
    },[])
    return (
        <PageRootContainer>
            <PageTitle onClickLeft={()=>{ navigate(-1) }} />
            <PageContent>
                <FlexColumn gap={24}>
                    <FlexColumn>
                        <div className={styles.txt14_B04}>{t("내 지글(ZIG) 코인")}</div>
                        <FlexRowStart gap={4}><span className={styles.txt28_B01}>{decimalFormat(view.miningAmount, 2)}</span> <span className={styles.txt22_B05}>ZIG</span></FlexRowStart>
                    </FlexColumn>
                    <FlexColumnCenter className={styles.txt16_W03} style={{borderRadius:'8px', padding:'18px 32px', opacity:'0.6', backgroundColor:'#111111', color:'#FFFFFF'}} onClick={()=>{ToastError(t('준비중입니다.'))}}>
                        {t("출금하기")}
                    </FlexColumnCenter>
                    <FlexColumn gap={12}>
                        <FlexRowSpace>
                            <FlexRowStart gap={4} className={styles.txt13_300_B05}><img src={require('../assets/imges/mining/icon_20.png')} width={20}/>{t("오늘 보상(예정)")}</FlexRowStart>
                            <FlexRowEnd className={styles.txt15_B03}>{t("총")} {decimalFormat(view.todayPersonalPoint + view.todayGroupPoint, 2)} ZIG</FlexRowEnd>
                        </FlexRowSpace>
                        <FlexColumn gap={8}>
                            <ProgressBar fillerBgcolor={"#3A71FF"} completed={Number(view.todayPersonalPoint / (view.todayPersonalPoint + view.todayGroupPoint) * 100 )} containerBgcolor={"#ABB5C2"} fillerHeight={8}/>
                            <FlexRowStart gap={4}>
                                    <div style={{width:'8px', height:'8px', borderRadius:'2px', background:'#3A71FF'}} />
                                    <div className={styles.txt12_B06}>{t("개인")} <span className={styles.txt12_B04}>{decimalFormat(view.todayPersonalPoint,2)} ZIG</span></div>
                                    <div style={{width:'8px', marginLeft:'4px', height:'8px', borderRadius:'2px', background:'#ABB5C2'}} />
                                    <div className={styles.txt12_B06}>{t("단체")} <span className={styles.txt12_B04}>{decimalFormat(view.todayGroupPoint,2)} ZIG</span></div>
                            </FlexRowStart>
                        </FlexColumn>
                    </FlexColumn>
                    <MiningTime />
                    <Bar />
                </FlexColumn>
                <FlexColumn style={{ paddingBottom: '23px'}}>
                    <FlexRowAround>
                        <div className={tabPage === 'mining' ? styles.topTabActive : styles.topTabDefault} onClick={() => {setTabPage('mining');}}>{t('보상')}</div>
                        <div className={tabPage === 'withdraw' ? styles.topTabActive : styles.topTabDefault} onClick={() => {setTabPage('withdraw');}}>{t('출금')}</div>
                    </FlexRowAround>
                    <div className={styles.line} style={{width: 'calc(100% + 40px)', marginLeft:'-20px'}}/>
                </FlexColumn>
                { tabPage === 'mining' && (<MiningHis />)}
                { tabPage === 'withdraw' && (<WithDrawHis />)}
            </PageContent>
        </PageRootContainer>
    )
}