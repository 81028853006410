import React, {RefObject, useCallback, useEffect, useState} from "react";
import BottomLayer, {FlexBottomButton} from "../common/container/BottomLayer";
import {
    Bullet, FlexBottom,
    FlexColumn,
    FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart,
    GrayBox
} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss"
import {Bar1px} from "../../pages/Profile";
import BottomButton from "../common/button/BottomButton";
import PhoneService from "../../services/phone.service";
import commonService from "../../services/common.service";
import CopyToClipboard from "react-copy-to-clipboard";
import PopupService, {IPopAction} from "../../services/popup.service";
import phoneService from "../../services/phone.service";
import CONFIRM_ICON from "../../assets/icons/confirm.png"
import {Toast} from "../common/popup/ToastAlert";
import CommonService from "../../services/common.service";
import { RST_MAP } from "./SndHis";
import {decimalFormat, ellipseAddress} from "../../common/js/utilities";
import DefaultInput from "../common/input/DefaultInput";
import {useForm} from "react-hook-form";
import AssetService from "../../services/asset.service";
import {Trans, useTranslation} from "react-i18next";

interface IPhoneConfirm {
    show:boolean;
    urlKey: string;
    pageMode?:string;
    setShow: any;
    item: any;
    refDwCount?:  React.MutableRefObject<number>;
}
export default function PhoneConfirm(prop:IPhoneConfirm){
    const { t } = useTranslation();
    const [detail, setDetail] = useState<any>({});
    const [btnActive, setBtnActive] = useState<boolean>(false);
    const [message, setMessage] = useState('');
    //
    // const fetchData = useCallback(async (urlKey:string) => {
    //     const response = await PhoneService.phoneView(urlKey);;
    //     setDetail(response.data);
    //     setBtnActive(true);
    // },[]);
    const {
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit,
    } = useForm();


    useEffect(() => {
        // if (prop.urlKey && prop.show) {
        //     // fetchData(prop.urlKey);
        //     setDetail(prop.item);
        //     setBtnActive(true);
        // }
        // return () => {
        //     setDetail({});
        //     setBtnActive(false);
        // }
        setDetail(prop.item);
        setMessage(prop.item.message);
        setBtnActive(true);
    }, [prop.item]);


    return (
        <BottomLayer show={prop.show} setShow={prop.setShow} content={
            (
                <FlexColumn gap={20}  style={{paddingBottom:'32px'}}>
                    <FlexRowSpace>
                        <FlexRowStart gap={15}>
                            <img src={detail.symbolImgFileId} width={38} height={38} alt={"symbol"}/>
                            <FlexColumn gap={2}>
                                <div className={styles.txt16_B02}>{detail.coinDisplayName}</div>
                                <div className={styles.txt12_B04}>
                                    <Bullet>{detail.mainnetDisplayName}</Bullet>
                                </div>
                            </FlexColumn>
                        </FlexRowStart>
                        <FlexRowEnd className={styles.txt18_500_Point_B}>
                            {decimalFormat(detail.amount)}
                            {detail.symbolName}
                        </FlexRowEnd>
                    </FlexRowSpace>
                    <FlexColumn className={styles.line}/>
                    {
                        detail.rstCode === 'DW' || detail.rstCode === 'SM' ?
                            (
                                <>
                                <FlexRowSpace>
                                    <div className={styles.txt14_B05}>{t("받는사람")}</div>
                                    <div className={styles.txt15_B02}>
                                        {detail.receiveUser}
                                        {detail.receiveUserNm && <span>({detail.receiveUserNm})</span>}
                                    </div>
                                </FlexRowSpace>
                                <FlexRowSpace>
                                    <div className={styles.txt14_B05}>{t("인증번호")}</div>
                                    <div className={styles.txt15_B02}>
                                        {detail.confirmTxt}
                                        <CopyToClipboard text={detail.confirmTxt}
                                                         onCopy={() => Toast(t("인증번호를 복사 하였습니다."))}>
                                            <span className={styles.copy}>{t("복사")}</span>
                                        </CopyToClipboard>
                                    </div>
                                </FlexRowSpace>
                                <FlexRowSpace>
                                    <div className={styles.txt14_B05}>{t("공유링크")}</div>
                                    <div className={styles.txt15_B02}>
                                        zigle.link/ {detail.linkUrl}
                                        <CopyToClipboard text={window.location.protocol + "//" + window.location.host + "/p/" + detail.linkUrl}
                                        onCopy={() => Toast(t("링크를 복사 하였습니다."))}>
                                            <span className={styles.copy}>{t("복사")}</span>
                                        </CopyToClipboard>
                                    </div>
                                </FlexRowSpace>
                                </>
                            )
                            :
                            (
                                <FlexColumn gap={16}>
                                    <FlexRowSpace style={{marginTop:'12px'}}>
                                        <div className={styles.txt14_B05}>{t("보낸 주소")}</div>
                                        <div className={styles.txt15_B02}>
                                            {ellipseAddress(detail.fromAddr, 10)}
                                            <CopyToClipboard text={detail.fromAddr}
                                                             onCopy={() => Toast(t("복사 하였습니다."))}>
                                                <span className={styles.copy}>{t("복사")}</span>
                                            </CopyToClipboard>
                                            <img src={require('../../assets/imges/short_cut.png')} style={{paddingLeft:'10px'}} width={20} onClick={()=>{window.open(detail.explorerUrl +"/address/"+detail.fromAddr, '_blank', 'noreferrer')}}/>
                                        </div>
                                    </FlexRowSpace>
                                    <FlexRowSpace>
                                        <div className={styles.txt14_B05}>{t("받는 주소")}</div>
                                        <div className={styles.txt15_B02}>
                                            {ellipseAddress(detail.receiveAddr, 10)}
                                            <CopyToClipboard text={detail.receiveAddr}
                                                             onCopy={() => Toast(t("복사 하였습니다."))}>
                                                <span className={styles.copy}>복사</span>
                                            </CopyToClipboard>
                                            <img src={require('../../assets/imges/short_cut.png')} style={{paddingLeft:'10px'}} width={20} onClick={()=>{window.open(detail.explorerUrl +"/address/"+detail.receiveAddr, '_blank', 'noreferrer')}}/>
                                        </div>
                                    </FlexRowSpace>
                                    <FlexRowSpace>
                                        <div className={styles.txt14_B05}>TXID</div>
                                        <div className={styles.txt15_B02}>
                                            {
                                                ellipseAddress(detail.txId, 10)
                                            }
                                            <CopyToClipboard text={detail.txId}
                                                             onCopy={() => Toast(t("복사 하였습니다."))}>
                                                <span className={styles.copy}>{t("복사")}</span>
                                            </CopyToClipboard>
                                            <img src={require('../../assets/imges/short_cut.png')} style={{paddingLeft:'10px'}} width={20} onClick={()=>{window.open(detail.explorerUrl +"/tx/"+detail.txId, '_blank', 'noreferrer')}}/>
                                        </div>
                                    </FlexRowSpace>
                                            {
                                                prop.pageMode === 'Snd' ?
                                                    (
                                                        <FlexRowSpace>
                                                            <div className={styles.txt14_B05}>{t("보낸 메세지")}</div>
                                                            <div className={styles.txt15_B02}>
                                                                <FlexRowSpace>
                                                                    <DefaultInput
                                                                        error={errors.linkMessage}
                                                                        marginBottom={1}
                                                                        inputElement={
                                                                            <input
                                                                                type={"text"}
                                                                                defaultValue={message}
                                                                                className={styles.inputText_18}
                                                                                style={{backgroundColor:'#FFFFFF', borderBottom:"none"}}
                                                                                {...register("linkMessage", {
                                                                                    onChange: (el) => {
                                                                                        setMessage(el.target.value)
                                                                                    }
                                                                                })}
                                                                            />
                                                                        }
                                                                    />
                                                                    <span className={styles.copy} style={{width:'70px'}} onClick={()=>{
                                                                        const params = new FormData();
                                                                        params.append('idx', prop.item.idx);
                                                                        params.append('type', prop.item.type);
                                                                        params.append('message', message);

                                                                        AssetService.updateMsg(params).then((res:any)=>{
                                                                            if(res.status){
                                                                                Toast(t('수정 되었습니다.'))
                                                                            }
                                                                        })
                                                                    }}>수정</span>
                                                                </FlexRowSpace>
                                                            </div>
                                                        </FlexRowSpace>
                                                    ):
                                                    (
                                                        <FlexRowSpace>
                                                            <div className={styles.txt14_B05}>{t("받은 메세지")}</div>
                                                            <div className={styles.txt15_B02}>
                                                                <FlexRowSpace><div>{detail.message}</div><span style={{width:'67px'}} >&nbsp;</span></FlexRowSpace>
                                                            </div>
                                                        </FlexRowSpace>
                                                    )
                                            }
                                    <FlexRowCenter className={styles.txt13_B05} style={{borderRadius:'16px', padding:'20px', backgroundColor:'#f9fafb' }}>
                                        {detail.datetime} &nbsp;&nbsp;
                                        {
                                            (detail.rstCode ==="CF" || detail.rstCode ==="SN" || detail.rstCode ==="DF" || detail.rstCode ==="FF") && <img src={require('../../assets/icons/error.png')} width={20}/>
                                        }
                                        {RST_MAP[detail.rstCode]}
                                    </FlexRowCenter>
                                </FlexColumn>
                            )
                    }


                    {detail.rstCode === 'DW' && (<GrayBox className={styles.txt13_B05}>
                        <Trans t={t} className={styles.modifyProfileSpan} i18nKey={"전화번호 송금 최종 승인 시 상대방에게 코인을 전송합니다.<span style={{fontWeight: '700'}}>받는 사람과 수량이 정확한 지 꼭 확인해주세요.</span>"} />
                    </GrayBox>)}


                    {
                        detail.rstCode === 'DW' ? (
                                <FlexBottom>
                                    <BottomButton text={t("송금 취소")} textColor={"#f01d1d"}
                                                  backgroundColor={"#f2f4f6"}
                                                  onClick={() => {
                                                      const ok: IPopAction = {
                                                          btnTxt: t('확인'),
                                                          onClick: () => {
                                                              phoneService.phoneCancle(detail.idx).then(()=>{
                                                                  PopupService.newPopup(t("송금 취소")
                                                                      , t("송금을 취소 했습니다.")
                                                                      , ""
                                                                      ,{btnTxt:t("확인 했어요"),
                                                                          onClick: () => {
                                                                              prop.setShow(false);
                                                                              prop.item.rstCode = 'CI';
                                                                              if(prop.refDwCount){
                                                                                  prop.refDwCount.current = prop.refDwCount.current - 1;
                                                                              }
                                                                          }}
                                                                      , {}
                                                                  );
                                                              })
                                                          }
                                                      }
                                                      PopupService.newPopup(t("송금 취소 확인")
                                                          , t("승인 요청된 송금을 취소 하시겠습니까?")
                                                          , ""
                                                          ,ok
                                                          , {
                                                              btnTxt:t("취소"),
                                                              onClick: () => {}
                                                          }
                                                      )


                                                  }} isActive={btnActive}/>
                                    <BottomButton text={t("최종 승인")} textColor={"#ffffff"}
                                                  backgroundColor={"#3a71ff"}
                                                  onClick={() => {
                                                      const ok: IPopAction = {
                                                          btnTxt: t('확인'),
                                                          onClick: () => {
                                                              phoneService.phoneConfirm(detail.idx).then(() => {
                                                                  PopupService.newPopup(t("최종 승인 완료")
                                                                      , <FlexColumn gap={20}>
                                                                          <FlexRowCenter>
                                                                              <img src={CONFIRM_ICON} width={64} height={64}/>
                                                                          </FlexRowCenter>
                                                                          <FlexRowCenter className={styles.txt16_B02}>{t("최종승인은 완료 했어요.")}</FlexRowCenter>
                                                                          <GrayBox>
                                                                              <FlexRowSpace>
                                                                                  <FlexRowStart
                                                                                      className={styles.txt13_B05}>{t("받는사람")}</FlexRowStart>
                                                                                  <FlexRowEnd
                                                                                      className={styles.txtTitle13}>{detail.hpNum}</FlexRowEnd>
                                                                              </FlexRowSpace>
                                                                              <FlexRowSpace>
                                                                                  <FlexRowStart className={styles.txt13_B05}>{t("송금된 코인")}</FlexRowStart>
                                                                                  <FlexRowEnd className={styles.txt13_B}>
                                                                                      {decimalFormat(detail.amount)}
                                                                                      {detail.symbolName}
                                                                                  </FlexRowEnd>
                                                                              </FlexRowSpace>
                                                                          </GrayBox>
                                                                      </FlexColumn>
                                                                      , ""
                                                                      ,{btnTxt:t("확인 했어요"),
                                                                          onClick: () => {
                                                                              prop.setShow(false);
                                                                              prop.item.rstCode = 'DI';
                                                                              if(prop.refDwCount){
                                                                                  prop.refDwCount.current = prop.refDwCount.current - 1;
                                                                              }
                                                                          }}
                                                                      , {}
                                                                  )
                                                              });
                                                          }
                                                      }

                                                      PopupService.newPopup(t("승인 확인")
                                                          , t("송금을 승인 하시겠습니까?")
                                                          , ""
                                                          ,ok
                                                          , {
                                                              btnTxt:t("취소"),
                                                              onClick: () => {}
                                                          }
                                                      )

                                                  }} isActive={btnActive}/>
                                </FlexBottom>
                            )
                            :
                            (
                                detail.rstCode === 'SM' ? (
                                    <FlexBottom>
                                        <BottomButton text={t("송금 취소")} textColor={"#ffffff"}
                                                      backgroundColor={"#3a71ff"}
                                                      onClick={() => {
                                                          const ok: IPopAction = {
                                                              btnTxt: t('확인'),
                                                              onClick: () => {
                                                                  phoneService.phoneCancle(detail.idx).then(()=>{
                                                                      PopupService.newPopup(t("송금취소")
                                                                          , t("송금을 취소 했습니다.")
                                                                          , ""
                                                                          ,{btnTxt:t("확인 했어요"),
                                                                              onClick: () => {
                                                                                  prop.setShow(false);
                                                                                  prop.item.rstCode = 'CI';
                                                                              }}
                                                                          , {}
                                                                      );
                                                                  })
                                                              }
                                                          }
                                                          PopupService.newPopup(t("송금취소 확인")
                                                              , t("승인 요청된 송금을 취소 하시겠습니까?")
                                                              , ""
                                                              ,ok
                                                              , {
                                                                  btnTxt:t("취소"),
                                                                  onClick: () => {}
                                                              }
                                                          )
                                                      }} isActive={btnActive}/>
                                    </FlexBottom>
                                )
                                : null
                            )
                    }


                </FlexColumn>
            )
        }/>
    );
}
