import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import styles from "../common/css/zigle.module.scss";
import {IAirdropSet} from "./AirdropFi";
import styled from "styled-components";
import PageTitle, {
    FlexBottom,
    FlexColumn, FlexColumnCenter, FlexRowEnd, FlexRowSpace, FlexRowStart,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import BottomButton from "../components/common/button/BottomButton";
import ProfileService from "../services/profile.service";
import {Toast} from "../components/common/popup/ToastAlert";
import CopyToClipboard from "react-copy-to-clipboard";
import RefPopup from "../components/home/RefPopup";
import {useScript} from "../common/js/hooks";
import {Trans, useTranslation} from "react-i18next";



export default function RefPage() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [refList, setRefList] = useState([]);
    const refCnt = useRef(0);
    const refCode = useRef("");
    const [showPop, setShowPop] = useState(false);
    const status = useScript("https://developers.kakao.com/sdk/js/kakao.js");
    const handleKakaoButton = (currentUrl:any) => {
        // window.Kakao.Share.sendScrap({
        //     requestUrl: currentUrl,
        // });
        window.Kakao.Share.sendDefault({
            objectType: 'feed',
            content: {
                title: t('Zigle 회원가입 초대 포인트'),
                description: t('초대 링크를 통해 친구를 초대하고, 지글 포인트를 받아보세요!'),
                imageUrl:
                    window.location.protocol + "//" + window.location.host+'/assets/image/img_188.png',
                link: {
                    mobileWebUrl: currentUrl,
                    webUrl: currentUrl,
                },
            },

            buttons: [
                {
                    title: t('웹으로 이동'),
                    link: {
                        mobileWebUrl: currentUrl,
                        webUrl: currentUrl,
                    },
                }
            ],
        });
    };
    // kakao sdk 초기화하기
    // status가 변경될 때마다 실행되며, status가 ready일 때 초기화를 시도합니다.
    useEffect(() => {
        if (status === "ready" && window.Kakao) {
            // 중복 initialization 방지
            if (!window.Kakao.isInitialized()) {
                // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
                window.Kakao.init(process.env.REACT_APP_KAKAO_AUTH_KEY);
            }
        }
    }, [status]);
    useEffect(()=>{
        ProfileService.refList().then((res)=>{
            setRefList(res.data);
            refCode.current = res.data[0].refCode;
            if(res.data[0]?.email ===null){
                refCnt.current = 0
            }else{
                refCnt.current = res.data.length;
            }
        })
    },[]);

  return (
      <>
          <RefPopup setShow={setShowPop} show={showPop} refCnt={refCnt.current} refList={refList} />
          <PageRootContainer>
            <PageTitle
                textTitle ={t("초대링크 복사")}
                onClickLeft={()=>{
                    navigate(-1);
                }}
                />
            <PageContent style={{marginBottom:'100px'}}>
                <FlexColumn gap={26}>
                    <FlexColumn gap={36}>
                        <FlexColumn gap={4}>
                            <div className={styles.txt20_B01}><Trans t={t} i18nKey={"초대 링크를 통해 친구를 초대하고,<br/><span style={{fontWeight:'700'}}>지글 포인트를 받아보세요!</span>"}/> </div>
                            <div className={styles.txt13_B05}><Trans t={t} i18nKey={"지글포인트는 코인 안전 송금 및 전화번호 송금,<br/>에어드랍 링크 생성에 사용 할 수 있어요"}/></div>
                        </FlexColumn>
                        <FlexColumnCenter>
                            <img src={require('../assets/imges/img_188.png')} width={188}/>
                        </FlexColumnCenter>
                    </FlexColumn>
                    <FlexColumn gap={10}>
                        <FlexRowSpace>
                            <div className={styles.txt13_B02}>{t('친구초대')}</div>
                            <div className={styles.txt11_B04} style={{padding:'4px 8px', borderRadius:'100px', border:'1px solid #D4D8E2'}} onClick={()=>setShowPop(true)}>{t('초대내역')}</div>
                        </FlexRowSpace>
                        <FlexColumn style={{background:'#F8F9FC', border:'1px solid #F2F4F8', borderRadius:'12px', padding:'19px 16px'}} gap={15}>
                            <FlexRowSpace>
                                <FlexRowStart className={styles.txt14_B05}>{t('링크')}</FlexRowStart>
                                <FlexRowEnd className={styles.txt14_B03} gap={3}>
                                    {window.location.host+"/join/"+refCode.current}
                                    <CopyToClipboard text={window.location.protocol + "//" + window.location.host+"/join/"+refCode.current}
                                                     onCopy={() => Toast(t("주소를 복사 하였습니다."))}>
                                    <img src={require('../assets/imges/copy.png')} width={24} />
                                    </CopyToClipboard>
                                </FlexRowEnd>
                            </FlexRowSpace>
                            <div className={styles.line}></div>
                            <FlexRowSpace>
                                <FlexRowStart className={styles.txt14_B05}>{t('초대코드')}</FlexRowStart>
                                <FlexRowEnd className={styles.txt14_B03} gap={3}>
                                    {refCode.current}
                                    <CopyToClipboard text={refCode.current} onCopy={() => Toast(t("주소를 복사 하였습니다."))}>
                                    <img src={require('../assets/imges/copy.png')} width={24} />
                                    </CopyToClipboard>
                                </FlexRowEnd>
                            </FlexRowSpace>
                            <div className={styles.line}></div>
                            <FlexRowSpace>
                                <FlexRowStart className={styles.txt14_B05}>{t('초대된 친구')}</FlexRowStart>
                                <FlexRowEnd className={styles.txt14_B03} gap={3}>
                                    {refCnt.current}{t('명')}
                                </FlexRowEnd>
                            </FlexRowSpace>
                        </FlexColumn>
                    </FlexColumn>
                </FlexColumn>
                <FlexBottom>
                    <CopyToClipboard text={window.location.protocol + "//" + window.location.host+"/join/"+refCode.current}
                                     onCopy={() => Toast(t("주소를 복사 하였습니다."))}>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#F2F4F7"}
                        textColor={"#3A71FF"}
                        text={t("초대링크 복사")}
                        onClick={() => {

                        }}
                    />
                    </CopyToClipboard>
                    <BottomButton
                        isActive={true}
                        backgroundColor={"#FEE500"}
                        textColor={"#37191A"}
                        icon={require('../assets/imges/kakao.png')}
                        text={t("카카오톡 초대")}
                        onClick={() => {
                            handleKakaoButton(window.location.protocol + "//" + window.location.host+"/join/"+refCode.current)
                        }}
                    />
              </FlexBottom>
            </PageContent>
          </PageRootContainer>
      </>
  )
}
