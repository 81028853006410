import React, {useEffect, useRef, useState} from "react";
import styles from "../common/css/zigle.module.scss";

import PageTitle, {
  FlexBottom,
  FlexColumn,
  FlexRowAround,
  PageContent,
  PageRootContainer,
} from "../components/common/container/PageContainer";
import BottomButton from "../components/common/button/BottomButton";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import DefaultInput from "../components/common/input/DefaultInput";
import KrwSelect from "../components/ziglecharge/KrwSelect";
import UsdSelect from "../components/ziglecharge/UseSelect";
import TokenSelect from "../components/ziglecharge/TokenSelect";
import PayDepth1 from "../components/ziglecharge/PayDepth1";
import PayDepth2 from "../components/ziglecharge/PayDepth2";
import PayDepthEnd from "../components/ziglecharge/PayDepthEnd";
import {PayType_Map} from "./MyPoint";
import PayDepthCryptoEnd from "../components/ziglecharge/PayDepthCryptoEnd";
import {useTranslation} from "react-i18next";

function Payment() {
  const navigate = useNavigate();
  const {t} = useTranslation();


    return (
        <PageRootContainer>
          <PageTitle
              onClickLeft={() => {
                navigate(-1);
              }}
              textTitle={t("포인트결제")}
          />
          <PageContent>
          </PageContent>
        </PageRootContainer>
    );
}


export default Payment;