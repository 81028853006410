import {IAirdropSet} from "../../pages/AirdropFi";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {useState} from "react";
import {FlexBottom, FlexColumn, FlexColumnCenter, FlexRowCenter, FlexRowSpace} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import {Bar} from "../../pages/MyLink";
import BottomButton from "../common/button/BottomButton";
import CommonService from "../../services/common.service";
import {ellipseAddress} from "../../common/js/utilities";
import {Trans, useTranslation} from "react-i18next";

interface IAirdropFiEnd {
    airdropInfo: IAirdropSet;
}
export default function AirdropFiEnd({airdropInfo}:IAirdropFiEnd){
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    const [coinBalance , setCoinBalance] = useState('0');
    return (
        <>
        <FlexColumn gap={28}>
            <FlexColumn gap={12}>
                <FlexRowCenter><img src={require('../../assets/icons/send_ok.png')} width={64} /></FlexRowCenter>
                <FlexColumn  className={styles.txt20_B01}>
                    <FlexRowCenter style={{textAlign:'center'}}><Trans t={t} className={styles.airdropFiEnd} i18nKey={"에어드랍 생성을<br/><span className={styles.txt20_500_B01}>완료</span>했어요!"} /></FlexRowCenter>

                </FlexColumn>
                <FlexColumnCenter className={styles.txt14_B05} style={{textAlign:'center'}}>
                    <Trans t={t} className={styles.airdropFiEnd600} i18nKey={"총 <span>{0}개의 지갑</span>에<br/>에어드랍 할 링크를 생성했어요"} values={{0:airdropInfo.airdropCnt}}/>
                </FlexColumnCenter>
            </FlexColumn>
            <Bar/>
            <FlexColumn className={styles.txt14_B03} gap={16}>
                <FlexRowSpace>
                    <div>{t("전송방식")}</div>
                    <div>{t("선착순")}</div>
                </FlexRowSpace>
                <FlexRowSpace>
                    <div>{t("출금지갑")}</div>
                    <div>{ellipseAddress(airdropInfo.walletAddr, 6)}</div>
                </FlexRowSpace>
                <FlexRowSpace>
                    <div>{t("지갑당 에어드랍")}</div>
                    <div>{airdropInfo.amount} {airdropInfo.coinInfo.symbolName}</div>
                </FlexRowSpace>
                {/*<FlexRowSpace>*/}
                {/*    <div>서비스 이용료</div>*/}
                {/*    <div>1,000 P</div>*/}
                {/*</FlexRowSpace>*/}
                <FlexColumn className={styles.line}></FlexColumn>
                <FlexRowSpace className={styles.txt18_700_B01}>
                    <div>{t("총 수량")}</div>
                    <div style={{color:'#3A71FF'}}>{airdropInfo.receiveAmount} {airdropInfo.coinInfo.symbolName}</div>
                </FlexRowSpace>
            </FlexColumn>
        </FlexColumn>
    <FlexBottom>
        <BottomButton
            isActive={true}
            backgroundColor={"#f2f4f6"}
            textColor={"#3a71ff"}
            text={t("추가 에어드랍 생성")}
            onClick={() => {

            }}
        />
        <BottomButton
            isActive={true}
            backgroundColor={"#3a71ff"}
            textColor={"white"}
            // border={"solid 1px #dbdee6"}
            onClick={() => {
                localStorage.setItem("airdropUrl", airdropInfo.airdropUrl);
                navigate("/myAirdrop");
            }}
            text={t("상세보기")}
        />
    </FlexBottom>
    </>
    )
}