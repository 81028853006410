import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import AuthService from "./services/auth.service";
import ProfileService from "./services/profile.service";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};


let messaging;
try {
  const app = initializeApp(firebaseConfig);
  messaging = getMessaging(app);
}catch (e){
  console.log("없음")
}


async function requestPermission() {
  // console.log("권한 요청 중...");
  if(messaging){
    const permission = await Notification.requestPermission();
    if (permission === "denied") {
      console.log("알림 권한 허용 안됨");
      return;
    }

    // console.log("알림 권한이 허용됨");

    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_VAPID_KEY,
    });
    // console.log("token", token);
    if (token) {
      const user = AuthService.getCurrentUser();

      if (user.userId) {
        // console.log("userId : ", user.userId);
        // console.log("token: ", token);

        const params = new URLSearchParams();
        params.append("userId", user.userId);
        params.append("token", token);
        await ProfileService.setPushToken(params);
      }
    } else {
      console.log("Can not get Token");
    }

    onMessage(messaging, (payload) => {
      console.log("메시지가 도착했습니다.", payload);
      // ...
    });
  }

}

requestPermission();
