import React from "react";
import {FlexColumnCenter, FlexRowAround} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import {useTranslation} from "react-i18next";
import {ToastError} from "../common/popup/ToastAlert";

interface IPayDepthProps {
    payInfo:any;
    setPayInfo:any;
}
export default function KrwSelect({payInfo, setPayInfo}:IPayDepthProps) {
    const { t } = useTranslation();

    return (
        <FlexRowAround gap={5}>
            <FlexColumnCenter className={ payInfo.subPayType === 'K1' ? styles.imageBoxActive : styles.imageBoxDefault}
                 onClick={()=>{
                     // setPayInfo(
                     //     {...payInfo,
                     //         subPayType : 'K1'
                     //     }
                     // );
                     ToastError(t("준비중입니다."))
                 }}
            >
                <img
                    src={require("../../assets/imges/payment/payment_01.png")}
                    width={40}
                    alt={"payment01"}
                />
                <span>{t("무통장입금")}</span>
            </FlexColumnCenter>
            <FlexColumnCenter className={ payInfo.subPayType === 'K2' ? styles.imageBoxActive : styles.imageBoxDefault}
                 onClick={()=>{
                     setPayInfo(
                         {...payInfo,
                             subPayType : 'K2'
                         }
                     );
                 }}>
                <img
                    src={require("../../assets/imges/payment/payment_02.png")}
                    width={40}
                    alt={"payment02"}
                />
                <span>{t("신용카드")}</span>
            </FlexColumnCenter>
            <FlexColumnCenter className={ payInfo.subPayType === 'K3' ? styles.imageBoxActive : styles.imageBoxDefault}
                 onClick={()=>{
                     // setPayInfo(
                     //     {...payInfo,
                     //         subPayType : 'K3'
                     //     }
                     // );
                     ToastError(t("준비중입니다."))
                 }}>
                <img
                    src={require("../../assets/imges/payment/payment_03.png")}
                    width={40}
                    alt={"payment03"}
                />
                <span>{t("핸드폰")}</span>
            </FlexColumnCenter>
        </FlexRowAround>
    )
}