import React, {ReactHTMLElement, useEffect, useRef, useState} from "react";
import PageTitle, {
    FlexBottom, FlexBottom86,
    FlexColumn, FlexColumnCenter, FlexRowCenter, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper, ToggleBtn, ToggleCircle, ZSelect
} from "../common/container/PageContainer";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {ICoinInfo} from "../../common/dto/CoinInfoDto";
import {useInView} from "react-intersection-observer";
import CommonService from "../../services/common.service";
import CryptoItem from "../common/CryptoItem";
import EmptyContent from "../common/EmptyContent";
import {ellipseAddress} from "../../common/js/utilities";
import CoinPopup from "../tokenlist/CoinPopup";
import MetaMaskService from "../../services/metamask.service";

interface IChatCoinSelect {
    show: boolean;
    setShow: any;
    itemInfo:any;
    setItemInfo:any;
    handleOnClick?: (item: string)=> void;
}

export default function ChatCoinSelect(props:IChatCoinSelect){
    const { t } = useTranslation();
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();
    const [searchCoin, setSearchCoin] = useState('');
    const [mainnetId, setMainnetId]= useState('');
    const [coinList, setCoinList] = useState<Array<ICoinInfo>>([]);
    const [mainnetList, setMainnetList] = useState<any[]>([]);

    const [showCoinPopup, setShowCoinPopup] = useState(false);


    const [reset, setReset] = useState(false);
    const [currPage, setCurrPage] = useState(1);
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [blockSize] = useState(10);
    const fetchData = async(searchCoin: any) =>{
        const response = await CommonService.chainIdTokenList(props.itemInfo.mainnetLinkName, 'coin',  searchCoin, currPage, blockSize);
        if (!response.data.length) {
            setLastList(true);
            return;
        }
        setCoinList([...coinList, ...response.data]);
    }


    const onHandleClickMainnet = (selectItem:any) =>{
        props.setItemInfo({
            ...props.itemInfo,
            mainnetId : selectItem.mainnetId,
            mainnetSymbolName : selectItem.symbolName,
            mainnetLinkName : selectItem.linkName,
        })
        setShowCoinPopup(false);

        setCoinList([]);
        setLastList(false);
        if(currPage!==1)
        {
            setCurrPage(1);
        }else{
            setReset(!reset);
        }


    }
    const getMainnet = async ()=>{
        const mainnetResposne = await CommonService.mainnetList('', 1, 100, '');
        setMainnetList(mainnetResposne.data);
    }
    useEffect(()=>{
        getMainnet();
    },[])
    useEffect(() => {

        if(props.show){
            if (!lastList) {
                fetchData(searchCoin);
            }
        }else{
            setCoinList([]);
            setLastList(false);
            setCurrPage(1);
        }

    },[currPage, searchCoin, reset, props.show]);

    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);

    return (
        <>
            {
                props.show &&
                (

                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <CoinPopup setShow={setShowCoinPopup} show={showCoinPopup} mainnetList={mainnetList} onHandleClickMainnet={onHandleClickMainnet} />
                                <FlexColumn className={styles.pageTitle} style={{ height: "56px" }}>
                                    <FlexRowSpace>
                                        <FlexRowStart style={{width:'30%'}}>
                                            <img
                                                src={require("../../assets/imges/back_arrow.png")}
                                                width={28}
                                                alt={"back_arrow"}
                                                style={{ marginLeft: "-8px" }}
                                                onClick={() => {
                                                    props.setShow(false);
                                                }}
                                            />
                                        </FlexRowStart>
                                        <FlexRowCenter  style={{width:'40%', whiteSpace:'pre-wrap', textAlign:'center'}} className={styles.txt14_B02}>
                                            <div style={{border:' 1px solid #D4D8E2', padding:'3px 10px 3px 16px', borderRadius:'100px'}}
                                                 onClick={() => {
                                                     setShowCoinPopup(true)
                                                 }}
                                            >{props.itemInfo.mainnetLinkName}<img src={require('../../assets/icons/arrow_select.png')} width={20} /></div>
                                        </FlexRowCenter>
                                        <FlexRowEnd style={{width:'30%'}}></FlexRowEnd>
                                    </FlexRowSpace>
                                </FlexColumn>
                                <PageContent>
                                    <FlexColumn gap={12}>
                                        <div className={styles.txt20_500_B01}>{t("지정코인 선택")}</div>
                                        <FlexRowStart>
                                            <div style={{width:'28px', border: '0', borderBottom: 'solid 1px #f2f4f8', height: '47px', display: 'flex',alignItems: 'center'}}>
                                                <img src={require('../../assets/imges/search_icon.png')} style={{width:'28px'}} />
                                            </div>
                                            <div style={{width:'100%'}}><input
                                                type={"text"}
                                                className={styles.inputText_44}
                                                placeholder={t('코인명을 입력해 주세요')}
                                                {...register("searchCoin", {
                                                    required: t('코인명을 입력해 주세요'),
                                                    onChange: (el) => {
                                                        setCurrPage(1);
                                                        setCoinList([]);
                                                        setLastList(false);

                                                        setSearchCoin(el.target.value);
                                                    }
                                                })}
                                            /></div>
                                        </FlexRowStart>
                                    </FlexColumn>
                                    <FlexColumn>
                                        {
                                            coinList.length > 0 ? (
                                                coinList.map((coinInfo, idx) => {

                                                    return (
                                                        <FlexColumn key={coinInfo.coinId} style={{height:'78px'}} onClick={()=>{

                                                            props.setItemInfo({
                                                                ...props.itemInfo,
                                                                coinId : coinInfo.coinId,
                                                                coinSymbolName : coinInfo.symbolName,
                                                                coinSymbolImgFileId:coinInfo.symbolImgFileId
                                                            })
                                                            props.setShow(false);
                                                        }}>
                                                            <FlexRowSpace>
                                                                <FlexRowStart>
                                                                    <div style={{paddingRight:'12px'}}>
                                                                        <img src={coinInfo.symbolImgFileId} alt="bgIcon" width={38} />
                                                                    </div>
                                                                    <FlexColumn>
                                                                        <div className={styles.txt14_B01}>{coinInfo.coinName}</div>
                                                                        <div className={styles.txt12_B05}>{coinInfo.symbolName}</div>
                                                                    </FlexColumn>
                                                                </FlexRowStart>
                                                                <FlexRowEnd>
                                                                    {
                                                                        coinInfo.contAddr && (
                                                                            <div  className={styles.txt12_B04} style={{background:'#F2F4F6', lineHeight:'130%', borderRadius:'100px', padding:'3px 4px 3px 6px'}}>
                                                                                {ellipseAddress(coinInfo.contAddr, 5)}
                                                                            </div>
                                                                        )
                                                                    }
                                                                </FlexRowEnd>
                                                            </FlexRowSpace>
                                                        </FlexColumn>
                                                    );
                                                })
                                            )
                                            :
                                            (
                                                 <EmptyContent titleText={t('검색 결과가 없습니다')} titleImage={require('../../assets/icons/empty_icon.png')}/>
                                            )
                                        }
                                    </FlexColumn>
                                    {
                                        Object.values(coinList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
                                    }
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>
    )
}