import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import { data_coinList } from "../../data/coinList";
import { useSelector } from "react-redux";
import ReactPlaceholder from "react-placeholder";
import CryptoItem from "../common/CryptoItem";
import CommonService from "../../services/common.service";
import {ICoinInfo} from "../../common/dto/CoinInfoDto";
import {useInView} from "react-intersection-observer";
import AssetService from "../../services/asset.service";
import {FlexBottom86, FlexColumn, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import EmptyContent from "../common/EmptyContent";
import {useAccount, useBalance} from "wagmi";
import {useTranslation} from "react-i18next";

export default function AssetFrame() {
  const navigate = useNavigate();

  const { t } = useTranslation();
  
  const chainId = useSelector((state: any) => state.walletReducer.chainId);
  const mainNetDetail = useSelector((state: any) => state.walletReducer.mainNetDetail);

  const { address, isConnected } = useAccount();

  const hookCoinBalance = useBalance({
    address: address,
  });

  
  const login: boolean = useSelector((state: any) => state.userReduce.login);

  const [searchCoin, setSearchCoin] = useState('');
  const [coinList, setCoinList] = useState<Array<ICoinInfo>>([]);
  const [currPage, setCurrPage] = useState(1);
  const [lastList, setLastList] = useState(false);
  const [ref, inView] = useInView();
  const [hasNextPage, setHasNextPage] = useState(true);
  const [blockSize] = useState(10);
  const [reSet, setReSet] = useState(false);

  const fetchData = async(searchCoin: any) =>{
    let data = await mainNetDetail;
    const response = await AssetService.getMyCoin(chainId, searchCoin, currPage, blockSize);
    if (!response.data.length) {
      setLastList(true);
      return;
    }
    setCoinList([...coinList, ...response.data]);

  }
  useEffect(() => {
    if (address  && !lastList && chainId && login) {
      fetchData(searchCoin);
    }
  },[currPage, searchCoin, reSet]);
  useEffect(()=>{
    if (address && chainId && login) {

      setCoinList([]);
      setLastList(false);
      setCurrPage(1);
      setReSet((state:boolean) => !state);
    }
  },[chainId, address, login])
  return (
    <div className={styles.mainBox}>
      <div className={styles.titleContainer} onClick={()=>navigate("/myCoin")}>
        <div className={`${styles.flexRowStart}`}>
          <div className={styles.txtTitle18}>{t("내 자산")}</div>
        </div>
        <div>
          <img
            src={require("../../assets/icons/btnArrow.png")}
            height={28}
            alt={"arrow"}
          />
        </div>
      </div>

      <div className={styles.contentsCenter}>
        {!address && (
          <div className={styles.txtNoContent} style={{ height: "40px" }}>
            {t("지갑을 연결해 주세요.")}
          </div>
        )}
        {address && (

            <FlexColumn>

              {
                  coinList.length > 0 ? (
                    <div className={styles.flexColumn}>
                      {coinList.map((coinInfo, idx) => {

                        return (
                          <CryptoItem
                            key={idx}
                            coinInfo={coinInfo}
                            address={address}
                            coinBalance={hookCoinBalance.data?.formatted}
                          />
                        );
                      })}
                    </div>
                  )
                      :
                      (
                          <EmptyContent titleText={t('내 자산을 등록 해주세요.')}/>
                      )
              }
            </FlexColumn>
        )}
      </div>
    </div>
  );
}
