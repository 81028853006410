import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import PageTitle, {
    FlexColumEnd,
    FlexColumn, FlexColumnCenter, FlexRowCenter, FlexRowEnd,
    FlexRowSpace, FlexRowStart,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import React, {useState} from "react";
import styles from "../common/css/zigle.module.scss";
import {useTranslation} from "react-i18next";

export default function AirdropCreate(){
    const navigate = useNavigate();
    const [home, setHome] = useState(true);
    const { t } = useTranslation();

    return (
        <PageRootContainer>
            <PageTitle home={home} textTitle={t('에어드랍 만들기')} onClickLeft={()=>{ navigate(-1);}} />
            <PageContent  style={{marginBottom:'0px'}}>
                <FlexColumn gap={28}>
                    <FlexColumn className={styles.txt20_500_B01}>
                        {t('어떤 방식으로 만들까요?')}
                    </FlexColumn>
                    <FlexRowSpace onClick={()=>navigate("/airdropFi")}>
                        <FlexRowStart style={{minWidth:'70%'}} gap={8}>
                            <img src={require('../assets/imges/airdrop/airdrop_type1.png')} width={40} />
                            <FlexColumn>
                                <div className={styles.txt13_B05}>{t('손 빠른 사람이 임자')}</div>
                                <div>{t('선착순 링크')}</div>
                            </FlexColumn>
                        </FlexRowStart>
                        <FlexRowEnd>
                            <img
                                src={require("../assets/icons/btnArrow.png")}
                                height={28}
                                alt={"link"}
                            />
                        </FlexRowEnd>
                    </FlexRowSpace>
                    <FlexRowSpace onClick={()=>navigate("/airdropDe")}>
                        <FlexRowStart style={{minWidth:'70%'}} gap={8}>
                            <img src={require('../assets/imges/airdrop/airdrop_type2.png')} width={40} />
                            <FlexColumn>
                                <div className={styles.txt13_B05}>{t('지갑주소와 닉네임으로')}</div>
                                <div>{t('지정 인원')}</div>
                            </FlexColumn>
                        </FlexRowStart>
                        <FlexRowEnd>
                            <img
                                src={require("../assets/icons/btnArrow.png")}
                                height={28}
                                alt={"link"}
                            />
                        </FlexRowEnd>
                    </FlexRowSpace>
                </FlexColumn>
            </PageContent>
        </PageRootContainer>

    )
}