import React, {useEffect, useRef, useState} from "react";
import styles from "../../common/css/zigle.module.scss";
import {
    FlexBottom, FlexBottom86, FlexBottomStart,
    FlexColumn, FlexColumnCenter,
    FlexRowAround, FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, ToggleBtn, ToggleCircle
} from "../common/container/PageContainer";
import {IAirdropSet} from "../../pages/AirdropFi";
import "react-datepicker/dist/react-datepicker.css";
import MetaMaskService from "../../services/metamask.service";
import {useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import AirdropService from "../../services/airdrop.service";
import {ethers} from "ethers";
import bigDecimal from "js-big-decimal";
import {ToastError} from "../common/popup/ToastAlert";
import {useAccount} from "wagmi";
import {Trans, useTranslation} from "react-i18next";
interface IAirdropStep4 {
    airdropInfo: IAirdropSet;
    setDepth: any;
    setAirdropInfo: any;
    txIdResult?:(result:any,
                 resultMessage:any,
                 send_token_amount:any,
                 account:any,
                 to_address:any,
                 mainnetId:any,
                 coinId:any,
                 linkMngId:any)=> void;
}

export default function AirdropDeStep6({airdropInfo, setAirdropInfo, setDepth, txIdResult}:IAirdropStep4){
    const { t } = useTranslation();
    const { address, isConnected } = useAccount();
    const ziglePoint = useSelector((state: any) => state.userReduce.ziglePoint);

    const [gasFee, setGasFee] = useState("0");
    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
    } = useForm();


    const onSubmit = (data: any) => {
        if(address){
            AirdropService.airdropFeeUser(airdropInfo.mainnetId).then((res)=>{
                if(res.resultCode==="SUCCESS"){
                    if((Number(res.data.balance) - Number(res.data.balanceLock)) > Number(gasFee)){
                        if((Number(airdropInfo.ziglePoint) * Number(airdropInfo.airdropCnt)) > res.data.ziglePoint){
                            ToastError(t("Zigle point가 부족합니다."));
                            return false;
                        }else{
                            MetaMaskService.sendTransferToken(
                                address,
                                airdropInfo.coinInfo.contAddr,
                                (airdropInfo.airdropCnt * airdropInfo.amount).toString(),
                                airdropInfo.receiveAddr,
                                airdropInfo.mainnetId,
                                airdropInfo.coinInfo.coinId,
                                '',
                                txIdResult
                            );
                        }
                    }else{
                        ToastError(t("사용할 수수료가 부족 합니다 수수료를 입금 후 사용해 주세요."));
                    }
                }
            });
        }

    }
    useEffect(()=>{
        async function fetchData() {
            //0.000000003
            let rpcurl = airdropInfo.mainnetInfo.rpcUrl;
            if(airdropInfo.mainnetInfo.chainId == "1")
            {
                rpcurl += process.env.REACT_APP_INFURA_KEY
            }
            const provider = new ethers.providers.JsonRpcProvider(
                rpcurl
            );
            const gas = await provider.getGasPrice();

            const tmpGas = new bigDecimal(ethers.utils.formatUnits(gas, airdropInfo.mainnetInfo.decimalVal)).multiply(new bigDecimal("21000")).multiply(new bigDecimal("1.3"));
            setAirdropInfo({
                ...airdropInfo,
                feeAverage:tmpGas.getValue()
            });
            setGasFee(tmpGas.multiply(new bigDecimal(airdropInfo.airdropCnt)).getValue());
        }
        fetchData();
    },[])

    return (
        <FlexColumn>
            <FlexColumn gap={20}>
                <FlexColumn className={styles.txt20_B01}><Trans t={t} i18nKey={"최종 에어드랍 지급 수량 및<br/>수수료를 확인해 주세요!"}/></FlexColumn>
                <FlexColumn gap={28} className={styles.wave}>
                    <FlexColumnCenter className={styles.txt15_B05} >
                        <img src={airdropInfo.coinInfo.symbolImgFileId} width={64} />
                        <div>
                            <span className={styles.txt15_B02}>{airdropInfo.coinInfo.displayName}</span>({airdropInfo.coinInfo.symbolName})
                        </div>
                        <div>
                            <FlexRowStart gap={4}>
                                <div className={styles.ellipse_151}></div>
                                <div className={styles.txt12_B04} style={{textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden'}}>{airdropInfo.mainnetLinkName}</div>
                            </FlexRowStart>
                        </div>
                    </FlexColumnCenter>
                    <div className={styles.line} style={{borderTop:'1px dashed #E3E5ED'}}/>
                    <FlexColumn gap={12} className={styles.txt13_B05}>
                        <FlexRowSpace>
                            <div>{t("인원")}</div>
                            <div className={styles.txt13_B02}>{t('{0}명', {0:airdropInfo.airdropCnt})}</div>
                        </FlexRowSpace>
                        <FlexRowSpace>
                            <div>{t("지급 수량")}</div>
                            <div className={styles.txt13_B02}>{airdropInfo.receiveAmount} {airdropInfo.coinInfo.symbolName}</div>
                        </FlexRowSpace>
                        <FlexRowSpace>
                            <div>{t("수수료")}</div>
                            <div className={styles.txt13_B02}>{gasFee} {airdropInfo.mainnetInfo.symbolName}</div>
                        </FlexRowSpace>
                        <FlexRowSpace>
                            <div>{t("서비스 이용료")}</div>
                            <div className={styles.txt13_B02}>{airdropInfo.airdropCnt * airdropInfo.ziglePoint} P</div>
                        </FlexRowSpace>
                    </FlexColumn>
                    <FlexColumnCenter gap={12}>
                        <div className={styles.popupBtnBlue}  onClick={handleSubmit(onSubmit)}>{t("지급하기")}</div>
                        <div className={styles.txt13_B05}>{t("에어드랍 내용에 동의 하시면 눌러주세요")}</div>
                    </FlexColumnCenter>

                </FlexColumn>
            </FlexColumn>
        </FlexColumn>
    )
}

