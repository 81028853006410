import styles from "../../common/css/zigle.module.scss";
import {Trans, useTranslation} from "react-i18next";
import {FlexColumnCenter} from "../common/container/PageContainer";
import PopupService from "../../services/popup.service";
import FavService from "../../services/fav.service";
import {Toast} from "../common/popup/ToastAlert";
import ChatService from "../../services/chat.service";
import React from "react";
import {useNavigate} from "react-router-dom";

export interface IChatNoFriend{
    userInfo : any;
    handleOnClick?: (item: any)=> void;
    roomDoname: any;

}
export default function ChatNoFriend(props:IChatNoFriend){
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <FlexColumnCenter style={{paddingTop:'240px'}} gap={20}>
            <img
                src={props.userInfo.profileImageFileId}
                width={72}
                alt={"profile image"}
                style={{borderRadius:'48px', aspectRatio:'1', objectFit:'cover'}}
            />
            <div className={styles.txt13_B05} style={{textAlign:'center', lineHeight:'130%'}}>
                <Trans t={t} i18nKey={"{0}님을 <b>프렌딩</b>하고<br/>채팅방에 참여해 보세요"} values={{0:props.roomDoname.current}}/>
            </div>
            <FlexColumnCenter className={styles.txt14_500_PointB} style={{ width:'75px', height:'34px', background:'#3A71FF', color:'#FFFFFF', borderRadius:'8px' }} onClick={()=> {
                if(props.handleOnClick){
                    props.handleOnClick(props.userInfo);
                }
            }}>{t("프렌드")}</FlexColumnCenter>
        </FlexColumnCenter>
    )
}