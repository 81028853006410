export interface JsonData {
  [keyName: string]: any;
}

export interface IUserToken {
  timestamp: Number;
  userId: string;
  email: string;
  accessToken: string;
  refreshToken: string;
  socialCode: string;
  userLevel?: string;
  refCode: string;
  upRefCode?: string;
}

export const AnonyToken: IUserToken = {
  timestamp: 0,
  userId: "",
  email: "",
  accessToken: "",
  refreshToken: "",
  socialCode: "",
  refCode:"",
};

export interface IUser {
  userId: string;
  email: string;
  sliceEmail: string;
  doname: string;
  ziglePoint: Number;
  cryptoZigle: Number;
  profileImageFileId: string;
  introduce?: string;
  hpCountry?: string;
  hpNum?: string;
  login: boolean;
  socialCode: string;
  refCode: string;
  upRefCode?: string;
  miningAmount:Number;
}

export const AnaonyUser: IUser = {
  userId: "",
  email: "",
  sliceEmail: "",
  doname: "",
  ziglePoint: 0,
  cryptoZigle: 0.0,
  profileImageFileId:
    "https://s3.ap-northeast-2.amazonaws.com/bucket.zigle.io/profiles/profile6.png",
  introduce: "",
  hpCountry: "",
  hpNum: "",
  socialCode: "",
  refCode:"",
  upRefCode: "",
  login: false,
  miningAmount:0.0
};

export interface IWallet {
  openModal: any;
  metaInstall: boolean;
  chainId: Number;
  mainNetDetail: any;
  // disconnect: any;
}

export interface IAction {
  type: string;
  payload: any;
  text: string;
}
