import React, {useState} from "react";
import {
    FlexColumEnd,
    FlexColumn,
    FlexRowAround,
    FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace
} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import CommonService from "../../services/common.service";
import Button34 from "../common/button/Button34";
import CopyToClipboard from "react-copy-to-clipboard";
import {Confirm_Map, Event_Map, PayType_Map} from "../../pages/MyPoint";
import {FadeLoader} from "react-spinners";
import Popup from "../../services/popup.service";
import {JsonData} from "../../common/dto/CommonDto";
import {decimalFormat, ellipseAddress} from "../../common/js/utilities";
import moment from "moment";
import {Trans, useTranslation} from "react-i18next";
interface IPointSndHisItem {
    index:number;
    item:any;
    monthResult:any;
}

    // 'N':'신청중',
    // 'C':'취소중',
    // 'I':'입금확인중',
    // 'F':'충전 실패',
    // 'Y':'충전 완료'

export default function PointRcvHisItem(props:IPointSndHisItem) {
    const { t } = useTranslation();
    const popupView = (item:any)=>{

        const alertHtml = (
            <FlexColumn gap={20}>
                <FlexColumn gap={20}>
                {
                    (item.confirmType === 'N' || item.confirmType === 'I') ?
                        (
                            <FlexRowCenter><Trans t={t} i18nKey={"포인트 충전을 위해 <br />입금을 확인 중이에요"}/></FlexRowCenter>
                        )
                        :
                        (
                            <>
                            <FlexRowCenter>
                                {
                                    item.confirmType === 'Y' ?
                                        (<img src={require('../../assets/icons/confirm.png')} width={64} />)
                                        :
                                        (<img src={require('../../assets/icons/error.png')} width={64} />)
                                }
                            </FlexRowCenter>
                            <FlexRowCenter>
                                {t("포인트 {0} 했어요", {0:Confirm_Map[item.confirmType]})}
                            </FlexRowCenter>
                            </>
                        )
                }
                </FlexColumn>
                <FlexColumn style={{backgroundColor:'#f2f4f6', padding:'20px'}} gap={8}>
                    <FlexRowSpace>
                        <div className={styles.txt13_B05}>{t("충전포인트")}</div>
                        <div className={styles.txt13_B}>{decimalFormat(item.ziglePoint)}</div>
                    </FlexRowSpace>
                    <FlexRowSpace>
                        <div className={styles.txt13_B05}>{t("입금금액")}</div>
                        <div className={styles.txt13_300_B05}>{decimalFormat(item.amount)} {item.payType==='T' && item.symbolName}</div>
                    </FlexRowSpace>
                    {
                        item.payType==='T' ? (
                            <FlexRowSpace>
                                <div className={styles.txt13_B05}>TXID</div>
                                <div className={styles.txt13_300_B05}>
                                    {ellipseAddress(item.txId, 10)}
                                </div>
                            </FlexRowSpace>
                        )
                        :
                        (
                            <>
                                <FlexRowSpace>
                                    <div className={styles.txt13_B05}>{t("입금은행")}</div>
                                    <div className={styles.txt13_300_B05}>
                                        {item.bankName ? item.bankName + " " + item.toAddr : null}
                                    </div>
                                </FlexRowSpace>
                                <FlexRowSpace>
                                    <div className={styles.txt13_B05}>{t("입금내용")}</div>
                                    <div className={styles.txt13_300_B05}>
                                        {item.bankUser}
                                    </div>
                                </FlexRowSpace>
                            </>
                        )
                    }
                </FlexColumn>
            </FlexColumn>
        );
        Popup.alertMobile(alertHtml, () => {}, Confirm_Map[item.confirmType]);
    }
    return (
        <FlexColumn gap={16}>
            {
                props.monthResult ==="Y" &&
                (
                    props.index !==0 ?
                        (
                            <FlexColumn gap={24}>
                                <FlexColumn className={styles.line}></FlexColumn>
                                <FlexColumn className={styles.txt13_B04}>
                                    {moment(props.item.regDate).format('MM월DD일')}
                                </FlexColumn>
                            </FlexColumn>
                        )
                        :
                        (<FlexColumn className={styles.txt13_B04}>
                            {moment(props.item.regDate).format('MM월DD일')}
                        </FlexColumn>)
                )
            }
            <FlexRowAround>
                <FlexColumn gap={5}>
                    <div className={styles.txt16_B02}>
                        {PayType_Map[props.item.payType]}
                        {props.item.payType ==='T' && ("("+props.item.symbolName+")")}
                    </div>
                    <div className={styles.txt12_B05}>{moment(props.item.regDate).format('HH:mm')}</div>
                </FlexColumn>
                <FlexColumEnd gap={5}>
                    <div className={styles.txt18_500_Point_B}>+{decimalFormat(props.item.ziglePoint)}</div>
                    <div className={styles.txt12_B05}  style={{textDecoration:'Underline'}}
                    onClick={()=>{
                        popupView(props.item);
                    }}>
                        {Confirm_Map[props.item.confirmType]}
                    </div>
                </FlexColumEnd>
            </FlexRowAround>
        </FlexColumn>

    );
};