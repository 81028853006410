import axios from "axios";
import ProfileService, { getHeader } from "./profile.service";
import { checkStatus401, checkStatus401RstType } from "./common.service";
import { JsonData } from "../common/dto/CommonDto";
import { Toast } from "../components/common/popup/ToastAlert";

let API_URL = "";
if (process.env.REACT_APP_ENVTEXT === "local") {
  API_URL = "/api";
} else {
  API_URL = process.env.REACT_APP_API_URL ?? "https://api.zigle.link/api";
}

export interface PaymentResDto {
    serviceId? : string          //           glx_api             //테스트 아이디 일반결제 : glx_api
    serviceCode? : ["CREADIT", "HP","BANK"],          //
    serviceType? : string          //           glx_api             //테스트 아이디 일반결제 : glx_api
    userId? : string         //               user_id
    itemName? : string           //       테스트상품_123
    itemCode? : string           //           item_code
    amount? : string         //           1004
    userName? : string           //       홍길동
    userEmail? : string          //       test@test.com
    orderDate? : string          //       year+month+date+hour+minute+second ;
    orderId? : string            //           test_"+orderDate;
    returnUrl? : string          //           http://127.0.0.1/BillgatePay-JSP/PayReturn.jsp  // *가맹점 수정 필수
    checkSum? : string           //
    cancelFlag? : string         //       Y
    reserved1? : string          //       예비변수1
    reserved2? : string          //       예비변수2
    reserved3? : string          //       예비변수3
}

export interface PaymentReqDto {
    amount : number
    serviceType : string
}

const checksum = (
    req:PaymentReqDto
)  => {
    return axios
        .get(
            API_URL +
            "/v1/user/payment/checksum/"+req.serviceType+"/"+req.amount,
            {
                params: null,
                headers: {
                    ...getHeader(),
                },
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            checkStatus401(error.response);
        });
};

const PaymentService = {
    checksum,

};

export default PaymentService;
