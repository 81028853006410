import React, {useEffect, useState} from "react";
import {
    FlexColumEnd,
    FlexColumn,
    FlexRowAround,
    FlexRowCenter,
    FlexRowEnd, FlexRowSpace,
    FlexRowStart
} from "../common/container/PageContainer";
import styled from "styled-components";
import styles from "../../common/css/zigle.module.scss";
import ReactPlaceholder from "react-placeholder";
import CommonService from "../../services/common.service";
import {decimalFormat} from "../../common/js/utilities";
import {useTranslation} from "react-i18next";


const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 80px;
  border-radius: 16px;
  border: solid 1px #f2f4f6;
`;

const SymbolImg = styled.div`
  width: 38px;
  height: 38px;
`;

const CryptoInfoWarpper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;


interface ILinkItemProps {
    linkItem:any;
    onClick?: () => void;
}
export default function LinkItem({linkItem, onClick}:ILinkItemProps) {
    const { t } = useTranslation();
    return (
        <FlexColumn key={linkItem.linkMngId} style={{height:'80px', padding:'20px 16px', borderRadius:'16px', border:'solid 1px #f2f4f6'}} onClick={() => {
            if (onClick) {
                onClick();
            }
        }}>
            <FlexRowSpace>
                <FlexRowStart>
                    <div style={{paddingRight:'12px'}}>
                        <img src={linkItem.tnCoinInfo?.symbolImgFileId} alt={"symbol"} width={38} />
                    </div>
                    <FlexColumn>
                        <div className={styles.txt15_B02} style={{width:'98px', textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden'}}>{linkItem.linkName}</div>
                         <FlexRowStart gap={4}>
                            <div className={styles.ellipse_151}></div>
                             <div className={styles.txt12_B04} style={{width:'98px', textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden'}}>{t(linkItem.tnMainnetInfo.displayName)}</div>
                         </FlexRowStart>
                    </FlexColumn>
                </FlexRowStart>
                <FlexColumEnd>
                    <div className={styles.txt12_B05}>
                        { linkItem.linksTypeCode === 'SP' ? t('지정 수량') : t('자유 수량')}
                    </div>
                    <div className={styles.txt16_B01}>
                        {decimalFormat(linkItem.amount)}<span style={{paddingLeft:'2px'}} className={styles.txt13_B05}>{linkItem.tnCoinInfo?.symbolName}</span>
                    </div>
                </FlexColumEnd>
            </FlexRowSpace>
        </FlexColumn>
    )
}