
import PageTitle, {
    FlexBottom,
    FlexBottomStart,
    FlexColumn, FlexColumnCenter,
    FlexRowAround, FlexRowCenter, FlexRowSpace, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../common/container/PageContainer";
import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import MetaMaskService from "../../services/metamask.service";
import styles from "../../common/css/zigle.module.scss";
import BottomButton from "../common/button/BottomButton";
import DefaultInput from "../common/input/DefaultInput";
import {useForm} from "react-hook-form";
import bigDecimal from "js-big-decimal";
import CommonService from "../../services/common.service";
import AirdropService from "../../services/airdrop.service";
import PopupService from "../../services/popup.service";
import {ethers} from "ethers";
import {decimalFormat} from "../../common/js/utilities";
import {ToastError} from "../common/popup/ToastAlert";
import {useAccount} from "wagmi";
import {useWalletEthersPorvider} from "../../common/js/ethersProviderHook";
import {useTranslation} from "react-i18next";

interface IAirdropFeeOut {
    popupOut:boolean;
    setPopupOut:any;
    popupItem:any;
    reLoad:boolean;
    setReLoad:any;
}
export default function AirdropFeeOut(props:IAirdropFeeOut) {
    const { t } = useTranslation();
    const [home, setHome] = useState(true);
    const chainId = useSelector((state:any) => state.walletReducer.chainId);

    const { address, isConnected } = useAccount();

    const [btnActive, setBtnActive] = useState(false);
    const [amount, setAmount] = useState("0");
    const [gasFee, setGasFee] = useState("0");
    const zigleWalletAddress = process.env.REACT_APP_WALLET_ADDRESS;
    const provider = useWalletEthersPorvider();

    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
    } = useForm();
    const formData = useRef({

    });
    const onSubmit = () => {
        if(!address){
            ToastError(t("지갑연결을 확인해 주세요."));
            return false;
        }

        if (chainId != props.popupItem.mainnetInfo.chainId) {
            MetaMaskService.setSwitchNetwork(provider,parseInt(props.popupItem.mainnetInfo.chainId), props.popupItem.mainnetInfo.mainnetName, props.popupItem.mainnetInfo.symbolName, props.popupItem.mainnetInfo.decimalVal, props.popupItem.mainnetInfo.rpcUrl, props.popupItem.mainnetInfo.explorerUrl, '').then(
                (response) => {
                    if (!response.status) {
                    }
                }
            );
        } else {
            formData.current = {
                accountType : "O",
                mainnetId:props.popupItem.mainnetInfo.mainnetId,
                feeAmount: amount,
                transactionCost: gasFee,
                fromAddr: zigleWalletAddress!.toString(),
                receiveAddr: address
            }
            AirdropService.airdropFeeTransfer(formData.current).then((res)=>{
                if(res.status) {
                    PopupService.newPopup(t("출금 신청 완료")
                        , t("네트워크 상황에 따라 다소 시간이 걸릴 수 있습니다.")
                        , ""
                        , {
                            btnTxt: t("확인"),
                            onClick: () => {
                                props.setReLoad(!props.reLoad);
                                props.setPopupOut(false);
                            }
                        }
                        , {}
                    );
                }
            });
        }
    }
    useEffect(()=>{
        async function fetchData() {
            //0.000000003
            let rpcurl = props.popupItem.mainnetInfo.rpcUrl;
            if(props.popupItem.mainnetInfo.chainId == "1")
            {
                rpcurl += process.env.REACT_APP_INFURA_KEY
            }
            const provider = new ethers.providers.JsonRpcProvider(
                rpcurl
            );
            const gas = await provider.getGasPrice();
            const tmpGas = new bigDecimal(ethers.utils.formatUnits(gas, props.popupItem.mainnetInfo.decimalVal)).multiply(new bigDecimal(21000));
            setGasFee(tmpGas.getValue());
        }
        if(props.popupItem){
            fetchData();
        }


    },[props.popupItem])
    return (
        <>
            {
                props.popupOut && (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle
                                    home={home}
                                    textTitle={props.popupItem.mainnetInfo?.symbolName+ t("수수료 출금")}
                                    onClickLeft={()=>{
                                        props.setPopupOut(false);
                                    }}
                                />
                                <PageContent>
                                    <FlexColumn gap={8}>
                                        <FlexRowCenter>
                                            <div style={{width: '150px'}}>
                                                <DefaultInput
                                                    style={{width: '100px'}}
                                                    error={errors.amount}
                                                    inputElement={
                                                        <input
                                                            type={"text"}
                                                            className={styles.inputText_33}
                                                            placeholder={t("코인 수량 입력")}
                                                            value={amount}
                                                            {...register("amount", {
                                                                required: t("코인 수량 입력"),
                                                                onChange: (el) => {
                                                                    setAmount(el.target.value);

                                                                    if (new bigDecimal(el.target.value).compareTo(new bigDecimal(0)) <= 0) {
                                                                        setError("amount", {
                                                                            type: 'custom',
                                                                            message: t('수량을 입력해 주세요.')
                                                                        });
                                                                        setBtnActive(false);
                                                                    } else if ((new bigDecimal(props.popupItem.balance).subtract(new bigDecimal(props.popupItem.balanceLock)).subtract(new bigDecimal(gasFee))).round(4).compareTo(new bigDecimal(el.target.value)) >= 0) {

                                                                        setBtnActive(true);
                                                                        clearErrors('amount');
                                                                    } else {
                                                                        setError("amount", {
                                                                            type: 'custom',
                                                                            message: t("최대 {0}까지 가능합니다.", {0:decimalFormat((new bigDecimal(props.popupItem.balance).subtract(new bigDecimal(props.popupItem.balanceLock))).getValue(), 4)})
                                                                        });
                                                                        setBtnActive(false);
                                                                    }
                                                                }
                                                            })}
                                                        />
                                                    }
                                                />
                                            </div>
                                            <div style={{
                                                marginBottom: '24px',
                                                color: '#dbdee6'
                                            }}>{props.popupItem.mainnetInfo.symbolName}</div>
                                        </FlexRowCenter>
                                        <FlexRowCenter>
                                            <div className={styles.txt13_B04} style={{
                                                borderRadius: '8px',
                                                textAlign: 'center',
                                                padding: '10px 16px',
                                                backgroundColor: '#f2f4f6'
                                            }}
                                                 onClick={()=>{
                                                     if((new bigDecimal(props.popupItem.balance).subtract(new bigDecimal(gasFee)).subtract(new bigDecimal(props.popupItem.balanceLock))).compareTo(new bigDecimal(0)) > 0) {
                                                         setAmount(decimalFormat((new bigDecimal(props.popupItem.balance).subtract(new bigDecimal(gasFee)).subtract(new bigDecimal(props.popupItem.balanceLock))).getValue(), 4));
                                                         setBtnActive(true);
                                                         clearErrors('amount');
                                                     }
                                                 }}
                                            >
                                                {t("출금 가능 수량")} {decimalFormat((new bigDecimal(props.popupItem.balance).subtract(new bigDecimal(props.popupItem.balanceLock))).getValue(), 4) } {props.popupItem.mainnetInfo.symbolName}
                                            </div>
                                        </FlexRowCenter>
                                        <FlexColumnCenter className={styles.txt13_red}>
                                            {t("현재 평균 수수료")} {decimalFormat(gasFee, 13)} {props.popupItem.mainnetInfo.symbolName}
                                        </FlexColumnCenter>
                                    </FlexColumn>
                                    <FlexBottom>
                                        <BottomButton
                                            isActive={btnActive}
                                            backgroundColor={"#3a71ff"}
                                            textColor={"white"}
                                            // border={"solid 1px #dbdee6"}
                                            onClick={handleSubmit(onSubmit)}
                                            text={t("다음")}
                                        />
                                    </FlexBottom>
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
            </>
    )
}