import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import PageTitle, {
    FlexBottom,
    FlexColumn, FlexRowAround,
    PageContent
} from "../common/container/PageContainer";
import {useForm} from "react-hook-form";
import DefaultInput from "../common/input/DefaultInput";
import styles from "../../common/css/zigle.module.scss";
import BottomButton from "../common/button/BottomButton";
import styled from "styled-components";
import {TextField} from "@material-ui/core";
import AssetService from "../../services/asset.service";
import PopAlert from "../common/popup/PopAlert";
import PopupService from "../../services/popup.service";
import {useTranslation} from "react-i18next";


const HidddenInputBox = styled.input`
  display: none;
`;

interface ITokenWriteProps {
    setPageMode?: any;
}
export default function TokenWrite(props:ITokenWriteProps) {
    const { t } = useTranslation();

    const inputRef = useRef<HTMLFormElement>(null);
    const {
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit,
    } = useForm();

    const handleSymbolImageUploader = () => {
        inputRef.current?.symbolImageFile.click();
    };

    const [btnActive, setBtnActive] = useState(false);
    const navigate = useNavigate();
    const onSubmit = (data: any) => {
        console.info("data", data);
        console.info("errors", errors);
        const params = new FormData();
        params.append('mainnetName', data.mainName);
        params.append('chainId', data.chainId);
        params.append('rpcUrl', data.rpcUrl);
        params.append('explorerUrl', data.explorerUrl);
        params.append('symbolName', data.currencySymbol);
        params.append('symbolImg', data.symbolImageFile[0]);
        params.append('desc', data.desc);

        AssetService.mainnetInsert(params).then(
            (response) => {
               // PopAlert('', ()=>navigate("/findcoin") ,t('coinList.write.submitOkTitle'));
                if(response.status)
                {
                    PopupService.newPopup(t("메인넷추가 요청 성공")
                        , t("메인넷 추가 요청을 성공했어요.")
                        , ""
                        ,{btnTxt:t("확인 했어요"),
                            onClick: () => {
                                props.setPageMode('list');
                            }}
                        , {}
                    );

                }
            }
        )
    };
    useEffect(()=>{
        if(isDirty === true && isValid === true)
        {
            setBtnActive(true);
        }
    },[isDirty, isValid])
    return (
        <FlexColumn>
            <FlexColumn>
                <form ref={inputRef} onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                    <DefaultInput
                        lable={t("메인넷 이름")}
                        error={errors.mainName}
                        icon={require("../../assets/icons/input_x.png")}
                        iconClick={() => {
                           let el = inputRef.current?.mainName;
                            el.value ='';
                        }}
                        inputElement={
                            <input
                                type={"text"}
                                className={styles.inputText_44}
                                placeholder={t("메인넷명을 입력해주세요.")}
                                {...register("mainName", {
                                    required: t("메인넷명을 입력해주세요.")
                                })}
                            />
                        }
                    />
                    <DefaultInput
                        lable={t("체인 ID")}
                        error={errors.chainId}
                        icon={require("../../assets/icons/input_x.png")}
                        iconClick={() => {
                            let el = inputRef.current?.chainId;
                            el.value ='';
                        }}
                        inputElement={
                            <input
                                type={"text"}
                                className={styles.inputText_44}
                                placeholder={t("체인ID을 입력해주세요.")}
                                {...register("chainId", {
                                    required: t("체인ID을 입력해주세요.")
                                })}
                            />
                        }
                    />
                    <DefaultInput
                        lable={t("RPC URL")}
                        error={errors.rpcUrl}
                        icon={require("../../assets/icons/input_x.png")}
                        iconClick={() => {
                            let el = inputRef.current?.rpcUrl;
                            el.value ='';
                        }}
                        inputElement={
                            <input
                                type={"text"}
                                className={styles.inputText_44}
                                placeholder={t("RPC URL을 입력해주세요.")}
                                {...register("rpcUrl", {
                                    required: t("RPC URL을 입력해주세요.")
                                })}
                            />
                        }
                    />
                    <DefaultInput
                        lable={t("Explorer URL")}
                        error={errors.explorerUrl}
                        icon={require("../../assets/icons/input_x.png")}
                        iconClick={() => {
                            let el = inputRef.current?.explorerUrl;
                            el.value ='';
                        }}
                        inputElement={
                            <input
                                type={"text"}
                                className={styles.inputText_44}
                                placeholder={t("EXPLORER URL명을 입력해주세요.")}
                                {...register("explorerUrl", {
                                    required: t("EXPLORER URL을 입력해주세요."),
                                    minLength:2
                                })}
                            />
                        }
                    />
                    <FlexRowAround>
                        <div style={{width:'100%'}}>
                            <DefaultInput
                            lable={t("심볼명")}
                            error={errors.currencySymbol}
                            icon={require("../../assets/icons/input_x.png")}
                            iconClick={() => {
                                let el = inputRef.current?.currencySymbol;
                                el.value ='';
                            }}
                            inputElement={
                                <input
                                    type={"text"}
                                    className={styles.inputText_44}
                                    placeholder={t("심볼명을 입력해주세요.")}
                                    {...register("currencySymbol", {
                                        required: t("심볼명을 입력해주세요."),
                                        minLength:2
                                    })}
                                />
                            }
                            />
                        </div>
                        <div style={{width:'30%', height:'50px'}}>

                                    <HidddenInputBox
                                        type="file"
                                        {...register("symbolImageFile", {
                                            required: t("symbolImageFile을 입력해주세요.")
                                        })}
                                    />
                            <div className={styles.img_btn}  onClick={() => {handleSymbolImageUploader();}}>
                                {t("이미지 첨부")}
                            </div>
                        </div>
                    </FlexRowAround>
                    <DefaultInput
                        lable={"설명"}
                        error={errors.desc}
                        inputElement={
                            <TextField
                                className={styles.textAreaBox}
                                placeholder={t("설명을 입력해주세요")}
                                multiline
                                minRows={3}
                                variant="outlined"
                                {...register("desc", {
                                })}
                            />
                        }
                    />
                </form>
            </FlexColumn>
            <FlexBottom>
                <BottomButton
                    isActive={btnActive}
                    backgroundColor={"#3a71ff"}
                    textColor={"white"}
                    // border={"solid 1px #dbdee6"}
                    onClick={handleSubmit(onSubmit)}
                    text={t("다음")}
                />
            </FlexBottom>
        </FlexColumn>
    )
}