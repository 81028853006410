import {FlexColumEnd, FlexColumn, FlexRowAround, FlexRowStart} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import {decimalFormat, ellipseAddress} from "../../common/js/utilities";
import React from "react";
import {useTranslation} from "react-i18next";


interface IMiningHisItem{

    index:number;
    item:any;
    monthResult:any;
}

export default function MiningHisItem(props:IMiningHisItem){
    const { t } = useTranslation();
    return (
        <FlexColumn gap={16}>
            {
                props.monthResult ==="Y" &&
                (<FlexColumn className={styles.txt13_B04}>{new Date(props.item.modDtm).getMonth()+1 +"월 "+new Date(props.item.modDtm).getDate()+"일"}</FlexColumn>)
            }
                <FlexRowAround>
                    <FlexRowStart className={styles.txt16_B02}>
                            {props.item.miningType === 'P' ? t("개인보상") : t("단체보상")}
                    </FlexRowStart>
                    <FlexColumEnd  className={styles.txt18_700_B01}>
                        { props.item.orderType === 'I' ? '+' : '-'}{decimalFormat(props.item.amount)}
                    </FlexColumEnd>
                </FlexRowAround>
        </FlexColumn>
    )
}