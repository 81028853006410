import React, {useState} from "react";
import {
    FlexColumEnd,
    FlexColumn,
    FlexRowAround,
    FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace
} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import CommonService from "../../services/common.service";
import Button34 from "../common/button/Button34";
import CopyToClipboard from "react-copy-to-clipboard";
import {Confirm_Map, Event_Map, PayType_Map} from "../../pages/MyPoint";
import {FadeLoader} from "react-spinners";
import Popup from "../../services/popup.service";
import {JsonData} from "../../common/dto/CommonDto";
import moment from "moment";
import {useTranslation} from "react-i18next";

interface IAirdropFeeHisItem {
    index:number;
    item:any;
    monthResult:any;
}


export default function AirdropFeeHisItem(props:IAirdropFeeHisItem) {
    const { t } = useTranslation();
    return (
        <FlexColumn gap={16}>
            {
                props.monthResult ==="Y" &&
                (
                    props.index !==0 ?
                        (
                            <FlexColumn gap={24}>
                                <FlexColumn className={styles.line}></FlexColumn>
                                <FlexColumn className={styles.txt13_B04}>{moment(props.item.regDate).format('MM월DD일')}</FlexColumn>
                            </FlexColumn>
                        )
                        :
                        (<FlexColumn className={styles.txt13_B04}>{moment(props.item.regDate).format('MM월DD일')}</FlexColumn>)
                )
            }
            <FlexRowAround>
                <FlexColumn gap={5}>
                    <div className={styles.txt16_B02}>
                        {
                            props.item.modeType === "FEE" ? (
                                props.item.modeTitle === "I" ? (t("수수료 입금")) : (t("수수료 출금"))
                            ) : (
                                props.item.modeTitle
                            )
                        }
                    </div>
                    <div className={styles.txt14_B05}>
                        {
                            props.item.modeType === "AIR" ? (
                                props.item.airdropType === "airdropDe" ? (t("지정인원 {0}명", {0:props.item.airdropCnt})) : (t("선착순 {0}명", {0:props.item.airdropCnt}))
                            ) : null
                        }
                    </div>
                    <div className={styles.txt12_B05}>{moment(props.item.regDate).format('HH:mm')}</div>
                </FlexColumn>
                <FlexColumEnd gap={5}>
                    <div className={styles.txt18_700_B01}>{
                        props.item.modeType === "FEE" ? (
                            props.item.modeTitle === "I" ? ("+"+ props.item.feeAmount) : ("-"+ props.item.feeAmount)
                        ) : (
                            props.item.feeAmount && ("-"+props.item.feeAmount)
                        )
                    }</div>
                </FlexColumEnd>
            </FlexRowAround>
        </FlexColumn>

    );
};