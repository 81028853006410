
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useInView} from "react-intersection-observer";
import {IAirdropList} from "../../common/dto/AirdropList";
import AirdropService from "../../services/airdrop.service";
import EmptyContent from "../common/EmptyContent";
import AirdropItem from "./AirdropItem";
import MyAirdropItem from "./MyAirDropItem";
import {useTranslation} from "react-i18next";

interface IAirdropMode {
    modeType : any;
    subType : any;
    pageMode:any;
    reLoad:any;
    onClickViewItem?:(item: any)=> void;
}
export default function AirdropList({modeType, subType, pageMode, onClickViewItem, reLoad} : IAirdropMode) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [airdropList, setAirdropList] = useState<Array<IAirdropList>>([]);
    const [currPage, setCurrPage] = useState(1);
    const [blockSize] = useState(10);
    const [searchTxt, setSearchTxt] = useState('');
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);

    const [reSet, setReSet] = useState(false);

    useEffect(() => {
        const fetchData = async() =>{
            const response = await AirdropService.airdropList(currPage, blockSize, modeType, subType, pageMode);
            setAirdropList([...airdropList, ...response.data]);
            if (!response.data.length)  {
                setLastList(true);
                return;
            }
        }

        if (!lastList) {
            fetchData();
        }
    },[currPage, reSet]);
    useEffect(()=>{
        if(reLoad !== reSet){
            setAirdropList([]);
            setLastList(false);
            if(currPage ===1){
                setReSet(reLoad);
            }else{
                setCurrPage(1);
            }
        }
    },[reLoad]);

    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);

    return (
        <>
            {
                airdropList.length > 0 ?
                    (
                        airdropList.map((item, index)=>{

                            return (
                                pageMode === "MY" ? (

                                    <MyAirdropItem key={item.airdropMngId} itemDetail={item} onClickViewItem={onClickViewItem}/>
                                ) : (
                                    <AirdropItem key={item.airdropMngId} itemDetail={item}/>
                                    )
                            )}
                        )
                    )
                    :
                    (
                        <EmptyContent titleText={ modeType === 'I' ? t('진행 예정인 에어드랍이 없어요.') : modeType === 'E' ? t('종료된 에어드랍이 없어요.') : t('진행중인 에어드랍이 없어요.')} titleImage={require('../../assets/icons/empty_icon.png')}/>
                    )
            }
            {
                Object.values(airdropList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
            }
        </>
    )
}