import axios from "axios";
import ProfileService, { getHeader } from "./profile.service";
import bigDecimal from "js-big-decimal";
import PopupService from "./popup.service";
import {Toast, ToastError} from "../components/common/popup/ToastAlert";

let API_URL = "";
if (process.env.REACT_APP_ENVTEXT === "local") {
  API_URL = "/api";
} else {
  API_URL = process.env.REACT_APP_API_URL ?? "https://api.zigle.link/api";
}


const codeList = (grpCode: string) => {
  return axios
      .get(API_URL + "/comm/codelist", {
        params: {
          grpCode: grpCode,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error: any) => {
        checkStatus401(error.response);
      });
};
const statistics = () => {
  return axios
      .get(API_URL + "/v1/anony/comm/statistics")
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        checkStatus401(error.response);
      });
};
const transactionInsert = (
    txId: string,
    amount: string,
    fromAddr: string,
    toAddr: string,
    mainnetId: string,
    coinId: string,
    linkMngId: string,
    receiveUserId: string,
    message:string
) => {
  const params = new URLSearchParams();
    params.append("txId", txId);
    params.append("amount", amount);
    params.append("fromAddr", fromAddr);
    params.append("toAddr", toAddr);
    params.append("mainnetId", mainnetId);
    params.append("coinId", coinId);
    params.append("linkMngId", linkMngId);
    params.append("receiveUserId", receiveUserId);
    params.append("message", message);
  return axios
      .post(API_URL + "/v1/anony/trans/transaction", params, {
          headers: {
              ...getHeader(),
          }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        checkStatus401(error.response);
      });
};
const coinlist = (
    searchTxt: string,
    currentPage: number,
    blockSize: number
) => {
  return axios
      .get(API_URL + "/v1/anony/trans/coinlist", {
        params: {
          searchTxt: searchTxt,
          currentPage: currentPage,
          blockSize: blockSize,
          pagingYn: "Y",
        },
      })
      .then((response) => {
        return response.data;
      });
};
const mainnetList = (
    searchTxt: string,
    currentPage: number,
    blockSize: number,
    phoneUse: string
) => {
  return axios
      .get(API_URL + "/v1/anony/trans/mainnetlist", {
        params: {
          phoneUse: phoneUse,
          searchTxt: searchTxt,
          currentPage: currentPage,
          blockSize: blockSize,
          pagingYn: "Y",
        },
      })
      .then((response) => {
        return response.data;
      });
};
const getSnsLinkList = (snsText: string) => {
  ///link/{urlKey}/info
  return axios
      .get(API_URL + "/v1/anony/link/" + snsText + "/info")
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        checkStatus401(error.response);
      });
};


const getMistList = (walletAddress: string) => {
    return axios
        .get(API_URL + "/v1/anony/trans/buyList/mistCoin/" + walletAddress)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            checkStatus401(error.response);
        });
};


const chainIdTokenLink = (chainId?: string, coinLinkName?: string) => {
    return axios
        .get(API_URL + "/v1/anony/trans/" + chainId + "/coin/" + coinLinkName)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            checkStatus401(error.response);
        });
};
const chainIdTokenList = (
    chainId: string,
    codeType: string,
    searchTxt: string,
    currentPage?: number,
    blockSize?: number
) => {
  //codeType : mainnet, coin, mainnetDetail
  return axios
      .get(API_URL + "/v1/anony/trans/" + chainId + "/" + codeType, {
        params: {
            searchTxt: searchTxt,
          currentPage: currentPage,
          blockSize: blockSize,
          pagingYn: "Y",
        },
          headers: {
              ...getHeader(),
          }
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        checkStatus401(error.response);
      });
};
const donameSearch = (doname: string) => {
  return axios
      .get(API_URL + "/v1/anony/accnt/userid", {
        params: {
          doname: doname,
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        checkStatus401(error.response);
      });
};
const donamelist = (doname: string, currentPage: number, blockSize: number, orderBy:string) => {
  return axios
      .get(API_URL + "/v1/anony/accnt/donames", {
        params: {
            searchTxt: doname,
          currentPage: currentPage,
          blockSize: blockSize,
          pagingYn: "Y",
          orderBy:orderBy
        },
        headers: {
          ...getHeader(),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        checkStatus401(error.response);
      });
};
const friendCnt = (zigleIdx:string) => {
    return axios
        .get(API_URL + "/v1/anony/accnt/" + zigleIdx + "/friendCnt", {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            checkStatus401(error.response);
        });
}
const friendList = (
    userId: string,
    currentPage: number,
    blockSize: number,
    modeType:string
) => {
    return axios
        .get(API_URL + "/v1/anony/accnt/friendList", {
            params: {
                userId: userId,
                currentPage: currentPage,
                blockSize: blockSize,
                modeType:modeType
            },
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            checkStatus401(error.response);
        });
};
const friendUserInfo = (zigleIdx: string) => {
  return axios
      .get(API_URL + "/v1/anony/accnt/" + zigleIdx + "/detail", {
        headers: {
          ...getHeader(),
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        checkStatus401(error.response);
      });
};
const getWalletLoginAddress = (userId:string) =>{
    return axios
        .get(API_URL + "/v1/anony/accnt/" + userId + "/adress", {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            checkStatus401(error.response);
        });
};



const getSelectCountry = () =>{
    return axios
        .get(API_URL + "/v1/anony/accnt/select_country", {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            checkStatus401(error.response);
        });
};


export const checkStatus401 = (response: any): void => {
  if (response.status === 401) {
      PopupService.newPopup("Error"
          , response.message
          , ""
          ,{btnTxt:"확인 했어요",
              onClick: () => {
              }}
          , {}
      );
    ProfileService.logout();
    window.location.href = "/walletLogin";
  } else if (response.status === 504) {
    console.warn(response.data);
  } else {
    if (response.data.failStack && response.data.failStack[0]?.error) {
        PopupService.newPopup(""
            , response.data.failStack[0]?.error
            , ""
            ,{btnTxt:"확인 했어요",
                onClick: () => {
                }}
            , {}
        );
    } else {
        PopupService.newPopup("Error"
            , response.data.message
            , ""
            ,{btnTxt:"확인 했어요",
                onClick: () => {
                }}
            , {}
        );
    }
  }
};

export interface RstType {
  status: boolean;
  msg: string;
}

export const checkStatus401RstType = (response: any): RstType | void => {
  if (response.status === 401) {
      ToastError(response.data.message);
    ProfileService.logout();
    window.location.href = "/walletLogin";
  } else {
    let rst: RstType = {
      msg: "",
      status: false,
    };
    if (response.data.failStack && response.data.failStack[0]?.error) {
      rst.msg = response.data.failStack[0].error;
    }else{
        rst.msg = response.data.message;
    }
      ToastError(rst.msg);
    return rst;
  }
};

const coinMultiCodeList = (
    multiCode : string
)=>{
    return axios
        .get(API_URL + "/v1/anony/trans/multiCode/"+multiCode)
        .then((response) => {
            return response.data;
        });
}

const getProduct = (secretKey: string) => {
    return axios
        .get(API_URL + "/v1/anony/trans/getProduct/"+secretKey, {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        });
};
const phoneView = (urlKey: string) => {
    return axios
        .get(API_URL + "/v1/anony/accnt/" + urlKey + "/info", {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        });
};

const updateConfirmSms = (
    histHpId: string,
    toAddr: string,
    confirmTxt: string,
    pageType: string
) => {
    const params = new URLSearchParams();
    params.append("histHpId", histHpId);
    params.append("confirmTxt", confirmTxt);
    params.append("toAddr", toAddr);
    params.append("pageType", pageType);

    return axios
        .post(API_URL + "/v1/anony/accnt/confirmSms", params, {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return { status: true, msg: "" };
        })
        .catch((error) => {
            return checkStatus401RstType(error.response);
        });
};

const CommonService = {
    codeList,
    statistics,
    transactionInsert,
    mainnetList,
    coinlist,
    chainIdTokenList,
    donamelist,
    friendUserInfo,
    getSnsLinkList,
    donameSearch,
    chainIdTokenLink,
    coinMultiCodeList,
    getWalletLoginAddress,
    getSelectCountry,
    phoneView,
    getMistList,
    getProduct,
    updateConfirmSms,
    friendCnt,
    friendList
};

export default CommonService;
