import React, {ReactHTMLElement, useEffect, useRef, useState} from "react";
import PageTitle, {
    FlexBottom,
    FlexColumn, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../common/container/PageContainer";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import CopyToClipboard from "react-copy-to-clipboard";
import BottomButton from "../common/button/BottomButton";
import QRCode from "qrcode.react";
import DefaultInput from "../common/input/DefaultInput";
import {useForm} from "react-hook-form";
import AssetService from "../../services/asset.service";
import PopupService from "../../services/popup.service";
import {ILinkList} from "../../common/dto/LinkDto";
import {Toast, ToastError} from "../common/popup/ToastAlert";
import {useSelector} from "react-redux";
import ProfileService from "../../services/profile.service";
import {Trans, useTranslation} from "react-i18next";


interface IUpRefPopup {
    show: boolean;
    setShow: any;
    loginCheck:any;
}
function UpRefPopup(props:IUpRefPopup) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [btnActive, setBtnActive] = useState(false);
    const upRefCode = useSelector((state: any) => state.userReduce.upRefCode);
    const refCode = useSelector((state: any) => state.userReduce.refCode);
    const inputRef = useRef<HTMLFormElement>(null);
    const {
        setValue,
        register,
        setError,
        clearErrors,
        formState: { errors, isDirty, isValid },
        handleSubmit,
    } = useForm();
    const onSubmit = (data:any) => {
        if(refCode === data.upRefCode){
            ToastError(t("본인에 초대코드는 등록할 수 없습니다."));
        }else{
            ProfileService.updateUpRefCode(data.upRefCode).then((res)=>{
                if(res.status){
                    Toast(t("500P가 지급되었습니다."));
                    props.loginCheck();
                    props.setShow(false);
                }else{
                    ToastError(res.msg);
                }
            })
        }
    }

    useEffect(()=>{
        if(upRefCode){
            setBtnActive(false);
        }
    },[upRefCode])

    return (
        <>
            {
                props.show &&
                (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle
                                    textTitle ={t('초대 코드 입력')}
                                    onClickLeft={()=>{
                                        props.setShow(false);
                                    }}
                                />
                                <PageContent>
                                    <FlexColumn gap={32}>
                                        <FlexColumn  gap={4}>
                                            <FlexColumn className={styles.txt20_B01}>
                                                    <Trans t={t} className={styles.modifyProfileSpan} i18nKey={"초대코드를 입력하시면<br/><span>지글 포인트 500P를 드립니다!</span>"}/>
                                            </FlexColumn>
                                            <FlexColumn className={styles.txt13_B05}>
                                                <Trans t={t} i18nKey={"지글포인트는 코인 안전 송금 치 전화번호 송금,<br/>에어드랍 링크 생성에 사용 할 수 있어요."}/>
                                            </FlexColumn>
                                        </FlexColumn>
                                        <FlexColumn gap={3}>
                                            <DefaultInput
                                                lable={t("초대코드")}
                                                error={errors.upRefCode}
                                                inputElement={
                                                    <input
                                                        type={"text"}
                                                        className={styles.inputText_44}
                                                        defaultValue={upRefCode}
                                                        placeholder={t("초대코드를 입력해주세요.")}
                                                        {...register("upRefCode", {
                                                            required: t("초대코드를 입력해주세요.")
                                                        })}
                                                        onChange={(el)=>{
                                                            if(el.target.value){
                                                                setBtnActive(true)
                                                            }else{
                                                                setBtnActive(false)
                                                            }
                                                        }}
                                                    />
                                                }
                                            />
                                        </FlexColumn>
                                    </FlexColumn>
                                    <FlexBottom>
                                        <BottomButton
                                            isActive={btnActive}
                                            backgroundColor={"#3a71ff"}
                                            textColor={"white"}
                                            // border={"solid 1px #dbdee6"}
                                            onClick={handleSubmit(onSubmit)}
                                            text={t("확인")}
                                        />
                                    </FlexBottom>
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                        )
            }
        </>

    );
}

export default UpRefPopup;
