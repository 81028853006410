import {
    FlexBottomStart, FlexColumn,
    FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart
} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import BottomButton from "../common/button/BottomButton";
import React, {useEffect, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import TextareaAutosize from 'react-textarea-autosize';
import AirdropFriend from "./AirdropFriend";
import {IAirdropDeStep} from "./AirdropDeStep1";
import {ethers} from "ethers";
import CommonService from "../../services/common.service";
import MetaMaskService from "../../services/metamask.service";
import {useTranslation} from "react-i18next";

export default function AirdropDeStep2({airdropInfo, setAirdropInfo, setDepth}: IAirdropDeStep){
    const { t } = useTranslation();
    const [selectType, setSelectType] = useState('wallet');
    const [btnActive, setBtnActive] = useState(false);
    const [airdropList, setAirdropList] = useState('');
    const [show, setShow] = useState(false);
    const failCheck = useRef(0);
    const inputRef = useRef<HTMLFormElement>(null);
    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
    } = useForm();
    const onClickFriend = () =>{
        setShow(true);
    }

    const onSubmit = async(data: any) => {

        const userArray = airdropList.split(",");
        let setArray = userArray.filter((v, i) => userArray.indexOf(v) === i);
        setAirdropInfo({
            ...airdropInfo,
            tmpAirDropList:setArray.filter((value) => value != null)
        })
        // const resultList = await Promise.all(setArray.map(async(item, index) => {
        //     if(!item){
        //         return null;
        //     } else{
        //         const checkResult = await MetaMaskService.transUserCheck(item.replace(/\n/g, "").replace(/^\s+|\s+$/g, ""), airdropInfo.airdropSubType);
        //         if(checkResult === "N") {
        //             failCheck.current++;
        //         }
        //         return {
        //             usrId : item.replace(/\n/g, "").replace(/^\s+|\s+$/g, ""),
        //             delYn: "Y",
        //             checkYn :checkResult
        //         };
        //     }
        // }));
        // setAirdropInfo({
        //     ...airdropInfo,
        //     failCnt:failCheck.current,
        //     airdropCnt:resultList.filter((value) => value != null).length,
        //     airDropList:resultList.filter((value) => value != null)
        // });
        setDepth(2);
    }
    useEffect(()=>{
        if(inputRef.current)
        {
            inputRef.current?.airdropList.focus()
        }
        if(airdropList){
            setBtnActive(true);
        }else{
            setBtnActive(false);
        }
    },[airdropList]);
    return (
        <>
            <AirdropFriend show={show} setShow={setShow} setAirdropList={setAirdropList}/>
            <FlexColumn gap={19} style={{marginBottom:'200px' }}>
                <FlexRowSpace>
                    <FlexRowStart className={styles.txt20_500_B01}>{t('누구에게 보낼까요?')}</FlexRowStart>
                    <FlexRowEnd>
                        <FlexRowCenter className={styles.txt13_B05} style={{width:'140px', textAlign:'center', background:'#F2F4F6',padding:'4px', borderRadius:'8px'}}>
                            <div style={ selectType === 'wallet' ? {background:'#ffffff', color:'#2F3236', width:'66px', padding:'6px 8px', fontWeight:'700', borderRadius:'8px'} : { width:'66px',  padding:'6px 8px'} }
                                 onClick={()=>{
                                     if(selectType==='nick'){
                                         setAirdropList('');
                                         setAirdropInfo({
                                             ...airdropInfo,
                                             airdropSubType : 'wallet'
                                         })
                                     }
                                     setSelectType("wallet");
                                 }}>지갑주소</div>
                            <div style={ selectType === 'nick' ? {background:'#ffffff', color:'#2F3236', width:'66px', padding:'6px 8px', fontWeight:'700', borderRadius:'8px'} : { width:'66px',  padding:'6px 8px'} }
                                 onClick={()=>{
                                     if(selectType==='wallet'){
                                         setAirdropList('');
                                         setAirdropInfo({
                                             ...airdropInfo,
                                             airdropSubType : 'nick'
                                         })
                                     }
                                     setSelectType("nick");
                                 }}>닉네임</div>
                        </FlexRowCenter>
                    </FlexRowEnd>
                </FlexRowSpace>
                <form
                    ref={inputRef}
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ width: "100%" }}
                >
                    <TextareaAutosize
                        style={{border:'none'}}
                        className={styles.textAreaBox}
                        placeholder={selectType === 'wallet' ? t("지갑주소입력") : t("닉네임입력")}
                        value={airdropList}
                        {...register("airdropList", {
                            required: t('지갑주소입력'),
                            onChange: (el) => {
                                setAirdropList(el.target.value);
                            }
                        })}
                    />
                </form>
                {
                    selectType === 'nick' && (<div className={styles.txt13_B05} style={{textAlign:'center', padding:'10px 16px', background:'#F2F4F6', borderRadius:'8px'}} onClick={()=>onClickFriend()}>{t('친구 목록')}</div>)
                }
            </FlexColumn>
            <FlexBottomStart style={{top: 'calc(100% - 168px)'}} gap={20}>
                <FlexColumn className={styles.txt13_B04} gap={4} style={{padding:'20px', borderRadius:'16px', backgroundColor:'#F8F9FC'}}>
                    <div>
                        {t('지갑 목록에서 선택하거나 지갑 주소 또는 닉네임을 쉼표로 구분하여 입력하세요. 최대 100개까지 입력 가능합니다.')}
                    </div>
                    <div style={{fontWeight:'600'}}>
                        {t('예 ) 지갑주소1, 지갑주소2, 지갑주소3, 지갑주소4, ...')}
                    </div>
                </FlexColumn>
                <FlexColumn>
                    <BottomButton
                        isActive={btnActive}
                        backgroundColor={"#3a71ff"}
                        textColor={"white"}
                        // border={"solid 1px #dbdee6"}
                        onClick={handleSubmit(onSubmit)}
                        text={t("송금하기")}
                    />
                </FlexColumn>
            </FlexBottomStart>
        </>
    )
}