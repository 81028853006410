import React, {useEffect, useState} from "react";
import {
    FlexBottom, FlexBottom143,
    FlexBottom86, FlexBottomStart,
    FlexColumn, FlexRowAround,
    FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart
} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import CommonService from "../../services/common.service";
import CopyToClipboard from "react-copy-to-clipboard";
import DefaultInput from "../common/input/DefaultInput";
import {Checkbox} from "@material-ui/core";
import BottomButton from "../common/button/BottomButton";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import AssetService from "../../services/asset.service";
import PopupService from "../../services/popup.service";
import FavService from "../../services/fav.service";
import TokenPopup from "../tokenlist/TokenPopup";
import WalletPopup from "./WalletPopup";
import MetaMaskService from "../../services/metamask.service";
import {Toast, ToastError} from "../common/popup/ToastAlert";
import bigDecimal from "js-big-decimal";
import {decimalFormat} from "../../common/js/utilities";
import {useAccount, useBalance} from "wagmi";
import {useWalletEthersPorvider} from "../../common/js/ethersProviderHook";

interface ITransferStep {
    transferInfo:any;
    setTransferInfo:any;
    mainnetInfo:any;
    setDepth:any;
    handleOnClick?: (item: string)=> void;
    txIdResult?:(result:any,
                 resultMessage:any,
                 send_token_amount:any,
                 account:any,
                 to_address:any,
                 mainnetId:any,
                 coinId:any,
                 linkMngId:any)=> void;
}

export default function TransferPhone2(props: ITransferStep) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [btnActive, setBtnActive] = useState(false);
    const [service, setService] = useState<boolean>(false);

    const { address, isConnected } = useAccount();
    const hookCoinBalance = useBalance({
        address: address,
    });
    
    const chainId = useSelector((state:any) => state.walletReducer.chainId);
    const disconnect = useSelector((state:any) => state.walletReducer.disconnect);
    const ziglePoint = useSelector((state: any) => state.userReduce.ziglePoint);
    const provider = useWalletEthersPorvider();

    const [coinBalance , setCoinBalance] = useState('0')
    const [error, setError] = useState(false);

    const ref = React.useRef<HTMLInputElement>(null);

    const onSubmitClick = () => {
        if(!address){
            ToastError(t("지갑연결을 확인해 주세요."));
            return false;
        }
        if(chainId != props.mainnetInfo.chainId)
        {
            MetaMaskService.setSwitchNetwork(provider,parseInt(props.mainnetInfo.chainId), props.mainnetInfo.mainnetName, props.mainnetInfo.symbolName, props.mainnetInfo.decimalVal, props.mainnetInfo.rpcUrl, props.mainnetInfo.explorerUrl, '').then(
                (response) => {
                    if(!response.status)
                    {
                    }
                }
            );
        }else {

            if(props.transferInfo.zigleFee > ziglePoint){
                ToastError(t("Zigle point가 부족합니다."));
                return false;
            }
            props.setDepth(9);
        }
    }

    useEffect(()=>{
        const fetchData = async() =>{

            let balance = '0';
            if(props.transferInfo.coinTypeCode ==='TOKEN')
            {
                MetaMaskService.getBalance(address, props.transferInfo.coinContAddr)
                    .then((res) => {
                        balance = res ? res : '0';
                        setCoinBalance(balance);
                    });
            }else{
                balance = hookCoinBalance.data?.formatted ? hookCoinBalance.data?.formatted : '0';
                setCoinBalance(balance);
            }

        }
        fetchData();
        ref.current?.focus();
    },[]);

    useEffect(()=>{
        props.setTransferInfo(
            {...props.transferInfo,
                safeOn: service
            }
        );
    },[service])
    return (
        <FlexColumn>
            <FlexColumn gap={64}>
                  <FlexRowCenter>
                      <FlexRowCenter className={styles.wallet_logo_area} gap={4}>
                          <div  style={{width:'170px'}} className={styles.txt13_B04}>+{props.transferInfo.countryNum} {props.transferInfo.phoneNum}</div>

                          {/*<CopyToClipboard text={address} onCopy={() => Toast("주소를 복사 했습니다.")}>*/}
                          {/*    <img src={require("../../assets/icons/copy.png")} height={16} alt={"copy"} />*/}
                          {/*</CopyToClipboard>*/}
                      </FlexRowCenter>
                  </FlexRowCenter>
                  <FlexColumn gap={8}>
                      <FlexRowCenter>
                          <div style={{width:'150px'}}>
                              <DefaultInput
                                  style={{width:'100px'}}
                                  inputElement={
                                      <input
                                          ref={ref}
                                          type={"number"}
                                          className={styles.inputText_33}
                                          placeholder={t("받을 수량")}
                                          value={props.transferInfo.amount}
                                          onChange ={ (el) => {
                                              if((new bigDecimal(coinBalance).compareTo(new bigDecimal(el.target.value)) > 0) && (new bigDecimal(el.target.value).compareTo(new bigDecimal(0)) > 0)){
                                                  setBtnActive(true);
                                                  setError(false);
                                              }else{
                                                  if(new bigDecimal(el.target.value).compareTo(new bigDecimal(0)) > 0) {
                                                      setError(true);
                                                  }
                                                  setBtnActive(false);
                                              }
                                              props.setTransferInfo(
                                                  {...props.transferInfo,
                                                      amount: el.target.value
                                                  }
                                              );
                                          }}
                                      />
                                  }
                              />
                          </div>
                          <div style={{marginBottom:'24px', color: '#dbdee6'}}>{props.transferInfo.coinSymbolName}</div>
                      </FlexRowCenter>
                      {
                          error ? (
                              <>
                                  <FlexRowCenter className={styles.txt13_B05} style={{color:'#f01d1d'}}>
                                      {t("출금 가능 잔액이 부족해요.")}
                                  </FlexRowCenter>
                                  <FlexRowCenter>
                                      <div className={styles.txt13_B04} style={{borderRadius:'8px', textAlign:'center', color:'#f01d1d', padding:'10px 16px', backgroundColor:'#fff0f0'}}
                                           onClick={()=>{
                                               props.setTransferInfo(
                                                   {...props.transferInfo,
                                                       amount: decimalFormat(coinBalance)
                                                   }
                                               );
                                               setBtnActive(true);
                                               setError(false);
                                           }}>
                                          {t("{0} {1} 로 변경", {0:decimalFormat(coinBalance), 1:props.transferInfo.coinSymbolName})}
                                      </div>
                                  </FlexRowCenter>
                                  </>
                          ) :
                          (
                              <FlexRowCenter>
                                  <div className={styles.txt13_B04} style={{borderRadius:'8px',  textAlign:'center', padding:'10px 16px', backgroundColor:'#f2f4f6'}}>
                                      {t("보유 수량")} {decimalFormat(coinBalance)} {props.transferInfo.coinSymbolName}
                                  </div>
                              </FlexRowCenter>
                          )
                      }
                  </FlexColumn>
              </FlexColumn>

            <FlexBottom143 style={{top:'calc(100% - 112px)'}}>
                <FlexColumn>
                    <div style={{paddingLeft:'20px'}} className={styles.txt13_B05}>
                        <input type="checkbox" checked={service} id={'secession'} onChange={(e)=>{setService((prev: boolean) => !prev)}} />
                        <label htmlFor={'secession'}>{t("상대방이 받기 전에 한번 더 승인 할게요.")}</label>
                    {/*<label>*/}
                    {/*    <Checkbox color={"primary"} checked={service} onChange={(e)=>{*/}
                    {/*    setService((prev: boolean) => !prev)*/}
                    {/*}} />상대방이 받기 전에 한번 더 승인 할게요.*/}
                    {/*</label>*/}
                    </div>
                    <FlexRowSpace className={styles.txt13_B05} style={{height:'40px', padding:'6px, 20px'}}>
                        <div style={{paddingLeft:'20px'}}>{t("수수료")}</div>
                        <div style={{paddingRight:'20px'}}>{props.transferInfo.zigleFee}</div>
                    </FlexRowSpace>
                    <FlexColumn>
                        <BottomButton
                            isActive={btnActive}
                            backgroundColor={"#3a71ff"}
                            textColor={"white"}
                            // border={"solid 1px #dbdee6"}
                            onClick={()=>onSubmitClick()}
                            text={t("송금하기")}
                        />
                    </FlexColumn>
                </FlexColumn>
            </FlexBottom143>
        </FlexColumn>
    );
}