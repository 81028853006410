import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import PageTitle, {
    FlexColumn,
    FlexRowStart,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import {useSelector} from "react-redux";
import styles from "../common/css/zigle.module.scss";
import LinkWrapper from "../components/myLink/LinkWrapper";
import LinkPopup from "../components/myLink/LinkPopup";
import {useTranslation} from "react-i18next";

export default function MyLinkList() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [pageMode, setPageMode] = useState('list');
    const [blockSize] = useState(7);
    const [searchTxt, setSearchTxt] = useState('');
    const [showTokenPopup, setShowTokenPopup] = useState(false);
    const [itemInfo, setItemInfo] = useState('');
    const [reLoad, setReLoad] = useState(false);

    const userId: string = useSelector((state: any) => state.userReduce.userId);

    const onClickLinkItem = (linkInfo:any)=>{
        setItemInfo(linkInfo);
        setShowTokenPopup(true);
    }
    return (
        <PageRootContainer>

            <LinkPopup setShow={setShowTokenPopup} show={showTokenPopup} itemInfo={itemInfo} setReLoad={setReLoad}/>
            <PageTitle
                textTitle ={t('도네이션 링크')}
                onClickLeft={()=>{
                    navigate(-1);
                }}
                rightSrc={require('../assets/imges/plus_icon.png')}
                onClickRight={() => {
                    navigate("/creatLink")
                }}
            />
            <PageContent>
                <FlexColumn>
                    <FlexRowStart>
                        <div style={{width:'28px', border: '0', borderBottom: 'solid 1px #f2f4f8', height: '47px', display: 'flex',alignItems: 'center'}}>
                            <img src={require('../assets/imges/search_icon.png')} style={{width:'28px'}} /></div>
                            <div style={{width:'100%'}}><input
                                type={"text"}
                                className={styles.inputText_44}
                                placeholder={t('링크 이름, 코인, 메인넷')}
                                defaultValue={searchTxt}
                                onChange ={(el) => {
                                    setSearchTxt(el.target.value);
                                }}
                            /></div>
                    </FlexRowStart>
                </FlexColumn>
                <FlexColumn style={{paddingTop:'12px'}}>
                    <LinkWrapper userId={userId} blockSize={blockSize} orderBy={'DESC'} limit={true} reLoad={reLoad} searchTxt={searchTxt} onClickLinkItem={onClickLinkItem}/>
                </FlexColumn>
            </PageContent>
        </PageRootContainer>
    );
}