import React, {useEffect} from 'react';
import { Route, useNavigate } from 'react-router-dom';
import AuthService from "../../services/auth.service";
import PopupService from "../../services/popup.service";

const PrivateRoute = ({children}) => {
    const navigate = useNavigate();

    const user = AuthService.getCurrentUser();
    if(!user.userId){
        PopupService.newPopup("로그인을 해주세요!","로그인 후 이용이 가능 합니다.", "",{btnTxt:"확인", onClick: () => {
                sessionStorage.setItem("redirectTo",window.location.href);
                navigate('/walletLogin');
            }}, {})
    }
    return children;
    // return !user ? <Navigate to="/login" /> : children;
};

export default PrivateRoute;