import React, {useEffect, useState} from "react";
import styles from "../common/css/zigle.module.scss";
import PageTitle, {
    FlexColumn,
    FlexRowAround, PageContent, PageRootContainer
} from "../components/common/container/PageContainer";
import {useNavigate, useParams} from "react-router-dom";
import TokenWrapper from "../components/tokenlist/TokenWrapper";
import CoinWrapper from "../components/tokenlist/CoinWrapper";
import TokenWrite from "../components/tokenlist/TokenWrite";
import CoinWrite from "../components/tokenlist/CoinWrite";
import CoinDetail from "../components/tokenlist/CoinDetail";
import {ICoinInfo} from "../common/dto/CoinInfoDto";
import {useSelector} from "react-redux";
import TokenPopup from "../components/tokenlist/TokenPopup";
import {useTranslation} from "react-i18next";


export default function TokenList() {
    const params = useParams();
    const {mainLink, coinLink} = params;
    let pageTab = 0;
    if(coinLink)
    {
        pageTab = 1;
    }
    const login = useSelector((state: any) => state.userReduce.login);


    const navigate = useNavigate();
    const { t } = useTranslation();

    const [showTokenPopup, setShowTokenPopup] = useState(false);
    const [showCoinPopup, setShowCoinPopup] = useState(false);
    const [itemInfo, setItemInfo] = useState('');

    const [tabNum, setTabNum] = useState(pageTab);
    const [pageMode, setPageMode] = useState('list');
    const [coinDetailInfo, setCoinDetailInfo] = useState<ICoinInfo>();
    const plusOnClick = () =>{
        setPageMode('write');
    }
    const detailOnClick = (item: any)=>{
        setCoinDetailInfo(item);
        setPageMode('detail');
    }
    return (
        <PageRootContainer>
            {
                (pageMode === 'list') && (
                    <>
                        <TokenPopup setShow={setShowTokenPopup} show={showTokenPopup} mainnetItem={itemInfo}/>
                        <PageTitle
                            textTitle={t('메타마스크 관리')}
                            onClickLeft={() => navigate(-1)}
                            rightSrc={require('../assets/imges/plus_icon.png')}
                            onClickRight={() => plusOnClick()}
                        />
                        <PageContent>
                            <FlexColumn style={{height: '50px', paddingBottom: '23px'}}>
                                <FlexRowAround>
                                    <div
                                        className={
                                            tabNum === 0 ? styles.topTabActive : styles.topTabDefault
                                        }
                                        onClick={() => {
                                            setTabNum(0);
                                        }}
                                    >
                                        {t('메인넷')}
                                    </div>
                                    <div
                                        className={
                                            tabNum === 1 ? styles.topTabActive : styles.topTabDefault
                                        }
                                        onClick={() => {
                                            setTabNum(1);
                                        }}
                                    >
                                        {t('토큰')}
                                    </div>
                                </FlexRowAround>
                                <div className={styles.line} style={{width: 'calc(100% + 40px)', marginLeft:'-20px'}}/>
                            </FlexColumn>
                            {tabNum === 0 && <TokenWrapper setShowTokenPopup={setShowTokenPopup} setItemInfo={setItemInfo} mainLink={mainLink}/>}
                            {tabNum === 1 && <CoinWrapper setItemInfo={setItemInfo} detailOnClick={detailOnClick} mainLink={mainLink} coinLink={coinLink}/>}
                        </PageContent>
                    </>
                )
            }
            {
                (pageMode === 'write') && (
                    <>
                        <PageTitle
                            textTitle={tabNum === 0 ? t('메인넷 추가 요청') : t('토큰 추가 요청')}
                            onClickLeft={()=>setPageMode('list')}
                        />
                        <PageContent>
                            { tabNum === 0 && <TokenWrite setPageMode={setPageMode} /> }
                            { tabNum === 1 && <CoinWrite setPageMode={setPageMode}  /> }
                        </PageContent>
                    </>
                )
            }
            { (pageMode === 'detail') && (
                <>
                    <PageTitle
                        textSrc={coinDetailInfo?.symbolImgFileId}
                        textTitle={coinDetailInfo?.displayName + "("+coinDetailInfo?.symbolName+")"}
                        onClickLeft={()=>setPageMode('list')}
                    />
                    <PageContent>
                        <CoinDetail setPageMode={setPageMode} coinDetailInfo={coinDetailInfo}/>
                    </PageContent>
                </>
            )}
        </PageRootContainer>
    )
}