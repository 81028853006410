import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import {FlexColumn, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import {Trans, useTranslation} from "react-i18next";

export default function LinkFrame() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
      <FlexColumn className={styles.overFlowScrillHideBar}>
        <FlexRowStart style={{width:'420px'}}  gap={12} >
          <FlexColumn style={{width:'132px'}} className={styles.mainBox} gap={4} onClick={()=>navigate('/myLinkList')}>
            <div className={styles.txt14_B05}>{t("링크로 간편하게")}</div>
            <div className={styles.txt16_B01}><Trans t={t} i18nKey={"도네이션 링크 <br />만들기"}/></div>
            <FlexRowEnd>
              <img
                  src={require("../../assets/imges/link.png")}
                  width={40}
                  alt={"link"}
              />
            </FlexRowEnd>
          </FlexColumn>
          <FlexColumn style={{width:'132px'}}  className={styles.mainBox} gap={4} onClick={()=>navigate('/airdrop')}>
            <div className={styles.txt14_B05}>{t("한번에 여러명")}</div>
            <div className={styles.txt16_B01}><Trans t={t} i18nKey={"에어드랍<br/>뿌리기"}/></div>
            <FlexRowEnd>
              <img
                  src={require("../../assets/imges/airdrop.png")}
                  width={40}
                  alt={"link"}
              />
            </FlexRowEnd>
          </FlexColumn>
          <FlexColumn style={{width:'132px'}}  className={styles.mainBox} gap={4} onClick={()=>navigate('/transfer', {state:{tmpLinkType:'hpTrans'}})}>
            <div className={styles.txt14_B05}>{t("누구보다 퀵하게")}</div>
            <div className={styles.txt16_B01}><Trans t={t} i18nKey={"전화번호로<br />보내기"}/></div>
            <FlexRowEnd>
              <img
                  src={require("../../assets/imges/phone.png")}
                  width={40}
                  alt={"link"}
              />
            </FlexRowEnd>
          </FlexColumn>
        </FlexRowStart>
      </FlexColumn>

  );
}
