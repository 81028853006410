import React, {ReactHTMLElement, useEffect, useRef, useState} from "react";
import PageTitle, {
    FlexBottom, FlexBottom86,
    FlexColumn, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper, ZSelect
} from "../common/container/PageContainer";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import CopyToClipboard from "react-copy-to-clipboard";
import BottomButton from "../common/button/BottomButton";
import QRCode from "qrcode.react";
import DefaultInput from "../common/input/DefaultInput";
import {useForm} from "react-hook-form";
import AssetService from "../../services/asset.service";
import PopupService from "../../services/popup.service";
import {ILinkList} from "../../common/dto/LinkDto";
import PhoneList from "../Friend/PhoneList";
import PhoneService from "../../services/phone.service";
import CommonService from "../../services/common.service";
import {IHpInfoReqDto, ILoginReqDto} from "../../common/dto/request/UserReqDto";
import {useTranslation} from "react-i18next";


interface ITokenDetail {
    show: boolean;
    setShow: any;
    handleOnClick?: (item: string)=> void;
    setReLoad:any;
}
function PhoneInsertPopup(props:ITokenDetail) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [btnActive, setBtnActive] = useState(false);
    const [countryList, setCountryList] = useState<any[]>([]);
    const inputRef = useRef<HTMLFormElement>(null);
    const {
        setValue,
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit,
    } = useForm();
    const onSubmit = (data: any) => {


        const params = new FormData();

        params.append('hpNum', data.hpNum);
        params.append('hpName', data.hpName);
        params.append('hpCountry',data.hpCountry);
        PhoneService.phoneInsert(params).then((response:any) => {
            console.log("response",response)
            if(response.status){
                PopupService.newPopup(t("연락처 추가 완료")
                    ,t("연락처가 성공적으로 등록되었어요.")
                    , ""
                    ,{btnTxt:t("확인 했어요"),
                        onClick: () => {
                            props.setShow(false);
                            props.setReLoad((state:boolean) => !state);
                        }}
                    , {}
                );
            }
        });

    }
    useEffect(()=>{
        if(isDirty === true && isValid === true)
        {
            setBtnActive(true);
        }
    },[isDirty, isValid])

    useEffect(()=>{
       CommonService.getSelectCountry().then((data) => {
           setCountryList([...countryList, ...data.data]);
       });
    }, [])
    return (
        <>
            {
                props.show &&
                (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle
                                    textTitle ={t('연락처 추가')}
                                    onClickLeft={()=>{
                                        props.setShow(false);
                                    }}
                                />
                                <PageContent>
                                    <form ref={inputRef} onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                                        <DefaultInput
                                            lable={t("이름")}
                                            error={errors.hpName}
                                            inputElement={
                                                <input
                                                    type={"text"}
                                                    className={styles.inputText_44}
                                                    placeholder={t("이름을 입력해주세요.")}
                                                    maxLength={12}
                                                    {...register("hpName", {
                                                        required: t("이름을 입력해주세요."),
                                                    })}
                                                />
                                            }
                                        />
                                        <DefaultInput
                                            lable={t("전화번호")}
                                            error={errors.hpNum}
                                            inputElement={
                                            <FlexRowStart>
                                                <ZSelect {...register('hpCountry')} value={82} style={{height:'46px', textAlign:'center', fontSize:'13px', color: '#111111', borderBottom: 'solid 1px #f2f4f8'}} >
                                                    <option value={''}>{t("선택")}</option>
                                                    {
                                                        countryList.length > 0 && (
                                                          countryList.map((item, index)=>{
                                                              return(
                                                                  <option key={item.countryNum + index} value={item.countryNum}>+{item.countryNum} {item.kor}</option>
                                                              )
                                                          })
                                                        )
                                                    }
                                                </ZSelect>
                                                <input
                                                    type={"number"}
                                                    className={styles.inputText_44}
                                                    placeholder={t("전화번호를 입력해주세요.")}
                                                    maxLength={12}
                                                    {...register("hpNum", {
                                                        required: t("전화번호를 입력해주세요."),
                                                        maxLength:12,
                                                        pattern: {
                                                            value: /^([0-9]{2,4})([0-9]{3,4})([0-9]{4})$/i,
                                                            message: t("휴대폰 형식에 맞지 않습니다."),
                                                        }
                                                    })}
                                                />
                                            </FlexRowStart>
                                            }
                                        />
                                    </form>
                                    <FlexBottom>
                                        <BottomButton
                                            isActive={btnActive}
                                            backgroundColor={"#3a71ff"}
                                            textColor={"white"}
                                            // border={"solid 1px #dbdee6"}
                                            onClick={handleSubmit(onSubmit)}
                                            text={t("다음")}
                                        />
                                    </FlexBottom>
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                        )
            }
        </>

    );
}

export default PhoneInsertPopup;
