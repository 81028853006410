import React, {useEffect, useState} from "react";
import styles from "../common/css/zigle.module.scss";
import SettingFrame from "../components/home/SettingFrame";
import LoginFrame from "../components/home/LoginFrame";
import LinkFrame from "../components/home/LinkFrame";
import ZigleCoinFrame from "../components/home/ZigleCoinFrame";
import AssetFrame from "../components/home/AssetFrame";
import MetamaskFrame from "../components/home/MetamaskFrame";
import WalletFrame from "../components/home/WalletFrame";

import {useDispatch, useSelector} from "react-redux";
import EventFrame from "../components/home/EventFrame";
import queryString from "query-string";
import {useLocation} from "react-router-dom";
import AuthService from "../services/auth.service";
import PopupService from "../services/popup.service";
import ProfileService from "../services/profile.service";
import {DISCONNECT} from "../components/reducer/walletReducer";
import {LOGOUT} from "../components/reducer/userReducer";
import MainBanner from "../components/home/MainBanner";
import TopBanner from "../components/home/TopBanner";
import {useTranslation} from "react-i18next";
import NaviBar from "../components/home/NaviBar";

interface IHome {
    isMailCheck?: string;
}
function Home(props:IHome) {
    const { search } = useLocation();
    const {certification} = queryString.parse(search);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const login: boolean = useSelector((state: any) => state.userReduce.login);

    useEffect(()=>{
        if(certification!=null && certification!=undefined && props.isMailCheck=="Y")
        {
            AuthService.certification(certification).then((resData: any) => {
                    console.info("===>",resData);
                    if (resData.status) {
                        document.location.href = '/';
                    } else {
                        PopupService.newPopup(t("오류")
                            ,resData.msg
                            , ""
                            ,{btnTxt:t("확인 했어요"),
                                onClick: () => {

                                }}
                            , {}
                        );
                    }
                }
            );
        }else if(certification!=null && certification!=undefined && props.isMailCheck=="Q")
        {
            ProfileService.quitCertification(certification).then((resData: any) => {
                    console.info("===>",resData);
                    if (resData.status) {
                        dispatch({type: DISCONNECT});
                        dispatch({type: LOGOUT});
                        PopupService.newPopup(t("회원 탈퇴")
                            , t("탈퇴 되었습니다.")
                            , ""
                            ,{btnTxt:t("확인 했어요"),
                                onClick: () => {
                                    document.location.href = '/';
                                }}
                            , {}
                        );

                    } else {
                        PopupService.newPopup(t("오류")
                            ,resData.msg
                            , ""
                            ,{btnTxt:t("확인 했어요"),
                                onClick: () => {

                                }}
                            , {}
                        );
                    }
                }
            );
        }
    },[props.isMailCheck, certification]);

  return (
    <div style={{ width: "100%" }}>

        <SettingFrame />
      <div className={styles.rootContainer} >
        <TopBanner />
        {!login && <LoginFrame />}
        {(login) && <WalletFrame />}
        {/*<HowToWrapper />*/}
        <AssetFrame />
        {/*<MainBanner />*/}
        <LinkFrame />
        <MetamaskFrame />
        <ZigleCoinFrame />
      </div>
        {/* Event Banner Area*/}
        {/*<EventFrame />*/}
        <NaviBar pageType={"main"}/>
    </div>
  );
}

export default Home;
