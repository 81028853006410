import {
    FlexColumEnd,
    FlexColumn,
    FlexColumnCenter,
    FlexRowSpace,
    FlexRowStart
} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import ProgressBar from "./ProgressBar";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {useTranslation} from "react-i18next";

interface IAirdropMode {
    itemDetail : any;
}
export default function AirdropItem({itemDetail} : IAirdropMode) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [airdropBlur, setAirdropBlur ] = useState(true);
    const [airdropCountDown, setAirdropCountDow ] = useState('');
    useEffect(()=>{
        var getCurrentTime = moment();
        var targetTime =moment(itemDetail.airdropStartDay);
        var getCurrentTimeUnix = getCurrentTime.unix();
        var targetTimeUnix = targetTime.unix();


        var leftTime = targetTimeUnix - getCurrentTimeUnix;
        var duration = moment.duration(leftTime, 'seconds');
        var interval = 1000;
        var intv = setInterval(function(){
            if (duration.asSeconds() <= 1 || getCurrentTimeUnix >= targetTimeUnix ) {
                setAirdropBlur(false)
                clearInterval(intv);
            }else{
                duration = moment.duration(duration.asSeconds() - 1, 'seconds');
                setAirdropBlur(true);
                setAirdropCountDow('');
                var timer = {
                    days : duration.days(),
                    hours : (duration.hours() < 10) ? '0' + duration.hours() : duration.hours(),
                    minutes : (duration.minutes() < 10) ? '0' + duration.minutes() : duration.minutes(),
                    seconds : (duration.seconds() < 10) ? '0' + duration.seconds() : duration.seconds()
                }
                if(timer.days > 0)
                {
                    setAirdropCountDow("D-"+timer.days);
                }else{
                    setAirdropCountDow(timer.hours + ' : ' + timer.minutes + ' : ' +  timer.seconds)
                }
            }
        }, interval);
    },[itemDetail])
  return (
      <>
          <FlexColumn className={styles.mainBox} gap={12} onClick={()=>navigate("/a/"+itemDetail.airdropUrl)}>
              <FlexRowSpace>
                  <div>{itemDetail.airdropName}</div>
                  {
                      airdropBlur ? (
                          <div className={styles.airdrop_bage_card_ready}>
                              <img src={require('../../assets/imges/airdrop/airdrop_bege_ready.png')} width={16} /> {t("진행예정")}
                          </div>
                      ) : (
                          ((itemDetail.airdropState === "E") || (itemDetail.airdropCnt - itemDetail.participateCount === 0)) ? (
                              itemDetail.airdropState === "E" ? (
                                  <div className={styles.airdrop_bage_card_end} style={{fontSize:'11px'}}>
                                      <img src={require('../../assets/imges/airdrop/airdrop_bege_end.png')} width={16} /> {t("종료")}
                                  </div>
                                  // <div className={styles.airdrop_bage_card_end} style={{width:'48px', borderRadius:'8px'}}>
                                  //     {t("종료")}
                                  // </div>
                              ) : (
                                  <div className={styles.airdrop_bage_card_before} style={{fontSize:'11px'}}>
                                          <img src={require('../../assets/imges/airdrop/airdrop_bege_before.png')} width={16} /> {t("분배전")}
                                      </div>
                                  )

                          ) : (
                              <div className={styles.airdrop_bage_card_ing}>
                                  <img src={require('../../assets/imges/airdrop/airdrop_bege_ing.png')} width={16} /> {t("진행중")}
                              </div>
                          )
                      )
                  }
              </FlexRowSpace>
              <div className={styles.line}></div>
              <FlexColumn>
                  <FlexColumn style={{position:'sticky'}} gap={12}>
                      {
                          airdropBlur && (
                              <FlexColumnCenter className={styles.airdropBlur}>
                                  <div className={styles.txt20_500_B01}>{t("OPEN")}</div>
                                  <div className={styles.txt20_B01}>{airdropCountDown}</div>
                              </FlexColumnCenter>
                          )
                      }
                      <FlexRowSpace>
                          <FlexRowStart>
                              <div style={{paddingRight:'12px'}}>
                                  <img src={itemDetail.mainnetSymbolImgFileId} alt={"symbol"} width={38} />
                              </div>
                              <FlexColumn>
                                  <div className={styles.txt15_B02} style={{width:'98px', textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden'}}>{itemDetail.coinDisplayName}</div>
                                  <FlexRowStart gap={4}>
                                      <div className={styles.ellipse_151}></div>
                                      <div className={styles.txt12_B04} style={{width:'98px', textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden'}}>{t(itemDetail.mainnetDisplayName)}</div>
                                  </FlexRowStart>
                              </FlexColumn>
                          </FlexRowStart>
                          <FlexColumEnd>
                              <div className={styles.txt12_B05}>
                                  {t("지갑당")}
                              </div>
                              <div className={styles.txt16_B01}>
                                  {itemDetail.amount}<span style={{paddingLeft:'2px'}} className={styles.txt13_B05}>{itemDetail.coinSymbolName}</span>
                              </div>
                          </FlexColumEnd>
                      </FlexRowSpace>
                      <ProgressBar containerPadding={6} fillerBgcolor={"#000000"} completed={(itemDetail.participateCount / itemDetail.airdropCnt) * 100}  fillerHeight={35} leftLabelValue={Number((itemDetail.participateCount / itemDetail.airdropCnt) * 100)} rightLabelValue={itemDetail.participateCount+"/"+itemDetail.airdropCnt}/>
                  </FlexColumn>
              </FlexColumn>
          </FlexColumn>
          {/*<FlexColumn className={styles.mainBox} gap={12} onClick={()=>navigate("/a/tesUrl")}>*/}
          {/*    <FlexRowSpace>*/}
          {/*        <div>홍길동의 2차 에어드랍</div>*/}
          {/*        <div className={styles.airdrop_bage_card_ing}>*/}
          {/*            <img src={require('../../assets/imges/airdrop/airdrop_bege_ing.png')} width={16} /> 진행중*/}
          {/*        </div>*/}
          {/*    </FlexRowSpace>*/}
          {/*    <div className={styles.line}></div>*/}
          {/*    <FlexColumn>*/}
          {/*        <FlexColumn style={{position:'relative'}} gap={12}>*/}
          {/*            <FlexRowSpace>*/}
          {/*                <FlexRowStart>*/}
          {/*                    <div style={{paddingRight:'12px'}}>*/}
          {/*                        <img src={'https://s3.ap-northeast-2.amazonaws.com/bucket.zigle.io/symbols/cfc6201256924042b7a0fca67a9053f9.png'} alt={"symbol"} width={38} />*/}
          {/*                    </div>*/}
          {/*                    <FlexColumn>*/}
          {/*                        <div className={styles.txt15_B02} style={{width:'98px', textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden'}}>이더리움</div>*/}
          {/*                        <FlexRowStart gap={4}>*/}
          {/*                            <div className={styles.ellipse_151}></div>*/}
          {/*                            <div className={styles.txt12_B04} style={{width:'98px', textOverflow:'ellipsis', whiteSpace:'nowrap', overflow:'hidden'}}>ㅁㅈㄷㅅㅈㄷㅅ</div>*/}
          {/*                        </FlexRowStart>*/}
          {/*                    </FlexColumn>*/}
          {/*                </FlexRowStart>*/}
          {/*                <FlexColumEnd>*/}
          {/*                    <div className={styles.txt12_B05}>*/}
          {/*                        지갑당*/}
          {/*                    </div>*/}
          {/*                    <div className={styles.txt16_B01}>*/}
          {/*                        1.23454<span style={{paddingLeft:'2px'}} className={styles.txt13_B05}>BNB</span>*/}
          {/*                    </div>*/}
          {/*                </FlexColumEnd>*/}
          {/*            </FlexRowSpace>*/}
          {/*            <ProgressBar completed={30} containerPadding={6} fillerBgcolor={"#000000"} containerHeight={44} fillerHeight={35} leftLabelValue={'30'} rightLabelValue={"30/100"}/>*/}
          {/*        </FlexColumn>*/}
          {/*    </FlexColumn>*/}
          {/*</FlexColumn>*/}
      </>
  )
}