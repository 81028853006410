import React, {useCallback, useEffect, useState,} from "react";
import {useDispatch} from "react-redux";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {isMobile} from "react-device-detect";
import TagManager from "react-gtm-module";
import axios from "axios";


import styled from "styled-components";
import ScrollToTop from "./common/js/ScrollTop";
import PublicRoute from "./common/js/PublicRoute";
import Home from "./pages/Home";
import Loading from "./components/Loading";
import {AUTO_CONNECT, CONNECTED, DISCONNECT, WEB3_INIT,} from "./components/reducer/walletReducer";
import PopupService from "./services/popup.service";
import "./firebase-message-sw";
import ZigleCharge from "./pages/ZigleCharge";
import WalletLogin from "./pages/WalletLogin";
import LoginKakao from "./pages/LoginKakao";
import LoginGoogle from "./pages/LoginGoogle";
import LoginEmail from "./pages/LoginEmail";
import NoJoinInfo from "./pages/NoJoinInfo";
import JoinEmail from "./pages/JoinEmail";
import CreatLink from "./pages/CreatLink";
import TokenList from "./pages/TokenList";
import {LOGIN_CHECK} from "./components/reducer/userReducer";
import {IUserToken} from "./common/dto/CommonDto";
import AuthService from "./services/auth.service";
import ProfileService from "./services/profile.service";
import Setting from "./pages/Setting";
import PrivateRoute from "./common/js/PrivateRoute";
import Profile from "./pages/Profile";
import MyLink from "./pages/MyLink";
import MyLinkList from "./pages/MyLinkList";
import TransferCoin from "./pages/TransferCoin";
import ModifyProfile from "./components/profile/ModifyProfile";
import History from "./pages/History";
import Friend from "./pages/Friend";
import MyPoint from "./pages/MyPoint";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserProifle from "./pages/UserProifle";
import LinkTrans from "./pages/LinkTrans";
import PhoneTrans from "./pages/PhoneTrans";
import EmailLoginForm from "./pages/EmailLoginForm";
import JoinEmailCmpl from "./pages/JoinEmailCmpl";
import Service from "./pages/Service";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import MyCoinSetting from "./pages/MyCoinSetting";
import LoginEmailForm from "./pages/LoginEmailForm";
import Metamask from "./pages/Metamask";
import Coin from "./pages/Coin";
import Quit from "./pages/Quit";
import Airdrop from "./pages/Airdrop";
import AirdropCreate from "./pages/AirdropCreate";
import MyAirdrop from "./pages/MyAirdrop";
import AirDropTrans from "./pages/AirDropTrans";
import AirdropDe from "./pages/AirdropDe";
import AirdropFi from "./pages/AirdropFi";
import RefPage from "./pages/RefPage";
import Chat from "./pages/Chat";

import {useWeb3Modal} from '@web3modal/react'
import {useAccount, useBalance, useConnect, useNetwork,} from "wagmi";
import {useWalletEthersPorvider} from "./common/js/ethersProviderHook";
import Ido from "./pages/Ido";
import Payment from "./pages/Payment";
import ChatView from "./pages/ChatView";
import MobilePwa from "./pages/MobilePwa";

import Bank from "./pages/Bank";
import Card from "./pages/Card";
import Hp from "./pages/Hp";
import ZethPay from "./pages/ZethPay";
import {ChannelTypeEnum, Client} from "talkplus-sdk";
import ChatService from "./services/chat.service";
import Mining from "./pages/Mining";


const tagManagerArgs = {
  gtmId: "G-2YG8B40LKM",
};
const pathUrl = window.location.pathname.split("/");

const ContentsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 100vh;
`;
const BodyWrapper = styled.div`
  box-sizing: content-box;
  min-width: 360px;
  max-width: 412px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: white;
  //padding: 0px 20px 0px 20px;
`;

declare global {
  interface Window {
    promptEvent: any;
    ethereum:any;
  }
}

const talkPlusAppId = process.env.REACT_APP_TALKPLUS_APPID ?? "";
const talkPlusAppKey = process.env.REACT_APP_TALKPLUS_APIKEY ?? "";
const client = new Client({appId: talkPlusAppId});

function App() {
  const [error, setError] = useState<Error | null>(null);
// # connect 연결 및 connector 목록 정보
  const { connect, connectors, pendingConnector, reset } = useConnect({
    onError(err) {
      console.log("app error : ",err);
      setError(err);
      reset();
    },
  });

  //loading spiner
  const [loading, setLoading] = useState(false);
  const [walletInit, setWalletInit] = useState(false);
  const [metaMaskInstall, setMetaMaskInstall] = useState(false);
  const [pwaVisible, setPwaVisible] = useState(false);
  const [isPwa, setIsPwa] = useState(false);
  const dispatch = useDispatch();
  const provider = useWalletEthersPorvider();
  const { open, close } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const hookCoinBalance = useBalance({
    address: address,
  });
  const [talkPlus, setTalkPlus] = useState<any>();
  const { chain, chains } = useNetwork();




  const isMetaMaskInstalled = useCallback(() => {
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  }, []);
  const disconnect = useCallback(() => {
    dispatch({
      type: DISCONNECT,
    });
  }, []);
  useEffect(()=>{
    if(isConnected && address){
      const loginUser:IUserToken = AuthService.getCurrentUser();
      console.log("loginUser,",loginUser)
      if (loginUser.userId !== '') {
        ProfileService.insertWalletLogin(address);
      }
    }else{
     // connectionWallet();
    }
  },[address, isConnected]);

  useEffect(()=>{
    if(chain?.id){
      let payload = {
        chainId: chain?.id,
        disconnect: disconnect,
      };
      dispatch({
        type: CONNECTED,
        payload: payload,
      });
    }
  },[chain])

  const connectionWallet = useCallback(async () => {
console.log("connectionWallet");
    if (!isMetaMaskInstalled() && isMobile === false) {

      setMetaMaskInstall(false);
      // dispatch({type: NOT_INSTALL});
      PopupService.newPopup("지갑을 연결해야 서비스를 이용할수 있어요","메타마스크 서비스 페이지로 이동합니다.", "",{btnTxt:"확인", onClick: () => {
          window.location.href = '/metamask';
        }}, {})
    } else {
      if (isConnected === false) {
        console.log("open")
        open();
      }
    }
  }, []);

  const delay = useCallback((delay: number) => {
    return new Promise((res) => setTimeout(res, delay));
  }, []);

  async function makeConnect() {
    console.log("WEB3_INIT");
    dispatch({
      type: WEB3_INIT,
      payload: {
        openModal: connectionWallet,
      },
    });
  }
  if (!walletInit) {
    setWalletInit(true);
    makeConnect();
  }

  const loginCheck = useCallback(async () => {
    const userToken: IUserToken = AuthService.getCurrentUser();
    if (userToken.userId) {
      if (new Date().getTime() > userToken.timestamp) {
        // 만료시간이 지난 item 삭제
        return;
      }

      const data: any = await ProfileService.userDetail();
      let payload = {
        email: userToken.email,
        userId: userToken.userId,
        socialCode: userToken.socialCode,
        sliceEmail:
            userToken.email.slice(0, 6) + ".." + userToken.email.slice(-3),
        profileImageFileId: data.data.profileImageFileId,
        ziglePoint: data.data.ziglePoint,
        cryptoZigle: 0,
        doname: data.data.doname,
        introduce: data.data.introduce,
        hpCountry: data.data.hpCountry,
        hpNum: data.data.hpNum,
        refCode: data.data.refCode,
        upRefCode: data.data.upRefCode,
        miningAmount: data.data.miningAmount,
        login: true,
      };
      /* chat서비스 로그인 */

      if(client.isLoggedIn() === false){
        ChatService.userCheck(userToken.userId).then(res=>{
          if(res.status === 200){
            ChatService.userLogin(userToken.email, userToken.userId).then(async res=>{
              // ChatHelp.chatLogin(userToken.userId, data.data.doname, data.data.profileImageFileId, res.data.loginToken);
              await client.loginWithToken({
                userId: userToken.userId, // unique userId
                username: data.data.doname, // username
                loginToken: res.data.loginToken, // login token issued by admin REST API
                profileImageUrl: data.data.profileImageFileId,
              }).then(async res=>{
                if((res.user.profileImageUrl !== data.data.profileImageFileId) || (res.user.username !== data.data.doname)){
                  await client.updateUser({
                    username:data.data.doname,
                    profileImageUrl: data.data.profileImageFileId
                  }).then(res =>{
                    setTalkPlus(client);
                  });
                }else{
                  setTalkPlus(client);
                }

                if(data.data.doname){
                  await client.getChannel({channelId: userToken.userId+"_chat"})
                    .then(async res=>{
                      if(res.channel.name !== data.data.doname){
                        client.updateChannel({
                          channelId: userToken.userId+"_chat",
                          name: data.data.doname,
                          imageUrl:data.data.profileImageFileId
                        });
                      }
                      ChatService.createChatRoom(userToken.userId+"_chat",data.data.doname);
                      await client.hideChannel({channelId: userToken.userId+"_chat"});
                    })
                    .catch(async err=>{
                      console.log("err", err)
                      await client.createChannel({
                        channelId: userToken.userId+"_chat",
                        name: data.data.doname,
                        type: ChannelTypeEnum.SuperPublic,
                        members: [],
                        maxMemberCount:100000,
                        imageUrl:data.data.profileImageFileId
                      }).then(async res=>{
                        ChatService.createChatRoom(userToken.userId+"_chat",data.data.doname);
                        await client.hideChannel({channelId: userToken.userId+"_chat"});
                      })
                    });
                }

              }).catch((error:any)=>{
                console.log("talkPlus error",error);
              })
            })
          }else{
            ChatService.userRegister(userToken.email, userToken.userId, data.data.doname, data.data.profileImageFileId).then(async res=>{
              // ChatHelp.chatLogin(userToken.userId, data.data.doname, data.data.profileImageFileId, res.data.loginToken);
              await client.loginWithToken({
                userId: userToken.userId, // unique userId
                username: data.data.doname, // username
                loginToken: res.data.loginToken, // login token issued by admin REST API
                profileImageUrl: data.data.profileImageFileId,
              }).then(async res=>{
                setTalkPlus(client);
                if(data.data.doname) {
                  await client.getChannel({channelId: userToken.userId + "_chat"})
                      .then(async (res:any)=> {
                        ChatService.createChatRoom(userToken.userId + "_chat", data.data.doname);
                        await client.hideChannel({channelId: userToken.userId + "_chat"});
                      })
                      .catch(async err => {
                        console.log("err", err)
                        await client.createChannel({
                          channelId: userToken.userId + "_chat",
                          name: data.data.doname,
                          type: ChannelTypeEnum.SuperPublic,
                          members: [],
                          maxMemberCount:100000,
                          imageUrl: data.data.profileImageFileId
                        }).then(async res => {
                          ChatService.createChatRoom(userToken.userId+"_chat",data.data.doname);
                          await client.hideChannel({channelId: userToken.userId + "_chat"});
                        });
                        await client.hideChannel({channelId: userToken.userId + "_chat"});
                      });
                }
              }).catch((error:any)=>{
                console.log("talkPlus error",error);
              })
            })

          }
        })
      }

      dispatch({ type: LOGIN_CHECK, payload: payload });
      // dispatch({ type: AUTO_CONNECT });

      if (pathUrl[1] !== "embed") {
        console.log("embed")
        dispatch({ type: AUTO_CONNECT });
      }
    }else{
      if(pathUrl[1] ==="a"){
        console.log("a=====")
        dispatch({ type: AUTO_CONNECT });
      }
      // dispatch({type: DISCONNECT});
    }
  }, []);


  //axios 호출시 인터셉트
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
    //로그인을 확인한다.
    loginCheck();
    // PWA 인서트 현황
    if(isMobile){
      if (window.matchMedia("(display-mode: standalone)").matches) {
        //PWA를 통해 들어옴
        setIsPwa(true);
      }else{
        window.addEventListener("beforeinstallprompt", function (event) {
          event.preventDefault();
          window.promptEvent = event;
        });
        setIsPwa(false);
      }
    }else{
      setIsPwa(true);
      window.addEventListener("beforeinstallprompt", function (event) {
        event.preventDefault();
        window.promptEvent = event;
        if (window.matchMedia("(display-mode: standalone)").matches) {
          setPwaVisible(false);
        } else {
          setPwaVisible(true);
        }
      });
    }
    axios.interceptors.request.use(
        function (config) {
          // 경로가 /api 인것만 로딩..

          if (config.url != null && config.url.includes("/api")) {
            if(!config.url.includes("/user/chat")) {
              setLoading(true);
            }
          }
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
    );

    //axios 호출 종료시 인터셉트
    axios.interceptors.response.use(
        function (response) {
          setLoading(false);
          return response;
        },
        function (error) {
          setLoading(false);
          return Promise.reject(error);
        }
    );
  }, []);

  return (
      <>
        <ContentsWrapper>
          <BrowserRouter>
            <ScrollToTop />
            <BodyWrapper>

              {
                isPwa === false ? (
                    <Routes>
                        <Route path="/u/:nick" element={ <PublicRoute restricted={false}><UserProifle  talkPlus={talkPlus}/></PublicRoute>}/>
                        <Route path="/l/:linkCode" element={ <PublicRoute restricted={false}><LinkTrans /></PublicRoute>}/>
                        <Route path="/p/:linkCo`de" element={ <PublicRoute restricted={false}><PhoneTrans /></PublicRoute>}/>
                        <Route path="/a/:linkCode" element={ <PublicRoute restricted={false}><AirDropTrans /></PublicRoute>}/>
                        <Route path="/tokenList/:mainLink/:coinLink" element={ <PublicRoute restricted={false}><TokenList /></PublicRoute>}/>
                        <Route path="/tokenList/:mainLink" element={ <PublicRoute restricted={false}><TokenList /></PublicRoute>}/>
                        <Route path="/tokenList" element={ <PublicRoute restricted={false}><TokenList /></PublicRoute>}/>
                        <Route path="/*" element={ <PublicRoute restricted={false}><MobilePwa /></PublicRoute>} />
                    </Routes>
                ) : (
                    <Routes>
                      <Route path="/" element={ <PublicRoute restricted={false}><Home /></PublicRoute>}/>
                      <Route path="/walletLogin" element={ <PublicRoute restricted={false}><WalletLogin /></PublicRoute>}/>
                      <Route path="/join/:refCode" element={ <PublicRoute restricted={false}><WalletLogin /></PublicRoute>}/>
                      <Route path="/loginKakao" element={ <PublicRoute restricted={false}><LoginKakao /></PublicRoute>}/>
                      <Route path="/loginGoogle" element={ <PublicRoute restricted={false}><LoginGoogle /></PublicRoute>}/>
                      <Route path="/loginEmail" element={ <PublicRoute restricted={false}><LoginEmail /></PublicRoute>}/>
                      <Route path="/loginEmailForm" element={ <PublicRoute restricted={false}><LoginEmailForm /></PublicRoute>}/>
                      <Route path="/noJoinInfo" element={ <PublicRoute restricted={false}><NoJoinInfo /></PublicRoute>}/>
                      <Route path="/joinEmail" element={ <PublicRoute restricted={false}><JoinEmail /></PublicRoute>}/>
                      <Route path="/joinEmailCmpl" element={ <PublicRoute restricted={false}><JoinEmailCmpl /></PublicRoute>}/>
                      <Route path="/EmailLoginForm" element={ <PublicRoute restricted={false}><EmailLoginForm /></PublicRoute>}/>
                      <Route path="/authmail" element={<PublicRoute restricted={false}><Home isMailCheck="Y" /></PublicRoute>} />
                      <Route path="/quitmail" element={<PublicRoute restricted={false}><Home isMailCheck="Q" /></PublicRoute>} />

                      <Route path="/privacy" element={ <PublicRoute restricted={false}><Privacy /></PublicRoute>}/>
                      <Route path="/terms" element={ <PublicRoute restricted={false}><Terms /></PublicRoute>}/>
                      <Route path="/service" element={ <PublicRoute restricted={false}><Service /></PublicRoute>}/>
                      <Route path="/metamask" element={ <PublicRoute restricted={false}><Metamask /></PublicRoute>}/>

                      <Route path="/ido" element={<PublicRoute restricted={false}><Ido /></PublicRoute>} />


                      <Route path="/tokenList/:mainLink/:coinLink" element={ <PublicRoute restricted={false}><TokenList /></PublicRoute>}/>
                      <Route path="/tokenList/:mainLink" element={ <PublicRoute restricted={false}><TokenList /></PublicRoute>}/>
                      <Route path="/tokenList" element={ <PublicRoute restricted={false}><TokenList /></PublicRoute>}/>

                      <Route path="/u/:nick" element={ <PublicRoute restricted={false}><UserProifle  talkPlus={talkPlus}/></PublicRoute>}/>
                      <Route path="/l/:linkCode" element={ <PublicRoute restricted={false}><LinkTrans /></PublicRoute>}/>
                      <Route path="/p/:linkCode" element={ <PublicRoute restricted={false}><PhoneTrans /></PublicRoute>}/>
                      <Route path="/setting" element={ <PublicRoute restricted={false}><Setting pwaVisible={pwaVisible} loginCheck={loginCheck}/></PublicRoute>}/>
                      <Route path="/a/:linkCode" element={ <PublicRoute restricted={false}><AirDropTrans /></PublicRoute>}/>

                      <Route path="/zigleCharge" element={ <PrivateRoute><ZigleCharge /></PrivateRoute>}/>
                      <Route path="/payment" element={ <PrivateRoute><Payment /></PrivateRoute>}/>

                      <Route path="/Bank" element={ <PrivateRoute><Bank /></PrivateRoute>}/>
                      <Route path="/Card" element={ <PrivateRoute><Card /></PrivateRoute>}/>
                      <Route path="/Hp" element={ <PrivateRoute><Hp /></PrivateRoute>}/>

                      <Route path="/zEth" element={ <PrivateRoute><ZethPay /></PrivateRoute>}/>


                      <Route path="/mining" element={ <PrivateRoute><Mining /></PrivateRoute>}/>
                      <Route path="/friend" element={ <PrivateRoute><Friend /></PrivateRoute>}/>
                      <Route path="/creatLink" element={ <PrivateRoute><CreatLink /></PrivateRoute>}/>
                      <Route path="/myLink" element={ <PrivateRoute><MyLink /></PrivateRoute>}/>
                      <Route path="/myLinkList" element={ <PrivateRoute><MyLinkList /></PrivateRoute>}/>
                      <Route path="/transfer" element={ <PrivateRoute><TransferCoin /></PrivateRoute>}/>
                      <Route path="/history/:modeType" element={ <PrivateRoute><History /></PrivateRoute>}/>
                      <Route path="/profile" element={ <PrivateRoute><Profile talkPlus={talkPlus}/></PrivateRoute>}/>
                      <Route path="/modifyProfile" element={ <PrivateRoute><ModifyProfile talkPlus={talkPlus}/></PrivateRoute>}/>
                      <Route path="/myPoint" element={ <PrivateRoute><MyPoint /></PrivateRoute>}/>
                      <Route path="/myCoin" element={ <PrivateRoute><MyCoinSetting /></PrivateRoute>}/>
                      <Route path="/coin" element={ <PrivateRoute><Coin /></PrivateRoute>}/>
                      <Route path="/quit" element={ <PrivateRoute><Quit /></PrivateRoute>}/>
                      <Route path="/airdrop" element={ <PrivateRoute><Airdrop /></PrivateRoute>}/>
                      <Route path="/airdropCreate" element={ <PrivateRoute><AirdropCreate /></PrivateRoute>}/>
                      <Route path="/myAirdrop" element={ <PrivateRoute><MyAirdrop /></PrivateRoute>}/>
                      <Route path="/airdropDe" element={ <PrivateRoute><AirdropDe /></PrivateRoute>}/>
                      <Route path="/airdropFi" element={ <PrivateRoute><AirdropFi /></PrivateRoute>}/>
                      <Route path="/ref" element={ <PrivateRoute><RefPage /></PrivateRoute>}/>
                      <Route path="/chat/:doname" element={ <PrivateRoute><ChatView talkPlus={talkPlus}/></PrivateRoute>}/>
                      <Route path="/chat" element={ <PrivateRoute><Chat talkPlus={talkPlus}/></PrivateRoute>}/>
                    </Routes>
                )
              }

              <ToastContainer/>
            </BodyWrapper>
          </BrowserRouter>
        </ContentsWrapper>
        <Loading loading={loading}></Loading>
      </>
  );
}

export default App;

