import React, {useEffect, useRef, useState} from "react";
import {
    FlexBottom,
    FlexBottomStart,
    FlexColumn,
    FlexColumnCenter,
    FlexRowCenter,
    FlexRowSpace
} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import BottomButton from "../common/button/BottomButton";
import CopyToClipboard from "react-copy-to-clipboard";
import {Toast} from "../common/popup/ToastAlert";
import {useSelector} from "react-redux";
import MetaMaskService from "../../services/metamask.service";
import CommonService from "../../services/common.service";
import {useNavigate} from "react-router-dom";
import bigDecimal from "js-big-decimal";
import {useAccount, useBalance} from "wagmi";
import {Trans, useTranslation} from "react-i18next";

interface ITransferStep {
    transferInfo:any;
    setDepth:any;
    setTransferInfo:any;
}


export default function TransferEnd(props: ITransferStep) {
    const navigate = useNavigate();
    const { t } = useTranslation();


    const [coinBalance , setCoinBalance] = useState('0');
    const { address, isConnected } = useAccount();

    const hookCoinBalance = useBalance({
        address: address,
    });

    useEffect(()=>{
        const fetchData = async() =>{

            let balance = '0';
            let bigBalance;
            if(props.transferInfo.coinTypeCode ==='TOKEN')
            {
                MetaMaskService.getBalance(address, props.transferInfo.coinContAddr)
                    .then((res) => {
                        bigBalance = new bigDecimal(res).subtract(new bigDecimal(props.transferInfo.amount));
                        setCoinBalance(bigBalance.getValue());
                    });
            }else{
                bigBalance = new bigDecimal(hookCoinBalance.data?.formatted).subtract(new bigDecimal(props.transferInfo.amount));
                setCoinBalance(bigBalance.getValue());
            }

        }

        if(address){
            fetchData();
        }

    },[address]);

    return (
        <FlexColumn gap={28}>
            <FlexColumn gap={12}>
                <FlexRowCenter><img src={require('../../assets/icons/send_ok.png')} width={64} /></FlexRowCenter>
                <FlexColumnCenter className={styles.txt20_500_B01}>
                    <Trans className={styles.transSpanWeight400} t={t} i18nKey={"{0}에게<br/><span>코인을 보냈어요!</span>"} values={{0:props.transferInfo.sendUserName}} />
                </FlexColumnCenter>
            </FlexColumn>
            {
                props.transferInfo.linkType === 'hpTrans' && (
                    <FlexColumn gap={28}>
                        <FlexColumn gap={10}>
                            <FlexColumn className={styles.txt14_B03}>{t("링크")}</FlexColumn>
                            <FlexRowSpace style={{height:'34px'}}>
                                <div className={styles.txt20_B01}>{window.location.protocol + "//" + window.location.host + "/p/"+props.transferInfo.linkUrl}</div>
                                <div>
                                    <CopyToClipboard text={window.location.protocol + "//" + window.location.host + "/p/"+props.transferInfo.linkUrl}
                                                     onCopy={() => Toast(t("복사 하였습니다."))}>
                                        <div className={styles.img_btn} style={{width:'56px'}}>
                                            {t("복사")}
                                        </div>
                                    </CopyToClipboard>
                                </div>
                            </FlexRowSpace>
                            <div className={styles.line}></div>
                        </FlexColumn>
                        <FlexColumn gap={10}>
                            <FlexColumn className={styles.txt14_B03}>{t("인증번호")}</FlexColumn>
                            <FlexRowSpace style={{height:'34px'}}>
                                <div className={styles.txt20_B01}>{props.transferInfo.confirmTxt}</div>
                                <div>
                                    <CopyToClipboard text={props.transferInfo.confirmTxt}
                                                     onCopy={() => Toast(t("복사 하였습니다."))}>
                                        <div className={styles.img_btn} style={{width:'56px'}}>
                                            {t("복사")}
                                        </div>
                                    </CopyToClipboard>
                                </div>
                            </FlexRowSpace>
                            <div className={styles.line}></div>
                        </FlexColumn>
                    </FlexColumn>
                )
            }
            <FlexColumn className={styles.txt14_B03} gap={16}>
                <FlexRowSpace>
                    <div>{t("보낸수량")}</div>
                    <div>{props.transferInfo.amount} {props.transferInfo.coinSymbolName}</div>
                </FlexRowSpace>
                <FlexRowSpace>
                    <div>{t("수수료")}</div>
                    <div>{props.transferInfo.zigleFee}</div>
                </FlexRowSpace>
            </FlexColumn>
            <FlexColumn className={styles.line}></FlexColumn>
            {/*<FlexColumn>*/}
            {/*    <FlexRowSpace>*/}
            {/*        <div className={styles.txt18_500}>남은 잔액</div>*/}
            {/*        <div className={styles.txt18_500_PointB}>{decimalFormat(coinBalance)} {props.transferInfo.coinSymbolName}</div>*/}
            {/*    </FlexRowSpace>*/}
            {/*</FlexColumn>*/}
            <FlexColumn className={styles.txt12_B05} style={{borderRadius:'12px', padding:'12px', backgroundColor:'#F8F9FC', marginBottom:'10px'}}>
                <div>{t("보낸 메세지")}</div>
                <div className={styles.txt14_B01}>{props.transferInfo.message}</div>
            </FlexColumn>
            {
                props.transferInfo.linkType === 'hpTrans' && (
                    <FlexColumn className={styles.txt13_B05} style={{borderRadius:'16px', padding:'20px', backgroundColor:'#f9fafb', marginBottom:'30px'}}>
                        <Trans t={t} i18nKey={"받는 분에게 코인을 받을 수 있는 링크과 인증 번호가 발송되었어요. <br/>또는 지글 프로필의 받기 내역에서 인증 번호 입력이 가능해요."}/>
                    </FlexColumn>
                )
            }
            <FlexBottom>
                <BottomButton
                    isActive={true}
                    backgroundColor={"#f2f4f6"}
                    textColor={"#3a71ff"}
                    text={t("추가 송금하기")}
                    onClick={() => {
                        props.setTransferInfo(
                            {
                                pageTitle:'',
                                linkType:'',
                                linkName:t('송금 방식'),
                                coinId: '',
                                coinTypeCode: '',
                                coinContAddr: '',
                                coinDecimalVal: '',
                                coinSymbolName: t('송금 코인'),
                                coinSymbolImgFileId:require('../../assets/icons/question2.png'),
                                mainnetId : '',
                                mainnetLinkName: t('메인넷'),
                                sendUserId : '',
                                sendUserName : '',
                                sendWalletAddress:'',
                                countryNum:'',
                                phoneNum:'',
                                zigleFee:0,
                                amount : 0,
                                safeOn: false,
                                confirmTxt:'',
                                txId : ''
                            });
                        props.setDepth(0);
                    }}
                />
                <BottomButton
                    isActive={true}
                    backgroundColor={"#3a71ff"}
                    textColor={"#ffffff"}
                    text={t("내역 바로가기")}
                    onClick={() => {
                        navigate("/history/scv");
                    }}
                />
            </FlexBottom>
        </FlexColumn>
    )
}