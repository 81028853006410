import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FlexColumEnd, FlexColumn, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {useSelector} from "react-redux";
import PopupService from "../../services/popup.service";
import FavService from "../../services/fav.service";
import {Toast} from "../common/popup/ToastAlert";
import ChatService from "../../services/chat.service";
import {ellipseText} from "../../common/js/utilities";


export default function ChatListItem (props:{pageType:string, talkPlus:any, item:any, searchTxt:string, handleOnClick?: (item: string)=> void;}) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const userId: string = useSelector((state: any) => state.userReduce.userId);
    const doname: string = useSelector((state: any) => state.userReduce.doname);

    const [chatView, setChatView] = useState<any>({
        channel:{

        }
    });
    const getMyChannel = async(channelId: string)=>{
        const resMyChannel = await props.talkPlus.getChannel({channelId: channelId});

        var getCurrentTime = moment();
        var targetTime = moment(resMyChannel.channel.lastSentAt);
        var leftTime = getCurrentTime.diff(targetTime, 'minutes');
        if(Number(leftTime) > 1440){
            resMyChannel.channel.leftTime = parseInt(String(Number(leftTime) /1440))+ t("일");
        }else if(Number(leftTime) > 59){
            resMyChannel.channel.leftTime = parseInt(String(Number(leftTime) /60))+ t("시간");
        }else if(Number(leftTime) <= 0){
            resMyChannel.channel.leftTime = t("방금");
        }else{
            resMyChannel.channel.leftTime = parseInt(String(leftTime))+t("분");
        }
        if(props.searchTxt){
            resMyChannel.channel.tmpName= resMyChannel.channel.name.replace(props.searchTxt, "<span style='color:#3A71FF'>"+ props.searchTxt +"</span>");
        }else{
            resMyChannel.channel.tmpName= resMyChannel.channel.name;
        }
        setChatView(resMyChannel);
    }

    const handleImgError = (e:any) => {
        e.target.src = "https://s3.ap-northeast-2.amazonaws.com/bucket.zigle.io/profiles/profile"+(Number(props.item.userId) % 9)+".png";
    }

    useEffect(()=>{
        getMyChannel(props.item.chatId);
    },[props.item]);
    return (
        <FlexColumn >
            <FlexColumn style={{padding:'12px 16px'}} onClick={()=>{
                if(doname){
                    if(props.handleOnClick) {
                        props.handleOnClick(props.item.doname);
                    }else{
                        navigate("/chat/"+chatView.channel.name);
                    }
                }else{
                    PopupService.newPopup(t("닉네임없음")
                        ,t("닉네임을 입력하셔야 사용 가능합니다.")
                        , ""
                        ,{btnTxt:t("확인"),
                            onClick: () => {
                                navigate("/modifyProfile");
                            }}
                        , {
                            btnTxt:"",
                            onClick: () => {}
                        }
                    );
                }
            }}>
                <FlexRowSpace>
                    <FlexRowStart gap={14} style={{width:'70%'}}>
                        <img
                            src={chatView.channel.imageUrl}
                            onError={handleImgError}
                            width={48}
                            alt={"profile image"}
                            style={{borderRadius:'48px', aspectRatio:'1', objectFit:'cover'}}
                        />
                        <FlexColumn>
                            <FlexRowStart className={styles.txt16_B02} dangerouslySetInnerHTML={{ __html: chatView.channel.tmpName }}>

                            </FlexRowStart>
                            <div className={styles.txt13_B05}>
                                {ellipseText(chatView.channel.lastMessage?.text, 12)}
                            </div>
                        </FlexColumn>
                    </FlexRowStart>
                    <FlexRowEnd className={styles.txt13_B06} style={{width:'30%'}}>
                        <FlexColumEnd gap={4}>
                            <div className={styles.txt12_B05} style={{marginTop:'0px', marginBottom:'auto'}}>{t("{0} 전 활동", {0:  chatView.channel.leftTime })}</div>
                            <div>
                                {props.item.userCnt }{t("명")}
                            </div>
                        </FlexColumEnd>
                    </FlexRowEnd>
                </FlexRowSpace>
            </FlexColumn>
            <div className={styles.line}/>
        </FlexColumn>
    )
}