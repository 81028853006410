import axios from "axios";
import { getHeader } from "./profile.service";
import { checkStatus401 } from "./common.service";

let API_URL = "";
if (process.env.REACT_APP_ENVTEXT === "local") {
  API_URL = "/api";
} else {
  API_URL = process.env.REACT_APP_API_URL ?? "https://api.zigle.link/api";
}
const friendList = (
    searchTxt: string,
    currentPage: number,
    blockSize: number
) => {
  return axios
    .get(API_URL + "/v1/user/fav/friends", {
      params: {
        searchTxt: searchTxt,
        currentPage: currentPage,
        blockSize: blockSize
      },
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatus401(error.response);
    });
};
const friendInsert = (userId: string) => {
  const params = new URLSearchParams();
  params.append("userId", userId);

  return axios
    .post(API_URL + "/v1/user/fav/friend", params, {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatus401(error.response);
    });
};
const friendDelete = (userId: string) => {
  return axios
    .get(API_URL + "/v1/user/fav/delFriend?userId=" + userId, {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatus401(error.response);
    });
};
const FavService = {
  friendInsert,
  friendDelete,
  friendList,
};

export default FavService;
