import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import {useTranslation} from "react-i18next";

export default function MetamaskFrame() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className={styles.mainBox} onClick={() => {
      navigate("/tokenList")
    }}>
      <div className={styles.titleContainer}>
        <div className={styles.flexRowStart}>
          <div className={styles.txtTitle18}>{t("메타마스크 관리")}</div>
          {/*<div className={styles.lp2}>*/}
          {/*  <img*/}
          {/*    src={require("../../assets/icons/question.png")}*/}
          {/*    alt={"question"}*/}
          {/*    width={"20px"}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div style={{width:'28px', textAlign:'right'}}>
          <img
            src={require("../../assets/icons/btnArrow.png")}
            height={28}
            alt={"arrow"}
          />
        </div>
      </div>
    </div>
  );
}
