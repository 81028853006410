import PageTitle, {
    FlexColumEnd,
    FlexColumn,
    FlexRowAround, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../common/container/PageContainer";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import styles from "../../common/css/zigle.module.scss";
import {useNavigate} from "react-router-dom";
import {useInView} from "react-intersection-observer";
import moment from "moment";
import AirdropFeeHisItem from "./AirdropFeeHisItem";
import EmptyContent from "../common/EmptyContent";
import {IMyAirdropTransfer} from "../../common/dto/AirdropList";
import CommonService from "../../services/common.service";
import {ellipseAddress} from "../../common/js/utilities";
import {useTranslation} from "react-i18next";

interface IMyAirdropParticipation {
    setShow : any;
    show : boolean;
    listInfo:Array<IMyAirdropTransfer>;
    setMyAirdropList: (page:number, block:number)=> void;
}
export default function MyAirdropParticipation({setShow, show, listInfo, setMyAirdropList} : IMyAirdropParticipation) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [currPage, setCurrPage] = useState(1);
    const [blockSize] = useState(10);
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [reSet, setReSet] = useState(false);
    let monthDay = "";
    let monthResult = "";
    useEffect(() => {
       if(currPage > 1){
           setMyAirdropList(currPage,blockSize);
       }
    },[currPage, reSet]);

    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);

    return (
        <>
            {
                show &&
                (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle

                                    textTitle ={t("받은내역")}
                                    onClickLeft={()=>{
                                        setShow(false);
                                    }}
                                />
                                <PageContent style={{marginBottom:'100px'}}>
                                    <FlexColumn gap={32}>
                                    {
                                        listInfo.length > 0 ?
                                            (
                                                listInfo.map((item, index)=>{
                                                    let tmpDay = moment(item.regDatetime).format('MM-DD');

                                                    if((tmpDay) && (monthDay != tmpDay)){
                                                        monthResult = "Y";
                                                        monthDay = tmpDay;
                                                    }else{
                                                        monthResult = "N";
                                                    }
                                                    return (
                                                        <FlexColumn gap={16} key={item.histAirdropId}>
                                                            {
                                                                monthResult ==="Y" &&
                                                                (
                                                                    index !==0 ?
                                                                        (
                                                                            <FlexColumn gap={24}>
                                                                                <FlexColumn className={styles.line}></FlexColumn>
                                                                                <FlexColumn className={styles.txt13_B04}>{moment(item.regDatetime).format('MM월DD일')}</FlexColumn>
                                                                            </FlexColumn>
                                                                        )
                                                                        :
                                                                        (<FlexColumn className={styles.txt13_B04}>{moment(item.regDatetime).format('MM월DD일')}</FlexColumn>)
                                                                )
                                                            }
                                                            <FlexRowAround>
                                                                <FlexColumn gap={5}>
                                                                    <div className={styles.txt16_B02}>{item.airdropName}</div>
                                                                    <div className={styles.txt14_B05}>{(item.airdropState === "DO" || item.airdropState === "CO") ? ellipseAddress(item.txId,5) : t("지급전")}</div>
                                                                    <div className={styles.txt12_B05}>{moment(item.regDatetime).format('HH:mm')}</div>
                                                                </FlexColumn>
                                                                <FlexColumEnd gap={5}>
                                                                    <div className={styles.txt18_700_B01}>+{item.amount} {item.coinSymbolName}</div>
                                                                </FlexColumEnd>
                                                            </FlexRowAround>
                                                        </FlexColumn>
                                                    )
                                                })
                                            )
                                            :
                                            (
                                                <EmptyContent titleText={t('받은 내역이 없어요.')}/>
                                            )
                                    }
                                    {
                                        Object.values(listInfo).length >= blockSize ? (
                                            <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) : null
                                    }
                                    </FlexColumn>
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>
    )
}