import React, {forwardRef, useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import {Checkbox, TextField} from "@material-ui/core";
import {
    FlexBottom, FlexBottom86, FlexBottomStart,
    FlexColumn, FlexColumnCenter,
    FlexRowAround, FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, ToggleBtn, ToggleCircle
} from "../common/container/PageContainer";
import BottomButton from "../common/button/BottomButton";
import {useForm} from "react-hook-form";
import DefaultInput from "../common/input/DefaultInput";
import {IAirdropSet} from "../../pages/AirdropFi";
import MetaMaskService from "../../services/metamask.service";
import {useSelector} from "react-redux";
import CommonService from "../../services/common.service";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {ko} from "date-fns/locale";

import moment from 'moment';
import AirdropService from "../../services/airdrop.service";
import bigDecimal from "js-big-decimal";
import {decimalFormat} from "../../common/js/utilities";
import {ToastError} from "../common/popup/ToastAlert";
import {useAccount, useBalance} from "wagmi";
import PopupService from "../../services/popup.service";
import {Trans, useTranslation} from "react-i18next";

registerLocale("ko", ko);

interface IAirdropStep4 {
    airdropInfo: IAirdropSet;
    setAirdropInfo: any;
    setDepth: any;
    txIdResult?:(result:any,
                 resultMessage:any,
                 send_token_amount:any,
                 account:any,
                 to_address:any,
                 mainnetId:any,
                 coinId:any,
                 linkMngId:any)=> void;
}

export default function AirdropFiStep4({airdropInfo, setAirdropInfo, setDepth, txIdResult}:IAirdropStep4){
    const { t } = useTranslation();
    const [balance, setBalance] = useState(0.0);
    const [price, setPrice] = useState(0);
    const [btnActive, setBtnActive] = useState(false);
    const { address, isConnected } = useAccount();

    const hookCoinBalance = useBalance({
        address: address,
    });



    const [amount ,setAmount] = useState<number>(0);
    const [toggle, setToggle] = useState(false);
    const [service, setService] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<any>('');
    const [startTime, setStartTime] = useState<any>('');

    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
    } = useForm();
    const testClick = ()=>{
        PopupService.newDatePopup(
            {btnTxt:t("확인 했어요"),
                onClick: () => {

                }}
            , {}
        );
    }

    const onSubmit = (data: any) => {

        if(!address){
            ToastError(t("지갑연결을 확인해 주세요."));
            return false;
        }

        if(toggle && !service && (startDate === '' || startTime ===''))
        {
            if(startDate === ''){
                setError("startDate", {type:'custom', message:t('시작 날짜를 지정해 주세요.')});
            }else{
                setError("startTime", {type:'custom', message:t('시작시간을 지정해 주세요.')});
            }
            return false;

        }
        MetaMaskService.sendTransferToken(
            address,
            airdropInfo.coinInfo.contAddr,
            new bigDecimal(airdropInfo.airdropCnt).multiply(new bigDecimal(amount)).getValue(),
            airdropInfo.receiveAddr,
            airdropInfo.mainnetId,
            airdropInfo.coinInfo.coinId,
            '',
            txIdResult
        );

        // setDepth(4);
    }
    useEffect(() => {
        if(airdropInfo.coinInfo.typeCode ==='TOKEN') {
            try {
                MetaMaskService.getBalance(address, airdropInfo.coinInfo.contAddr)
                    .then((res) => {
                        setBalance(Number(res));
                        //가격정보 호출
                        // MetaMaskService.getBalance(coinInfo.address, contract, coinInfo.symbolName,coinInfo.contAddr, 0).then((rst)=>{
                        setPrice(Number((new bigDecimal(airdropInfo.coinInfo.exchangeRate).multiply(new bigDecimal(res))).getValue()));

                        // });
                    });
            } catch (E) {
                console.info("E", E);
                setBalance(0);
                setPrice(0);
            }
        }else{
            setBalance(Number(hookCoinBalance.data?.formatted));
            //가격정보 호출
            // MetaMaskService.getBalance(coinInfo.address, contract, coinInfo.symbolName,coinInfo.contAddr, 0).then((rst)=>{
            setPrice(Number((new bigDecimal(airdropInfo.coinInfo.exchangeRate).multiply(new bigDecimal(hookCoinBalance.data?.formatted))).getValue()));

        }
    }, [airdropInfo.coinInfo]);
    return (
        <FlexColumn>
            <FlexColumn gap={12}>
                <FlexColumn className={styles.txt20_B01}>{t("지갑당 에어드랍 수량")}</FlexColumn>
                <FlexColumn>
                    <FlexRowSpace>
                        <FlexRowStart style={{width:'80%'}}>
                            <DefaultInput
                                error={errors.amount}
                                inputElement={
                                    <input
                                        type={"number"}
                                        className={styles.inputText_44}
                                        placeholder={t("코인 수량 입력")}
                                        {...register("amount", {
                                            required: t("코인 수량 입력"),
                                            onChange: (el)=>{
                                                setAmount(el.target.value);
                                                setAirdropInfo({
                                                    ...airdropInfo,
                                                    amount: el.target.value,
                                                    receiveAmount : new bigDecimal(airdropInfo.airdropCnt).multiply(new bigDecimal(el.target.value)).getValue()
                                                });
                                                const balanceDecimal = new bigDecimal(balance).round(4);
                                                if(new bigDecimal(el.target.value).compareTo(new bigDecimal(0)) <= 0){
                                                    setError("amount", {type:'custom', message:t('수량을 입력해 주세요.')});
                                                    setBtnActive(false);
                                                }else if(balanceDecimal.compareTo(new bigDecimal(el.target.value)) > 0){
                                                    setBtnActive(true);
                                                    clearErrors('amount');

                                                }else{
                                                    setError("amount", {type:'custom', message:t('최대 {0}까지 가능합니다.', {0:decimalFormat(balance, 4)})});
                                                    setBtnActive(false);
                                                }
                                            }
                                        })}
                                    />
                                }
                            />
                        </FlexRowStart>
                        <div className={styles.txt20_B01} style={{width:'20%', textAlign:'right', marginTop:'22px', marginBottom:'auto', borderBottom:'solid 1px #f2f4f8'}}>
                            {airdropInfo.coinInfo.symbolName}
                        </div>
                    </FlexRowSpace>
                    <FlexRowSpace>
                        <FlexRowStart className={styles.txt11_B04}>
                            <div style={{padding:'8px 12px', background:'#F2F4F7', borderRadius:'8px'}}>{t("사용가능")} {decimalFormat(balance, 4)} {airdropInfo.coinInfo.symbolName}</div>
                        </FlexRowStart>
                        <FlexRowEnd className={styles.txt12_B04}>{t("{0}원", {0:decimalFormat(price,2)})}</FlexRowEnd>
                    </FlexRowSpace>
                </FlexColumn>

                <FlexColumnCenter className={styles.txt13_B05}  style={{padding:'16px', borderRadius:'12px', backgroundColor:'#F8F9FC'}}>
                    <div style={{textAlign:'center'}}><Trans t={t} i18nKey={"지갑당 {0} {1} 씩<br/>{2}개의 지갑에 보냅니다."} values={{0:amount, 1:airdropInfo.coinInfo.symbolName, 2:airdropInfo.airdropCnt}}/> </div>
                    <div className={styles.txt17_600_mint}>{t("총 {0} {1} 사용", {0:new bigDecimal(airdropInfo.airdropCnt).multiply(new bigDecimal(amount)).getValue(), 1:airdropInfo.coinInfo.symbolName})}</div>
                    {/*<FlexRowCenter gap={5} style={{padding:'10px', width:'60%', borderRadius:'16px', border:'solid 1px #f2f4f8'}}>*/}
                    {/*    서비스 이용료*/}
                    {/*    <img*/}
                    {/*            src={require("../../assets/icons/question.png")}*/}
                    {/*            alt={"question"}*/}
                    {/*            width={"20px"}*/}
                    {/*          />*/}
                    {/*    <span className={styles.txt14_BOLD_B04}> 1000 P</span>*/}
                    {/*</FlexRowCenter>*/}
                </FlexColumnCenter>

            </FlexColumn>
            <FlexBottomStart style={ toggle ? {top: 'calc(100% - 300px)'} : {top: 'calc(100% - 120px)'}}>
                <FlexColumn>
                    <FlexColumn gap={16} className={styles.airdrop_open} style={toggle ? {height:'244px'} : {height:'64px'}}>
                        <FlexRowSpace>
                            <div className={styles.txt16_500_B01}>{t("에어드랍 오픈 예약")}</div>
                            <div>
                                <ToggleBtn onClick={()=>{
                                    setToggle((prev) => !prev);
                                    setAirdropInfo(
                                        {...airdropInfo,
                                            airdropOpenYn : !toggle ? "Y" : "N"
                                        }
                                    );
                                }} toggle={toggle}>
                                    <ToggleCircle toggle={toggle} />
                                </ToggleBtn>
                            </div>
                        </FlexRowSpace>
                        {
                            toggle && (
                                <>
                                    <FlexColumn gap={8}>
                                        <FlexRowSpace className={styles.select_mainnet} style={{backgroundColor:'#ffffff'}}>
                                            <FlexRowStart gap={12}>
                                                <div>
                                                    <img src={require('../../assets/imges/airdrop/airdrop_ymd.png')} height={28}/>
                                                </div>
                                                <FlexColumn className={styles.txt12_B05}>
                                                    <div>{t("날짜")}</div>
                                                    <div>
                                                        <DefaultInput
                                                            error={errors.startDate}
                                                            marginBottom={3}
                                                            inputElement={
                                                                service ? (
                                                                    <span style={{color:'#111111'}}>{t("미지정")}</span>
                                                                ) : (
                                                                    // <input
                                                                    //     type={"text"}
                                                                    //     className={styles.inputText_33}
                                                                    //     placeholder={"YYYY.MM.DD"}
                                                                    //     readOnly={true}
                                                                    //     {...register("startDate", {
                                                                    //         required: "시작 날짜를 지정해 주세요.",
                                                                    //     })}
                                                                    //     onClick={()=>{
                                                                    //         testClick()
                                                                    //     }}
                                                                    // />
                                                                    <DatePicker
                                                                        className={styles.customDatePicker}
                                                                        selected={startDate}
                                                                        placeholderText={"YYYY.MM.DD"}
                                                                        dateFormat="yyyy.MM.dd"
                                                                        locale="ko"
                                                                        onChange={(date: Date) => {
                                                                            setStartDate(date);
                                                                            setAirdropInfo({
                                                                                ...airdropInfo,
                                                                                airdropStartDay: date ? moment(date).format('YYYY-MM-DD') : "",
                                                                            });
                                                                            clearErrors("startDate");
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                            />
                                                    </div>
                                                </FlexColumn>
                                            </FlexRowStart>
                                        </FlexRowSpace>
                                        <FlexRowSpace className={styles.select_mainnet} style={{backgroundColor:'#ffffff'}}>
                                            <FlexRowStart gap={12}>
                                                <div>
                                                    <img src={require('../../assets/imges/airdrop/airdrop_hs.png')} height={28}/>
                                                </div>
                                                <FlexColumn className={styles.txt12_B05}>
                                                    <div>{t("시간")}</div>
                                                    <div>
                                                        <DefaultInput
                                                            error={errors.startTime}
                                                            marginBottom={3}
                                                            inputElement={
                                                                service ? (
                                                                    <span style={{color:'#111111'}}>{t("미지정")}</span>
                                                                    ) : (
                                                                    <DatePicker className={styles.customDatePicker}
                                                                                selected={startTime}
                                                                                placeholderText={t("시작시간")}
                                                                                showTimeSelect
                                                                                showTimeSelectOnly
                                                                                timeIntervals={15}
                                                                                timeCaption="Time"
                                                                                dateFormat="aa h:mm"
                                                                                locale="ko"
                                                                                onChange={(date: Date) => {
                                                                                    setStartTime(date);
                                                                                    setAirdropInfo({
                                                                                        ...airdropInfo,
                                                                                        airdropStartTime:date ? moment(date).format('HH:mm') : "",
                                                                                    });

                                                                                    clearErrors("startTime");
                                                                                }}
                                                                    />
                                                                )
                                                            }
                                                            />
                                                    </div>
                                                </FlexColumn>
                                            </FlexRowStart>
                                        </FlexRowSpace>
                                    </FlexColumn>
                                    <FlexColumn>
                                        <div className={styles.txt14_B05}>
                                            <input type="checkbox" checked={service} id={'secession'} onChange={(e)=>{
                                                setService((prev: boolean) => !prev);
                                                setStartDate('');
                                                setStartTime('');
                                                clearErrors("startDate");
                                                clearErrors("startTime");
                                                setAirdropInfo({
                                                    ...airdropInfo,
                                                    airdropStartDay: "",
                                                    airdropStartTime: "",
                                                });
                                            }} />
                                            <label htmlFor={'secession'}>{t("날짜와 시간은 나중에 설정할게요")}</label>
                                            {/*<Checkbox checked={service} onChange={(e)=>{setService((prev: boolean) => !prev)}} />날짜와 시간은 나중에 설정할게요*/}
                                        </div>
                                    </FlexColumn>
                                </>
                            )
                        }

                    </FlexColumn>
                    <FlexColumn>
                        <BottomButton
                            isActive={btnActive}
                            backgroundColor={"#3a71ff"}
                            textColor={"white"}
                            // border={"solid 1px #dbdee6"}
                            onClick={handleSubmit(onSubmit)}
                            text={t("다음")}
                        />
                    </FlexColumn>
                </FlexColumn>
            </FlexBottomStart>
        </FlexColumn>
    )
}