import React, {CSSProperties, useEffect, useState} from "react";

import {inspect} from "util";
import styles from "../../common/css/zigle.module.scss";
import BottomLayer, {
    BottomContent,
    BottomTitlte,
    BottomWrapper,
    FlexBottomButton,
    Overlay
} from "../common/container/BottomLayer";
import {
    FlexBottom, FlexBottomFree,
    FlexBottomStart,
    FlexColumn,
    FlexColumSpace,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart
} from "../common/container/PageContainer";
import BottomButton from "../common/button/BottomButton";
import {useSelector} from "react-redux";
import MetaMaskService from "../../services/metamask.service";
import { Toast } from "../common/popup/ToastAlert";
import CommonService from "../../services/common.service";
import PhoneUpdatePopup from "./PhoneUpdatePopup";
import PhoneInsertPopup from "./PhoneInsertPopup";
import {useInView} from "react-intersection-observer";
import PhoneTransPopup from "./PhoneTransPopup";
import { maskingPhone } from "../../common/js/utilities";
import {useTranslation} from "react-i18next";

interface IPhoneView {
    show: boolean;
    setShow: any;
    viewItem: any;
    setReLoad:any;
    deleteItem: (item: string)=> void;
}
function PhoneView(props:IPhoneView) {
    const { t } = useTranslation();
    const [reLoad, setReLoad] = useState(false);
    const [insertPopupShow, setInsertPopupShow] = useState(false);
    const [phoneTransShow, setPhoneTransShow] = useState(false);
    const [phoneTransUser, setPhoneTransUser] = useState({
        linkType:'',
        linkName:'',
        sendUserId : '',
        sendUserName : '',
        sendWalletAddress:'',
        countryNum:'',
        phoneNum:'',
    });
    useEffect(()=>{
        if(props.viewItem.hpName)
        {
            setPhoneTransUser({
                ...phoneTransUser,
                linkType:'hpTrans',
                linkName:t('전화번호 송금'),
                sendUserName:props.viewItem.hpName,
                countryNum:props.viewItem.hpCountry,
                phoneNum:props.viewItem.hpNum,
            })
        }

    },[props.viewItem])
    return (
        <>
            <PhoneTransPopup show={phoneTransShow} setShow={setPhoneTransShow}
                             phoneTransUser={phoneTransUser}/>
            <PhoneUpdatePopup setShow={props.setShow} popupShow={insertPopupShow}
                              setPopupShow={setInsertPopupShow} updateItem={props.viewItem}
                              setReLoad={props.setReLoad} deleteItem={props.deleteItem}/>
        {
            props.show &&
                (
                    <Overlay>
                        <FlexBottomFree  style={{ top: 'calc(100% - (180px  + env(safe-area-inset-bottom)))', height:'180px'}}>
                            <FlexRowEnd>
                                <img src={require('../../assets/icons/close_28.png')} width={28} height={28}
                                     alt={"close"}
                                     onClick={()=>props.setShow(false)}/>
                            </FlexRowEnd>
                            <FlexColumn gap={34}>
                                <FlexColumn>
                                    <FlexRowSpace>
                                        <FlexRowStart>
                                            <div style={{paddingRight: '12px'}}>
                                                <img
                                                    src={props.viewItem.profileImageFileId ? props.viewItem.profileImageFileId : 'https://s3.ap-northeast-2.amazonaws.com/bucket.zigle.io/profiles/profile' + (props.viewItem.hpId % 9) + '_1.png'}
                                                    alt="bgIcon" style={{width: '38px', borderRadius: '15px'}}/>
                                            </div>
                                            <FlexColumn>
                                                <div className={styles.txt16_B02}>{props.viewItem.hpName}</div>
                                                <div
                                                    className={styles.txt14_B05}>{props.viewItem.hpCountry} {maskingPhone(props.viewItem.hpNum)}</div>
                                            </FlexColumn>
                                        </FlexRowStart>
                                    </FlexRowSpace>
                                </FlexColumn>
                                <FlexRowSpace style={{width: 'calc(100% + 40px)', marginLeft:'-20px'}}>
                                    <BottomButton
                                        isActive={true}
                                        backgroundColor={"#f2f4f6"}
                                        textColor={"#3a71ff"}
                                        text={t("연락처 편집")}
                                        onClick={() => {
                                            setInsertPopupShow(true);
                                            props.setShow(false);
                                        }}
                                    />
                                    <BottomButton
                                        isActive={true}
                                        backgroundColor={"#3a71ff"}
                                        textColor={"#ffffff"}
                                        text={t("코인 송금")}
                                        onClick={() => {
                                            setPhoneTransShow(true);
                                            props.setShow(false);
                                        }}
                                    />
                                </FlexRowSpace>
                            </FlexColumn>
                        </FlexBottomFree>
                    </Overlay>
                )
        }
        </>
    );
};

export default PhoneView;
