import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import {FlexColumn, FlexRowCenter, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import {ICoinInfo} from "../../common/dto/CoinInfoDto";
import {useInView} from "react-intersection-observer";
import CommonService from "../../services/common.service";
import {ILinkList} from "../../common/dto/LinkDto";
import AssetService from "../../services/asset.service";
import LinkItem from "./LinkItem";
import EmptyContent from "../common/EmptyContent";
import LinkPopup from "./LinkPopup";
import PopupService from "../../services/popup.service";
import {useTranslation} from "react-i18next";


interface ILinkWrapperProps {
    userId:string,
    blockSize?: number;
    orderBy:string;
    searchTxt?:string;
    limit:boolean;
    reLoad:boolean;
    onClickLinkItem?: (item: any)=> void;
}

export default function LinkWrapper(props:ILinkWrapperProps) {
    const { t } = useTranslation();
    const [linkList, setLinkList] = useState<Array<ILinkList>>([]);
    const [currPage, setCurrPage] = useState(1);
    const [blockSize] = useState(props.blockSize ? props.blockSize : 10);
    const [searchTxt, setSearchTxt] = useState('');
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);

    const [reSet, setReSet] = useState(false);

    useEffect(() => {
        const fetchData = async(searchTxt: any) =>{
            if(props.userId) {
                const response = await AssetService.myLinkList(searchTxt, props.userId, currPage, blockSize, props.orderBy);
                setLinkList([...linkList, ...response.data]);
                if ((!response.data.length) || (props.limit)) {
                    setLastList(true);
                    return;
                }
            }
        }
        if (!lastList) {
            
            fetchData(searchTxt);
        }
    },[currPage, searchTxt, props.userId, reSet]);
    useEffect(()=>{
        setCurrPage(1);
        setLinkList([]);
        setSearchTxt(props.searchTxt ? props.searchTxt : '');
        setLastList(false);
        setReSet(props.reLoad);
    },[props.searchTxt, props.reLoad]);

    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);
    return (
        <>


           <FlexColumn gap={12}>
               {
                   linkList.length > 0 ?
                       (
                           linkList.map((item, index)=>{
                               return (<LinkItem key={item.linkMngId} linkItem={item} onClick={()=>{
                                   if(props.onClickLinkItem) {
                                       props.onClickLinkItem(item)
                                   }
                               }} />)
                           })

                       )
                       :
                       (
                           <FlexRowStart key={1} gap={12} style={{ padding: '20px 16px', marginTop:'12px', border: 'solid 1px #f2f4f6'}}>
                               <img src={require('../../assets/icons/question2.png')} height={38}/>
                               <div className={styles.txt15_B02}>{t("만들어진 링크가 없습니다.")}</div>
                           </FlexRowStart>
                       )
               }
               {
                   Object.values(linkList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
               }
           </FlexColumn>
        </>
    );
}