import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {FlexColumEnd, FlexColumn, FlexRowCenter, FlexRowEnd, FlexRowStart} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import React from "react";

import moment from "moment";



interface IChatViewItem {
    viewItem : any;
    handleOnClick?: (userId: string, nickName:string)=> void;
    monthResult:any;
}
export default function ChatViewItem(props:IChatViewItem){
    const { t } = useTranslation();

    const userId: string = useSelector((state: any) => state.userReduce.userId);

    return(
        <>
            {
                props.monthResult === "Y" &&
                (
                    <FlexRowCenter>
                        <div style={{background:'#2F3236', padding:'4px 12px', borderRadius:'1000px'}} className={styles.txt12_W01}>{moment(props.viewItem.createdAt).format('MM.DD')}.{t(moment(props.viewItem.createdAt).format('dddd'))}</div>
                    </FlexRowCenter>
                )
            }
            {
                (userId === props.viewItem.userId) ? (
                    <FlexColumn key={props.viewItem.id}>
                        <FlexColumEnd gap={2}>
                            <FlexRowEnd gap={4}>
                                <div className={styles.txt10_B06} style={{paddingTop: '20px', marginBottom: '0px', marginTop: 'auto'}}>{moment(props.viewItem.createdAt).format('HH:mm')}</div>
                                <div className={styles.txt14_B02} style={{
                                    padding: '10px 14px',
                                    borderRadius: '16px',
                                    background: '#3A71FF',
                                    color:'#FFFFFF',
                                    wordBreak:'break-all'
                                }}>
                                    {props.viewItem.fileUrl && (<img src={props.viewItem.fileUrl} width={120} />)}
                                    {props.viewItem.text}
                                </div>
                            </FlexRowEnd>
                        </FlexColumEnd>
                    </FlexColumn>
                ) : (
                    <FlexColumn key={props.viewItem.id}>
                        <FlexRowStart gap={8}>
                            <img
                                src={props.viewItem.profileImageUrl ? props.viewItem.profileImageUrl : 'https://s3.ap-northeast-2.amazonaws.com/bucket.zigle.io/profiles/profile' + (Number(props.viewItem.userId.charAt(props.viewItem.userId.length - 1)) <= 0 ? 1 : Number(props.viewItem.userId.charAt(props.viewItem.userId.length - 1)) % 9) + '.png'}
                                width={24}
                                alt={"profile image"}
                                style={{
                                    borderRadius: '24px',
                                    aspectRatio: '1',
                                    objectFit: 'cover',
                                    marginTop: '0px',
                                    marginBottom: 'auto',
                                }}
                            />
                            <FlexColumn gap={2}>
                                <div className={styles.txt12_B02}>{props.viewItem.username}</div>
                                <FlexRowStart gap={4}>
                                    <div className={styles.txt14_B02} style={{
                                        padding: '10px 14px',
                                        borderRadius: '16px',
                                        background: '#F2F4F6',
                                        wordBreak:'break-all'
                                    }}>
                                        {props.viewItem.fileUrl && (<img src={props.viewItem.fileUrl} width={120} />)}
                                        {props.viewItem.text}
                                    </div>
                                    <div className={styles.txt10_B06} style={{
                                        paddingTop: '20px',
                                        minWidth: '45px',
                                        marginBottom: '0px',
                                        marginTop: 'auto'
                                    }}>{moment(props.viewItem.createdAt).format('HH:mm')}
                                        {
                                            props.viewItem.userId !=='6524f77c29bf0700010561d0' && (
                                                <img src={require('../../assets/imges/chat/dots_three.png')} width={16} onClick={()=>{
                                                    if(props.handleOnClick){
                                                        props.handleOnClick(props.viewItem.userId, props.viewItem.username);
                                                    }
                                                }}/>
                                            )
                                        }

                                    </div>
                                </FlexRowStart>
                            </FlexColumn>

                        </FlexRowStart>
                    </FlexColumn>
                )
            }
        </>
    )

}