import React, {useEffect, useRef, useState} from "react";
import {useInView} from "react-intersection-observer";
import styles from "../common/css/zigle.module.scss";
import PageTitle, {
    PageContent, PageRootContainer
} from "../components/common/container/PageContainer";
import {Input, TextField} from "@material-ui/core";
import {useNavigate} from "react-router-dom";
import AssetService from "../services/asset.service";
import {ILinkList} from "../common/dto/LinkDto";
import {useForm} from "react-hook-form";
import BottomButton from "../components/common/button/BottomButton";
import LinkStep1 from "../components/creatlink/LinkStep1";
import LinkStep3 from "../components/creatlink/LinkStep3";
import LinkStep4 from "../components/creatlink/LinkStep4";
import MetaMaskService from "../services/metamask.service";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import MainnetList from "../components/common/MainnetList";
import CoinList from "../components/common/CoinList";
import {Toast, ToastError} from "../components/common/popup/ToastAlert";
import {useAccount} from "wagmi";
import {useWalletEthersPorvider} from "../common/js/ethersProviderHook";
import {IMainnetInfo} from "../common/dto/MainnetInfo";
import CommonService from "../services/common.service";
import CoinPopup from "../components/tokenlist/CoinPopup";
import CoinSelect from "../components/common/CoinSelect";
import CoinWrite from "../components/tokenlist/CoinWrite";

export default function CreatLink() {

    const navigate = useNavigate();
    const { t } = useTranslation();

    
    const chainId = useSelector((state:any) => state.walletReducer.chainId);
    const disconnect = useSelector((state:any) => state.walletReducer.disconnect);
    const mainNetDetail = useSelector((state: any) => state.walletReducer.mainNetDetail);
    const [mainnetList, setMainnetList] = useState<any[]>([]);
    const [mainnetItem, setMainnetItem] = useState<IMainnetInfo>();
    const [mainnetInfo, setMainnetInfo] = useState<IMainnetInfo>();
    const [home, setHome] = useState(true);
    const { address, isConnected } = useAccount();

    const [pageMode, setPageMode] = useState('list');

    const provider = useWalletEthersPorvider();
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const [depth, setDepth] = useState(0);
    const [pageTitle, setPageTitle] = useState('');
    const [showCoinPopup, setShowCoinPopup] = useState(false);

    const [linkInfo, setLinkInfo] = useState<{pageTitle:string; pageType:string; linkName:string; coinId:string; coinSymbolName:string; mainnetId:string; mainnetLinkName:string; amount:number;}>({
        pageTitle:'',
        pageType:'createLink',
        linkName:'',
        coinId: '',
        coinSymbolName: '',
        mainnetId : '',
        mainnetLinkName: '',
        amount : 0
    })

    const handleOnClick = (selectItem: any) =>{
        if(!address){
            ToastError(t("지갑연결을 확인해 주세요."));
            return false;
        }
        if(depth===1)
        {
            setLinkInfo({
                ...linkInfo,
                coinId:selectItem.coinId,
                coinSymbolName:selectItem.symbolName
            });
            setDepth(3);
        }

    }
    useEffect(() => {
        async function fetchMainnetDetail() {
            const response = await CommonService.mainnetList('', 1, 100, '');
            setMainnetList(response.data);
            let setMainNetDetail = await mainNetDetail;
            setLinkInfo({
                ...linkInfo,
                mainnetId: setMainNetDetail.mainnetId,
                mainnetLinkName: setMainNetDetail.linkName
            });
            setMainnetItem(setMainNetDetail);
            setMainnetInfo(setMainNetDetail);
        }
        fetchMainnetDetail();

    },[mainNetDetail]);
    const onClickMainnet = () =>{
        setShowCoinPopup(true);
    }
    const onHandleClickMainnet = (selectItem:any) =>{
        if(chainId.toString() !== selectItem.chainId.toString()) {
            MetaMaskService.setSwitchNetwork(provider, parseInt(selectItem.chainId), selectItem.mainnetName, selectItem.symbolName, selectItem.decimalVal, selectItem.rpcUrl, selectItem.explorerUrl, '').then(
                (response) => {
                    if (!response.status) {
                    }
                }
            )
        };
        setShowCoinPopup(false);
    }

    return (
        <PageRootContainer>
            <CoinPopup setShow={setShowCoinPopup} show={showCoinPopup} mainnetList={mainnetList} onHandleClickMainnet={onHandleClickMainnet} />
            {
                (pageMode === 'list') ? (
                    <PageTitle
                        home={home}
                        textTitle={linkInfo.pageTitle}
                        onClickLeft={() => {
                            if (depth == 0) {
                                navigate(-1);
                            } else {
                                if (depth === 3) {
                                    setLinkInfo({
                                        ...linkInfo,
                                        coinId: '',
                                        coinSymbolName: '',
                                    });
                                } else if (depth === 2) {
                                    setLinkInfo({
                                        ...linkInfo,
                                        mainnetId: '',
                                        mainnetLinkName: ''
                                    });
                                } else if (depth === 1) {
                                    setLinkInfo({
                                        ...linkInfo,
                                        pageTitle: '',
                                        linkName: ''
                                    })
                                }
                                setDepth(depth - 1);
                            }
                        }}
                        mainnetName={(depth === 1) ? linkInfo.mainnetLinkName : ''}
                        onClickMainnet={onClickMainnet}
                        rightSrc={(depth === 1) ? require('../assets/imges/plus_icon.png') : ''}
                        onClickRight={() => {
                            if (depth === 1) {
                                setPageMode('write');
                            }
                        }}
                    />
                ) : (
                <PageTitle
                    textTitle={t('토큰 추가 요청')}
                    onClickLeft={()=>setPageMode('list')}
                />
                )
            }
            <PageContent>
                    { (depth === 0 && pageMode === 'list') && <LinkStep1 setDepth={setDepth} linkInfo={linkInfo} setLinkInfo={setLinkInfo} /> }
                    {/*{ depth === 1 && <MainnetList handleOnClick={handleOnClick} /> }*/}
                    {/*{ depth === 2 && <CoinList itemInfo={linkInfo} handleOnClick={handleOnClick} /> }*/}
                    { (depth === 1 && pageMode === 'list') && <CoinSelect itemInfo={linkInfo} handleOnClick={handleOnClick} setPageMode={setPageMode} /> }
                    { (depth === 3 && pageMode === 'list') && <LinkStep4 setDepth={setDepth} linkInfo={linkInfo} setLinkInfo={setLinkInfo} /> }
                    { (pageMode === 'write') && <CoinWrite setPageMode={setPageMode}  /> }
            </PageContent>
        </PageRootContainer>
    )
}