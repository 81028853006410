import React, {useCallback, useEffect, useRef, useState} from "react";
import AssetService from "../../services/asset.service";
import Button34 from "../common/button/Button34";
import {
    BellDiv,
    FlexColumEnd,
    FlexColumn,
    FlexRowAround, FlexRowCenter,
    FlexRowEnd, FlexRowStart,
    GrayBox,
    GrayBoxW00,
    YelloBox, ZSelect
} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss"
import {Box} from "../../pages/Profile";
import EmptyContent from "../common/EmptyContent";
import {useInView} from "react-intersection-observer";
import CommonService from "../../services/common.service";
import {JsonData} from "../../common/dto/CommonDto";
import CopyToClipboard from "react-copy-to-clipboard";
import BottomLayer from "../common/container/BottomLayer";
import PhoneConfirm from "./PhoneConfirm";
import SndHisItem from "./SndHisItem";
import {Trans, useTranslation} from "react-i18next";

// let rstMap = new Map<string,string>();
// rstMap.set('CI','회수신청');
// rstMap.set('CR','회수대기');
// rstMap.set('CO','회수완료');
// rstMap.set('CF','회수실패');
// rstMap.set('WR','입금대기');
// rstMap.set('SM','입금완료(sms발송)');
// rstMap.set('SN','문자발송실패');
// rstMap.set('DW','승인신청');
// rstMap.set('DI','승인완료');
// rstMap.set('DR','송금대기');
// rstMap.set('DO','송금완료');
// rstMap.set('DF','송금실패');
// rstMap.set('FF','No Wallet');

export const RST_MAP:JsonData = {
    'CI':'회수신청',
    'CR':'회수대기',
    'CO':'회수완료',
    'CF':'회수실패',
    'WR':'입금대기',
    'SM':'입금완료(sms발송)',
    'SN':'문자발송실패',
    'DW':'승인신청',
    'DI':'송금예정',
    'DR':'송금대기',
    'DO':'송금완료',
    'DF':'송금실패',
    'FF':'No Wallet',
}

export default function CoinSndHis() {
    const { t } = useTranslation();
    const [list, setList] = useState<any[]>([])
    const [type, setType] = useState<string>("");
    const [showPop, setShowPop] = useState(false);
    const [urlKey, setUrlKey] = useState<string>("");
    const [detail, setDetail] = useState({});

    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [currPage, setCurrPage] = useState(1);
    const [blockSize] = useState(10);

    const refDwCount = useRef(0);
    let monthDay = "";
    let monthResult = "";


    const fetchData = useCallback(()=>{
        AssetService.sendHis(type,currPage, '').then((data) => {
            if (!data.data.length) {
                setLastList(true);
                return;
            }
            data.data.map((item:any)=>{
                if (item.rstCode === 'DW') {
                    refDwCount.current = refDwCount.current + 1;
                }

            });

            setList([...list, ...data.data]);
        });
    },[list,currPage,type])

    useEffect(() => {
        if (currPage === 1) {
            refDwCount.current = 0;
        }
        fetchData();
        console.info("refDwCount.current", refDwCount.current);
    }, [currPage,type]);

    useEffect(() => {
        if (inView && lastList) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ lastList, inView]);

    return (
        <Box gap={24}>
            <PhoneConfirm show={showPop} setShow={setShowPop} urlKey={urlKey} item={detail} refDwCount={refDwCount} pageMode={'Snd'}/>
            <FlexColumn>
                {/*<FlexRowStart>자산현황</FlexRowStart>*/}
                <FlexRowEnd>
                    <ZSelect onChange={el => {
                        setList([]);
                        setLastList(false);
                        setCurrPage(1);
                        setType(el.target.value);
                    }}>
                        <option value={""}>{t("전체")}</option>
                        <option value={"HP"}>{t("전화번호송금")}</option>
                        <option value={"TR"}>{t("안전송금")}</option>
                    </ZSelect>
                </FlexRowEnd>
            </FlexColumn>
            {
                refDwCount.current > 0 && (
                    <YelloBox style={{height: '56px'}}>
                        <BellDiv className={styles.txt14_B04}>
                            <Trans t={t} i18nKey={"승인하실 송금이 <b>{0}건</b> 있습니다."} values={{0:refDwCount.current}}/>
                        </BellDiv>
                    </YelloBox>
                )
            }

            <FlexColumn gap={40}>
                {
                    list.length > 0 ? (
                            list.map((item, index) => {
                                let tmpDay = new Date(item.datetime).getMonth()+1 +"월 "+new Date(item.datetime).getDate()+"일";

                                if((tmpDay) && (monthDay != tmpDay)){
                                    monthResult = "Y";
                                    monthDay = tmpDay;
                                }else{
                                    monthResult = "N";
                                }

                                return (
                                    <SndHisItem
                                        key={item.idx}
                                        item={item}
                                        index={index}
                                        setUrlKey={setUrlKey}
                                        setShowPop={setShowPop}
                                        setDetail={setDetail}
                                        monthResult={monthResult}
                                    ></SndHisItem>
                                );
                            })

                        )
                        :
                        (
                            <EmptyContent titleText={t('송금 이력이 없어요.')}/>
                        )

                }
                {
                    Object.values(list).length >= blockSize ? (
                        <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) : null
                }
            </FlexColumn>

        </Box>
    );
};