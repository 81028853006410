import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import {FlexColumn, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import {IMainnetInfo} from "../../common/dto/MainnetInfo";
import {useInView} from "react-intersection-observer";
import CommonService from "../../services/common.service";
import {useForm} from "react-hook-form";
import {ICoinInfo} from "../../common/dto/CoinInfoDto";
import EmptyContent from "../common/EmptyContent";
import {Trans, useTranslation} from "react-i18next";

interface ICoinWrapperProps {
    detailOnClick?: any;
    setItemInfo?: any;
    coinLink?: string;
    mainLink?: string;
}

export default function CoinWrapper(props: ICoinWrapperProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();


    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();
    const [searchCoin, setSearchCoin] = useState(props.coinLink);

    const [coinList, setCoinList] = useState<Array<ICoinInfo>>([]);
    const [currPage, setCurrPage] = useState(1);
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [blockSize] = useState(10);


    useEffect(() => {
        const fetchData = async(searchCoin: any) =>{
            let response;
            if(props.coinLink)
            {
                response = await CommonService.chainIdTokenLink(props.mainLink, props.coinLink);
                if (!response.data.length) {
                    setLastList(true);
                    return;
                }
            }else{
                response = await CommonService.coinlist(searchCoin, currPage, blockSize);
                if (!response.data.length) {
                    setLastList(true);
                    return;
                }
            }
            setCoinList([...coinList, ...response.data]);
        }
        if (!lastList) {
            
            fetchData(searchCoin);
        }
    },[currPage, searchCoin]);

    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);

    return (
        <>
            <FlexColumn className={styles.tokenEventBadge}>
                    <span className={styles.tokenEventText}>
                        <Trans t={t} className={styles.transSpanWeight400} i18nKey={"<span>아래 항목을 선택하면 </span>메타마스크에 해당 메인넷으로 등록<span>됩니다.</span>"}/>
                    </span>
            </FlexColumn>
            <FlexColumn>
                <FlexRowStart>
                    <div style={{width:'28px', border: '0', borderBottom: 'solid 1px #f2f4f8', height: '47px', display: 'flex',alignItems: 'center'}}><img src={require('../../assets/imges/search_icon.png')} style={{width:'28px'}} /></div>
                    <div style={{width:'100%'}}>
                        <input
                            type={"text"}
                            className={styles.inputText_44}
                            placeholder={t('코인명을 입력해 주세요')}
                            defaultValue={searchCoin}
                            onChange ={(el) => {
                                setCurrPage(1);
                                setCoinList([]);
                                setLastList(false);
                                setSearchCoin(el.target.value);
                            }}
                        />
                    </div>
                </FlexRowStart>
            </FlexColumn>
            {
                coinList.length > 0 ? (
                    coinList.map((item, index) => {
                        return (
                            <FlexColumn key={item.coinId} style={{height:'78px'}} onClick={()=>{props.detailOnClick(item)}}>
                                <FlexRowSpace>
                                    <FlexRowStart>
                                        <div style={{paddingRight:'12px'}}>
                                            <img src={item.symbolImgFileId} alt="bgIcon" width={38} />
                                        </div>
                                        <FlexColumn>
                                            <div className={styles.txt14_B01}>{item.coinName}</div>
                                            <div className={styles.txt12_B05}>{item.symbolName}</div>
                                        </FlexColumn>
                                    </FlexRowStart>
                                    <FlexRowEnd>
                                        <img
                                            src={require("../../assets/icons/btnArrow.png")}
                                            height={28}
                                            alt={"arrow"}
                                        />
                                    </FlexRowEnd>
                                </FlexRowSpace>
                            </FlexColumn>
                        )
                    })
                ) : (
                    < EmptyContent titleText={t('코인을 찾을수 없어요')} titleImage={require('../../assets/icons/empty_icon.png')}/>
                )
            }
            {
                Object.values(coinList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
            }
        </>
    );
}
