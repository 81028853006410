import React, {useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import {TextField} from "@material-ui/core";
import {FlexBottom, FlexColumn} from "../common/container/PageContainer";
import BottomButton from "../common/button/BottomButton";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

interface ILinkStep {
    linkInfo: any;
    setLinkInfo: any;
    setDepth: any;
}

export default function LinkStep1({linkInfo, setLinkInfo, setDepth}: ILinkStep) {
    const [btnActive, setBtnActive] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const inputRef = useRef<HTMLFormElement>(null);

    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
    } = useForm();
    const onSubmit = (data: any) => {
        setLinkInfo({...linkInfo, pageTitle:data.linkName, linkName :data.linkName})
        setDepth(1)
    }
    return (
        <FlexColumn>
            <FlexColumn>
                <form
                    ref={inputRef}
                    onSubmit={handleSubmit(onSubmit)}
                    style={{ width: "100%" }}
                >
                    <textarea
                        style={{border:'none'}}
                        className={styles.textAreaBox}
                        placeholder={t("도네이션 링크의\n이름을 입력해주세요")}
                        rows={2}
                        maxLength={12}
                        defaultValue={linkInfo.pageTitle}
                        {...register("linkName", {
                            required: t("도네이션 링크의\n이름을 입력해주세요"),
                            maxLength:12,
                            onChange: (el) => {
                                if(el.target.value){
                                    setBtnActive(true)
                                }else{
                                    setBtnActive(false)
                                }
                            }
                        })}
                    />
                </form>
            </FlexColumn>
            <FlexBottom>
                <BottomButton
                    isActive={btnActive}
                    backgroundColor={"#3a71ff"}
                    textColor={"white"}
                    // border={"solid 1px #dbdee6"}
                    onClick={handleSubmit(onSubmit)}
                    text={t("다음")}
                />
            </FlexBottom>
        </FlexColumn>
    )
}