import React, {CSSProperties, useEffect, useState} from "react";

import {inspect} from "util";
import styles from "../../common/css/zigle.module.scss";
import BottomLayer, { FlexBottomButton } from "../common/container/BottomLayer";
import {FlexColumn, FlexRowCenter, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import BottomButton from "../common/button/BottomButton";
import {useSelector} from "react-redux";
import MetaMaskService from "../../services/metamask.service";
import CommonService from "../../services/common.service";
import Button34 from "../common/button/Button34";
import {Toast} from "../common/popup/ToastAlert";
import CopyToClipboard from "react-copy-to-clipboard";
import {ellipseAddress} from "../../common/js/utilities";
import moment from "moment";
import {Trans, useTranslation} from "react-i18next";
import PopupService from "../../services/popup.service";
import FavService from "../../services/fav.service";
import ProfileService from "../../services/profile.service";
interface ITokenDetail {
    show: boolean;
    setShow: any;
    walletList: any[];
    address:any;
    setWalletList:any;
    walletOnClick?: (item: string)=> void;
}
function WalletPopup(props:ITokenDetail) {
    const { t } = useTranslation();
    return (
        <BottomLayer show={props.show}
         setShow={props.setShow}
         title={t("지갑 연결 목록")}
         content={
             (
                 <FlexColumn gap={22} style={{paddingBottom:'24px'}}>
                     <FlexColumn>
                         <div  className={styles.txt12_B04} style={{textAlign:'center', width:'100%', padding:'16px 0px', borderRadius:'12px', backgroundColor:'#f2f4f6'}}>
                            <Trans t={t} i18nKey={"지글 계정에 연결이 됐던 지갑 목록입니다.<br/>현재 연결 된 지갑을 변경하시려면 메타마스크에서 변경 해 주세요."} />
                         </div>
                     </FlexColumn>
                     <FlexColumn gap={24} style={{paddingTop:'20px', maxHeight:'600px', overflow:'auto'}}>
                         {
                             props.walletList.length > 0 ? (
                                 props.walletList.map((item, index) => {
                                     return (
                                         <FlexRowSpace key={item.walletAddress}>
                                             <FlexRowStart style={{height:'40px'}} className={styles.txt16_B04} onClick={()=>{ if(props.walletOnClick){ props.walletOnClick(item.walletAddress)} }}>
                                                 <FlexColumn>
                                                     <div>{ellipseAddress(item.walletAddress, 4)}
                                                         <CopyToClipboard
                                                             text={item.walletAddress}
                                                             onCopy={() => Toast(t("주소를 복사 했습니다."))}
                                                         >
                                                         <img src={require('../../assets/icons/copy.png')} width={20} />
                                                         </CopyToClipboard>
                                                     </div>
                                                     <div className={styles.txt12_W03}>{t("마지막 연결")} {moment(item.regDate).format('YYYY-MM-DD HH:mm')}</div>
                                                 </FlexColumn>
                                             </FlexRowStart>
                                             <FlexRowEnd>
                                                 {
                                                     item.mainUse === "Y" ?
                                                         (<Button34 text={t("대표지갑")} type={"sky"} onClick={() => {}} />)
                                                         :
                                                         (
                                                             <img src={require('../../assets/imges/profile/no_main.png')} width={40}
                                                                  onClick={()=> {
                                                                      PopupService.newPopup(t("대표 지갑으로 설정할까요?")
                                                                          ,t("안전 송금 (닉네임),<br/>도네이션 링크로 코인을 받으면<br/>대표 지갑에 보관돼요")
                                                                          , ""
                                                                          ,{btnTxt:t("설정할래요"),
                                                                              onClick: () => {
                                                                                ProfileService.mainAddress(item.walletAddress).then(
                                                                                    (res) => {
                                                                                        props.setWalletList([...res.data]);
                                                                                        Toast(t("대표지갑이 변경되었습니다."));
                                                                                        props.setShow(false);
                                                                                    }
                                                                                )
                                                                              }}
                                                                          , {
                                                                              btnTxt:"닫기",
                                                                              onClick: () => {}
                                                                          }
                                                                      );
                                                                  }}/>
                                                         )
                                                 }
                                             </FlexRowEnd>
                                         </FlexRowSpace>
                                    )
                                })
                             ) : null
                         }
                     </FlexColumn>
                 </FlexColumn>
             )
         }/>
    );
}

export default WalletPopup;
