import React, {useEffect, useRef, useState} from "react";
import PageTitle, {
    Circle,
    FC, FlexBottom,
    FlexColumn, FlexColumnCenter,
    FlexColumSpace,
    FlexRowAround,
    FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, GrayBox,
    GrayBoxW01,
    PageContent,
    PageRootContainer,
    ZBadge,
} from "../components/common/container/PageContainer";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "../common/css/zigle.module.scss";
import IconKakao from "../assets/imges/login/iconKakao.png";
import IconGoogle from "../assets/imges/login/iconGoogle.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import IconCopy from "../assets/icons/copy.png";
import styled from "styled-components";
import Button56 from "../components/common/button/Button56";
import CommonService from "../services/common.service";
import CoinItem from "../components/profile/CoinItem";
import PopupService from "../services/popup.service";
import SndHis from "../components/profile/SndHis";
import RcvHis from "../components/profile/RcvHis";
import {Toast} from "../components/common/popup/ToastAlert";
import LinkWrapper from "../components/myLink/LinkWrapper";
import BottomButton from "../components/common/button/BottomButton";
import FavService from "../services/fav.service";
import PhoneTransPopup from "../components/Friend/PhoneTransPopup";
import {decimalFormat, ellipseAddress, maskingEmail} from "../common/js/utilities";
import {useTranslation} from "react-i18next";
import UserFriendList from "../components/profile/UserFriendList";
import UserChatRoom from "../components/profile/UserChatRoom";
import {IChatView} from "./Chat";

export const Box = styled(FC)``;

export const Bar = styled.div`
  background-color: #f2f4f6;
  height: 16px;
  width: calc(100% + 40px);
  margin-left: -20px;
`;

export const Bar1px = styled.div`
  background-color: #f2f4f6;
  height: 1px;
  width: calc(100% - 40px);
  //margin-left: -20px;
`;

export default function UserProfile({talkPlus}:IChatView) {
    const params = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {state} = useLocation();
    const [backType, setBackType] = useState(false);

    const [showFriend, setShowFriend] = useState(false);
    const [friendView, setFriendView] = useState("");

    const [zigleIdx, setZigleIdx] = useState('');
    const [userInfo , setUserInfo] = useState<any>([]);
    const [isMyFriend, setIsMyFriend]= useState('');
    const [blockSize] = useState(7);
    const [searchTxt, setSearchTxt] = useState('');
    const [showTokenPopup, setShowTokenPopup] = useState(false);
    const [itemInfo, setItemInfo] = useState('');
    const [reLoad, setReLoad] = useState(false);
    const [walletAddress, setWalletAddress] = useState('');
    const [phoneTransShow, setPhoneTransShow] = useState(false);
    const [friendCnt, setFriendCnt] = useState<{friendCnt:number, friendingCnt:number, chatRoomCnt:number, myRoomUser:number}>({
        friendCnt:0,
        friendingCnt:0,
        chatRoomCnt:0,
        myRoomUser:0
    });
    const [phoneTransUser, setPhoneTransUser] = useState({
        linkType:'',
        linkName:'',
        sendUserId : '',
        sendUserName : '',
        sendWalletAddress:'',
        countryNum:'',
        phoneNum:'',
    });
    const userId: string = useSelector((state: any) => state.userReduce.userId);
    const doname: string = useSelector((state: any) => state.userReduce.doname);

    const onClickLinkItem = (linkInfo:any)=>{
        navigate("/l/"+linkInfo.linkUrl)
    }
    const friendSetting=()=>{
        let titleMsg = t("프렌드하기");
        let message = t("'{0}' 님을 프렌드 할까요?", {0:userInfo?.doname});
        let subMessage = "";
        if(isMyFriend === "Y"){
            titleMsg = t("언프렌드");
            message = t("'{0}' 님을 언프렌드 할까요?", {0:userInfo?.doname});
            subMessage = "";
        }
        PopupService.newPopup(titleMsg
            ,message
            , subMessage
            ,{btnTxt:t("확인"),
                onClick: () => {
                    if(isMyFriend === "N"){
                        FavService.friendInsert(zigleIdx).then((response) => {
                            setIsMyFriend('Y');
                            Toast(t('친구등록이 완료 되었습니다.'));
                        });
                    }else{
                        FavService.friendDelete(zigleIdx).then((response) => {
                            setIsMyFriend('N');
                            Toast(t('친구취소가 완료 되었습니다.'));
                        });
                    }
                }}
            , {
                btnTxt:"",
                onClick: () => {}
            }
        );
    }
    useEffect(()=>{
        if(params.nick)
        {
            CommonService.donameSearch(params.nick).then(
                (response) => {
                    console.log(response.data);
                    setZigleIdx(response.data);
                    CommonService.friendUserInfo(response.data).then(
                        (response) => {
                            setUserInfo(response.data[0]);
                            setIsMyFriend(response.data[0]?.isMyFriend);
                            setPhoneTransUser({
                                ...phoneTransUser,
                                linkType:'safeTrans',
                                linkName:t('안전 송금'),
                                sendUserId : response.data[0]?.userId,
                                sendUserName : response.data[0]?.doname,
                            })
                        }
                    )
                    CommonService.friendCnt(response.data).then(res=>{
                        setFriendCnt(res.data)
                    });
                    CommonService.getWalletLoginAddress(response.data).then(
                        (res) => {
                            setWalletAddress(res.data.find((obj:any)=>{return obj.mainUse ==="Y"})?.walletAddress);
                        }
                    )
            })
        }
    }, [params.nick]);
    useEffect(()=>{
        if(state){
            const {back} = state;	// 3번 라인
            setBackType(back);
        }
    },[state]);
    const [showChatRoom, setShowChatRoom] = useState(false);

    return (
        <PageRootContainer>
            <UserFriendList show={showFriend} setShow={setShowFriend} modeType={friendView} doname={userInfo?.doname} userId={userInfo?.userId} friendCnt={friendCnt}/>
            <UserChatRoom show={showChatRoom} setShow={setShowChatRoom} doname={userInfo?.doname} userId={userInfo?.userId} talkPlus={talkPlus} />
            <PhoneTransPopup show={phoneTransShow} setShow={setPhoneTransShow} phoneTransUser={phoneTransUser}/>
            {
                backType === true ? (<PageTitle
                        onClickLeft={()=>{ navigate(-1); }}
                        rightSrc={require('../assets/icons/close_28.png')}
                        onClickRight={()=>{ navigate("/"); }}/>)
                    : (<PageTitle
                        rightSrc={require('../assets/icons/close_28.png')}
                        onClickRight={()=>{ navigate("/"); }}/>)
            }


                {
                    userInfo ?
                        (
                            <PageContent>
                                <FlexColumn gap={16}>
                                    <FlexRowSpace>
                                        <FlexRowStart gap={8}>
                                            <img
                                                src={userInfo?.profileImageFileId ? userInfo?.profileImageFileId : 'https://s3.ap-northeast-2.amazonaws.com/bucket.zigle.io/profiles/profile'+(Number(zigleIdx) % 9)+'_1.png'}
                                                width={50}
                                                alt={"profile image"}
                                                style={{borderRadius:'100px', aspectRatio:'1', objectFit:'cover'}}
                                            />
                                        </FlexRowStart>
                                        <FlexRowEnd gap={4}>
                                            <FlexRowCenter className={styles.txt13_W} style={{borderRadius:'100px', width:'102px', height:'24px', backgroundColor:'#3A71FF'}} gap={4}>
                                                <FlexRowCenter>{t("채팅방")}</FlexRowCenter><FlexRowCenter style={{width:'53px', height:'24px', fontSize:'12px', backgroundColor:'#759CFF', padding:'2px 8px 2px 6px', borderRadius:'100px'}}><img src={require('../assets/imges/profile/eye.png')} width={20} />{friendCnt.myRoomUser}</FlexRowCenter>
                                            </FlexRowCenter>
                                        </FlexRowEnd>
                                    </FlexRowSpace>
                                    <FlexColumn gap={8}>
                                        <FlexColumn gap={3}>
                                            <div className={styles.txt20_B01}>
                                                {userInfo?.doname}
                                            </div>
                                            {userInfo?.introduce && (
                                                <div className={styles.txt14_B05}>
                                                    {userInfo?.introduce}
                                                </div>
                                            )}
                                        </FlexColumn>
                                        <FlexRowStart className={styles.txt14_B04} gap={8}>
                                            <div onClick={()=>{setShowChatRoom(true);}}>{friendCnt.chatRoomCnt} {t("룸")}</div>
                                            <div style={{height:'12px', width:'1px' , backgroundColor:'#E3E5ED'}}/>
                                            <div onClick={()=>{setShowFriend(true); setFriendView('friend');}}>{decimalFormat(friendCnt.friendCnt,0)} {t("프렌더")}</div>
                                            <div style={{height:'12px', width:'1px' , backgroundColor:'#E3E5ED'}}/>
                                            <div onClick={()=>{setShowFriend(true); setFriendView('friending');}}>{decimalFormat(friendCnt.friendingCnt,0)} {t("프렌딩")}</div>
                                        </FlexRowStart>
                                    </FlexColumn>
                                    <GrayBoxW01 style={{ height: "40px" }}>
                                        <FlexRowCenter gap={8}>
                                            <div className={styles.txt13_B04}>{ellipseAddress(walletAddress, 10)}</div>
                                            <div>
                                                <CopyToClipboard
                                                    text={walletAddress}
                                                    onCopy={() => Toast(t("주소를 복사 했습니다."))}
                                                >
                                                    <img src={IconCopy} width={20} alt={"copy"} />
                                                </CopyToClipboard>
                                            </div>
                                        </FlexRowCenter>
                                    </GrayBoxW01>
                                    {
                                        userId != zigleIdx &&
                                        (
                                            <FlexRowCenter gap={8}>
                                                <FlexColumnCenter className={styles.profile_btn_white} style={{fontSize:'16px', width:'50%'}}
                                                     onClick={() => {
                                                         // setPhoneTransShow(true);
                                                         if(userId) {
                                                             if (doname) {
                                                                 navigate("/chat/" + userInfo?.doname);
                                                             } else {
                                                                 PopupService.newPopup(t("닉네임없음")
                                                                     , t("닉네임을 입력하셔야 사용 가능합니다.")
                                                                     , ""
                                                                     , {
                                                                         btnTxt: t("확인"),
                                                                         onClick: () => {
                                                                             navigate("/modifyProfile");
                                                                         }
                                                                     }
                                                                     , {
                                                                         btnTxt: "",
                                                                         onClick: () => {
                                                                         }
                                                                     }
                                                                 );
                                                             }
                                                         }else{
                                                             PopupService.newPopup(t("로그인을 해주세요!")
                                                                 ,t("친구 추가는 로그인이 필요해요!")
                                                                 , t("코인 송금은 로그인 없이 가능해요")
                                                                 ,{btnTxt:t("계정 있어요"),
                                                                     onClick: () => {
                                                                         sessionStorage.setItem("redirectTo",window.location.href);
                                                                         navigate('/walletLogin');
                                                                     }}
                                                                 , {btnTxt:t("지글이 처음이에요!"),
                                                                     onClick: () => {
                                                                         sessionStorage.setItem("redirectTo",window.location.href);
                                                                         navigate('/joinEmail');
                                                                     }}
                                                             );
                                                         }
                                                     }}>
                                                    {t('채팅')}
                                                </FlexColumnCenter>
                                                <FlexColumnCenter className={styles.profile_btn_white} style={{fontSize:'16px', width:'50%', color:isMyFriend ==='N' ? '#FFFFFF' : '#000000', backgroundColor:isMyFriend ==='N' ? '#000000' : '#F2F4F6'}}
                                                     onClick={() => {
                                                         if(userId)
                                                         {
                                                             friendSetting();
                                                         }else{
                                                             PopupService.newPopup(t("로그인을 해주세요!")
                                                                 ,t("친구 추가는 로그인이 필요해요!")
                                                                 , t("코인 송금은 로그인 없이 가능해요")
                                                                 ,{btnTxt:t("계정 있어요"),
                                                                     onClick: () => {
                                                                         sessionStorage.setItem("redirectTo",window.location.href);
                                                                         navigate('/walletLogin');
                                                                     }}
                                                                 , {btnTxt:t("지글이 처음이에요!"),
                                                                     onClick: () => {
                                                                         sessionStorage.setItem("redirectTo",window.location.href);
                                                                         navigate('/joinEmail');
                                                                     }}
                                                             );
                                                         }
                                                     }}>{t(isMyFriend ==='Y' ? "언프렌드" : "프렌드")}
                                                </FlexColumnCenter>
                                            </FlexRowCenter>
                                        )
                                    }
                                    <Bar/>
                                    <FlexColumn style={{paddingTop:'12px'}} gap={16}>
                                        <div className={styles.txt18_500}>{t("도네이션링크")}</div>
                                        <LinkWrapper userId={zigleIdx} blockSize={blockSize} orderBy={'DESC'} limit={false} reLoad={reLoad} searchTxt={searchTxt} onClickLinkItem={onClickLinkItem}/>
                                    </FlexColumn>
                                </FlexColumn>
                            </PageContent>
                        )
                        :
                        ( <PageContent>
                            <FlexColumn>
                                <FlexColumn>
                                    <div className={styles.txt20_B01}>{t('잘못된 링크 입니다.')}</div>
                                    <FlexRowCenter>
                                        <img
                                            src={require("../assets/imges/login/img_01.png")}
                                            width={226}
                                            alt={"imgwallet"}
                                        />
                                    </FlexRowCenter>
                                    <GrayBox>
                                        <div className={styles.txt14_BOLD_B04}>
                                            {t('잘못된 링크 입니다.')}
                                        </div>
                                        <div className={styles.txt13_B05}>
                                            {t('보낸 사람에게 다시 한번 확인하여 주세요')}
                                        </div>
                                    </GrayBox>
                                </FlexColumn>
                            </FlexColumn>
                            </PageContent>)
                }
        </PageRootContainer>
    )
}