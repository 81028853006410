import ChatService from "../../services/chat.service";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import {useInView} from "react-intersection-observer";
import {FlexColumn, FlexRowCenter, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import ChatListItem from "./ChatListItem";
import EmptyContent from "../common/EmptyContent";
import styles from "../../common/css/zigle.module.scss";
import ChatAllListItem from "./ChatAllListItem";
import moment from "moment";
import ChatTime from "./ChatTime";


export default function ChatAllList (props:{pageType:string, talkPlus:any}) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [lastIndex, setLastIndex] = useState(0);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [blockSize] = useState(10);
    const [lastList, setLastList] = useState(false);
    const [chatList, setChatList] = useState<Array<any>>([]);
    const listBool = useRef(false);
    const listCnt = useRef(0);
    const [currPage, setCurrPage] = useState(1);
    const [orderBy, setOrderBy] = useState('DESC');
    const [orderType, setOrderType] = useState('userCnt');
    const [refresh, setRefresh] = useState(false);
    const getAllChannels = async()=>{
        console.log("currPage",currPage)
        ChatService.listChatRoom('', props.pageType, currPage, orderType, orderBy, '').then((data) =>{
            if (!data.data.length) {
                setLastList(true);
                return;
            }
            setChatList([...chatList, ...data.data]);
        });
    }

    useEffect(() => {
        if (inView && hasNextPage && !lastList) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);
    useEffect(()=>{
        if(props.talkPlus) {
            getAllChannels();
        }
    }, [refresh, currPage, props.talkPlus]);


    return (
        <>
            <FlexColumn gap={16}>
                <FlexColumn gap={19}>
                    <FlexColumn gap={4}>
                        <div className={styles.txt14_B04}>{t("일일 보상")}</div>
                        <div className={styles.txt28_600_B02}>100,000 ZIG</div>
                    </FlexColumn>
                   <ChatTime />
                    <div className={styles.line}/>
                    <FlexRowStart>
                        <FlexRowStart className={styles.txt13_500_B02} style={orderType === "userCnt" ? {borderRadius:'100px', width:'87px', padding:'8px 6px 8px 12px', background:'#111111', color:'#ffffff'} : {borderRadius:'100px', width:'87px', padding:'8px 6px 8px 12px'}} onClick={()=>{
                            setOrderType('userCnt');
                            setLastList(false);
                            setChatList([]);
                            listCnt.current = 0;
                            if(orderType === "userCnt"){
                                if(orderBy === "ASC")
                                {
                                    setOrderBy("DESC");
                                }else{
                                    setOrderBy("ASC");
                                }
                            }else{
                                setOrderBy("DESC");
                            }
                            if(currPage ===1 ){
                                setRefresh(!refresh);
                            }else{
                                setCurrPage(1);
                            }
                        }}>
                            <div>{t("참여 많은")}</div>
                            {
                                (orderType === "userCnt") ? (
                                    orderBy === "ASC" ? (
                                        <img src={require('../../assets/imges/chat/orderDown.png')} width={20} />
                                    ) : (
                                        <img src={require('../../assets/imges/chat/orderUp.png')} width={20} />
                                    )
                                ) : (
                                    <img src={require('../../assets/imges/chat/order.png')} width={20} />
                                )
                            }
                        </FlexRowStart>
                        <FlexRowStart className={styles.txt13_500_B02} style={orderType === "regDate" ? {borderRadius:'100px', width:'87px', padding:'8px 6px 8px 12px', background:'#111111', color:'#ffffff'} : {borderRadius:'100px', width:'87px', padding:'8px 6px 8px 12px'}} onClick={()=>{
                            setOrderType('regDate');
                            setLastList(false);
                            setChatList([]);
                            listCnt.current = 0;
                            if(orderType === "regDate"){
                                if(orderBy === "ASC")
                                {
                                    setOrderBy("DESC");
                                }else{
                                    setOrderBy("ASC");
                                }
                            }else{
                                setOrderBy("DESC");
                            }
                            if(currPage ===1 ){
                                setRefresh(!refresh);
                            }else{
                                setCurrPage(1);
                            }
                        }}>
                            {t("최근 만든")}
                            {
                                (orderType === "regDate") ? (
                                    orderBy === "ASC" ? (
                                        <img src={require('../../assets/imges/chat/orderDown.png')} width={20} />
                                    ) : (
                                        <img src={require('../../assets/imges/chat/orderUp.png')} width={20} />
                                    )
                                ) : (
                                    <img src={require('../../assets/imges/chat/order.png')} width={20} />
                                )
                            }
                        </FlexRowStart>
                        <FlexRowStart className={styles.txt13_500_B02} style={orderType === "chatCnt" ? {borderRadius:'100px', width:'87px', padding:'8px 6px 8px 12px', background:'#111111', color:'#ffffff'} : {borderRadius:'100px', width:'87px', padding:'8px 6px 8px 12px'}} onClick={()=>{
                            setOrderType('chatCnt');
                            setLastList(false);
                            setChatList([]);
                            listCnt.current = 0;
                            if(orderType === "chatCnt"){
                                if(orderBy === "ASC")
                                {
                                    setOrderBy("DESC");
                                }else{
                                    setOrderBy("ASC");
                                }
                            }else{
                                setOrderBy("DESC");
                            }
                            if(currPage ===1 ){
                                setRefresh(!refresh);
                            }else{
                                setCurrPage(1);
                            }
                        }}>
                            {t("채팅 많은")}
                            {
                                (orderType === "chatCnt") ? (
                                    orderBy === "ASC" ? (
                                        <img src={require('../../assets/imges/chat/orderDown.png')} width={20} />
                                    ) : (
                                        <img src={require('../../assets/imges/chat/orderUp.png')} width={20} />
                                    )
                                ) : (
                                    <img src={require('../../assets/imges/chat/order.png')} width={20} />
                                )
                            }
                        </FlexRowStart>
                    </FlexRowStart>
                </FlexColumn>
                <FlexColumn>
                {
                    chatList.length > 0 ?
                        (
                            chatList.map((item,index)=>{
                                listBool.current = !listBool.current;
                                listCnt.current = listCnt.current + 1;
                                return (
                                    <ChatAllListItem key={index} listCnt={index + 1} listBool={listBool.current} pageType={props.pageType} talkPlus={props.talkPlus} item={item}/>
                                )
                            })
                        ) : (
                            <EmptyContent titleText={t('등록된 채팅방이 없습니다.')}/>
                        )
                }
                </FlexColumn>
            </FlexColumn>
            {
                Object.values(chatList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
            }
        </>
    )
}