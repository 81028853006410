import React, {useEffect, useState} from "react";
import {
    FlexBottom,
    FlexBottom86, FlexBottomStart,
    FlexColumn, FlexRowAround,
    FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart
} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import CommonService from "../../services/common.service";
import CopyToClipboard from "react-copy-to-clipboard";
import DefaultInput from "../common/input/DefaultInput";
import {Checkbox} from "@material-ui/core";
import BottomButton from "../common/button/BottomButton";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import AssetService from "../../services/asset.service";
import PopupService from "../../services/popup.service";
import FavService from "../../services/fav.service";
import TokenPopup from "../tokenlist/TokenPopup";
import WalletPopup from "./WalletPopup";
import MetaMaskService from "../../services/metamask.service";
import {Toast, ToastError} from "../common/popup/ToastAlert";
import bigDecimal from "js-big-decimal";
import {decimalFormat, ellipseAddress} from "../../common/js/utilities";
import {useAccount, useBalance} from "wagmi";
import {useWalletEthersPorvider} from "../../common/js/ethersProviderHook";
interface ITransferStep {
    transferInfo:any;
    setTransferInfo:any;
    mainnetInfo:any;
    handleOnClick?: (item: string)=> void;
    setDepth:any;
    txIdResult?:(result:any,
                 resultMessage:any,
                 send_token_amount:any,
                 account:any,
                 to_address:any,
                 mainnetId:any,
                 coinId:any,
                 linkMngId:any)=> void;
}

export default function TransferFriend2(props: ITransferStep) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [btnActive, setBtnActive] = useState(false);
    const [service, setService] = useState<boolean>(false);


    
    const chainId = useSelector((state:any) => state.walletReducer.chainId);
    const disconnect = useSelector((state:any) => state.walletReducer.disconnect);
    const { address, isConnected } = useAccount();
    const hookCoinBalance = useBalance({
        address: address,
    });

    const provider = useWalletEthersPorvider();

    const [walletList, setWalletList] = useState<any[]>([]);
    const [coinBalance , setCoinBalance] = useState('0');

    const [showWalletPopup, setShowWalletPopup] = useState(false);
    const [error, setError] = useState(false);

    const ref = React.useRef<HTMLInputElement>(null);
    const walletOnClick = (popWalletAddress:string) =>{
        props.setTransferInfo(
            {...props.transferInfo,
                sendWalletAddress: popWalletAddress
            }
        );
        setShowWalletPopup(false);
        ref.current?.focus();
    }


    const onSubmitClick = () => {
        // if(props.handleOnClick) {
        //     props.handleOnClick('10');
        // }
        if(!address){
            ToastError(t("지갑연결을 확인해 주세요."));
            return false;
        }
        if(chainId != props.mainnetInfo.chainId)
        {
            MetaMaskService.setSwitchNetwork(provider, parseInt(props.mainnetInfo.chainId), props.mainnetInfo.mainnetName, props.mainnetInfo.symbolName, props.mainnetInfo.decimalVal, props.mainnetInfo.rpcUrl, props.mainnetInfo.explorerUrl, '').then(
                (response) => {
                    if(!response.status)
                    {
                    }
                }
            );
        }else {
            props.setDepth(9);
        }
    }
    useEffect(()=>{
        const fetchData = async() =>{

            if(chainId != props.mainnetInfo.chainId)
            {
                MetaMaskService.setSwitchNetwork(provider, parseInt(props.mainnetInfo.chainId), props.mainnetInfo.mainnetName, props.mainnetInfo.symbolName, props.mainnetInfo.decimalVal, props.mainnetInfo.rpcUrl, props.mainnetInfo.explorerUrl, '').then(
                    (response) => {
                        if(!response.status)
                        {
                        }
                    }
                );
            }else {
                let balance:string = '0';
                if(props.transferInfo.coinTypeCode ==='TOKEN')
                {
                    MetaMaskService.getBalance(address, props.transferInfo.coinContAddr)
                        .then((res) => {

                            balance = res ? res : '0';
                            if(props.transferInfo.linkType==='SP'){
                                if(balance <= props.transferInfo.amount){
                                    setError(true);
                                    setBtnActive(false);
                                }
                                setService(true);
                            }
                            setCoinBalance(balance);
                        });
                }else{
                    balance = hookCoinBalance.data?.formatted ? hookCoinBalance.data?.formatted : '0';
                    if(props.transferInfo.linkType==='SP'){
                        if(balance <= props.transferInfo.amount){
                            setError(true);
                            setBtnActive(false);
                        }
                        setService(true);
                    }
                    setCoinBalance(balance);
                }
            }
        }
        if(props.transferInfo.sendUserId && address && provider)
        {
            fetchData();
        }
        ref.current?.focus();

    },[props.transferInfo.sendUserId, address, provider]);
    useEffect(()=>{
        if(!service){
            setBtnActive(false);
        }else{
            if(ref.current)
            {
                ref.current.value='';
            }
            setBtnActive(true);
        }
    },[service])
    return (
        <FlexColumn>
            <WalletPopup setShow={setShowWalletPopup} show={showWalletPopup} walletList={walletList} walletOnClick={walletOnClick}/>

            <FlexColumn gap={64}>
                  <FlexRowCenter>
                      <FlexRowAround className={styles.wallet_logo_area}>
                          <div className={styles.txt13_B04}>{ellipseAddress(props.transferInfo.sendWalletAddress, 8)}</div>
                          {
                              walletList.length > 0 && (<div  onClick={()=>{ setShowWalletPopup(true); }}><img src={require('../../assets/icons/arrow_select.png')} width={20}/></div>)
                          }
                          <div>
                              <CopyToClipboard text={props.transferInfo.sendWalletAddress} onCopy={() => Toast(t("주소를 복사 했습니다."))}>
                                  <img src={require("../../assets/icons/copy.png")} height={20} alt={"copy"} />
                              </CopyToClipboard>
                          </div>
                      </FlexRowAround>
                  </FlexRowCenter>
                  <FlexColumn gap={8}>
                      <FlexRowCenter>
                          <div style={{width:'150px'}}>
                              <DefaultInput
                                  style={{width:'100px'}}
                                  inputElement={
                                      <input
                                          ref={ref}
                                          type={"number"}
                                          className={styles.inputText_33}
                                          disabled={service}
                                          placeholder={t("받을 수량")}
                                          value={props.transferInfo.amount}
                                          onChange ={ (el) => {
                                              if((new bigDecimal(coinBalance).compareTo(new bigDecimal(el.target.value)) > 0) && (new bigDecimal(el.target.value).compareTo(new bigDecimal(0)) > 0)){
                                                  setBtnActive(true);
                                                  setError(false);
                                              }else{
                                                  if(new bigDecimal(el.target.value).compareTo(new bigDecimal(0)) > 0) {
                                                      setError(true);
                                                  }
                                                  setBtnActive(false);
                                              }
                                              props.setTransferInfo(
                                                  {...props.transferInfo,
                                                      amount: el.target.value
                                                  }
                                              );
                                          }}
                                      />
                                  }
                              />
                          </div>
                          <div style={{marginBottom:'24px', color: '#dbdee6'}}>{props.transferInfo.coinSymbolName}</div>
                      </FlexRowCenter>
                      {
                          error ? (
                              <>
                                  <FlexRowCenter className={styles.txt13_B05} style={{color:'#f01d1d'}}>
                                      {t("출금 가능 잔액이 부족해요.")}
                                  </FlexRowCenter>
                                  {
                                      !service ?
                                          (
                                              <FlexRowCenter>
                                                  <div className={styles.txt13_B04} style={{borderRadius:'8px', textAlign:'center', color:'#f01d1d', padding:'10px 16px', backgroundColor:'#fff0f0'}}
                                                       onClick={()=>{
                                                           props.setTransferInfo(
                                                               {...props.transferInfo,
                                                                   amount: decimalFormat(coinBalance)
                                                               }
                                                           );
                                                           setBtnActive(true);
                                                           setError(false);
                                                       }}>
                                                      {t("{0} {1} 로 변경", {0:decimalFormat(coinBalance), 1:props.transferInfo.coinSymbolName})}
                                                  </div>
                                              </FlexRowCenter>
                                          )
                                          :
                                          (
                                              <FlexRowCenter>
                                                  <div className={styles.txt13_B04} style={{borderRadius:'8px',  textAlign:'center', padding:'10px 16px', backgroundColor:'#f2f4f6'}}>
                                                      {t("보유 수량")} {decimalFormat(coinBalance)} {props.transferInfo.coinSymbolName}
                                                  </div>
                                              </FlexRowCenter>
                                          )

                                  }
                                  </>
                          ) :
                          (
                              <FlexRowCenter>
                                  <div className={styles.txt13_B04} style={{borderRadius:'8px',  textAlign:'center', padding:'10px 16px', backgroundColor:'#f2f4f6'}}>
                                      {t("보유 수량")} {decimalFormat(coinBalance)} {props.transferInfo.coinSymbolName}
                                  </div>
                              </FlexRowCenter>
                          )
                      }
                  </FlexColumn>
              </FlexColumn>
            {
                props.transferInfo.linkType==='CO' || props.transferInfo.linkType==='SP' ?
                    (
                        props.transferInfo.linkType==='CO' ?
                            (
                                <FlexBottomStart style={{top:'calc(100% - (189px + env(safe-area-inset-bottom)))'}}>
                                    <FlexColumn gap={4} style={{ padding:'20px',  backgroundColor:'#f9fafb'}} >
                                        <div className={styles.txt14_BOLD_B04}>{t("수량이 정해지지 않은 링크에요")}</div>
                                        <div className={styles.txt13_B05}>{t("받는 사람이 수량을 정하지 않아 보내는 사람이 자유롭게 입력 후 송금하시면 되는 링크에요.")}</div>
                                    </FlexColumn>
                                </FlexBottomStart>
                            )
                            :
                            (
                                <FlexBottomStart style={{top:'calc(100% - (189px  + env(safe-area-inset-bottom)))'}}>
                                    <FlexColumn gap={4} style={{ padding:'20px',  backgroundColor:'#f9fafb'}} >
                                        <div className={styles.txt14_BOLD_B04}>{t("수량이 정해진 링크에요")}</div>
                                        <div className={styles.txt13_B05}>{t("상대방이 받을 수량을 정해 두었어요 수량을 다르게 보내고 싶다면 상대방에게 링크를 다시 요청해 주세요.")}</div>
                                    </FlexColumn>
                                </FlexBottomStart>
                            )
                    )
                    :
                    null
            }

            <FlexBottomStart >
                <FlexColumn>
                    <FlexRowSpace className={styles.txt13_B05} style={{height:'40px', padding:'12px, 20px'}}>
                        <div style={{paddingLeft:'20px'}}>{t("수수료")}</div>
                        <div style={{paddingRight:'20px'}}>{props.transferInfo.zigleFee === 0 ? t("무료") : props.transferInfo.zigleFee}</div>
                    </FlexRowSpace>
                    <FlexColumn>
                        <BottomButton
                            isActive={btnActive}
                            backgroundColor={"#3a71ff"}
                            textColor={"white"}
                            // border={"solid 1px #dbdee6"}
                            onClick={()=>onSubmitClick()}
                            text={t("송금하기")}
                        />
                    </FlexColumn>
                </FlexColumn>
            </FlexBottomStart>
        </FlexColumn>
    );
}