import React, {useRef, useState} from "react";
import {useForm} from "react-hook-form";
import styles from "../../common/css/zigle.module.scss";
import {FlexBottom, FlexColumn, FlexRowSpace} from "../common/container/PageContainer";
import PopupService from "../../services/popup.service";
import CopyToClipboard from "react-copy-to-clipboard";
import BottomButton from "../common/button/BottomButton";
import {useSelector} from "react-redux";
import {Toast} from "../common/popup/ToastAlert";
import {Trans, useTranslation} from "react-i18next";


interface IPayDepthProps {
    payInfo:any;
    setPayInfo:any;
    reset?: ()=> void;
}
export default function PayDepthEnd({payInfo, setPayInfo, reset}:IPayDepthProps) {
    const { t } = useTranslation();

    const ziglePoint = useSelector((state: any) => state.userReduce.ziglePoint);

    return (
        <FlexColumn gap={28}>
            <FlexColumn gap={2} className={styles.txt20_B01}>
                <Trans t={t} className={styles.airdropFiEnd} i18nKey={"포인트 충전 금액을<br/><span>입금 계좌에 입금</span>해 주세요."} />
            </FlexColumn>
            <FlexColumn>
                <div className={styles.txt14_B04}>{t("입금계좌번호")}</div>
                <FlexRowSpace>
                    <div  className={styles.txt13_B01} style={{width:'80%',padding:'10px 0px', borderBottom: 'solid 1px #f2f4f8'}}>
                        <span className={styles.txt12_500_PointB}>{payInfo.bankInfo.accountNum}</span> / {payInfo.bankInfo.bankOwner} / {payInfo.bankInfo.bankName}
                    </div>
                    <div style={{width:'20%'}}>
                        <CopyToClipboard text={payInfo.bankInfo.accountNum}
                                         onCopy={() => Toast(t("복사 하였습니다."))}>
                            <span className={styles.copy}>{t("복사")}</span>
                        </CopyToClipboard>
                    </div>
                </FlexRowSpace>
            </FlexColumn>
            <FlexColumn>
                <div className={styles.txt14_B04}>{t("입금자명+식별번호")}</div>
                <FlexRowSpace>
                    <div className={styles.txt16_B01} style={{width:'80%', padding:'10px 0px', borderBottom: 'solid 1px #f2f4f8'}}>
                        {payInfo.bankInfo.userName}{payInfo.bankInfo.checkNum}
                    </div>
                    <div style={{width:'20%'}}>
                        <CopyToClipboard text={payInfo.bankInfo.userName+ payInfo.bankInfo.checkNum}
                                         onCopy={() => Toast(t("복사 하였습니다."))}>
                            <span className={styles.copy}>{t("복사")}</span>
                        </CopyToClipboard>
                    </div>
                </FlexRowSpace>
            </FlexColumn>
            <FlexColumn style={{backgroundColor:'#f2f4f8', height:'16px'}}></FlexColumn>
            <FlexColumn className={styles.txt14_B03} gap={16}>
                <FlexRowSpace>
                    <div>{t("충전 전")}</div>
                    <div>{ziglePoint} P</div>
                </FlexRowSpace>
                <FlexRowSpace>
                    <div>{t("충전 예정")}</div>
                    <div>{payInfo.ziglePoint} P</div>
                </FlexRowSpace>
            </FlexColumn>
            <div className={styles.line}></div>
            <FlexRowSpace>
                <div className={styles.txt16_500_B01}>{t("충전 후")}</div>
                <div className={styles.txt16_500_PointB}>{ziglePoint + payInfo.ziglePoint} P</div>
            </FlexRowSpace>
            <FlexColumn className={styles.txt12_B05} style={{padding:'20px', backgroundColor:'#f2f4f6'}}>
                <Trans t={t} i18nKey={"입금시 입금자명은 반드시 [입금자명+식별번호]로 입금해 주세요.<br />요청 금액과 동일한 금액으로 입금하셔야 포인트가 충전 됩니다.<br />24시간 내에 입금해 주셔야 유효합니다.<br />"} />
            </FlexColumn>
            <FlexBottom>
                <BottomButton
                    isActive={true}
                    backgroundColor={"#f2f4f6"}
                    textColor={"#3a71ff"}
                    text={t("추가 충전하기")}
                    onClick={() => {
                        if(reset){
                            reset();
                        }
                    }}
                />
                <BottomButton
                    isActive={true}
                    backgroundColor={"#3a71ff"}
                    textColor={"#ffffff"}
                    text={t("내역 바로가기")}
                    onClick={() => {

                    }}
                />
            </FlexBottom>
        </FlexColumn>
    )
}