import React, {ReactHTMLElement, useEffect, useRef, useState} from "react";
import PageTitle, {
    FlexBottom, FlexBottom86,
    FlexColumn, FlexColumnCenter, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper, ZSelect
} from "../common/container/PageContainer";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import MainnetList from "../common/MainnetList";
import CoinList from "../common/CoinList";
import TransferFriend2 from "../transferCoin/TransferFriend2";
import TransferPhone2 from "../transferCoin/TransferPhone2";
import TransferEnd from "../transferCoin/TransferEnd";
import CommonService from "../../services/common.service";
import EmptyContent from "../common/EmptyContent";
import {useInView} from "react-intersection-observer";
import {useForm} from "react-hook-form";
import FavService from "../../services/fav.service";
import PopupService from "../../services/popup.service";
import {Toast} from "../common/popup/ToastAlert";
import {maskingEmail} from "../../common/js/utilities";
import {useTranslation} from "react-i18next";

interface IPhoneTrans {
    show: boolean;
    setShow: any;
    handleOnClick?: (item: string)=> void;
    handleOnRightClick?: (item: string)=> void;
}
function DonamePopup(props:IPhoneTrans) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [searchTxt, setSearchTxt] = useState('');
    const [friendList, setFriendList] = useState<any[]>([]);
    const [currPage, setCurrPage] = useState(1);
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [blockSize] = useState(10);
    const [reSet, setReSet] = useState(false);
    const [trandList, setTrandList] = useState<any[]>([]);
    const {
        register,
        resetField,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const fetchData = async(searchTxt: any) =>{
        const response = await CommonService.donamelist(searchTxt, currPage, blockSize, '');
        if (!response.data.length) {
            setLastList(true);
            return;
        }
        setFriendList([...friendList, ...response.data]);
    }
    useEffect(() => {

        if (!lastList && props.show && searchTxt) {

            fetchData(searchTxt);
        }
    },[currPage, searchTxt, props.show, reSet]);

    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);
    useEffect(()=>{
        if(props.show){
            CommonService.donamelist(searchTxt, currPage, blockSize, 'tranding').then(res=>{
                console.log(res.data);
                setTrandList(res.data);
            });
        }
    },[props.show])
    return (
        <>
            {
                props.show &&
                (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageContent style={{paddingTop:'10px'}}>
                                    <FlexColumn>
                                        <FlexRowStart>
                                            <img
                                                src={require("../../assets/imges/back_arrow.png")}
                                                width={28}
                                                alt={"back_arrow"}
                                                style={{ marginLeft: "-8px" }}
                                                onClick={() => {
                                                    if(props.handleOnRightClick)
                                                    {
                                                        resetField("searchTxt");
                                                        setSearchTxt('');
                                                        setFriendList([]);
                                                        setLastList(false);
                                                        props.handleOnRightClick('');

                                                    }else{
                                                        resetField("searchTxt");
                                                        setFriendList([]);
                                                        setSearchTxt('');
                                                        setLastList(false);
                                                        setCurrPage(1);
                                                        props.setShow(false);
                                                    }
                                                }}
                                            />

                                            <div style={{width:'100%'}}><input
                                                type={"text"}
                                                className={styles.inputText_44_noborder}
                                                placeholder={t('닉네임을 입력해 주세요')}
                                                value={searchTxt}
                                                {...register("searchTxt", {
                                                    required: t('닉네임을 입력해 주세요'),
                                                    onChange: (el) => {
                                                        setCurrPage(1);
                                                        setFriendList([]);
                                                        setLastList(false);
                                                        setSearchTxt(el.target.value);
                                                    }
                                                })}
                                            /></div>
                                        </FlexRowStart>
                                        <div className={styles.line} style={{width: 'calc(100% + 40px)', marginLeft:'-20px'}}/>
                                    </FlexColumn>
                                    <FlexColumn style={{marginTop:'28px'}}>
                                        {
                                            searchTxt ? (
                                                friendList.length > 0 ? (
                                                        friendList.map((item, index) => {
                                                            return (
                                                                <FlexColumn key={item.userId} style={{height:'78px'}}>
                                                                    <FlexRowSpace>
                                                                        <FlexRowStart  onClick={()=>{navigate("/u/"+item.doname, {
                                                                            state: {
                                                                                back:true
                                                                            }})}}>
                                                                            <div style={{paddingRight:'12px'}}>
                                                                                <img src={item.profileImageFileId ? item.profileImageFileId : 'https://s3.ap-northeast-2.amazonaws.com/bucket.zigle.io/profiles/profile'+(item.userId % 9)+'.png' } alt="bgIcon" style={{width:'38px', height:'38px', borderRadius:'15px', aspectRatio:'1', objectFit:'cover'}} />
                                                                            </div>
                                                                            <FlexColumn>
                                                                                <div className={ styles.txt16_B02}>{item.doname}</div>
                                                                                <div className={styles.txt14_B05}>{t("{0}명 프렌딩", {0:item.favTotalCnt})}</div>
                                                                            </FlexColumn>
                                                                        </FlexRowStart>
                                                                        <FlexRowEnd gap={4}>
                                                                            {
                                                                                item.isMyFriend === "N" ? (
                                                                                    <FlexColumnCenter className={styles.txt14_500_PointB} style={{ width:'75px', height:'34px', background:'#3A71FF', color:'#FFFFFF', borderRadius:'8px' }} onClick={()=> {
                                                                                        PopupService.newPopup(t("프렌드하기")
                                                                                            ,t("'{0}' 님을 프렌드 할까요?", {0:item.doname})
                                                                                            , ""
                                                                                            ,{btnTxt:"확인",
                                                                                                onClick: () => {
                                                                                                    FavService.friendInsert(item.userId).then((response) => {
                                                                                                        Toast(t('친구등록이 완료 되었습니다.'));
                                                                                                        setFriendList(friendList.map(ztem=>ztem.userId === item.userId ? { ...ztem, isMyFriend:'Y' } : ztem))
                                                                                                    });
                                                                                                }}
                                                                                            , {
                                                                                                btnTxt:t("취소"),
                                                                                                onClick: () => {}
                                                                                            }
                                                                                        );
                                                                                    }}>{t("프렌드")}</FlexColumnCenter>
                                                                                ) : (
                                                                                    <FlexColumnCenter className={styles.txt14_500_PointB} style={{width:'75px', height:'34px',  background:'#EBF2FF',  borderRadius:'8px' }} onClick={()=>{

                                                                                        PopupService.newPopup(t("언프렌드")
                                                                                            ,t("'{0}' 님을 언프렌드 할까요?", {0:item.doname})
                                                                                            , ""
                                                                                            ,{btnTxt:t("확인"),
                                                                                                onClick: () => {
                                                                                                    FavService.friendDelete(item.userId).then((response) => {
                                                                                                        setFriendList(friendList.map(ztem=>ztem.userId === item.userId ? { ...ztem, isMyFriend:'N' } : ztem))
                                                                                                        Toast(t('친구취소가 완료 되었습니다.'));
                                                                                                    });
                                                                                                }}
                                                                                            , {
                                                                                                btnTxt:"",
                                                                                                onClick: () => {}
                                                                                            }
                                                                                        );
                                                                                    }} >{t('언프렌드')}</FlexColumnCenter>
                                                                                )
                                                                            }
                                                                            <img src={require('../../assets/imges/chat/btn_chat.png')} width={34} onClick={()=>{navigate("/chat/"+item.doname)}}/>
                                                                        </FlexRowEnd>
                                                                    </FlexRowSpace>
                                                                </FlexColumn>
                                                            )
                                                        })
                                                    )
                                                    :
                                                    (
                                                        <EmptyContent titleText={t('검색 결과가 없어요')} titleImage={require('../../assets/imges/list_x.png')}/>
                                                    )
                                                ) : (
                                                    <>
                                                    <FlexColumn className={styles.txt15_B01} style={{marginBottom:'8px'}}>{t("트랜딩 프렌드")}</FlexColumn>
                                                        <FlexRowStart>
                                                            {
                                                                trandList.length > 0 && (
                                                                    trandList.map((item, index) => {
                                                                        return (
                                                                            <div className={styles.txt12_B04} style={{padding:'8px 14px', borderRadius:'100px', background:'#F2F4F6'}} onClick={()=>{setSearchTxt(item.doname)}}>{item.doname}</div>
                                                                        )}
                                                                    )
                                                                )
                                                            }
                                                            <div></div>
                                                        </FlexRowStart>
                                                    </>
                                            )

                                        }
                                    </FlexColumn>
                                    {
                                        Object.values(friendList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
                                    }
                                </PageContent>

                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>
    )
}
export default DonamePopup;