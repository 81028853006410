import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import styles from "../common/css/zigle.module.scss";
import styled from "styled-components";
import PageTitle, {
    FlexBottom,
    FlexColumn, FlexColumnCenter, FlexRowEnd, FlexRowSpace, FlexRowStart,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import {useTranslation} from "react-i18next";
import NaviBar from "../components/home/NaviBar";
import FriendingTab from "../components/Friend/FriendingTab";
import FriendingTab2 from "../components/Friend/FriendingTab2";
import ChatList from "../components/chat/ChatList";
import {ToastError} from "../components/common/popup/ToastAlert";
import {useSelector} from "react-redux";
import ChatAllList from "../components/chat/ChatAllList";
import ChatSearchPopup from "../components/chat/ChatSearchPopup";


export interface IChatView {
    talkPlus : any;
}

export default function Chat({talkPlus}:IChatView){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [tabFirst, setTabFirst] = useState('0');
    const [show, setShow] = useState(false);

    useEffect(()=>{
        console.log("localStorage.getItem()",localStorage.getItem("chatTab"))
        if(localStorage.getItem("chatTab")){
            setTabFirst(localStorage.getItem("chatTab") ?? '0');
        }
    },[localStorage.getItem("chatTab")])

    return (
        <PageRootContainer>
            <ChatSearchPopup setShow={setShow} show={show} talkPlus={talkPlus}/>
            <PageContent  style={{marginBottom:'80px', paddingTop:'30px' }}>
                <FlexRowSpace style={{height:'56px'}}>
                    <FlexRowStart gap={12} style={{width:'90%'}}>
                        <div className={ tabFirst === '0' ? styles.txt22_600_B01 : styles.txt22_600_W03} onClick={()=>{ localStorage.setItem("chatTab", '0'); setTabFirst('0')}}>{t("참여중")}</div>
                        <div className={ tabFirst === '1' ? styles.txt22_600_B01 : styles.txt22_600_W03} onClick={()=>{ localStorage.setItem("chatTab", '1'); setTabFirst('1')}}>{t("채팅목록")}</div>
                    </FlexRowStart>
                    <FlexRowEnd style={{width:'10%'}}>
                        <img src={require('../assets/imges/search_icon.png')} style={{width:'28px'}} onClick={()=>{setShow(true)}}/>
                    </FlexRowEnd>
                </FlexRowSpace>
                { tabFirst === '0' && (<ChatList pageType={"my"} talkPlus={talkPlus}/>)}
                { tabFirst === '1' && (<ChatAllList pageType={"list"} talkPlus={talkPlus}/>)}
            </PageContent>
            <NaviBar pageType={"chat"}/>
        </PageRootContainer>
    )
}