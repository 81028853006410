import React from "react";
import styles from "./Popup.module.css";
import CloseBtn from "../../../assets/icons/X.svg";
import { IPopAction } from "../../../services/popup.service";
import {useTranslation} from "react-i18next";

interface IPopConfirm {
  title: string | null;
  onClose: () => void;
  fnClose?: () => void | null;
  message: string | JSX.Element;
  ok: IPopAction | null;
  cancel: IPopAction | null;
}

function PopConfirm(props: IPopConfirm) {
    const { t } = useTranslation();
  let isStr = false;
  if (typeof props.message === "string") {
    isStr = true;
  }

  return (
    <div className={styles.confirmWrap}>
      <div className={styles.popupTitleBox}>
        <div className={styles.popupTitle}>
          {props.title ? props.title : t("확인")}
        </div>
        <div className={styles.closeBtn}>
          <img
            onClick={() => {
              if (props.fnClose) {
                props.fnClose();
              }
              props.onClose();
            }}
            src={CloseBtn}
            alt="close"
          />
        </div>
      </div>
      <div className={styles.popupLine} />
      {isStr && (
        <div
          className={styles.popupBody}
          dangerouslySetInnerHTML={{ __html: props.message.toString() }}
        />
      )}
      {!isStr && <div className={styles.popupBody}>{props.message}</div>}

      <div className={styles.confirmBtnWrap}>
        <div className={styles.ConfirmBtnCancel}>
          <a
            onClick={() => {
              props.onClose();
              if (props.cancel?.onClick) {
                props.cancel.onClick();
              }
            }}
          >
            {props.cancel?.btnTxt ? props.cancel.btnTxt : t("취소")}
          </a>
        </div>

        <div className={styles.ConfirmBtnOk}>
          <a
            onClick={() => {
              props.onClose();
              if (props.ok?.onClick) {
                props.ok.onClick();
              }
            }}
          >
            {props.ok?.btnTxt ? props.ok.btnTxt : t("확인")}
          </a>
        </div>
      </div>
    </div>
  );
}

export default PopConfirm;
