import React, {CSSProperties, useEffect, useState} from "react";
import BottomLayer, {FlexBottomButton} from "../container/BottomLayer";
import BottomButton from "../button/BottomButton";
import {FlexColumn, FlexRowStart} from "../container/PageContainer";
import {inspect} from "util";
import styles from "../../../common/css/zigle.module.scss";
import {useTranslation} from "react-i18next";

interface IFriendDetail {
    show: boolean;
    setShow: any;
}
function FriendDetail(props:IFriendDetail) {
    const { t } = useTranslation();
    return (
        <BottomLayer show={props.show}
                     setShow={props.setShow}
                     content={
                         (
                             <>
                                 <FlexRowStart gap={8}>
                                     <img src={require('../../../assets/imges/setting/profile img_list.png')} width={54} height={54} alt={"profile"}/>
                                     <FlexColumn>
                                         <div className={styles.txt20_B01}>홍길동</div>
                                         <div className={styles.txt14_300_B01}>010-2557-5432</div>
                                     </FlexColumn>
                                 </FlexRowStart>
                                 <FlexBottomButton>
                                     <BottomButton text={t("연락처편집")} textColor={"#3a71ff"} backgroundColor={"#f2f4f6"} onClick={() => {
                                     }} isActive={true}/>
                                     <BottomButton text={t("코인송금")} textColor={"#ffffff"} backgroundColor={"#3a71ff"} onClick={() => {
                                     }} isActive={true}/>
                                 </FlexBottomButton>
                             </>
                         )
                     }/>
    );
}

export default FriendDetail;
