import React, { useEffect, useState } from "react";
import styles from "../common/css/zigle.module.scss";
import PageTitle, {
  FlexBottom, FlexBottom79,
  FlexColumn,
  FlexRowCenter, FlexRowEnd, FlexRowSpace, FlexRowStart,
  PageContent,
  PageRootContainer,
} from "../components/common/container/PageContainer";
import { useNavigate } from "react-router-dom";
import BottomButton from "../components/common/button/BottomButton";
import {useDispatch, useSelector} from "react-redux";
import CommonService from "../services/common.service";
import ReactPlaceholder from "react-placeholder";
import { GoogleLogin } from "react-google-login";
import AuthService from "../services/auth.service";
import {useAccount, useDisconnect} from "wagmi";
import {ellipseAddress, maskingEmail} from "../common/js/utilities";
import {Trans, useTranslation} from "react-i18next";
import IconGoogle from "../assets/imges/login/iconGoogle.png";
import {DISCONNECT} from "../components/reducer/walletReducer";

const googleClientId = process.env.REACT_APP_GOOGLE_AUTH_KEY ?? "";

export default function LoginGoogle() {
  const [btnActive, setBtnActive] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { address, isConnected } = useAccount();
  const [email, setEmail] = useState('');
  const { disconnect } = useDisconnect();
  const dispatch = useDispatch();

  const onSuccessGoogle = async (response: any) => {
    console.info("onSuccessGoogle ::", response);
    if(address)
    {
      AuthService.oauthLogin(
          "google",
          response.profileObj.email,
          response.profileObj.googleId,
          response.tokenId,
          address
      );
    }
  };

  const onFailure = (error: any) => {
    console.log("onFailure", error);
  };

  useEffect(() => {
    //지갑여부 확인
    if (address) {
      setBtnActive(true);
      AuthService.checkJoinByAddress(address).then((walletLogin) => {
        if (walletLogin === null || walletLogin === undefined) {
          //회원계정과 연결 안되었을 경우.
          navigate("/noJoinInfo");
          return;
        }
        let socialCode = walletLogin.socialCode;
        setEmail(maskingEmail(walletLogin.email));
        //카카오 계정과 연결 되었을 경우
        if (socialCode === "kakao") {
          navigate("/loginKakao");
        } else if (socialCode === "google") {
          //구글 계정과 연결 되었을 경우
        } else {
          //이메일 계정과 연결 되었을 경우.
          navigate("/loginEmail");
        }
      });
    }
  }, [address]);

  return (
    <PageRootContainer>
      <PageTitle
        onClickLeft={() => {
          dispatch({type: DISCONNECT});
          disconnect();
          navigate(-2);
        }}
        textTitle={""}
      />

      <PageContent>
        <FlexColumn>
          <FlexColumn gap={68}>
            <ReactPlaceholder
              ready={btnActive}
              showLoadingAnimation={true}
              type={"text"}
              rows={3}
            >
              <div className={styles.txt20_B01}>
                <Trans t={t} i18nKey={'{0}는<br />구글 계정으로<br />지글에 가입되어 있네요!'} values={{0:ellipseAddress(address, 5)}} />
              </div>
            </ReactPlaceholder>
            <FlexRowCenter>
              <img
                src={require("../assets/imges/login/googleLogin.png")}
                width={226}
                alt={"googleLogin"}
              />
            </FlexRowCenter>
          </FlexColumn>

          <FlexBottom79>
            <FlexColumn gap={10}>
              <FlexRowSpace className={styles.txt13_300_B05} style={{padding:'0px 20px'}}>
                <FlexRowStart>{t("가입 된 계정")}</FlexRowStart>
                <FlexRowEnd gap={3}><img src={IconGoogle} width={20} />{email}</FlexRowEnd>
              </FlexRowSpace>
              <GoogleLogin
                clientId={googleClientId}
                responseType={"id_token"}
                render={(renderProps) => (
                  <BottomButton
                    isActive={btnActive}
                    backgroundColor={"#ffffff"}
                    textColor={"#111111"}
                    border={"solid 1px #dbdee6"}
                    text={t("구글로 로그인하기")}
                    onClick={renderProps.onClick}
                  />
                )}
                onSuccess={onSuccessGoogle}
                onFailure={onFailure}
              />
            </FlexColumn>
          </FlexBottom79>
        </FlexColumn>
      </PageContent>
    </PageRootContainer>
  );
}
