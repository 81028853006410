import React, { useEffect, useState } from "react";
import styles from "../../common/css/zigle.module.scss";
import {FlexColumn} from "../common/container/PageContainer";

import GuideImg1 from "../../assets/imges/metamask/metamask_guide4_1.png";
import {Trans, useTranslation} from "react-i18next";

export default function Guide4() {
    const { t } = useTranslation();
    return (
        <FlexColumn gap={24}>
            <FlexColumn gap={8}>
                <div className={styles.txt18_500}>Secret backup phrase</div>
                <div className={styles.txt14_B05} style={{lineHeight:'1.71'}}>
                    <Trans t={t} i18nKey={"가장 중요한 단계입니다.<br/>시드문구에 표시된 영문단어를 순서대로<br/>종이 등에 정확하게 필기해서 안전하게 보관하세요.<br/>시드문구는 지갑을 복구 할 수 있는 유일한 방법입니다.<br/>완료되면 다음 버튼을 클릭합니다."}/>
                </div>
                <div><img src={GuideImg1} alt="Guide Img" style={{width:'100%'}}/></div>
            </FlexColumn>
        </FlexColumn>
    )
}