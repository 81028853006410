import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import PageTitle, {
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import React, {useEffect, useRef, useState} from "react";
import AirdropDeStep2 from "../components/airdrop/AirdropDeStep2";
import {IAirdropSet} from "./AirdropFi";
import CommonService from "../services/common.service";
import MetaMaskService from "../services/metamask.service";
import PopupService from "../services/popup.service";
import {ICoinInfo} from "../common/dto/CoinInfoDto";
import {IMainnetInfo} from "../common/dto/MainnetInfo";
import CoinPopup from "../components/tokenlist/CoinPopup";
import AirdropDeStep1 from "../components/airdrop/AirdropDeStep1";
import AirdropDeStep3 from "../components/airdrop/AirdropDeStep3";
import CoinSelect from "../components/common/CoinSelect";
import AirdropDeStep5 from "../components/airdrop/AirdropDeStep5";
import AirdropDeStep6 from "../components/airdrop/AirdropDeStep6";
import {ethers} from "ethers";
import AirdropService from "../services/airdrop.service";
import AirdropFiEnd from "../components/airdrop/AirdropFiEnd";
import {useAccount} from "wagmi";
import {useWalletEthersPorvider} from "../common/js/ethersProviderHook";
import CoinWrite from "../components/tokenlist/CoinWrite";
import AirdropFee from "../components/airdrop/AirdropFee";
import {useTranslation} from "react-i18next";

export interface IAirdropDe {

}
export default function AirdropDe() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [home, setHome] = useState(true);
    const [depth, setDepth] = useState(0);
    const [showCoinPopup, setShowCoinPopup] = useState(false);
    const zigleWalletAddress = process.env.REACT_APP_WALLET_ADDRESS;

    const [mainnetList, setMainnetList] = useState<any[]>([]);
    const [mainnetItem, setMainnetItem] = useState<IMainnetInfo>();
    const { address, isConnected } = useAccount();
    const [pageMode, setPageMode] = useState('list');
    const [airdropFeeShow, setAirdropFeeShow] = useState(false);

    const mainNetDetail = useSelector((state: any) => state.walletReducer.mainNetDetail);

    const provider = useWalletEthersPorvider();

    const [airdropInfo, setAirdropInfo] = useState<IAirdropSet>({
        pageTitle:t('지정 인원 에어드랍'),
        airdropType:'airdropDe',
        airdropSubType:'wallet',
        airdropName:'',
        mainnetId : '',
        mainnetLinkName: '',
        walletAddr : '',
        receiveAddr : zigleWalletAddress ? zigleWalletAddress : '',
        txId : '',
        receiveAmount : 0,
        ziglePoint:0,
        airdropCnt:0,
        failCnt:0,
        airdropUrl:'',
        amount:0,
        feeAverage:0,
        displayYn:'N',
        airdropOpenYn:'N',
        foundationName:'',
        homepageUrl:'',
        informationContents:'',
        airdropStartDay:'',
        airdropStartTime:'',
        airDropList:[{
            usrId:'',
            delYn:'',
            checkYn:''
        }],
        tmpAirDropList:[],
        coinInfo: {
            btopFlag:false,
            coinId: '',
            coinName: '',
            contAddr: '',
            displayName:'',
            decimalVal: '',
            description: '',
            linkName: '',
            mainnetId: '',
            multiCode: '',
            priority: '',
            statusCode: '',
            symbolImgFileId: '',
            symbolName: '',
            typeCode: '',
            useYn: '',
            coingekoId: '',
            exchangeRate: '',
        },
        mainnetInfo:{
            btopFlag:false,
            chainId: '',
            coinDtoList: '',
            decimalVal: '',
            description: '',
            displayName:'',
            explorerUrl: '',
            linkName: '',
            mainnetId: '',
            mainnetName: '',
            phoneUse: '',
            priority: 0,
            rpcUrl: '',
            statusCode: '',
            symbolImgFileId: '',
            symbolName: '',
            zigleFee: 0,
        }
    });
    const formData = useRef({
        airdropType: '',
        airdropName: '',
        airdropSubType: '',
        mainnetId: '',
        coinId: '',
        walletAddr: '',
        receiveAddr: '',
        txId: '',
        receiveAmount: '',
        ziglePoint: 0,
        airdropCnt: 0,
        amount:  0,
        feeAverage: 0,
        displayYn: '',
        airdropOpenYn: '',
        tmpUser: [{

        }],
    });


    const txIdResult = (result:any,
                        resultMessage:any,
                        send_token_amount:any,
                        account:any,
                        to_address:any,
                        mainnetId:any,
                        coinId:any,
                        linkMngId:any)=> {
        if (result == "success") {
            formData.current = {
                airdropType: airdropInfo.airdropType,
                airdropName: airdropInfo.airdropName,
                airdropSubType: airdropInfo.airdropSubType,
                mainnetId: airdropInfo.mainnetId,
                coinId: airdropInfo.coinInfo.coinId,
                walletAddr:airdropInfo.walletAddr,
                receiveAddr: airdropInfo.receiveAddr,
                txId: resultMessage,
                receiveAmount: send_token_amount,
                ziglePoint: airdropInfo.ziglePoint,
                airdropCnt: airdropInfo.airdropCnt,
                amount: airdropInfo.amount,
                feeAverage: airdropInfo.feeAverage,
                displayYn:airdropInfo.displayYn,
                airdropOpenYn:airdropInfo.airdropOpenYn,
                tmpUser:airdropInfo.airDropList
            };

            AirdropService.createAirdropDe(formData.current).then(
                (res)=>{
                    
                    if(res.resultCode === "SUCCESS"){
                        setAirdropInfo({
                            ...airdropInfo,
                            airdropUrl: res.data.airdropUrl
                        });
                        setDepth(6);
                    }
                }
            )

        } else {
            PopupService.newPopup(t("확인 필요")
                , t("메타마스크에서 트랜잭션을 승인해 주세요.")
                , ""
                , {
                    btnTxt: t("확인 했어요"),
                    onClick: () => {
                    }
                }
                , {}
            );
        }
    }

    const handleOnClick = (selectItem: ICoinInfo) =>{
        
        setAirdropInfo({
            ...airdropInfo,
            coinInfo : selectItem
        });
        setDepth(4);
    }
    const onClickMainnet = () =>{
        setShowCoinPopup(true);
    }
    const onHandleClickMainnet = (selectItem:any) =>{
        if(selectItem.mainnetId !== airdropInfo.mainnetId)
        {
            MetaMaskService.setSwitchNetwork(provider, parseInt(selectItem.chainId), selectItem.mainnetName, selectItem.symbolName, selectItem.decimalVal, selectItem.rpcUrl, selectItem.explorerUrl, '').then(
                (response) => {
                    if (!response.status) {
                    }
                }
            );
        }
        setShowCoinPopup(false);
    }

    useEffect(() => {
        async function fetchMainnetDetail() {
            const response = await CommonService.mainnetList('', 1, 100, '');
            setMainnetList(response.data);
            let setMainNetDetail = await mainNetDetail;
            setMainnetItem(setMainNetDetail);

            setAirdropInfo({
                ...airdropInfo,
                walletAddr: address ? address.toString() : '',
                mainnetId: setMainNetDetail.mainnetId,
                mainnetLinkName: setMainNetDetail.linkName,
                mainnetInfo : {
                    ...airdropInfo.mainnetInfo,
                    chainId: setMainNetDetail.chainId,
                    decimalVal: setMainNetDetail.decimalVal,
                    displayName:setMainNetDetail.displayName,
                    explorerUrl: setMainNetDetail.explorerUrl,
                    linkName: setMainNetDetail.linkName,
                    mainnetId: setMainNetDetail.mainnetId,
                    mainnetName: setMainNetDetail.mainnetName,
                    rpcUrl: setMainNetDetail.rpcUrl,
                    symbolImgFileId: setMainNetDetail.symbolImgFileId,
                    symbolName: setMainNetDetail.symbolName,
                    zigleFee: setMainNetDetail.zigleFee,
                },
                ziglePoint: setMainNetDetail.airdropFee
            });
        }
        fetchMainnetDetail();
    },[mainNetDetail]);



    return (
        <PageRootContainer>
            <AirdropFee setAirdropFeeShow={setAirdropFeeShow} airdropFeeShow={airdropFeeShow} />
            <CoinPopup setShow={setShowCoinPopup} show={showCoinPopup} mainnetList={mainnetList} onHandleClickMainnet={onHandleClickMainnet} />
            {
                (pageMode === 'list') ? (
                    <PageTitle home={home}
                               textTitle={airdropInfo.pageTitle}
                               mainnetName={(depth === 3) ? airdropInfo.mainnetLinkName : ''}
                               rightSrc={
                                (depth === 3) ? require('../assets/imges/plus_icon.png') : (
                                    depth === 5 ? require('../assets/imges/airdrop/airdrop_fee.png') : ''
                                )
                                }
                               onClickRight={() => {
                                   if (depth === 3) {
                                       setPageMode('write');
                                   }else if(depth ===5){
                                       setAirdropFeeShow(true);
                                   }

                               }}
                               onClickMainnet={onClickMainnet}
                               onClickLeft={() => {
                                   if (depth == 0) {
                                       navigate(-1);
                                   } else {
                                       setDepth(depth - 1);
                                   }
                               }}
                    />
                ) : (
                    <PageTitle
                        textTitle={t('토큰 추가 요청')}
                        onClickLeft={()=>setPageMode('list')}
                    />
                )
            }
            <PageContent  style={{marginBottom:'0px'}}>
                {
                    (pageMode === 'list') ? (
                        <>
                            {depth === 0 && <AirdropDeStep1 setDepth={setDepth} airdropInfo={airdropInfo}
                                                            setAirdropInfo={setAirdropInfo}/>}
                            {depth === 1 && <AirdropDeStep2 setDepth={setDepth} airdropInfo={airdropInfo}
                                                            setAirdropInfo={setAirdropInfo}/>}
                            {depth === 2 && <AirdropDeStep3 setDepth={setDepth} airdropInfo={airdropInfo}
                                                            setAirdropInfo={setAirdropInfo}/>}
                            {depth === 3 && <CoinSelect itemInfo={airdropInfo} handleOnClick={handleOnClick} setPageMode={setPageMode}/>}
                            {depth === 4 && <AirdropDeStep5 setDepth={setDepth} airdropInfo={airdropInfo}
                                                            setAirdropInfo={setAirdropInfo}/>}
                            {depth === 5 && <AirdropDeStep6 setDepth={setDepth} airdropInfo={airdropInfo}
                                                            setAirdropInfo={setAirdropInfo} txIdResult={txIdResult}/>}
                            {depth === 6 && <AirdropFiEnd airdropInfo={airdropInfo}/>}
                        </>
                    ) : (
                        <CoinWrite setPageMode={setPageMode}  />
                    )
                }
            </PageContent>
        </PageRootContainer>
    )
}