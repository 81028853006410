import axios from "axios";
import ProfileService, { getHeader } from "./profile.service";
import { checkStatus401, checkStatus401RstType } from "./common.service";
import { JsonData } from "../common/dto/CommonDto";
import { Toast } from "../components/common/popup/ToastAlert";

let API_URL = "";
if (process.env.REACT_APP_ENVTEXT === "local") {
  API_URL = "/api";
} else {
  API_URL = process.env.REACT_APP_API_URL ?? "https://api.zigle.link/api";
}

const walletInsert = (
  mainetCode: string,
  walletAddr: string,
  walletName: string
) => {
  const params = new URLSearchParams();
  params.append("chainId", mainetCode);
  params.append("walletAddr", walletAddr);
  params.append("walletName", walletName);
  return axios
    .post(API_URL + "/v1/user/asset/wallet", params, {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    });
};

const getMyCoin = (
    chainId: string,
    searchTxt: string,
    currentPage: number,
    blockSize: number
) => {
    return axios
        .get(
            API_URL +
            "/v1/user/asset/myCoin",
            {
                params: {
                    chainId: chainId,
                    searchTxt: searchTxt,
                    currentPage: currentPage,
                    blockSize: blockSize
                },
                headers: {
                    ...getHeader(),
                },
            }
        )
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            checkStatus401(error.response);
        });
};

const getWalletName = (mainnetId: string, walletAddr: string) => {
  return axios
    .get(
      API_URL +
        "/v1/user/asset/wallet/?mainnetId=" +
        mainnetId +
        "&walletAddr=" +
        walletAddr,
      {
        headers: {
          ...getHeader(),
        },
      }
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatus401(error.response);
    });
};

const mainnetInsert = (params: object) => {
  return axios
    .post(API_URL + "/v1/user/asset/mainnet", params, {
      headers: {
        ...getHeader(),
        "Content-type": "multipart/form-date",
      },
    })
    .then((response) => {
      return { status: true, msg: "" };
    })
    .catch((error) => {
      checkStatus401RstType(error.response);
        return { status: false, msg: "" };
    });
};

const coinInsert = (params: FormData) => {
  // const params = new FormData();
  // params.append('mainnetCode', mainnetCode);
  // params.append('contract', contract);
  // params.append('coinName', coinName);
  // params.append('symbolName', symbolName);
  // params.append('decimal', decimal);
  // params.append('symbolImg', symbolImg);
  // params.append('typeCode', typeCode);
  return axios
    .post(API_URL + "/v1/user/asset/coin", params, {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return { status: true, msg: "" };
    })
    .catch((error) => {
      checkStatus401RstType(error.response);
      return { status: false, msg: "" };
    });
};

const assetLinkList = (
  urlType: string,
  searchCoin: string,
  currentPage: number,
  blockSize: number
) => {
  // urlType : mainnetlink, coinlink, oneclick
  return axios
    .get(API_URL + "/v1/user/asset/" + urlType, {
      params: {
        searchText: searchCoin,
        currentPage: currentPage,
        blockSize: blockSize,
        pagingYn: "Y",
      },
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatus401(error.response);
    });
};
const assetLinkPost = (urlType: string, params: any) => {
  // urlType : mainnetlink, coinlink, oneclick

  return axios
    .post(API_URL + "/v1/user/asset/" + urlType, params, {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return { status: true, msg: "" };
    })
    .catch((error: any) => {
      checkStatus401RstType(error.response);
    });
};
const assetDelete = (linkMngId: string) => {
  // urlType : mainnetlink, coinlink, oneclick
  return axios
    .get(API_URL + "/v1/user/asset/delMylink?linkMngId=" + linkMngId, {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatus401(error.response);
    });
};
const updateMylink = (params: FormData) => {
    return axios
        .post(API_URL + "/v1/user/asset/updateMylink", params, {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return { status: true, msg: "" };
        })
        .catch((error) => {
            checkStatus401RstType(error.response);
        });
};
const updateMyCoin = (params:FormData) =>{
    return axios
        .post(API_URL + "/v1/user/asset/updateMyCoin", params, {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            checkStatus401RstType(error.response);
        });
}
const assetCoin = (
  mainnetCode: string,
  currentPage: number,
  blockSize: number
) => {
  return axios
    .get(API_URL + "/v1/user/asset/" + mainnetCode + "/coin", {
      params: {
        currentPage: currentPage,
        blockSize: blockSize,
      },
    })
    .then((response) => {
      return response.data;
    });
};
const myLinkList = (
  searchTxt: string,
  userId: string,
  currentPage: number,
  blockSize: number,
  orderBy: string
) => {
  return axios
    .get(API_URL + "/v1/anony/trans/myLinkList", {
      params: {
        userId: userId,
        searchTxt: searchTxt,
        currentPage: currentPage,
        blockSize: blockSize,
        orderBy: orderBy,
      },
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    });
};

const sendHis = (type: string | null, currentPage: number, coinId: string | null) => {

    let paramJsonData: JsonData = {};
    paramJsonData['currentPage'] = currentPage;
    if (type) {
        paramJsonData.type = type;
    }
    if (coinId) {
        paramJsonData.coinId = coinId;
    }
    return axios
        .get(API_URL + "/v1/user/asset/paging/sendHis", {
            params: paramJsonData,
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error: any) => {
            checkStatus401(error.response);
        });
};

const rcvHis = (type: string | null, currentPage: number, coinId: string | null) => {
    let paramJsonData: JsonData = {};
    paramJsonData['currentPage'] = currentPage;
    if (type) {
        paramJsonData.type = type;
    }
    if (coinId) {
        paramJsonData.coinId = coinId;
    }
    return axios
        .get(API_URL + "/v1/user/asset/paging/rcvdHis", {
            params: paramJsonData,
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error: any) => {
            checkStatus401(error.response);
        });
};

const updateMsg = (params: any) => {
    // urlType : mainnetlink, coinlink, oneclick

    return axios
        .post(API_URL + "/v1/user/asset/updateMsg", params, {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return { status: true, msg: "" };
        })
        .catch((error: any) => {
            checkStatus401RstType(error.response);
        });
};
const AssetService = {
  rcvHis,
  sendHis,
  walletInsert,
  mainnetInsert,
  coinInsert,
  assetLinkList,
  assetLinkPost,
  assetDelete,
  assetCoin,
  getWalletName,
  myLinkList,
  updateMylink,
    getMyCoin,
    updateMyCoin,
    updateMsg,
};

export default AssetService;
