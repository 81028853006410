import React, {ReactHTMLElement, useEffect, useRef, useState} from "react";
import PageTitle, {
    FlexBottom, FlexBottom86,
    FlexColumn, FlexColumnCenter, FlexRowAround, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper, ZSelect
} from "../common/container/PageContainer";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import {useTranslation} from "react-i18next";
import {useInView} from "react-intersection-observer";
import ChatService from "../../services/chat.service";
import FavService from "../../services/fav.service";
import EmptyContent from "../common/EmptyContent";
import {Toast, ToastError} from "../common/popup/ToastAlert";
import PopupService from "../../services/popup.service";
import {ellipseAddress} from "../../common/js/utilities";
import {CopyToClipboard} from "react-copy-to-clipboard";

interface IChatMember {
    show: boolean;
    setShow: any;
    chatId:string;
    talkPlus:any;
}

export default function ChatMember(props:IChatMember) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [tabFirst, setTabFirst] = useState('0');

    const [currPage, setCurrPage] = useState(1);
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [blockSize] = useState(10);
    const [reSet, setReSet] = useState(false);
    const [totalCnt, setTotalCnt] = useState(0);
    const [memberList, setMemberList] = useState<Array<any>>([]);
    const [muteList, setMuteList] = useState<Array<any>>([]);
    const fetchData = async() =>{
        const response = await ChatService.listChatUser(props.chatId, currPage, blockSize);
        setTotalCnt(response.totalCount);
        if (!response.data.length) {
            setLastList(true);
            return;
        }
        setMemberList([...memberList, ...response.data]);
    }
    useEffect(() => {
        if (!lastList && props.show) {
            console.log("======",lastList);
            fetchData();
        }
    },[currPage, props.show]);
    useEffect(()=>{
        if(props.talkPlus && props.chatId && props.show){

            props.talkPlus.getMutedPeers({
                channelId:props.chatId,
                limit: 100
            }).then((res:any)=>{
                if(res.members.length>0){
                    res.members.map((item:any, index:number) =>{
                        setMuteList(pre=>[...pre, item.id]);
                    });
                }
            })
        }else{
            setMuteList([]);
        }
    },[props.talkPlus, props.chatId, props.show])
    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);

    return (
        <>
            {
                props.show &&
                    (
                        <FullScreen>
                            <PopupBodyWrapper>
                                <PageRootContainer>
                                    <PageTitle onClickLeft={()=>{
                                        setTabFirst('0');
                                        setCurrPage(1);
                                        setLastList(false);
                                        setMemberList([]);
                                        props.setShow(false);
                                    }} />
                                    <PageContent>
                                        <FlexRowAround>
                                            <div className={ tabFirst === '0' ? styles.topTabActive : styles.topTabDefault} onClick={()=>{setTabFirst('0')}}>{t('마스터({0})', {0:0})}</div>
                                            <div className={ tabFirst === '1' ? styles.topTabActive : styles.topTabDefault} onClick={()=>{setTabFirst('1')}}>{t('멤버({0})', {0:totalCnt})}</div>
                                        </FlexRowAround>
                                        <div className={styles.line} style={{width: 'calc(100% + 40px)', marginLeft:'-20px'}}/>
                                        <FlexColumn style={{paddingTop:'16px'}}>
                                            {
                                                ((memberList.length > 0 ) && (tabFirst === '1')) ? (
                                                    memberList.map((item, index) => {
                                                        return (
                                                            <FlexColumn key={item.userId} style={{height: '72px'}}>
                                                                <FlexRowSpace>
                                                                    <FlexRowStart>
                                                                        <div style={{paddingRight: '12px'}}>
                                                                            <img
                                                                                src={item.profileImageFileId ? item.profileImageFileId : 'https://s3.ap-northeast-2.amazonaws.com/bucket.zigle.io/profiles/profile' + (item.userId % 9) + '.png'}
                                                                                alt="bgIcon" style={{
                                                                                width: '38px',
                                                                                height: '38px',
                                                                                borderRadius: '15px',
                                                                                aspectRatio: '1',
                                                                                objectFit: 'cover'
                                                                            }}/>
                                                                        </div>
                                                                        <FlexColumn>
                                                                            <div className={styles.txt16_B02}>{item.doname}</div>
                                                                            <div  className={styles.txt12_B04} style={{background:'#F2F4F6', borderRadius:'100px', padding:'2px 4px 2px 6px'}}>
                                                                                {ellipseAddress(item.mainWalletAddress, 4)}
                                                                                <CopyToClipboard
                                                                                    text={item.mainWalletAddress}
                                                                                    onCopy={() => Toast(t("주소를 복사 했습니다."))}
                                                                                >
                                                                                    <img src={require("../../assets/icons/copy.png")} width={16} alt={"copy"} />
                                                                                </CopyToClipboard>
                                                                            </div>
                                                                        </FlexColumn>
                                                                    </FlexRowStart>
                                                                    <FlexRowEnd>
                                                                        {
                                                                            (muteList.indexOf(item.userId) > -1) && (
                                                                                <div
                                                                                    className={styles.txt13_red}
                                                                                    style={{padding:'10px 14px', fontWeight:'500', borderRadius:'8px', background:'#FFF0F0'}}
                                                                                    onClick={() => {
                                                                                        props.talkPlus.unmutePeers({
                                                                                            channelId:props.chatId,
                                                                                            members: [item.userId]
                                                                                        }).then((res:any)=>{
                                                                                            Toast(t("{0}을 차단 해제 하였습니다.", {0:item.doname}));
                                                                                            setMuteList(muteList.filter(user => user !== item.userId));
                                                                                        }).catch((error:any)=>{
                                                                                            ToastError(t("{0}님은 이미 차단 해제 한 상태 입니다.", {0:item.doname}));
                                                                                        });
                                                                                    }}
                                                                                >{t("차단해제")}</div>
                                                                            )
                                                                        }
                                                                    </FlexRowEnd>
                                                                </FlexRowSpace>
                                                            </FlexColumn>
                                                        )
                                                    })
                                                ) : (
                                                    <EmptyContent titleText={t('등록된 사람이 없습니다.')} titleImage={require('../../assets/imges/list_x.png')}/>
                                                )
                                            }
                                        </FlexColumn>
                                        {
                                            Object.values(memberList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
                                        }
                                    </PageContent>
                                </PageRootContainer>
                            </PopupBodyWrapper>
                        </FullScreen>
                    )
            }
        </>
    )
}