import React, {useEffect, useRef, useState} from "react";
import {useInView} from "react-intersection-observer";
import {useForm} from "react-hook-form";
import FavService from "../../services/fav.service";
import {
    FlexBottom143, FlexBottomStart,
    FlexColumn,
    FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart
} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import CommonService from "../../services/common.service";
import EmptyContent from "../common/EmptyContent";
import PhoneService from "../../services/phone.service";
import popupService from "../../services/popup.service";
import PhoneInsertPopup from "./PhoneInsertPopup";
import PhoneView from "./PhoneView";
import PopupService from "../../services/popup.service";
import { maskingPhone } from "../../common/js/utilities";
import {useTranslation} from "react-i18next";

interface ITransferStep {
    phoneMode:string;
    setPhoneMode:any;
    handleOnClick?: (item: string)=> void;
    setPhoneView?:any;
    phoneView?:any;
    viewItem?:any;
}

export default function PhoneList(props:ITransferStep) {
    const { t } = useTranslation();
    const [searchTxt, setSearchTxt] = useState('');

    const [phoneList, setPhoneList] = useState<any[]>([]);
    const [currPage, setCurrPage] = useState(1);
    const [lastList, setLastList] = useState(false);
    const [reLoad, setReLoad] = useState(false);
    const [reSet, setReSet] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [insertPopupShow, setInsertPopupShow] = useState(false)
    const [blockSize] = useState(10);
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const deleteItem = (item:any)=>{
        PhoneService.phoneDelete(item.hpId).then((response) => {
            PopupService.newPopup(t("연락처 삭제 완료")
                ,t("연락처가 성공적으로 삭제 되었어요.")
                , ""
                ,{btnTxt:t("확인 했어요"),
                    onClick: () => {
                        const newPhoneList = phoneList.filter((phoneList)=> phoneList.hpId !== item.hpId);
                        setPhoneList(newPhoneList);
                    }}
                , {}
            );
        });
    }
    useEffect(() => {
        const fetchData = async(searchCoin: any) =>{
            const response = await PhoneService.phoneList(searchTxt, currPage, blockSize);
            if (!response.data.length) {
                setLastList(true);
                return;
            }
            setPhoneList([...phoneList, ...response.data]);
        }
        if (!lastList) {
            
            fetchData(searchTxt);
        }
    },[currPage, searchTxt, reSet]);

    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);
    useEffect(()=>{
        setCurrPage(1);
        setPhoneList([]);
        setLastList(false);
        setReSet(reLoad);
    },[reLoad]);
    return (
            <>
                <PhoneView setShow={props.setPhoneView} show={props.phoneView} viewItem={props.viewItem} setReLoad={setReLoad} deleteItem={deleteItem}/>
                <PhoneInsertPopup show={insertPopupShow} setShow={setInsertPopupShow} setReLoad={setReLoad}/>
                <FlexColumn>
                    {/*<FlexColumn>*/}
                    {/*    <FlexRowStart>*/}
                    {/*        <div style={{width:'28px', border: '0', borderBottom: 'solid 1px #f2f4f8', height: '47px', display: 'flex',alignItems: 'center'}}><img src={require('../../assets/imges/search_icon.png')} style={{width:'28px'}} /></div>*/}
                    {/*        <div style={{width:'100%'}}><input*/}
                    {/*            type={"text"}*/}
                    {/*            className={styles.inputText_44}*/}
                    {/*            placeholder={t('이름 또는 전화번호')}*/}
                    {/*            {...register("searchTxt", {*/}
                    {/*                required: t('이름 또는 전화번호'),*/}
                    {/*                onChange: (el) => {*/}
                    {/*                    setCurrPage(1);*/}
                    {/*                    setPhoneList([]);*/}
                    {/*                    setLastList(false);*/}
                    {/*                    setSearchTxt(el.target.value);*/}
                    {/*                }*/}
                    {/*            })}*/}
                    {/*        /></div>*/}
                    {/*    </FlexRowStart>*/}
                    {/*</FlexColumn>*/}
                    <FlexColumn style={{marginTop:'20px'}}>
                        <FlexColumn className={styles.txt15_B01} style={{marginBottom:'8px'}}>{t("내 연락처 목록")}</FlexColumn>
                        {
                            phoneList.length > 0 ? (
                                    phoneList.map((item, index) => {
                                        return (
                                            <FlexColumn key={item.hpId} style={{height:'78px'}}>
                                                <FlexRowSpace >
                                                    <FlexRowStart onClick={()=>{
                                                        if(props.handleOnClick) {
                                                            props.handleOnClick(item);
                                                        }
                                                    }}>
                                                        <div style={{paddingRight:'12px'}}>
                                                            <img src={item.profileImageFileId ? item.profileImageFileId : 'https://s3.ap-northeast-2.amazonaws.com/bucket.zigle.io/profiles/profile'+(item.hpId % 9)+'_1.png' } alt="bgIcon" style={{width:'38px', borderRadius:'15px', aspectRatio:'1', objectFit:'cover'}} />
                                                        </div>
                                                        <FlexColumn>
                                                            <div className={ styles.txt16_B02}>{item.hpName}</div>
                                                            <div className={styles.txt14_B05}>+{item.hpCountry } {maskingPhone(item.hpNum)}</div>
                                                        </FlexColumn>
                                                    </FlexRowStart>
                                                    <FlexRowEnd>
                                                        {
                                                            props.phoneMode ==='write' && (
                                                                <div className={styles.img_btn} style={{width:'56px'}} onClick={()=>deleteItem(item)}>
                                                                    {t("삭제")}
                                                                </div>
                                                            )
                                                        }
                                                    </FlexRowEnd>
                                                </FlexRowSpace>
                                            </FlexColumn>
                                        )
                                    })
                                )
                                :
                                (
                                    <EmptyContent titleText={t('아래의 버튼으로<br/>친구의 연락처를 추가해 보세요')} titleImage={require('../../assets/icons/no_hp.png')}/>
                                )
                        }
                    </FlexColumn>
                </FlexColumn>
                <FlexBottomStart style={{top:'calc(100% - 150px)'}}>
                    <FlexRowCenter className={styles.txt15_PointB} style={{height:'52px', width:'135px', borderRadius:'100px', backgroundColor:'#f2f4f6'}} onClick={()=>{setInsertPopupShow(true)}}><img src={require('../../assets/icons/plus_blue.png')} width={20} /> {t("연락처 추가")}</FlexRowCenter>
                </FlexBottomStart>
                {
                    Object.values(phoneList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
                }

            </>
    )
}