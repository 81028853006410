import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useInView} from "react-intersection-observer";
import AssetService from "../../services/asset.service";
import {Box} from "../../pages/Profile";
import PhoneConfirm from "../profile/PhoneConfirm";
import {FlexColumn, FlexRowEnd, ZSelect} from "../common/container/PageContainer";
import RcvHisItem from "../profile/RcvHisItem";
import EmptyContent from "../common/EmptyContent";
import MiningService from "../../services/mining.service";
import MiningHisItem from "./MiningHisItem";


export default function WithDrawHis(){
    const { t } = useTranslation();
    const [list, setList] = useState<any[]>([])

    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [currPage, setCurrPage] = useState(1);
    const [blockSize] = useState(10);
    let monthDay = "";
    let monthResult = "";

    useEffect(() => {
        if (currPage === 1) {
            setList([]);
        }

        MiningService.miningList('O', currPage, blockSize).then((data) => {
            if (!data.data.length) {
                setLastList(true);
                return;
            }
            setList([...list, ...data.data]);
        });
    }, [currPage]);

    useEffect(() => {
        if (inView && lastList) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ lastList, inView]);

    return (
        <Box gap={24}>
            <FlexColumn gap={40}>
                {
                    list.length > 0 ? (
                            list.map((item,index)=>{
                                let tmpDay = new Date(item.modDtm).getMonth()+1 +"월 "+new Date(item.modDtm).getDate()+"일";

                                if((tmpDay) && (monthDay != tmpDay)){
                                    monthResult = "Y";
                                    monthDay = tmpDay;
                                }else{
                                    monthResult = "N";
                                }

                                return (
                                    <MiningHisItem  key={item.idx}
                                                    item={item}
                                                    index={index}
                                                    monthResult={monthResult}/>
                                );
                            })
                        )
                        :
                        (
                            <EmptyContent titleText={t('출금 내역이 없습니다.')}  titleImage={require('../../assets/imges/list_x.png')}/>
                        )

                }
                {
                    Object.values(list).length >= blockSize ? (
                        <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) : null
                }
            </FlexColumn>
        </Box>
    );
};