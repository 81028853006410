import React, {useEffect, useState} from "react";
import {ICoinInfo} from "../../common/dto/CoinInfoDto";
import {useInView} from "react-intersection-observer";
import CommonService from "../../services/common.service";
import FavService from "../../services/fav.service";
import styles from "../../common/css/zigle.module.scss";
import {FlexColumn, FlexColumnCenter, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import {useForm} from "react-hook-form";
import EmptyContent from "../common/EmptyContent";
import { maskingEmail } from "../../common/js/utilities";
import {useTranslation} from "react-i18next";
import {Toast} from "../common/popup/ToastAlert";
import PopupService from "../../services/popup.service";
import {useNavigate} from "react-router-dom";


interface ITransferStep {
    handleOnClick?: (item: string)=> void;
    reset?: boolean;
    pageMode?:string;
}

export default function FriendList(props:ITransferStep) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchTxt, setSearchTxt] = useState('');

    const [friendList, setFriendList] = useState<any[]>([]);
    const [currPage, setCurrPage] = useState(1);
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [blockSize] = useState(10);
    const [refresh, setRefresh] = useState(false);

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const fetchData = async(searchCoin: any) =>{
        const response = await FavService.friendList(searchTxt, currPage, blockSize);
        if (!response.data.length) {
            setLastList(true);
            return;
        }
        setFriendList([...friendList, ...response.data]);
    }
    useEffect(() => {
        if (!lastList) {
            
            fetchData(searchTxt);
        }
    },[currPage, searchTxt, refresh]);

    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);
    useEffect(()=>{
        if(friendList.length > 0)
        {
            setFriendList([]);
            setLastList(false);
            if(currPage ===1 ){
                setRefresh(!refresh);
            }else{
                setCurrPage(1);
            }

        }
    },[props.reset])

    return (
        <>
            {
                props.pageMode === "trans" && (
                    <FlexColumn>
                        <FlexRowStart>
                            <div style={{
                                width: '28px',
                                border: '0',
                                borderBottom: 'solid 1px #f2f4f8',
                                height: '47px',
                                display: 'flex',
                                alignItems: 'center'
                            }}><img src={require('../../assets/imges/search_icon.png')} style={{width: '28px'}}/></div>
                            <div style={{width: '100%'}}><input
                                type={"text"}
                                className={styles.inputText_44}
                                placeholder={t('닉네임을 입력해 주세요')}
                                {...register("searchTxt", {
                                    required: t('닉네임을 입력해 주세요'),
                                    onChange: (el) => {
                                        setCurrPage(1);
                                        setFriendList([]);
                                        setLastList(false);
                                        setSearchTxt(el.target.value);
                                    }
                                })}
                            /></div>
                        </FlexRowStart>
                    </FlexColumn>
                )
            }
            <FlexColumn style={{marginTop:'20px'}}>
                <FlexColumn className={styles.txt15_B01} style={{marginBottom:'8px'}}>{t("내 프렌드 목록")}</FlexColumn>
                {
                    friendList.length > 0 ? (
                        friendList.map((item, index) => {
                            return (
                                <FlexColumn key={item.favoriteId} style={{height:'78px'}}>
                                    <FlexRowSpace>
                                        <FlexRowStart onClick={()=>{
                                            if(props.handleOnClick) {
                                                props.handleOnClick(item);
                                            }
                                        }}>
                                            <div style={{paddingRight:'12px'}}>
                                                <img src={item.profileImageFileId ? item.profileImageFileId : 'https://s3.ap-northeast-2.amazonaws.com/bucket.zigle.io/profiles/profile'+(item.userId % 9)+'.png' } alt="bgIcon" style={{width:'38px', height:'38px', borderRadius:'15px', aspectRatio:'1', objectFit:'cover'}} />
                                            </div>
                                            <FlexColumn>
                                                <div className={ styles.txt16_B02}>{item.doname}</div>
                                                <div className={styles.txt14_B05}>{t("{0}명 프렌딩", {0:item.favTrandingTotalCnt})}</div>
                                            </FlexColumn>
                                        </FlexRowStart>
                                        {
                                            props.pageMode === "friend" && (
                                                <FlexRowEnd gap={4}>
                                                    <FlexColumnCenter className={styles.txt14_500_PointB} style={{width:'75px', height:'34px',  background:'#EBF2FF',  borderRadius:'8px' }} onClick={()=>{

                                                        PopupService.newPopup(t("언프렌드")
                                                            ,t("'{0}' 님을 언프렌드 할까요?", {0:item.doname})
                                                            , ""
                                                            ,{btnTxt:t("확인"),
                                                                onClick: () => {

                                                                    FavService.friendDelete(item.userId).then((response) => {

                                                                        setFriendList(friendList.filter(ztem => {
                                                                            return ztem.userId !== item.userId;
                                                                        }));
                                                                        Toast(t('친구취소가 완료 되었습니다.'));
                                                                    });
                                                                }}
                                                            , {
                                                                btnTxt:"",
                                                                onClick: () => {}
                                                            }
                                                        );
                                                    }} >{t('언프렌드')}</FlexColumnCenter>
                                                    <img src={require('../../assets/imges/chat/btn_chat.png')} width={34} onClick={()=>{navigate("/chat/"+item.doname)}}/>
                                                </FlexRowEnd>
                                            )
                                        }

                                    </FlexRowSpace>
                                </FlexColumn>
                            )
                        })
                    )
                    :
                    (
                        <EmptyContent titleText={t('우측 상단의 검색을 통해<br/>친구를 찾아보세요')} titleImage={require('../../assets/imges/list_x.png')}/>
                    )
                }
            </FlexColumn>
            {
                Object.values(friendList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
            }
        </>
    )
}