import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import {FlexColumn, FlexColumnCenter, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import {ICoinInfo} from "../../common/dto/CoinInfoDto";
import {useInView} from "react-intersection-observer";
import CommonService from "../../services/common.service";
import {useForm} from "react-hook-form";
import {IMainnetInfo} from "../../common/dto/MainnetInfo";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

import MetaMaskService from "../../services/metamask.service";
import CryptoItem from "../common/CryptoItem";
import EmptyContent from "../common/EmptyContent";
import AirdropService from "../../services/airdrop.service";
import {useAccount, useBalance} from "wagmi";

interface ILinkStep {
    handleOnClick?: (item: ICoinInfo)=> void;
    itemInfo:any;
    setPageMode?:any;
}

export default function CoinSelect(props: ILinkStep) {
    const { t } = useTranslation();
    const chainId = useSelector((state:any) => state.walletReducer.chainId);

    const { address, isConnected } = useAccount();

    const hookCoinBalance = useBalance({
        address: address,
    });


    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();
    const [searchCoin, setSearchCoin] = useState('');
    const [mainnetId, setMainnetId]= useState('');
    const [coinList, setCoinList] = useState<Array<ICoinInfo>>([]);
    const [reset, setReset] = useState(false);
    const [currPage, setCurrPage] = useState(1);
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [blockSize] = useState(10);

    const fetchData = async(searchCoin: any) =>{
        const response = await CommonService.chainIdTokenList(props.itemInfo.mainnetLinkName, 'coin',  searchCoin, currPage, blockSize);
        if (!response.data.length) {
            setLastList(true);
            return;
        }
        setCoinList([...coinList, ...response.data]);
    }

    useEffect(() => {
        if (!lastList) {
            if(mainnetId === props.itemInfo.mainnetId) {
                fetchData(searchCoin);
            }
        }
    },[currPage, searchCoin, reset]);
    useEffect(()=>{
        if (props.itemInfo.mainnetLinkName !='' && props.itemInfo.mainnetLinkName !=undefined) {
            if(mainnetId != props.itemInfo.mainnetId){
                setMainnetId(props.itemInfo.mainnetId);

                setCoinList([]);
                setLastList(false);
                if(currPage!==1)
                {
                    setCurrPage(1);
                }else{
                    setReset(!reset);
                }
            }
        }
    },[props.itemInfo])

    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);

    return (
        <>
            <FlexColumn gap={12}>
                {
                    props.itemInfo.pageType !='myCoin'  && (
                        <FlexColumn className={styles.txt20_500_B01}>

                            {
                                props.itemInfo.pageType ==='createLink' ? (t('어떤 코인을 받을까요?')
                                ): (t('어떤 코인을 보낼까요?'))
                            }
                        </FlexColumn>
                    )
                }
                <FlexColumn>
                    <FlexRowStart>
                        <div style={{width:'28px', border: '0', borderBottom: 'solid 1px #f2f4f8', height: '47px', display: 'flex',alignItems: 'center'}}>
                            <img src={require('../../assets/imges/search_icon.png')} style={{width:'28px'}} />
                        </div>
                        <div style={{width:'100%'}}><input
                            type={"text"}
                            className={styles.inputText_44}
                            placeholder={t('코인명을 입력해 주세요')}
                            {...register("searchCoin", {
                                required: t('코인명을 입력해 주세요'),
                                onChange: (el) => {
                                    setCurrPage(1);
                                    setCoinList([]);
                                    setLastList(false);

                                    setSearchCoin(el.target.value);
                                }
                            })}
                        /></div>
                    </FlexRowStart>
                </FlexColumn>
            </FlexColumn>
            <FlexColumn>
                {
                    coinList.length > 0 ? (
                            coinList.map((coinInfo, idx) => {

                                return (
                                    <CryptoItem
                                        key={idx}
                                        coinInfo={coinInfo}
                                        pageInfo={props.itemInfo}
                                        address={address}
                                        coinBalance={hookCoinBalance.data?.formatted}
                                        onClick={()=>{
                                            if(props.handleOnClick) {
                                                props.handleOnClick(coinInfo);
                                            }
                                        }}
                                    />
                                );
                            })
                        )
                        :
                        (
                            <>
                                <EmptyContent titleText={t('검색 결과가 없습니다')} titleImage={require('../../assets/icons/empty_icon.png')}/>
                                {
                                    props.setPageMode && (
                                        <FlexColumnCenter style={{paddingTop:'16px'}}>
                                            <div className={styles.txt13_500_B04} style={{padding:'10px 16px', color:'#3A71FF', borderRadius:'8px', background:'#EBF2FF'}} onClick={()=>{
                                                props.setPageMode("write");
                                            }}>{t("목록에 내코인 추가하기")}</div>
                                        </FlexColumnCenter>
                                    )
                                }
                            </>
                        )
                }
            </FlexColumn>
            {
                Object.values(coinList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
            }
        </>
    );
}