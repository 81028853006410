import React, {useEffect, useState} from "react";
import {FlexRowAround} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import PhoneService from "../../services/phone.service";
import bigDecimal from "js-big-decimal";
import {useTranslation} from "react-i18next";

interface IPayDepthProps {
    payInfo:any;
    setPayInfo:any;
}
export default function TokenSelect({payInfo, setPayInfo}:IPayDepthProps) {
    const { t } = useTranslation();

    const [list, setList]= useState<any[]>([]);
    useEffect(()=>{
        PhoneService.exchangeList().then(
            (res)=>{
                setList([...list, ...res.data]);
            }
        )
    },[])
    return (
        <FlexRowAround>
            {
                list.length > 0 ?
                    (
                        list.map((item, index)=>{
                            let tmpAmount = "0";
                            if(item.typeCode === 'COIN')
                            {
                                tmpAmount = new bigDecimal(payInfo.ziglePoint).divide(new bigDecimal(item.exchangeRate), 5).getValue();
                            }else{
                                tmpAmount = new bigDecimal(payInfo.ziglePoint).divide(new bigDecimal(item.exchangeRate), 1).getValue();
                            }

                            return (
                                <div key={item.contAddr} className={ payInfo.cryptoInfo.symbolName === item.symbolName ? styles.imageBoxActive : styles.imageBoxDefault}
                                      onClick={()=>{
                                          setPayInfo(
                                              {...payInfo,
                                                  subPayType : 'T',
                                                  amount : tmpAmount,
                                                  cryptoInfo: {
                                                      ...payInfo.cryptoInfo,
                                                      tokenType: item.typeCode,
                                                      chainId: item.chainId,
                                                      symbolName: item.symbolName,
                                                      contAddr: item.contAddr,
                                                      exchangeRate: item.exchangeRate,
                                                      mainnetName: item.mainnetName,
                                                      explorerUrl: item.explorerUrl,
                                                      rpcUrl: item.rpcUrl,
                                                      symbolImgFileId: item.symbolImgFileId,
                                                      decimalVal: item.decimalVal
                                                  }
                                              }
                                          );
                                      }}>
                                    <img
                                        src={require('../../assets/imges/payment/'+item.symbolFileName)}
                                        width={40}
                                        height={40}
                                        alt={"payment01"}
                                    />
                                    <span>
                                        {tmpAmount +" " +item.symbolName}
                                    </span>
                                </div>
                            )
                        })
                    )
                    :
                    null
            }
            <div style={{width:'102px'}}></div>
        </FlexRowAround>
    )
}