import {FlexColumn, FlexColumnCenter} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import React from "react";
import {Trans, useTranslation} from "react-i18next";


export default function Intro2() {
    const { t } = useTranslation();
    return (
        <FlexColumn gap={80} style={{paddingTop:'56px'}}>
            <FlexColumn gap={12}>
                <div className={styles.txt24_800_W}><Trans t={t} i18nKey={"친구들을 추가하고<br/>간편하게 코인 주고 받기!"}/></div>
                <div className={styles.txt15_B03}><Trans t={t} i18nKey={"친구들을 초대하세요<br/>송금 링크부터 닉네임을 통한 안전 전송까지!"}/></div>
            </FlexColumn>
            <FlexColumnCenter gap={40}>
                <img src={require('../../assets/imges/intro/introduction_img2.png')} style={{width:'100%'}} />
                <img src={require('../../assets/imges/intro/introduction_img3.png')} style={{width:'100%'}} />
                <img src={require('../../assets/imges/intro/introduction_img4.png')} style={{width:'100%'}} />
            </FlexColumnCenter>
        </FlexColumn>
    )
}