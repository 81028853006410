import React, {useState} from "react";
import {FlexColumEnd, FlexColumn, FlexRowAround, FlexRowEnd, FlexRowSpace} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import CommonService from "../../services/common.service";
import Button34 from "../common/button/Button34";
import CopyToClipboard from "react-copy-to-clipboard";
import {Event_Map, PayType_Map} from "../../pages/MyPoint";
import {decimalFormat} from "../../common/js/utilities";
import moment from "moment";
import {useTranslation} from "react-i18next";

interface IPointSndHisItem {
    index:number;
    item:any;
    monthResult:any;
}
export default function PointSndHisItem(props:IPointSndHisItem) {
    const { t } = useTranslation();

        return (
            <FlexColumn gap={16}>
                {
                    props.monthResult ==="Y" &&
                        (
                            props.index !==0 ?
                                (
                                    <FlexColumn gap={24}>
                                        <FlexColumn className={styles.line}></FlexColumn>
                                        <FlexColumn className={styles.txt13_B04}>{moment(props.item.regDate).format('MM월DD일')}</FlexColumn>
                                    </FlexColumn>
                                )
                                :
                                (<FlexColumn className={styles.txt13_B04}>{moment(props.item.regDate).format('MM월DD일')}</FlexColumn>)
                        )
                }
                <FlexRowAround>
                    <FlexColumn gap={5}>
                        <div className={styles.txt16_B02}>{PayType_Map[props.item.payType]}</div>
                        <div className={styles.txt12_B05}>{moment(props.item.regDate).format('HH:mm')}</div>
                    </FlexColumn>
                    <FlexColumEnd gap={5}>
                        <div className={styles.txt18_500_Point_B}>-{decimalFormat(props.item.ziglePoint)}</div>
                        <div className={styles.txt12_B05}>{Event_Map[props.item.eventType]}</div>
                    </FlexColumEnd>
                </FlexRowAround>
            </FlexColumn>
        );


};