import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import Button34 from "../common/button/Button34";
import { useSelector } from "react-redux";
import CommonService from "../../services/common.service";
import LvButton from "../common/button/LvButton";
import {decimalFormat} from "../../common/js/utilities";
import {useTranslation} from "react-i18next";

export default function ZigleCoinFrame() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const miningAmount = useSelector((state: any) => state.userReduce.miningAmount);

  return (
    <div className={styles.mainBox} onClick={() => {
      navigate("/mining")
    }}>
      <div className={styles.titleContainer}>
        <div className={`${styles.flexRowStart}`}>
          <div className={styles.txtTitle18}>{t("내 지글(ZIG)")}</div>
        </div>
        <div>
          <img
            src={require("../../assets/icons/btnArrow.png")}
            height={28}
            alt={"arrow"}
          />
        </div>
      </div>

      <div className={styles.contentsBetween}>
        <div className={styles.txtZigleCoin}>
          {decimalFormat(miningAmount)}
        </div>
        <div>
          <div>
            <Button34
              type={"black"}
              text={"Mining"}
              width={"78"}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
