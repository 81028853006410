import React, { useEffect, useState } from "react";
import { FlexRowCenter } from "../container/PageContainer";

interface IBottomButton {
  text: string | JSX.Element;
  textColor: string;
  backgroundColor: string;
  border?: string;
  onClick: () => void;
  isActive: boolean;
  icon?: string;
  radius?:string;
}

export default function BottomButton(props: IBottomButton) {
  const [bgColor, setBgColor] = useState("#f2f4f6");
  const [opacity, setOpacity] = useState(0.4);
  const [color, setColor] = useState("#111111");
  const [border, setBorder] = useState("");
  const [pointerEvents, setPointerEvents] = useState<"auto" | "none">("none");

  useEffect(() => {
    if (props.isActive) {
      setOpacity(1);
      setColor(props.textColor);
      setPointerEvents("auto");
      setBgColor(props.backgroundColor);
      if (props.border) setBorder(props.border);
    } else {

      setOpacity(0.4);
      setColor(props.textColor ? props.textColor : '#111111');
      setPointerEvents("none");
      setBgColor(props.backgroundColor ? props.backgroundColor : '#f2f4f6');
      if (props.border) setBorder(props.border);
    }
  }, [props]);

  return (
    <FlexRowCenter
      style={{
        height: "56px",
        // width: "calc(100% + 12*2px)",
        // marginLeft: "-12px",
        backgroundColor: bgColor,
        pointerEvents: pointerEvents,
        opacity: opacity,
        color: color,
        border: border,
        borderRadius: props.radius
      }}
      onClick={() => {
        props.onClick();
      }}
    >
      {props.icon && (
        <img
          src={props.icon}
          width={28}
          alt={"btnIcon"}
          style={{ marginRight: "4px" }}
        />
      )}
      <div style={{fontSize:'16px'}}>{props.text}</div>
    </FlexRowCenter>
  );
}
