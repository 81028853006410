import React, { useEffect, useState } from "react";
import styles from "../../common/css/zigle.module.scss";
import {FlexColumn} from "../common/container/PageContainer";

import GuideImg1 from "../../assets/imges/metamask/metamask_guide2_1.png";
import GuideImg2 from "../../assets/imges/metamask/metamask_guide2_2.png";
import GuideImg3 from "../../assets/imges/metamask/metamask_guide2_3.png";
import {Trans, useTranslation} from "react-i18next";

export default function Guide2() {
    const { t } = useTranslation();
    return (
        <FlexColumn gap={24}>
            <FlexColumn gap={8}>
                <div className={styles.txt18_500}>Add to Chrome</div>
                <div className={styles.txt14_B05} style={{lineHeight:'1.71'}}>
                    <Trans t={t} i18nKey={"Chrome에 추가 버튼을 눌러 메타마스크 설치를 진행해주세요.<br/>이 단계를 거치면 메타마스크 설치가 완료 됩니다."}/>
                </div>
                <div><img src={GuideImg1} alt="Guide Img" style={{width:'100%'}}/></div>
            </FlexColumn>
            <FlexColumn gap={8}>
                <div className={styles.txt18_500}>Create wallet after installation is complete</div>
                <div className={styles.txt14_B05} style={{lineHeight:'1.71'}}>
                    <Trans t={t} i18nKey={"설치가 완료되면 아래의 페이지가 표시됩니다.<br/>시작하기 버튼을 클릭합니다.<br/>"}/>
                </div>
                <div><img src={GuideImg2} alt="Guide Img" style={{width:'100%'}}/></div>
            </FlexColumn>
            <FlexColumn gap={8}>
                <div className={styles.txt14_B05} style={{lineHeight:'1.71'}}>
                    <Trans t={t} i18nKey={"지갑 생성이 처음이므로 지갑 생성 버튼을 클릭합니다.<br/>지갑이 이미 있는 경우 지갑 가져오기 버튼을 사용하여 이미 생성된 지갑을 가져옵니다.<br/>"}/>
                </div>
                <div><img src={GuideImg3} alt="Guide Img" style={{width:'100%'}}/></div>
            </FlexColumn>
        </FlexColumn>
    )
}