import React, {useState} from "react";
import styles from "../../../common/css/zigle.module.scss";
import CloseBtn from "../../../assets/icons/X.svg";
import { IPopAction } from "../../../services/popup.service";
import {FlexColumn, FlexRowCenter, FlexRowEnd} from "../container/PageContainer";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import {ko} from "date-fns/locale";
import {useTranslation} from "react-i18next";

registerLocale("ko", ko);


interface INewDatePop {
  onClose: () => void;
  fnClose?: () => void | null;
  ok: IPopAction | null;
  cancel: IPopAction | null;
}
const StyledDatePicker = styled(DatePicker)`
 & .react-datepicker-popper {
        width: 320px;
    }
`;
function NewDatePop(props: INewDatePop) {
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState<Date>(new Date());
    const onChange = (date:Date) => {

        setStartDate(date);
    };

  return (
    <FlexColumn gap={20} style={{backgroundColor: 'white', width:'300px', borderRadius:'24px', padding:'20px 20px 32px 20px'}}>
        <FlexRowEnd>
            <img
            onClick={() => {
                if (props.fnClose) {
                    props.fnClose();
                }
                props.onClose();
            }}
            src={CloseBtn}
            alt="close"
        />
        </FlexRowEnd>
            <StyledDatePicker selected={startDate} locale="ko"
                     onChange={(date:Date) => setStartDate(date)}
                     inline/>
        <FlexRowCenter>
            <div className={styles.popupBtnBlue}
                 onClick={() => {
                     props.onClose();
                     if (props.ok?.onClick) {

                         props.ok.onClick();
                     }else{

                     }
                 }}
            >{props.ok?.btnTxt ? props.ok.btnTxt : t("확인")}</div>
        </FlexRowCenter>
        {
            props.cancel?.onClick && props.cancel?.btnTxt && (
                <FlexRowCenter>
                    <div className={styles.txt14_B05}
                         onClick={() => {
                             props.onClose();
                             if (props.cancel?.onClick) {

                                 props.cancel.onClick();
                             }else{

                             }
                         }}
                    >
                        {props.cancel?.btnTxt ? props.cancel.btnTxt : t("취소")}
                    </div>
                </FlexRowCenter>
            )
        }


    </FlexColumn>
  );
}

export default NewDatePop;
