import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import styled from "styled-components";
import {FlexBottom, FlexBottomFree, FlexColumnCenter, FlexRowCenter} from "../common/container/PageContainer";
import {useTranslation} from "react-i18next";

import homeOn from "../../assets/imges/home/home_on.png";
import homeOff from "../../assets/imges/home/home_off.png";

import airdropOn from "../../assets/imges/home/airdrop_on.png";
import airdropOff from "../../assets/imges/home/airdrop_off.png";

import tradeOn from "../../assets/imges/home/trade_on.png";
import tradeOff from "../../assets/imges/home/trade_off.png";

import friendOn from "../../assets/imges/home/friend_on.png";
import friendOff from "../../assets/imges/home/friend_off.png";

import chatOn from "../../assets/imges/home/chat_on.png";
import chatOff from "../../assets/imges/home/chat_off.png";
import TradePopup from "./TradePopup";

interface INaviBar{
    pageType:string;
}
export default function NaviBar(props:INaviBar){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    return(
        <>
            <TradePopup show={show} setShow={setShow}/>
            <FlexBottom style={{ top: 'calc(100% - 80px)', boxShadow:'0px 1px 0.1px 0px #F2F3F6 inset', alignItems:'flex-start', paddingTop:'4px', backgroundColor:'#ffffff', marginLeft:props.pageType === "main" ? '0px' : '-20px', height:'80px'}}>
                <FlexRowCenter gap={24}>
                    <FlexColumnCenter style={{width:'48px'}} onClick={()=>{navigate("/")}}>
                        <img src={props.pageType === "main" ? homeOn : homeOff} width={28} />
                        <div className={ props.pageType === "main" ? styles.txt10_B01 : styles.txt10_B06}>{t("홈")}</div>
                    </FlexColumnCenter>
                    <FlexColumnCenter style={{width:'48px'}} onClick={()=>{navigate("/airdrop")}}>
                        <img src={props.pageType === "airdrop" ? airdropOn : airdropOff} width={28} />
                        <div className={ props.pageType === "airdrop" ? styles.txt10_B01 : styles.txt10_B06}>{t("에어드랍")}</div>
                    </FlexColumnCenter>
                    <FlexColumnCenter style={{width:'48px'}} onClick={()=>{setShow(true)}}>
                        <img src={props.pageType === "trade" ? tradeOn : tradeOff} width={28} />
                        <div className={ props.pageType === "trade" ? styles.txt10_B01 : styles.txt10_B06}>{t("거래")}</div>
                    </FlexColumnCenter>
                    <FlexColumnCenter style={{width:'48px'}} onClick={()=>{navigate("/friend")}}>
                        <img src={props.pageType === "friend" ? friendOn : friendOff} width={28} />
                        <div className={ props.pageType === "friend" ? styles.txt10_B01 : styles.txt10_B06}>{t("프렌드")}</div>
                    </FlexColumnCenter>
                    <FlexColumnCenter style={{width:'48px'}} onClick={()=>{navigate("/chat")}}>
                        <img src={props.pageType === "chat" ? chatOn : chatOff} width={28} />
                        <div className={ props.pageType === "chat" ? styles.txt10_B01 : styles.txt10_B06}>{t("채팅")}</div>
                    </FlexColumnCenter>
                </FlexRowCenter>
            </FlexBottom>
        </>
    )
}