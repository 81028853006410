import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import {
    FlexBottom, FlexBottomStart,
    FlexColumn, FlexColumSpace,
    FlexRowAround,
    FlexRowCenter,
    FlexRowEnd,
    FlexRowSpace,
    FlexRowStart
} from "../common/container/PageContainer";
import {useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {ellipseAddress} from "../../common/js/utilities";
import CopyToClipboard from "react-copy-to-clipboard";
import DefaultInput from "../common/input/DefaultInput";
import BottomButton from "../common/button/BottomButton";
import AssetService from "../../services/asset.service";
import PopupService from "../../services/popup.service";
import {Toast, ToastError} from "../common/popup/ToastAlert";
import {useAccount} from "wagmi";
import CommonService from "../../services/common.service";

interface ILinkStep {
    linkInfo: any;
    setLinkInfo: any;
    setDepth: any;
}

export default function LinkStep4({linkInfo, setLinkInfo, setDepth}: ILinkStep) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [btnActive, setBtnActive] = useState(false);
    const [service, setService] = useState<boolean>(false);

    const userId: string = useSelector((state: any) => state.userReduce.userId);
    
    const chainId = useSelector((state:any) => state.walletReducer.chainId);
    const disconnect = useSelector((state:any) => state.walletReducer.disconnect);
    const [address,  setAddress] = useState('');

    const inputRef = useRef<HTMLFormElement>(null);
    const {
        setValue,
        register,
        setError,
        clearErrors,
        formState: { errors, isDirty, isValid },
        handleSubmit,
    } = useForm();

    const onSubmit = (data:any) => {
        if(!address){
            ToastError(t("지갑연결을 확인해 주세요."));
            return false;
        }
       let modeType = 'coinlink';
        const params = new URLSearchParams();
        params.append('mainnetId', linkInfo.mainnetId);
        params.append('walletName', linkInfo.pageTitle);
        params.append('walletAddr', address);
        params.append('coinId', linkInfo.coinId);
        if(!service && inputRef.current?.popAmount)
        {
            params.append('amount', inputRef.current?.popAmount.value);
            modeType = 'oneclick';
        }
        AssetService.assetLinkPost(modeType, params).then(
            (response) => {

                if(response?.status)
                {
                    PopupService.newPopup(t("도네이션링크 생성")
                        ,t("도네이션링크가 등록되었습니다.")
                        , ""
                        ,{btnTxt:t("확인 했어요"),
                            onClick: () => {
                                navigate('/myLinkList');
                            }}
                        , {}
                    );
                }
            }
        )
    }
    useEffect(()=>{
        inputRef.current?.popAmount.focus();
    },[]);
    useEffect(()=>{
        CommonService.getWalletLoginAddress(userId).then((res:any)=>{
            setAddress(res.data.find((obj:any)=>{return obj.mainUse ==="Y"})?.walletAddress);
        })
        if(!service){
            setBtnActive(false);
        }else{
            if(inputRef.current?.popAmount)
            {
                inputRef.current.popAmount.value='';
            }
            setBtnActive(true);
        }
    },[service])
    return (
        <FlexColumn>
            <FlexColumn gap={64}>
                <FlexRowCenter>
                    <FlexRowAround className={styles.wallet_logo_area}>
                        <div><img src={require('../../assets/icons/wallet_icon.png')} width={20} /></div>
                        <div className={styles.txt13_B04}>{ellipseAddress(address, 8)}</div>
                        <div>
                            <CopyToClipboard text={address as string} onCopy={() => Toast(t("주소를 복사 했습니다."))}>
                                <img src={require("../../assets/icons/copy.png")} height={20} alt={"copy"} />
                            </CopyToClipboard>
                            </div>
                    </FlexRowAround>
                </FlexRowCenter>

                <FlexRowCenter>
                    <div style={{width:'150px'}}>
                        <form ref={inputRef} onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                            {
                                service ? (
                                    <DefaultInput
                                        style={{width:'100px'}}
                                        error={errors.popAmount}
                                        inputElement={
                                            <input
                                                type={"number"}
                                                className={styles.inputText_33}
                                                disabled={service}
                                                placeholder={t("받을 수량")}
                                                {...register("popAmount", {
                                                    required:false,
                                                    validate: (value, formValues) => {
                                                        clearErrors('popAmount');
                                                        return true;
                                                    }
                                                })}
                                            />
                                        }
                                    />
                                ) :  (
                                        <DefaultInput
                                            style={{width:'100px'}}
                                            error={errors.popAmount}
                                            inputElement={
                                                <input
                                                    type={"number"}
                                                    className={styles.inputText_33}
                                                    disabled={service}
                                                    placeholder={t("받을 수량")}
                                                    {...register("popAmount", {
                                                        required: t("받을 수량을 입력해주세요."),
                                                        validate: (value, formValues) => {
                                                            var pattern=  /^([0-9]{1,6})(\.[0-9]{1,5})?$/i;
                                                            if(pattern.test(value)){
                                                                setBtnActive(true);
                                                                clearErrors('popAmount');
                                                                return true;
                                                            }else{
                                                                setBtnActive(false);
                                                                setError("popAmount", {type:'custom', message:t('6자리 숫자 및 소수점 5자리까지 입력 가능합니다.')});
                                                                return false;
                                                            }
                                                        }
                                                    })}
                                                />
                                            }
                                        />
                                    )

                            }

                        </form>
                    </div>
                    <div style={{marginBottom:'24px', color: '#dbdee6'}}>{linkInfo.coinSymbolName}</div>
                </FlexRowCenter>
            </FlexColumn>

            <FlexBottomStart gap={23}>
                <FlexColumn style={{paddingLeft:'20px'}} className={styles.txt14_B02}>
                        <div>
                            <input type="checkbox" checked={service} id={'secession'} onChange={(e)=>{setService((prev: boolean) => !prev)}} />
                            <label htmlFor={'secession'}>{t("수량은 보내는 사람이 자유롭게 입력할게요")}</label>
                            {/*<Checkbox checked={service} onChange={(e)=>{setService((prev: boolean) => !prev)}} />수량은 보내는 사람이 자유롭게 입력할게요*/}
                        </div>
                </FlexColumn>
                <FlexColumn>
                    <BottomButton
                    isActive={btnActive}
                    backgroundColor={"#3a71ff"}
                    textColor={"white"}
                    // border={"solid 1px #dbdee6"}
                    onClick={handleSubmit(onSubmit)}
                    text={t("다음")}
                    />
                </FlexColumn>
            </FlexBottomStart>

        </FlexColumn>
    );
}
