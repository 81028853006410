import React, { useEffect, useState } from "react";
import styles from "../common/css/zigle.module.scss";
import PageTitle, {
    FlexColumn, FlexRowStart,
    PageContent,
    PageRootContainer,
} from "../components/common/container/PageContainer";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import CommonService from "../services/common.service";
import ReactPlaceholder from "react-placeholder";
import AuthService from "../services/auth.service";
import { SocialButton } from "../components/common/button/Button34";
import { GoogleLogin } from "react-google-login";
import KakaoLogin from "react-kakao-login";
import {gapi} from "gapi-script";
import {ellipseAddress} from "../common/js/utilities";
import { useAccount } from "wagmi";
import {ToastError} from "../components/common/popup/ToastAlert";
import {Trans, useTranslation} from "react-i18next";
const googleClientId = process.env.REACT_APP_GOOGLE_AUTH_KEY ?? "";
const kakaoClientId = process.env.REACT_APP_KAKAO_AUTH_KEY ?? "";

export default function NoJoinInfo() {
  const [btnActive, setBtnActive] = useState(false);
  const navigate = useNavigate();
    const { t } = useTranslation();
  const { address, isConnected } = useAccount();

    const [check1, setCheck1] = useState(false);
    const [check2, setCheck2] = useState(false);


  const onSuccessGoogle = async (response: any) => {
      if(!address){
          ToastError(t("지갑연결을 확인해 주세요."));
          return false;
      }
        AuthService.oauthLogin(
          "google",
          response.profileObj.email,
          response.profileObj.googleId,
          response.tokenId,
          address
        );
  };

  const onSuccessKaKao = async (response: any) => {
      if(!address){
          ToastError(t("지갑연결을 확인해 주세요."));
          return false;
      }
    AuthService.oauthLogin(
      "kakao",
      response.profile.kakao_account.email,
      response.profile.id,
      response.response.id_token,
      address
    );
  };

  const onFailure = (error: any) => {
    console.log("onFailure", error);
  };

  useEffect(() => {
    //지갑여부 확인
    if (address) {
      setBtnActive(true);
        AuthService.checkJoinByAddress(address).then((walletLogin) => {
            if (walletLogin === null || walletLogin === undefined) {
                //회원계정과 연결 안되었을 경우.

            }else{
                let socialCode = walletLogin.socialCode;
                //카카오 계정과 연결 되었을 경우
                if (socialCode === "kakao") {
                    navigate("/loginKakao");
                } else if (socialCode === "google") {
                    //구글 계정과 연결 되었을 경우
                    navigate("/loginGoogle");
                } else {
                    //이메일 계정과 연결 되었을 경우.
                    navigate("/loginEmail");
                }
            }
        });
    }
  }, [address]);
    useEffect(() => {
        const initClient = () => {
            gapi.client.init({
                clientId: googleClientId,
                scope: "",
            });
        };
        gapi.load("client:auth2", initClient);
    });
  return (
    <PageRootContainer>
      <PageTitle
        onClickLeft={() => {
          navigate(-2);
        }}
        textTitle={""}
      />

      <PageContent>
        <FlexColumn>
          <FlexColumn gap={28}>
            <ReactPlaceholder
              ready={btnActive}
              showLoadingAnimation={true}
              type={"text"}
              rows={3}
            >
              <div className={styles.txt20_B01}>
                  <Trans t={t} i18nKey={'{0}는<br />이메일 연동이 필요해요!'} values={{0:ellipseAddress(address, 5)}} />
              </div>
            </ReactPlaceholder>
            <FlexColumn gap={12}>
                <FlexColumn gap={20}>
                  <ReactPlaceholder
                    ready={btnActive}
                    showLoadingAnimation={true}
                    type={"text"}
                    rows={3}
                    style={{ height: "180px" }}
                  >
                    {/*구글로 시작하기*/}
                    <GoogleLogin
                      clientId={googleClientId}
                      responseType={"id_token"}
                      render={(renderProps) => (
                        <SocialButton
                          style={{
                            border: "solid 1px #d4d8e2",
                            backgroundColor: "ffffff",
                            color: "#111111",
                            gap: "4px",
                          }}
                          onClick={()=>{
                              if(check1 ===true && check2 === true){
                                  renderProps.onClick()
                              }else{
                                  ToastError(t("이용약관 및 개인정보처리방침에 동의해주세요."));
                              }
                          }}
                        >
                          <img
                            src={require("../assets/imges/login/iconGoogle.png")}
                            width={20}
                            alt={"gogleIcon"}
                          />
                          <div className={styles.txt16_B01}>{t('구글로 시작하기')}</div>
                        </SocialButton>
                      )}
                      onSuccess={onSuccessGoogle}
                      onFailure={onFailure}
                    />

                    {/*카카오로 시작하기*/}
                    <KakaoLogin
                      token={kakaoClientId ?? ""}
                      onSuccess={onSuccessKaKao}
                      onFail={onFailure}
                      onLogout={() => console.log("logout")}
                      render={(renderProps) => (
                        <SocialButton
                          style={{
                            backgroundColor: "#fee500",
                            color: "#111111",
                            gap: "4px",
                          }}
                          onClick={()=>{
                              if(check1 ===true && check2 === true){
                                  renderProps.onClick()
                              }else{
                                  ToastError(t("이용약관 및 개인정보처리방침에 동의해주세요."));
                              }
                          }}
                        >
                          <img
                            src={require("../assets/imges/login/iconKakao.png")}
                            width={20}
                            alt={"kakaoIcon"}
                          />
                          <div className={styles.txt16_B01}>{t('카카오로 시작하기')}</div>
                        </SocialButton>
                      )}
                    />
                    {/* 이메일로 시작하기 */}
                    <SocialButton
                      style={{
                        border: "solid 1px #d4d8e2",
                        backgroundColor: "ffffff",
                        color: "#111111",
                        gap: "4px",
                      }}
                      onClick={()=>{
                          if(check1 ===true && check2 === true){
                              navigate("/joinEmail")
                          }else{
                            ToastError(t("이용약관 및 개인정보처리방침에 동의해주세요."));
                          }
                      }}
                    >
                      <img
                        src={require("../assets/imges/login/iconEmail.png")}
                        width={22}
                        alt={"emailIcon"}
                      />
                      <div
                        className={styles.txt16_B01}
                        style={{ color: "#3a71ff" }}
                      >
                          {t('이메일로 시작하기')}
                      </div>
                    </SocialButton>
                  </ReactPlaceholder>
                    <FlexColumn>
                        <FlexRowStart className={styles.txt13_B05} gap={4}><img src={ check1 === true ? require('../assets/imges/login/check_on.png') : require('../assets/imges/login/check_off.png')} width={24} onClick={()=>{setCheck1(!check1)}}/><div><span style={{textDecoration:'underline'}} onClick={()=>navigate('/terms')}>이용약관</span>에 동의합니다</div></FlexRowStart>
                        <FlexRowStart className={styles.txt13_B05} gap={4}><img src={ check2 === true ? require('../assets/imges/login/check_on.png') : require('../assets/imges/login/check_off.png')} width={24} onClick={()=>{setCheck2(!check2)}}/><div><span style={{textDecoration:'underline'}} onClick={()=>navigate('/privacy')}>개인정보처리방침</span>에 동의합니다</div></FlexRowStart>
                    </FlexColumn>
                </FlexColumn>
            </FlexColumn>
          </FlexColumn>
        </FlexColumn>
      </PageContent>
    </PageRootContainer>
  );
}
