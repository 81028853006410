import styles from "../../../common/css/zigle.module.scss";
import React, {ReactHTMLElement, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {FC, FlexRowEnd, FlexRowStart, FR} from "./PageContainer";
import BottomButton from "../button/BottomButton";

export const FlexBottomButton = styled(FR)`
  justify-content: space-between;
  align-items: center;
  width: calc(100% + 20 * 2px);
  height: 56px;
  margin-left: -20px;
  margin-top: 20px;
`;


export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background: rgba(0, 0, 0, 0.5);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  flex-direction: column;
  justify-content: flex-end;
`;

export const BottomWrapper = styled.div`
  box-sizing: content-box;
  min-width: 360px;
  max-width: 412px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  animation: fadeInUp 0.5s;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  background-color: white; 
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
    to {
      opacity: 1;
      transform: translateZ(0);
    }
  }
`;

export const BottomTitlte = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding-right: 20px;
`;

export const BottomContent = styled.div`
  background-color: white;
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-bottom: calc(constant(safe-area-inset-bottom) + 25px);
  padding-bottom: calc(env(safe-area-inset-bottom) + 25px);
`;

interface IBottomLayer {
  show: boolean;
  setShow: any;
  content:any
    title?:string;
}

export default function BottomLayer(props: IBottomLayer) {
    return (
        <>
            {
                props.show &&
                (
                    <Overlay>
                        <BottomWrapper>
                            <BottomTitlte>
                                {
                                    props.title && (<FlexRowStart className={styles.txt17_B01} style={{paddingLeft:'20px'}}>{props.title}</FlexRowStart>)
                                }

                                <FlexRowEnd>
                                    <img src={require('../../../assets/icons/close_28.png')} width={28} height={28}
                                     alt={"close"}
                                     onClick={()=>props.setShow(false)}/>
                                </FlexRowEnd>
                            </BottomTitlte>
                            <BottomContent>
                                {props.content}
                            </BottomContent>
                        </BottomWrapper>
                    </Overlay>)
            }
        </>
    );
};
