import React, {useEffect, useRef, useState} from "react";
import styles from "../../common/css/zigle.module.scss";
import {FlexBottom, FlexColumn, FlexRowAround} from "../common/container/PageContainer";
import DefaultInput from "../common/input/DefaultInput";
import KrwSelect from "./KrwSelect";
import UsdSelect from "./UseSelect";
import TokenSelect from "./TokenSelect";
import BottomButton from "../common/button/BottomButton";
import {useForm} from "react-hook-form";
import {ICoinInfo} from "../../common/dto/CoinInfoDto";
import {PayType_Map} from "../../pages/MyPoint";
import {useSelector} from "react-redux";
import MetaMaskService from "../../services/metamask.service";
import PhoneService from "../../services/phone.service";
import {ToastError} from "../common/popup/ToastAlert";
import {useAccount} from "wagmi";
import {useWalletEthersPorvider} from "../../common/js/ethersProviderHook";
import PopupService from "../../services/popup.service";
import {useTranslation} from "react-i18next";
import {useScript} from "../../common/js/hooks";
import PaymentService, {PaymentReqDto, PaymentResDto} from "../../services/payment.service";
import FavService from "../../services/fav.service";


interface IPayDepthProps {
    payInfo:any;
    setPayInfo:any;
}
export default function PayDepth1({payInfo, setPayInfo}:IPayDepthProps) {
    const pgScript = useScript("https://pay.billgate.net/paygate/plugin/gx_web_client.js");
    const [payment, setPayment] = useState<PaymentResDto>({});
    const [serviceCode, setServiceCode] = useState<string>("CREADIT");


    const { t } = useTranslation();

    const inputRef = useRef<HTMLFormElement>(null);
    const [btnActive, setBtnActive] = useState(false);
    const provider = useWalletEthersPorvider();
    
    const chainId = useSelector((state:any) => state.walletReducer.chainId);
    const { address, isConnected } = useAccount();
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const txIdResult =(result:any,
                      resultMessage:any,
                      send_token_amount:any,
                      account:any,
                      to_address:any,
                      mainnetId:any,
                      coinId:any,
                      linkMngId:any) =>{
        if(result=="success")
        {
            payInfo = {
                ...payInfo,
                cryptoInfo: {
                    ...payInfo.cryptoInfo,
                    fromAddr:account,
                    txId: resultMessage
                }
            }
            PhoneService.ziglePointBank(payInfo).then(
                (res) => {
                    setPayInfo(
                        {
                            ...payInfo,
                            depth: 10,
                        }
                    )
                }
            )
        } else {
            PopupService.newPopup(t("확인 필요")
                , t("메타마스크에서 트랜잭션을 승인해 주세요.")
                , ""
                , {
                    btnTxt: t("확인 했어요"),
                    onClick: () => {
                    }
                }
                , {}
            );
        }

    }
    const onSubmit = (data: any) => {
        if(!address){
            ToastError(t("지갑연결을 확인해 주세요."));
            return false;
        }
        if(payInfo.payType === 'K'){
            // setPayInfo(
            //     {...payInfo,
            //         pageTitle: PayType_Map[payInfo.subPayType],
            //         depth : 2,
            //         amount : payInfo.ziglePoint,
            //         cryptoInfo: {
            //             ...payInfo.cryptoInfo,
            //             symbolName:'KRW'
            //         }
            //     }
            // );
            // window.GX_pay( "payment", 'submit', "https_tpay");
            let req : PaymentReqDto = {
                amount:payInfo.ziglePoint
                ,serviceType:payInfo.subPayType
            }
            console.log("======================")
            checksum(req);
        }else if(payInfo.payType === 'T'){
            if(chainId != payInfo.cryptoInfo.chainId)
            {
                MetaMaskService.setSwitchNetwork(provider, parseInt(payInfo.cryptoInfo.chainId), payInfo.cryptoInfo.mainnetName, payInfo.cryptoInfo.symbolName, payInfo.cryptoInfo.decimalVal, payInfo.cryptoInfo.rpcUrl, payInfo.cryptoInfo.explorerUrl, '').then(
                    (response) => {
                        if(!response.status)
                        {
                        }
                    }
                );
            }else{
                MetaMaskService.sendTransferToken(
                    address,
                    payInfo.cryptoInfo.contAddr,
                    payInfo.amount,
                    payInfo.cryptoInfo.toAddr,
                    '',
                    '',
                    '',
                    txIdResult
                );
            }

        }
    };
    const plusBtnOnClick = (plus:number) =>{
        let el = inputRef.current?.point;
        el.value = Number(el.value)+plus;
        setPayInfo(
            {...payInfo,
                ziglePoint : el.value
            }
        );
        el.focus();
    }

    const checksum = async(req: PaymentReqDto) =>{
        const response = await PaymentService.checksum(req);
        if ('SUCCESS' === response.resultCode) {
            setPayment(response.data);
            return;
        }
    }


    useEffect(()=>{
        if (payment.checkSum) {
            //layerpopup, popup , submit
            window.GX_pay( "formPayment", 'submit', "https_pay");
        }
    },[payment.checkSum])




    useEffect(()=>{
        if((payInfo.subPayType) && (Number(payInfo.ziglePoint) >= 1000))
        {
            setBtnActive(true);
        }else{
            setBtnActive(false);
        }
    },[payInfo.subPayType, payInfo.ziglePoint])
    return (
        <>
            <FlexRowAround>
                <div className={ payInfo.payType === 'K' ? styles.topTabActive : styles.topTabDefault} onClick={() => {
                    setPayInfo(
                        {...payInfo,
                            payType : 'K',
                            subPayType:''
                        }
                    );
                }}>
                    {t("KRW 결제")}
                </div>
                <div className={ payInfo.payType === 'P' ? styles.topTabActive : styles.topTabDefault} onClick={() => {
                    // setPayInfo(
                    //     {...payInfo,
                    //         payType : 'P',
                    //         subPayType:''
                    //     }
                    // );
                    ToastError(t("준비중입니다."))
                }}>
                    {t("USD 결제")}
                </div>
                <div className={ payInfo.payType === 'T' ? styles.topTabActive : styles.topTabDefault} onClick={() => {
                    setPayInfo(
                        {...payInfo,
                            payType : 'T',
                            subPayType:''
                        }
                    );
                }}>
                    {t("암호화폐결제")}
                </div>
            </FlexRowAround>
            <div className={styles.line} style={{width: 'calc(100% + 40px)', marginLeft:'-20px'}}/>
            <FlexColumn>
                {/*  사용자 입력 부분*/}
                <FlexColumn style={{ marginBottom: "40px", marginTop: "25px" }}>
                    <form name={"formPayment"} ref={inputRef} onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                        <input type={"hidden"} name={"SERVICE_ID"} value={payment.serviceId??''}/>
                        <input type={"hidden"} name={"SERVICE_CODE"} value={payment.serviceCode??''}/>
                        <input type={"hidden"} name={"SERVICE_TYPE"} value={payment.serviceType??''}/>
                        <input type={"hidden"} name={"ORDER_ID"} value={payment.orderId??''}/>
                        <input type={"hidden"} name={"AMOUNT"} value={payment.amount??''}/>
                        <input type={"hidden"} name={"ORDER_DATE"} value={payment.orderDate??''}/>
                        <input type={"hidden"} name={"RETURN_URL"} value={payment.returnUrl??''}/>
                        <input type={"hidden"} name={"CHECK_SUM"} value={payment.checkSum??''}/>
                        <input type={"hidden"} name={"ITEM_CODE"} value={payment.itemCode??''}/>
                        <input type={"hidden"} name={"ITEM_NAME"} value={payment.itemName??''}/>
                        <input type={"hidden"} name={"USER_ID"} value={payment.userId??''}/>
                        <input type={"hidden"} name={"USER_NAME"} value={payment.userName??''}/>
                        <input type={"hidden"} name={"USER_EMAIL"} value={payment.userEmail??''}/>
                        <input type={"hidden"} name={"RESERVED1"} value={payment.reserved1??''}/>
                        <input type={"hidden"} name={"RESERVED2"} value={payment.reserved2??''}/>
                        <input type={"hidden"} name={"RESERVED3"} value={payment.reserved3??''}/>
                        <input type={"hidden"} name={"CANCEL_FLAG"} value={payment.cancelFlag??''}/>
                        <input type={"hidden"} name={"INSTALLMENT_PERIOD"} value={'0:3:6:9:12'}/>
                        <input type={"hidden"} name={"ESCROW_FLAG"} value={'N'}/>
                        <input type={"hidden"} name={"QUOTA"} value={'1'}/>
                        <DefaultInput
                            lable={t("충전금액")}
                            error={errors.point}
                            icon={require("../../assets/icons/input_x.png")}
                            iconClick={() => {
                                let el = inputRef.current?.point;
                                el.value =0;
                                el.focus()
                            }}
                            inputElement={
                                <input
                                    type={"number"}
                                    className={styles.inputText_44}
                                    placeholder={t("충전 금액을 입력해주세요.")}
                                    {...register("point", {
                                        required: t("충전 금액을 입력해주세요."),
                                        onChange: (el)=>{
                                            setPayInfo(
                                                {...payInfo,
                                                    ziglePoint : el.target.value
                                                }
                                            );
                                        },
                                        onBlur: (el) => {
                                            if (el.target.value >= 1000) {
                                                let val = el.target.value;
                                                val = val - (val % 1000);
                                                el.target.value = val;
                                                setPayInfo(
                                                    {...payInfo,
                                                        ziglePoint : val
                                                    }
                                                );
                                            }
                                        },
                                        validate: (value, formValues) => {
                                            if (value >= 1000) {
                                                return true;
                                            } else {
                                                return t("최소 1,000원 이상 신청해 주세요.");
                                            }
                                        },
                                    })}
                                />
                            }
                        />
                    </form>
                    <FlexRowAround>
                        <div className={styles.textBox} onClick={()=>{
                            plusBtnOnClick(1000);
                        }}>+1천</div>
                        <div className={styles.textBox} onClick={()=>{
                            plusBtnOnClick(3000);
                        }}>+3천</div>
                        <div className={styles.textBox} onClick={()=>{
                            plusBtnOnClick(5000);
                        }}>+5천</div>
                        <div className={styles.textBox} onClick={()=>{
                            plusBtnOnClick(10000);
                        }}>+1만</div>
                        <div className={styles.textBox} onClick={()=>{
                            plusBtnOnClick(30000);
                        }}>+3만</div>
                    </FlexRowAround>
                </FlexColumn>

                <FlexColumn gap={16}>
                    <div className={styles.txt20_500_B01}>
                        {t("충전 방식을 선택해 주세요")}
                    </div>
                    { payInfo.payType === 'K' && (<KrwSelect payInfo={payInfo} setPayInfo={setPayInfo} />)}
                    { payInfo.payType === 'P' && (<UsdSelect  payInfo={payInfo} setPayInfo={setPayInfo}/>)}
                    { payInfo.payType === 'T' && (<TokenSelect payInfo={payInfo} setPayInfo={setPayInfo}/>)}
                </FlexColumn>
                <FlexColumn gap={2}  className={styles.txt12_B04} style={{padding:'20px', marginTop: "30px", borderRadius:'16px', lineHeight:'22px', backgroundColor:'#f2f4f6'}}>
                    <div>{t("(주)두다홀딩스")}</div>
                    <div>{t("주소 : 서울특별시 강남구 봉은사로 418, 5층(삼성동, 조양빌딩)")}<br/>
                        {t("사업자 등록번호 : 222-87-01653")}<br/>
                        {t("대표자 : 유석현")}<br/>
                        {t("고객센터 : 02-6267-9004")}</div>
                </FlexColumn>
            </FlexColumn>

            {/*버튼영역.*/}
            <FlexBottom>

                <BottomButton
                    isActive={btnActive}
                    backgroundColor={"#3a71ff"}
                    textColor={"#ffffff"}
                    text={t("다음")}
                    onClick={handleSubmit(onSubmit)}
                />
            </FlexBottom>
        </>
    )
}