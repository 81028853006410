import axios from "axios";
import {
  IFcmTokenReqDto,
  IProfilePassReqDto,
  IProfileWalletReqDto,
} from "../common/dto/request/ProfileDto";
import { checkStatus401, checkStatus401RstType } from "./common.service";
import {IUserToken} from "../common/dto/CommonDto";
import {ToastError} from "../components/common/popup/ToastAlert";
import PopupService from "./popup.service";

let API_URL: String = "";
if (process.env.REACT_APP_ENVTEXT === "local") {
  API_URL = "/api";
} else {
  API_URL = process.env.REACT_APP_API_URL ?? "https://api.zigle.link/api";
}
const userInfo = () => {
  return axios
    .get(API_URL + "/v1/user/profile/userInfo", {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatus401(error.response);
    });
};
const userDetail = async () => {
  return await axios
    .get(API_URL + "/v1/user/profile/detail", {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatus401(error.response);
    });
};
const passwdChange = (params: IProfilePassReqDto) => {
  return axios
    .post(API_URL + "/v1/user/profile/passwd", params, {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return { status: true, msg: "" };
    })
    .catch((error) => {
      return checkStatus401RstType(error.response);
    });
};
const walletNameChange = (params: IProfileWalletReqDto) => {
  return axios
    .post(API_URL + "/v1/user/profile/walletname", params, {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatus401(error.response);
    });
};

const doNameChange = (doname: string) => {
  const params = new URLSearchParams();
  params.append("doname", doname);
  return axios
    .post(API_URL + "/v1/user/profile/doname", params, {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatus401(error.response);
    });
};
const introduceChange = (introduce: string) => {
  const params = new URLSearchParams();
  params.append("introduce", introduce);
  return axios
    .post(API_URL + "/v1/user/profile/introduce", params, {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatus401(error.response);
    });
};

const walletList = () => {
  return axios
    .get(API_URL + "/v1/user/profile/walletlist", {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatus401(error.response);
    });
};

const isDoName = (doname: string) => {
  return axios
    .get(API_URL + "/v1/user/profile/isdoname/?doname=" + doname, {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatus401(error.response);
    });
};
const photoChange = (selectedFile: any) => {
  let formData = new FormData();
  formData.append("file", selectedFile);

  return axios
    .post(API_URL + "/v1/user/profile/photo", formData, {
      headers: {
        ...getHeader(),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      checkStatus401(error.response);
    });
};
const setPushToken = (params: IFcmTokenReqDto) => {
  return axios
    .post(API_URL + "/v1/user/profile/setToken", params, {
      headers: {
        ...getHeader(),
      },
    })
    .then((response) => {
      return response.data;
    }).catch((error) => {
          checkStatus401(error.response);
      });
};

export const getHeader = () => {
  return {
    Authorization: localStorage.getItem("accessToken") ?? "",
    "REFRESH-TOKEN": localStorage.getItem("refreshToken") ?? "",
  };
};

const insertWalletLogin = (address: string) => {
  let params = new FormData();
  params.append("address", address);
  return axios
      .post(API_URL + "/v1/user/profile/insertWalletLogin", params, {
        headers: {
          ...getHeader(),
        },
      })
      .then((response) => {
        return { status: true, msg: "" };
      })
      .catch((error) => {
          PopupService.newPopup("다시 로그인 해 주세요.", error.response.data.message,  "",{btnTxt:"확인", onClick: () => {
                  ProfileService.logout();
                  window.location.href = "/walletLogin";
              }}, {})

      });
};
const memberQuit = ()=>{
  return axios
      .post(API_URL + "/v1/user/profile/quitKey", "", {
        headers: {
          ...getHeader(),
        },
      })
      .then((response) => {
        return { status: true, msg: "" };
      })
      .catch((error) => {
        return checkStatus401RstType(error.response);
      });
}
const quitCertification = (authKey: any)=>{
    return axios
        .get(API_URL + "/v1/user/profile/quitCertification", {
            headers: {
                ...getHeader(),
            },
            params: {
                authKey: authKey,
            },
        })
        .then((response) => {
            return { status: true, msg: "" };
        })
        .catch((err) => {
            if (err.response.data.failStack && err.response.data.failStack[0]) {
            }
            return { status: false, msg: "인증정보가 잘못됐습니다." };
        });
}
const refList = () => {
    return axios
        .get(API_URL + "/v1/user/profile/refList", {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            checkStatus401(error.response);
        });
};
const updateUpRefCode = (upRefCode: string) => {
    let params = new FormData();
    params.append("upRefCode", upRefCode);
    return axios
        .post(API_URL + "/v1/user/profile/updateUpRefCode", params, {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return { status: true, msg: "" };
        })
        .catch((error) => {
            return { status: false, msg: error.response.data.message };
        });
};
const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
};
const mainAddress = (walletAddress: string) => {
    return axios
        .get(API_URL + "/v1/user/profile/mainAddress/" + walletAddress, {
            headers: {
                ...getHeader(),
            },
        })
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            checkStatus401(error.response);
        });
};
const ProfileService = {
  insertWalletLogin,
  userInfo,
  userDetail,
  passwdChange,
  doNameChange,
  introduceChange,
  walletList,
  walletNameChange,
  isDoName,
  photoChange,
  logout,
  setPushToken,
  memberQuit,
    quitCertification,
    refList,
    updateUpRefCode,
    mainAddress,
};

export default ProfileService;
