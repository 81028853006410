import React, { useEffect, useState } from "react";
import styles from "../../common/css/zigle.module.scss";
import {FlexColumn} from "../common/container/PageContainer";

import GuideImg1 from "../../assets/imges/metamask/metamask_guide3_1.png";
import GuideImg2 from "../../assets/imges/metamask/metamask_guide3_2.png";
import {Trans, useTranslation} from "react-i18next";

export default function Guide3() {
    const { t } = useTranslation();
    return (
        <FlexColumn gap={24}>
            <FlexColumn gap={8}>
                <div className={styles.txt18_500}>Optional consent</div>
                <div className={styles.txt14_B05} style={{lineHeight:'1.71'}}>
                    <Trans t={t} i18nKey={"메타마스크 개선에 도움이 되는 데이터 수집을 허용 하려면<br/>I Agree 버튼을 클릭하고 그렇지 않으면<br/> No Thank 버튼을 클릭합니다<br/>사용자가 No Thank 버튼을 클릭하더라도 지갑은 계속 생성될 수 있습니다."}/>
                </div>
                <div><img src={GuideImg1} alt="Guide Img" style={{width:'100%'}}/></div>
            </FlexColumn>
            <FlexColumn gap={8}>
                <div className={styles.txt18_500}>Set wallet password</div>
                <div className={styles.txt14_B05} style={{lineHeight:'1.71'}}>
                    <Trans t={t} i18nKey={"지갑의 비밀번호를 만듭니다.<br/>MetaMask를 사용하려고 할 때마다 입력해야 합니다.<br/>크롬이 제거되거나 브라우저가 전환되는 경우 새 비밀번호를 생성해야 합니다.<br/>이 경우 지갑 가져오기 버튼으로 이동합니다."}/>
                </div>
                <div><img src={GuideImg2} alt="Guide Img" style={{width:'100%'}}/></div>
            </FlexColumn>
        </FlexColumn>
    )
}