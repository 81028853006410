import {
    FlexColumEnd,
    FlexColumn,
    FlexColumnCenter,
    FlexRowAround,
    FlexRowSpace,
    FlexRowStart
} from "../common/container/PageContainer";
import { ethers } from "ethers";
import styles from "../../common/css/zigle.module.scss";
import ProgressBar from "./ProgressBar";
import React, {useEffect, useRef, useState} from "react";
import {ICoinInfo} from "../../common/dto/CoinInfoDto";
import CommonService from "../../services/common.service";
import {IAirdropFeeResDto} from "../../common/dto/AirdropList";
import bigDecimal from "js-big-decimal";
import {useSelector} from "react-redux";
import {decimalFormat} from "../../common/js/utilities";
import {useTranslation} from "react-i18next";

interface IAirdropFee  {
    handleOnClick?: (popupType:string, item: IAirdropFeeResDto)=> void;
    itemDetail:any;
}
export default function AirdropFeeItem (props:IAirdropFee){
    const { t } = useTranslation();
    const {itemDetail} = props;
    const [gasFee, setGasFee] = useState("0");


    const amount = useRef('');
    useEffect(()=>{
        async function fetchData() {
            //0.000000003
            let rpcurl = itemDetail.mainnetInfo.rpcUrl;
            if(itemDetail.mainnetInfo.chainId == "1")
            {
                rpcurl += process.env.REACT_APP_INFURA_KEY
            }
            const provider = new ethers.providers.JsonRpcProvider(
                rpcurl
            );
            const gas = await provider.getGasPrice();
            const tmpGas = new bigDecimal(ethers.utils.formatUnits(gas, itemDetail.mainnetInfo.decimalVal)).multiply(new bigDecimal(21000));
            setGasFee(tmpGas.getValue());
        }
        fetchData();
        const tmpAmount = new bigDecimal(itemDetail.balance).subtract(new bigDecimal(itemDetail.balanceLock));
        amount.current = tmpAmount.getValue();
    },[itemDetail])
    return (
        <>
            <FlexColumn>
                <FlexColumn className={styles.airdropFeeTop} gap={16}>
                    <FlexRowSpace>
                        <FlexRowStart>
                            <div style={{paddingRight:'12px'}}>
                                <img src={itemDetail.mainnetInfo.symbolImgFileId} alt="bgIcon" width={38} />
                            </div>
                            <FlexColumn>
                                <div className={styles.txt14_B01}>{itemDetail.mainnetInfo.displayName}</div>
                                <div className={styles.txt12_B05}>{t("수수료")}</div>
                            </FlexColumn>
                        </FlexRowStart>
                        <FlexColumEnd>
                            <div className={styles.txt12_B05}>
                                {t("보유수량")}
                            </div>
                            <div className={styles.txt16_B01}>
                                {decimalFormat(amount.current,4)}<span style={{paddingLeft:'2px'}} className={styles.txt13_B05}>{itemDetail.mainnetInfo.symbolName}</span>
                            </div>
                        </FlexColumEnd>
                    </FlexRowSpace>
                    <FlexColumnCenter className={styles.airdrop_now_fee}>
                        {t("현재 평균 수수료")} {decimalFormat(gasFee, 13)} {itemDetail.mainnetInfo.symbolName}
                    </FlexColumnCenter>
                </FlexColumn>
                <FlexRowAround className={styles.airdropFeeBottom}>
                    <FlexColumnCenter onClick={()=>{
                        if(props.handleOnClick){
                            props.handleOnClick("his", itemDetail);
                        }
                    }} style={{width:'33%'}}>{t("이용내역")}</FlexColumnCenter>
                    <div style={{width:'1px', height:'100%', alignSelf:'stretch', flexGrow:'0', backgroundColor:'#e7e9ed'}}></div>
                    <FlexColumnCenter style={{width:'33%'}} onClick={()=>{
                        if(props.handleOnClick){
                            props.handleOnClick("in", itemDetail);
                        }
                    }} >{t("입금")}</FlexColumnCenter>
                    <div style={{width:'1px', height:'100%', alignSelf:'stretch', flexGrow:'0', backgroundColor:'#e7e9ed'}}></div>
                    <FlexColumnCenter style={{width:'33%'}} onClick={()=>{
                        if(props.handleOnClick){
                            props.handleOnClick("out", itemDetail);
                        }
                    }} >{t("출금")}</FlexColumnCenter>
                </FlexRowAround>
            </FlexColumn>
        </>
    )
}