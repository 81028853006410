import React from "react";
import {FlexColumEnd, FlexColumn, FlexRowAround} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import CommonService from "../../services/common.service";
import Button34 from "../common/button/Button34";
import CopyToClipboard from "react-copy-to-clipboard";
import {RST_MAP} from "./SndHis";
import {Toast} from "../common/popup/ToastAlert";
import {decimalFormat, ellipseAddress} from "../../common/js/utilities";
import {useTranslation} from "react-i18next";
interface ISndHisItem {
    index:number;
    item:any;
    setUrlKey:(p:string)=>void
    setShowPop:(p:boolean)=>void
    setDetail:(p:any)=>void
    monthResult:any;
}
export default function SndHisItem(props:ISndHisItem) {
    const { t } = useTranslation();
    return (
        <FlexColumn gap={16}>
        {
            props.monthResult ==="Y" &&
            (
                props.index !==0 ?
                    (
                        <FlexColumn gap={24}>
                            <FlexColumn className={styles.line}></FlexColumn>
                            <FlexColumn className={styles.txt13_B04}>{new Date(props.item.datetime).getMonth()+1 +"월 "+new Date(props.item.datetime).getDate()+"일"}</FlexColumn>
                        </FlexColumn>
                    )
                    :
                    (<FlexColumn className={styles.txt13_B04}>{new Date(props.item.datetime).getMonth()+1 +"월 "+new Date(props.item.datetime).getDate()+"일"}</FlexColumn>)
            )
        }
            <FlexRowAround>
                <FlexColumn gap={5}>
                    <div className={styles.txt16_500_B02}>
                        {props.item.type === 'TR' ? t("안전송금") : t("전화번호송금")}
                    </div>
                    <div className={styles.txt14_B01}>
                        {
                            props.item.type === 'HP' ? props.item.receiveUser : ellipseAddress(props.item.receiveUser, 4)
                        }
                        {props.item.receiveUserNm && (<span>({props.item.receiveUserNm})</span>)}
                    </div>
                    <div className={styles.txt12_B05}>{props.item.datetime}</div>
                </FlexColumn>

                <FlexColumEnd gap={5}>
                    <div className={styles.txt18_500}>
                        -{decimalFormat(props.item.amount)}
                        <span style={{marginLeft: "4px"}}>{props.item.symbolName}</span>
                    </div>
                    {
                        (
                            () => {
                                switch (props.item.rstCode) {
                                    case "SM":
                                        return <Button34 text={t("송금취소")}
                                                         type={"white"}
                                                         onClick={() => {
                                                             props.setUrlKey(props.item.linkUrl)
                                                             props.setShowPop(true)
                                                             props.setDetail(props.item)
                                                         }}
                                        />
                                    case "DW":
                                        return <Button34 text={t("최종승인")}
                                                         type={"black"}
                                                         onClick={() => {
                                                             props.setUrlKey(props.item.linkUrl)
                                                             props.setShowPop(true)
                                                             props.setDetail(props.item)
                                                         }}
                                        />
                                    default:
                                        return (
                                            <>
                                                <div className={styles.txt12_B05}
                                                     onClick={() => {
                                                         props.setUrlKey(props.item.linkUrl)
                                                         props.setShowPop(true)
                                                         props.setDetail(props.item)
                                                     }}
                                                     style={{textDecoration:'Underline'}}
                                                >
                                                    {
                                                        (props.item.rstCode ==="CF" || props.item.rstCode ==="SN" || props.item.rstCode ==="DF" || props.item.rstCode ==="FF") && <img src={require('../../assets/icons/error.png')} width={20}/>
                                                    }
                                                    {t(RST_MAP[props.item.rstCode])}
                                                </div>
                                            </>
                                        )
                                }

                            }
                        )()
                    }

                </FlexColumEnd>
            </FlexRowAround>
        </FlexColumn>
    );
};