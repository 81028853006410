import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FlexColumEnd, FlexColumn, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import React, {useEffect, useState} from "react";
import moment from "moment";
import {decimalFormat} from "../../common/js/utilities";
import PopupService from "../../services/popup.service";
import {useSelector} from "react-redux";


export default function ChatAllListItem (props:{pageType:string, talkPlus:any, item:any, listCnt:number, listBool:boolean}) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const doname: string = useSelector((state: any) => state.userReduce.doname);
    const [chatView, setChatView] = useState<any>({
        channel:{

        }
    });
    const handleImgError = (e:any) => {
        e.target.src = "https://s3.ap-northeast-2.amazonaws.com/bucket.zigle.io/profiles/profile"+(Number(props.item.userId) % 9)+".png";
    }

    const getMyChannel = async(channelId: string)=>{

        const resMyChannel = await props.talkPlus.getChannel({channelId: channelId});
        console.log("resMyChannel",resMyChannel)

        setChatView(resMyChannel);
    }
    useEffect(()=>{
        console.log(props.listBool);

        getMyChannel(props.item.chatId);
    },[props.item]);
    return (
        <FlexColumn onClick={()=>{
            console.log("doname",doname);
            if(doname){
                console.log("doname navigate")
                navigate("/chat/"+chatView.channel.name);
            }else{
                PopupService.newPopup(t("닉네임없음")
                    ,t("닉네임을 입력하셔야 사용 가능합니다.")
                    , ""
                    ,{btnTxt:t("확인"),
                        onClick: () => {
                            navigate("/modifyProfile");
                        }}
                    , {
                        btnTxt:"",
                        onClick: () => {}
                    }
                );
            }
        }}>
            <FlexColumn style={ props.listBool === true ? {background:'#F8F9FC', width: 'calc(100% + 40px)', marginLeft:'-20px', padding:'12px 16px'} : {width: 'calc(100% + 40px)', marginLeft:'-20px', padding:'12px 16px'}}>
                <FlexRowSpace>
                    <FlexRowStart gap={14} style={{width:'70%'}}>
                        <div className={styles.txt15_PointB}>{props.listCnt}</div>
                        <img
                            src={chatView.channel.imageUrl}
                            onError={handleImgError}
                            width={48}
                            alt={"profile image"}
                            style={{borderRadius:'48px', aspectRatio:'1', objectFit:'cover'}}
                        />
                        <FlexColumn>
                            <FlexRowStart className={styles.txt16_B02} gap={4}>
                                {chatView.channel.name} {Number(props.item.key) > 0 && (<div className={styles.txt12_BFC7DC}><img src={require('../../assets/imges/chat/key.png')} width={12} /> {props.item.key} </div>) }
                            </FlexRowStart>
                            <div className={styles.txt12_B05}>
                                {props.item.userCnt}{t("명")}
                            </div>
                        </FlexColumn>
                    </FlexRowStart>
                    <FlexRowEnd className={styles.txt14_B03} style={{width:'30%'}}>
                        {decimalFormat(props.item.ziglePoint)} ZIG
                    </FlexRowEnd>
                </FlexRowSpace>
            </FlexColumn>
        </FlexColumn>
    )
}