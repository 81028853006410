import React, { useEffect, useState } from "react";
import styled from "styled-components";
import styles from "../../common/css/zigle.module.scss";
import {FlexColumn} from "./container/PageContainer";
import {Trans, useTranslation} from "react-i18next";

interface IEmptyProps {
    titleText?: string;
    titleImage?: string;
}
export default function CryptoItem(props: IEmptyProps) {
    const { t } = useTranslation();
    return (
        <FlexColumn style={{alignItems:'center', paddingTop:'93px'}}>
            {props.titleImage && (
                <div><img src={props.titleImage} height={100}/></div>
            )}
            <div className={styles.txt14_B05} style={{paddingTop:'14px', textAlign:'center'}}>
                <Trans t={t} i18nKey={props.titleText} />
            </div>
        </FlexColumn>
    )
}