import {FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import CommonService from "../../services/common.service";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {ethers} from "ethers";
import {register} from "../../serviceWorkerRegistration";
import {useForm} from "react-hook-form";
import DefaultInput from "../common/input/DefaultInput";
import MetaMaskService from "../../services/metamask.service";
import {ellipseAddress} from "../../common/js/utilities";
import {useTranslation} from "react-i18next";
interface IAirdropUserItem{
    airdropInfo:any,
    setAirdropInfo:any,
    itemDetail:any,
    onClickHandle: (type:string, tmpId:string, changeId:string, changeDel:string, checkYn:string)=> void;
}

export default function AirdropUserItem({airdropInfo, setAirdropInfo, itemDetail, onClickHandle}: IAirdropUserItem){
    const { t } = useTranslation();
    const [userWallet, setUserWallet] = useState(itemDetail.usrId);
    const [userChange, setUserChange] = useState(false);
    const inputRef = useRef<HTMLFormElement>(null);
    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
    } = useForm();


    const onCheckHandle = async() => {
        const findIndex = airdropInfo.airDropList.findIndex((element: { usrId: any; })=> element.usrId === userWallet.replace(/\n/g, "").replace(/^\s+|\s+$/g, ""));

        if(findIndex != -1) {
            setError("changeId", {type:'custom', message:t('이미 등록된 값입니다.')});
            return false;
        }else{

            const checkResult = await MetaMaskService.transUserCheck(userWallet.replace(/\n/g, "").replace(/^\s+|\s+$/g, ""), airdropInfo.airdropSubType);
            if(checkResult === "Y")
            {
                setAirdropInfo({
                    ...airdropInfo,
                    failCnt: airdropInfo.failCnt--
                })
                onClickHandle('idChange', itemDetail.usrId, userWallet, '', checkResult);
            }else{
                if (airdropInfo.airdropSubType === "wallet") {
                    setError("changeId", {type:'custom', message:t('유효한 주소가 아닙니다.')});
                    return false;
                }else{
                    setError("changeId", {type:'custom', message:t('없는 사용자입니다.')});
                    return false;
                }
            }
        }
    }
    return (
        <>
            {
                itemDetail.delYn === "N" ? (
                     itemDetail.checkYn ==="N" ? (
                        <FlexRowSpace gap={8}>
                            <FlexRowStart className={styles.select_mainnet} style={itemDetail.delYn ==="N" ? {backgroundColor:'#F2F4F7'} : {backgroundColor:'#ffffff'}}>
                                <FlexRowStart gap={4}>
                                    {ellipseAddress(userWallet, 6)}
                                </FlexRowStart>
                            </FlexRowStart>
                            <img src={require('../../assets/imges/airdrop/airdrop_re.png')} width={28} onClick={()=>{
                                setAirdropInfo({
                                    ...airdropInfo,
                                    failCnt: airdropInfo.failCnt++
                                })
                                onClickHandle('ynChange',itemDetail.usrId,'','Y', '');
                            }}/>
                        </FlexRowSpace>
                    )
                    :
                    (
                        <FlexRowSpace gap={8}>
                            <FlexRowStart className={styles.select_mainnet} style={itemDetail.delYn ==="N" ? {backgroundColor:'#F2F4F7'} : {backgroundColor:'#ffffff'}}>
                                <FlexRowStart gap={4}>
                                    {ellipseAddress(userWallet, 6)}
                                </FlexRowStart>
                            </FlexRowStart>
                            <img src={require('../../assets/imges/airdrop/airdrop_re.png')} width={28} onClick={()=>{
                                onClickHandle('ynChange',itemDetail.usrId,'','Y', '');
                            }}/>
                        </FlexRowSpace>
                    )
                )
                :
                (
                    itemDetail.checkYn ==="N" ? (
                        <>
                            <FlexRowSpace gap={8}>
                                <FlexRowStart className={styles.select_mainnet} style={itemDetail.delYn ==="N" ? {backgroundColor:'#F2F4F7'} : {backgroundColor:'#ffffff'}}>
                                    {
                                        !userChange ? (
                                            <>
                                                <FlexRowStart gap={4}>
                                                    {ellipseAddress(userWallet, 6)}
                                                    <div className={styles.mainCryptoBadge}
                                                         style={{background: '#FFEBED', padding:'6px 10px', fontSize: '11px', color: '#F01D1D'}}>{t("확인실패")}
                                                    </div>
                                                </FlexRowStart>
                                                <FlexRowEnd className={styles.copy} style={{width: '66px',backgroundColor: '#ffffff',padding: '10px 16px'}}
                                                            onClick={()=>{setUserChange(true)}}
                                                >{t("수정")}</FlexRowEnd>
                                            </>
                                        ) : (
                                            <>
                                                <FlexRowStart gap={4}>
                                                    <input
                                                        type={"text"}
                                                        className={styles.inputText_33}
                                                        placeholder={t("입력")}
                                                        value={userWallet}
                                                        {...register("changeId", {
                                                            required: t("입력"),
                                                            onChange: (el) => {
                                                                clearErrors("changeId");
                                                                setUserWallet(el.target.value);
                                                            }
                                                        })}
                                                    />
                                                </FlexRowStart>
                                                <FlexRowEnd className={styles.copy} style={{width: '66px', color:'#3A71FF', backgroundColor: '#EBF2FF',padding: '10px 16px'}}
                                                    onClick={()=>{
                                                        onCheckHandle();
                                                    }}
                                                >{t("확인")}</FlexRowEnd>
                                            </>
                                        )
                                    }

                                </FlexRowStart>
                                <img src={require('../../assets/imges/airdrop/airdrop_x.png')} width={28} onClick={()=>{
                                    setAirdropInfo({
                                        ...airdropInfo,
                                        failCnt: airdropInfo.failCnt--
                                    })
                                    onClickHandle('ynChange',itemDetail.usrId,'','N', '');
                                }}/>
                            </FlexRowSpace>
                            {errors.changeId && (
                                    <div
                                        className={styles.txt13_red}
                                        style={{ marginTop: "4px", marginBottom: "4px" }}
                                    >
                                        {errors.changeId.message?.toString()}
                                    </div>
                                )}
                        </>
                        )
                        :
                        (
                            <FlexRowSpace gap={8}>
                                <FlexRowStart className={styles.select_mainnet} style={itemDetail.delYn ==="N" ? {backgroundColor:'#F2F4F7'} : {backgroundColor:'#ffffff'}}>
                                    <FlexRowStart gap={4}>
                                        {ellipseAddress(userWallet, 6)}
                                    </FlexRowStart>
                                </FlexRowStart>
                                <img src={require('../../assets/imges/airdrop/airdrop_x.png')} width={28} onClick={()=>{
                                    onClickHandle('ynChange',itemDetail.usrId,'','N', '');
                                }}/>
                            </FlexRowSpace>
                        )
                )
            }
        </>
    )
}