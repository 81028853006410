import React, {useRef, useState} from "react";
import {useForm} from "react-hook-form";
import styles from "../../common/css/zigle.module.scss";
import {FlexBottom, FlexColumn, FlexRowSpace} from "../common/container/PageContainer";
import PopupService from "../../services/popup.service";
import CopyToClipboard from "react-copy-to-clipboard";
import BottomButton from "../common/button/BottomButton";
import {useSelector} from "react-redux";
import CommonService from "../../services/common.service";
import {Toast} from "../common/popup/ToastAlert";
import {ellipseAddress} from "../../common/js/utilities";
import {Trans, useTranslation} from "react-i18next";

interface IPayDepthProps {
    payInfo:any;
    setPayInfo:any;
    reset?: ()=> void;
}
export default function PayDepthCryptoEnd({payInfo, setPayInfo, reset}:IPayDepthProps) {
    const { t } = useTranslation();

    const ziglePoint = useSelector((state: any) => state.userReduce.ziglePoint);

    return (
        <FlexColumn gap={28}>
            <FlexColumn gap={2} className={styles.txt20_B01}>
                <Trans t={t} className={styles.airdropFiEnd} i18nKey={"포인트 충전 완료를 위해<br/><span>입금 확인중</span> 입니다."}/>
            </FlexColumn>
            <FlexColumn>
                <div className={styles.txt14_B04}>{t("출금지갑")}</div>
                <FlexRowSpace>
                    <div  className={styles.txt13_B01} style={{width:'80%',padding:'10px 0px', borderBottom: 'solid 1px #f2f4f8'}}>
                        <span className={styles.txt16_B01}>{ellipseAddress(payInfo.cryptoInfo.fromAddr, 12)}</span>
                    </div>
                </FlexRowSpace>
            </FlexColumn>
            <FlexColumn>
                <div className={styles.txt14_B04}>TXID</div>
                <FlexRowSpace>
                    <div className={styles.txt16_B01} style={{width:'80%', padding:'10px 0px', borderBottom: 'solid 1px #f2f4f8'}}>
                        {ellipseAddress(payInfo.cryptoInfo.txId, 14)}
                    </div>
                    <div style={{width:'20%'}}>
                        <CopyToClipboard text={payInfo.cryptoInfo.txId}
                                         onCopy={() => Toast(t("복사 하였습니다."))}>
                            <span className={styles.copy}>{t("복사")}</span>
                        </CopyToClipboard>
                    </div>
                </FlexRowSpace>
            </FlexColumn>
            <FlexColumn style={{backgroundColor:'#f2f4f8', height:'16px'}}></FlexColumn>
            <FlexColumn className={styles.txt14_B03} gap={16}>
                <FlexRowSpace>
                    <div>{t("충전 전")}</div>
                    <div>{ziglePoint} P</div>
                </FlexRowSpace>
                <FlexRowSpace>
                    <div>{t("충전 예정")}</div>
                    <div>{payInfo.ziglePoint} P</div>
                </FlexRowSpace>
            </FlexColumn>
            <div className={styles.line}></div>
            <FlexRowSpace>
                <div className={styles.txt16_500_B01}>{t("충전 후")}</div>
                <div className={styles.txt16_500_PointB}>{ziglePoint + payInfo.ziglePoint} P</div>
            </FlexRowSpace>
            <FlexColumn className={styles.txt12_B05} style={{padding:'20px', backgroundColor:'#f2f4f6'}}>
                <Trans t={t} i18nKey={"네트워크 상환에 따라 다소 시간이 걸릴 수 있습니다.<br />[포인트내역]에서 해당 결제에 대한 정보를 관리 하실 수 있습니다.<br />입금확인 시 충전이 완료됩니다.<br />"}/>
            </FlexColumn>
            <FlexBottom>
                <BottomButton
                    isActive={true}
                    backgroundColor={"#f2f4f6"}
                    textColor={"#3a71ff"}
                    text={t("추가 충전하기")}
                    onClick={() => {
                        if(reset){
                            reset();
                        }
                    }}
                />
                <BottomButton
                    isActive={true}
                    backgroundColor={"#3a71ff"}
                    textColor={"#ffffff"}
                    text={t("내역 바로가기")}
                    onClick={() => {

                    }}
                />
            </FlexBottom>
        </FlexColumn>
    )
}