import axios from "axios";
import { AnonyToken, IUserToken } from "../common/dto/CommonDto";
import {
  EmailJoinReqDto,
  ILoginReqDto,
} from "../common/dto/request/UserReqDto";
import {checkStatus401} from "./common.service";
import {getHeader} from "./profile.service";

let API_URL = "";
if (process.env.REACT_APP_ENVTEXT === "local") {
  API_URL = "/api";
  // API_URL = process.env.REACT_APP_API_URL;
} else {
  API_URL = process.env.REACT_APP_API_URL ?? "https://api.zigle.link/api";
}

interface AxiosRst {
  status: boolean;
  msg: string;
}

const login = ( email: string, passwd: string) => {
  const params = new FormData();
  params.set("email", email);
  params.set("passwd", passwd);
  return axios
    .post(API_URL + "/v1/anony/login", params)
    .then((response) => {
      if (response.data.data.userId) {
        let userInfo: IUserToken = setTokenUser(response.data.data, "email");
        localStorage.setItem(
          "accessToken",
          "Bearer " + response.data.data.token
        );
        localStorage.setItem(
          "refreshToken",
          "Bearer " + response.data.data.token
        );
        localStorage.setItem("user", JSON.stringify(userInfo));
        const rst: AxiosRst = {
          status: true,
          msg: "",
        };
        return rst;
      }
    })
    .catch((err) => {
      checkStatus401(err.response);
    });
};
const oauthLogin = (
  socialCode: string,
  email: string,
  profileId: string,
  tokenId: string,
  walletAddress: string
): void => {
  const params = new URLSearchParams();
  params.append("socialCode", socialCode);
  params.append("email", email);
  params.append("profileId", profileId);
  params.append("tokenId", tokenId);
  params.append("walletAddress", walletAddress);
  if(localStorage.getItem("refCode")){
    params.append("refCode",localStorage.getItem("refCode") ?? "");
  }

  axios
    .post(API_URL + "/v1/anony/callback", params)
    .then((response) => {
      if (response.data.data.userId) {
        let userInfo: IUserToken = setTokenUser(response.data.data, socialCode);
        localStorage.setItem(
          "accessToken",
          "Bearer " + response.data.data.token
        );
        localStorage.setItem(
          "refreshToken",
          "Bearer " + response.data.data.token
        );
        localStorage.setItem("user", JSON.stringify(userInfo));
        if(localStorage.getItem("refCode")){
          localStorage.removeItem("refCode");
        }

        if (sessionStorage.getItem("redirectTo")) {
          const redirectTo = sessionStorage.getItem("redirectTo");
          sessionStorage.setItem("redirectTo", "");
          document.location.href = redirectTo!;
        } else {
          document.location.href = "/";
        }
      }
    })
    .catch((err) => {
      console.log(err);
      checkStatus401(err.response);
    });
  // return 1;
};

const authKeyMail = (  email: string, passwd: string) => {
  const params = new FormData();
  params.set("email", email);
  params.set("passwd", passwd);
  if(localStorage.getItem("refCode")){
    params.append("refCode",localStorage.getItem("refCode") ?? "");
  }
  return axios
    .post(API_URL + "/v1/anony/authKey", params)
    .then((response) => {
      const rst: AxiosRst = {
        status: true,
        msg: "",
      };
      return rst;
    })
    .catch((err) => {
      console.log(err);
      let msg = "";
      if (err.response.data.failStack && err.response.data.failStack[0]) {
        msg = err.response.data.failStack[0].error;
      }
      const rst: AxiosRst = {
        status: false,
        msg: msg,
      };
      return rst;
    });
};
const certification = (authKey: any) => {
  return axios
    .get(API_URL + "/v1/anony/certification", {
      params: {
        authKey: authKey,
      },
    })
    .then((response) => {
      if (response.data.data.userId) {
        let userInfo: IUserToken = setTokenUser(response.data.data, "email");

        if(localStorage.getItem("refCode")){
          localStorage.removeItem("refCode");
        }

        localStorage.setItem(
          "accessToken",
          "Bearer " + response.data.data.token
        );
        localStorage.setItem(
          "refreshToken",
          "Bearer " + response.data.data.token
        );
        localStorage.setItem("user", JSON.stringify(userInfo));

        return { status: true, msg: "" };
      }
    })
    .catch((err) => {
      if (err.response.data.failStack && err.response.data.failStack[0]) {
      }
      return { status: false, msg: "인증정보가 잘못됐습니다." };
    });
};
const getCurrentUser = (): IUserToken => {
  let user: string = localStorage.getItem("user") ?? "";
  if (user === "") {
    user = JSON.stringify(AnonyToken);
  }
  return JSON.parse(user);
};

const setTokenUser = (data: any, socialCode: string): IUserToken => {
  let userInfo: IUserToken = AnonyToken;
  userInfo.timestamp = new Date().getTime() + 1000 * 60 * 60 * 24 * 30;
  userInfo.userId = data.userId;
  userInfo.email = data.email;
  userInfo.userLevel = data.userLevel;
  userInfo.accessToken = data.token;
  userInfo.refreshToken = data.token;
  userInfo.socialCode = socialCode;
  return userInfo;
};

export interface IWalletLogin {
  email: string;
  socialCode: string;
  userId: string;
  walletAddress: string;
  walletName: string;
}

const checkJoinByAddress = (address: string) => {
  return axios
    .get(API_URL + "/v1/anony/checkJoinByAddress", {
      params: {
        address: address,
      },
    })
    .then((response) => {
      let data = response.data;
      if (data.data) {
        let rst: IWalletLogin = data.data;
        return rst;
      }
    })
    .catch((err) => {
      return null;
    });
};
const ipCheck = () =>{
  return  axios
      .get("http://ipinfo.io", {

      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        checkStatus401(error.response);
      });
}

const AuthService = {
  login,
  oauthLogin,
  getCurrentUser,
  authKeyMail,
  certification,
  checkJoinByAddress,
  ipCheck,
};

export default AuthService;
