import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import PageTitle, {
    FlexBottomStart,
    FlexColumn, FlexRowSpace,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import styles from "../common/css/zigle.module.scss";
import {useSelector} from "react-redux";
import {Checkbox} from "@material-ui/core";
import BottomButton from "../components/common/button/BottomButton";
import PopupService from "../services/popup.service";
import CommonService from "../services/common.service";
import ProfileService from "../services/profile.service";
import {decimalFormat} from "../common/js/utilities";
import {Trans, useTranslation} from "react-i18next";

export default function Quit() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const doname: string = useSelector((state: any) => state.userReduce.doname);
    const ziglePoint = useSelector((state: any) => state.userReduce.ziglePoint);

    const [btnActive, setBtnActive] = useState(false);
    const [service, setService] = useState<boolean>(false);
    const quitConfirm =()=>{
        PopupService.newPopup(t("정말 탈퇴 하시겠어요?"),
            t("탈퇴하실 경우,<br/>보유 포인트가 모두 없어집니다."),
            <FlexRowSpace style={{marginTop:'20px', borderRadius:'16px', padding:'20px', backgroundColor:'#f9fafb'}}>
                <div>{t('보유 포인트')}</div>
                <div>{decimalFormat(ziglePoint)}</div>
            </FlexRowSpace>,
            {btnTxt:t("탈퇴할게요."), onClick: () => {
                ProfileService.memberQuit().then((resData)=>{
                    console.info("resData", resData);

                    if (resData?.status) {
                        navigate("/joinEmailCmpl", {
                        state: {
                            page:"quit"
                        }});
                    }
                }
                )
            }},
            {btnTxt:t("돌아기기"), onClick:()=>{

                }}
        )
    }
    useEffect(()=>{
        setBtnActive(service);
    },[service])

    return (
        <PageRootContainer>
            <PageTitle textTitle = {t('탈퇴하기')} onClickLeft={()=>{
                navigate(-1);
            }}
            />
            <PageContent>
                <FlexColumn gap={20}>
                    <FlexColumn gap={4}>
                        <div className={styles.txt20_B01}>
                            <Trans t={t} i18nKey={'<span className={styles.txt20_500_B01}>{0}</span>님과 이별인가요?<br/>너무 아쉬워요'} values={{0:doname}}/>
                        </div>
                        <div className={styles.txt13_B05}>{t('탈퇴 시 계정 내에 보유 중인 포인트 및 전송 이력, 친구 정보가 삭제됩니다. 메타마스크 등 지갑 내의 자산은 지글 탈퇴와 관련 없이 지갑에 보관 되어요!')}</div>
                    </FlexColumn>
                    <FlexColumn gap={4} className={styles.txt13_B05} style={{borderRadius:'16px', padding:'20px', backgroundColor:'#f9fafb'}}>
                            <div className={styles.txt13_600_B04}>{t('탈퇴 시 삭제되는 항목')}</div>
                            <div>
                                <Trans t={t} i18nKey={'프로필 정보 (이메일, 사진, 닉네임, 친구 목록 등)<br/>거래 내역 (코인 입출금, 포인트 충전 및 사용)<br/>지글 서비스 내 기타 자산'}/>

                            </div>
                    </FlexColumn>
                </FlexColumn>
                <FlexBottomStart gap={23}>
                    <FlexColumn style={{paddingLeft:'20px'}} className={styles.txt14_B02}>
                        <div className={styles.txt14_B02}>
                            <input type="checkbox" checked={service} id={'secession'} onChange={(e)=>{setService((prev: boolean) => !prev)}} />
                            <label htmlFor={'secession'}>{t('탈퇴 유의사항을 확인했어요.')}</label>
                            {/*<Checkbox checked={service} onChange={(e)=>{setService((prev: boolean) => !prev)}} />탈퇴 유의사항을 확인했어요*/}
                        </div>
                    </FlexColumn>
                    <FlexColumn>
                        <BottomButton
                            isActive={btnActive}
                            backgroundColor={"#3a71ff"}
                            textColor={"white"}
                            onClick={()=>quitConfirm()}
                            text={t("탈퇴하기")}
                        />
                    </FlexColumn>
                </FlexBottomStart>
            </PageContent>
        </PageRootContainer>
    )
}