import React, {useEffect, useRef, useState} from "react";
import PageTitle, {
    FlexColumn,
    FlexRowSpace,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import {useNavigate, useParams} from "react-router-dom";
import RcvHis from "../components/profile/RcvHis";
import userEvent from "@testing-library/user-event";
import SndHis from "../components/profile/SndHis";
import {useTranslation} from "react-i18next";

export default function History() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const params = useParams();
    const [pageTitle, setPageTitle] = useState('');
    const {modeType} = params;
    useEffect(()=>{
        if(modeType ==='scv')
        {
            setPageTitle(t('보낸 내역'));
        }else{
            setPageTitle(t('받은 내역'));
        }
    },[modeType])
    return (
        <PageRootContainer>
            <PageTitle textTitle = {pageTitle} onClickLeft={()=>{
                    navigate(-1);
            }}
            />
            <PageContent>
                <FlexColumn>
                    {
                        modeType === 'rcv' ? (<RcvHis/>) : (<SndHis/>)
                    }
                </FlexColumn>
            </PageContent>
        </PageRootContainer>
    )
}