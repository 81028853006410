import React, {ReactHTMLElement, useEffect, useRef, useState} from "react";
import PageTitle, {
    FlexBottom, FlexBottom86,
    FlexColumn, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../common/container/PageContainer";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import CopyToClipboard from "react-copy-to-clipboard";
import BottomButton from "../common/button/BottomButton";
import QRCode from "qrcode.react";
import DefaultInput from "../common/input/DefaultInput";
import {useForm} from "react-hook-form";
import AssetService from "../../services/asset.service";
import PopupService from "../../services/popup.service";
import {ILinkList} from "../../common/dto/LinkDto";
import PhoneList from "../Friend/PhoneList";
import FriendList from "../Friend/FriendList";
import DonamePopup from "../Friend/DonamePopup";
import {useTranslation} from "react-i18next";

interface ITokenDetail {
    show: boolean;
    setShow: any;
    setDepth:any;
    transferInfo:any;
    setTransferInfo:any;
    handleOnClick?: (item: string)=> void;
}
function FriendPopup(props:ITokenDetail) {
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [pageMode, setPageMode] = useState('list');
    const [donameView, setDonameView] = useState(false);
    const [reset, setReset] = useState(false);
    const inputRef = useRef<HTMLFormElement>(null);
    const {
        setValue,
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit,
    } = useForm();
    const transferOnClick = ()=>{
        setDonameView(false);
        setReset(!reset);
    }
    return (
        <>
            {
                props.show &&
                (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <DonamePopup handleOnClick={transferOnClick} setShow={setDonameView} show={donameView} />
                            <PageRootContainer>
                                <PageTitle
                                    home={true}
                                    textTitle ={t('지글 친구')}
                                    onClickLeft={()=>{
                                        props.setTransferInfo(
                                            {...props.transferInfo,
                                                coinId: '',
                                                coinTypeCode: '',
                                                coinContAddr: '',
                                                coinDecimalVal: '',
                                                coinSymbolName: t('송금 코인'),
                                                coinSymbolImgFileId:require('../../assets/icons/question2.png'),
                                            }
                                        );
                                        props.setDepth(1);
                                        props.setShow(false);
                                    }}
                                    rightSrc={require('../../assets/icons/plus_friend.png')}
                                    onClickRight={() => {setDonameView(true)}}

                                />
                                <PageContent>
                                    <FlexColumn className={styles.txt20_500_B01}>
                                        {t("누구한테 보낼까요?")}
                                    </FlexColumn>
                                    <FriendList handleOnClick={props.handleOnClick} reset={reset} pageMode={"trans"}/>
                                    <FlexBottom86  style={{padding:'24px 20px', borderRadius:'24px', boxShadow:'inset 0 0.6px 1px 0 #dbdee6', backgroundColor:'#ffffff'}}>
                                        <FlexColumn>
                                            <FlexRowSpace>
                                                <FlexRowStart>
                                                    <div style={{paddingRight:'12px'}}>
                                                        <img src={props.transferInfo.coinSymbolImgFileId} alt="bgIcon" width={38} />
                                                    </div>
                                                    <FlexColumn gap={3}>
                                                        <div className={props.transferInfo.coinId ? styles.txt16_B02 : styles.txt16_W03}>{props.transferInfo.coinSymbolName}</div>
                                                        <FlexRowStart className={styles.txt12_B05}>
                                                            <div style={{marginRight:'8px', width:'4px', height:'4px', borderRadius:'50%', backgroundColor:'#66bf9f'}}></div>
                                                            <div className={props.transferInfo.mainnetId ? styles.txt12_200_B04 : styles.txt12_W03}>{props.transferInfo.mainnetLinkName}</div>
                                                        </FlexRowStart>
                                                    </FlexColumn>
                                                </FlexRowStart>
                                                <FlexRowEnd>
                                                    {
                                                        props.transferInfo.linkType ?
                                                            (
                                                                props.transferInfo.linkType === 'safeTrans' ?
                                                                    (<div style={{backgroundColor:'#ebf2ff', color:'#3a71ff', borderRadius:'100px', padding:'9px 20px'}} className={styles.txt15_B02}>{props.transferInfo.linkName}</div>)
                                                                    :
                                                                    (
                                                                        props.transferInfo.linkType === 'hpTrans' ?
                                                                            (<div style={{backgroundColor:'#e2fffd', color:'#53cec7', borderRadius:'100px', padding:'9px 20px'}}  className={styles.txt15_B02}>{props.transferInfo.linkName}</div>)
                                                                            :
                                                                            (<div style={{backgroundColor:'#f2f4f6', color:'#53cec7', borderRadius:'100px', padding:'9px 20px'}}  className={styles.txt15_B02}>{props.transferInfo.linkName}</div>)
                                                                    )
                                                            )
                                                            :
                                                            (<div className={styles.txt15_W03}>{props.transferInfo.linkName}</div>)
                                                    }
                                                </FlexRowEnd>
                                            </FlexRowSpace>
                                        </FlexColumn>
                                    </FlexBottom86>
                                </PageContent>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                        )
            }
        </>

    );
}

export default FriendPopup;
