import React, {ReactHTMLElement, useEffect, useRef, useState} from "react";
import PageTitle, {
    FlexBottom, FlexBottom86,
    FlexColumn, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FullScreen,
    PageContent,
    PageRootContainer, PopupBodyWrapper
} from "../common/container/PageContainer";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import {useForm} from "react-hook-form";
import FriendList from "../Friend/FriendList";
import {useInView} from "react-intersection-observer";
import FavService from "../../services/fav.service";
import CommonService from "../../services/common.service";
import EmptyContent from "../common/EmptyContent";
import {Checkbox} from "@material-ui/core";
import BottomButton from "../common/button/BottomButton";
import {maskingEmail} from "../../common/js/utilities";
import {useTranslation} from "react-i18next";

interface IAirdropFrend {
    show: boolean;
    setShow: any;
    handleOnClick?: (item: string)=> void;
    setAirdropList:any;
}

export default function AirdropFriend(props:IAirdropFrend){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [reset, setReset] = useState(false);
    const [searchTxt, setSearchTxt] = useState('');

    const [friendList, setFriendList] = useState<any[]>([]);
    const [currPage, setCurrPage] = useState(1);
    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [hasNextPage, setHasNextPage] = useState(true);
    const [blockSize] = useState(10);
    const [checked, setChecked] = useState<any>([]);
    const [btnActive, setBtnActive] = useState(false);
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm();
    const checkedItems = checked.length
        ? checked.reduce((total:any, item:any) => {
            return total + ", " + item;
        })
        : "";
    const onSubmitClick = () => {
        let tmpItem = "";
        checked.map((item:string,index:any)=>{
            if(tmpItem === "")
            {
                tmpItem = item;
            }else{
                tmpItem += ","+item;
            }
        })
        props.setAirdropList(tmpItem);
        props.setShow(false);
    }
    const handleCheck = (event:any) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            updatedList = [...checked, event.target.value];
        } else {
            updatedList.splice(checked.indexOf(event.target.value), 1);
        }
        setChecked(updatedList);
    };
    const fetchData = async(searchCoin: any) =>{
        const response = await FavService.friendList(searchTxt, currPage, blockSize);
        if (!response.data.length) {
            setLastList(true);
            return;
        }
        setFriendList([...friendList, ...response.data]);
    }
    useEffect(() => {
        if (!lastList) {
            fetchData(searchTxt);
        }
    },[currPage, searchTxt]);

    useEffect(() => {
        if (inView && hasNextPage) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ hasNextPage, inView]);
    useEffect(()=>{
        if(checked.length > 0)
        {
            setBtnActive(true);
        }else{
            setBtnActive(false);
        }
    },[ checked])
    return (
        <>
            {
                props.show &&
                (
                    <FullScreen>
                        <PopupBodyWrapper>
                            <PageRootContainer>
                                <PageTitle
                                    home={true}
                                    textTitle ={t('지글 친구')}
                                    onClickLeft={()=>{
                                        props.setShow(false);
                                    }}
                                />
                                <PageContent>
                                    <FlexColumn className={styles.txt20_500_B01}>
                                        {t("받을 친구를 선택해 주세요.")}
                                    </FlexColumn>
                                    <FlexColumn>
                                        <FlexRowStart>
                                            <div style={{width:'28px', border: '0', borderBottom: 'solid 1px #f2f4f8', height: '47px', display: 'flex',alignItems: 'center'}}><img src={require('../../assets/imges/search_icon.png')} style={{width:'28px'}} /></div>
                                            <div style={{width:'100%'}}><input
                                                type={"text"}
                                                className={styles.inputText_44}
                                                placeholder={t('닉네임을 입력해 주세요')}
                                                {...register("searchTxt", {
                                                    required: t('닉네임을 입력해 주세요'),
                                                    onChange: (el) => {
                                                        setCurrPage(1);
                                                        setFriendList([]);
                                                        setLastList(false);
                                                        setSearchTxt(el.target.value);
                                                    }
                                                })}
                                            /></div>
                                        </FlexRowStart>
                                    </FlexColumn>
                                    <FlexColumn style={{marginTop:'28px'}}>
                                        <FlexColumn className={styles.txt15_B01} style={{marginBottom:'8px'}}>{t("내 친구 목록")}</FlexColumn>
                                        {
                                            friendList.length > 0 ? (
                                                    friendList.map((item, index) => {
                                                        return (
                                                            <FlexColumn key={item.favoriteId} style={{height:'78px'}}>
                                                                <FlexRowSpace>
                                                                    <FlexRowStart>
                                                                        <div style={{paddingRight:'12px'}}>
                                                                            <img src={item.profileImageFileId ? item.profileImageFileId : 'https://s3.ap-northeast-2.amazonaws.com/bucket.zigle.io/profiles/profile'+(item.userId % 9)+'.png' } alt="bgIcon" style={{width:'38px', height:'38px', borderRadius:'15px'}} />
                                                                        </div>
                                                                        <FlexColumn>
                                                                            <div className={ styles.txt16_B02}>{item.doname}</div>
                                                                            <div className={styles.txt14_B05}>{maskingEmail(item.email)}</div>
                                                                        </FlexColumn>
                                                                    </FlexRowStart>
                                                                    <FlexRowEnd>
                                                                        <input type="checkbox" id={item.userId} value={item.doname} onChange={handleCheck} />
                                                                        <label htmlFor={item.userId}></label>
                                                                    </FlexRowEnd>
                                                                </FlexRowSpace>
                                                            </FlexColumn>
                                                        )
                                                    })
                                                )
                                                :
                                                (
                                                    < EmptyContent titleText={t('친구를 찾을 수 없어요')} titleImage={require('../../assets/icons/empty_icon.png')}/>
                                                )
                                        }
                                    </FlexColumn>
                                    {
                                        Object.values(friendList).length >= blockSize ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
                                    }
                                </PageContent>
                                <FlexBottom>
                                    <BottomButton
                                        isActive={btnActive}
                                        backgroundColor={"#3a71ff"}
                                        textColor={"white"}
                                        // border={"solid 1px #dbdee6"}
                                        onClick={()=>onSubmitClick()}
                                        text={t("총 {0}명 선택 확인", {0:checked.length})}
                                    />
                                </FlexBottom>
                            </PageRootContainer>
                        </PopupBodyWrapper>
                    </FullScreen>
                )
            }
        </>
    )
}