import React, { useEffect, useState } from "react";
import AssetService from "../../services/asset.service";
import Button34 from "../common/button/Button34";
import {
    BellDiv,
    FlexColumEnd,
    FlexColumn,
    FlexRowAround, FlexRowCenter,
    FlexRowEnd,
    GrayBox,
    GrayBoxW00,
    YelloBox, ZSelect
} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss"
import {Box} from "../../pages/Profile";
import EmptyContent from "../common/EmptyContent";
import {useInView} from "react-intersection-observer";
import CommonService from "../../services/common.service";
import CopyToClipboard from "react-copy-to-clipboard";
import {RST_MAP} from "./SndHis";
import RcvHisItem from "./RcvHisItem";
import PhoneConfirm from "./PhoneConfirm";
import {useTranslation} from "react-i18next";


export default function RcvHis() {
    const { t } = useTranslation();
    const [list, setList] = useState<any[]>([])
    const [type, setType] = useState<string>("");
    const [showPop, setShowPop] = useState(false);
    const [urlKey, setUrlKey] = useState<string>("");
    const [detail, setDetail] = useState({});

    const [lastList, setLastList] = useState(false);
    const [ref, inView] = useInView();
    const [currPage, setCurrPage] = useState(1);
    const [blockSize] = useState(10);
    let monthDay = "";
    let monthResult = "";

    useEffect(() => {
        if (currPage === 1) {
            setList([]);
        }

        AssetService.rcvHis(type,currPage, '').then((data) => {
            if (!data.data.length) {
                setLastList(true);
                return;
            }
            setList([...list, ...data.data]);
        });
    }, [currPage,type]);

    useEffect(() => {
        if (inView && lastList) {
            setCurrPage(currPage=>currPage + 1);
        }
    }, [ lastList, inView]);

    return (
        <Box gap={24}>
            <PhoneConfirm show={showPop} setShow={setShowPop} urlKey={urlKey} item={detail} />
            <FlexRowEnd>
                <ZSelect onChange={el=>{
                    setList([]);
                    setType(el.target.value);
                    setLastList(false);
                    setCurrPage(1);
                }}>
                    <option value={""}>{t("전체")}</option>
                    <option value={"HP"}>{t("전화번호송금")}</option>
                    <option value={"TR"}>{t("안전송금")}</option>
                </ZSelect>
            </FlexRowEnd>

            <FlexColumn gap={40}>
                {
                    list.length > 0 ? (
                            list.map((item,index)=>{
                                let tmpDay = new Date(item.datetime).getMonth()+1 +"월 "+new Date(item.datetime).getDate()+"일";

                                if((tmpDay) && (monthDay != tmpDay)){
                                    monthResult = "Y";
                                    monthDay = tmpDay;
                                }else{
                                    monthResult = "N";
                                }

                                return (
                                    <RcvHisItem  key={item.idx}
                                                 item={item}
                                                 index={index}
                                                 setUrlKey={setUrlKey}
                                                 setShowPop={setShowPop}
                                                 setDetail={setDetail}
                                                 monthResult={monthResult}/>
                                );
                            })
                        )
                        :
                        (
                            <EmptyContent titleText={t('받은 이력이 없어요.')}/>
                        )

                }
                {
                    Object.values(list).length >= blockSize ? (
                        <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) : null
                }
            </FlexColumn>
        </Box>
    );
};