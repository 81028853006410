import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import styles from "../common/css/zigle.module.scss";
import styled from "styled-components";
import PageTitle, {
    FlexBottom, FlexColumEnd,
    FlexColumn, FlexColumnCenter, FlexRowCenter, FlexRowEnd, FlexRowSpace, FlexRowStart,
    PageContent,
    PageRootContainer
} from "../components/common/container/PageContainer";
import {Trans, useTranslation} from "react-i18next";
import {IChatItem} from "../components/chat/ChatList";
import chatJson from "../common/json/chat.json";
import {decimalFormat} from "../common/js/utilities";
import {Toast, ToastError} from "../components/common/popup/ToastAlert";
import ChatService from "../services/chat.service";
import {useSelector} from "react-redux";
import { IChatView } from "./Chat";
import CommonService from "../services/common.service";
import PopupService from "../services/popup.service";
import FavService from "../services/fav.service";
import {useInView} from "react-intersection-observer";
import {useForm} from "react-hook-form";
import ChatViewItem from "../components/chat/ChatViewItem";
import ChatMember from "../components/chat/ChatMember";
import moment from "moment";
import {useAccount, useBalance} from "wagmi";
import MetaMaskService from "../services/metamask.service";
import {useWalletEthersPorvider} from "../common/js/ethersProviderHook";
import ChatNoFriend from "../components/chat/ChatNoFriend";
import ChatTokenCheck from "../components/chat/ChatTokenCheck";
import ChatSetting from "../components/chat/ChatSetting";

const HidddenInputBox = styled.input`
  display: none;
`;

export default function ChatView({talkPlus}:IChatView){
    const param = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const roomDoname = useRef(param.doname);
    const [show, setShow] = useState(false);
    const [settingShow, setSettingShow] = useState(false);

    const chainId = useSelector((state:any) => state.walletReducer.chainId);
    const { address, isConnected } = useAccount();
    const [coinBalance , setCoinBalance] = useState('0');
    const hookCoinBalance = useBalance({
        address: address,
    });

    const provider = useWalletEthersPorvider();

    const [ref, inView] = useInView();
    const [inViewCnt, setInViewCnt] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [blockSize] = useState(50);

    const [userInfo , setUserInfo] = useState<any>([]);
    const [lastIndex, setLastIndex] = useState(0);
    const [chatSend, setChatSend] = useState(false);
    const messageBoxRef = useRef<HTMLDivElement | null>(null);
    const profileRef = useRef<any>();

    const [chatArray, setChatArray] = useState<Array<any>>([]);
    const [chatReadOnly, setChatReadOnly] = useState(false);


    const [chatId, setChatId] = useState('');
    const [muteList, setMuteList] = useState<Array<any>>([]);
    const [blockList, setBlockList] = useState<Array<any>>([]);
    const userId: string = useSelector((state: any) => state.userReduce.userId);

    const [viewItem, setViewItem] = useState<IChatItem>({
        profileImage: '',
        doname: '',
        key: '0',
        time: '0',
        activity : '0',
        contents: '0',
        master:'0',
        ethValue:'0'
    });

    const [chatList, setChatList] = useState<Array<any>>([]);
    const inputRef = useRef<HTMLFormElement>(null);
    const {
        setValue,
        register,
        formState: { errors, isDirty, isValid },
        handleSubmit,
    } = useForm();
    let monthDay = "";
    let monthResult = "";


    const modalPopupHandleClick = (popupUserId:string, popupNickName:string) =>{
        PopupService.chatPopup(
            <FlexColumn>
                <FlexRowSpace className={styles.txt16_B04} style={{padding:'8px 20px', height:'40px'}} onClick={()=>{
                    navigate("/u/"+popupNickName);
                    PopupService.chatPopupClose();
                }}>
                    <FlexRowStart>{t("프로필 보기")}</FlexRowStart>
                    <FlexRowEnd><img src={require('../assets/imges/chat/profile_btn.png')}  width={20}/></FlexRowEnd>
                </FlexRowSpace>
                {
                    muteList.indexOf(popupUserId) > -1 ? (
                        <FlexRowSpace className={styles.txt16_B04} style={{padding:'8px 20px', height:'40px'}} onClick={()=>{

                            talkPlus.unmutePeers({
                                channelId:userInfo.userId+'_chat',
                                members: [popupUserId]
                            }).then((res:any)=>{
                                Toast(t("{0}을 차단 해제 하였습니다.", {0:popupNickName}));
                                setMuteList(muteList.filter(user => user !== popupUserId));
                                PopupService.chatPopupClose();
                            }).catch((error:any)=>{
                                ToastError(t("{0}님은 이미 차단 해제 한 상태 입니다.", {0:popupNickName}));
                                PopupService.chatPopupClose();
                            });
                        }}>
                            <FlexRowStart>{t("차단해제")}</FlexRowStart>
                            <FlexRowEnd><img src={require('../assets/imges/chat/chat_ban.png')}  width={20}/></FlexRowEnd>
                        </FlexRowSpace>
                    ) : (
                        <FlexRowSpace className={styles.txt16_B04} style={{padding:'8px 20px', height:'40px'}} onClick={()=>{
                            talkPlus.mutePeers({
                                channelId:userInfo.userId+'_chat',
                                members: [popupUserId],
                                expireInMinutes:0
                            }).then((res:any)=>{
                                Toast(t("{0}을 차단하였습니다.", {0:popupNickName}));
                                PopupService.chatPopupClose();
                                setMuteList(pre=>[...pre, popupUserId]);
                            }).catch((error:any)=>{
                                ToastError(t("{0}님은 이미 차단한 상태 입니다.", {0:popupNickName}));
                                PopupService.chatPopupClose();
                            });
                        }}>
                            <FlexRowStart>{t("차단하기")}</FlexRowStart>
                            <FlexRowEnd><img src={require('../assets/imges/chat/chat_ban.png')}  width={20}/></FlexRowEnd>
                        </FlexRowSpace>
                    )
                }


                {
                    (userId === userInfo.userId) && (
                        blockList.indexOf(popupUserId) > -1 ? (
                                <FlexRowSpace className={styles.txt16_B04} style={{padding:'8px 20px'}} onClick={()=>{

                                    talkPlus.unbanChannelMembers({
                                        channelId:userInfo.userId+'_chat',
                                        members: [popupUserId]
                                    }).then((res:any)=>{
                                        ChatService.banChatRoom(userInfo.userId+"_chat", popupUserId, "Y");
                                        Toast(t("{0}을 강제 퇴장 취소 하였습니다.", {0:popupNickName}));
                                        PopupService.chatPopupClose();
                                    }).catch((error:any)=>{
                                        ToastError(t("{0}님은 이미 강제 퇴장 취소 상태 입니다.", {0:popupNickName}));
                                        PopupService.chatPopupClose();
                                    });
                                }}>
                                    <FlexRowStart>{t("강제퇴장취소")}</FlexRowStart>
                                    <FlexRowEnd><img src={require('../assets/imges/setting/icon_quit.png')}  width={20}/></FlexRowEnd>
                                </FlexRowSpace>
                            )
                            :
                            (
                                <FlexRowSpace className={styles.txt16_B04} style={{padding:'8px 20px'}} onClick={()=>{

                                    talkPlus.banChannelMembers({
                                        channelId:userInfo.userId+'_chat',
                                        members: [popupUserId]
                                    }).then((res:any)=>{
                                        ChatService.banChatRoom(userInfo.userId+"_chat", popupUserId, "Y");
                                        Toast(t("{0}을 강제 퇴장 하였습니다.", {0:popupNickName}));
                                        PopupService.chatPopupClose();
                                    }).catch((error:any)=>{
                                        ToastError(t("{0}님은 이미 강제 퇴장 상태 입니다.", {0:popupNickName}));
                                        PopupService.chatPopupClose();
                                    });
                                }}>
                                    <FlexRowStart>{t("강제퇴장")}</FlexRowStart>
                                    <FlexRowEnd><img src={require('../assets/imges/setting/icon_quit.png')}  width={20}/></FlexRowEnd>
                                </FlexRowSpace>
                            )

                    )
                }

            </FlexColumn>
        )
    }
    const handleFileChange = (e:any) => {
        let imgFile = e.target.files[0];
        let imgFileSize = String(imgFile.size);
        talkPlus.sendMessage({
            channelId: userInfo.userId+"_chat",
            type: 'text',
            text: '',
            data: { 'fileSizeLabel': imgFileSize, 'fileNameLabel': imgFile.name, fileTypeLabel: 'image' },
            file: imgFile
        }).then((res:any)=>{
            setChatList(pre=>[ res.message, ...pre]);
        });
    }
    const handleOnClickTokenCheck = async () =>{
        if(userInfo.mainnetChainId != chainId)
        {
            MetaMaskService.setSwitchNetwork(provider, parseInt(userInfo.mainnetChainId), userInfo.mainnetName, userInfo.mainnetSymbolName, userInfo.decimalVal, userInfo.mainnetRpcUrl, userInfo.mainnetExplorerUrl, '').then(
                async (res) => {

                    if(res.status)
                    {
                        let balance:string = '0';
                        if(userInfo.contAddr !=="" && userInfo.contAddr !== null && userInfo.contAddr !== undefined)
                        {
                            MetaMaskService.getBalance(address, userInfo.contAddr)
                                .then(async (res) => {
                                    balance = res ? res : '0';
                                    if(userInfo.checkCnt <= balance){
                                        await talkPlus.joinChannel({
                                            channelId: userInfo.userId+"_chat",
                                        }).then((res:any)=>{
                                            setUserInfo({...userInfo, isMyChat: "Y"});
                                            ChatService.joinChatRoom(userInfo.userId+"_chat", "N");
                                            setViewItem({...viewItem, activity:res.channel.memberCount});
                                            fetchData(userInfo.userId);
                                        }).catch((errors:any) =>{
                                            if(errors.code==="2003")
                                            {
                                                ToastError(t("아직 채팅룸이 개설되지 않은 회원입니다."));
                                            }else{
                                                ToastError(t("차단된 룸입니다."));
                                            }
                                        });
                                    }else{
                                        ToastError(t("보유 기준을 충족하지 못했어요."));
                                    }
                                });
                        }else{
                            balance = hookCoinBalance.data?.formatted ? hookCoinBalance.data?.formatted : '0';
                            if(userInfo.checkCnt <= balance){
                                await talkPlus.joinChannel({
                                    channelId: userInfo.userId+"_chat",
                                }).then((res:any)=>{
                                    setUserInfo({...userInfo, isMyChat: "Y"});
                                    ChatService.joinChatRoom(userInfo.userId+"_chat", "N");
                                    setViewItem({...viewItem, activity:res.channel.memberCount});
                                    fetchData(userInfo.userId);
                                }).catch((errors:any) =>{
                                    if(errors.code==="2003")
                                    {
                                        ToastError(t("아직 채팅룸이 개설되지 않은 회원입니다."));
                                    }else{
                                        ToastError(t("차단된 룸입니다."));
                                    }
                                });
                            }else{
                                ToastError(t("보유 기준을 충족하지 못했어요."));
                            }
                        }
                    }
                }
            );
        }else {
            let balance:string = '0';
            if(userInfo.contAddr !=="" && userInfo.contAddr !== null && userInfo.contAddr !== undefined)
            {
                MetaMaskService.getBalance(address, userInfo.contAddr)
                    .then( async (res) => {
                        balance = res ? res : '0';
                        if(userInfo.checkCnt <= balance){
                            await talkPlus.joinChannel({
                                channelId: userInfo.userId+"_chat",
                            }).then((res:any)=>{
                                setUserInfo({...userInfo, isMyChat: "Y"});
                                ChatService.joinChatRoom(userInfo.userId+"_chat", "N");
                                setViewItem({...viewItem, activity:res.channel.memberCount});
                                fetchData(userInfo.userId);
                            }).catch((errors:any) =>{
                                if(errors.code==="2003")
                                {
                                    ToastError(t("아직 채팅룸이 개설되지 않은 회원입니다."));
                                }else{
                                    ToastError(t("차단된 룸입니다."));
                                }
                            });
                        }else{
                            ToastError(t("보유 기준을 충족하지 못했어요."));
                        }
                    });
            }else{
                balance = hookCoinBalance.data?.formatted ? hookCoinBalance.data?.formatted : '0';

                if(userInfo.checkCnt <= balance){
                    await talkPlus.joinChannel({
                        channelId: userInfo.userId+"_chat",
                    }).then((res:any)=>{
                        setUserInfo({...userInfo, isMyChat: "Y"});
                        ChatService.joinChatRoom(userInfo.userId+"_chat", "N");
                        setViewItem({...viewItem, activity:res.channel.memberCount});
                        fetchData(userInfo.userId);
                    }).catch((errors:any) =>{
                        if(errors.code==="2003")
                        {
                            ToastError(t("아직 채팅룸이 개설되지 않은 회원입니다."));
                        }else{
                            ToastError(t("차단된 룸입니다."));
                        }
                    });
                }else{
                    ToastError(t("보유 기준을 충족하지 못했어요."));
                }
            }
        }

    }
    const handleOnClickFriendInsert = (tmpUserInfo:any) =>{
        PopupService.newPopup(t("프렌드하기")
            ,t("'{0}' 님을 프렌드 할까요?", {0:tmpUserInfo.doname})
            , ""
            ,{btnTxt:t("확인"),
                onClick: () => {
                    FavService.friendInsert(tmpUserInfo.userId).then(async (response) => {
                        Toast(t('친구등록이 완료 되었습니다.'));

                        if(tmpUserInfo.checkUse !== "Y"){
                            await talkPlus.joinChannel({
                                channelId: tmpUserInfo.userId+"_chat",
                            }).then((res:any)=>{
                                ChatService.joinChatRoom(tmpUserInfo.userId+"_chat", "N");
                                setUserInfo({...tmpUserInfo, isMyFriend: "Y", isMyChat: "Y"});
                                setViewItem({...viewItem, activity:res.channel.memberCount});
                                fetchData(tmpUserInfo.userId);
                            });
                        }else{
                            setUserInfo({...tmpUserInfo, isMyFriend: "Y"});
                        }
                    });
                }}
            , {
                btnTxt:t("취소"),
                onClick: () => {}
            }
        );
    }
    const handleProfileImageUploader = (e: React.MouseEvent) => {
        profileRef.current.click();
    };
    const listenerFunc = useCallback((data:any) => {
        if (data.type === 'message') {
            console.log(data);console.log(data);
            if(data.channel.id === userInfo.userId+"_chat")
            {
                setChatList(pre=>[ data.message, ...pre]);
            }

        }
        if (data.type === 'messageDeleted') {
            console.log("message deleted devent");
        }

        if (data.type === 'channelAdded') {
            console.log("new channel added with me as member");
        }

        if (data.type === 'channelChanged') {
            console.log("one of my channels changed");
        }

        if (data.type === 'channelRemoved') {
            console.log("one of my channels was removed");
        }

        if (data.type === 'memberAdded') {
            console.log("new channel member");
        }

        if (data.type === 'memberLeft') {
            console.log("channel member left");
        }
    },[chatList]);

    const fetchData = async(userIdx:string) =>{
        let params;
        if(hasNextPage){
            params = {
                channelId: userIdx+"_chat",
                lastMessageId: lastIndex,
                order: 'latest',
                limit:blockSize
            }
        }else{
            params = {
                channelId: userIdx+"_chat",
                order: 'latest',
                limit:blockSize
            }
        }
        const resp = await talkPlus.getMessages(params);

        if(resp.messages.length > 0){
            setChatList(pre=>[...pre, ...resp.messages]);
            setLastIndex(resp.messages[resp.messages.length - 1].id);
        }
        setHasNextPage(resp.hasNext);
    }

    const onSubmit = async (data: any) => {
        if(chatSend){
            await talkPlus.sendMessage({
                channelId : userInfo.userId+"_chat",
                type: 'text',
                text: data.message.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
            }).then((res:any)=>{

                if(res.message.text.length > 10){
                    ChatService.chatCnt(userInfo.userId+"_chat");
                }

                setChatList(pre=>[ res.message, ...pre]);
                setChatSend(false);
                setValue("message","");
                // 3초 안에 입력 금지

                var getCurrentTime = moment();

                if(chatArray.length === 7){
                    if(getCurrentTime.unix() - chatArray[0] <=10){
                        ToastError(t("도배 방지를 위해 1분간 채팅 입력이 금지됩니다."));
                        setChatReadOnly(true);
                    }
                    setChatArray(chatArray.slice(1))
                }
                setChatArray(pre=>[...pre , getCurrentTime.unix()])
            });
        }
    }

    useEffect(()=>{
        if (messageBoxRef.current) {
            messageBoxRef.current?.scrollIntoView({behavior: 'smooth'});
        }
    },[chatList]);
    useEffect(()=>{
        //도배 방지 시간 설정
        if(chatReadOnly){
            const interval = 60000;
            var intv = setInterval(function () {
                Toast(t("채팅 입력이 가능합니다."));
                setChatReadOnly(false);
                clearInterval(intv);
            } , interval);
        }

    },[chatReadOnly])
    useEffect(()=>{
        if(param.doname && talkPlus){
            talkPlus.on('event', listenerFunc);

            CommonService.donameSearch(param.doname).then(
                (response) => {

                    talkPlus.getMutedPeers({
                        channelId:response.data+"_chat",
                        limit: 100
                    }).then((res:any)=>{
                        if(res.members.length>0){
                            res.members.map((item:any, index:number) =>{
                                setMuteList(pre=>[...pre, item.id]);
                            });
                        }
                    });
                    if(response.data === userId) {
                        talkPlus.getBannedChannelUsers({
                            channelId: response.data + "_chat",
                            limit: 100
                        }).then((res: any) => {
                            console.log("res ", res)
                            if (res.users.length > 0) {
                                res.users.map((item: any, index: number) => {
                                    setBlockList(pre => [...pre, item.id]);
                                });
                            }
                        });
                    }

                    CommonService.friendUserInfo(response.data).then(
                        async (response) => {
                            setChatId(response.data[0].userId+"_chat");
                            if(response.data[0].userId === userId){
                                response.data[0].isMyFriend = "Y";
                                setUserInfo(response.data[0]);
                                talkPlus.joinChannel({
                                    channelId: userId+"_chat",
                                }).then((res:any)=>{
                                    setViewItem({...viewItem, activity:res.channel.memberCount});
                                    ChatService.joinChatRoom(userId+"_chat", "N");
                                    fetchData(userId);
                                });
                            }else{
                                setUserInfo(response.data[0]);
                                if(response.data[0]?.isMyFriend === "N"){
                                    ToastError(t("먼저 프렌딩을 하고 룸에 참여해 주세요."));
                                }else{
                                    if(response.data[0].checkUse === "Y" && response.data[0].isMyChat === "N"){

                                    }else{
                                        await talkPlus.joinChannel({
                                            channelId: response.data[0].userId+"_chat",
                                        }).then((res:any)=>{
                                            ChatService.joinChatRoom(response.data[0].userId+"_chat", "N");
                                            setViewItem({...viewItem, activity:res.channel.memberCount});
                                            fetchData(response.data[0].userId);
                                        }).catch((errors:any) =>{
                                            if(errors.code==="2003")
                                            {
                                                ToastError(t("아직 채팅룸이 개설되지 않은 회원입니다."));
                                            }else{
                                                ToastError(t("차단된 룸입니다."));
                                            }
                                        });
                                    }
                                }
                            }
                        }
                    )

                });
        }
    },[param.doname, talkPlus]);
    useEffect(() => {
        if (inView && hasNextPage && inViewCnt > 0) {
            fetchData(userInfo.userId);
        }
        if(inView){
            setInViewCnt(inViewCnt+1)
        }
    }, [ hasNextPage, inView]);
    return (
        <PageRootContainer>
            <ChatMember show={show} setShow={setShow} chatId={chatId} talkPlus={talkPlus}/>
            <ChatSetting  chatId={chatId}  show={settingShow} setShow={setSettingShow} setUserInfo={setUserInfo} userInfo={userInfo} userCnt={viewItem?.activity} talkPlus={talkPlus}/>
            <FlexColumn className={styles.pageTitle}>
                <FlexRowSpace style={{ height: "56px" }} >
                    <FlexRowStart style={{width:'30%'}}>
                        <img
                            src={require("../assets/imges/back_arrow.png")}
                            width={28}
                            alt={"back_arrow"}
                            style={{ marginLeft: "-8px" }}
                            onClick={() => {
                                navigate(-1);
                            }}
                        />
                        <img src={require('../assets/icons/home.png')} width={28} onClick={()=>navigate('/')}/>
                    </FlexRowStart>
                    <FlexColumnCenter  style={{width:'40%', whiteSpace:'pre-wrap', textAlign:'center'}} className={styles.txt15_B02}>
                        <div>{roomDoname.current}</div>
                        <div className={styles.txt12_B05} style={{textDecoration:'underline'}} onClick={()=>{setShow(true)}}>{t("{0} 멤버", {0:viewItem?.activity})}</div>
                    </FlexColumnCenter>
                    <FlexRowEnd  style={{width:'30%'}} className={styles.txt15_Crimson}>
                        {
                            (userInfo.isMyChat === "Y") && (
                                <img src={require('../assets/imges/menu_three.png')} width={28} onClick={()=>{
                                    setSettingShow(true)
                                }}/>
                            )
                        }
                    </FlexRowEnd>
                </FlexRowSpace>
                <div className={styles.line} style={{width: 'calc(100% + 40px)', marginLeft:'-20px'}}/>
            </FlexColumn>
            <PageContent style={{paddingBottom:'80px'}}>
                {
                    userInfo.isMyFriend === "N" ? (
                        <ChatNoFriend handleOnClick={handleOnClickFriendInsert} userInfo={userInfo} roomDoname={roomDoname}/>
                    ) : (
                        userInfo.checkUse === "Y" && userInfo.isMyChat === "N" ? (
                            <ChatTokenCheck handleOnClick={handleOnClickTokenCheck} userInfo={userInfo} roomDoname={roomDoname}/>
                        ) : (
                            <>
                                {
                                    hasNextPage ? ( <div ref={ref} className="py-3 bg-red-500 text-white text-center"></div>) :null
                                }
                                <FlexColumn gap={16}>
                                    {
                                        chatList.length > 0 ?
                                            (
                                                chatList.slice(0).reverse().map((item,index)=> {
                                                    let tmpDay = moment(item.createdAt).format('MM.DD');

                                                    if((tmpDay) && (monthDay != tmpDay)){
                                                        monthResult = "Y";
                                                        monthDay = tmpDay;
                                                    }else{
                                                        monthResult = "N";
                                                    }

                                                    return (
                                                        <ChatViewItem key={item.id} viewItem={item} handleOnClick={modalPopupHandleClick}  monthResult={monthResult}/>
                                                    );
                                                })
                                            )
                                            :
                                            (
                                                <FlexColumnCenter style={{paddingTop: '240px'}} gap={20}>
                                                    <img src={require('../assets/imges/chat/fi_7789699.png')} width={56}/>
                                                    <div className={styles.txt13_W03}
                                                         style={{textAlign: 'center', lineHeight: '130%'}}>
                                                        <Trans t={t} i18nKey={"{0}님의 채팅방이 개설되었어요<br/>가장 먼저 채팅을 시작해보세요"} values={{0: roomDoname.current}}/>
                                                    </div>
                                                </FlexColumnCenter>
                                            )
                                    }
                                    <div ref={messageBoxRef}/>
                                    <FlexBottom style={{ top: 'calc(100% - 80px)', padding:'8px 16px 32px 16px',  backgroundColor:'#F8F9FC', height:'80px'}}>
                                        <form ref={inputRef} onSubmit={handleSubmit(onSubmit)}  style={{ width: "100%" }}>
                                            <FlexRowCenter gap={8} className={styles.txt14_B03}>
                                                <HidddenInputBox
                                                    ref={profileRef}
                                                    type="file"
                                                    onChange={handleFileChange}
                                                ></HidddenInputBox>
                                                <img src={require('../assets/imges/chat/fileBtn.png')} width={28} onClick={(e) => {
                                                    handleProfileImageUploader(e);
                                                }}/>
                                                <FlexRowCenter style={{ background:'#F2F4F6',height:'44px', padding:'8px 12px 8px 20px', border:'1px solid #E3E5ED', borderRadius:'48px', color:'#FFFFFF'}} gap={5}>
                                                    <input
                                                        type={"text"}
                                                        className={styles.inputText_chat}
                                                        placeholder={t("메시지 보내기")}
                                                        readOnly={chatReadOnly}
                                                        {...register("message", {
                                                            required: t("메시지 보내기"),
                                                            onChange:(el)=>{
                                                                if(el.target.value){
                                                                    setChatSend(true);
                                                                }else{
                                                                    setChatSend(false);
                                                                }
                                                            }
                                                        })}
                                                    />
                                                    {
                                                        chatSend ? (<img src={require('../assets/imges/chat/chat_send_on.png')} width={24} onClick={handleSubmit(onSubmit)}/>) : (<img src={require('../assets/imges/chat/chat_send.png')} width={24} />)
                                                    }
                                                </FlexRowCenter>
                                                {/*<FlexRowCenter style={{ background:'#000000',height:'44px', padding:'8px 12px', borderRadius:'48px', color:'#FFFFFF'}} gap={5} onClick={()=>{ToastError(t('준비중입니다.'))}}>*/}
                                                {/*    <img src={require('../assets/imges/chat/silver_key.png')} width={20}/>{t("Key를 구매하고 채팅에 참여하기")}*/}
                                                {/*</FlexRowCenter>*/}
                                                {/*<FlexColumnCenter style={{borderRadius:'48px', width:'72px', height:'44px', border:'1px solid #E3E5ED'}} onClick={()=>{navigate(-1)}}>{t("나가기")}</FlexColumnCenter>*/}

                                            </FlexRowCenter>
                                        </form>
                                    </FlexBottom>
                                </FlexColumn>
                            </>
                        )
                    )
                }
            </PageContent>
        </PageRootContainer>
    )
}