import React from "react";
import {FlexColumEnd, FlexColumn, FlexRowAround} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import CommonService from "../../services/common.service";
import CopyToClipboard from "react-copy-to-clipboard";
import {Toast} from "../common/popup/ToastAlert";
import {decimalFormat, ellipseAddress} from "../../common/js/utilities";
import {useTranslation} from "react-i18next";
interface IRcvHisItem {

    index:number;
    item:any;
    setUrlKey:(p:string)=>void
    setShowPop:(p:boolean)=>void
    setDetail:(p:any)=>void
    monthResult:any;
}
export default function RcvHisItem(props:IRcvHisItem){
    const { t } = useTranslation();
    return (
        <FlexColumn gap={16}>
            {
                props.monthResult ==="Y" &&
                (
                    props.index !==0 ?
                        (
                            <FlexColumn gap={24}>
                                <FlexColumn className={styles.line}></FlexColumn>
                                <FlexColumn className={styles.txt13_B04}>{new Date(props.item.datetime).getMonth()+1 +"월 "+new Date(props.item.datetime).getDate()+"일"}</FlexColumn>
                            </FlexColumn>
                        )
                        :
                        (<FlexColumn className={styles.txt13_B04}>{new Date(props.item.datetime).getMonth()+1 +"월 "+new Date(props.item.datetime).getDate()+"일"}</FlexColumn>)
                )
            }
            <FlexColumn gap={5}>
                <FlexRowAround>
                    <FlexColumn gap={5}>
                        <div className={styles.txt16_500_B02}>
                            {props.item.type === 'TR' ? t("안전송금") : t("전화번호송금")}
                        </div>
                        <div className={styles.txt14_B01}>
                            {
                                props.item.type === 'HP' ?
                                    props.item.sndUser
                                    :
                                    ellipseAddress(props.item.sndAddress, 4)
                            }

                            {
                                props.item.sndUserNm &&
                                (<span>({props.item.sndUserNm})</span>)}

                        </div>
                        <div className={styles.txt12_B05}>{props.item.datetime}</div>
                    </FlexColumn>
                    <FlexColumEnd gap={5}>
                        <div className={styles.txt18_500}>
                            {decimalFormat(props.item.amount)}
                            <span style={{marginLeft:"4px"}}>{props.item.symbolName}</span>
                        </div>
                        {props.item.txId &&
                            (

                                <div className={styles.txt12_B05} style={{textDecoration:'Underline'}} onClick={() => {
                                    props.setUrlKey(props.item.linkUrl)
                                    props.setShowPop(true)
                                    props.setDetail(props.item)
                                }}>TXID</div>
                            )
                        }
                    </FlexColumEnd>
                </FlexRowAround>
                <FlexColumn className={styles.txt14_B04}>{props.item.message}</FlexColumn>
            </FlexColumn>
        </FlexColumn>
    )
}