import React, {useEffect, useRef, useState} from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../common/css/zigle.module.scss";
import {Checkbox, TextField} from "@material-ui/core";
import {FlexBottom, FlexColumn, FlexRowAround} from "../common/container/PageContainer";
import BottomButton from "../common/button/BottomButton";
import {useForm} from "react-hook-form";
import DefaultInput from "../common/input/DefaultInput";
import {IAirdropSet} from "../../pages/AirdropFi";
import {useTranslation} from "react-i18next";

interface AirdropFiStep1 {
    airdropInfo: IAirdropSet;
    setAirdropInfo: any;
    setDepth: any;
}

export default function AirdropFiStep2({airdropInfo, setAirdropInfo, setDepth}: AirdropFiStep1) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [btnActive, setBtnActive] = useState(false);
    const [service, setService] = useState<boolean>(false);

    const maxCnt = 100;
    const inputRef = useRef<HTMLFormElement>(null);

    const {
        register,
        formState: { errors },
        handleSubmit,
        setError,
        clearErrors,
    } = useForm();
    const plusBtnOnClick = (plus:number) =>{
        let el = inputRef.current?.airdropCnt;
        if(plus === maxCnt)
        {
            el.value = maxCnt;
        }else{
            el.value = Number(el.value)+plus;
        }


        if(el.value > maxCnt){
            setError("airdropCnt", {type:'custom', message:t("최대 {0}까지 가능합니다.", {0:maxCnt})});
            setBtnActive(false);
            return false;
        }

        setAirdropInfo(
            {...airdropInfo,
                airdropCnt : el.value
            }
        );
        clearErrors('airdropCnt');
        el.focus();
    }

    const onSubmit = (data: any) => {

        setAirdropInfo(
            {...airdropInfo,
                displayYn: !service ? "Y" : "N",
            }
        );
        setDepth(3);
    }
    useEffect(()=>{
        if(Number(airdropInfo.airdropCnt) > 0 && airdropInfo.airdropCnt <=100)
        {
            setBtnActive(true);
        }else{
            setBtnActive(false);
        }
    },[airdropInfo.airdropCnt])
    return (
        <FlexColumn>
            <form
                ref={inputRef}
                onSubmit={handleSubmit(onSubmit)}
                style={{ width: "100%" }}
            >
                <FlexColumn gap={16}>
                    <FlexColumn className={styles.txt20_500_B01}>{t("몇 명에게 보낼까요?")}</FlexColumn>
                    <DefaultInput
                        error={errors.airdropCnt}
                        marginBottom={1}
                        icon={require("../../assets/icons/input_x.png")}
                        iconClick={() => {
                            let el = inputRef.current?.airdropCnt;
                            el.value =0;
                            clearErrors('airdropCnt');
                            el.focus()
                        }}
                        inputElement={
                            <input
                                type={"number"}
                                className={styles.inputText_44}
                                placeholder={t("인원수 입력")}
                                {...register("airdropCnt", {
                                    required: t("인원수 입력"),
                                    onChange: (el)=>{

                                        if(el.target.value > maxCnt)
                                        {
                                            setError("airdropCnt", {type:'custom', message:t("최대 {0}까지 가능합니다.", {0:maxCnt})});
                                            setBtnActive(false);
                                        }else{
                                            setAirdropInfo(
                                                {...airdropInfo,
                                                    airdropCnt : el.target.value
                                                }
                                            );
                                            setBtnActive(true);
                                            clearErrors('airdropCnt');
                                        }
                                    },
                                   validate: (value, formValues) => {
                                        if (value >= 0 && value <=maxCnt) {
                                            return true;
                                        } else {
                                            return t("최소 0명 이상 최대 {0}명까지 가능합니다.", {0:maxCnt});
                                        }
                                    },
                                })}
                            />
                        }
                    />
                    <FlexRowAround>
                        <div className={styles.textBox} onClick={()=>{
                            plusBtnOnClick(1)
                        }}>+1</div>
                        <div className={styles.textBox} onClick={()=>{
                            plusBtnOnClick(5)
                        }}>+5</div>
                        <div className={styles.textBox} onClick={()=>{
                            plusBtnOnClick(10)
                        }}>+10</div>
                        <div className={styles.textBox} onClick={()=>{
                            plusBtnOnClick(50)
                        }}>+50</div>
                        <div className={styles.textBox} onClick={()=>{
                            plusBtnOnClick(maxCnt)
                        }}>{t("최대")}</div>
                    </FlexRowAround>
                    <FlexColumn>
                        <div className={styles.txt14_B05}>
                            <input type="checkbox" checked={service} id={'secession'} onChange={(e)=>{
                                setService((prev: boolean) => !prev);
                            }} />
                            <label htmlFor={'secession'}>{t("비공개 링크로 만들기")}</label>
                            {/*<Checkbox checked={service} onChange={(e)=>{setService((prev: boolean) => !prev)}} />비공개 링크로 만들기*/}
                        </div>
                    </FlexColumn>
                </FlexColumn>
            </form>
            <FlexBottom>
                <BottomButton
                    isActive={btnActive}
                    backgroundColor={"#3a71ff"}
                    textColor={"white"}
                    // border={"solid 1px #dbdee6"}
                    onClick={handleSubmit(onSubmit)}
                    text={t("다음")}
                />
            </FlexBottom>
        </FlexColumn>
    )
}