import React, { useEffect, useRef, useState } from "react";
import PageTitle, {
    FlexBottom,
    FlexColumn, FlexRowCenter,
    PageContent,
    PageRootContainer,
} from "../components/common/container/PageContainer";
import { useNavigate } from "react-router-dom";
import BottomButton from "../components/common/button/BottomButton";
import { useSelector } from "react-redux";
import DefaultInput from "../components/common/input/DefaultInput";
import { useForm } from "react-hook-form";
import AuthService from "../services/auth.service";
import { ILoginReqDto } from "../common/dto/request/UserReqDto";
import PopupService from "../services/popup.service";
import styles from "../common/css/zigle.module.scss";
import {useAccount} from "wagmi";
import {useTranslation} from "react-i18next";

export default function JoinEmail() {
  const [btnActive, setBtnActive] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { address, isConnected } = useAccount();
  const inputRef = useRef<HTMLFormElement>(null);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors,
  } = useForm();

  useEffect(() => {
    //지갑여부 확인
    if (address) {
      setBtnActive(true);
    }
  }, [address]);

  const onSubmit = (data: any) => {
    clearErrors();
    AuthService.authKeyMail(data.email,data.passwd).then((resData)=>{
        console.info("resData", resData);

        if (resData.status) {
            navigate("/joinEmailCmpl", {
                state: {
                    page:"join"
                }});
        } else {
            PopupService.newPopup(""
                , resData.msg
                , ""
                ,{btnTxt:t("확인 했어요"),
                    onClick: () => {
                    }}
                , {}
            );
        }
        }
    )
  };

  return (
    <PageRootContainer>
      <PageTitle
        onClickLeft={() => {
          navigate(-1);
        }}
        textTitle={t("이메일로 시작하기")}
      />

      <PageContent>
        <FlexColumn>
          <FlexColumn>
            <form
              ref={inputRef}
              onSubmit={handleSubmit(onSubmit)}
              style={{ width: "100%" }}
            >
              <DefaultInput
                lable={t("이메일")}
                error={errors.email}
                inputElement={
                  <input
                    type={"text"}
                    className={styles.inputText_44}
                    placeholder={t("이메일을 입력해주세요")}
                    {...register("email", {
                      required: t("이메일을 입력해 주세요."),
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                        message: t("이메일 형식이 올바르지 않습니다."),
                      },
                    })}
                  />
                }
              />
              <DefaultInput
                lable={t("비밀번호")}
                icon={require("../assets/icons/eye_3x.png")}
                iconClick={() => {
                  let el = inputRef.current?.passwd;
                  if (el.type === "password") {
                    el.type = "text";
                  } else {
                    el.type = "password";
                  }
                }}
                error={errors.passwd}
                inputElement={
                  <input
                    type={"password"}
                    className={styles.inputText_44}
                    placeholder={t("비밀번호를 입력해주세요.")}
                    {...register("passwd", {
                      required: t("비밀번호를 입력해 주세요."),
                      pattern: {
                        value: /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,20}$/i,
                        message: t("영문+숫자 조합 6자리 이상 입력해 주세요."),
                      },
                      minLength: 6,
                      maxLength: 20,
                    })}
                  />
                }
              />
              <DefaultInput
                lable={t("비밀번호 확인")}
                error={errors.passwdConfirm}
                icon={require("../assets/icons/eye_3x.png")}
                iconClick={() => {
                  let el = inputRef.current?.passwdConfirm;
                  if (el.type === "password") {
                    el.type = "text";
                  } else {
                    el.type = "password";
                  }
                }}
                inputElement={
                  <input
                    type={"password"}
                    className={styles.inputText_44}
                    placeholder={t("비밀번호확인을 입력해 주세요.")}
                    {...register("passwdConfirm", {
                      required: t("비밀번호확인을 입력해 주세요."),
                      pattern: {
                        value: /^(?=.*[a-zA-Z])(?=.*[0-9]).{6,20}$/i,
                        message: t("영문+숫자 조합 6자리 이상 입력해 주세요."),
                      },
                      minLength: 6,
                      maxLength: 20,
                      validate: (value, formValues) => {
                        if (value !== formValues.passwd) {
                          setError("passwd", {
                            type: "custom",
                            message: t("패스워드가 일치 하지 않습니다."),
                          });
                          return t("패스워드가 일치 하지 않습니다.");
                        }
                        return true;
                      },
                    })}
                  />
                }
              />
            </form>
          </FlexColumn>
            <FlexRowCenter className={styles.txt12_B05}>
                <div onClick={()=>navigate("/loginEmailForm")}>{t("이미 계정이 있으신가요?")}</div>
            </FlexRowCenter>
          <FlexBottom>
            <BottomButton
              isActive={btnActive}
              backgroundColor={"#3a71ff"}
              textColor={"white"}
              // border={"solid 1px #dbdee6"}
              onClick={handleSubmit(onSubmit)}
              icon={require("../assets/imges/login/iconEmail.png")}
              text={t("인증이메일 발송하기")}
            />
          </FlexBottom>
        </FlexColumn>
      </PageContent>
    </PageRootContainer>
  );
}
