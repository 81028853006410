import React, {useEffect, useRef, useState} from "react";
import {useInView} from "react-intersection-observer";
import styles from "../common/css/zigle.module.scss";
import PageTitle, {
    FC,
    FlexBottom,
    FlexColumn,
    FlexColumSpace,
    FlexRowAround, FlexRowCenter, FlexRowEnd,
    FlexRowSpace,
    FlexRowStart, FR, PageContent, PageRootContainer
} from "../components/common/container/PageContainer";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import styled from "styled-components";
import LinkWrapper from "../components/myLink/LinkWrapper";
import LinkPopup from "../components/myLink/LinkPopup";
import PopupService from "../services/popup.service";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {Toast, ToastError} from "../components/common/popup/ToastAlert";
import {useAccount} from "wagmi";
import {ellipseAddress} from "../common/js/utilities";
import {useTranslation} from "react-i18next";
import NaviBar from "../components/home/NaviBar";
export const Box = styled(FC)``;

export const Bar = styled.div`
  background-color: #f2f4f6;
  height: 16px;
  width: calc(100% + 40px);
  margin-left: -20px;
`;
export const BlueBox = styled(FR)`
  gap: 4px;
  width: 100%;
  height: 68px;
  padding: 20px;
  border-radius: 20px;
  background-color: #3a71ff;
  justify-content: center;
  align-items: center;
`;


export default function MyLink() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [blockSize] = useState(7);
    const [searchTxt, setSearchTxt] = useState('');
    const [showTokenPopup, setShowTokenPopup] = useState(false);
    const [itemInfo, setItemInfo] = useState('');
    const [reLoad, setReLoad] = useState(false);

    const login = useSelector((state: any) => state.userReduce.login);
    const { address, isConnected } = useAccount();

    const profileImageFileId: string = useSelector(
        (state: any) => state.userReduce.profileImageFileId
    );
    const userId: string = useSelector((state: any) => state.userReduce.userId);
    const doname: string = useSelector((state: any) => state.userReduce.doname);
    const email: string = useSelector((state: any) => state.userReduce.email);
    const socialCode: string = useSelector(
        (state: any) => state.userReduce.socialCode
    );

    const onClickLinkItem = (linkInfo:any)=>{
        setItemInfo(linkInfo);
        setShowTokenPopup(true);
    }

    return (
        <PageRootContainer>
            <LinkPopup setShow={setShowTokenPopup} show={showTokenPopup} itemInfo={itemInfo} setReLoad={setReLoad}/>
            <PageTitle
                textTitle ={t('받기')}
                onClickLeft={()=>{
                    navigate(-1);
                }}
                rightSrc={require('../assets/icons/link_history.png')}
                onClickRight={()=>{
                  navigate('/history/rcv');
                }}
            />
            <PageContent>
                <FlexColumn gap={24}>
                    <Box>
                        <FlexRowSpace>
                            <FlexRowStart gap={12}>
                                <div>
                                    <img
                                        src={profileImageFileId}
                                        width={50}
                                        alt={"profile image"}
                                        style={{borderRadius:'100px', aspectRatio:'1', objectFit:'cover'}}
                                    />
                                </div>
                                <FlexColumSpace style={{ alignItems: "start" }}>
                                    <div className={styles.txt20_B01}>
                                        {doname ?? t("닉네임이 없습니다.")}
                                    </div>
                                </FlexColumSpace>
                            </FlexRowStart>
                            <div className={styles.link_sub}>
                               <span className={styles.txt12_500_PointB} style={{width:'56px'}}
                                     onClick={()=>{
                                         if(doname) {
                                             PopupService.snsLinkMobile(window.location.protocol + "//" + window.location.host + "/u/" + doname);
                                         }else{
                                             ToastError(t('닉네임을 입력하셔야 사용 가능합니다.'))
                                         }
                                     }}
                               >{t("지글로 받기")}</span>
                            </div>
                        </FlexRowSpace>
                    </Box>

                    <BlueBox>
                        <div className={styles.txt18_500_W}>
                            <span className={styles.txt16} style={{color:'#ffffff', paddingLeft:'1px'}}>{ellipseAddress(address , 5)}</span>
                            <CopyToClipboard
                                text={address as string}
                                onCopy={() => Toast(t("주소를 복사 했습니다."))}
                            >
                                <img src={require("../assets/icons/copy.png")} width={20} alt={"copy"} />
                            </CopyToClipboard>
                        </div>
                    </BlueBox>
                </FlexColumn>
                <FlexColumn gap={32} style={{marginTop:'32px'}}>
                    <Bar/>
                    <FlexRowCenter style={{backgroundColor:'#f8f9fc', borderRadius:'12px', padding: '20px 16px'}}  onClick={() => {navigate("/creatLink")}}>
                        <img
                            src={require("../assets/imges/link.png")}
                            width={48}
                            alt={"link"}
                        />
                        <div  style={{width:'100%'}} className={styles.lp8}>
                            <div className={styles.txtContent}>
                                {t('링크 하나로 더욱 쉬워진 지글만의 코인받기')}
                            </div>
                            <div className={styles.txtTitle15}
                            >{t('도네이션 링크 만들기')}</div>
                        </div>
                        <FlexRowEnd style={{width:'40px'}} ><img src={require('../assets/icons/btnArrow.png')} height={28} alt={"arrow"} /></FlexRowEnd>
                    </FlexRowCenter>
                </FlexColumn>
                <FlexRowSpace style={{paddingTop:'24px'}}>
                    <div className={styles.txt20_B01}>{t('도네이션 링크')}</div>
                    <div  style={{width:'28px', textAlign:'right'}} onClick={() => {navigate("/myLinkList")}}>
                        <img
                            src={require("../assets/icons/btnArrow.png")}
                            height={28}
                            alt={"arrow"}
                        />
                    </div>
                </FlexRowSpace>
                <FlexColumn style={{paddingTop:'12px'}}>
                    <LinkWrapper userId={userId} blockSize={blockSize} orderBy={'DESC'} limit={true} reLoad={reLoad} searchTxt={searchTxt} onClickLinkItem={onClickLinkItem}/>
                </FlexColumn>
            </PageContent>
            <NaviBar pageType={"trade"}/>
        </PageRootContainer>
    )
}