import {FlexColumn, FlexRowAround} from "../common/container/PageContainer";
import styles from "../../common/css/zigle.module.scss";
import PhoneList from "./PhoneList";
import FriendList from "./FriendList";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import DonamePopup from "./DonamePopup";


export default function FriendingTab(props:{donameView:boolean}){
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [tabPage, setTabPage] = useState('friend');
    const [phoneMode, setPhoneMode] = useState('list');
    const [phoneView, setPhoneView] = useState(false);
    const [updateView, setUpdateView] = useState(false);
    const [viewItem, setViewItem] = useState<any>([]);

    const [reset, setReset] = useState(false);

    const handleOnClick=(selectItem: any)=>{

        if(tabPage ==='phone')
        {
            setViewItem(selectItem);
            setPhoneView(true);
        }else{
            navigate("/u/"+selectItem.doname, {
                state: {
                    back:true
                }})
        }
    }
    useEffect(()=>{
        if(props.donameView === false){
            setReset(!reset);
        }
    },[props.donameView])
    return (
        <>
            <FlexColumn style={{ paddingBottom: '23px'}}>
                <FlexRowAround>
                    <div className={tabPage === 'friend' ? styles.topTabActive : styles.topTabDefault} onClick={() => {setTabPage('friend');}}>{t('프렌드')}</div>
                    <div className={tabPage === 'phone' ? styles.topTabActive : styles.topTabDefault} onClick={() => {setTabPage('phone');}}>{t('연락처')}</div>
                </FlexRowAround>
                <div className={styles.line} style={{width: 'calc(100% + 40px)', marginLeft:'-20px'}}/>
            </FlexColumn>


            { tabPage === 'phone' && <PhoneList phoneView={phoneView} setPhoneView={setPhoneView} viewItem={viewItem} phoneMode={phoneMode} setPhoneMode={setPhoneMode}  handleOnClick={handleOnClick}/>}
            { tabPage === 'friend' && <FriendList handleOnClick={handleOnClick}  reset={reset} pageMode={"friend"}/>}

        </>
    )
}