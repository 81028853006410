import React, {CSSProperties, useEffect, useState} from "react";

import {inspect} from "util";
import styles from "../../common/css/zigle.module.scss";
import BottomLayer, { FlexBottomButton } from "../common/container/BottomLayer";
import {FlexColumn, FlexRowCenter, FlexRowEnd, FlexRowSpace, FlexRowStart} from "../common/container/PageContainer";
import BottomButton from "../common/button/BottomButton";
import {useSelector} from "react-redux";
import MetaMaskService from "../../services/metamask.service";
import CommonService from "../../services/common.service";
import Button34 from "../common/button/Button34";
import {Toast, ToastError} from "../common/popup/ToastAlert";
import CopyToClipboard from "react-copy-to-clipboard";
import {ellipseAddress} from "../../common/js/utilities";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
interface ITradePopup {
    show: boolean;
    setShow: any;
}
export default function TradePopup(props:ITradePopup){
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <BottomLayer show={props.show}
             setShow={props.setShow}
             title={''}
             content={
                 (
                     <FlexColumn gap={22} style={{paddingBottom:'24px'}}>
                         <FlexRowStart gap={10} onClick={()=>{navigate("/transfer")}}>
                             <img src={require('../../assets/imges/home/trade_scv.png')} width={44} />
                             <FlexColumn>
                                 <div className={styles.txt15_B01}>{t('보내기')}</div>
                                 <div className={styles.txt13_B05}>{t('지갑 주소 또는 닉네임, 전화번호로 코인 보내기')}</div>
                             </FlexColumn>
                         </FlexRowStart>
                         <FlexRowStart gap={10} onClick={()=>{navigate("/myLink")}}>
                             <img src={require('../../assets/imges/home/trade_rcv.png')} width={44} />
                             <FlexColumn>
                                 <div className={styles.txt15_B01}>{t('받기')}</div>
                                 <div className={styles.txt13_B05}>{t('내 지갑 주소, 도네이션 링크로 코인 받기')}</div>
                             </FlexColumn>
                         </FlexRowStart>
                         <FlexRowStart gap={10} onClick={()=>{ToastError(t('준비중입니다.'))}}>
                             <img src={require('../../assets/imges/home/zeth.png')} width={44} />
                             <FlexColumn>
                                 <div className={styles.txt15_B01}>{t('zETH 교환')}</div>
                                 <div className={styles.txt13_B05}>{t('ETH ↔ zETH 교환하기')}</div>
                             </FlexColumn>
                         </FlexRowStart>
                         <FlexRowStart gap={10} onClick={()=>{navigate("/zigleCharge")}}>
                             <img src={require('../../assets/imges/home/point.png')} width={44} />
                             <FlexColumn>
                                 <div className={styles.txt15_B01}>{t('지글포인트 충전')}</div>
                                 <div className={styles.txt13_B05}>{t('서비스 이용을 위해 지글포인트 충전하기')}</div>
                             </FlexColumn>
                         </FlexRowStart>
                     </FlexColumn>
                 )}
             />
    )

}