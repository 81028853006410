import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import langEn from './lang.us';
import langKo from './lang.ko';

const resource = {
    us: {
        translations: langEn
    },
    kr: {
        translations: langKo
    }
}

i18n.use(initReactI18next).init({
        resources: resource,
        // 초기 설정 언어
        lng: localStorage.getItem('language') || 'kr',
        fallbackLng: 'kr',
        debug: false,
        defaultNS: 'translations',
        ns: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false
        },
        react: {
            defaultTransParent: 'div',
            transEmptyNodeValue: '',
            transSupportBasicHtmlNodes: true,
            // <Trans> 컴포넌트 내부에 들어가는 html 태그들
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'button', 'a', 'span', 'div', 'input', 'b'],
            transWrapTextNodes: '',
        },

    })

export default i18n;